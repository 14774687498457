import React from "react";

import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { formatWholeDollars } from "src/utils";

const useStyles = makeStyles({
  root: {
    margin: "20px auto",
    padding: 10,
    maxWidth: 1000,
  },
  textCell: {
    wordBreak: "break-word",
  },
});

export const IDRTable = ({ data, loading }: any) => {
  const styles = useStyles();
  if (loading) {
    return <Box>Loading ...</Box>;
  }
  if (!data) {
    return null;
  }
  return (
    <Paper className={styles.root}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={styles.textCell}>Repayment Plan</TableCell>
              <TableCell className={styles.textCell}>First Payment</TableCell>
              <TableCell className={styles.textCell}>Last Payment</TableCell>
              <TableCell className={styles.textCell}>
                Total Paid (w/o Tax)
              </TableCell>
              <TableCell className={styles.textCell}>Amount Forgiven</TableCell>
              <TableCell className={styles.textCell}>
                Tax Obligation @ 35%
              </TableCell>
              <TableCell className={styles.textCell}>
                Total Paid w/ tax
              </TableCell>
              <TableCell className={styles.textCell}>
                Repayment Period (Mo)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={styles.textCell}>REPAYE/SAVE</TableCell>
              <TableCell>{formatWholeDollars(data.repaye[0])}</TableCell>
              <TableCell>{formatWholeDollars(data.repaye[1])}</TableCell>
              <TableCell>{formatWholeDollars(data.repaye[2])}</TableCell>
              <TableCell>{formatWholeDollars(data.repaye[3])}</TableCell>
              <TableCell>{formatWholeDollars(data.repaye[4])}</TableCell>
              <TableCell>
                {formatWholeDollars(data.repaye[2] + data.repaye[4])}
              </TableCell>
              <TableCell>{data.repaye[5]}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.textCell}>PAYE</TableCell>
              <TableCell>{formatWholeDollars(data.paye[0])}</TableCell>
              <TableCell>{formatWholeDollars(data.paye[1])}</TableCell>
              <TableCell>{formatWholeDollars(data.paye[2])}</TableCell>
              <TableCell>{formatWholeDollars(data.paye[3])}</TableCell>
              <TableCell>{formatWholeDollars(data.paye[4])}</TableCell>
              <TableCell>
                {formatWholeDollars(data.paye[2] + data.paye[4])}
              </TableCell>
              <TableCell>{data.paye[5]}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.textCell}>IBR</TableCell>
              <TableCell>{formatWholeDollars(data.ibr[0])}</TableCell>
              <TableCell>{formatWholeDollars(data.ibr[1])}</TableCell>
              <TableCell>{formatWholeDollars(data.ibr[2])}</TableCell>
              <TableCell>{formatWholeDollars(data.ibr[3])}</TableCell>
              <TableCell>{formatWholeDollars(data.ibr[4])}</TableCell>
              <TableCell>
                {formatWholeDollars(data.ibr[2] + data.ibr[4])}
              </TableCell>
              <TableCell>{data.ibr[5]}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.textCell}>
                IBR for New Borrowers
              </TableCell>
              <TableCell>
                {formatWholeDollars(data.ibr_for_new_borrowers[0])}
              </TableCell>
              <TableCell>
                {formatWholeDollars(data.ibr_for_new_borrowers[1])}
              </TableCell>
              <TableCell>
                {formatWholeDollars(data.ibr_for_new_borrowers[2])}
              </TableCell>
              <TableCell>
                {formatWholeDollars(data.ibr_for_new_borrowers[3])}
              </TableCell>
              <TableCell>
                {formatWholeDollars(data.ibr_for_new_borrowers[4])}
              </TableCell>
              <TableCell>
                {formatWholeDollars(
                  data.ibr_for_new_borrowers[2] + data.ibr_for_new_borrowers[4]
                )}
              </TableCell>
              <TableCell>{data.ibr_for_new_borrowers[5]}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default IDRTable;
