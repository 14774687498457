import React from "react";
import colors from "src/theme/colors";
import shadows from "src/theme/shadows";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  ASSET_ALLOCATIONS,
  DEBT_ALLOCATIONS,
} from "src/interfaces/plan.interface";
import Icon from "src/components/Icon";
import CenterContainer from "../../Components/CenterContainer";

const AllocationCategories = ({ isDebt, onSelect }: any) => {
  const styles = useStyles();
  const items = isDebt ? DEBT_ALLOCATIONS : ASSET_ALLOCATIONS;
  return (
    <CenterContainer
      title={isDebt ? "Debts" : "Assets"}
      iconName={isDebt ? "fb-scales-tripped" : "fb-model"}
    >
      <Container maxWidth="sm" className={styles.main}>
        <Grid container spacing={2}>
          {Object.values(items).map((item) => (
            <Grid item xs={4}>
              <Button
                fullWidth
                className={styles.card}
                onClick={() => onSelect(item)}
              >
                <Icon iconName={item.icon}></Icon>
                <Typography className={styles.cardText}>
                  {item.label}
                </Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Container>
    </CenterContainer>
  );
};

export default AllocationCategories;

const useStyles = makeStyles({
  main: {
    height: "calc(100vh - 210px)",
    padding: 50,
  },
  card: {
    boxShadow: shadows[2],
    height: 110,
    border: `1px solid ${colors.blueGray7}`,
    "&>span": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: colors.brandingBlue1,
      cursor: "pointer",
    },
  },
  cardText: {
    fontSize: 12,
    color: colors.brandingBlue1,
    fontWeight: 500,
    textAlign: "center",
    marginTop: 5,
    height: 24,
  },
});
