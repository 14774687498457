import React from "react";
import Select from "@material-ui/core/Select";
import { selectStyles } from "../../theme/base";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const useStyles = makeStyles(selectStyles);

interface IDropDown {
  className?: string;
  value?: string;
  items?: Array<{ label: string; value: string }>;
  onChange?: (event: React.ChangeEvent<any>) => void;
  [propName: string]: any;
}

const Dropdown = ({
  className,
  value,
  onChange,
  items,
  ...props
}: IDropDown) => {
  const classes: any = useStyles();
  return (
    <Select
      {...props}
      className={clsx(className, classes.root)}
      value={value}
      onChange={onChange}
      inputProps={{
        classes: {
          icon: classes.icon,
        },
      }}
      native
      IconComponent={ExpandMoreIcon}
    >
      {!!items &&
        items.map((item) => (
          <option key={`items-${item.value}`} value={item.value}>
            {item.label}
          </option>
        ))}
    </Select>
  );
};

export default Dropdown;
