import React, { useEffect, useState } from "react";

import { Box, Card, makeStyles, Typography } from "@material-ui/core";

import CenterContainer from "src/components/CenterContainer";
import FbIcon from "src/components/Icon";
import {
  emptyPlan,
  optimizedRecapItem,
  QuestionFormProps,
} from "src/interfaces/optimizedPlanBuild.interface";

interface RecapProps extends QuestionFormProps {
  handleClick?: (n: number, i: number) => void;
}

const Recap = ({
  planSetter,
  planData,
  nextClicked,
  goToNext,
  handleClick,
}: RecapProps) => {
  const styles = useStyles();
  const [nextClick] = useState(nextClicked);
  const [recapEntries, setRecapEntries] = useState<optimizedRecapItem[]>([]);
  const onHandleClick = (e: number, i: number) => {
    if (handleClick) {
      handleClick(e, i);
    }
  };

  const updateRecapEntries = () => {
    const entries: optimizedRecapItem[] = [];
    if (planData.will_nonprofit === "y") {
      entries.push({
        description: "Working at a Non-profit",
        imageName: "fb-full-time",
        stepIndex: 1,
        resetKeys: ["will_nonprofit"],
      });
    }
    if (planData.s_will_nonprofit === "y") {
      entries.push({
        description: "Working at a Non-profit",
        imageName: "fb-full-time",
        stepIndex: 1,
        who: "spouse",
        resetKeys: ["s_will_nonprofit"],
      });
    }
    if (planData.has_401k === "y") {
      entries.push({
        description: "Retirement Details - 401K/403b/457/TSP",
        imageName: "fb-retire",
        stepIndex: 4,
        resetKeys: [
          "has_401k",
          "retirement_limit",
          "retirement_match",
          "retirement_additional_limit",
          "retirement_additional_match",
          "has_roth_401k",
          "retirement_nonelective_contribution",
        ],
      });
    }
    if (planData.s_has_401k === "y") {
      entries.push({
        description: "Retirement Details - 401K/403b/457/TSP",
        imageName: "fb-retire",
        stepIndex: 4,
        who: "spouse",
        resetKeys: [
          "s_has_401k",
          "s_retirement_limit",
          "s_retirement_match",
          "s_retirement_additional_limit",
          "s_retirement_additional_match",
          "s_has_roth_401k",
          "s_retirement_nonelective_contribution",
        ],
      });
    }
    if (planData.has_hsa === "y") {
      entries.push({
        description: "Retirement Details - HSA",
        imageName: "fb-retire",
        stepIndex: 5,
        resetKeys: ["has_hsa"],
      });
    }
    if (planData.will_marry === "y") {
      entries.push({
        description: "Getting Married",
        details: planData.marry_date,
        imageName: "fb-baby",
        stepIndex: 6,
        resetKeys: [
          "will_marry",
          "marry_date",
          "marry_spouse_fed_loan_balance",
          "marry_spouse_priv_loan_balance",
        ],
      });
    }
    if (planData.will_move === "y") {
      entries.push({
        description: "Moving to a Different Area",
        details: planData.move_date,
        imageName: "fb-moving",
        stepIndex: 8,
        resetKeys: ["will_move", "move_zip_code", "move_date"],
      });
    }
    if (planData.vehicle_date !== "") {
      entries.push({
        description: "Purchasing a Vehicle",
        details: planData.vehicle_date,
        imageName: "fb-vehicle",
        stepIndex: 9,
        resetKeys: [
          "vehicle_date",
          "vehicle_down",
          "vehicle_cost",
          "vehicle_type",
        ],
      });
    }
    planData.children_dates.forEach((e, index) => {
      {
        entries.push({
          description: "Having a Child",
          imageName: "fb-baby",
          details: e.toString(),
          stepIndex: 7,
          subIndex: index,
          resetKeysAtIndex: [
            "children_dates",
            "child_income_drop",
            "s_child_income_drop",
          ],
        });
      }
    });
    planData.income_increase_date.forEach((e, index) => {
      {
        entries.push({
          description: "Expecting Increase in Income",
          imageName: "fb-full-time",
          details: e.toString(),
          stepIndex: 2,
          subIndex: index,
          resetKeysAtIndex: ["income_increase", "income_increase_date"],
        });
      }
    });
    planData.income_decrease_date.forEach((e, index) => {
      {
        entries.push({
          description: "Expecting Decrease in Income",
          imageName: "fb-reduce-work",
          details: e.toString(),
          stepIndex: 3,
          subIndex: index,
          resetKeysAtIndex: ["income_decrease", "income_decrease_date"],
        });
      }
    });
    planData.s_income_increase_date.forEach((e, index) => {
      {
        entries.push({
          description: "Expecting Increase in Income",
          imageName: "fb-full-time",
          details: e.toString(),
          stepIndex: 2,
          who: "spouse",
          subIndex: index,
          resetKeysAtIndex: ["s_income_increase", "s_income_increase_date"],
        });
      }
    });
    planData.s_income_decrease_date.forEach((e, index) => {
      {
        entries.push({
          description: "Expecting Decrease in Income",
          imageName: "fb-reduce-work",
          details: e.toString(),
          stepIndex: 3,
          who: "spouse",
          subIndex: index,
          resetKeysAtIndex: ["s_income_decrease", "s_income_decrease_date"],
        });
      }
    });
    // sort in ascending order
    entries.sort((a, b) => {
      const hasBLessThanA = a.details && b.details && a.details > b.details;
      const hasAButNoB = a.details && !b.details;
      if (hasBLessThanA || hasAButNoB) {
        return 1;
      } else {
        return -1;
      }
    });
    // if no entries to display then skip to the next page
    if (entries.length) {
      setRecapEntries(entries);
    } else {
      // goToNext();
    }
  };

  useEffect(() => {
    updateRecapEntries();
  }, [planData]);

  useEffect(() => {
    if (nextClicked !== nextClick) {
      goToNext();
    }
  }, [nextClicked]);

  const RecapSections = ({
    entry,
    recapClick,
  }: {
    entry: optimizedRecapItem;
    recapClick: (e: number, i: number) => void;
  }) => {
    const subIndex = entry.subIndex || 0;
    const handleClick = () => {
      recapClick(entry.stepIndex, subIndex);
    };
    const handleDelete = () => {
      const empty: any = {};
      if (entry.resetKeys) {
        // find those keys in the emptyPlan and reset the plan to match those values
        entry.resetKeys.forEach((key) => {
          empty[key as keyof typeof empty] =
            emptyPlan[key as keyof typeof emptyPlan];
        });
      } else if (entry.resetKeysAtIndex && entry.subIndex !== undefined) {
        entry.resetKeysAtIndex.forEach((key) => {
          // the thing at the key is an array
          // splice out the thing at the given index
          const data = planData[key as keyof typeof planData];
          if (Array.isArray(data)) {
            // this is to be sure that we are working with fresh data and not a reference
            const pData = [...data];
            pData.splice(subIndex, 1);
            empty[key as keyof typeof empty] = pData;
          }
        });
      }
      const newPlan = {
        ...planData,
        ...empty,
      };
      planSetter(newPlan);
    };
    return (
      <Card className={styles.card}>
        <Box className="flex row items-center py-2" onClick={handleClick}>
          <Box className="flex items-center mx-2">
            {entry.imageName && (
              <FbIcon iconName={entry.imageName} color="primary" />
            )}
          </Box>
          <Box className="pl-2">
            <Typography className=" text-sm" color="primary">
              {entry.description}
              {entry.who === "spouse" ? " (Spouse)" : ""}
            </Typography>
            <Box className="flex row">
              <Typography className=" text-sm w-16">{entry.details}</Typography>
              <Typography className="pl-2 text-sm" color="primary">
                Edit
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* <Box onClick={handleClick}/> */}

        <Box
          onClick={handleDelete}
          className="px-4 flex justify-center items-center"
        >
          <FbIcon iconName="fb-trash-can" color="primary" />
        </Box>
      </Card>
    );
  };

  return (
    <CenterContainer title="7. Recap" scrollable>
      <Box className="my-4">
        Below are the life events you'd like us to use in you financial plan.
      </Box>

      {/* Coming Soon! */}
      {!recapEntries.length && (
        <Typography className="mt-4 ml-4">Click Next To Review Plan</Typography>
      )}
      {recapEntries.map((item) => (
        <RecapSections entry={item} recapClick={onHandleClick} />
      ))}
      <Box className="pb-5" />
    </CenterContainer>
  );
};

export default Recap;

const useStyles = makeStyles({
  card: {
    borderRadius: "5px",
    display: "grid",
    gridTemplateColumns: "4.8fr .2fr",
    paddingLeft: "4px",
    marginTop: "5px",
  },
});
