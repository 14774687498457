import { all, call, put, takeLatest } from "redux-saga/effects";

import { FAIL, START, SUCCESS } from "../common";
import { FbAction } from "../../interfaces/common";
import * as actions from "./actions";
import {
  createSubscriptionApi,
  deleteSubscriptionApi,
  getCouponsApi,
  getCustomerPortalApi,
  getPublicKeyApi,
  getPurchasePlansApi,
  getMoneySchoolPlansApi,
  getSubscriptionsApi,
} from "src/apiService";
import {
  Coupon,
  PurchasePlan,
  Subscription,
  SubscriptionPayload,
} from "src/interfaces";
import { track } from "src/utils/tracking";

function* getPublicKey() {
  try {
    const data: string = yield call(getPublicKeyApi);
    yield put({ type: actions.GET_PUBLIC_KEY + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.GET_PUBLIC_KEY + FAIL, payload: error });
  }
}

function* getPurchasePlans() {
  try {
    const data: Array<PurchasePlan> = yield call(getPurchasePlansApi);
    yield put({ type: actions.GET_PURCHASE_PLANS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.GET_PURCHASE_PLANS + FAIL, payload: error });
  }
}

function* getCoupons({ payload }: FbAction<string>) {
  try {
    const data: Array<Coupon> = yield call(getCouponsApi, payload);
    yield put({ type: actions.GET_COUPONS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.GET_COUPONS + FAIL, payload: error });
  }
}

function* getSubscriptions() {
  try {
    const data: Array<Subscription> = yield call(getSubscriptionsApi);
    yield put({ type: actions.GET_SUBSCRIPTIONS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.GET_SUBSCRIPTIONS + FAIL, payload: error });
  }
}

function* createSubscription({ payload }: FbAction<SubscriptionPayload>) {
  try {
    const data: { id: string } = yield call(createSubscriptionApi, payload);
    const subscription: Subscription = {
      id: data.id,
      name: payload.token,
      plan: payload.plan,
    };

    track({ event: "payment_completed" });

    yield put({
      type: actions.CREATE_SUBSCRIPTION + SUCCESS,
      payload: subscription,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_SUBSCRIPTION + FAIL, payload: error });
  }
}

function* deleteSubscription({ payload }: FbAction<string>) {
  try {
    const data: Array<Subscription> = yield call(
      deleteSubscriptionApi,
      payload
    );
    yield put({ type: actions.DELETE_SUBSCRIPTION + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.DELETE_SUBSCRIPTION + FAIL, payload: error });
  }
}

function* getAllPurchaseData() {
  try {
    const plans: Array<PurchasePlan> = yield call(getPurchasePlansApi);
    const subscriptions: Array<Subscription> = yield call(getSubscriptionsApi);
    const portalData: { location: string } = yield call(getCustomerPortalApi);
    yield put({
      type: actions.GET_ALL_PURCHASE_DATA + SUCCESS,
      payload: {
        plans,
        subscriptions,
        portal: portalData.location,
      },
    });
  } catch (error) {
    yield put({ type: actions.GET_ALL_PURCHASE_DATA + FAIL, payload: error });
  }
}

function* getMoneySchoolPurchaseData() {
  try {
    const plans: Array<PurchasePlan> = yield call(getMoneySchoolPlansApi);
    const subscriptions: Array<Subscription> = yield call(getSubscriptionsApi);
    const portalData: { location: string } = yield call(getCustomerPortalApi);
    yield put({
      type: actions.GET_ALL_PURCHASE_DATA + SUCCESS,
      payload: {
        plans,
        subscriptions,
        portal: portalData.location,
      },
    });
  } catch (error) {
    yield put({ type: actions.GET_ALL_PURCHASE_DATA + FAIL, payload: error });
  }
}

export function* stripeSagas() {
  yield all([
    takeLatest(actions.GET_PUBLIC_KEY + START, getPublicKey),
    takeLatest(actions.GET_PURCHASE_PLANS + START, getPurchasePlans),
    takeLatest(actions.GET_COUPONS + START, getCoupons),
    takeLatest(actions.GET_SUBSCRIPTIONS + START, getSubscriptions),
    takeLatest(actions.CREATE_SUBSCRIPTION + START, createSubscription),
    takeLatest(actions.DELETE_SUBSCRIPTION + START, deleteSubscription),
    takeLatest(actions.GET_ALL_PURCHASE_DATA + START, getAllPurchaseData),
    takeLatest(
      actions.GET_MONEY_SCHOOL_PURCHASE_DATA + START,
      getMoneySchoolPurchaseData
    ),
  ]);
}
