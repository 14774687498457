import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import { getCurrentStep } from "src/store/profileBuild/selector";
import { getScore } from "src/store/dashboard/selector";

// NEW PB IMPORTS
import {
  EuiHeader,
  EuiText,
  EuiHeaderSectionItem,
  EuiTourStep,
  EuiButton,
  EuiIcon,
} from "@elastic/eui";
import Logo from "src/components/Global/FitbuxLogo";
import ProgressBar from "src/components/Global/ProgressBar";
import FitbuxScore from "src/components/Global/FitbuxScore";
import { closeTourCard } from "src/store/tourcard/actions";
import { AppState } from "src/store";
import styled from "@emotion/styled";

const StyledHeader = styled(EuiHeader)`
  box-shadow: none;
  border-bottom: none;
  padding: 0 1rem;
  block-size: 60px;
`;

const StyledEuiHeaderSectionItem = styled(EuiHeaderSectionItem)`
  gap: 8px;
`;

const StyledEuiText = styled(EuiText)`
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
`;

const TourStepCard = styled(EuiTourStep)`
  background-color: #cce4f5;
  p {
    font-size: 14px;
  }
  .tour-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
`;

const TourButton = styled(EuiButton)`
  background: transparent;
  border: 1px solid #343741;
  min-inline-size: 65px;
  height: 32px;
  font-size: 14px;
`;

const steps = [
  PROFILE_BUILD_STEPS.WELCOME,
  PROFILE_BUILD_STEPS.PERSONAL_INFO,
  PROFILE_BUILD_STEPS.MY_EDUCATION,
  PROFILE_BUILD_STEPS.SPOUSE_EDUCATION,
  PROFILE_BUILD_STEPS.INCOME_EXPENSES_INTRO,
  PROFILE_BUILD_STEPS.MY_INCOME,
  PROFILE_BUILD_STEPS.SPOUSE_INCOME,
  PROFILE_BUILD_STEPS.EXPENSES,
  PROFILE_BUILD_STEPS.DEBTS,
  PROFILE_BUILD_STEPS.ASSETS,
  PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO,
  PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_1,
  PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_1,
  PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_2,
  PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_2,
  PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_3,
  PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_3,
  PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_4,
  PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_4,
];

const ProfileBuilderHeader = () => {
  const score = useSelector(getScore);
  const [currentScore, setCurrentScore] = useState(score);
  const scoreRef = useRef(currentScore);
  const finished = useRef(false);

  const dispatch = useDispatch();
  const isTourCardOpen = useSelector(
    (state: AppState) => state.tourCard.isTourCardOpen
  );

  const animateScore = () => {
    finished.current = false;
    const targetScore = score;
    const tickScore = () => {
      setCurrentScore((c) => {
        if (c === targetScore || targetScore !== score) {
          finished.current = true;
          return c;
        }
        const diff = targetScore - c;
        if (diff > 0) {
          return c + Math.floor(Math.max(1, diff / 80));
        }
        return c + Math.floor(Math.min(-1, diff / 80));
      });
      if (!finished.current) {
        window.requestAnimationFrame(tickScore);
      }
    };
    window.requestAnimationFrame(tickScore);
  };

  useEffect(() => {
    setCurrentScore(scoreRef.current);
    scoreRef.current = score;
    animateScore();
    return () => {
      finished.current = true;
    };
  }, [score]);

  const currentStep = useSelector(getCurrentStep);
  const currentStepIndex = steps.findIndex((step) => step === currentStep) || 1;
  return (
    <>
      <StyledHeader position="fixed">
        <EuiHeaderSectionItem>
          <Logo link="/" className="logo-container" alt="fitbux logo" />
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem>
          <StyledEuiHeaderSectionItem aria-label="FitBux Score">
            <StyledEuiText>
              FitBUX
              <br />
              Score
            </StyledEuiText>
            <TourStepCard
              content={
                <EuiText>
                  <p>
                    The FitBUX Score incorporates your budget, financial assets,
                    debt, human capital, and behavior to represent your complete
                    financial picture.
                  </p>
                </EuiText>
              }
              isOpen={isTourCardOpen}
              maxWidth={360}
              onFinish={() => dispatch(closeTourCard())}
              offset={8}
              step={1}
              stepsTotal={1}
              title={
                <div className="tour-header">
                  <h3>FitBUX Score</h3>
                  <EuiIcon
                    type="cross"
                    onClick={() => dispatch(closeTourCard())}
                  />
                </div>
              }
              anchorPosition="rightUp"
              footerAction={
                <TourButton
                  color="text"
                  onClick={() => dispatch(closeTourCard())}
                >
                  Done
                </TourButton>
              }
            >
              <FitbuxScore currentScore={currentScore}></FitbuxScore>
            </TourStepCard>
          </StyledEuiHeaderSectionItem>
        </EuiHeaderSectionItem>
      </StyledHeader>
      <ProgressBar fillPercentage={(currentStepIndex / steps.length) * 100} />
    </>
  );
};

export default ProfileBuilderHeader;
