import React from "react";
import { useSelector } from "react-redux";

import { Box, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import AllocationSlider from "src/components/AllocationSlider";
import { getScore } from "src/store/dashboard/selector";
import { scoreToAllocation } from "src/utils";

const useStyles = makeStyles({
  text: { fontSize: 14 },
  tableHeader: {},
  leftColumn: {
    width: 200,
  },
  addButton: {
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 13,
      fontWeight: 500,
    },
  },
  buttonContainer: {
    margin: "40px auto",
    display: "flex",
    justifyContent: "center",
  },
  inputRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: "12px auto",
    maxWidth: 500,
    alignItems: "center",
  },
});

const SelectRisk = ({ back, next, allocation, setAllocation }: any) => {
  const styles = useStyles();
  const score = useSelector(getScore);
  const fitbuxAllocation = scoreToAllocation(score);

  const submit = () => {
    next();
  };

  const setFormValues = (callback: any) => {
    const { allocation: newAllocation } = callback({});
    setAllocation(newAllocation);
  };

  return (
    <>
      <Card title="Select The Investment Risk">
        <Typography className={styles.text}>
          Select the degree of risk you are willing to take. Higher risk means
          greater fluctuations in investment returns, while lower risk means
          less fluctuations in returns. Investment returns are based on
          portfolios and allocations created by FitBUX.
        </Typography>
        <AllocationSlider
          lowLabel="Low"
          highLabel="High"
          setFormValues={setFormValues}
          formValues={{
            fitbuxAllocation,
            allocation,
          }}
        />
      </Card>
      <Box className={styles.buttonContainer}>
        <Button fbColor="primary" onClick={back} className="mr-4">
          Back
        </Button>
        <Button fbColor="primary" onClick={submit}>
          Next
        </Button>
      </Box>
    </>
  );
};

export default SelectRisk;
