import { SidebarContent } from "src/interfaces";

export const lifeEventsCategoriesBlowout: SidebarContent[] = [
  {
    header: "Setting Goals",
    body: [
      "Once you've decided your destination, it's key to identify how to best get there.",
      "1) List your short-term and long-time goals.",
      "2) Prioritize 2-3 goals and go after them hard, one at a time. This is key to remain focused.",
      "3) Short-term goals take precedence over long-term goals. Chances are, you won't hit your long-term goals if you don't hit your short-term goals first.",
    ],
  },
  {
    header: "Comparing Options",
    body: [
      "We provide an easy way to simulate different plans. Simply select your goals on this screen, build, and save a plan.",
      "You can then build a second plan, choose different goals, and compare the outcomes between the two plans.",
    ],
  },
];
