import React from "react";
import { useSelector } from "react-redux";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import { getIsMarried } from "src/store/system/selector";
import welcomeImg from "../../../../assets/svgs/fi_landing.svg";
import Welcome from "../shared/Welcome";

const IncomeWelcome = () => {
  const isMarried = useSelector(getIsMarried);
  return (
    <Welcome
      prevStep={
        isMarried
          ? PROFILE_BUILD_STEPS.SPOUSE_EDUCATION
          : PROFILE_BUILD_STEPS.MY_EDUCATION
      }
      nextStep={PROFILE_BUILD_STEPS.MY_INCOME}
      title="Set up financial plan"
      headerContent={
        <>
          We'll ask you some questions about your budget, assets, and debts to
          create a long-term financial plan.
        </>
      }
      boldStep={1}
      welcomeImg={welcomeImg}
    />
  );
};

export default IncomeWelcome;
