import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { makeStyles, Typography } from "@material-ui/core";

import { getHouseholdPerformanceApi } from "src/apiService";
import Card from "src/components/Card";
import { getScore } from "src/store/dashboard/selector";
import { getPortfolioPerformance } from "src/store/investments/selector";
import { investmentsStyles } from "src/theme";
import { scoreToAllocation } from "src/utils";
import AllocationTable from "src/components/AllocationTable";

const useStyles = makeStyles({
  ...investmentsStyles,
  actions: {},
  progressContainer: {},
});

const disclaimerText =
  "Disclaimer: FitBUX's recommended allocation is based on our proprietary models developed by FitBUX RIA LLC. Your current allocation details are based on the oldest common date in which your investments existed. If the oldest common date is less than 10 years, we replace your investments with less than 10 years of history with similar investments that are older than 10 years. FitBUX's recommended allocation is based on the completeness of your FitBUX Profile. Therefore, FitBUX is not liable for incomplete or non-updated profiles. FitBUX is also not liable for you implementing the recommended allocation or not implementing. Thus, implementing the recommended allocation is your responsibility. Your Current Allocation includes any cash/checking/savings accounts you currently have on FitBUX whereby the cash is not being used to save for a goal in your financial plan.";

export const AnalysisResults = ({ results, setError }: any) => {
  const classes = useStyles();

  const score = useSelector(getScore);
  const { expectedReturns, standardDeviations }: any = useSelector(
    getPortfolioPerformance
  );

  const [myPerformance, setMyPerformance] = useState({
    standard_deviation: 10,
    annual_return: 5,
  });

  useEffect(() => {
    getHouseholdPerformanceApi()
      .then((result) => setMyPerformance(result.inception))
      .catch(() => setError(true));
  }, []);
  const fitbuxAllocation = scoreToAllocation(score);

  return (
    <>
      <Card title="Risk &amp; Return Summary">
        <AllocationTable
          myAllocationLabel="Current"
          myAvg={myPerformance.annual_return * 100}
          fbAvg={expectedReturns["" + fitbuxAllocation]}
          myStdDev={myPerformance.standard_deviation * 100}
          fbStdDev={standardDeviations["" + fitbuxAllocation]}
          extraAvg={results.annual_return * 100}
          extraStdDev={results.standard_deviation * 100}
          extraColumn
        />
      </Card>
      <Typography className={classes.disclaimer}>{disclaimerText}</Typography>
    </>
  );
};

export default AnalysisResults;
