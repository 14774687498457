import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getRouterState } from "src/store/router/selector";

import Analysis from "./Analysis";
import SelectChoices from "./SelectChoices";

export const InvestmentRecommendations = () => {
  const [step, setStep] = useState(0);
  const router = useSelector(getRouterState);

  useEffect(() => {
    if (router.location.hash === "#step2") {
      setStep(1);
    }
  }, [router]);

  switch (step) {
    case 1:
      return <Analysis goBack={() => setStep(0)} />;
    case 0:
    default:
      return <SelectChoices next={() => setStep(1)} />;
  }
};

export default InvestmentRecommendations;
