import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Box, CircularProgress, makeStyles } from "@material-ui/core";

import { getProjectedCdReturnApi } from "src/apiService";
import CalculatorLayout from "src/layouts/CalculatorLayout";
import { getRouterState } from "src/store/router/selector";
import Introduction from "./Introduction";
import Input from "./Input";
import Results from "./Results";

const useStyles = makeStyles({
  container: {
    margin: "20px auto",
    width: "90%",
    maxWidth: 1100,
  },
  loading: {
    margin: "120px auto",
    width: 0,
  },
});

const CD_DISCLAIMER =
  "Notes: The interest rate represents the annual percentage rate (APY) and the future value of your CD is calculated using daily compounding.";

enum STEPS {
  INTRODUCTION,
  INPUT,
  RESULTS,
}

const CdReturns = () => {
  const styles = useStyles();
  const router = useSelector(getRouterState);
  const [amount, setAmount] = useState("");
  const [rate, setRate] = useState(0);
  const [nMonths, setNMonths] = useState(0);
  const [step, setStep] = useState(STEPS.INTRODUCTION);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(0);

  const getResults = () => {
    setLoading(true);
    getProjectedCdReturnApi({
      amount: +amount,
      interest_rate: rate,
      months: nMonths,
    }).then((response) => {
      setLoading(false);
      setStep(STEPS.RESULTS);
      setResult(response);
    });
  };

  const renderStep = () => {
    if (loading) {
      return (
        <Box className={styles.loading}>
          <CircularProgress size={80} />
        </Box>
      );
    }
    switch (step) {
      case STEPS.INTRODUCTION:
        return <Introduction next={() => setStep(STEPS.INPUT)} />;
      case STEPS.INPUT:
        return (
          <Input
            amount={amount}
            setAmount={setAmount}
            rate={rate}
            setRate={setRate}
            nMonths={nMonths}
            setNMonths={setNMonths}
            back={() => setStep(STEPS.INTRODUCTION)}
            next={getResults}
          />
        );
      case STEPS.RESULTS:
        return <Results result={result} back={() => setStep(STEPS.INPUT)} />;
    }
  };

  return (
    <CalculatorLayout
      title="Future Value of a CD"
      backTo={
        router.location.query?.return === "investments"
          ? "/investments"
          : "/otheritems"
      }
      disclaimer={CD_DISCLAIMER}
    >
      <Box className={styles.container}>{renderStep()}</Box>
    </CalculatorLayout>
  );
};

export default CdReturns;
