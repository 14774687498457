import { createAction } from "redux-actions";

import { Account, AccountProvider } from "src/interfaces";
import { START, SUCCESS } from "../common";
import { PROFILE_BUILD_STEPS } from "../profileBuild/constants";

export interface AddAccountPayload {
  account: Partial<Account>;
  nextProfileStep?: PROFILE_BUILD_STEPS;
}

export interface UpdateAccountPayload {
  id: number;
  update: Partial<Account>;
}

export interface GetPublicTokenPayload {
  who?: string;
  itemId?: string;
}

export interface UpdateProviderPayload {
  itemId: string;
  update: any;
}

export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_PLAID_PUBLIC_TOKEN = "GET_PUBLIC_TOKEN";
export const GET_ACCOUNT_PROVIDERS = "GET_PROVIDERS"; // linked accounts
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const LOAD_ALL_ACCOUNT_DETAILS = "LOAD_ALL_ACCOUNT_DETAILS";
export const MARK_PROVIDER_CONFIRMED = "MARK_PROVIDER_CONFIRMED";
export const RELINK_LINKED_ACCOUNT = "RELINK_LINKED_ACCOUNT";
export const SET_REFERRAL_CODE = "SET_REFERRAL_CODE";
export const OVERWRITE_ACCOUNTS = "OVERWRITE_ACCOUNTS";
export const GET_LINKED_ACCOUNTS = "GET_LINKED_ACCOUNTS";
export const FETCH_PROVIDER_ACCOUNTS = "FETCH_PROVIDER_ACCOUNTS";
export const UPDATE_PROVIDER = "UPDATE_PROVIDER";

export const getAccount = createAction(GET_ACCOUNT + START);
export const receiveAccount = createAction(GET_ACCOUNT + SUCCESS);
export const getAccounts = createAction(GET_ACCOUNTS + START);
export const addAccount = createAction<AddAccountPayload>(ADD_ACCOUNT + START);
export const addAccountSuccess = createAction(ADD_ACCOUNT + SUCCESS);
export const updateAccount = createAction<UpdateAccountPayload>(
  UPDATE_ACCOUNT + START
);
export const deleteAccount = createAction<number>(DELETE_ACCOUNT + START);
export const removeAccount = createAction<number>(DELETE_ACCOUNT + SUCCESS);
export const getPublicToken = createAction<GetPublicTokenPayload>(
  GET_PLAID_PUBLIC_TOKEN + START
);
export const loadAllAccountDetails = createAction(
  LOAD_ALL_ACCOUNT_DETAILS + START
);
export const getAccountProviders = createAction(GET_ACCOUNT_PROVIDERS + START);
export const getProviderAccounts = createAction<AccountProvider>(
  FETCH_PROVIDER_ACCOUNTS + START
);
export const relinkLinkedAccount = createAction<string>(
  RELINK_LINKED_ACCOUNT + START
);
export const markProviderConfirmed = createAction<string>(
  MARK_PROVIDER_CONFIRMED
);
export const setReferralCode = createAction<string>(SET_REFERRAL_CODE);
export const updateProvider = createAction<UpdateProviderPayload>(
  UPDATE_PROVIDER
);
