import React from "react";
import { useSelector } from "react-redux";

import Button from "src/components/Button";
import StickyFooter from "src/components/StickyFooter";
import CalculatorLayout from "src/layouts/CalculatorLayout";
import { getIsSubscribed } from "src/store/system/selector";
import { AdapterLink } from "src/utils";

import { DISCLAIMER, STEP } from "./CONSTANT";
import { useRentVsBuy } from "./UseRentVsBuy";
import LandingStep from "./Steps/LandingStep";
import FreeUserLandingStep from "./Steps/FreeUserLandingStep";
import RentInputScreen from "./Steps/RentInputScreen";
import HomeInputScreen from "./Steps/HomeInputScreen";
import ResultStepScreen from "./Steps/ResultStepScreen";

const Content = () => {
  const { activeStep } = useRentVsBuy();

  const renderStep = () => {
    const paidAccount = useSelector(getIsSubscribed);
    if (paidAccount) {
      switch (activeStep) {
        case STEP.LANDING_STEP:
          return <LandingStep />;
        case STEP.HOUSE_INPUT_STEP:
          return <HomeInputScreen />;
        case STEP.RENT_INPUT_STEP:
          return <RentInputScreen />;
        case STEP.RESULT_STEP:
          return <ResultStepScreen />;
        default:
          return <LandingStep />;
      }
    } else {
      return <FreeUserLandingStep />;
    }
  };

  return (
    <CalculatorLayout
      title="Rent Vs Buying A House"
      backTo="/homebuying"
      disclaimer={DISCLAIMER}
      footer={
        activeStep === STEP.RESULT_STEP ? (
          <StickyFooter text="Check purchase rates with FitBUX's mortgage lending partners.">
            <Button
              fbColor="accent"
              component={AdapterLink}
              to="/mortgage-rates"
              className="mr-10"
            >
              Check My Rates
            </Button>
          </StickyFooter>
        ) : null
      }
    >
      {renderStep()}
    </CalculatorLayout>
  );
};

export default Content;
