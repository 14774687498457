import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid, makeStyles, withWidth } from "@material-ui/core";

import CircleChart from "src/components/CircleProgressChart";
import Card from "src/components/Card";
import ScoreReport from "../Components/TextScoreReport";
import colors from "src/theme/colors";
import { getScore } from "src/store/dashboard/selector";
import { AdapterLink } from "src/utils";

const FitbuxScore = ({ showLink, width }: any) => {
  const styles = useStyles();
  const score = useSelector(getScore);

  const chartData = [
    { key: "Your Score", value: score },
    { key: "Total Score", value: 1000 - score },
  ];

  return (
    <Card
      title="My FitBUX Score"
      className="md:h-80"
      headComponent={
        <Box className="w-36">
          <AdapterLink className={styles.link} to="/scorefactors">
            Explore score factors
          </AdapterLink>
        </Box>
      }
    >
      <Grid container className="items-center mt-4 border-b-2">
        <Grid item xs={12} md={6}>
          <CircleChart data={chartData} />
          {showLink && <Box className="text-center"></Box>}
        </Grid>
        <Grid item xs={12} md={6}>
          <ScoreReport
            score={score}
            className={
              width === "sm" ? `${styles.borderTop} mt-4` : styles.borderLeft
            }
          />
        </Grid>
      </Grid>
    </Card>
  );
};
const useStyles = makeStyles({
  container: {
    padding: "0px 30px",
  },
  borderLeft: {
    borderLeft: `1px solid ${colors.blueGray7}`,
  },
  borderTop: {
    borderTop: `1px solid ${colors.blueGray7}`,
  },
  link: {
    textDecoration: "none",
    fontSize: "0.8em",
    color: colors.brandingBlue1,

    "&:hover": {
      textDecoration: "underline",
    },
  },
});
export default withWidth()(FitbuxScore);
