import React, { useState } from "react";

import { Box, makeStyles, Theme } from "@material-ui/core";

import Button from "src/components/Button";
import Tabs from "src/components/Tabs";
import { usePointsCalculator } from "../UsePointsCalculator";
import { TAB_VALUES } from "../CONSTANTS";
import BreakEvenMethod from "../BreakEvenMethod";
import InvestmentMethods from "../InvestmentMethods";

const ResultScreen = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const { handleBack } = usePointsCalculator();

  const handleChange = (value: number) => {
    setActiveTab(value);
  };

  const renderStep = (activeTab: number) => {
    switch (activeTab) {
      case 0:
        return <BreakEvenMethod />;
      case 1:
        return <InvestmentMethods />;
      default:
        return <BreakEvenMethod />;
    }
  };

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.tabContainer}>
          <Tabs
            list={TAB_VALUES}
            value={activeTab}
            _handleChange={handleChange}
          />
        </Box>
        {renderStep(activeTab)}
        <Box className={classes.actionContainer}>
          <Button onClick={() => handleBack()}>Back</Button>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  tabContainer: {
    width: 300,
    margin: " 20px auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  headRow: {
    borderBottom: "1px solid #000",
  },
  headCell: {
    fontWeight: 600,
    border: 0,
    textAlign: "center",
  },

  row: {
    border: 0,
  },
  breakDownRow: {
    border: 0,
    backgroundColor: "#f0fafe",
  },
  breakDownTitleCell: {
    padding: theme.spacing(4, 2),
    border: 0,
  },
  breakDownCell: {
    border: 0,
    textAlign: "center",
  },
  titleCell: {
    border: 0,
    textAlign: "left",
  },
  cell: {
    border: 0,
    textAlign: "center",
  },
  bottomContainer: {
    backgroundColor: "#ebebeb",
    padding: theme.spacing(4, 2),
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    gap: 20,
  },
  title: {
    fontWeight: 600,
  },
}));

export default ResultScreen;
