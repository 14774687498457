import React from "react";
import { useSelector } from "react-redux";

import { Grid } from "@material-ui/core";

import NetWealthChart from "../Components/NetWealthChart";
import Chart from "src/components/Chart";
import {
  getDashboardNetProjection,
  getLiveProjection,
} from "src/store/planBuild/selector";
import { getHasPlan } from "src/store/system/selector";
import { chartData } from "../data";

const Summary = () => {
  const hasPlan = useSelector(getHasPlan);
  const netProjection = useSelector(getDashboardNetProjection);
  const liveProjection = useSelector(getLiveProjection);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <NetWealthChart />
      </Grid>
      <Grid item xs={12} md={6}>
        {!hasPlan && (
          <Chart
            title="Projected Net Wealth"
            isLocked
            data={chartData}
            xKey="name"
            keys={["uv", "pv", "amt"]}
            lockedButton={{
              label: "Build and Implement My Plan",
              to: "/build-plan",
            }}
            width="100%"
          />
        )}
        {hasPlan && (
          <Chart
            dollars
            title="Projected Net Wealth"
            data={netProjection}
            xKey="month"
            keys={["net"]}
            keyLabels={{
              net: "Current Projected",
            }}
            startMonth={
              liveProjection ? +liveProjection.start.slice(5) : undefined
            }
            startYear={
              liveProjection ? +liveProjection.start.slice(0, 4) : undefined
            }
            width="100%"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Summary;
