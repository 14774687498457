import { MappedCashflow, DEBT_TYPES } from "src/interfaces";

export interface HOME_CASH_FLOW extends MappedCashflow {
  drop?: boolean;
  inschool?: boolean;
}

export const HOME_INCOME_TYPES: any = {
  salary: "Salary",
  hourly: "Hourly wage",
  side_hustle: "Side hustle",
  bonus: "Bonus",
  commission: "Commission",
  stipend: "Stipend",
  other_income: "Other income",
};

export const HOME_DEBT_TYPES = { ...DEBT_TYPES };
// exclude home_loan from the home debt types
delete HOME_DEBT_TYPES.home_loan;

export interface HomeResults {
  optimal: ResultData;
  ok: ResultData;
  risky: ResultData;
}

export interface ResultData {
  home_value: number;
  housing_expense: number;
  debts: DebtData[];
  message?: string;
  unaffordable?: boolean;
}

export interface DebtData {
  type: string;
  payment: number;
}

export enum SECTIONS {
  LANDING,
  INCOME,
  SIDEINCOME,
  DEBT,
  DOWNPAYMENT,
  RESULTS,
}

export const STUDENT_LOAN_TYPES: any = {
  fed_loan: true,
  priv_loan: true,
  perkins_loan: true,
};
