import {
  Box,
  Theme,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import TitleCard from "src/components/TitleCard";
import { formatWholeDollars } from "src/utils";
import { usePointsCalculator } from "../UsePointsCalculator";

const BreakEvenMethod = () => {
  const classes = useStyles();
  const { breakEvenResult } = usePointsCalculator();
  const { noPointsOffer, pointOfferOne, pointOfferTwo } = breakEvenResult;

  return (
    <TitleCard title="Results: Break-Even Method">
      <Box className={classes.container}>
        <Typography>
          Buying points upfront would lower your starting mortgage balance and
          your required monthly payment.
        </Typography>
        <Typography>
          The Break-Even Method calculates how long it would take to recoup the
          amount you paid in points upfront thanks to those lower monthly
          payments.
        </Typography>
        <Typography>
          Paying points will make sense if you’re planning on keeping the
          property longer than it takes to reach break-even.{" "}
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.headRow}>
              <TableCell className={classes.headCell} colSpan={2} />
              <TableCell className={classes.headCell}>
                “No Point” Offer
              </TableCell>
              <TableCell className={classes.headCell}>Point Offer 1</TableCell>
              <TableCell className={classes.headCell}>Point Offer 2</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.row}>
              <TableCell className={classes.titleCell} colSpan={2}>
                <Typography>Points Paid In Dollars</Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography>
                  {formatWholeDollars(noPointsOffer.pointsInDollar)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography>
                  {formatWholeDollars(pointOfferOne.pointsInDollar)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography>
                  {formatWholeDollars(pointOfferTwo.pointsInDollar)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.row}>
              <TableCell className={classes.titleCell} colSpan={2}>
                <Typography>Monthly Payment</Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography>
                  {formatWholeDollars(noPointsOffer.monthlyPayment)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography>
                  {formatWholeDollars(pointOfferOne.monthlyPayment)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography>
                  {formatWholeDollars(pointOfferTwo.monthlyPayment)}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow className={classes.row}>
              <TableCell className={classes.titleCell} colSpan={2}>
                <Typography>
                  Difference In Monthly Payment vs. “No Point” Offer
                </Typography>
              </TableCell>
              <TableCell className={classes.cell} />
              <TableCell className={classes.cell}>
                <Typography>
                  {formatWholeDollars(pointOfferOne.deltaMonthlyPayment)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography>
                  {formatWholeDollars(pointOfferTwo.deltaMonthlyPayment)}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow className={classes.breakDownRow}>
              <TableCell className={classes.breakDownTitleCell} colSpan={2}>
                <Typography className={classes.title}>
                  Break-Even (Years)
                </Typography>
                <br />
                <Typography>
                  Points paid in dollars / Difference In Monthly Payment / 12
                </Typography>
              </TableCell>
              <TableCell className={classes.cell} />
              <TableCell className={classes.breakDownCell}>
                <Typography>
                  {pointOfferOne.breakEvenYears.toFixed(1)}
                </Typography>
              </TableCell>
              <TableCell className={classes.breakDownCell}>
                <Typography>
                  {pointOfferTwo.breakEvenYears.toFixed(1)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box className={classes.bottomContainer}>
          <Typography className={classes.title}>What This Means: </Typography>
          <br />
          <Typography>
            If you’re planning on owning your property for more than{" "}
            {pointOfferOne.breakEvenYears < pointOfferTwo.breakEvenYears
              ? pointOfferOne.breakEvenYears.toFixed(1) + " "
              : pointOfferTwo.breakEvenYears.toFixed(1) + " "}
            years, then a point offer makes sense.
          </Typography>
        </Box>
      </Box>
    </TitleCard>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  headRow: {
    borderBottom: "1px solid #000",
  },
  headCell: {
    fontWeight: 600,
    border: 0,
    textAlign: "center",
    wordBreak: "normal",
  },

  row: {
    border: 0,
  },
  breakDownRow: {
    border: 0,
    backgroundColor: "#f0fafe",
  },
  breakDownTitleCell: {
    padding: theme.spacing(4, 2),
    border: 0,
    "& .MuiTypography-root": {
      wordBreak: "normal",
    },
  },
  breakDownCell: {
    border: 0,
    textAlign: "center",
    "& .MuiTypography-root": {
      wordBreak: "normal",
    },
  },
  titleCell: {
    border: 0,
    textAlign: "left",
    "& .MuiTypography-root": {
      wordBreak: "normal",
    },
  },
  cell: {
    border: 0,
    textAlign: "center",
    "& .MuiTypography-root": {
      wordBreak: "normal",
    },
  },
  bottomContainer: {
    backgroundColor: "#ebebeb",
    padding: theme.spacing(4, 2),
  },
  title: {
    fontWeight: 600,
  },
}));

export default BreakEvenMethod;
