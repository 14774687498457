import axiosClient, { SHORT_ENDPOINT } from "../axiosClient";
import makeAuthenticatedRequest from "../authenticatedRequest";

export interface GetScoreResponse {
  score: number;
  hc: number;
  ranks: {
    applicant: {
      humanCapital: number;
      riskToIncome: number;
      education: number;
      workExperience: number;
      careerEnhancements: number;
      extraCurricular: number;
      financial: number;
      leverage: number;
      creditCard: number;
      loanToValue: number;
      emergencyFund: number;
      discretionaryIncome: number;
    };
  };
}

export interface GetScoreRanksResponse {
  score: number;
  ranks: {
    applicant: {
      humanCapital: number;
      riskToIncome: number;
      education: number;
      workExperience: number;
      careerEnhancements: number;
      extraCurricular: number;
      financial: number;
      leverage: number;
      creditCard: number;
      loanToValue: number;
      emergencyFund: number;
      discretionaryIncome: number;
    };
    average: {
      humanCapital: number;
      riskToIncome: number;
      education: number;
      workExperience: number;
      careerEnhancements: number;
      extraCurricular: number;
      financial: number;
      leverage: number;
      creditCard: number;
      loanToValue: number;
      emergencyFund: number;
      discretionaryIncome: number;
    };
  };
  scores: [
    {
      date: string;
      score: number;
    }
  ];
}

export const getScoreApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("ranks.php");
    return data as GetScoreResponse;
  });

export const getScoreRanksApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("get_dashboard_data.php", {
      baseURL: SHORT_ENDPOINT,
    });
    return data as GetScoreRanksResponse;
  });

export const fetchPlanNotificationsApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/tracking/notification.php");
    return data;
  });

export const dismissPlanNotificationsApi = (id: number) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.patch("/tracking/notification.php", [
      { id, status: "ignore" },
    ]);
    return data;
  });

export const fetchCallsApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/profile/calendar.php");
    return data;
  });

export const getTransactionSummaryApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`transaction/contribution.php`);
    return data;
  });

export const getTransactionYearlySummaryApi = (
  year: number,
  startMonth?: number,
  endMonth?: number
) =>
  makeAuthenticatedRequest(async () => {
    let startMonthString = "01";
    if (startMonth) {
      startMonthString = startMonth < 10 ? "0" + startMonth : "" + startMonth;
    }
    let endMonthString = "12";
    if (endMonth) {
      endMonthString = endMonth < 10 ? "0" + endMonth : "" + endMonth;
    }
    const { data } = await axiosClient.get(
      `transaction/yearlycontribution.php?start=${year}-${startMonthString}&end=${year}-${endMonthString}`
    );
    return data;
  });

export const getEducationYearlySummaryApi = (
  year: number,
  startMonth?: number,
  endMonth?: number
) =>
  makeAuthenticatedRequest(async () => {
    let startMonthString = "01";
    if (startMonth) {
      startMonthString = startMonth < 10 ? "0" + startMonth : "" + startMonth;
    }
    let endMonthString = "12";
    if (endMonth) {
      endMonthString = endMonth < 10 ? "0" + endMonth : "" + endMonth;
    }
    const { data } = await axiosClient.get(
      `/transaction/yearlyeducation.php?start=${year}-${startMonthString}&end=${year}-${endMonthString}`
    );
    return data;
  });

export const getWholePlanSummaryApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`transaction/dashboard.php`);
    return data;
  });

export const getIdealLoanRateApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/account/account.php?id=NNN");
    return data;
  });

export const getLifeInsuranceLiabilitiesApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/v2/tool/life_insurance");
    return data;
  });

export const getDealsApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/marketing/deals.php");
    return data;
  });

export const getEmergencyFundTargetApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/tool/emergencyfund.php");
    return data;
  });

export const getIncomeSummaryApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/cashflow/income/summary.php");
    return data;
  });

export const getExpenseSummaryApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/cashflow/expense/summary.php");
    return data;
  });

export const getRiskSummaryApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/cashflow/risk/summary.php");
    return data;
  });

export const getIncomeYearSummaryApi = (
  year: number,
  startMonth?: number,
  endMonth?: number
) =>
  makeAuthenticatedRequest(async () => {
    let startMonthString = "01";
    if (startMonth) {
      startMonthString = startMonth < 10 ? "0" + startMonth : "" + startMonth;
    }
    let endMonthString = "12";
    if (endMonth) {
      endMonthString = endMonth < 10 ? "0" + endMonth : "" + endMonth;
    }
    const { data } = await axiosClient.get(
      `/cashflow/income/yearsummary.php?start=${year}-${startMonthString}&end=${year}-${endMonthString}`
    );
    return data;
  });

export const getExpenseYearSummaryApi = (
  year: number,
  startMonth?: number,
  endMonth?: number
) =>
  makeAuthenticatedRequest(async () => {
    let startMonthString = "01";
    if (startMonth) {
      startMonthString = startMonth < 10 ? "0" + startMonth : "" + startMonth;
    }
    let endMonthString = "12";
    if (endMonth) {
      endMonthString = endMonth < 10 ? "0" + endMonth : "" + endMonth;
    }
    const { data } = await axiosClient.get(
      `/cashflow/expense/yearsummary.php?start=${year}-${startMonthString}&end=${year}-${endMonthString}`
    );
    return data;
  });

export const getRiskYearSummaryApi = (
  year: number,
  startMonth?: number,
  endMonth?: number
) =>
  makeAuthenticatedRequest(async () => {
    let startMonthString = "01";
    if (startMonth) {
      startMonthString = startMonth < 10 ? "0" + startMonth : "" + startMonth;
    }
    let endMonthString = "12";
    if (endMonth) {
      endMonthString = endMonth < 10 ? "0" + endMonth : "" + endMonth;
    }
    const { data } = await axiosClient.get(
      `/cashflow/risk/yearsummary.php?start=${year}-${startMonthString}&end=${year}-${endMonthString}`
    );
    return data;
  });

export const getIdrAccountsApi = (payload: { who?: string }) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(
      `/tracking/idr/accounts.php?who=${payload.who || "applicant"}`
    );
    return data;
  });

export const setIdrAccountsApi = (payload: {
  who?: string;
  accountIds: number[];
}) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(
      `/tracking/idr/accounts.php?who=${payload.who || "applicant"}`,
      { accounts: payload.accountIds }
    );
    return data;
  });

export const getIdrAssumptionsApi = (payload: { who?: string }) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(
      `/tracking/idr/assumptions.php?who=${payload.who || "applicant"}`
    );
    return data;
  });

export const setIdrAssumptionsApi = (payload: {
  who?: string;
  assumptions: any;
}) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post(
      `/tracking/idr/assumptions.php?who=${payload.who || "applicant"}`,
      payload.assumptions
    );
    return data;
  });

export const getIdrOverviewApi = (payload: { who?: string }) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(
      `/tracking/idr/data.php?who=${payload.who || "applicant"}`
    );
    return data;
  });

export const getCalendlyLinkApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/profile/schedule.php");
    return data;
  });

export const getMessagesApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/message.php");
    return data;
  });

export const sendMessageApi = (message: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("/message.php", { message });
    return data;
  });

export const markMessageReadApi = (id: number) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(`/message.php?id=${id}`);
    return data;
  });

export const getMiscStateApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/misc.php");
    return data;
  });

export const setMiscStateApi = (state: any) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put("/misc.php", state);
    return data;
  });
