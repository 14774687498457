import React from "react";
import {
  Box,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { colors } from "src/theme";
import { Info } from "@material-ui/icons";
import _ from "lodash";
import {
  DollarTextField,
  formatWholeDollars,
  PercentTextField,
} from "src/utils";
import Button from "src/components/Button";
import TitleCard from "src/components/TitleCard";

interface MortgageDetailsProps {
  mortgageDetails: {
    month: number;
    year: number;
    rate: number;
    balance: number;
    term: number;
  };
  monthlyPayment: number;
  onChange(value: unknown, key: string): void;
  onNextClick(): void;
  isHeloc?: boolean;
}

const MortgageDetails = ({
  mortgageDetails,
  onChange,
  monthlyPayment,
  onNextClick,
  isHeloc,
}: MortgageDetailsProps) => {
  const classes = useStyles();
  const { month, year, rate, balance } = mortgageDetails;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value, e.target.name);
  };

  const ready = () => {
    const { year, month, balance, rate } = mortgageDetails;
    if (balance > 0 && rate > 0 && 12 * year + month > 0) return true;
    return false;
  };

  const handleNext = () => onNextClick();

  const mortgageOrHeloc = isHeloc ? "HELOC" : "mortgage";

  return (
    <Box className={classes.root}>
      <TitleCard title={isHeloc ? "HELOC Details" : "Mortgage Details"}>
        <Box className={classes.container}>
          <Table>
            <TableHead>
              <TableRow className={classes.row}>
                <TableCell className={classes.wideCell}></TableCell>
                <TableCell className={classes.cell}>
                  <Box className={classes.headerTitle}>Years</Box>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Box className={classes.headerTitle}>Months</Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell className={classes.wideCell}>
                  <Typography>
                    What is the remaining length of your {mortgageOrHeloc} or
                    the expected {mortgageOrHeloc} length you are considering?
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <TextField
                    variant="outlined"
                    select
                    fullWidth
                    name="year"
                    value={year}
                    onChange={handleChange}
                  >
                    <MenuItem
                      value={""}
                      style={{
                        display: "none",
                      }}
                    ></MenuItem>
                    {_.range(0, 31).map((year) => (
                      <MenuItem value={year} key={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </TextField>
                </TableCell>
                <TableCell className={classes.cell}>
                  <TextField
                    variant="outlined"
                    select
                    fullWidth
                    value={month}
                    name="month"
                    onChange={handleChange}
                    disabled={year === 30}
                  >
                    <MenuItem
                      value={""}
                      style={{
                        display: "none",
                      }}
                    ></MenuItem>
                    {_.range(0, 12).map((month) => (
                      <MenuItem value={month} key={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </TextField>
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.wideCell}>
                  <Typography>
                    What is your current or expected interest rate?
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}></TableCell>
                <TableCell className={classes.cell}>
                  <PercentTextField
                    variant="outlined"
                    name="rate"
                    decimalScale={3}
                    value={rate ? rate : ""}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.wideCell}>
                  <Typography>
                    What is your balance or expected {mortgageOrHeloc} amount?
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}></TableCell>
                <TableCell className={classes.cell}>
                  <DollarTextField
                    variant="outlined"
                    name="balance"
                    decimalScale={0}
                    value={balance ? balance : ""}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.wideCell}>
                  <Typography variant="h6">
                    Estimated required monthly payment.
                  </Typography>
                  <Tooltip
                    title={`Your required payment is calculated from the above inputs.${
                      isHeloc
                        ? ""
                        : " It does not include taxes or mortgage insurance."
                    }`}
                  >
                    <Info />
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.cell}></TableCell>
                <TableCell className={classes.cell}>
                  <Typography>{formatWholeDollars(monthlyPayment)}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </TitleCard>
      <Box className={classes.actionContainer}>
        {ready() ? (
          <Button fbColor="primary" onClick={handleNext}>
            Next
          </Button>
        ) : (
          <Tooltip title="All fields must be filled out to continue">
            <span>
              <Button disabled fbColor="primary">
                Next
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    color: colors.brandingBlue1,
    display: "flex",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: 10,
    marginBottom: "20px",
  },
  wideCell: {
    border: 0,
    display: "flex",
    flex: 1,
    alignItems: "center",
    padding: "0 !important",
  },
  cell: {
    border: 0,
    display: "flex",
    width: "150px",
    padding: "0 !important",
    "&p": {
      whiteSpace: "initial",
    },
  },
  headerTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: colors.brandingBlue1,
    fontSize: "12px",
    flex: 1,
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  requiredPaymentTextField: {
    "& *": {
      border: "none",
    },
  },
}));

export default MortgageDetails;
