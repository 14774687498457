import React, { useState } from "react";
import { useSelector } from "react-redux";
import { range } from "lodash";

import { validateZipApi } from "src/apiService";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import ToolTipIcon from "src/components/ToolTipIcon";

import { colors } from "src/theme";

import { MONTHS, YEARS_FROM_NOW } from "src/constants";
import { Question } from "src/interfaces/questionnaire.interface";
import { getIsHomeowner } from "src/store/account/selector";
import { getProfile, getSpouseProfile } from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import {
  DollarTextField,
  PercentTextField,
  validateZip,
  formatZip,
} from "src/utils";

interface QuestionInterface {
  questions: Question;
  values: any;
  onChange: (newValues: any) => void;
}

const Questionnaire = ({ questions, onChange, values }: QuestionInterface) => {
  const styles = useStyles();
  const isHomeowner = useSelector(getIsHomeowner);
  const isMarried = useSelector(getIsMarried);
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const updateFields = values;
  const [zipError, setZipError] = useState(false);

  const handleSelectMonth = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    const month = value < 10 ? "0" + value : value.toString();
    const year = updateFields[field].slice(0, 4);
    const newMonthYear = `${year}-${month}`;
    const update = { ...updateFields, [field]: newMonthYear };
    onChange(update);
  };

  const handleSelectYear = (newYear: number | null, field: string) => {
    if (newYear) {
      const year = newYear.toString();
      const month = updateFields[field].slice(5);
      const newMonthYear = `${year}-${month}`;
      const update = { ...updateFields, [field]: newMonthYear };
      onChange(update);
    }
  };
  const setFormValueHlp = (
    e: React.ChangeEvent<any>,
    fieldType: string | null
  ) => {
    const field = e.target.name;
    let value = e.target.value;

    if (fieldType == "zip") {
      value = formatZip(value);
    }
    if (e.target.type === "number") {
      value = +value;
    }
    const update = { ...updateFields, [field]: value };
    if (field === "enable_reduce" && value === "y") {
      update.reduce = "applicant";
      update.salary = 0;
    }
    onChange(update);
  };

  const setFormValue = (e: React.ChangeEvent<any>) => {
    setFormValueHlp(e, null);
  };

  const renderRetirementAgeItems = () => {
    const profile = updateFields.who === "spouse" ? spouseProfile : myProfile;
    const dobYear = profile.dob_year;
    const currentAge = new Date().getFullYear() - dobYear;
    const ages = range(currentAge, 121);
    return ages.map((age: number) => (
      <MenuItem key={age} value={age}>
        {age}
      </MenuItem>
    ));
  };

  const checkZip = async (input: string) => {
    // check the format (cheap check)
    let error = !validateZip(input);
    if (!error) {
      // check the zip (round-trip check to API)
      error = !(await validateZipApi(input));
    }
    setZipError(error);
  };

  return (
    <>
      {questions.fields.map((item) => {
        const setFormValueWithType = (e: React.ChangeEvent<any>) =>
          setFormValueHlp(e, item.type);

        // note: the "enabled" key acts as a display if all "y"
        // note: look at all "enabled requirements, and if any of the requirements are set to "n" then hide this question
        if (item.enabled) {
          const hideQuestion = item.enabled.some((enabler, i) => {
            const enablingValues = item.enabling_values
              ? item.enabling_values[i]
              : null;
            const enablersValue = updateFields[enabler];
            const isDisabled =
              enablingValues && enablingValues.indexOf(enablersValue) < 0;
            if (
              // hide conditions
              isDisabled ||
              (enabler === "profile_married" && !isMarried) ||
              (enabler === "profile_homeowner" && !isHomeowner) ||
              (!enablingValues &&
                !enabler.startsWith("profile") &&
                enablersValue !== "y") // if enablersValue is not "y" then hide question
            ) {
              return true;
            } else {
              return false;
            }
          });
          if (hideQuestion) {
            return null;
          }
        }
        let label = item.label;
        if (item.whoText) {
          if (updateFields[item.whoText] !== "spouse") {
            label = label
              .replace("their", "your")
              .replace("they", "you")
              .replace("does your spouse", "do you");
          }
        }

        // ! figure out the retirement life event default value
        // let retirementAge = updateFields[item.field] || 65;
        // if (updateFields.eventtype === "retire") {
        //     const dobYear =
        //     item.who !== "applicant"
        //         ? myProfile.dob_year
        //         : spouseProfile.dob_year;
        //     const retirementYears = sortedLifeEvents.filter(
        //     (event) =>
        //         event.eventtype === "retire" &&
        //         event["who" as keyof typeof event] ===
        //         updateFields.who
        //     );
        //     if (retirementYears && !retirementAge) {
        //     const retireYear = +retirementYears[0].date.slice(
        //         0,
        //         4
        //     );
        //     retirementAge = retireYear - dobYear;
        //     setUpdateFields({
        //         ...updateFields,
        //         age: retirementAge,
        //     });
        //     }
        // }

        return (
          <Grid
            key={item.field}
            item
            xs={item.gridWidth || 6}
            className="px-1 flex align-bottom"
          >
            {item.type === "monthYear" && (
              <FormControl
                component="fieldset"
                className="mt-4"
                fullWidth={!item.gridWidth}
              >
                <FormLabel component="legend">
                  <Box className="items-center text-sm">
                    {label}{" "}
                    {item.info && (
                      <span className="absolute">
                        <ToolTipIcon placement="right" title={item.info} />
                      </span>
                    )}
                  </Box>
                </FormLabel>
                <Box className="flex mt-4">
                  <Select
                    placeholder="Month"
                    variant="outlined"
                    style={{ width: "50%", fontSize: "13px" }}
                    name={item.field}
                    onChange={handleSelectMonth}
                    value={+updateFields[item.field].slice(5)}
                    className={styles.autoText + " mr-4"}
                  >
                    {MONTHS.map((month: string, index: number) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                  {/*<Autocomplete
            id="month_selector"
            options={Months}
            getOptionLabel={(option) => option.name}
            style={{ width: "50%" }}
            placeholder="Month"
            className={`mr-4`}
            renderInput={(params) =>
                <TextField
                {...params}
                label="Month"
                variant="outlined"
                placeholder="Month"
                className={styles.autoText}
                />
            }
            onChange={handleSelectMonth}
            value={selectedMonth}
            />*/}
                  <Autocomplete
                    id={item.field}
                    // name={item.field}
                    options={YEARS_FROM_NOW}
                    getOptionLabel={(option) => String(option)}
                    style={{ width: "50%" }}
                    placeholder="Year"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Year"
                        className={styles.autoText}
                        name={item.field}
                      />
                    )}
                    onChange={(e, yearData) => {
                      handleSelectYear(yearData, item.field);
                    }}
                    value={updateFields[item.field].slice(0, 4)}
                  />
                </Box>
              </FormControl>
            )}
            {item.type === "age" && (
              <FormControl component="fieldset" className="mt-4" fullWidth>
                <FormLabel component="legend" className="text-sm">
                  {label}
                </FormLabel>
                <Select
                  className="mt-aut"
                  style={{ fontSize: "13px" }}
                  variant="outlined"
                  fullWidth
                  value={updateFields[item.field]}
                  name={item.field}
                  placeholder="Select"
                  onChange={setFormValue}
                >
                  {renderRetirementAgeItems()}
                </Select>
              </FormControl>
            )}
            {item.type === "select" && (
              <FormControl component="fieldset" className="mt-4" fullWidth>
                <FormLabel component="legend">
                  <Box className="items-center text-sm relative">
                    {label}{" "}
                    {item.info && (
                      <span className="absolute">
                        {" "}
                        <ToolTipIcon placement="right" title={item.info} />
                      </span>
                    )}
                  </Box>
                </FormLabel>
                <Select
                  className="mt-4"
                  style={{ fontSize: "13px" }}
                  variant="outlined"
                  fullWidth
                  value={updateFields[item.field]}
                  name={item.field}
                  placeholder="Select"
                  onChange={setFormValue}
                >
                  {item.select
                    ? item.select.map(
                        (selectItem: { label: string; value: any }) => (
                          <MenuItem
                            key={selectItem.value}
                            value={selectItem.value}
                            className="text-sm"
                          >
                            {selectItem.label}
                          </MenuItem>
                        )
                      )
                    : []}
                </Select>
              </FormControl>
            )}
            {item.type === "radio" && (
              <FormControl component="fieldset" className="mt-4" fullWidth>
                <FormLabel component="legend">
                  <Box className="items-center text-sm relative">
                    {label}{" "}
                    {item.info && (
                      <span className="absolute">
                        {" "}
                        <ToolTipIcon placement="right" title={item.info} />
                      </span>
                    )}
                  </Box>
                </FormLabel>
                <RadioGroup
                  row
                  className="mt-4"
                  style={{ fontSize: "13px" }}
                  // variant="outlined"
                  // fullWidth
                  value={updateFields[item.field]}
                  name={item.field}
                  placeholder="Select"
                  onChange={setFormValue}
                >
                  {item.select
                    ? item.select.map(
                        (selectItem: { label: string; value: any }) => (
                          <FormControlLabel
                            value={selectItem.value}
                            control={<Radio />}
                            label={selectItem.label}
                            labelPlacement="end"
                          />
                        )
                      )
                    : []}
                </RadioGroup>
              </FormControl>
            )}
            {item.type === "dollar" && (
              <FormControl component="fieldset" className="mt-4" fullWidth>
                <FormLabel component="legend">
                  <Box className="items-center text-sm">
                    {label}{" "}
                    {item.info && (
                      <span className="absolute">
                        <ToolTipIcon placement="right" title={item.info} />
                      </span>
                    )}
                  </Box>
                </FormLabel>
                <DollarTextField
                  className="mt-4"
                  style={{ fontSize: "13px" }}
                  variant="outlined"
                  fullWidth
                  name={item.field}
                  value={updateFields[item.field]}
                  placeholder="0"
                  onChange={setFormValue}
                />
              </FormControl>
            )}
            {item.type === "percent" && (
              <FormControl component="fieldset" className="mt-4" fullWidth>
                <FormLabel component="legend">
                  <Box className="items-center text-sm">
                    {label}{" "}
                    {item.info && (
                      <span className="absolute">
                        <ToolTipIcon placement="right" title={item.info} />
                      </span>
                    )}
                  </Box>
                </FormLabel>
                <PercentTextField
                  className="mt-4"
                  style={{ fontSize: "13px" }}
                  variant="outlined"
                  fullWidth
                  name={item.field}
                  value={updateFields[item.field]}
                  placeholder="0"
                  onChange={setFormValue}
                />
              </FormControl>
            )}
            {item.type === "string" && (
              <FormControl component="fieldset" className="mt-4" fullWidth>
                <FormLabel component="legend" className="">
                  <Box className="items-center text-sm">
                    {label}
                    {item.info && (
                      <span className="absolute">
                        <ToolTipIcon placement="right" title={item.info} />
                      </span>
                    )}
                  </Box>
                </FormLabel>
                <TextField
                  className="mt-4"
                  variant="outlined"
                  fullWidth
                  style={{ fontSize: "13px" }}
                  name={item.field}
                  value={updateFields[item.field]}
                  placeholder=""
                  onChange={setFormValue}
                  type="text"
                />
              </FormControl>
            )}
            {item.type === "zip" && (
              <FormControl component="fieldset" className="mt-4" fullWidth>
                <FormLabel component="legend" className="">
                  <Box className="items-center text-sm">
                    {label}
                    {item.info && (
                      <span className="absolute">
                        <ToolTipIcon placement="right" title={item.info} />
                      </span>
                    )}
                  </Box>
                </FormLabel>
                <TextField
                  className="mt-4"
                  variant="outlined"
                  error={zipError}
                  helperText={
                    zipError ? "Please enter a valid ZIP code." : undefined
                  }
                  fullWidth
                  style={{ fontSize: "13px" }}
                  name={item.field}
                  value={updateFields[item.field]}
                  placeholder=""
                  onChange={setFormValueWithType}
                  type="text"
                  onBlur={() => {
                    checkZip(updateFields[item.field]);
                  }}
                  onFocus={() => setZipError(false)}
                />
              </FormControl>
            )}
          </Grid>
        );
      })}
    </>
  );
};

export default Questionnaire;

const useStyles = makeStyles({
  item: {
    display: "flex",
    // alignItems: "stretch",
    justifyContent: "space-between",
    flexBasis: "0 auto 0",
    padding: "10px 15px",
    margin: "10px 0px",
    border: `1px solid ${colors.blueGray6}`,
  },
  editableArea: {
    cursor: "pointer",
    width: "calc(100% - 45px)",
  },
  retireEditArea: {
    cursor: "pointer",
    width: "100%",
  },
  removeArea: {
    width: "45px",
  },
  editRow: {
    display: "flex",
    // justifyContent: "space-between",
    // maxWidth: 120,
    alignItems: "center",
    // color: colors.blueGray4,
    "& p": {
      color: colors.textDisabled,
    },
    "& span": {
      color: colors.textDisabled,
    },
    color: "textDisabled",
    flexDirection: "row",
  },
  btnAddItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 30,
  },
  btnCancel: {
    width: "calc(100%)",
    marginBottom: "20px",
    // position: "absolute",
    // bottom: 20,
    // left: 0,
  },
  autoText: {
    height: 45,
    "&>div": {
      padding: "6px !important",
    },
    "&>legend": {
      visibility: "visible",
    },
  },
  textBody: {
    fontSize: "14px",
  },
  addedGoals: {
    display: "grid",
  },
  goalDetails: {
    flexDirection: "column",
  },
});
