import { SECTIONS } from "../common";

export const infoText = {
  [SECTIONS.SIDEINCOME]: [
    {
      header: `"Self-Employed" Income`,
      body: [
        `Lenders will not use income from being self-employed to qualify you for a loan if you haven't had the stated level of income for more than two years.`,
        `However, we look at this income differently.  We want to know how long you've had this income source and level of income to determine how risky it is.  If this income highly fluctuates year to year, then how much home you can afford decreases.`,
      ],
    },
    {
      header: `Did You Know?`,
      body: [
        `When qualifying for a loan, lenders consider 1099 income and side hustle income as "self-employed" income.  To be considered on your mortgage application, you'll have to show that minimum level of income on at least two tax returns to use for mortgage qualifications purposes.`,
        `For example, you reported $50,000 in 1099 income on your most recent tax return but only $10,000 in the previous year's tax return. You will not be able to use the full $50,000 on your mortgage application!`,
      ],
    },
  ],
  [SECTIONS.LANDING]: [
    {
      header: ``,
      body: [``],
    },
  ],
  [SECTIONS.INCOME]: [
    {
      header: `Income For Housing`,
      body: [
        `Income is used to determine if you can qualify for a house. However, there is much more to income than simply qualifying when it comes to what you can afford.`,
        `Different income types have different risks associated with them.  The more risky your income, the less home you can afford.`,
        `FitBUX's 'How Much Home Can I Afford' recommendation takes into account the types of income you have and the risks associated with each to determine how much home you can afford.`,
      ],
    },
  ],
  [SECTIONS.DEBT]: [
    {
      header: `Why Do Other Debt Payments Matter?`,
      body: [
        `In general, the greater your total required monthly payments are for your debts, the less home you can afford.`,
      ],
    },
  ],
  [SECTIONS.DOWNPAYMENT]: [
    {
      header: `Did You Know?`,
      body: [
        `Lenders usually require principal mortgage insurance ("PMI") if your down payment is less than 20% of the purchase price of your house. PMI increases your monthly payments and the total cost of home ownership.`,
      ],
    },
  ],
  [SECTIONS.RESULTS]: [
    {
      header: ``,
      body: [``],
    },
  ],
};
