import * as React from "react";
import { makeStyles } from "@material-ui/core";

import DashboardLayout from "src/layouts/DashboardLayout";
import { headerStyles } from "src/theme";
import { Content } from "./Content";

const useStyles = makeStyles(headerStyles);

const Solutions = () => {
  const classes = useStyles();
  return (
    <DashboardLayout pageTitle="Solutions">
      <main className={classes.mainContent}>
        <Content />
      </main>
    </DashboardLayout>
  );
};

export default Solutions;
