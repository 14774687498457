import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { DollarTextField } from "src/utils";
import Button from "src/components/Button";
import TitleCard from "src/components/TitleCard";
import { useRentVsBuy } from "../UseRentVsBuy";
import FbButton from "src/components/Button";

const RentInputScreen = () => {
  const classes = useStyles();
  const {
    rentInputData,
    handleChangeRentInputStep,
    handleNext,
    rentInputError,
    setRentInputError,
    handleBack,
  } = useRentVsBuy();

  return (
    <Box className={classes.root}>
      <TitleCard title="Rent Information">
        <Box className={classes.container}>
          <Typography className={classes.title}>
            Enter your current or expected rental details.
          </Typography>
          <Table className={classes.table}>
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell className={classes.wideCell}>
                  <Typography>
                    What is your current or expected rent amount?
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <DollarTextField
                    variant="outlined"
                    placeholder="$1000"
                    value={rentInputData.rent === 0 ? "" : rentInputData.rent}
                    name="rent"
                    onChange={handleChangeRentInputStep}
                    helperText={
                      rentInputError.rent
                        ? "Rent must be greater than $0."
                        : undefined
                    }
                    error={rentInputError.rent}
                    onFocus={() =>
                      setRentInputError((pre) => ({
                        ...pre,
                        rent: false,
                      }))
                    }
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.wideCell}>
                  <Typography>
                    What is your current or expected monthly renter's insurance.
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <DollarTextField
                    variant="outlined"
                    placeholder="$0"
                    value={
                      rentInputData.rentInsurance === 0
                        ? ""
                        : rentInputData.rentInsurance
                    }
                    name="rentInsurance"
                    onChange={handleChangeRentInputStep}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.wideCell}>
                  <Typography>
                    What ZIP code would the home be located in?
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <TextField
                    variant="outlined"
                    placeholder="78705"
                    name="zipCode"
                    value={rentInputData.zipCode}
                    onChange={handleChangeRentInputStep}
                    helperText={
                      rentInputError.zipCode
                        ? "Enter a valid ZIP code."
                        : undefined
                    }
                    error={rentInputError.zipCode}
                    onFocus={() =>
                      setRentInputError((pre) => ({
                        ...pre,
                        zipCode: false,
                      }))
                    }
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </TitleCard>
      <Box className={classes.actionContainer}>
        <FbButton onClick={handleBack}>Back</FbButton>
        <Button fbColor="primary" onClick={handleNext}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  title: {
    fontSize: 12,
  },
  table: {
    width: "95%",
    margin: "auto",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "10px",
  },
  wideCell: {
    border: 0,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0 !important",
  },
  cell: {
    border: 0,
    display: "flex",
    width: "200px",
    padding: "0 !important",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
  },
}));

export default RentInputScreen;
