import React from "react";
import { map } from "lodash";

import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  makeStyles,
  Tooltip,
} from "@material-ui/core";

import { GRADUATED_LIFE_EVENTS } from "src/interfaces/lifeEvents.interface";
import FbIcon from "src/components/Icon";
import colors from "src/theme/colors";
import shadows from "src/theme/shadows";
interface LifeEventCategoriesProps {
  onSelect: (type: string) => void;
}

const LifeEventCategories = ({ onSelect }: LifeEventCategoriesProps) => {
  const styles = useStyles();

  const getButton = (item: any, type: string, disabledButton?: boolean) => {
    if (disabledButton) {
      return (
        <Grid key={type} item xs={4}>
          <Tooltip title={item.toolTip || ""}>
            <Box>
              <Button
                disabled={item.disabled}
                fullWidth
                className={styles.disabledCard}
                onClick={() => onSelect(type)}
              >
                <FbIcon iconName={item.icon}></FbIcon>
                <Typography className={styles.cardText}>
                  {item.buttonLabel}
                </Typography>
              </Button>
            </Box>
          </Tooltip>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={4}>
          <Button
            fullWidth
            className={styles.card}
            onClick={() => onSelect(type)}
          >
            <FbIcon iconName={item.icon}></FbIcon>
            <Typography className={styles.cardText}>
              {item.buttonLabel}
            </Typography>
          </Button>
        </Grid>
      );
    }
  };

  return (
    <Container maxWidth="sm" className={styles.main}>
      <Grid container spacing={2}>
        {map(GRADUATED_LIFE_EVENTS, (item, type) =>
          getButton(item, type, item.disabled)
        )}
      </Grid>
    </Container>
  );
};

export default LifeEventCategories;

const useStyles = makeStyles({
  main: {
    padding: 50,
  },
  card: {
    boxShadow: shadows[2],
    height: 110,
    border: `1px solid ${colors.blueGray7}`,
    "&>span": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: colors.brandingBlue1,
      cursor: "pointer",
    },
  },
  disabledCard: {
    boxShadow: shadows[2],
    height: 110,
    border: `1px solid ${colors.blueGray7}`,
    backgroundColor: "#f6fbfd",
    "&>span": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: colors.brandingBlue1,
      cursor: "pointer",
    },
  },
  cardText: {
    fontSize: 13,
    color: colors.brandingBlue1,
    fontWeight: 500,
    textAlign: "center",
    marginTop: 5,
    height: 24,
  },
});
