import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getCurrentPortfolioApi,
  getPortfolioPerformanceApi,
  setCurrentPortfolioApi,
} from "src/apiService";

import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";

function* fetchCurrentPortfolio(): any {
  try {
    const portfolio: any = yield call(getCurrentPortfolioApi);
    yield put({ type: actions.GET_PORTFOLIO + SUCCESS, payload: portfolio });
  } catch (error) {
    yield put({ type: actions.GET_PORTFOLIO + FAIL, payload: error });
  }
}

function* setCurrentPortfolio({ payload }: any): any {
  try {
    yield call(setCurrentPortfolioApi, payload);
    yield put({ type: actions.GET_PORTFOLIO + SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.SET_PORTFOLIO + FAIL, payload: error });
  }
}

function* fetchPerformance(): any {
  try {
    const performance: any = yield call(getPortfolioPerformanceApi);
    yield put({
      type: actions.GET_PERFORMANCE + SUCCESS,
      payload: performance,
    });
  } catch (error) {
    yield put({ type: actions.GET_PERFORMANCE + FAIL, payload: error });
  }
}

export function* investmentsSagas() {
  yield all([
    takeLatest(actions.GET_PORTFOLIO + START, fetchCurrentPortfolio),
    takeLatest(actions.SET_PORTFOLIO + START, setCurrentPortfolio),
    takeLatest(actions.GET_PERFORMANCE + START, fetchPerformance),
  ]);
}
