import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import Button from "../Button";

export const ConfirmDialog = ({
  visible,
  title,
  message,
  confirmButton,
  cancelButton,
  onConfirm,
  onCancel,
}: any) => {
  return (
    <Dialog
      open={visible}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fbColor="secondary" onClick={onCancel}>
          {cancelButton || "No"}
        </Button>
        <Button fbColor="primary" onClick={onConfirm} autoFocus>
          {confirmButton || "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
