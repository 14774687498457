export const data = [
  {
    name: "Page A",
    value: 800,
  },
  {
    name: "Page B",
    value: 967,
  },
  {
    name: "Page C",
    value: 967,
  },
];

export const newPlanContent = {
  heading: "Stay on Top of Your Finances",
  description:
    "Our technology makes it easy to build a customized financial plan and implement it. Complete five easy steps to build your first plan.  You can then build multiple plans to compare. Once you decide on your plan, click 'Implement' and be on your way to financial peace of mind.",
  buttons: [{ color: "primary", text: "Build a New Plan" }],
};

export const tabLabels = ["This month", "Last Month", "Last 12 Months"];
