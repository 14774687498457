import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import Icon from "src/components/Icon";

const useStyles = makeStyles({
  text: { fontSize: 14 },
  buttonContainer: {
    margin: "40px auto",
    display: "flex",
    justifyContent: "center",
  },
  checkRow: {
    height: 50,
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  checkIcon: {
    marginRight: 10,
  },
  stackedText: {},
});

const Instructions = ({ next }: any) => {
  const styles = useStyles();

  return (
    <>
      <Card title="Make Life Easy">
        <Box className="mt-4">
          <Typography paragraph className={styles.text}>
            This application uses Spinwheel to link your student loan accounts.
            This allows us to see your payment history and loan balances in
            "read only" mode.
          </Typography>
          <Box className={styles.checkRow}>
            <Icon
              color="primary"
              iconName="fb-checkmark"
              className={styles.checkIcon}
            />
            <Box className={styles.stackedText}>
              <Typography className={styles.text}>Secure</Typography>
              <Typography className={styles.text}>
                Transfer of your loan data is encrypted end-to-end.
              </Typography>
            </Box>
          </Box>
          <Box className={styles.checkRow}>
            <Icon
              color="primary"
              iconName="fb-checkmark"
              className={styles.checkIcon}
            />
            <Box className={styles.stackedText}>
              <Typography className={styles.text}>Private</Typography>
              <Typography className={styles.text}>
                This application will never be able to access your log-in
                credentials.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
      <Box className={styles.buttonContainer}>
        <Button fbColor="primary" onClick={next}>
          Continue
        </Button>
      </Box>
    </>
  );
};

export default Instructions;
