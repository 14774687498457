import React from "react";

import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";

import { PercentTextField } from "src/utils";

export const RefinanceDetails = ({
  goBack,
  onNext,
  formValues,
  handleChange,
  availableTerms,
}: any) => {
  return (
    <Card style={{ maxWidth: 900, margin: "0 auto" }}>
      <Typography variant="h2" component="h2">
        Enter Your Student Loan Refinance Details
      </Typography>
      <Typography variant="body1">
        If you don't have an offer yet, then check our partners to get your rate
        by clicking the button in the above header.
      </Typography>
      <TableContainer className="max-w-lg mx-auto">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Term in Years</TableCell>
              <TableCell>Interest Rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Refinance Offer</TableCell>
              <TableCell>
                <Select
                  name="refinanceTerm"
                  variant="outlined"
                  onChange={handleChange}
                  value={formValues.refinanceTerm}
                >
                  {availableTerms.map((term: number) => (
                    <MenuItem key={term} value={"" + term}>
                      {term}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <PercentTextField
                  name="refinanceRate"
                  variant="outlined"
                  onChange={handleChange}
                  value={formValues.refinanceRate || ""}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        fullWidth
        className="my-4"
        fbColor="primary"
        disabled={!formValues.refinanceRate}
        onClick={onNext}
      >
        Continue
      </Button>
      <Button fbColor="secondary" onClick={goBack} fullWidth>
        Back
      </Button>
    </Card>
  );
};

export default RefinanceDetails;
