import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { planBuilderStyles } from "src/theme";
import Card from "src/components/Card";
import { ReviewSections } from "../Views/Review/ReviewViews/common";
import { getReviewSection } from "src/store/planBuild/selector";
import { useSelector } from "react-redux";
import { getHeaderClicked } from "src/store/dashboard/selector";
import PingEffect from "src/components/PingAnimation";

const useStyles = makeStyles(planBuilderStyles);
const steps = [
  {
    label: "1. Your FitBUX Score",
    subStep: ReviewSections.SCORE,
  },
  {
    label: "2. Goal Summary",
    subStep: ReviewSections.GOALSUMMARY,
  },
  {
    label: "3. Assets/Investments",
    subStep: ReviewSections.ASSETSINVESTMENTS,
  },
  {
    label: "4. Debts",
    subStep: ReviewSections.DEBTS,
  },
  {
    label: "5. Net Wealth Summary",
    subStep: ReviewSections.NETWEALTH,
  },
  {
    label: "6. Pay Check Allocation",
    subStep: ReviewSections.PAYCHECKALLOCATION,
  },
];

const optimizedSteps = [
  {
    label: "1. Your FitBUX Score",
    subStep: ReviewSections.SCORE,
  },
  {
    label: "2. Goal Summary",
    subStep: ReviewSections.GOALSUMMARY,
  },
  {
    label: "3. Pay Check Allocation",
    subStep: ReviewSections.PAYCHECKALLOCATION,
  },
  {
    label: "4. Assets/Investments",
    subStep: ReviewSections.ASSETSINVESTMENTS,
  },
  {
    label: "5. Debts",
    subStep: ReviewSections.DEBTS,
  },
  {
    label: "6. Net Wealth Summary",
    subStep: ReviewSections.NETWEALTH,
  },
];

const ReviewSidebar = ({
  handleClick,
  handleEdit,
  optimized,
}: {
  handleClick?: any;
  handleEdit?: any;
  optimized?: boolean;
}) => {
  const styles = useStyles();
  const reviewSection = useSelector(getReviewSection);
  const headerClickCount = useSelector(getHeaderClicked);
  const [triggerCount, setTriggerCount] = useState(headerClickCount);
  const stepCollection = optimized ? optimizedSteps : steps;

  // if triggerAnimation is updated, run the animation
  useEffect(() => {
    if (headerClickCount !== triggerCount) {
      setTriggerCount(headerClickCount);
    }
  }, [headerClickCount]);

  return (
    <Card
      className={styles.sidebar}
      title={"Plan Navigation"}
      noPadding
      titleTag="h6"
    >
      <Divider />
      <Box className={styles.leftCenter}>
        {stepCollection.map((step: any, index) => {
          const className = `${styles.step} ${styles.completedStep} ${
            reviewSection - 1 === index ? styles.activeReviewStep : ""
          }`;
          const onItemClick: any = () => {
            if (handleClick) {
              handleClick(step.subStep);
            }
          };
          return (
            <Typography
              key={step.label}
              variant="body1"
              className={className}
              onClick={onItemClick}
            >
              {step.label}
            </Typography>
          );
        })}
      </Box>
      <Box className="flex justify-center">
        <Box className="relative">
          <PingEffect trigger={headerClickCount !== triggerCount} />
          <Button
            className="relative z-40"
            color="primary"
            onClick={() => {
              if (handleEdit) {
                handleEdit();
              }
            }}
          >
            Edit This Plan
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default ReviewSidebar;
