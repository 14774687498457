import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { Box, CircularProgress, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import Icon from "src/components/Icon";
import { getAccountsLoading } from "src/store/account/selector";
import { getIsMarried } from "src/store/system/selector";

import useStyles from "./styles";

const LeftSidebar = ({
  activeProviderId,
  activeProvider,
  fixMode,
  openLinkDialog,
  providers,
  selectProvider,
  close,
}: any) => {
  const styles = useStyles();
  const isMarried = useSelector(getIsMarried);
  const accountsLoading = useSelector(getAccountsLoading);

  const sortedProviders = useMemo(() => {
    const filteredProviders = fixMode
      ? providers.filter((provider: any) => !!provider.error)
      : [...providers];
    return filteredProviders.sort((a: any, b: any) => {
      if (a.name === b.name) {
        return a.accounts - b.accounts;
      }
      if (a.name > b.name) {
        return 1;
      }
      return -1;
    });
  }, [providers, fixMode]);

  useEffect(() => {
    if (!activeProviderId && sortedProviders.length) {
      selectProvider(sortedProviders[0].item_id);
    }
  }, [activeProviderId, sortedProviders]);

  useEffect(() => {
    if (fixMode && !sortedProviders.length) {
      close();
    }
  }, [fixMode, sortedProviders, close]);

  return (
    <Card
      className={styles.sidebar}
      title={
        fixMode
          ? "Fix Your Linked Financial Institutions"
          : "Linked Financial Institutions"
      }
      noPadding
      titleTag="h6"
      divider
    >
      <Box className={styles.leftCenter}>
        {accountsLoading && (
          <Box className="w-full flex justify-center">
            <CircularProgress size={40} />
          </Box>
        )}
        {!accountsLoading &&
          sortedProviders.map((provider: any) => {
            const nAccounts = provider.accounts;
            const classes = [styles.step];
            if (activeProviderId === provider.item_id) {
              if (provider.error) {
                classes.push(styles.activeWarningStep);
              } else {
                classes.push(styles.activeStep);
              }
            } else if (provider.error) {
              classes.push(styles.inactiveWarningStep);
            } else {
              classes.push(styles.inactiveStep);
            }
            const className = classes.join(" ");
            return (
              <Box className={className}>
                <Icon
                  iconName={provider.error ? "fa-link-slash" : "fa-link"}
                  className="mr-2"
                />
                <Typography
                  key={provider.item_id}
                  variant="body1"
                  onClick={() => selectProvider(provider.item_id)}
                  className={
                    activeProviderId === provider.item_id
                      ? "font-semibold"
                      : "font-normal"
                  }
                >
                  {provider.name}
                  {!!nAccounts &&
                    ` (${nAccounts} account${nAccounts !== 1 ? "s" : ""})`}
                </Typography>
              </Box>
            );
          })}
      </Box>
      {/* <Divider /> */}
      {!fixMode && (
        <Box className={styles.leftBottom}>
          <Button
            variant="contained"
            fbColor="primary"
            onClick={() => openLinkDialog("applicant")}
            disabled={activeProvider?.error}
          >
            Add New Link{isMarried ? " (Mine)" : ""}
          </Button>
          {isMarried && (
            <Button
              className="mt-4"
              variant="contained"
              fbColor="primary"
              onClick={() => openLinkDialog("spouse")}
              disabled={activeProvider?.error}
            >
              Add New Link (Spouse)
            </Button>
          )}
        </Box>
      )}
    </Card>
  );
};

export default LeftSidebar;
