import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, makeStyles, Typography } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { openUpgradeDialog } from "src/store/system/actions";
import { getIsSubscribed } from "src/store/system/selector";
import Button from "src/components/Button";
import { colors } from "src/theme";
import TitleCard from "src/components/TitleCard";

const useStyles = makeStyles({
  mainContainer: {
    height: "100%",
  },
  transitions: {
    display: "flex",
    justifyContent: "center",
  },
});

const Landing = ({
  transition,
}: {
  transition: (
    next?: boolean,
    showResults?: boolean,
    incomeData?: any[]
  ) => void;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const subscribed = useSelector(getIsSubscribed);
  const Free = () => (
    <Box className={classes.mainContainer}>
      <TitleCard title="What This Recommendation Provides">
        <Typography>
          NOTE: This is only available to premium members.
          <br />
          <br />
          Qualifying for a home and being able to afford one are not the same
          thing.
          <br />
          <br />
          This recommendation shows you how much home you can afford TODAY and
          risk factors associated with taking on additional debt.
        </Typography>
      </TitleCard>
      <Box className={classes.transitions}>
        <Button
          onClick={() => dispatch(openUpgradeDialog())}
          className="text-white"
          color="primary"
        >
          Upgrade to Premium
        </Button>
      </Box>
    </Box>
  );

  const Paid = () => (
    <Box className={classes.mainContainer}>
      <TitleCard title="What This Recommendation Provides">
        <Typography>
          Qualifying for a home and being able to afford one are not the same
          thing.
          <br />
          <br />
          This recommendation shows you how much home you can afford TODAY and
          risk factors associated with taking on additional debt.
          <br />
          <br />
          To use this, be sure your profile is up-to-date first (i.e., income,
          debt, payments, etc.).
          <br />
          <br />
          For more information or if you need help, click the{" "}
          <span>
            <HelpOutlineIcon style={{ color: colors.brandingBlue1 }} />
          </span>{" "}
          icon.
        </Typography>
      </TitleCard>
      <Box className={classes.transitions}>
        <Button onClick={() => transition()} color="primary">
          Next
        </Button>
      </Box>
    </Box>
  );

  return subscribed ? <Paid /> : <Free />;
};

export default Landing;
