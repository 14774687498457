import React from "react";
import { makeStyles } from "@material-ui/core";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import colors from "src/theme/colors";
import shadows from "src/theme/shadows";

interface IState {
  title: string;
  btnLabel?: string;
  iconName?: string;
  children?: React.ReactNode;
  _handleClick?: () => void;
}

const InformationCard = ({
  title,
  btnLabel,
  iconName,
  _handleClick,
  children,
}: IState) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <p className={styles.title}>{title}</p>
        {btnLabel && (
          <Button
            variant="outlined"
            fbColor="primary"
            onClick={_handleClick}
            className={`w-max ${styles.button}`}
            endIcon={iconName && <Icon iconName={iconName} />}
          >
            {btnLabel}
          </Button>
        )}
      </div>
      {children}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    borderRadius: 28,
    background: colors.white,
    boxShadow: shadows[4],
    padding: 24,
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingBottom: 16,
    borderBottom: `1px solid ${colors.blueGray7}`,
  },
  title: {
    color: colors.brandingBlue1,
    fontSize: 20,
    fontWeight: 600,
    margin: 0,
  },
  button: {
    width: "100%",
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
});
export default InformationCard;
