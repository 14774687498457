import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { identity } from "lodash";
import { setPopup } from "src/store/dashboard/actions";

import {
  Box,
  Checkbox,
  Container,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";

import {
  fetchSavedPlanProjections,
  loadComparisonPlan,
  setBuildStep,
  setCurrentPlan,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  getLoadedSavedProjections,
  getLoadingSavedProjections,
  getPlans,
  getSavedProjectionsError,
} from "src/store/planBuild/selector";

import Button from "src/components/Button";

const useStyles = makeStyles({
  img: {
    maxHeight: 250,
    maxWidth: 250,
    "& img": {
      width: "100%",
    },
  },
  cell: {
    border: 0,
    padding: 4,
  },
  table: {
    marginBottom: 20,
    marginTop: 60,
    width: 400,
  },
});

const ComparePlanList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loadedProjections = useSelector(getLoadedSavedProjections);
  const loadingProjections = useSelector(getLoadingSavedProjections);
  const projectionsError = useSelector(getSavedProjectionsError);
  const plans = useSelector(getPlans);
  const [compareSelections, setCompareSelections] = useState([
    false,
    false,
    false,
  ]);

  useEffect(() => {
    if (!loadingProjections && !loadedProjections && !projectionsError) {
      dispatch(fetchSavedPlanProjections());
    }
  }, [loadedProjections, loadingProjections, projectionsError]);

  const toggleSelected = (index: number) => {
    const newCompareSelections = [...compareSelections];
    newCompareSelections[index] = !newCompareSelections[index];
    setCompareSelections(newCompareSelections);
  };

  const launchCompare = () => {
    let firstIndex = 0;
    let secondIndex = 1;
    if (!compareSelections[0]) {
      firstIndex = 1;
      secondIndex = 2;
    } else if (!compareSelections[1]) {
      secondIndex = 2;
    }
    const firstPlan = plans[firstIndex];
    dispatch(
      setCurrentPlan({ planId: firstPlan.id, index: firstIndex, keepId: false })
    );
    dispatch(loadComparisonPlan(secondIndex));
    dispatch(setBuildStep(PLAN_BUILD_STEPS.COMPARE));
    dispatch(push("/plan-builder"));
    dispatch(setPopup("Compare"));
  };

  // const launchCompareV2 = () => {
  //   let firstIndex = 0;
  //   let secondIndex = 1;
  //   if (!compareSelections[0]) {
  //     firstIndex = 1;
  //     secondIndex = 2;
  //   } else if (!compareSelections[1]) {
  //     secondIndex = 2;
  //   }
  //   const firstPlan = plans[firstIndex];
  //   dispatch(
  //     setCurrentPlan({ planId: firstPlan.id, index: firstIndex, keepId: false })
  //   );
  //   dispatch(loadComparisonPlan(secondIndex));
  //   dispatch(push("/plan-comparison"));
  // };

  const compareReady = compareSelections.filter(identity).length === 2;

  return (
    <Container className="py-48 flex justify-around">
      <Box className="w-2/3">
        <Typography variant="h2" component="h2" className="mb-3">
          Compare Plans
        </Typography>
        <Typography component="p" variant="body2">
          Select the 2 plans you would like to compare.
        </Typography>
        <Table className={classes.table}>
          <TableRow>
            <TableCell className={classes.cell} />
            <TableCell className={classes.cell}>Select</TableCell>
          </TableRow>
          {plans.map((plan, index) => (
            <TableRow key={plan.id}>
              <TableCell className={classes.cell}>{plan.name}</TableCell>
              <TableCell className={classes.cell}>
                <Checkbox
                  checked={compareSelections[index]}
                  disabled={compareReady && !compareSelections[index]}
                  onClick={() => toggleSelected(index)}
                />
              </TableCell>
            </TableRow>
          ))}
        </Table>
        <Button
          fbColor="primary"
          disabled={!compareReady}
          onClick={launchCompare}
        >
          Compare Plans
        </Button>
      </Box>
      <Box className={classes.img}>
        <img src="/assets/images/png/map.png" alt="rocket" />
      </Box>
    </Container>
  );
};

export default ComparePlanList;
