import React from "react";
import { identity } from "lodash";

import {
  Box,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import Card from "src/components/Card";
import Checkbox from "src/components/Checkbox";
import TextField from "src/components/TextField";
import { InputDefinition } from "src/interfaces";
import { colors } from "src/theme";
import { DollarTextField, PhoneNumberInput } from "src/utils";

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  innerContainer: {
    border: `1px solid ${colors.lightBorder}`,
    borderRadius: 12,
    marginTop: 30,
    padding: 12,
    width: "calc(50% - 20px)",
    boxShadow: theme.shadows[3],
  },
  innerTitle: {
    fontSize: 16,
  },
  inputLabel: {},
  inputRow: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    marginTop: 15,
    marginBottom: 15,
  },
  input: {
    margin: 0,
    width: 250,
  },
}));

interface TwoColumnInputCardProps {
  title: string;
  innerTitle1: string;
  innerTitle2?: string;
  inputs1: InputDefinition[];
  inputs2?: InputDefinition[];
  formValues: { [key: string]: any };
  setFormValues: (args: any) => void;
  errors?: Set<string>;
  setErrors?: (changeFn: (current: Set<string>) => Set<string>) => void;
  formatters?: any;
  validators?: any;
}

const TwoColumnInputCard = ({
  title,
  innerTitle1,
  innerTitle2,
  inputs1,
  inputs2,
  formValues,
  setFormValues,
  errors,
  setErrors,
  formatters,
  validators,
}: TwoColumnInputCardProps) => {
  const styles = useStyles();

  const addError = (field: string) => {
    if (!setErrors) {
      return;
    }
    setErrors((current) => {
      const newErrors = new Set(current);
      newErrors.add(field);
      return newErrors;
    });
  };

  const clearError = (field: string) => {
    if (!setErrors) {
      return;
    }
    setErrors((current) => {
      const newErrors = new Set(current);
      newErrors.delete(field);
      return newErrors;
    });
  };

  const handleChange = (e: any) => {
    const field = e.target.name;
    const formatter =
      formatters && formatters[field] ? formatters[field] : identity;
    const value = formatter(e.target.value);
    const validator = validators?.[field] || null;
    const isValid = !validator || validator(value);
    if (!isValid) {
      addError(field);
    } else {
      clearError(field);
    }
    setFormValues((current: any) => ({
      ...current,
      [field]: value,
    }));
  };

  const handleToggle = (name: string) => () => {
    setFormValues((current: any) => {
      const newValue = current[name] === "y" ? "n" : "y";
      return { ...current, [name]: newValue };
    });
  };

  const makeInput = (item: any) => {
    switch (item.type) {
      case "text":
      case "number":
        return (
          <TextField
            className={styles.input}
            error={errors?.has(item.name)}
            value={formValues[item.name]}
            onChange={handleChange}
            name={item.name}
            variant="outlined"
          />
        );
      case "dollars":
        return (
          <DollarTextField
            className={styles.input}
            error={errors?.has(item.name)}
            type="text"
            value={formValues[item.name]}
            onChange={handleChange}
            name={item.name}
            variant="outlined"
          />
        );
      case "phone":
        return (
          <PhoneNumberInput
            className={styles.input}
            error={errors?.has(item.name)}
            value={formValues[item.name]}
            onChange={handleChange}
            name={item.name}
            variant="outlined"
          />
        );
      case "select":
        return (
          <Select
            className={styles.input}
            error={errors?.has(item.name)}
            value={formValues[item.name]}
            name={item.name}
            onChange={handleChange}
            variant="outlined"
          >
            {item.values.map((selectValue: any) => {
              const value =
                typeof selectValue === "string"
                  ? selectValue
                  : selectValue.value;
              const label =
                typeof selectValue === "string"
                  ? selectValue
                  : selectValue.label;
              return (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        );
      case "toggle":
        return (
          <Checkbox
            checked={formValues[item.name] === "y"}
            color="primary"
            onChange={handleToggle(item.name)}
          />
        );
    }
  };

  return (
    <Card title={title}>
      <Box className={styles.outerContainer}>
        <Box className={styles.innerContainer}>
          <Typography variant="h2" className={styles.innerTitle}>
            {innerTitle1}
          </Typography>
          {inputs1.map((item) => (
            <Box key={item.name} className={styles.inputRow}>
              <Typography className={styles.inputLabel}>
                {item.label}
              </Typography>
              {makeInput(item)}
            </Box>
          ))}
        </Box>
        {(!!innerTitle2 || !!inputs2) && (
          <Box className={styles.innerContainer}>
            <Typography variant="h2" className={styles.innerTitle}>
              {innerTitle2 || ""}
            </Typography>
            {(inputs2 || []).map((item) => (
              <Box key={item.name} className={styles.inputRow}>
                <Typography className={styles.inputLabel}>
                  {item.label}
                </Typography>
                {makeInput(item)}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default TwoColumnInputCard;
