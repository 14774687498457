import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import { AdapterLink } from "src/utils";
dayjs.extend(relativeTime);

const NoGraduationEdit = ({ goBack }: any) => {
  const styles = useStyles();

  return (
    <>
      <Typography variant="body1" className={styles.text}>
        You have already implemented your plan. Therefore, the only way to
        change your graduation date is to build a new plan. You may do so by
        clicking the button below.
      </Typography>
      <Button
        component={AdapterLink}
        fbColor="primary"
        to="/plan#list"
        className={styles.btn}
      >
        Build a New Plan
      </Button>
      <Button
        variant="outlined"
        fbColor="primary"
        onClick={goBack}
        className={styles.btnCancel}
      >
        Cancel
      </Button>
    </>
  );
};

export default NoGraduationEdit;

const useStyles = makeStyles({
  btn: {
    width: "calc(100% - 50px)",
  },
  btnCancel: {
    width: "calc(100% - 50px)",
    position: "absolute",
    bottom: 20,
    left: 25,
  },
  text: {
    margin: "15px 0 30px",
    fontSize: 16,
  },
});
