import { PROFILE_BUILD_STEPS } from "src/store/oldProfileBuild/constants";

import Intro from "./Intro";
import Overview from "./MainForm";
import Education from "./EducationForm";
import Career from "./CareerForm";
import OtherHC from "./OtherHCForm";

const humanCapitalViews = {
  [PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO_1]: Intro,
  [PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO_2]: Intro,
  [PROFILE_BUILD_STEPS.HUMAN_CAPITAL_OVERVIEW]: Overview,
  [PROFILE_BUILD_STEPS.MY_EDUCATION]: Education,
  [PROFILE_BUILD_STEPS.SPOUSE_EDUCATION]: Education,
  [PROFILE_BUILD_STEPS.MY_CAREER]: Career,
  [PROFILE_BUILD_STEPS.SPOUSE_CAREER]: Career,
  [PROFILE_BUILD_STEPS.MY_OTHER_HUMAN_CAPITAL]: OtherHC,
  [PROFILE_BUILD_STEPS.SPOUSE_OTHER_HUMAN_CAPITAL]: OtherHC,
};

export default humanCapitalViews;
