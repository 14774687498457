export const data = [
  {
    name: "Page A",
    value: 800,
  },
  {
    name: "Page B",
    value: 967,
  },
  {
    name: "Page C",
    value: 967,
  },
];

export const newPlanContent = {
  heading: "Financial Independence Starts Today!",
  description:
    "Now that you completed your FitBUX profile, you can easily build a budget and see how you are currently allocating your day-to-day money. Next, you can build and compare multiple financial plans, set your goals, and visualize the optimal way to reach them.",
  subscribedDescription:
    "As a Premium Member, you can now link your financial accounts to make it even easier to create, implement and track your day-to-day money. Be sure to link your accounts or manually enter your transaction details so you can crush your goals!",
  buttons: [{ color: "primary", text: "Get Started" }],
};

export const tabLabels = ["This month", "Last Month", "Last 12 Months"];
