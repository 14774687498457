import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import FbRadio from "src/components/Radio";

import { ViewComponent } from "src/interfaces/viewComponent.interface";
import { getScore, setRefreshNeeded } from "src/store/dashboard/actions";
import {
  updateOther,
  UpdateOtherHumanCapitalPayload,
  setProfileStep,
} from "src/store/oldProfileBuild/actions";
import { OtherSection } from "src/interfaces";

import {
  getOtherHC,
  getProfileLoading,
  getSpouseOtherHC,
} from "src/store/profileBuild/selector";
import { getCurrentStep } from "src/store/oldProfileBuild/selector";
import { OTHER_HC_KEYS } from "src/store/profileBuild/selectorKeys";
import { pick } from "lodash";
import {
  LANGUAGE_LEVEL,
  PROFILE_BUILD_STEPS,
  PROFILE_TYPE,
} from "src/store/oldProfileBuild/constants";

const stepTitles = [
  "Undergrad & Previous",
  "Language",
  "Skill Sets",
  "Financial Proactiveness",
  "Extracurricular",
  "Military and Volunteer",
];
interface FormValues extends OtherSection {
  changed?: boolean;
  lang_num?: number;
  step?: number;
}

const OtherHCForm: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const isMine: boolean =
    useSelector(getCurrentStep) === PROFILE_BUILD_STEPS.MY_OTHER_HUMAN_CAPITAL;
  const otherHC = useSelector(isMine ? getOtherHC : getSpouseOtherHC);
  const loading = useSelector(getProfileLoading);

  const [formValues, setFormValues] = useState<FormValues>();

  useEffect(() => {
    setFormValues({
      ...otherHC,
      changed: false,
      lang_num:
        otherHC.lang === "y" ? (otherHC.lang_1 && otherHC.lang_2 ? 2 : 1) : 0,
      step: 0,
    });
  }, [otherHC]);

  const handleChange = (prop: keyof FormValues) => (
    event: React.ChangeEvent<any>
  ) => {
    const value = event.target.value;
    setFormValues({ ...formValues, [prop]: value });
  };

  if (!render) {
    return <div />;
  }

  return render({
    component: (
      <Container className="pb-32 p-0">
        <Card className="rounded-3xl" style={{ minHeight: 500 }}>
          <CardContent>
            <Typography
              component="h5"
              variant="body1"
              className="text-blue font-bold text-lg mb-2"
            >
              Add {isMine ? "My" : "Spouse"} Other Human Capital
            </Typography>
            <Typography
              component="p"
              variant="body1"
              className="text-primary font-medium text-md mb-3"
            >
              {stepTitles[(formValues?.step || 0) + 1]}
            </Typography>
            <Divider className="mb-3"></Divider>
            <Container maxWidth="xs" className="ml-0 mt-8">
              <Grid container spacing={3}>
                {formValues?.step === 0 && (
                  <>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Do you speak or read other languages?
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.lang || "n"}
                          className="flex flex-row"
                          onChange={handleChange("lang")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<FbRadio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<FbRadio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    {formValues?.lang === "y" && (
                      <Grid item xs={12}>
                        <FormControl component="fieldset" fullWidth>
                          <FormLabel component="legend" className="mb-4 h-4">
                            How many other languages do you speak or read?
                          </FormLabel>
                          <Select
                            variant="outlined"
                            fullWidth
                            value={formValues?.lang_num || 1}
                            placeholder="Number of Languages"
                            onChange={handleChange("lang_num")}
                          >
                            <MenuItem value={1} key="1">
                              1
                            </MenuItem>
                            <MenuItem value={2} key="2">
                              2 or more
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    {formValues?.lang === "y" &&
                      (formValues?.lang_num === 1 ||
                        formValues?.lang_num === 2) && (
                        <>
                          <Grid item xs={12}>
                            <Divider></Divider>
                          </Grid>
                          <Grid item xs={12} className="mb-3">
                            <FormLabel component="legend" className="mb-4 h-4">
                              Language #1
                            </FormLabel>
                            <TextField
                              label="Language"
                              fullWidth
                              variant="outlined"
                              onChange={handleChange("lang_1")}
                              value={formValues?.lang_1}
                            />
                          </Grid>
                          <Grid item xs={12} className="mb-3">
                            <FormControl component="fieldset">
                              <FormLabel component="legend" className="mb-4">
                                Speaking
                              </FormLabel>
                              <RadioGroup
                                value={
                                  formValues?.lang_speaking_1 ||
                                  LANGUAGE_LEVEL.BEGINNER
                                }
                                className="flex flex-row"
                                onChange={handleChange("lang_speaking_1")}
                              >
                                <FormControlLabel
                                  value={LANGUAGE_LEVEL.BEGINNER}
                                  control={<FbRadio />}
                                  label="Beginner"
                                />
                                <FormControlLabel
                                  value={LANGUAGE_LEVEL.PROFICIENT}
                                  control={<FbRadio />}
                                  label="Proficient"
                                />
                                <FormControlLabel
                                  value={LANGUAGE_LEVEL.FLUENT}
                                  control={<FbRadio />}
                                  label="Fluent"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} className="mb-3">
                            <FormControl component="fieldset">
                              <FormLabel component="legend" className="mb-4">
                                Reading
                              </FormLabel>
                              <RadioGroup
                                value={
                                  formValues?.lang_reading_1 ||
                                  LANGUAGE_LEVEL.BEGINNER
                                }
                                className="flex flex-row"
                                onChange={handleChange("lang_reading_1")}
                              >
                                <FormControlLabel
                                  value={LANGUAGE_LEVEL.BEGINNER}
                                  control={<FbRadio />}
                                  label="Beginner"
                                />
                                <FormControlLabel
                                  value={LANGUAGE_LEVEL.PROFICIENT}
                                  control={<FbRadio />}
                                  label="Proficient"
                                />
                                <FormControlLabel
                                  value={LANGUAGE_LEVEL.FLUENT}
                                  control={<FbRadio />}
                                  label="Fluent"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} className="mb-3">
                            <FormControl component="fieldset">
                              <FormLabel component="legend" className="mb-4">
                                Speaking
                              </FormLabel>
                              <RadioGroup
                                value={
                                  formValues?.lang_writing_1 ||
                                  LANGUAGE_LEVEL.BEGINNER
                                }
                                className="flex flex-row"
                                onChange={handleChange("lang_writing_1")}
                              >
                                <FormControlLabel
                                  value={LANGUAGE_LEVEL.BEGINNER}
                                  control={<FbRadio />}
                                  label="Beginner"
                                />
                                <FormControlLabel
                                  value={LANGUAGE_LEVEL.PROFICIENT}
                                  control={<FbRadio />}
                                  label="Proficient"
                                />
                                <FormControlLabel
                                  value={LANGUAGE_LEVEL.FLUENT}
                                  control={<FbRadio />}
                                  label="Fluent"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                    {formValues?.lang === "y" && formValues?.lang_num === 2 && (
                      <>
                        <Grid item xs={12}>
                          <Divider></Divider>
                        </Grid>
                        <Grid item xs={12} className="mb-3">
                          <FormLabel component="legend" className="mb-4 h-4">
                            Language #2
                          </FormLabel>
                          <TextField
                            label="Language"
                            fullWidth
                            variant="outlined"
                            onChange={handleChange("lang_2")}
                            value={formValues?.lang_2}
                          />
                        </Grid>
                        <Grid item xs={12} className="mb-3">
                          <FormControl component="fieldset">
                            <FormLabel component="legend" className="mb-4">
                              Speaking
                            </FormLabel>
                            <RadioGroup
                              value={
                                formValues?.lang_speaking_2 ||
                                LANGUAGE_LEVEL.BEGINNER
                              }
                              className="flex flex-row"
                              onChange={handleChange("lang_speaking_2")}
                            >
                              <FormControlLabel
                                value={LANGUAGE_LEVEL.BEGINNER}
                                control={<FbRadio />}
                                label="Beginner"
                              />
                              <FormControlLabel
                                value={LANGUAGE_LEVEL.PROFICIENT}
                                control={<FbRadio />}
                                label="Proficient"
                              />
                              <FormControlLabel
                                value={LANGUAGE_LEVEL.FLUENT}
                                control={<FbRadio />}
                                label="Fluent"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} className="mb-3">
                          <FormControl component="fieldset">
                            <FormLabel component="legend" className="mb-4">
                              Reading
                            </FormLabel>
                            <RadioGroup
                              value={
                                formValues?.lang_reading_2 ||
                                LANGUAGE_LEVEL.BEGINNER
                              }
                              className="flex flex-row"
                              onChange={handleChange("lang_reading_2")}
                            >
                              <FormControlLabel
                                value={LANGUAGE_LEVEL.BEGINNER}
                                control={<FbRadio />}
                                label="Beginner"
                              />
                              <FormControlLabel
                                value={LANGUAGE_LEVEL.PROFICIENT}
                                control={<FbRadio />}
                                label="Proficient"
                              />
                              <FormControlLabel
                                value={LANGUAGE_LEVEL.FLUENT}
                                control={<FbRadio />}
                                label="Fluent"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} className="mb-3">
                          <FormControl component="fieldset">
                            <FormLabel component="legend" className="mb-4">
                              Speaking
                            </FormLabel>
                            <RadioGroup
                              value={
                                formValues?.lang_writing_2 ||
                                LANGUAGE_LEVEL.BEGINNER
                              }
                              className="flex flex-row"
                              onChange={handleChange("lang_writing_2")}
                            >
                              <FormControlLabel
                                value={LANGUAGE_LEVEL.BEGINNER}
                                control={<FbRadio />}
                                label="Beginner"
                              />
                              <FormControlLabel
                                value={LANGUAGE_LEVEL.PROFICIENT}
                                control={<FbRadio />}
                                label="Proficient"
                              />
                              <FormControlLabel
                                value={LANGUAGE_LEVEL.FLUENT}
                                control={<FbRadio />}
                                label="Fluent"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {formValues?.step === -1 && (
                  <Grid item xs={12}>
                    <FormControl component="fieldset" className="mt-4">
                      <FormLabel component="legend" className="mb-4">
                        Were you enrolled in a gifted and talented program in
                        elementry school?
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_gifted_and_talented}
                        className="flex flex-row"
                        onChange={handleChange("qual_gifted_and_talented")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" className="mt-4">
                      <FormLabel component="legend" className="mb-4">
                        Did you graduate from an international baccalaureate
                        program?
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_intl_bacc}
                        className="flex flex-row"
                        onChange={handleChange("qual_intl_bacc")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" className="mt-4">
                      <FormLabel component="legend" className="mb-4">
                        Were you an AP scholar?
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_ap_scholar}
                        className="flex flex-row"
                        onChange={handleChange("qual_ap_scholar")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" className="mt-4">
                      <FormLabel component="legend" className="mb-4">
                        Were you offered a merit or academic scholarship for
                        college?
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_scholarship}
                        className="flex flex-row"
                        onChange={handleChange("qual_scholarship")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" className="mt-4">
                      <FormLabel component="legend" className="mb-4">
                        Were you offered a scholarship that covered your entire
                        tuition, costs, and fees for college?
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_full_scholarship}
                        className="flex flex-row"
                        onChange={handleChange("qual_full_scholarship")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
                {formValues?.step === 1 && (
                  <Grid item xs={12}>
                    <FormControl
                      component="fieldset"
                      fullWidth
                      className="mt-2"
                    >
                      <FormLabel component="legend" className="mb-3">
                        Electronics/electrical
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_electronics}
                        className="flex flex-row"
                        onChange={handleChange("qual_electronics")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    {/* {formValues.qual_electronics === "y" && <FormControl component="fieldset" className="my-3">
                      <TextField label="Years" variant="outlined"
                        onChange={handleChange('qual_electronics_yrs')}
                        type="number"
                        value={formValues.qual_electronics_yrs}
                      />
                    </FormControl>} */}
                    <FormControl
                      component="fieldset"
                      fullWidth
                      className="mt-8"
                    >
                      <FormLabel component="legend" className="mb-3">
                        Web Design
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_web}
                        className="flex flex-row"
                        onChange={handleChange("qual_web")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    {/* {formValues.qual_web === "y" && <FormControl component="fieldset" className="my-3">
                      <TextField label="Years" variant="outlined"
                        onChange={handleChange('qual_web_yrs')}
                        type="number"
                        value={formValues.qual_web_yrs}
                      />
                    </FormControl>} */}
                    <FormControl
                      component="fieldset"
                      fullWidth
                      className="mt-8"
                    >
                      <FormLabel component="legend" className="mb-3">
                        Computer Programming
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_work_prog}
                        className="flex flex-row"
                        onChange={handleChange("qual_work_prog")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    {/* {formValues.qual_work_prog === "y" && <FormControl component="fieldset" className="my-3">
                      <TextField label="Quantity" variant="outlined"
                        onChange={handleChange('qual_work_prog_qty')}
                        type="number"
                        value={formValues.qual_work_prog_qty}
                      />
                    </FormControl>} */}
                    <FormControl
                      component="fieldset"
                      fullWidth
                      className="mt-8"
                    >
                      <FormLabel component="legend" className="mb-3">
                        Home DIY Projects
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_diy}
                        className="flex flex-row"
                        onChange={handleChange("qual_diy")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    {/* {formValues.qual_diy === "y" && <FormControl component="fieldset" className="my-3">
                      <TextField label="Years" variant="outlined"
                        onChange={handleChange('qual_diy_yrs')}
                        type="number"
                        value={formValues.qual_diy_yrs}
                      />
                    </FormControl>} */}
                    <FormControl
                      component="fieldset"
                      fullWidth
                      className="mt-8"
                    >
                      <FormLabel component="legend" className="mb-3">
                        Crafts and design
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_craft}
                        className="flex flex-row"
                        onChange={handleChange("qual_craft")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    {/* {formValues.qual_craft === "y" && <FormControl component="fieldset" className="my-3">
                      <TextField label="Years" variant="outlined"
                        onChange={handleChange('qual_craft_yrs')}
                        type="number"
                        value={formValues.qual_craft_yrs}
                      />
                    </FormControl>} */}
                    <FormControl
                      component="fieldset"
                      fullWidth
                      className="mt-8"
                    >
                      <FormLabel component="legend" className="mb-3">
                        Automobile DIY projects
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_autobody}
                        className="flex flex-row"
                        onChange={handleChange("qual_autobody")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    {/* {formValues.qual_autobody === "y" && <FormControl component="fieldset" className="my-3">
                      <TextField label="Years" variant="outlined"
                        onChange={handleChange('qual_autobody_yrs')}
                        type="number"
                        value={formValues.qual_autobody_yrs}
                      />
                    </FormControl>} */}
                  </Grid>
                )}
                {formValues?.step === 2 && (
                  <Grid item xs={12}>
                    <FormControl component="fieldset" className="mt-2">
                      <FormLabel component="legend" className="mb-3">
                        Do you use personal financial management software to
                        manage your finances?
                      </FormLabel>
                      <RadioGroup className="flex flex-row">
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    {/*<FormControl component="fieldset" className="mt-8">
                      <FormLabel component="legend" className="mb-3">Do you have at least one brokerage account?</FormLabel>
                      <RadioGroup className="flex flex-row" >
                        <FormControlLabel value={"n"} control={<FbRadio />} label="No" />
                        <FormControlLabel value={"y"} control={<FbRadio />} label="Yes" />
                      </RadioGroup>
                    </FormControl>*/}
                    <FormControl component="fieldset" className="mt-8">
                      <FormLabel component="legend" className="mb-3">
                        Have you ever been deliquent on a loan?
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.delinquency}
                        className="flex flex-row"
                        onChange={handleChange("delinquency")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
                {formValues?.step === 3 && (
                  <Grid item xs={12}>
                    <FormControl component="fieldset" className="mt-2">
                      <FormLabel component="legend" className="mb-3">
                        Did you/do you play any club sports in college?
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_club_sports}
                        className="flex flex-row"
                        onChange={handleChange("qual_club_sports")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" className="mt-8">
                      <FormLabel component="legend" className="mb-3">
                        Did you/do you play any NCAA sports in college?
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_ncaa_sports}
                        className="flex flex-row"
                        onChange={handleChange("qual_ncaa_sports")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" className="mt-8">
                      <FormLabel component="legend" className="mb-3">
                        Have you played professional sports after college?
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_prof_sports}
                        className="flex flex-row"
                        onChange={handleChange("qual_prof_sports")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" className="mt-8">
                      <FormLabel component="legend" className="mb-3">
                        Do you play sports as a hobby (at least once a week)?
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_hobby_sports}
                        className="flex flex-row"
                        onChange={handleChange("qual_hobby_sports")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" className="mt-8">
                      <FormLabel component="legend" className="mb-3">
                        Have you ever run marathons or done triathlons?
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_marathons}
                        className="flex flex-row"
                        onChange={handleChange("qual_marathons")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
                {formValues?.step === 4 && (
                  <Grid item xs={12}>
                    <FormControl component="fieldset" className="mt-2">
                      <FormLabel component="legend" className="mb-3">
                        Have you served in the military without discharge?
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_military}
                        className="flex flex-row"
                        onChange={handleChange("qual_military")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" className="mt-8">
                      <FormLabel component="legend" className="mb-3">
                        Have you served in the Peace Corps?
                      </FormLabel>
                      <RadioGroup
                        value={formValues?.qual_peace_corps}
                        className="flex flex-row"
                        onChange={handleChange("qual_peace_corps")}
                      >
                        <FormControlLabel
                          value={"n"}
                          control={<FbRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value={"y"}
                          control={<FbRadio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Container>
          </CardContent>
        </Card>
      </Container>
    ),
    nextDisabled: loading.profile,
    onPrev: () => {
      if (formValues?.step === 0) {
        window.setTimeout(() => dispatch(getScore()), 500);
        dispatch(setProfileStep(PROFILE_BUILD_STEPS.HUMAN_CAPITAL_OVERVIEW));
      } else {
        setFormValues({
          ...formValues,
          step: (formValues?.step || 1) - 1,
        });
      }
    },
    onNext: () => {
      if (
        formValues &&
        typeof formValues.step === "number" &&
        formValues.step < 4
      ) {
        setFormValues({
          ...formValues,
          step: formValues?.step + 1,
        });
      } else {
        const otherHcValues = pick(formValues, OTHER_HC_KEYS);
        const payload: UpdateOtherHumanCapitalPayload = {
          who: isMine ? PROFILE_TYPE.APPLICANT : PROFILE_TYPE.SPOUSE,
          update: otherHcValues,
          nextPage: PROFILE_BUILD_STEPS.INCOME_EXPENSES_INTRO_1,
        };
        dispatch(updateOther(payload));
        window.setTimeout(() => {
          dispatch(getScore());
          dispatch(setRefreshNeeded({ summaries: true }));
        }, 500);
      }
    },
  });
};

export default OtherHCForm;
