import React from "react";
import { useSelector } from "react-redux";
import { Finance } from "financejs";

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import { getRefinanceApi } from "src/apiService";
import Button from "src/components/Button";
import Card from "src/components/Card";
import { YesNo } from "src/interfaces";

import { getSpouseGI, getMyGI } from "src/store/cashflow/selector";
import colors from "src/theme/colors";
import { DollarTextField, formatPercent, formatWholeDollars } from "src/utils";

const finance = new Finance();

const useStyles = makeStyles({
  borderCell: {
    borderBottom: `1px solid ${colors.brandingBlue1}`,
  },
});

export const RefinanceDetails = ({
  goBack,
  onNext,
  formValues,
  handleChange,
  setCompareResults,
  fixed,
  refiBalance,
  isSpouse,
}: any) => {
  const styles = useStyles();
  const grossIncome = useSelector(isSpouse ? getSpouseGI : getMyGI);
  const comparePayment1 = finance.PMT(
    formValues.compareRate1 / 100 / 12,
    formValues.compareTerm1 * 12,
    -formValues.compareAmount1
  );
  const compareRate2 = fixed
    ? formValues.refinanceRate
    : formValues.compareRate2;
  const compareTerm2 = fixed
    ? formValues.refinanceTerm
    : formValues.compareTerm2;
  const compareAmount2 = fixed ? refiBalance : formValues.compareAmount2;
  const comparePayment2 = finance.PMT(
    compareRate2 / 100 / 12,
    compareTerm2 * 12,
    -compareAmount2
  );

  const submit = () => {
    const payload1 = {
      no_graph: "y" as YesNo,
      noRefinancingPrepayment: 0,
      refinancingMonthlyPayment: formValues.comparePrepay1,
      balances: [formValues.compareAmount1],
      interests: [formValues.compareRate1],
      payments: [comparePayment1],
      who: isSpouse ? "spouse" : "applicant",
    };
    const payload2 = {
      no_graph: "y" as YesNo,
      noRefinancingPrepayment: 0,
      refinancingMonthlyPayment: formValues.comparePrepay2,
      balances: [compareAmount2],
      interests: [compareRate2],
      payments: [comparePayment2],
      who: isSpouse ? "spouse" : "applicant",
    };
    return Promise.all([
      getRefinanceApi(payload1),
      getRefinanceApi(payload2),
    ]).then((results) => {
      setCompareResults(results);
      onNext();
    });
  };

  return (
    <Card style={{ maxWidth: 900, margin: "0 auto" }}>
      <Typography variant="h2" component="h2">
        Compare Your Total Monthly Payments
      </Typography>
      <Typography variant="body1">
        If you make no prepayments then you'll be comparing how much the longer
        term costs.
      </Typography>
      <Typography variant="body1">
        If you want to use the longer term loan to get budget flexibility but
        plan on making prepayments, you'll want to make sure the total monthly
        payments between both offers are equal.
      </Typography>
      <TableContainer style={{ maxWidth: 1000 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="border-none" />
              <TableCell className="border-none">Refinance Offer #1</TableCell>
              <TableCell className="border-none">Refinance Offer #2</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="border-none">
                Monthly Required Payment Refinanced Loans
              </TableCell>
              <TableCell className="border-none">
                {formatWholeDollars(comparePayment1)}
              </TableCell>
              <TableCell className="border-none">
                {formatWholeDollars(comparePayment2)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="border-none">
                Discretionary Prepayment
              </TableCell>
              <TableCell className={styles.borderCell}>
                <DollarTextField
                  name="comparePrepay1"
                  variant="outlined"
                  value={formValues.comparePrepay1 || ""}
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell className={styles.borderCell}>
                <DollarTextField
                  name="comparePrepay2"
                  variant="outlined"
                  value={formValues.comparePrepay2 || ""}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="border-none">
                <p className="mb-1">Total Monthly Payment</p>
                <Typography variant="caption">
                  Payment as a % of Gross Income
                </Typography>
              </TableCell>
              <TableCell className="border-none">
                <p className="mb-1">
                  {formatWholeDollars(
                    comparePayment1 + formValues.comparePrepay1
                  )}
                </p>
                <Typography variant="caption">
                  {formatPercent(
                    ((comparePayment1 + formValues.comparePrepay1) /
                      (grossIncome / 12)) *
                      100
                  )}
                </Typography>
              </TableCell>
              <TableCell className="border-none">
                <p className="mb-1">
                  {formatWholeDollars(
                    comparePayment2 + formValues.comparePrepay2
                  )}
                </p>
                <Typography variant="caption">
                  {formatPercent(
                    ((comparePayment2 + formValues.comparePrepay2) /
                      (grossIncome / 12)) *
                      100
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button fullWidth className="my-4" fbColor="primary" onClick={submit}>
        Calculate
      </Button>
      <Button fbColor="secondary" onClick={goBack} fullWidth>
        Back
      </Button>
    </Card>
  );
};

export default RefinanceDetails;
