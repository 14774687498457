import { riskPageText } from "src/text";

export const aboutRisk = {
  heading: "Understanding Risk Management",
  description: riskPageText,
};

export const aboutRecommendations = {
  heading: "Recommendations",
  description:
    "﻿We recommend that you have long-term disability insurance at a minimum. If you have anyone that is dependent on your income (i.e. children) then you should also have life insurance.",
  img: "/assets/images/png/clipboard.png",
};

export const tabLabels = ["This Month", "Last Month", "Last 12 Months"];
