import Auto from "./Views/Auto";
import Children from "./Views/Children";
import EmployerRetirement from "./Views/EmployerRetirement";
import HSA from "./Views/HSA";
import IncomeDecrease from "./Views/IncomeDecrease";
import IncomeIncrease from "./Views/IncomeIncrease";
import Marriage from "./Views/Marriage";
import NonProfit from "./Views/NonProfit";
import Moving from "./Views/Moving";
import Entry from "./Views/Entry";
import Transition from "./Views/ReviewTransition";
import Recap from "./Views/Recap";

export const steps = [
  // !uncomment first line to jump start into the plan review without running the rest of the steps
  // Transition,
  Entry,
  NonProfit,
  IncomeIncrease,
  IncomeDecrease,
  EmployerRetirement,
  HSA,
  Marriage,
  Children,
  Moving,
  Auto,
  Recap,
  Transition,
];
