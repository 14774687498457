import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fuse from "fuse.js";

import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Select,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";

import Card from "src/components/Card";
import Icon from "src/components/Icon";
import colors from "src/theme/colors";
import shadows from "src/theme/shadows";
import {
  BREAKOUT_TYPES,
  TRANSACTION_TYPE_IDS,
  Transaction,
} from "src/interfaces";
import { getAccounts } from "src/store/account/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import { getTransactions } from "src/store/transaction/actions";
import { getTransactionsByCategory } from "src/store/transaction/selector";
import {
  getDateString,
  oneYearAgo,
  ninetyDaysAgo,
  thirtyDaysAgo,
} from "src/utils";

const useStyles = makeStyles({
  title: {
    fontSize: "16px !important",
  },
  content: {
    height: 520,
    overflowY: "auto",
  },
  item: {
    alignItems: "center",
    minHeight: 61,
    borderBottom: `1px solid ${colors.blueGray7}`,
    padding: `0 5px 0 0`,
    display: "flex",
    justifyContent: "space-between",
  },
  description: {
    fontSize: 14,
  },
  subtitle: {
    fontSize: 10,
    color: colors.blueGray2,
  },
  negative: {
    color: colors.error,
  },
  positive: {
    color: colors.success,
  },
  amount: {
    color: colors.blueGray2,
    fontSize: 14,
    marginRight: 4,
  },
  innerItem: {
    alignItems: "center",
    display: "flex",
  },
  tabContainer: {
    height: 42,
    padding: 4,
    margin: "16px -6px 6px",
    width: "103%",
    boxShadow: shadows[3],
  },
  textInputLabel: {
    marginBottom: 4,
    marginTop: 8,
    fontSize: 10,
  },
});

const fuseOptions = {
  keys: ["description", "typeLabel"],
};

const ConfirmedTransactions = ({ openDialog }: any) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const {
    all,
    income,
    expenses,
    assets,
    debts,
    risk,
    educationCosts,
    educationFunding,
  } = useSelector(getTransactionsByCategory);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const accounts = useSelector(getAccounts);
  const accountLengthRef = useRef(accounts.length);
  const [tab, setTab] = useState(0);
  const [search, setSearch] = useState("");
  const [dateRange, setDateRange] = useState(0);

  useEffect(() => {
    if (accounts.length > accountLengthRef.current) {
      accountLengthRef.current = accounts.length;
    }
    const to = getDateString(new Date());
    let from = getDateString(thirtyDaysAgo());
    if (dateRange === 1) {
      from = getDateString(ninetyDaysAgo());
    } else if (dateRange === 2) {
      from = getDateString(oneYearAgo());
    }
    if (accounts.length) {
      dispatch(getTransactions({ from, to }));
    }
  }, [accounts.length, dateRange]);

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setSearch("");
    setTab(newValue);
  };

  const transactionList = [
    all,
    income,
    expenses,
    assets,
    debts,
    risk,
    educationFunding,
    educationCosts,
  ];
  const transactions = transactionList[tab];

  const getTypeLabel = (typeValue: number) => {
    let label: string =
      TRANSACTION_TYPE_IDS[
        (typeValue + "") as keyof typeof TRANSACTION_TYPE_IDS
      ];
    if (!label) {
      label = BREAKOUT_TYPES[(typeValue + "") as keyof typeof BREAKOUT_TYPES];
    }
    if (!label) {
      console.warn(`Missing type label for transaction type ${typeValue}`);
    }
    return label || "";
  };

  const fuse = useMemo(
    () =>
      new Fuse(
        transactions.map((transaction: Transaction) => ({
          ...transaction,
          typeLabel: getTypeLabel(transaction.type),
        })),
        fuseOptions
      ),
    [transactions]
  );

  const displayTransactions: any[] = search
    ? fuse.search(search).map(({ item }) => item)
    : transactions;

  const renderTypeSelection = () => {
    const menuItems = [
      <MenuItem value="0">All</MenuItem>,
      <MenuItem value="1">Income</MenuItem>,
      <MenuItem value="2">Expenses</MenuItem>,
      <MenuItem value="3">Assets</MenuItem>,
      <MenuItem value="4">Debt</MenuItem>,
      <MenuItem value="5">Risk Mgmt.</MenuItem>,
    ];
    if (isCurrentStudent) {
      menuItems.push(
        <MenuItem value="6">Education Funding</MenuItem>,
        <MenuItem value="7">Education Costs</MenuItem>
      );
    }
    return (
      <Select
        className="mr-2"
        value={tab}
        onChange={(e) => handleTabChange(e, +(e.target.value as string))}
        variant="outlined"
      >
        {menuItems}
      </Select>
    );
  };

  const selectDateRange = (e: React.ChangeEvent<any>) => {
    const value = e.target.value;
    setDateRange(value);
  };

  return (
    <Card
      headComponent={
        <Box className="flex">
          {renderTypeSelection()}
          <Select
            onChange={selectDateRange}
            value={dateRange}
            variant="outlined"
          >
            <MenuItem value={0}>Last 30 Days</MenuItem>
            <MenuItem value={1}>Last 90 Days</MenuItem>
            <MenuItem value={2}>Last 12 Months</MenuItem>
          </Select>
        </Box>
      }
      titleClassName={styles.title}
      title="Transactions"
    >
      {/*renderTabs()*/}
      <TextField
        className="my-2"
        fullWidth
        variant="outlined"
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon iconName="fb-search" />
            </InputAdornment>
          ),
        }}
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
      <List className={styles.content}>
        {displayTransactions.map((transaction: any) => (
          <ListItem key={transaction.id} className={styles.item}>
            <Box className={styles.innerItem}>
              <Box className="flex justify-between items-center">
                <Avatar className="mr-3">
                  {transaction.description
                    ? transaction.description.substring(0, 2).toUpperCase()
                    : "#"}
                </Avatar>
                <Box>
                  <Typography variant="body1" className={styles.description}>
                    {transaction.description}
                  </Typography>
                  <Typography className={styles.subtitle} variant="body2">
                    {transaction.date} | {getTypeLabel(transaction.type)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={styles.innerItem}>
              <Typography
                variant="body1"
                className={
                  transaction.amount < 0 ? styles.positive : styles.negative
                }
              >
                {transaction.amount < 0 ? "+" : "-"}
              </Typography>
              <Typography variant="body1" className={styles.amount}>
                ${Math.abs(transaction.amount).toFixed(2)}
              </Typography>
              <IconButton onClick={() => openDialog(transaction)}>
                <Icon iconName="fa-pencil" />
              </IconButton>
            </Box>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default ConfirmedTransactions;
