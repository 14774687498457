import React, { useEffect } from "react";

import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";

import { AdapterLink, formatPercent, formatWholeDollars } from "src/utils";

export const SelectLoans = ({
  goBack,
  onNext,
  loans,
  fetchLoans,
  selectedLoans,
  setSelectedLoans,
}: any) => {
  useEffect(() => {
    fetchLoans();
  }, []);
  const allSelected = selectedLoans.size === loans.length;
  const toggleSelectAll = () => {
    if (allSelected) {
      setSelectedLoans(new Set([]));
    } else {
      setSelectedLoans(new Set(loans.map((loan: any, index: number) => index)));
    }
  };

  const toggleSelected = (index: number) => {
    const newSelected = new Set(selectedLoans);
    if (newSelected.has(index)) {
      newSelected.delete(index);
    } else {
      newSelected.add(index);
    }
    setSelectedLoans(newSelected);
  };

  return (
    <Card style={{ maxWidth: 900, margin: "0 auto" }}>
      <Typography variant="h2">
        Select the loans you'd like to refinance
      </Typography>
      {!!loans.length && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={allSelected}
                        onClick={toggleSelectAll}
                      />
                    }
                    label="Select All"
                  />
                </TableCell>
                <TableCell>Interest Rate</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loans.map((loan: any, index: number) => (
                <TableRow key={loan.id}>
                  <TableCell>
                    <Checkbox
                      checked={selectedLoans.has(index)}
                      onClick={() => toggleSelected(index)}
                    />
                  </TableCell>
                  <TableCell>{formatPercent(loan.interest)}</TableCell>
                  <TableCell>{formatWholeDollars(loan.balance)}</TableCell>
                  <TableCell>
                    {loan.type === "federal"
                      ? "Federal"
                      : loan.type === "perkins"
                      ? "Perkins"
                      : "Private"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!loans.length && (
        <div className="my-6" style={{ fontSize: 20 }}>
          No qualifying loans found.
        </div>
      )}
      <Typography className="my-4" variant="body1">
        Note: If you marked your Federal loans as being on an income driven
        repayment plan, they will not show up here. If you'd like them to, you
        must edit your profile and change your repayment plan to a standard 10
        year or 25 year option.{" "}
        <AdapterLink to="/accounts">Click here to do so.</AdapterLink>
      </Typography>
      <Button
        fullWidth
        className="my-4"
        fbColor="primary"
        disabled={!selectedLoans.size}
        onClick={onNext}
      >
        Continue
      </Button>
      <Button fbColor="secondary" onClick={goBack} fullWidth>
        Back
      </Button>
    </Card>
  );
};

export default SelectLoans;
