import React from "react";
import {
  Box,
  // makeStyles,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { AdapterLink } from "src/utils";

export const AccountComplete = () => {
  // const classes = useStyles();

  return (
    <Card title="">
      <Typography paragraph>
        You have completed the account opening process on FitBUX. Our partner
        Betterment will email you to confirm your account details.
      </Typography>
      <Typography paragraph>
        You will also receive an email from us in regard to the next steps and
        actions to take on Betterment.
      </Typography>
      <Box>
        <Button fbColor="primary" component={AdapterLink} to="/investments">
          Next
        </Button>
      </Box>
    </Card>
  );
};

export default AccountComplete;
