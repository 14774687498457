import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Container, Grid } from "@material-ui/core";

import CenterContainer from "src/components/CenterContainer";
import Questionnaire from "src/components/Questionnaire";
import {
  QuestionFormProps,
  singleQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";
import { getIsMarried } from "src/store/system/selector";

const Marriage = ({
  planSetter,
  planData,
  goToNext,
  nextClicked,
}: QuestionFormProps) => {
  // note: hold initial next click count
  const [nextClick] = useState(nextClicked);
  const questionData: QuestionForm = singleQuestions.marriage;
  const isMarried = useSelector(getIsMarried);

  const startingValues = questionData.emptyValue;
  for (const key in questionData.emptyValue) {
    // check the questionnaire for the key
    // if key exists then use that value
    const savedData = planData[key as keyof typeof planData];
    if (savedData && savedData !== "n") {
      // get the field for the question
      const question = questionData.fields.find(
        (question) => question.field === key
      );
      // set all field enablers to "y"
      if (question && question.enabled) {
        const enablers = question.enabled;
        enablers.forEach((enabler) => {
          startingValues[enabler] = "y";
        });
      }
      startingValues[key] = savedData;
    }
  }
  const [values, setValues] = useState<Question>(startingValues);

  const handleChange = (newValues: any) => {
    updatePlanData(newValues);
    setValues(newValues);
  };

  useEffect(() => {
    // note: if next click count changed after mount then run goToNext
    if (nextClicked !== nextClick) {
      goToNext();
    }
  }, [nextClicked]);

  const updatePlanData = (changedValues: any) => {
    const newPlan = { ...planData };
    // General Question
    if (changedValues.will_marry === "n") {
      newPlan.will_marry = "n";
      newPlan.marry_date = "";
      newPlan.marry_spouse_income = 0;
      newPlan.marry_cost = 0;
    } else if (changedValues.will_marry === "y") {
      newPlan.will_marry = changedValues.will_marry;
      newPlan.marry_date = changedValues.marry_date;
      newPlan.marry_cost = changedValues.marry_cost;
      newPlan.marry_spouse_income = changedValues.marry_spouse_income;
    }
    // Spouse federal Student loan data
    if (!changedValues.enable_fedloan) {
      newPlan.marry_spouse_fed_loan_balance = 0;
    } else {
      newPlan.marry_spouse_fed_loan_balance =
        changedValues.marry_spouse_fed_loan_balance;
    }
    // Spouse private Student loan data
    if (!changedValues.enable_privloan) {
      newPlan.marry_spouse_priv_loan_balance = 0;
    } else {
      newPlan.marry_spouse_priv_loan_balance =
        changedValues.marry_spouse_priv_loan_balance;
    }
    planSetter(newPlan);
  };

  return (
    <CenterContainer
      title={questionData.typeLabel}
      help={questionData.help}
      iconName="fb-event"
      scrollable
    >
      {isMarried && (
        <Container className="mt-4">
          Your profile indicates that you are already married. Please click
          "Next" to continue.
        </Container>
      )}
      {!isMarried && (
        <Grid container>
          <Questionnaire
            questions={questionData}
            values={values}
            onChange={handleChange}
          />
        </Grid>
      )}
    </CenterContainer>
  );
};

export default Marriage;
