import React from "react";
import { useSelector } from "react-redux";

import { Grid } from "@material-ui/core";
import {
  getScoreProjection,
  getMinFutureProjections,
  getLoadingProjection,
} from "src/store/planBuild/selector";
import OverviewTable from "../components/OverviewTable";
import ScoreFactorsDialog from "../components/ScoreFactorsDialog";
import Chart from "src/components/Chart";
import CenterContainer from "src/pages/PlanBuilder/Components/CenterContainer";

const Score = () => {
  const loading = useSelector(getLoadingProjection);

  const scoreProjection = useSelector(getScoreProjection);
  const future = useSelector(getMinFutureProjections);

  // useEffect(() => {
  //   dispatch(fetchPlanProjection());
  // }, []);

  const now = new Date();
  const nowYear = now.getFullYear();
  const years = [
    "Now",
    "" + (nowYear + 5),
    "" + (nowYear + 10),
    future.retirementYear || "",
  ];

  return (
    <CenterContainer review title="1. My FitBUX Score">
      <Grid container spacing={3} className="mt-2 h-full">
        {!loading && !!future.net.length && (
          <>
            <Grid item xs={6}>
              <Chart
                noTitlePadding
                centerTitle
                title="Projected FitBUX Score"
                data={scoreProjection}
                height="322px"
                keys={["score", "min"]}
                xKey="month"
                keyLabels={{
                  score: "Plan Followed",
                  min: "Plan Not Followed",
                }}
                width="100%"
              />
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                height: "100%",
                overflowY: "scroll",
              }}
            >
              <OverviewTable
                title="FitBUX Score:"
                columns={years}
                rows={[
                  {
                    header: "Total if plan followed",
                    columns: years.map(
                      (year: string, index: number) => future.score[index].plan
                    ),
                  },
                  {
                    header: "Total if plan not followed",
                    columns: years.map(
                      (year: string, index: number) => future.score[index].min
                    ),
                  },
                ]}
              />
              <ScoreFactorsDialog />
            </Grid>
          </>
        )}
      </Grid>
    </CenterContainer>
  );
};

export default Score;

// const useStyles = makeStyles({
//   actionBar: {
//     background: "white",
//     padding: "12px 68px",
//     bottom: 0,
//     left: 0,
//     display: "flex",
//     justifyContent: "space-between",
//     position: "fixed",
//     width: "100%",
//   },
//   actionButton: {
//     width: 168,
//   },
//   cardRowCell: {
//     fontSize: 12,
//     width: "16%",
//   },
//   cardRowLabel: {
//     fontSize: 12,
//     paddingLeft: 10,
//     width: "36%",
//   },
//   cardTableHeader: {},
//   chart: {
//     minWidth: 440,
//     maxWidth: 524,
//   },
//   loaderContainer: {
//     position: "fixed",
//     top: 0,
//     bottom: 0,
//     left: 0,
//     right: 0,
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     zIndex: 555,
//     background: "rgba(255, 255, 255, .8)",
//   },
// });
