import React from "react";
import {
  Box,
  Slider,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";

const MySlider = withStyles({
  root: {
    position: "absolute",
    width: 500,
  },
})(Slider);

const FbSlider = withStyles({
  root: {
    position: "absolute",
    width: 500,
  },
})(Slider);

const FbValueLabelComponent = ({ children, open }: any) => {
  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="bottom"
      title={
        <Box className="text-center">
          <Box>FitBUX's Recommended Allocation</Box>
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};
const MyValueLabelComponent = ({ children, open }: any) => {
  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={
        <Box className="text-center">
          <Box>Your Chosen Allocation</Box>
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};

export const AllocationSlider = ({
  formValues,
  setFormValues,
  lowLabel,
  highLabel,
}: any) => {
  const handleChange = (event: React.ChangeEvent<any>, value: any) =>
    setFormValues((current: any) => ({ ...current, allocation: value }));

  return (
    <Box className="flex justify-center my-12">
      <Typography className="mt-1 mr-4">
        {lowLabel || "Most Conservative"}
      </Typography>
      <Box className="relative" style={{ width: 500 }}>
        <FbSlider
          disabled
          value={formValues.fitbuxAllocation || 0}
          ValueLabelComponent={FbValueLabelComponent}
          valueLabelDisplay="on"
          min={0}
          max={95}
          step={5}
          marks
        />
        <MySlider
          value={formValues.allocation}
          onChange={handleChange}
          ValueLabelComponent={MyValueLabelComponent}
          valueLabelDisplay="on"
          min={0}
          max={95}
          step={5}
          marks
        />
      </Box>
      <Typography className="mt-1 ml-4">
        {highLabel || "Most Aggressive"}
      </Typography>
    </Box>
  );
};

export default AllocationSlider;
