import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePlaidLink } from "react-plaid-link";

import {
  EuiButton,
  EuiContextMenu,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPopover,
  EuiIcon,
  EuiText,
  useGeneratedHtmlId,
} from "@elastic/eui";
import { StyledEuiButtonEmpty } from "src/components/Global/StyledComponents";
import {
  addPublicTokenApi,
  fetchAccountProvidersApi,
  getPlaidPublicTokenApi,
} from "src/apiService";
import { AccountProvider } from "src/interfaces";
import { getAccounts, GET_ACCOUNT_PROVIDERS } from "src/store/account/actions";
import { SUCCESS } from "src/store/common";
import {
  getIsMarried,
  spouseSelector,
  userSelector,
} from "src/store/system/selector";
import { track } from "src/utils/tracking";
import styled from "@emotion/styled";

export const StyledEuiModalHeader = styled(EuiModalHeader)`
  padding-inline: 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledEuiIcon = styled(EuiIcon)`
  width: 24px;
  height: 24px;
`;

interface Props {
  openAddFlyout: VoidFunction;
  onClose: VoidFunction;
  title: string;
}

const AddAccountDialog = ({ openAddFlyout, onClose, title }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const spouse = useSelector(spouseSelector);
  const isMarried = useSelector(getIsMarried);
  const myName = user?.first_name;
  const spouseName = spouse?.first_name;
  const modalTitleId = useGeneratedHtmlId();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [userToken, setUserToken] = useState("");
  const [spouseToken, setSpouseToken] = useState("");
  const iconType = popoverOpen ? "arrowUp" : "arrowDown";
  const [showDialog, setShowDialog] = useState(true);

  useEffect(() => {
    getPlaidPublicTokenApi({ who: "applicant" }).then((data) => {
      setUserToken(data?.link_token || "");
    });
    if (isMarried) {
      getPlaidPublicTokenApi({ who: "spouse" }).then((data) => {
        setSpouseToken(data?.link_token || "");
      });
    }
  }, []);

  const onSuccess = (who: string) => {
    // TODO make sure you're polling for linked account updates
    const successFunc = async (public_token: string) => {
      try {
        await addPublicTokenApi({ public_token, who });
        const data: AccountProvider[] = await fetchAccountProvidersApi();
        dispatch({
          type: GET_ACCOUNT_PROVIDERS + SUCCESS,
          payload: {
            providers: data,
          },
        });
        dispatch(getAccounts());
        // track Plaid successful account link in google data layer
        track({ event: "linked_plaid_account" });
        // setWaitingOnNewProvider(true);
      } catch (error) {
        console.error(error);
      } finally {
        onClose();
      }
    };
    return successFunc;
  };

  const userConfig: any = {
    token: userToken,
    onSuccess: onSuccess("applicant"),
    onExit: onClose,
  };

  const spouseConfig: any = {
    token: spouseToken,
    onSuccess: onSuccess("spouse"),
    onExit: onClose,
  };

  const { open: userOpen } = usePlaidLink(userConfig);
  const { open: spouseOpen } = usePlaidLink(spouseConfig);

  const openPlaidApplicant = () => {
    setShowDialog(false);
    userOpen();
  };

  const openPlaidSpouse = () => {
    setShowDialog(false);
    spouseOpen();
  };

  if (!showDialog) {
    return null;
  }

  return (
    <>
      <EuiModal
        aria-labelledby={modalTitleId}
        onClose={onClose}
        style={{ maxWidth: 600 }}
      >
        <StyledEuiModalHeader>
          <EuiModalHeaderTitle id={modalTitleId}>{title}</EuiModalHeaderTitle>
          <StyledEuiIcon
            type="cross"
            onClick={onClose}
            style={{
              cursor: "pointer",
            }}
          />
        </StyledEuiModalHeader>
        <EuiModalBody>
          <EuiText size="m">
            <p>
              We use Plaid to connect your financial accounts quickly and
              securely. Once your accounts are connected, they will update
              automatically so you have the most complete picture of your
              finances. You also have the option of adding your accounts
              manually instead.
            </p>
          </EuiText>
        </EuiModalBody>
        <EuiModalFooter>
          <StyledEuiButtonEmpty onClick={openAddFlyout}>
            Add account manually
          </StyledEuiButtonEmpty>
          {isMarried ? (
            <EuiPopover
              className="popover-styles"
              button={
                <EuiButton
                  iconSide="right"
                  fill
                  iconType={iconType}
                  onClick={() => setPopoverOpen(true)}
                >
                  Connect account
                </EuiButton>
              }
              isOpen={popoverOpen}
              closePopover={() => setPopoverOpen(false)}
              hasArrow={false}
            >
              <EuiContextMenu
                initialPanelId={0}
                panels={[
                  {
                    id: 0,
                    title: "",
                    items: [
                      {
                        name: `${myName || ""}'s account`,
                        onClick: openPlaidApplicant as any,
                      },
                      {
                        name: `${spouseName || ""}'s account`,
                        onClick: openPlaidSpouse as any,
                      },
                    ],
                  },
                ]}
              />
            </EuiPopover>
          ) : (
            <EuiButton fill onClick={openPlaidApplicant}>
              Connect account
            </EuiButton>
          )}
        </EuiModalFooter>
      </EuiModal>
    </>
  );
};

export default AddAccountDialog;
