import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import { Breakout } from "src/interfaces";
import { formatDollarsAndCents } from "src/utils";

const useStyles = makeStyles({
  breakoutHeader: {
    fontSize: 16,
    fontWeight: 500,
  },
});

const BreakoutSummary = ({ values }: any) => {
  const styles = useStyles();
  const incomes = values.incomes || [];
  const gross = incomes.reduce(
    (result: number, item: Breakout) => result + Math.abs(item.amount),
    0
  );
  const deductions = values.deductions || [];
  const deductionTotal = deductions.reduce(
    (result: number, item: Breakout) => result + Math.abs(item.amount),
    0
  );
  const calculatedNet = gross - deductionTotal;
  return (
    <Box>
      <Typography variant="h2" component="h2" className={styles.breakoutHeader}>
        Transaction summary
      </Typography>
      <Box className="flex justify-between">
        <Typography variant="body1">Total Income</Typography>
        <Typography variant="body1">{formatDollarsAndCents(gross)}</Typography>
      </Box>
      <Box className="flex justify-between">
        <Typography variant="body1">- Deductions</Typography>
        <Typography variant="body1">
          {formatDollarsAndCents(deductionTotal)}
        </Typography>
      </Box>
      <Box className="flex justify-between">
        <Typography variant="body1" className="font-bold">
          = Net Income
        </Typography>
        <Typography variant="body1" className="font-bold">
          {formatDollarsAndCents(calculatedNet)}
        </Typography>
      </Box>
    </Box>
  );
};

export default BreakoutSummary;
