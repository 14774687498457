import { useEffect, useRef, useState } from "react";

import { sortBy } from "lodash";

import {
  deleteAccountTickerApi,
  fetchAccountTickersApi,
  fetchTickerOptions,
  getTickerPriceApi,
  updateAccountTickerApi,
} from "src/apiService";

import { AccountTicker } from "src/interfaces";

interface TickerOption {
  Code: string;
  Exchange: string;
  Name: string;
}

interface WorkingAllocation extends AccountTicker {
  new: boolean;
  unsaved: boolean;
  balance: number;
  originalTicker: string;
}

const CASH_TICKER = "CASH.PLD";

export const useInvestments = ({ adjustAccountBalance }: any) => {
  const [investments, setInvestments] = useState<WorkingAllocation[]>([]);
  const [options, setOptions] = useState<Array<TickerOption | string>>([]);
  const [tickerInputValue, setTickerInputValue] = useState("");
  const [focusedItem, setFocusedItem] = useState<any>(null);
  const tickersToDelete = useRef(new Set<string>([]));
  const originalInvestments = useRef<AccountTicker[]>([]);

  const initializeInvestments = (accountId: number) => {
    fetchAccountTickersApi(accountId).then((results) => {
      const sorted = sortBy(
        results,
        (item) => -(item.quantity * item.close_price)
      );
      originalInvestments.current = sorted;
      setInvestments(
        sorted.map((item) => ({
          ...item,
          new: false,
          unsaved: false,
          balance: item.quantity * item.close_price,
          original_ticker: item.ticker_symbol,
        }))
      );
    });
  };

  useEffect(() => {
    let active = true;
    const value = focusedItem?.ticker_symbol || "";
    if (!tickerInputValue) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (fetchTickerOptions) {
      fetchTickerOptions(tickerInputValue).then((results: any[]) => {
        if (active) {
          const sorted = sortBy(results, (item) => {
            if (item.Exchange === "US") {
              return 1;
            }
            if (item.Exchange === "CC") {
              return 2;
            }
            return 3;
          });
          setOptions(sorted);
        }
      });
    }
    return () => {
      active = false;
    };
  }, [investments, focusedItem, tickerInputValue]);

  const saveInvestments = (accountId: number) => {
    const promises = [];
    investments.forEach((item) => {
      if (item.new || item.unsaved) {
        const getPrice =
          item.ticker_symbol === CASH_TICKER
            ? Promise.resolve(1)
            : getTickerPriceApi(item.ticker_symbol);
        const promise = getPrice.then((price) => {
          if (!price) {
            return;
          }
          const quantity = item.balance / price;
          return updateAccountTickerApi(
            accountId,
            item.ticker_symbol,
            quantity
          );
        });
        promises.push(promise);
      }
      originalInvestments.current.forEach((item) => {
        if (tickersToDelete.current.has(item.ticker_symbol)) {
          promises.push(deleteAccountTickerApi(accountId, item.ticker_symbol));
        }
      });
    });
  };

  const addNewTicker = () => {
    setInvestments((current) => [
      ...current,
      {
        originalTicker: "",
        ticker_symbol: "",
        quantity: 0,
        close_price: 0,
        balance: 0,
        new: true,
        unsaved: true,
      },
    ]);
  };

  const addCash = () => {
    setInvestments((current) => [
      ...current,
      {
        originalTicker: "",
        ticker_symbol: CASH_TICKER,
        quantity: 0,
        close_price: 1,
        balance: 0,
        new: true,
        unsaved: true,
      },
    ]);
  };

  const removeItem = (item: WorkingAllocation) => {
    const foundIndex = investments.findIndex(
      (found) => found.ticker_symbol === item.ticker_symbol
    );
    if (!item.new) {
      tickersToDelete.current.add(item.ticker_symbol);
    }
    adjustAccountBalance(totalBalance - item.balance);
    setInvestments((current: WorkingAllocation[]) => {
      const newInvestments = [...current];
      newInvestments.splice(foundIndex, 1);
      return newInvestments;
    });
  };

  const cashValue = !!investments.find(
    (item) => item.ticker_symbol === CASH_TICKER
  );

  const totalBalance = investments.reduce(
    (result, investment) => result + investment.balance,
    0
  );

  const updateItemTicker = (item: WorkingAllocation, value: any) => {
    const foundIndex = investments.findIndex(
      (found) => found.ticker_symbol === item.ticker_symbol
    );
    const newValue = value ? `${value.Code}.${value.Exchange}` : "";
    const tickerAlreadyExists = !!investments.find(
      (found) => found.ticker_symbol === newValue
    );
    if (tickerAlreadyExists) {
      return;
    }
    tickersToDelete.current.delete(newValue);
    tickersToDelete.current.add(item.originalTicker);
    setInvestments((current: WorkingAllocation[]) => {
      const newInvestments = [...current];
      newInvestments[foundIndex] = {
        ...newInvestments[foundIndex],
        ticker_symbol: newValue,
        unsaved: true,
      };
      return newInvestments;
    });
  };

  const updateBalance = (item: WorkingAllocation, value: number) => {
    const foundIndex = investments.findIndex(
      (found) => found.ticker_symbol === item.ticker_symbol
    );
    const delta = value - item.balance;
    adjustAccountBalance(totalBalance + delta);
    setInvestments((current: WorkingAllocation[]) => {
      const newInvestments = [...current];
      newInvestments[foundIndex] = {
        ...newInvestments[foundIndex],
        balance: value,
        unsaved: true,
      };
      return newInvestments;
    });
  };

  return {
    addCash,
    addNewTicker,
    cashValue,
    initializeInvestments,
    investments,
    options,
    removeItem,
    saveInvestments,
    setFocusedItem,
    setOptions,
    setTickerInputValue,
    totalBalance,
    updateBalance,
    updateItemTicker,
  };
};
