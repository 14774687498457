import React from "react";

import { Box, CircularProgress, Dialog } from "@material-ui/core";

import { SPINWHEEL_CONTAINER_ID } from "./functions";

const SpinwheelDialog = ({ open, onClose, classes, loading, style }: any) => {
  return (
    <Dialog
      classes={classes}
      fullWidth={true}
      onExiting={onClose}
      onClose={onClose}
      open={open}
      maxWidth="xl"
      aria-labelledby="form-dialog-title"
      disableEnforceFocus
      PaperProps={{ style }}
    >
      <Box style={loading ? {} : { height: "80vh" }}>
        {!!loading && (
          <div className="my-10 w-full">
            <div className="mx-auto w-24">
              <CircularProgress size={96} />
            </div>
          </div>
        )}
        <div
          id={SPINWHEEL_CONTAINER_ID}
          style={loading ? {} : { height: "80vh" }}
        />
      </Box>
    </Dialog>
  );
};

export default SpinwheelDialog;
