import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  clearActionItemApi,
  refreshActionItemsApi,
  reportActionItemApi,
} from "src/apiService";
import {
  getAccounts,
  getAccountProviders,
  getAccountsLoading,
} from "src/store/account/selector";
import { getRawCashflows } from "src/store/cashflow/selector";
import ACTION_ITEMS, {
  CSC_ACTION_ITEMS,
} from "src/store/dashboard/actionItems";
import { fetchActionItems } from "src/store/dashboard/actions";
import {
  getDashboardLoaded,
  getLifeInsuranceLiabilities,
} from "src/store/dashboard/selector";
import { getUnconfirmedIncomeAndExpenses } from "src/store/transaction/selector";

import {
  getLivePlanSurplus,
  getLivePlan,
  // getLoadedState,
  getPlans,
} from "src/store/planBuild/selector";
import { getHasPlan } from "src/store/system/selector";

export const useCSC = () => {
  const dispatch = useDispatch();
  const cashflows = useSelector(getRawCashflows);
  const livePlanSurplus = useSelector(getLivePlanSurplus);
  const hasPlan = useSelector(getHasPlan);
  const livePlan = useSelector(getLivePlan);
  const savedPlans = useSelector(getPlans);
  const providers = useSelector(getAccountProviders);
  const unconfirmedTransactions = useSelector(getUnconfirmedIncomeAndExpenses);
  const accounts = useSelector(getAccounts);
  const loaded = useSelector(getDashboardLoaded);
  const loadingAccounts = useSelector(getAccountsLoading);
  const insuranceLiabilities = useSelector(getLifeInsuranceLiabilities);
  const [complete, setComplete] = useState(false);

  const data = {
    accounts,
    cashflows,
    livePlanSurplus,
    insuranceLiabilities,
    hasPlan,
    livePlan,
    providers,
    savedPlans,
    unconfirmedTransactions,
  };

  const recheckItem = (id: string) => {
    if (!ACTION_ITEMS[id].trigger(data)) {
      clearActionItemApi(id)
        .then(() => refreshActionItemsApi())
        .then(() => dispatch(fetchActionItems()));
    }
  };

  useEffect(() => {
    if (!loaded || loadingAccounts || complete) {
      return;
    }
    setComplete(true);
    const promises: any = [];
    CSC_ACTION_ITEMS.forEach((id) => {
      const itemIsActive = ACTION_ITEMS[id].trigger(data);
      if (itemIsActive) {
        promises.push(reportActionItemApi(id));
      } else {
        promises.push(clearActionItemApi(id));
      }
    });
    Promise.all(promises)
      .then(() => refreshActionItemsApi())
      .then(() => {
        dispatch(fetchActionItems());
      });
  }, [loaded, loadingAccounts, data, complete]);

  return { recheckItem };
};
