import React from "react";
export type Gender = "m" | "f" | "o";
export type YesNo = "y" | "n" | null;
export type YesNoNA = "y" | "n" | "na" | null;
export interface FbAction<T> {
  payload: T;
  type: string;
}

export interface Item0 {
  id: number;
  name: string;
}
export interface Item1 {
  id: string;
  name: string;
}

export const INCOME_TYPES: any = {
  "401k_dist": "401(k) dist.",
  bonus: "Bonus",
  commission: "Commission",
  dividend: "Dividend",
  hourly: "Hourly wage",
  interest: "Interest",
  ira_dist: "IRA dist.",
  other_income: "Other income",
  rental_income: "Rental income",
  salary: "Salary",
  side_hustle: "1099/Self-Employed/Side Hustle",
  stipend: "Stipend",
};

export const PLAN_INCOME_TYPES: any = {
  "401k_dist": "401(k) dist.",
  bonus: "Bonus",
  commission: "Commission",
  dividend: "Dividend",
  hourly: "Hourly wage",
  interest: "Interest",
  ira_dist: "IRA dist.",
  other: "Other income",
  rent: "Rental income",
  salary: "Salary",
  side_hustle: "1099/Self-Employed/Side Hustle",
  stipend: "Stipend",
};

export const EARNED_INCOME_TYPES: any = {
  salary: "Salary",
  hourly: "Hourly wage",
  side_hustle: "Side hustle",
  bonus: "Bonus",
  commission: "Commission",
  stipend: "Stipend",
  other_income: "Other income",
};

export const EXTRA_EXPENSE_TYPES = {
  advisory_services: "Advisory services",
  bank_fees: "Bank fees",
  books: "Books/Magazines/Newspapers",
  cable: "Cable",
  charity: "Charity",
  child_care: "Child/Dependent Costs",
  continuing_education: "Continuing education",
  electronics: "Electronics",
  entertainment: "Entertainment",
  gym: "Gym",
  healthcare: "Healthcare",
  home_improvement: "Home improvement",
  merchandise: "Merchandise",
  org_dues: "Org dues",
  other_expense: "Other expense",
  personal_care: "Personal care",
  pets: "Pets",
  professional_services: "Professional services",
  property_maintenance: "Property maintenance",
  property_management: "Property management",
  restaurant: "Restaurant",
  sports: "Sports",
  streaming: "Streaming",
  tolls: "Tolls",
  travel: "Travel",
  vehicle_lease: "Auto lease",
  vehicle_service: "Vehicle Service",
};

export const EXPENSE_TYPES: any = {
  advisory_services: "Advisory services",
  bank_fees: "Bank fees",
  books: "Books/Magazines/Newspapers",
  cable: "Cable",
  charity: "Charity",
  child_care: "Child/Dependent Costs",
  clothing: "Clothing",
  continuing_education: "Continuing education",
  electronics: "Electronics",
  entertainment: "Entertainment",
  fuel: "Fuel",
  grocery: "Grocery",
  gym: "Gym",
  healthcare: "Healthcare",
  hoa: "HOA fees",
  home_improvement: "Home improvement",
  merchandise: "Merchandise",
  org_dues: "Org dues",
  other_expense: "Other expense",
  personal_care: "Personal care",
  pets: "Pets",
  phone: "Phone",
  power: "Power",
  professional_services: "Professional services",
  prop_tax: "Property tax",
  property_maintenance: "Property maintenance",
  property_management: "Property management",
  rent: "Rent/Boarding",
  restaurant: "Restaurant",
  // school: "School",
  sports: "Sports",
  streaming: "Streaming",
  tolls: "Tolls",
  travel: "Travel",
  vehicle_lease: "Auto lease",
  vehicle_service: "Vehicle Service",
  water: "Water",
};

export const PLAN_EXPENSE_TYPES: any = {
  advisory_services: "Advisory services",
  bank_fees: "Bank fees",
  books: "Books/News",
  cable: "Cable",
  charity: "Charity",
  child_care: "Child/Dependent Costs",
  clothing: "Clothing",
  continuing_education: "Continuing education",
  electronics: "Electronics",
  entertainment: "Entertainment",
  fuel: "Fuel",
  grocery: "Grocery",
  gym: "Gym",
  healthcare: "Healthcare",
  hoa: "HOA fees",
  home_improvement: "Home improvement",
  merchandise: "Merchandise",
  org_dues: "Org dues",
  other: "Other expense",
  personal_care: "Personal care",
  pets: "Pets",
  phone: "Phone",
  power: "Power",
  professional_services: "Professional services",
  prop_tax: "Property tax",
  property_maintenance: "Property maintenance",
  property_management: "Property management",
  rent: "Rent/Boarding",
  restaurant: "Restaurant",
  // school: "School",
  sports: "Sports",
  streaming: "Streaming",
  tolls: "Tolls",
  travel: "Travel",
  vehicle_lease: "Auto lease",
  vehicle_service: "Vehicle Service",
  water: "Water",
};

export const ALL_EXPENSE_TYPES: any = {
  ...EXPENSE_TYPES,
  credits: "Credits/Refunds",
  tax: "Taxes",
  taxes: "Taxes",
};

export const GROUPED_EXPENSE_TYPES = [
  {
    header: "Child/Dependent Costs",
    items: [
      {
        label: "Child/Dependent Costs",
        value: "child_care",
      },
    ],
  },
  {
    header: "Education",
    items: [
      {
        label: "Books/Magazines/Newspapers",
        value: "books",
      },
      {
        label: "Continuing Education",
        value: "continuing_education",
        // }, {
        //   label: "Tuition and Fees",
        //   value: "school",
      },
    ],
  },
  {
    header: "Food and Drinks",
    items: [
      {
        label: "Groceries",
        value: "grocery",
      },
      {
        label: "Restaurants and Bars",
        value: "restaurant",
      },
    ],
  },
  {
    header: "Health and Medical Services",
    items: [
      {
        label: "Healthcare",
        value: "healthcare",
      },
    ],
  },
  {
    header: "Housing",
    items: [
      {
        label: "HOA Fees",
        value: "hoa",
      },
      {
        label: "Home Improvement/Maintenance",
        value: "home_improvement",
      },
      {
        label: "Property Tax",
        value: "prop_tax",
      },
      {
        label: "Rent/Boarding",
        value: "rent",
      },
    ],
  },
  {
    header: "Leisure",
    items: [
      {
        label: "Entertainment",
        value: "entertainment",
      },
      {
        label: "Hobbies/Sports",
        value: "sports",
      },
      {
        label: "Online/Streaming/Media",
        value: "streaming",
      },
      {
        label: "Travel",
        value: "travel",
      },
    ],
  },
  {
    header: "Pets",
    items: [
      {
        label: "Pet Related",
        value: "pets",
      },
    ],
  },
  {
    header: "Self Improvement",
    items: [
      {
        label: "Gym/Self Improvement",
        value: "gym",
      },
      {
        label: "Personal Care",
        value: "personal_care",
      },
    ],
  },
  {
    header: "Shopping",
    items: [
      {
        label: "Clothing/shoes",
        value: "clothing",
      },
      {
        label: "Electronics",
        value: "electronics",
      },
      {
        label: "General Merchandise",
        value: "merchandise",
      },
    ],
  },
  {
    header: "Transportation",
    items: [
      {
        label: "Auto Lease",
        value: "vehicle_lease",
      },
      {
        label: "Auto Service and Parts",
        value: "vehicle_service",
      },
      {
        label: "Gasoline and Fuel",
        value: "fuel",
      },
      {
        label: "Taxi/Tolls/Parking/Public Transit",
        value: "tolls",
      },
    ],
  },
  {
    header: "Utilities",
    items: [
      {
        label: "Cable/Satellite/Internet",
        value: "cable",
      },
      {
        label: "Electric/Gas",
        value: "power",
      },
      {
        label: "Phone",
        value: "phone",
      },
      {
        label: "Water/Trash",
        value: "water",
      },
    ],
  },
  {
    header: "Other",
    items: [
      {
        label: "Advisory Services",
        value: "advisory_services",
      },
      {
        label: "Bank Fees",
        value: "bank_fees",
      },
      {
        label: "Charitable Giving",
        value: "charity",
      },
      {
        label: "Organizational Dues",
        value: "org_dues",
      },
      {
        label: "Other Expenses",
        value: "other_expense",
      },
      {
        label: "Professional Services",
        value: "professional_services",
      },
      {
        label: "Rental Property Maintenance",
        value: "property_maintenance",
      },
      {
        label: "Rental Property Management",
        value: "property_management",
      },
    ],
  },
];

export const PROFILE_EXPENSE_TYPES = {
  grocery: "Groceries",
  hoa: "HOA dues",
  prop_tax: "Property taxes",
  rent: "Rent",
  clothing: "Clothing",
  fuel: "Fuel",
  power: "Power",
  phone: "Phone",
  water: "Water",
};

export const RISK_MANAGEMENT_TYPES: any = {
  health_insurance: "Health insurance",
  life_insurance: "Life insurance",
  disability_insurance: "Disability insurance",
  accidental_death: "Accidental death insurance",
  dental_insurance: "Dental insurance",
  vision_insurance: "Vision insurance",
  rental_insurance: "Rental insurance",
  home_insurance: "Home insurance",
  vehicle_insurance: "Vehicle insurance",
  property_insurance: "Property insurance",
};

export const ALL_RISK_MANAGEMENT_TYPES: any = {
  ...RISK_MANAGEMENT_TYPES,
  other_insurance: "Other insurance",
};

export const ALL_CASHFLOW_TYPES = {
  ...INCOME_TYPES,
  ...PLAN_INCOME_TYPES,
  ...EXPENSE_TYPES,
  ...RISK_MANAGEMENT_TYPES,
};

export const INVESTMENT_TYPES: any = {
  "401k_value": "401(k)",
  roth_401k_value: "Roth 401(k)",
  roth_ira_value: "Roth IRA",
  ira_value: "IRA",
  hsa_value: "HSA",
  other_retirement_value: "Other retirement account",
  other_investments_value: "Other investments",
};

export const TAX_DEFERRED_INVESTMENT_TYPES: any = {
  "401k_value": "Traditional 401(k) contributions",
  ira_value: "Traditional IRA contributions",
  hsa_value: "Health Savings Account contributions",
};

export const ALL_INVESTMENT_TYPES: any = {
  ...INVESTMENT_TYPES,
  crypto: "Cryptocurrency",
};

export const ASSET_TYPES: any = {
  cash_value: "Checking/savings",
  ...INVESTMENT_TYPES,
  home_value: "Primary residence",
  property_value: "Investment property",
  auto_value: "Auto value",
  other_assets_value: "Other assets",
};

export const DEBT_TYPES: any = {
  fed_loan: "Federal student loan",
  perkins_loan: "Perkins loan",
  priv_loan: "Private student loan",
  credit_card: "Credit card",
  home_loan: "Home mortgage",
  property_loan: "Investment property mortgage",
  auto_loan: "Auto loan",
  personal_loan: "Personal loan",
  other_debt: "Other debt",
};

export const PROFILE_DEBT_TYPES: any = {
  ...DEBT_TYPES,
  vehicle_lease: "Auto lease",
};

export const ACCOUNT_TYPES = {
  ...ASSET_TYPES,
  ...DEBT_TYPES,
  student_loan: "Student loans",
  property_loan: "Invest. property mortgage",
};

export const PROFILE_ACCOUNT_TYPES = {
  ...ASSET_TYPES,
  ...PROFILE_DEBT_TYPES,
};

export const WHO_TYPES: any = {
  mine: "Mine",
  spouse: "Spouse",
};

export const APPLICANT_WHO_TYPES: any = {
  applicant: "Mine",
  spouse: "Spouse",
};

export type Who = "applicant" | "spouse";

export type Whose = "mine" | "spouse";

export const REPAYMENT_PLANS: any = {
  std_plan: "Standard",
  ext_fixed: "Extended",
  ibr: "Income-based repayment (IBR)",
  new_ibr: "IBR for new borrowers",
  paye: "Pay as you earn (PAYE)",
  repaye: "Revised PAYE (REPAYE/SAVE)",
};

export const SPECIAL_REPAYMENTS = ["ibr", "new_ibr", "repaye", "paye"];

export enum STUDENT_STATUS {
  IN_SCHOOL,
  GRADUATED_RECENTLY,
  GRADUATED_6_MONTHS_AGO,
  GRADUATED_18_MONTHS_AGO,
  GRADUATED_5_YEARS_AGO,
}

export type RepaymentPlan = keyof typeof REPAYMENT_PLANS;
export type CASHFLOW_TYPES =
  | keyof typeof EXPENSE_TYPES
  | keyof typeof INCOME_TYPES;
export type ACCOUNT_TYPES = keyof typeof ASSET_TYPES | keyof typeof DEBT_TYPES;

export interface GeneralPageButtonDefinition {
  iconImage?: string;
  iconName: string;
  target: string;
  subscribedTarget?: string;
  label: string;
  disabled?: boolean;
  tooltip?: string;
  video?: string;
  dialog?: {
    title: string;
    content: React.ReactNode;
  };
}

export interface GeneralPageCardDefinition {
  items: GeneralPageButtonDefinition[];
  heading: string;
  subscribedHeading?: string;
  showSubscribeButton?: boolean;
}

export interface GeneralPageData {
  calculators: GeneralPageCardDefinition;
  answers: GeneralPageCardDefinition;
  products: GeneralPageCardDefinition;
}

export interface InputDefinition {
  name: string;
  label: string;
  type: "text" | "dollars" | "phone" | "number" | "select" | "toggle";
  values?: Array<{ value: string; label: string }> | string[];
}
