import React from "react";
import { useSelector } from "react-redux";

import { Box, makeStyles, Theme, Typography } from "@material-ui/core";

import { DisabilityInsuranceResult } from "src/apiService";
import Button from "src/components/Button";
import Card from "src/components/Card";
import { User } from "src/interfaces";
import { getIsMarried, userSelector } from "src/store/system/selector";
import { formatWholeDollars } from "src/utils";

interface ResultStepProps extends DisabilityInsuranceResult {
  goBack(): void;
}

const ResultStep = ({
  any_occupation_monthly_benefit,
  own_occupation_monthly_benefit,
  s_any_occupation_monthly_benefit,
  s_own_occupation_monthly_benefit,
  goBack,
}: ResultStepProps) => {
  const classes = useStyles();
  const user: User | undefined = useSelector(userSelector);
  const isMarried = useSelector(getIsMarried);

  if (!user) {
    return null;
  }

  return (
    <>
      <Card className={classes.card}>
        <Typography variant="h2" className={classes.title}>
          My Disability Insurance
        </Typography>
        {!!own_occupation_monthly_benefit && (
          <>
            <Box className={classes.resultsRow}>
              <Typography>
                Your disability policy/policies should provide a total monthly
                benefit of:
              </Typography>
              <Typography>
                {formatWholeDollars(
                  any_occupation_monthly_benefit +
                    own_occupation_monthly_benefit
                )}
              </Typography>
            </Box>
            <Typography variant="h2" className={classes.title}>
              Optimal Disability Insurance
            </Typography>
            <Box className={classes.resultsRow}>
              <Typography>
                Your Any Occupation Policy should provide a total monthly
                benefit of:
              </Typography>
              <Typography>
                {formatWholeDollars(any_occupation_monthly_benefit)}
              </Typography>
            </Box>
            <Box className={classes.resultsRow}>
              <Typography>
                Your Own Occupation Policy should provide a total monthly
                benefit of:
              </Typography>
              <Typography>
                {formatWholeDollars(own_occupation_monthly_benefit)}
              </Typography>
            </Box>
          </>
        )}
        {!own_occupation_monthly_benefit && (
          <Box className={classes.resultsRow}>
            <Typography>
              You should have an Any Occupation Disability policy that pays the
              following:
            </Typography>
            <Typography>
              {formatWholeDollars(any_occupation_monthly_benefit)}
            </Typography>
          </Box>
        )}
      </Card>
      {isMarried && (
        <Card className={classes.card}>
          <Typography variant="h2" className={classes.title}>
            Your Spouse's Disability Insurance
          </Typography>
          {!!s_own_occupation_monthly_benefit && (
            <>
              <Box className={classes.resultsRow}>
                <Typography>
                  Your spouse's disability policy/policies should provide a
                  total monthly benefit of:
                </Typography>
                <Typography>
                  {formatWholeDollars(
                    s_any_occupation_monthly_benefit +
                      s_own_occupation_monthly_benefit
                  )}
                </Typography>
              </Box>
              <Typography variant="h2" className={classes.title}>
                Optimal Disability Insurance
              </Typography>
              <Box className={classes.resultsRow}>
                <Typography>
                  Your spouse's Any Occupation Policy should provide a total
                  monthly benefit of:
                </Typography>
                <Typography>
                  {formatWholeDollars(s_any_occupation_monthly_benefit)}
                </Typography>
              </Box>
              <Box className={classes.resultsRow}>
                <Typography>
                  Your spouse's Own Occupation Policy should provide a total
                  monthly benefit of:
                </Typography>
                <Typography>
                  {formatWholeDollars(s_own_occupation_monthly_benefit)}
                </Typography>
              </Box>
            </>
          )}
          {!s_own_occupation_monthly_benefit && (
            <Box className={classes.resultsRow}>
              <Typography>
                Your spouse should have an Any Occupation Disability policy that
                pays the following:
              </Typography>
              <Typography>
                {formatWholeDollars(s_any_occupation_monthly_benefit)}
              </Typography>
            </Box>
          )}
        </Card>
      )}
      <Box className={classes.transitions}>
        <Button onClick={() => goBack()}>Back</Button>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "80%",
    margin: "20px auto",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  flexItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    minWidth: 400,
    position: "relative",
  },
  flexItemWithBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    minWidth: 400,
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
  },
  resultsRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
    maxWidth: 700,
  },
  title: {
    fontSize: 16,
    marginBottom: 16,
  },
  transitions: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
    gap: "20px",
  },
}));

export default ResultStep;
