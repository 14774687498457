import React from "react";
import { INVESTMENT_RETURN, STEP } from "../CONSTANTS";
import { Finance } from "financejs";
import { getPaidOffTerm, payDownData } from "src/utils";

interface PointsCalculatorContextProps {
  activeStep: STEP;
  handleNext(): void;
  handleBack(): void;
  handleInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    isInterest?: boolean
  ): void;
  handleCalculation(): void;
  calculatorValues: CalculatorValues;
  isCalculationReady: boolean;
  breakEvenResult: BreakEvenProps;
  investmentMethodResult: InvestmentMethodValues;
}

type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

interface CalculatorValues {
  mortgageTerm: number;
  mortgageAmount: number;
  noPointsInterestRate: number;
  offerOneInterestRate: number;
  offerOnePoint: number;
  offerTwoInterestRate: number;
  offerTwoPoint: number;
}

interface BreakEvenProps {
  noPointsOffer: BreakEvenResultProps;
  pointOfferOne: BreakEvenResultProps;
  pointOfferTwo: BreakEvenResultProps;
}

interface InvestmentMethodResultValue {
  noPointsOffer: number;
  pointOfferOne: number;
  pointOfferTwo: number;
}

interface BreakEvenResultProps {
  pointsInDollar: number;
  monthlyPayment: number;
  deltaMonthlyPayment: number;
  breakEvenYears: number;
}

export interface InvestmentMethodResult {
  monthlyRequiredPayment: InvestmentMethodResultValue;
  monthlyPrepayment?: InvestmentMethodResultValue;
  totalMonthlyPayment?: InvestmentMethodResultValue;
  loanPaidInYears: InvestmentMethodResultValue;
  monthlyInvestmentAmount: InvestmentMethodResultValue;
  yearsAvailableToInvest: InvestmentMethodResultValue;
  investmentValue: AtLeast<
    InvestmentMethodResultValue,
    "pointOfferOne" | "pointOfferTwo"
  >;
  futureValueOfPointsPaid: AtLeast<
    InvestmentMethodResultValue,
    "pointOfferOne" | "pointOfferTwo"
  >;
  netInvestmentValue: InvestmentMethodResultValue;
}

interface InvestmentMethodValues {
  strategyOne: InvestmentMethodResult;
  strategyTwo: InvestmentMethodResult;
}

const initBreakEvenValues: BreakEvenResultProps = {
  pointsInDollar: 0,
  monthlyPayment: 0,
  deltaMonthlyPayment: 0,
  breakEvenYears: 0,
};

const initCalculatorValues: CalculatorValues = {
  mortgageAmount: 0,
  mortgageTerm: 30,
  noPointsInterestRate: 0,
  offerOneInterestRate: 0,
  offerOnePoint: 0,
  offerTwoInterestRate: 0,
  offerTwoPoint: 0,
};

const initInvestmentMethodResultValue: InvestmentMethodResultValue = {
  noPointsOffer: 0,
  pointOfferOne: 0,
  pointOfferTwo: 0,
};

const initInvestmentMethodResultStrategyOne: InvestmentMethodResult = {
  monthlyRequiredPayment: initInvestmentMethodResultValue,
  monthlyInvestmentAmount: initInvestmentMethodResultValue,
  loanPaidInYears: initInvestmentMethodResultValue,
  yearsAvailableToInvest: initInvestmentMethodResultValue,
  investmentValue: initInvestmentMethodResultValue,
  futureValueOfPointsPaid: initInvestmentMethodResultValue,
  netInvestmentValue: initInvestmentMethodResultValue,
  monthlyPrepayment: initInvestmentMethodResultValue,
  totalMonthlyPayment: initInvestmentMethodResultValue,
};

const initInvestmentMethodResultStrategyTwo: InvestmentMethodResult = {
  monthlyRequiredPayment: initInvestmentMethodResultValue,
  monthlyInvestmentAmount: initInvestmentMethodResultValue,
  loanPaidInYears: initInvestmentMethodResultValue,
  yearsAvailableToInvest: initInvestmentMethodResultValue,
  investmentValue: initInvestmentMethodResultValue,
  futureValueOfPointsPaid: initInvestmentMethodResultValue,
  netInvestmentValue: initInvestmentMethodResultValue,
};

const initialState: PointsCalculatorContextProps = {
  activeStep: STEP.INTRO_SCREEN,
  handleNext: () => null,
  handleBack: () => null,
  handleInputChange: () => null,
  handleCalculation: () => null,
  isCalculationReady: false,
  calculatorValues: initCalculatorValues,
  breakEvenResult: {
    noPointsOffer: initBreakEvenValues,
    pointOfferOne: initBreakEvenValues,
    pointOfferTwo: initBreakEvenValues,
  },
  investmentMethodResult: {
    strategyOne: initInvestmentMethodResultStrategyOne,
    strategyTwo: initInvestmentMethodResultStrategyTwo,
  },
};

const PointsCalculatorContext = React.createContext(initialState);

const { Provider } = PointsCalculatorContext;

type PointsCalculatorContextProviderProps = {
  children: React.ReactChild | boolean | Array<JSX.Element>;
};

const finance = new Finance();

const PointsCalculatorContextProvider: React.FC<PointsCalculatorContextProviderProps> = ({
  children,
}: PointsCalculatorContextProviderProps) => {
  const [step, setStep] = React.useState(STEP.INTRO_SCREEN);
  const [isCalculationReady, setIsCalculationReady] = React.useState(false);
  const [
    calculatorValues,
    setCalculatorValues,
  ] = React.useState<CalculatorValues>(initCalculatorValues);
  const [breakEvenResult, setBreakEvenResult] = React.useState<BreakEvenProps>({
    noPointsOffer: initBreakEvenValues,
    pointOfferOne: initBreakEvenValues,
    pointOfferTwo: initBreakEvenValues,
  });
  const [
    investmentMethodResult,
    setInvestmentMethodResult,
  ] = React.useState<InvestmentMethodValues>({
    strategyOne: initInvestmentMethodResultStrategyOne,
    strategyTwo: initInvestmentMethodResultStrategyTwo,
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    isInterest?: boolean
  ) => {
    setCalculatorValues((pre) => ({
      ...pre,
      [e.target.name]: isInterest
        ? Number(Number(e.target.value).toFixed(3))
        : Number(e.target.value),
    }));
  };

  const handleBack = () => {
    if (STEP[step - 1]) return setStep(step - 1);
  };

  const handleNext = () => {
    if (STEP[step + 1]) return setStep(step + 1);
  };

  const checkProperties = (obj: any) => {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === 0) return false;
    }
    return true;
  };

  const calculatePointsPaidInDollars = (
    points: number,
    amount: number
  ): number => {
    const n = points / 100;
    const d = 100;
    return (n / d) * amount;
  };

  const calculateMonthlyPayment = (
    rate: number,
    term: number,
    amount: number
  ): number => {
    const i = rate / 100 / 12;
    const pmt = finance.PMT(i, term * 12, -amount);
    return pmt;
  };

  const calculateBreakEven = (
    saving: number,
    differenceInPoints: number
  ): number => {
    return differenceInPoints / saving / 12;
  };

  React.useEffect(() => {
    setIsCalculationReady(checkProperties(calculatorValues));
  }, [calculatorValues]);

  React.useEffect(() => {
    if (step !== STEP.RESULT_SCREEN) return;
    handleCalculation();
  }, [step]);

  const calculateFV = (term: number, monthlyPayment: number) => {
    const i = INVESTMENT_RETURN / 12;
    const numOfPeriod = term * 12;
    const fv = finance.FV(i, monthlyPayment, numOfPeriod);
    return fv;
  };

  const calculateReinvest = (
    delta: number,
    term: number,
    pmt: number,
    rate: number,
    balance: number,
    noPointsMonthlyPayment: number,
    noPointsRate: number
  ) => {
    const monthlyRate = rate / 100 / 12;
    const i = noPointsRate / 100 / 12;
    const noPointsPayment = payDownData(balance, i, noPointsMonthlyPayment);
    const normal = payDownData(balance, monthlyRate, pmt);
    const numOfPeriod = Math.round(term * 12);
    let sum = 0;
    for (let index = 0; index < numOfPeriod - 1; index++) {
      const numOfMonths = (numOfPeriod - (index + 1)) / 12;
      if (index === 358) {
        const fv = calculateFV(
          numOfMonths,
          noPointsPayment[index] - normal[index]
        );
        sum = sum + fv;
      } else {
        const fv = calculateFV(numOfMonths, delta);
        sum = sum + fv;
      }
    }
    return sum;
  };

  const calculateReinvestPaidOff = (
    reInvestTerm: number,
    monthlyPayment: number
  ) => {
    const reInvestMonths = Math.round(reInvestTerm * 12);
    let sum = 0;
    for (let index = 1; index <= reInvestMonths; index++) {
      const FV = calculateFV(index / 12, monthlyPayment);
      sum = sum + FV;
    }
    return sum;
  };

  const handleCalculation = () => {
    const monthlyPaymentNoOffer = calculateMonthlyPayment(
      calculatorValues.noPointsInterestRate,
      calculatorValues.mortgageTerm,
      calculatorValues.mortgageAmount
    );
    const pointsInDollarOfferOne = calculatePointsPaidInDollars(
      calculatorValues.offerOnePoint,
      calculatorValues.mortgageAmount
    );

    const monthlyPaymentOfferOne = calculateMonthlyPayment(
      calculatorValues.offerOneInterestRate,
      calculatorValues.mortgageTerm,
      calculatorValues.mortgageAmount
    );

    const deltaMonthlyPaymentOfferOne =
      monthlyPaymentNoOffer - monthlyPaymentOfferOne;

    const pointsInDollarOfferTwo = calculatePointsPaidInDollars(
      calculatorValues.offerTwoPoint,
      calculatorValues.mortgageAmount
    );

    const monthlyPaymentOfferTwo = calculateMonthlyPayment(
      calculatorValues.offerTwoInterestRate,
      calculatorValues.mortgageTerm,
      calculatorValues.mortgageAmount
    );
    const deltaMonthlyPaymentOfferTwo =
      monthlyPaymentNoOffer - monthlyPaymentOfferTwo;

    const totalMonthlyPaymentStrategyOne = {
      noPointsOffer: monthlyPaymentNoOffer,
      pointOfferOne: deltaMonthlyPaymentOfferOne + monthlyPaymentOfferOne,
      pointOfferTwo: deltaMonthlyPaymentOfferTwo + monthlyPaymentOfferTwo,
    };

    const loanPaidInYearsStrategyOne = {
      noPointsOffer: calculatorValues.mortgageTerm,
      pointOfferOne: getPaidOffTerm({
        rate: calculatorValues.offerOneInterestRate,
        balance: calculatorValues.mortgageAmount,
        payment: totalMonthlyPaymentStrategyOne.pointOfferOne,
      }),
      pointOfferTwo: getPaidOffTerm({
        rate: calculatorValues.offerTwoInterestRate,
        balance: calculatorValues.mortgageAmount,
        payment: totalMonthlyPaymentStrategyOne.pointOfferTwo,
      }),
    };

    const yearsAvailableToInvestStrategyOne = {
      noPointsOffer: 0,
      pointOfferOne:
        calculatorValues.mortgageTerm -
        loanPaidInYearsStrategyOne.pointOfferOne,
      pointOfferTwo:
        calculatorValues.mortgageTerm -
        loanPaidInYearsStrategyOne.pointOfferTwo,
    };

    const investmentValueInvestStrategyOne = {
      noPointsOffer: 0,
      pointOfferOne: calculateReinvestPaidOff(
        yearsAvailableToInvestStrategyOne.pointOfferOne,
        totalMonthlyPaymentStrategyOne.pointOfferOne
      ),
      pointOfferTwo: calculateReinvestPaidOff(
        yearsAvailableToInvestStrategyOne.pointOfferTwo,
        totalMonthlyPaymentStrategyOne.pointOfferTwo
      ),
    };
    const futureValueOfPointsPaidInvest = {
      noPointsOffer: 0,
      pointOfferOne: calculateFV(
        calculatorValues.mortgageTerm,
        pointsInDollarOfferOne
      ),
      pointOfferTwo: calculateFV(
        calculatorValues.mortgageTerm,
        pointsInDollarOfferTwo
      ),
    };

    const strategyOne: InvestmentMethodResult = {
      monthlyRequiredPayment: {
        noPointsOffer: monthlyPaymentNoOffer,
        pointOfferOne: monthlyPaymentOfferOne,
        pointOfferTwo: monthlyPaymentOfferTwo,
      },
      monthlyPrepayment: {
        noPointsOffer: 0,
        pointOfferOne: deltaMonthlyPaymentOfferOne,
        pointOfferTwo: deltaMonthlyPaymentOfferTwo,
      },
      totalMonthlyPayment: {
        noPointsOffer: monthlyPaymentNoOffer,
        pointOfferOne: deltaMonthlyPaymentOfferOne + monthlyPaymentOfferOne,
        pointOfferTwo: deltaMonthlyPaymentOfferTwo + monthlyPaymentOfferTwo,
      },
      monthlyInvestmentAmount: {
        noPointsOffer: 0,
        pointOfferOne: deltaMonthlyPaymentOfferOne + monthlyPaymentOfferOne,
        pointOfferTwo: deltaMonthlyPaymentOfferTwo + monthlyPaymentOfferTwo,
      },
      loanPaidInYears: loanPaidInYearsStrategyOne,
      yearsAvailableToInvest: {
        noPointsOffer: 0,
        pointOfferOne: Number(
          calculatorValues.mortgageTerm -
            Number(loanPaidInYearsStrategyOne.pointOfferOne.toFixed(1))
        ),
        pointOfferTwo: Number(
          calculatorValues.mortgageTerm -
            Number(loanPaidInYearsStrategyOne.pointOfferTwo.toFixed(1))
        ),
      },
      investmentValue: investmentValueInvestStrategyOne,
      futureValueOfPointsPaid: futureValueOfPointsPaidInvest,
      netInvestmentValue: {
        noPointsOffer: 0,
        pointOfferOne:
          investmentValueInvestStrategyOne.pointOfferOne -
          futureValueOfPointsPaidInvest.pointOfferOne,
        pointOfferTwo:
          investmentValueInvestStrategyOne.pointOfferTwo -
          futureValueOfPointsPaidInvest.pointOfferTwo,
      },
    };

    const investmentValueStrategyTwo = {
      noPointsOffer: undefined,
      pointOfferOne: calculateReinvest(
        deltaMonthlyPaymentOfferOne,
        calculatorValues.mortgageTerm,
        monthlyPaymentOfferOne,
        calculatorValues.offerOneInterestRate,
        calculatorValues.mortgageAmount,
        monthlyPaymentNoOffer,
        calculatorValues.noPointsInterestRate
      ),
      pointOfferTwo: calculateReinvest(
        deltaMonthlyPaymentOfferTwo,
        calculatorValues.mortgageTerm,
        monthlyPaymentOfferTwo,
        calculatorValues.offerTwoInterestRate,
        calculatorValues.mortgageAmount,
        monthlyPaymentNoOffer,
        calculatorValues.noPointsInterestRate
      ),
    };

    const strategyTwo: InvestmentMethodResult = {
      monthlyRequiredPayment: {
        noPointsOffer: monthlyPaymentNoOffer,
        pointOfferOne: monthlyPaymentOfferOne,
        pointOfferTwo: monthlyPaymentOfferTwo,
      },
      monthlyInvestmentAmount: {
        noPointsOffer: 0,
        pointOfferOne: deltaMonthlyPaymentOfferOne,
        pointOfferTwo: deltaMonthlyPaymentOfferTwo,
      },
      loanPaidInYears: {
        noPointsOffer: calculatorValues.mortgageTerm,
        pointOfferOne: calculatorValues.mortgageTerm,
        pointOfferTwo: calculatorValues.mortgageTerm,
      },
      yearsAvailableToInvest: {
        noPointsOffer: 0,
        pointOfferOne: calculatorValues.mortgageTerm,
        pointOfferTwo: calculatorValues.mortgageTerm,
      },
      investmentValue: investmentValueStrategyTwo,
      futureValueOfPointsPaid: {
        noPointsOffer: undefined,
        pointOfferOne: futureValueOfPointsPaidInvest.pointOfferOne,
        pointOfferTwo: futureValueOfPointsPaidInvest.pointOfferTwo,
      },
      netInvestmentValue: {
        noPointsOffer: 0,
        pointOfferOne:
          investmentValueStrategyTwo.pointOfferOne -
          futureValueOfPointsPaidInvest.pointOfferOne,
        pointOfferTwo:
          investmentValueStrategyTwo.pointOfferTwo -
          futureValueOfPointsPaidInvest.pointOfferTwo,
      },
    };

    setBreakEvenResult({
      pointOfferOne: {
        pointsInDollar: pointsInDollarOfferOne,
        monthlyPayment: monthlyPaymentOfferOne,
        deltaMonthlyPayment: deltaMonthlyPaymentOfferOne,
        breakEvenYears: calculateBreakEven(
          deltaMonthlyPaymentOfferOne,
          pointsInDollarOfferOne
        ),
      },
      pointOfferTwo: {
        pointsInDollar: pointsInDollarOfferTwo,
        monthlyPayment: monthlyPaymentOfferTwo,
        deltaMonthlyPayment: deltaMonthlyPaymentOfferTwo,
        breakEvenYears: calculateBreakEven(
          deltaMonthlyPaymentOfferTwo,
          pointsInDollarOfferTwo
        ),
      },
      noPointsOffer: {
        pointsInDollar: 0,
        monthlyPayment: monthlyPaymentNoOffer,
        deltaMonthlyPayment: 0,
        breakEvenYears: 0,
      },
    });

    setInvestmentMethodResult({
      strategyOne: strategyOne,
      strategyTwo: strategyTwo,
    });
  };

  const values: PointsCalculatorContextProps = {
    activeStep: step,
    handleNext,
    handleBack,
    handleInputChange,
    handleCalculation,
    calculatorValues,
    isCalculationReady,
    breakEvenResult,
    investmentMethodResult,
  };

  return <Provider value={values}>{children}</Provider>;
};

const usePointsCalculator = () => {
  const { ...props } = React.useContext(PointsCalculatorContext);
  return {
    ...props,
  };
};

export { usePointsCalculator, PointsCalculatorContextProvider };
