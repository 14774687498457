import React from "react";

import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";

import {
  DollarTextField,
  formatPercent,
  formatWholeDollars,
  PercentTextField,
} from "src/utils";

export const CompareOffers = ({
  goBack,
  onNext,
  formValues,
  handleChange,
  availableTerms,
  fixed,
  refiBalance,
}: any) => {
  return (
    <Card style={{ maxWidth: 900, margin: "0 auto" }}>
      <Typography variant="h2" component="h2">
        Enter Your Student Loan Refinance Details
      </Typography>
      <Typography variant="body1">
        If you don't have an offer yet, then check our partners to get your rate
        by clicking the button in the above header.
      </Typography>
      <TableContainer style={{ maxWidth: 1000 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Amount to Refinance</TableCell>
              <TableCell>Term in Years</TableCell>
              <TableCell>Interest Rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Refinance Offer #1</TableCell>
              <TableCell>
                <DollarTextField
                  name="compareAmount1"
                  variant="outlined"
                  onChange={handleChange}
                  value={formValues.compareAmount1 || ""}
                />
              </TableCell>
              <TableCell>
                <Select
                  name="compareTerm1"
                  variant="outlined"
                  onChange={handleChange}
                  value={formValues.compareTerm1}
                >
                  {availableTerms.map((term: number) => (
                    <MenuItem key={term} value={"" + term}>
                      {term}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <PercentTextField
                  name="compareRate1"
                  variant="outlined"
                  onChange={handleChange}
                  value={formValues.compareRate1 || ""}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Refinance Offer #2</TableCell>
              <TableCell>
                {!!fixed && formatWholeDollars(refiBalance)}
                {!fixed && (
                  <DollarTextField
                    name="compareAmount2"
                    variant="outlined"
                    onChange={handleChange}
                    value={formValues.compareAmount2 || ""}
                  />
                )}
              </TableCell>
              <TableCell>
                {!!fixed && formValues.refinanceTerm.toFixed(0)}
                {!fixed && (
                  <Select
                    name="compareTerm2"
                    variant="outlined"
                    onChange={handleChange}
                    value={formValues.compareTerm2}
                  >
                    {availableTerms.map((term: number) => (
                      <MenuItem key={term} value={"" + term}>
                        {term}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </TableCell>
              <TableCell>
                {!!fixed && formatPercent(formValues.refinanceRate)}
                {!fixed && (
                  <PercentTextField
                    name="compareRate2"
                    variant="outlined"
                    onChange={handleChange}
                    value={formValues.compareRate2 || ""}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        fullWidth
        className="my-4"
        fbColor="primary"
        disabled={
          !formValues.compareRate1 ||
          !formValues.compareAmount1 ||
          (!fixed && (!formValues.compareAmount2 || !formValues.compareRate2))
        }
        onClick={onNext}
      >
        Continue
      </Button>
      <Button fbColor="secondary" onClick={goBack} fullWidth>
        Back
      </Button>
    </Card>
  );
};

export default CompareOffers;
