export enum CURATED_PLAN_BUILD_STEPS {
  SHORT_TERM_GOAL = 1,
  EMPLOYER_RETIREMENT,
  EMPLOYER_RETIREMENT_SPOUSE,
  HSA,
  ROTH_IRA,
  ROTH_IRA_SPOUSE,
  INVEST_OR_PAYOFF,
  STUDENT_LOAN,
  STUDENT_LOAN_SPOUSE,
  HIGH_LOW,
  // MORTGAGE_DEBT,
  PERKINS_LOANS,
  PRIVATE_LOANS,
  CREDIT_CARD_LOANS,
  PERSONAL_LOANS,
  PRIMARY_MORTGAGE,
  INVESTMENT_PROPERTY_MORTGAGE,
  AUTO_DEBT,
  OTHER_DEBT,
}
