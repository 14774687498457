import produce from "immer";
import { handleActions } from "redux-actions";

import { InvestmentsState } from "../../interfaces";
import { FAIL, SUCCESS } from "../common";
import * as actions from "./actions";
import { LOG_OUT } from "../system/actions";

const initialState: InvestmentsState = {
  portfolioSettings: null,
  portfolioPerformance: [],
};

const reducerDefinitions: any = {
  [actions.GET_PORTFOLIO + SUCCESS]: (
    state: InvestmentsState,
    { payload }: any
  ) =>
    produce(state, (draft) => {
      draft.portfolioSettings = payload;
    }),
  [actions.GET_PORTFOLIO + FAIL]: (state: InvestmentsState) =>
    produce(state, (draft) => {
      draft.portfolioSettings = null;
    }),
  [actions.GET_PERFORMANCE + SUCCESS]: (
    state: InvestmentsState,
    { payload }: any
  ) =>
    produce(state, (draft) => {
      draft.portfolioPerformance = payload;
    }),
  [LOG_OUT]: () => initialState,
};

const investmentsReducer = handleActions<InvestmentsState, any>(
  reducerDefinitions,
  initialState
);

export default investmentsReducer;
