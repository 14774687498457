import React from "react";
import {
  Box,
  makeStyles,
  // Table,
  // TableBody,
  // TableCell,
  // TableHead,
  // TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import TitleCard from "src/components/TitleCard";
import { useRentVsBuy } from "../UseRentVsBuy";
import FbButton from "src/components/Button";
import { colors } from "src/theme";
import { Check, Close, Home } from "@material-ui/icons";
import { formatWholeDollars } from "src/utils";
// import { BUY_RESULT_DESCRIPTION, RENT_RESULT_DESCRIPTION } from "../CONSTANT";

const ResultStepScreen = () => {
  const classes = useStyles();
  const { handleBack, result } = useRentVsBuy();

  return (
    <Box className={classes.root}>
      <TitleCard title="Results">
        <Box className={classes.resultsContainer}>
          <Box>
            <Home className={classes.homeIcon} />
            {result?.recommendation === "buy" ? (
              <Check className={classes.checkIcon} />
            ) : (
              <Close className={classes.cancelIcon} />
            )}
          </Box>
          <Box className={classes.resultsContainerContent}>
            {result?.recommendation === "buy" && (
              <Typography>
                The average home price for a family of your size, in the ZIP
                code you provided, is {formatWholeDollars(result?.home_price)}.
              </Typography>
            )}

            <Typography>
              Based on the information provided, it would make more sense for
              you to{" "}
              {result?.recommendation === "buy" ? "purchase" : "not purchase"}{" "}
              your primary residence
              {result?.recommendation === "buy"
                ? ""
                : " and keep renting at this time"}
              .
            </Typography>
          </Box>
        </Box>
      </TitleCard>
      {/*  Summary Section commented out 
      <TitleCard title="Summary">
        <Box className={classes.container}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.headRow}>
                <TableCell className={classes.headCell} />
                <TableCell className={classes.headCell}>
                  FitBUX Score Today
                </TableCell>
                <TableCell className={classes.headCell}>
                  Expected FitBUX Score At Retirement
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell className={classes.titleCell}>
                  <Typography>Buying This Home</Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography>{result?.home_score_now}</Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography>{result?.home_score_retirement}</Typography>
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.titleCell}>
                  <Typography>Continuing To Rent</Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography>{result?.rent_score_now}</Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography>{result?.rent_score_retirement}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {result?.recommendation === "buy"
            ? BUY_RESULT_DESCRIPTION.map((content, index) => (
                <Typography key={index}>{content}</Typography>
              ))
            : RENT_RESULT_DESCRIPTION.map((content, index) => (
                <Typography key={index}>{content}</Typography>
              ))}
        </Box>
      </TitleCard> */}

      <Box className={classes.actionContainer}>
        <FbButton onClick={handleBack}>Back</FbButton>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  resultsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
  },
  homeIcon: {
    color: colors.brandingBlue1,
    height: 80,
    width: 80,
  },
  checkIcon: {
    height: 80,
    width: 80,
    color: theme.palette.success.main,
  },
  cancelIcon: {
    height: 70,
    width: 70,
    color: theme.palette.error.main,
    fontWeight: 900,
  },
  resultsContainerContent: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  table: {
    margin: "auto",
  },
  headRow: {},
  headCell: {
    fontWeight: 600,
    border: 0,
    textAlign: "center",
    wordBreak: "normal",
    color: colors.brandingBlue1,
  },

  row: {
    border: 0,
  },
  titleCell: {
    border: 0,
    "& .MuiTypography-root": {
      fontWeight: 500,
      fontSize: 13,
    },
  },
  cell: {
    border: 0,
    textAlign: "center",

    "& .MuiTypography-root": {
      fontWeight: 900,
      fontSize: 13,
    },
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
  },
}));

export default ResultStepScreen;
