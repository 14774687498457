import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Icon from "src/components/Icon";
import { HelpItem } from "src/interfaces";
import { clearHelp, setTutorial, setVideo } from "src/store/dashboard/actions";
import { VIMEO_VIDEOS, YOUTUBE_VIDEOS } from "src/store/dashboard/helpTopics";
import { getHelpItem } from "src/store/dashboard/selector";
import colors from "src/theme/colors";

const useStyles = makeStyles({
  visible: {
    background: colors.gradLightReverseHorizontal,
    border: `1px solid ${colors.brandingBlue1}`,
    position: "fixed",
    right: 0,
    top: 60,
    width: 295,
  },
  hidden: { display: "none" },
  header: { display: "flex", justifyContent: "space-between", padding: 20 },
  contentHeader: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.brandingBlue1,
    margin: 20,
  },
  contentContainer: { margin: 20, overflowY: "auto" },
  image: {},
  content: {},
  buttonContainer: {
    padding: "24px 20px",
  },
  tutorialButton: {
    display: "block",
    margin: "0 auto",
    width: 255,
  },
});

const Help = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const item: HelpItem | null = useSelector(getHelpItem);

  const close = () => {
    dispatch(clearHelp());
  };
  const openTutorial = () => {
    if (item) {
      if (item.topic !== "Placeholder Help") {
        dispatch(setTutorial(item?.tutorialKey || ""));
      } else {
        dispatch(setVideo(item.videoKey || ""));
      }
    }
  };

  if (!item) {
    return <div className={styles.hidden} />;
  }

  const videoKey = item.videoKey;
  let videoSrc = "";
  if (videoKey) {
    if (YOUTUBE_VIDEOS[videoKey]) {
      videoSrc = `https://www.youtube.com/embed/${YOUTUBE_VIDEOS[videoKey]}?enablejsapi=1`;
    } else {
      videoSrc = `https://player.vimeo.com/video/${VIMEO_VIDEOS[videoKey]}`;
    }
  }

  return (
    <Box className={styles.visible}>
      <Box className={styles.header}>
        <Typography variant="h2">Help</Typography>
        <IconButton aria-label="close" onClick={close}>
          <Icon iconName="fb-close" />
        </IconButton>
      </Box>
      <Divider />
      {/*<TextField
        className="m-5"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon iconName="fb-search" />
            </InputAdornment>
          )
        }}
        placeholder="Search..."
        value=""
        onChange={(e) => null}
      />
      <Divider />*/}
      <Typography variant="h3" className={styles.contentHeader}>
        {item.topic}
      </Typography>
      <Box className={styles.contentContainer}>
        {!!item.image && (
          <img alt={item.videoKey} className={styles.image} src={item.image} />
        )}
        {item.content.map((paragraph) => (
          <Typography key={paragraph} className={styles.content}>
            {paragraph}
          </Typography>
        ))}
        {!!videoKey && (
          <iframe
            src={videoSrc}
            width="265"
            height="180"
            frameBorder="0"
            allow="fullscreen; picture-in-picture"
            style={{ width: "100%" }}
            title={videoKey}
          ></iframe>
        )}
        {!!item.extraVideoKeys &&
          !!item.extraVideoKeys.length &&
          item.extraVideoKeys.map((key) => (
            <iframe
              key={key}
              src={videoSrc}
              width="265"
              height="180"
              frameBorder="0"
              allow="fullscreen; picture-in-picture"
              style={{ width: "100%" }}
              title={item.videoKey}
            ></iframe>
          ))}
      </Box>
      {(!!item.tutorialKey || item.topic === "Placeholder Help") && (
        <Box className={styles.buttonContainer}>
          <Button
            fbColor="primary"
            className={styles.tutorialButton}
            onClick={openTutorial}
          >
            Show Tutorial
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Help;
