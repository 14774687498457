import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";

import * as actions from "./actions";
import {
  addTransactionApi,
  confirmTransactionsApi,
  fetchTransactionsApi,
  fetchUnconfirmedTransactionsApi,
  syncTransactionApi,
} from "src/apiService/modules/transaction";
import { FAIL, START, SUCCESS } from "../common";

import { FbAction, NewTransaction } from "src/interfaces";

function* getUnconfirmedTransactions({ payload }: FbAction<number>) {
  try {
    if (payload) {
      yield call(syncTransactionApi, payload);
    }
    const data: { transactions: any[] } = yield call(
      fetchUnconfirmedTransactionsApi
    );
    yield put({
      type: actions.GET_UNCONFIRMED_TRANSACTIONS + SUCCESS,
      payload: data.transactions,
    });
  } catch (error) {
    yield put({
      type: actions.GET_UNCONFIRMED_TRANSACTIONS + FAIL,
      payload: error,
    });
  }
}

function* getTransactions({
  payload,
}: FbAction<actions.GetTransactionsPayload>) {
  try {
    const data: { transactions: any[] } = yield call(
      fetchTransactionsApi,
      payload.from,
      payload.to
    );
    yield put({
      type: actions.GET_TRANSACTIONS + SUCCESS,
      payload: data.transactions,
    });
  } catch (error) {
    yield put({ type: actions.GET_TRANSACTIONS + FAIL, payload: error });
  }
}

function* confirmTransactions({ payload }: FbAction<number[]>) {
  try {
    yield call(confirmTransactionsApi, payload);
    yield put({ type: actions.CONFIRM_TRANSACTIONS + SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.CONFIRM_TRANSACTIONS + FAIL, payload: error });
  }
}

function* addTransaction({ payload }: FbAction<NewTransaction>) {
  try {
    yield call(addTransactionApi, payload);
    yield put({ type: actions.ADD_TRANSACTION + SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.ADD_TRANSACTION + FAIL, payload: error });
  }
}

// function* pollForNewTransactions() {
//   while (true) {
//     yield delay(20000);
//     const subscribed: boolean = yield select(getIsSubscribed);
//     const suspended: boolean = yield select(getUpdatesSuspended);
//     if (subscribed && !suspended) {
//       yield getUnconfirmedTransactions({ payload: 0, type: "" });
//     }
//   }
// }

export function* transactionSagas() {
  yield all([
    takeLatest(
      actions.GET_UNCONFIRMED_TRANSACTIONS + START,
      getUnconfirmedTransactions
    ),
    takeLatest(actions.GET_TRANSACTIONS + START, getTransactions),
    takeEvery(actions.CONFIRM_TRANSACTIONS + START, confirmTransactions),
    takeEvery(actions.ADD_TRANSACTION + START, addTransaction),
    // pollForNewTransactions(),
  ]);
}
