import * as React from "react";

import { makeStyles } from "@material-ui/core";

import PlanBuilderLayout from "src/layouts/PlanBuilderLayout";
import { headerStyles } from "../../theme";
import { Content } from "./Content";

const useStyles = makeStyles(headerStyles);

const PlanBuild = () => {
  const classes = useStyles();
  return (
    <PlanBuilderLayout
      steps={["Build Plan", "Review & Compare", "Implement"]}
      pageTitle="Build a plan"
    >
      <main className={classes.mainContent}>
        <Content />
      </main>
    </PlanBuilderLayout>
  );
};

export default PlanBuild;
