import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Slider from "src/components/Slider";
import { setProfileStep } from "src/store/oldProfileBuild/actions";
import { getCurrentStep } from "src/store/oldProfileBuild/selector";
import { PROFILE_BUILD_STEPS } from "src/store/oldProfileBuild/constants";
import { ViewComponent } from "src/interfaces/viewComponent.interface";

const slides = [
  {
    image: "/assets/images/animations/human-fix.gif",
    subline: "",
    title: "Why Human Capital is Important",
    description:
      "Human Capital is the largest asset of young professionals. It's key in order to project your future income, the risk to your income, and determines your optimal financial plan.",
  },
  {
    image: "/assets/images/animations/hero.gif",
    subline: "",
    title: "How It Works",
    description:
      "FitBUX helps you identify the optimal way to convert your Human Capital To Financial Capital over time. Our technology makes it easy to build and implement your financial plan, helping you to reach your goals.",
  },
];

const Intro: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const page1: boolean =
    useSelector(getCurrentStep) === PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO_1;
  const nextStep: PROFILE_BUILD_STEPS = page1
    ? PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO_2
    : PROFILE_BUILD_STEPS.HUMAN_CAPITAL_OVERVIEW;
  const prevStep: PROFILE_BUILD_STEPS | null = page1
    ? PROFILE_BUILD_STEPS.PERSONAL_INFO
    : PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO_1;
  const onNext = () => dispatch(setProfileStep(nextStep));
  const onPrev = prevStep
    ? () => dispatch(setProfileStep(prevStep))
    : undefined;
  const onSkip = page1
    ? () => dispatch(setProfileStep(PROFILE_BUILD_STEPS.HUMAN_CAPITAL_OVERVIEW))
    : undefined;
  const onChanged = page1 ? onNext : onPrev;

  if (!render) {
    return <div />;
  }

  return render({
    component: (
      <Slider slides={slides} slide={page1 ? 0 : 1} onChanged={onChanged} />
    ),
    onNext,
    onPrev,
    onSkip,
  });
};

export default Intro;
