import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box, makeStyles } from "@material-ui/core";

import { QuestionFormProps } from "src/interfaces/optimizedPlanBuild.interface";
import CenterContainer from "src/pages/PlanBuilder/Components/CenterContainer";
import Home from "src/pages/PlanBuilder/Views/Review/ReviewViews/Home";
import Score from "src/pages/PlanBuilder/Views/Review/ReviewViews/Score";
import GoalSummary from "src/pages/PlanBuilder/Views/Review/ReviewViews/GoalSummary";
import AssetsInvestments from "src/pages/PlanBuilder/Views/Review/ReviewViews/AssetsInvestments";
import Debts from "src/pages/PlanBuilder/Views/Review/ReviewViews/Debts";
import NetWealth from "src/pages/PlanBuilder/Views/Review/ReviewViews/NetWealth";
import PayCheckAllocation from "src/pages/PlanBuilder/Views/Review/ReviewViews/PayCheckAllocation";
import { getLoadingProjection } from "src/store/planBuild/selector";
import { AppLoader } from "src/components";
import { colors } from "src/theme";

const fullMessage = "Hang tight while we optimizing you plan...";

const Transition = ({ reviewStepIndex }: QuestionFormProps) => {
  const styles = useStyles();
  const [index, setIndex] = useState(0);
  const loading = useSelector(getLoadingProjection);
  const [settingPlan, setSettingPlan] = useState(true);

  useEffect(() => {
    setIndex(reviewStepIndex + 1);
  }, [reviewStepIndex]);

  // fetch projections
  useEffect(() => {
    if (settingPlan && loading) {
      setSettingPlan(false);
    }
  }, [loading]);

  const Interstitial = () => (
    <CenterContainer title="Welcome To Your Optimized Plan">
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          marginTop: "1rem",
        }}
      >
        {fullMessage}
      </Box>
    </CenterContainer>
  );

  const Sections = [
    // add a section for the delay effect
    Interstitial,
    Home,
    Score,
    GoalSummary,
    AssetsInvestments,
    Debts,
    NetWealth,
    PayCheckAllocation,
  ];

  const Section = Sections[index];

  const ReviewLoading = () => (
    <Box
      style={{
        backgroundColor: colors.blueGray7,
      }}
      className="h-full w-full justify-center flex items-center"
    >
      {/* {fullMessage} */}
      <AppLoader />
    </Box>
  );

  return (
    <>
      {(settingPlan || loading) && (
        <Box className={styles.loaderContainer}>
          <ReviewLoading />
        </Box>
      )}
      <Section optimized />
    </>
  );
};

export default Transition;

const useStyles = makeStyles({
  mainContainer: {
    height: "calc(100vh - 170px)",
    width: "100%",
    paddingLeft: "20px",
    // overflowY: "hidden"
  },
  transitionContainer: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "center",
  },
  transition: {
    width: "50px",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  loaderContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 555,
    background: "rgba(255, 255, 255, .8)",
  },
  footer: {
    position: "absolute",
    backgroundColor: "white",
    bottom: "0px",
    width: "calc(100% + 60px)",
    height: "80px",
    marginLeft: "-100px",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  implement: {
    display: "flex",
    position: "relative",
    marginRight: "calc(50vw - 200px)",
  },
});
