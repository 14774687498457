import { applyMiddleware, combineReducers, createStore } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";

import rootSaga from "./rootSaga";
import accountReducer from "./account/reducers";
import tourCardReducer from "./tourcard/reducers";
import cashflowReducer from "./cashflow/reducers";
import dashboardReducer from "./dashboard/reducers";
import investmentsReducer from "./investments/reducers";
import systemReducer from "./system/reducers";
import planBuildReducer from "./planBuild/reducers";
import oldProfileBuildReducer from "./oldProfileBuild/reducers";
import profileBuildReducer from "./profileBuild/reducers";
import stripeReducer from "./stripe/reducers";
import transactionReducer from "./transaction/reducers";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const rootReducer = () => {
  const systemPersistConfig = {
    key: "system",
    storage: storage,
    whitelist: ["user", "loggedIn"],
  };
  return combineReducers({
    system: persistReducer(systemPersistConfig, systemReducer),
    account: accountReducer,
    cashflow: cashflowReducer,
    dashboard: dashboardReducer,
    investments: investmentsReducer,
    planBuild: planBuildReducer,
    oldProfileBuild: oldProfileBuildReducer,
    profileBuild: profileBuildReducer,
    stripe: stripeReducer,
    transaction: transactionReducer,
    tourCard: tourCardReducer,
    router: connectRouter(history),
  });
};

const rootReducerInstance = rootReducer();

export type AppState = ReturnType<typeof rootReducerInstance>;

const configureStore = async () => {
  const persistConfig = {
    key: "root",
    storage,
    whitelist: [],
  };

  const sagaMiddleware = createSagaMiddleware();

  const persistedReducer = persistReducer(persistConfig, rootReducerInstance);

  const store = createStore(
    persistedReducer,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware, routerMiddleware(history))
    )
  );

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
