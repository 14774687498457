import {
  Box,
  Card,
  Dialog,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import React from "react";
import { colors, theme } from "src/theme";

const EditOptions = ({
  handleClick,
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
  handleClick: (editOptimized: boolean) => void;
}) => {
  const styles = useStyles();

  const CardButton = ({
    title,
    text,
    onClick,
  }: {
    title: string;
    text: string;
    onClick: () => void;
  }) => {
    return (
      <Box className={styles.button} onClick={onClick}>
        <Typography className={styles.subTitle}>{title}</Typography>

        <Box className={styles.textBody}>
          <Typography>{text}</Typography>
        </Box>

        <Box className={styles.adornment}>
          <ArrowForwardIos />
          <ArrowForwardIos />
        </Box>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      {/* <Box className={styles.main}> */}
      <Card className={styles.container}>
        <Typography className={styles.title}>
          Choose One Of The Following:
        </Typography>
        <Divider className={styles.divider} />

        <Box className={styles.buttonContainer}>
          <CardButton
            title="Edit And Re-optimize Your Plan"
            text="Edit your answers in our optimizer and have FitBUX run a new optimized plan."
            onClick={() => {
              handleClick(true);
            }}
          />

          <CardButton
            title="Edit Your Optimized Plan Manually"
            text="This will convert your optimized plan into a manual plan which you can then edit and simulate manually."
            onClick={() => {
              handleClick(false);
            }}
          />
        </Box>
      </Card>
      {/* </Box> */}
    </Dialog>
  );
};

export default EditOptions;

const useStyles = makeStyles({
  main: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "9px",
    padding: "10px",
    // width: "800px",
    height: "350px",
    alignItems: "space-between",
  },
  divider: {
    width: "calc(100% - 10px)",
  },
  buttonContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  button: {
    height: "45%",
    width: "100%",
    margin: "2px auto",
    borderRadius: "9px",
    padding: "0 20px",
    position: "relative",
    border: "1px solid",
    borderColor: colors.blueGray5,
  },
  title: {
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.primary.main,
    margin: "10px",
  },
  subTitle: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginTop: "10px",
  },
  textBody: {
    height: "70%",
    display: "flex",
    marginRight: "20px",
    alignItems: "center",
  },
  adornment: {
    position: "absolute",
    right: "5px",
    bottom: "35%",
  },
  upgrade: {
    position: "absolute",
    bottom: "5px",
    right: "38%",
  },
});
