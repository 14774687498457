import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Box, CircularProgress, makeStyles } from "@material-ui/core";

import { getProjectedInvestmentReturnApi } from "src/apiService";
import CalculatorLayout from "src/layouts/CalculatorLayout";
import { Account, isInvestmentType } from "src/interfaces";
import { getAccounts } from "src/store/account/selector";
import Introduction from "./Introduction";
import Input from "./Input";
import SelectRisk from "./SelectRisk";
import Results from "./Results";

const useStyles = makeStyles({
  container: {
    margin: "20px auto",
    width: "90%",
    maxWidth: 1100,
  },
  loading: {
    margin: "120px auto",
    width: 0,
  },
});

enum STEPS {
  INTRODUCTION,
  INPUT,
  SELECT_RISK,
  RESULTS,
}

const INVESTMENT_PROJECTIONS_DISCLAIMER =
  "Disclaimer: FitBUX's recommended risk level and associated portfolio allocations are based on your FitBUX Score and the completeness of your FitBUX Profile. Therefore, FitBUX is not liable for incomplete or non-updated profiles. FitBUX is also not liable for you implementing the recommended risk level or associated portfolio allocations to your own portfolios. FitBUX's recommended allocations are based on our proprietary models developed by FitBUX RIA LLC. Projected portfolio values are estimated using a Monte Carlo simulation that takes into account historical returns from FitBUX’s recommended portfolios. The Average Annual Return reflects the annualized geometric mean return of the portfolio and takes into account the starting and ending balances as well as any monthly contributions.";

const InvestmentProjections = () => {
  const styles = useStyles();
  const accounts: Account[] = useSelector(getAccounts);
  const [investments, setInvestments] = useState<any[]>(
    Object.values(
      accounts
        .filter((account) => isInvestmentType(account.variable || ""))
        .reduce((result: any, account) => {
          const whoType = `${account.variable}__${account.who}`;
          if (!result[whoType]) {
            result[whoType] = {
              type: account.variable,
              balance: 0,
              contribution: 0,
              who: account.who,
            };
          }
          result[whoType].balance += account.balance;
          return result;
        }, {})
    )
  );
  const [allocation, setAllocation] = useState(50);
  const [step, setStep] = useState(STEPS.INTRODUCTION);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>({ data: [] });

  const getResults = () => {
    setLoading(true);
    const portfolio = `${100 - allocation}_${allocation}`;
    getProjectedInvestmentReturnApi({
      portfolio,
      accounts: investments.map((item) => ({
        type: item.type || "",
        balance: item.balance || 0,
        monthly_contribution: item.contribution || 0,
      })),
    }).then((response: any) => {
      setLoading(false);
      const formattedResults = response.values_25.map(
        (value: number, index: number) => ({
          value_75: response.values_75[index],
          value_50: response.values_50[index],
          value_25: response.values_25[index],
          month: index * 12,
        })
      );
      setResults({
        amount_75: response.amount_75,
        amount_50: response.amount_50,
        amount_25: response.amount_25,
        annual_return_75: response.annual_return_75,
        annual_return_50: response.annual_return_50,
        annual_return_25: response.annual_return_25,
        years: response.years,
        data: formattedResults,
      });
      setStep(STEPS.RESULTS);
    });
  };

  const renderStep = () => {
    if (loading) {
      return (
        <Box className={styles.loading}>
          <CircularProgress size={80} />
        </Box>
      );
    }
    switch (step) {
      case STEPS.INTRODUCTION:
        return <Introduction next={() => setStep(STEPS.INPUT)} />;
      case STEPS.INPUT:
        return (
          <Input
            back={() => setStep(STEPS.INTRODUCTION)}
            investments={investments}
            setInvestments={setInvestments}
            next={() => setStep(STEPS.SELECT_RISK)}
          />
        );
      case STEPS.SELECT_RISK:
        return (
          <SelectRisk
            back={() => setStep(STEPS.INPUT)}
            allocation={allocation}
            setAllocation={setAllocation}
            next={getResults}
          />
        );
      case STEPS.RESULTS:
        return (
          <Results results={results} back={() => setStep(STEPS.SELECT_RISK)} />
        );
    }
  };

  return (
    <CalculatorLayout
      title="Investment Projections"
      backTo="/investments"
      disclaimer={INVESTMENT_PROJECTIONS_DISCLAIMER}
    >
      <Box className={styles.container}>{renderStep()}</Box>
    </CalculatorLayout>
  );
};

export default InvestmentProjections;
