import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";

import Debts from "./Debts";
import Assets from "./Assets";

const incomeAndExpensesView = {
  [PROFILE_BUILD_STEPS.DEBTS]: Debts,
  [PROFILE_BUILD_STEPS.ASSETS]: Assets,
};

export default incomeAndExpensesView;
