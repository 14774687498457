import React from "react";
import { Box, Card, makeStyles, Typography } from "@material-ui/core";

import { DollarTextField } from "src/utils";
import HelpToggle from "src/components/HelpToggle";
import Button from "src/components/Button";
import colors from "src/theme/colors";

const useStyles = makeStyles({
  mainContainer: {},
  innerBox: {
    backgroundColor: "white",
    width: "80%",
    padding: "20px",
    margin: "20px auto",
    "& h1": {
      fontSize: "20px",
    },
  },
  top: {
    width: "100%",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "20px 20px 0px 20px",
  },
  transitions: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
  },
  dollarBox: {
    borderRadius: 5,
    border: `1px solid ${colors.blueGray5}`,
    height: "28.5px",
    padding: "0px 10px",
  },
});

const ConfirmDownPayment = ({
  transition,
  downPaymentAmount,
  setDownPaymentAmount,
}: {
  transition: (
    next?: boolean,
    showResults?: boolean,
    incomeData?: any[]
  ) => void;
  downPaymentAmount: number;
  setDownPaymentAmount: (value: number) => void;
}) => {
  const classes = useStyles();
  const handleChange = (event: any) => {
    let value = +event.target.value;
    if (isNaN(value)) {
      value = +event.target.value.slice(0, event.target.value.length - 1);
    }
    setDownPaymentAmount(value);
  };

  return (
    <Box className={classes.mainContainer}>
      <Card className={classes.innerBox}>
        <Box className={classes.top}>
          <Typography variant="h1">
            Confirm Your Down Payment <HelpToggle />
          </Typography>
        </Box>

        <Box className={classes.content}>
          <Typography paragraph>
            How much do you expect to have saved for your down payment at the
            time of purchase?
          </Typography>

          <Box className={classes.dollarBox}>
            <DollarTextField
              className="input"
              placeholder="$"
              value={downPaymentAmount}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </Card>

      <Box className={classes.transitions}>
        <Button
          onClick={() => {
            transition(false);
          }}
        >
          Back
        </Button>

        <Button
          onClick={() => {
            transition(true, true);
          }}
          color="primary"
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmDownPayment;
