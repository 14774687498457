import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  makeStyles,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import { profileBuilderStyles } from "src/theme";

import CenterContainer from "../../Components/CenterContainer";
import Button from "src/components/Button";
import {
  savePlan,
  updateCurrentPlan,
  estimateCurrentPlanStudentTaxes,
} from "src/store/planBuild/actions";
import { getCurrentPlan } from "src/store/planBuild/selector";
import {
  getGraduationYear,
  getUserInSchool,
} from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { FUNDING_INCOME_TYPES, Plan, PlanViewComponent } from "src/interfaces";
import { DollarTextField } from "src/utils";

const useStyles = makeStyles(profileBuilderStyles);

const AddIncome: PlanViewComponent = ({ onSave, onClose, render }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const plan: Plan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const userInSchool = useSelector(getUserInSchool);
  const graduationYear = useSelector(getGraduationYear);
  const [formValues, setFormValues] = useState<any>({
    type: "salary",
    who: userInSchool ? "applicant" : "spouse",
  });

  const save = () => {
    if (!formValues.earning) {
      return onClose();
    }
    const currentYear = +new Date().getFullYear();
    const newIncomes = [];
    for (let i = currentYear; i <= graduationYear; i++) {
      newIncomes.push({
        date: "" + i,
        earning: formValues.earning,
        type: formValues.type,
        who: formValues.who,
      });
    }
    const newPlan = {
      education: [...(plan.education || []), ...newIncomes],
    };
    dispatch(updateCurrentPlan(newPlan));
    dispatch(estimateCurrentPlanStudentTaxes());
    dispatch(savePlan(null));
    onSave();
  };

  const setFormValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    const newFormValues = { ...formValues, [field]: value };
    setFormValues(newFormValues);
  };

  return render({
    component: (
      <CenterContainer title="Add Income" iconName="fb-money">
        <Box className="flex items-center mt-4">
          <FormControl>
            <FormLabel className="mb-4">Type</FormLabel>
            <Select
              variant="outlined"
              fullWidth
              value={formValues.type}
              name="type"
              placeholder="Select"
              onChange={setFormValue}
            >
              {Object.entries(FUNDING_INCOME_TYPES).map(([key, label]) => (
                <MenuItem value={key} key={key}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className="ml-4">
            <FormLabel className="mb-4">Annual Amount</FormLabel>
            <DollarTextField
              variant="outlined"
              name="earning"
              value={formValues.earning}
              onChange={setFormValue}
            />
          </FormControl>
          {isMarried && (
            <FormControl className="ml-4">
              <FormLabel className="mb-4">Mine/Spouse</FormLabel>
              <Select
                variant="outlined"
                fullWidth
                value={formValues.who}
                name="who"
                placeholder="Select"
                onChange={setFormValue}
              >
                <MenuItem value="applicant">Mine</MenuItem>
                <MenuItem value="spouse">My spouse's</MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>
        <Button
          variant="outlined"
          fbColor="primary"
          onClick={onClose}
          className={styles.btnCancel}
        >
          Cancel
        </Button>
      </CenterContainer>
    ),
    nextDisabled: !formValues.earning,
    nextLabel: "Save",
    nextTooltip: !formValues.earning
      ? "Please enter an income amount."
      : undefined,
    onNext: save,
  });
};

export default AddIncome;
