import { Box, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import Button from "src/components/Button";
import TitleCard from "src/components/TitleCard";
import { INTRO_SCREEN_HELP } from "../CONSTANTS";
import { usePointsCalculator } from "../UsePointsCalculator";

const PointsCalculatorIntroScreen = () => {
  const classes = useStyles();
  const { handleNext } = usePointsCalculator();
  return (
    <Box className={classes.root}>
      <TitleCard
        title="Should you pay points on your mortgage?"
        sideContent={INTRO_SCREEN_HELP}
      >
        <Box className={classes.container}>
          <Typography>
            This calculator helps you determine if paying points on a mortgage
            is beneficial to you and, if so, the optimal number of points you
            should pay.
          </Typography>
          <Typography>
            There are three methods to calculate the benefit of paying points
            which are included in this calculator:
          </Typography>
          <Box>
            <Typography>
              1) The Break-Even Method (Most Common Method) and
            </Typography>
            <Typography>
              2) The 2 Investment Methods (More Advanced Methods).
            </Typography>
          </Box>
        </Box>
      </TitleCard>
      <Box className={classes.actionContainer}>
        <Button fbColor="primary" onClick={() => handleNext()}>
          Let's Get Started
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
}));

export default PointsCalculatorIntroScreen;
