import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";

const useStyles = makeStyles({
  text: { fontSize: 14 },
  buttonContainer: {
    margin: "40px auto",
    display: "flex",
    justifyContent: "center",
  },
});

const Introduction = ({ next }: any) => {
  const styles = useStyles();

  return (
    <>
      <Card title="What This Calculator Provides">
        <Box className="mt-4">
          <Typography paragraph className={styles.text}>
            This calculator gives you the ability to project the value of your
            investments invested in the stock market.
          </Typography>
          <Typography paragraph className={styles.text}>
            In addition, it gives you the ability to change investment risk
            levels so you can see the impact on future portfolio values.
          </Typography>
        </Box>
      </Card>
      <Box className={styles.buttonContainer}>
        <Button fbColor="primary" onClick={next}>
          Continue
        </Button>
      </Box>
    </>
  );
};

export default Introduction;
