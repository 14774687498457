import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cloneDeep, range } from "lodash";

import { Box } from "@material-ui/core";
import MainCard from "src/components/MainCard";

// import { profileBuilderStyles } from "src/theme";

import {
  estimateCurrentPlanStudentTaxes,
  savePlan,
  setBuildStep,
  setPlanDirty,
  updateCurrentPlan,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  getCurrentPlan,
  getCurrentPlanEducationDetails,
} from "src/store/planBuild/selector";
import {
  getLastGraduationYearMonth,
  getSpouseInSchool,
  getUserInSchool,
} from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import {
  ASSET_TYPES,
  EDUCATION_EXPENSE_TYPES,
  FUNDING_INCOME_TYPES,
  Plan,
  PlanViewComponent,
} from "src/interfaces";
import AddFunding from "./AddFunding";
import { formatWholeDollars } from "src/utils";

// const useStyles = makeStyles(profileBuilderStyles);

const now = new Date();
const nowYear = now.getFullYear();
const nowMonth = now.getMonth() + 1;

const MainForm: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const isMarried = useSelector(getIsMarried);
  const spouseInSchool = useSelector(getSpouseInSchool);
  const userInSchool = useSelector(getUserInSchool);
  const plan: Plan = useSelector(getCurrentPlan);
  const [graduationYear] = useSelector(getLastGraduationYearMonth);
  const { funding, expenses, fundingTotal, expenseTotal }: any = useSelector(
    getCurrentPlanEducationDetails
  );
  const [newExpense, setNewExpense] = useState<any>(null);
  const [addingFunding, setAddingFunding] = useState(false);
  const [editingSpecialType, setEditingSpecialType] = useState<any>(null);

  const years = range(nowYear, graduationYear + 1).map((n) => "" + n);

  const monthYears = useMemo(() => {
    const output = [];
    for (let m = nowMonth; m <= 12; m++) {
      let displayMonth = "" + m;
      if (+displayMonth < 10) {
        displayMonth = "0" + displayMonth;
      }
      output.push(`${displayMonth}/${nowYear}`);
    }
    for (let y = nowYear + 1; y <= graduationYear; y++) {
      for (let m = 1; m <= 12; m++) {
        let displayMonth = "" + m;
        if (+displayMonth < 10) {
          displayMonth = "0" + displayMonth;
        }
        output.push(`${displayMonth}/${y}`);
      }
    }
    return output;
  }, [graduationYear]);

  const saveFunding = (item: any, updateFields: any) => {
    const education: any = cloneDeep(plan.education || []);
    const newItem: any = { ...(plan.education?.[item.editIndex] || {}) };
    education[item.editIndex] = newItem;
    if (item.type in FUNDING_INCOME_TYPES) {
      newItem.earning = updateFields.amount;
      newItem.date = updateFields.displayDate;
      newItem.who = updateFields.who;
    } else {
      newItem.payment = updateFields.amount;
      newItem.who = updateFields.who;
      if (updateFields.displayDate.length > 4) {
        const month = updateFields.displayDate.slice(0, 2);
        const year = updateFields.displayDate.slice(3);
        newItem.date = `${year}-${month}`;
      }
    }
    dispatch(updateCurrentPlan({ education }));
    if (item.type in FUNDING_INCOME_TYPES) {
      // Income changed, taxes may change
      dispatch(estimateCurrentPlanStudentTaxes());
    }
    dispatch(savePlan(null));
  };

  const saveExpense = (item: any, updateFields: any) => {
    const rawDate = updateFields.year || item.year;
    const newItem: any = {
      payment: updateFields.amount || item.amount,
      who: updateFields.who || item.who,
      type: updateFields.type || item.type,
    };
    if (rawDate.length > 4) {
      newItem.date = `${rawDate.slice(3, 7)}-${rawDate.slice(0, 2)}`;
    } else {
      newItem.date = item.date;
    }
    const education = cloneDeep(plan.education || []);
    if (item.editIndex >= 0) {
      education[item.editIndex] = newItem;
    } else {
      education.push(newItem);
    }
    dispatch(updateCurrentPlan({ education }));
    dispatch(savePlan(null));
    setNewExpense(null);
  };

  const onNext = () =>
    dispatch(setBuildStep(PLAN_BUILD_STEPS.EXPENSES_AND_INSURANCE));

  const defaultNewExpense = {
    amount: "",
    editIndex: -1,
    type: "",
    year: `${nowMonth}/${nowYear}`,
    who: userInSchool ? "applicant" : "spouse",
  };
  const addFunding = () => setAddingFunding(true);
  const closeAddEdit = () => {
    setAddingFunding(false);
    setEditingSpecialType(false);
  };
  const addNewExpense = () => setNewExpense(defaultNewExpense);
  const removeItem = (item: any) => {
    if (item.editIndex < 0) {
      return setNewExpense(null);
    }
    const education = cloneDeep(plan.education || []);
    education.splice(item.editIndex, 1);
    dispatch(updateCurrentPlan({ education }));
    if (item.type in FUNDING_INCOME_TYPES) {
      // Income changed, taxes may change
      dispatch(estimateCurrentPlanStudentTaxes());
    }
    dispatch(savePlan(null));
  };

  const openForEditing = (item: any) => {
    setAddingFunding(true);
    setEditingSpecialType(item);
  };

  const fundingColumns: any[] = [
    { label: "Type", field: "type", type: "fixed", width: "40%" },
    {
      label: "Date",
      field: "displayDate",
      type: "select",
      width: "20%",
      items: (row: any) => {
        if ((FUNDING_INCOME_TYPES as any)[row.type]) {
          return years;
        }
        if ((ASSET_TYPES as any)[row.type]) {
          return ["N/A"];
        }
        return monthYears;
      },
    },
    {
      label: "Amount",
      field: "amount",
      type: "number",
      width: "20%",
      formatter: formatWholeDollars,
    },
  ];
  const expenseColumns: any[] = [
    {
      label: "Type",
      field: "type",
      type: "select",
      width: "40%",
      items: EDUCATION_EXPENSE_TYPES,
    },
    {
      label: "Year",
      field: "year",
      type: "select",
      width: "20%",
      items: monthYears,
    },
    {
      label: "Amount",
      field: "amount",
      type: "number",
      width: "20%",
      formatter: formatWholeDollars,
    },
  ];
  if (isMarried) {
    const whoColumn = {
      label: "Owner",
      field: "who",
      type: "select",
      width: "30%",
      items: {
        applicant: "Mine",
        spouse: "Spouse",
      },
    };
    fundingColumns.push(whoColumn);
    fundingColumns[0].width = "30%";
    if (spouseInSchool) {
      expenseColumns.push(whoColumn);
      expenseColumns[0].width = "30%";
    }
  }

  const showExpense: any[] = [...expenses];
  if (newExpense) {
    showExpense.push(newExpense);
  }

  if (addingFunding) {
    return (
      <AddFunding
        editingSpecialType={editingSpecialType}
        onClose={closeAddEdit}
        render={render}
      />
    );
  }

  return render({
    component: (
      <Box>
        <MainCard
          iconName="fb-money"
          title="Education Funding"
          columns={fundingColumns}
          data={funding}
          allowSelect
          onAdd={addFunding}
          specialEdit={{
            check: (item: any) => {
              return ["funding", "parent_loan"].indexOf(item.type) >= 0;
            },
            handle: openForEditing,
          }}
          onDelete={removeItem}
          onSave={saveFunding}
          summaryRow={{
            type: "Total",
            amount: fundingTotal,
          }}
          setDirtyFlag={() => dispatch(setPlanDirty(true))}
        />
        <MainCard
          iconName="fb-wallet"
          title="Education Costs (Excluding Living Costs)"
          columns={expenseColumns}
          data={showExpense}
          allowSelect
          defaultNewItem={defaultNewExpense}
          onAdd={addNewExpense}
          onCancelEdit={() => setNewExpense(null)}
          onDelete={removeItem}
          onSave={saveExpense}
          summaryRow={{
            type: "Total",
            amount: expenseTotal,
          }}
          setDirtyFlag={() => dispatch(setPlanDirty(true))}
        />
      </Box>
    ),
    onNext,
    nextLabel: "Next Section",
  });
};

export default MainForm;
