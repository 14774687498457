import {
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiTitle,
  EuiIcon,
  EuiText,
  EuiButton,
  EuiFlyoutFooter,
  EuiSpacer,
} from "@elastic/eui";
import { useFlyout } from "./FlyoutContext";
import FlyoutInfo from "./FlyoutInfo";
import styled from "@emotion/styled";
import { StyledEuiButton } from "./StyledComponents";

export const StyledFlyoutHeader = styled(EuiFlyoutHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StyledFlyout = styled(EuiFlyout)`
  top: 0;
  position: fixed;
  z-index: 1000;

  @media (max-width: 576px) {
    width: 100vw !important;
    max-inline-size: 100vw !important;
  }

  @media (min-width: 576px) and (max-width: 992px) {
    width: 70vw !important;
    max-inline-size: 70vw !important;
  }
`;
const StyledEuiIcon = styled(EuiIcon)`
  width: 24px;
  height: 24px;
`;
export const StyledEuiFlyoutFooter = styled(EuiFlyoutFooter)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent;
  border-top: 1px solid #e9edf3;
  button {
    font-size: 16px;
  }
`;

function Flyout() {
  const { isFlyoutOpen, closeFlyout, infoType, customFlyout } = useFlyout();
  if (!isFlyoutOpen && !customFlyout?.component) return null;

  const contentAction = FlyoutInfo({
    infoType: infoType,
    componentType: "contentAction",
  });
  const submitAction = FlyoutInfo({
    infoType: infoType,
    componentType: "submitAction",
  });
  const cancelAction = FlyoutInfo({
    infoType: infoType,
    componentType: "cancelAction",
  });
  const { component: Component, title, ...props } = customFlyout;

  return (
    <StyledFlyout
      className="customFlyout"
      ownFocus={true}
      size="s"
      onClose={closeFlyout}
    >
      <StyledFlyoutHeader hasBorder>
        <EuiTitle size="m">
          <h2>
            {title ||
              FlyoutInfo({ infoType: infoType, componentType: "header" })}
          </h2>
        </EuiTitle>
        <StyledEuiIcon
          type="cross"
          onClick={closeFlyout}
          style={{ cursor: "pointer" }}
        />
      </StyledFlyoutHeader>
      {!!Component && <Component {...props} />}
      {!Component && (
        <>
          <EuiFlyoutBody>
            <EuiText>
              {FlyoutInfo({ infoType: infoType, componentType: "body" })}
            </EuiText>
            <EuiSpacer size="l" />
            {!!contentAction && (
              <EuiButton size="s" color="text">
                {contentAction}
              </EuiButton>
            )}
          </EuiFlyoutBody>
          <StyledEuiFlyoutFooter>
            {!!cancelAction && (
              <StyledEuiButton color="text" className="btn-spacing">
                {cancelAction}
              </StyledEuiButton>
            )}
            {!!submitAction && (
              <EuiButton color="primary" fill onClick={closeFlyout}>
                {submitAction}
              </EuiButton>
            )}
          </StyledEuiFlyoutFooter>
        </>
      )}
    </StyledFlyout>
  );
}

export default Flyout;
