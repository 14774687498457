import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { maxBy } from "lodash";
import { Finance } from "financejs";

import {
  EuiButtonEmpty,
  EuiCheckbox,
  EuiFieldText,
  EuiFormRow,
  EuiFlyoutBody,
  EuiButton,
  EuiSuperSelect,
  EuiText,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalFooter,
  useGeneratedHtmlId,
} from "@elastic/eui";

import { StyledEuiFlyoutFooter } from "src/components/Global/Flyout";
import {
  StyledEuiButton,
  StyledSpan,
  StyledBsAsterisk,
  StyledSpacer,
  LabelStyle,
  LabelAsterisk,
} from "src/components/Global/StyledComponents";
import FormError from "src/components/Global/FormError";
import { addAccountApi } from "src/apiService";
import { useFlyout } from "src/components/Global/FlyoutContext";
import {
  Account,
  ASSET_TYPES,
  DEBT_TYPES,
  FbAction,
  isInvestmentType,
  MappedAccount,
  REPAYMENT_PLANS,
} from "src/interfaces";
import {
  getFormattedAssetsAndDebts,
  getLinkedAccounts,
} from "src/store/account/selector";
import {
  addAccount,
  addAccountSuccess,
  deleteAccount,
  removeAccount,
  updateAccount,
  UpdateAccountPayload,
} from "src/store/account/actions";
import { PROFILE_TYPE } from "src/store/profileBuild/constants";
import {
  getDebts,
  getSpouseDebts,
  getStudentStatus,
  getSpouseStudentStatus,
  getSpouseInSchool,
  getUserInSchool,
} from "src/store/profileBuild/selector";
import {
  getIsMarried,
  spouseSelector,
  userSelector,
} from "src/store/system/selector";
import { updateDebts } from "src/store/profileBuild/actions";
import { getAccountApi, removeLinkedAccountApi } from "src/apiService";
import { DollarTextField, PercentTextField } from "src/utils";
import FedLoansForm, { ESTIMATED_FEDERAL_LOAN_BALANCE } from "./FedLoansForm";
import InvestmentAllocations from "./InvestmentAllocations";
import { useInvestments } from "./hooks";
import InfoIcon from "src/components/InfoIcon";
import styled from "@emotion/styled";

export const StyledEuiModalHeader = styled(EuiModalHeader)`
  padding-inline: 24px 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const StyledEuiIcon = styled(EuiIcon)`
  width: 24px;
  height: 24px;
`;

const finance = new Finance();

const initialValues: any = {
  id: 0,
  type: "",
  firm: "",
  name: "",
  who: PROFILE_TYPE.APPLICANT,
  balance: 0,
  balance_live: 0,
  rate: 0,
  payment: 0,
  carrying: "y",
  "asset-balance": 0,
  is_included: "n",
  months: 1,
  years: 0,
  fed_loans: [],
  fed_repayment_plan: Object.keys(REPAYMENT_PLANS)[0],
};

interface IProps {
  isDebt?: boolean;
  selected?: MappedAccount;
}

const AddEditManualAccountForm = ({ isDebt, selected }: IProps) => {
  const dispatch = useDispatch();
  const { debts }: { debts: MappedAccount[] } = useSelector(
    getFormattedAssetsAndDebts
  );
  const linkedAccounts = useSelector(getLinkedAccounts);
  // const loading = useSelector(getAccountsLoading);
  // const loaded = useSelector(getAccountsLoaded);
  const isMarried = useSelector(getIsMarried);
  const myProfileDebts = useSelector(getDebts);
  const spouseProfileDebts = useSelector(getSpouseDebts);
  const myMonthsSinceGraduation = useSelector(getStudentStatus);
  const spouseMonthsSinceGraduation = useSelector(getSpouseStudentStatus);
  const userInSchool = useSelector(getUserInSchool);
  const spouseInSchool = useSelector(getSpouseInSchool);
  const user = useSelector(userSelector);
  const userFirstName = user?.first_name || "";
  const spouse = useSelector(spouseSelector);
  const spouseFirstName = spouse?.first_name || "";
  const [formValues, setFormValues] = useState<any>(initialValues);
  const [enableFedLoanBreakouts, setEnableFedLoanBreakouts] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [lockWho, setLockWho] = useState(false);
  const { closeFlyout } = useFlyout();
  const investmentData = useInvestments({
    adjustAccountBalance: (balance: number) => {
      setFormValues((current: any) => ({ ...current, balance }));
    },
  });
  const { initializeInvestments, saveInvestments } = investmentData;
  useEffect(() => {
    if (selected && isInvestmentType(selected.variable)) {
      initializeInvestments(selected.id);
    }
  }, []);

  const myFedLoans = (debts || []).filter(
    (account) =>
      account.variable === "fed_loan" &&
      (account.whose || account.who) !== PROFILE_TYPE.SPOUSE
  );
  const spouseFedLoans = (debts || []).filter(
    (account) =>
      account.variable === "fed_loan" &&
      (account.whose || account.who) === PROFILE_TYPE.SPOUSE
  );

  const handleSelect = (fieldName: string) => (value: string) => {
    if (fieldName === "type") {
      setFormValues((current: any) => {
        const newFormValues = { ...current, type: value };
        if (value === "fed_loan") {
          if (myFedLoans.length || spouseFedLoans.length) {
            setLockWho(true);
            newFormValues.who = myFedLoans.length ? "spouse" : "applicant";
          }
        } else {
          setLockWho(false);
        }
        return newFormValues;
      });
    } else {
      handleChange(fieldName)({ target: { value } } as any);
    }
  };

  const setFedLoanDetails = (
    loanDetails: Account[],
    plan: any,
    isSpouse: boolean
  ) => {
    const currentMonthsSinceGraduation = isSpouse
      ? spouseMonthsSinceGraduation
      : myMonthsSinceGraduation;
    if (
      (loanDetails.length === 1 &&
        loanDetails[0].name === ESTIMATED_FEDERAL_LOAN_BALANCE) ||
      currentMonthsSinceGraduation < 6
    ) {
      const loan =
        monthsSinceGraduation < 6
          ? maxBy(loanDetails, (item) => item.balance)
          : loanDetails[0];
      setFormValues((current: any) => ({
        ...current,
        ...loan,
        fed_repayment_plan: plan,
        fed_loans: loanDetails,
        type: "fed_loan",
      }));
      setEnableFedLoanBreakouts(false);
    } else {
      let rateNumerator = 0;
      let rateDenominator = 0;
      let totalPayment = 0;
      loanDetails.forEach((loan: Account) => {
        rateNumerator += loan.rate || 0;
        rateDenominator += loan.balance || 0;
        totalPayment += loan.payment || 0;
      });
      setFormValues((current: any) => ({
        ...current,
        ...loanDetails[0],
        balance: rateDenominator,
        payment: totalPayment,
        rate: rateDenominator ? rateNumerator / rateDenominator : 0,
        fed_repayment_plan: plan,
        fed_loans: loanDetails,
        type: "fed_loan",
      }));
      setEnableFedLoanBreakouts(true);
    }
  };

  const getAccountDetails = async (newSelected: any) => {
    if (newSelected) {
      const isSpouse =
        (newSelected.who || newSelected.whose) === PROFILE_TYPE.SPOUSE;
      if (newSelected.variable === "fed_loan") {
        const fedLoans = isSpouse ? spouseFedLoans : myFedLoans;
        const plan = isSpouse
          ? spouseProfileDebts.fed_repayment_plan
          : myProfileDebts.fed_repayment_plan;
        if (fedLoans && fedLoans.length) {
          Promise.all(
            fedLoans.map((loan) => getAccountApi(loan.id))
          ).then((accountDetails) =>
            setFedLoanDetails(accountDetails, plan, isSpouse)
          );
        } else {
          setFormValues({
            ...formValues,
            fed_repayment_plan: plan,
            fed_loans: [],
          });
        }
      } else {
        getAccountApi(newSelected.id).then((data) => {
          setFormValues({
            ...newSelected,
            carrying: data.carrying ? "y" : "n",
            type: newSelected.variable,
            who: isSpouse ? PROFILE_TYPE.SPOUSE : PROFILE_TYPE.APPLICANT,
            fed_loans: [],
            manual: data.manual,
            payment: data.payment,
            rate: data.rate,
            balance: data.balance || data.balance_live,
            balance_live: data.balance_live || data.balance,
          });
        });
      }
    } else {
      setFormValues(initialValues);
    }
  };

  useEffect(() => {
    if (selected) {
      getAccountDetails(selected);
    }
  }, [selected]);

  const checkIfLinked = (id: number) => {
    return !!(linkedAccounts as any[]).find((e) => e.id === id);
  };

  const isLinked = checkIfLinked(selected?.id || 0);

  const matchedLinkedAccount = isLinked
    ? linkedAccounts.find((item) => item.id === selected?.id)
    : null;

  const handleChange = (prop: string) => (event: React.ChangeEvent<any>) => {
    setErrors((current: any) => ({ ...current, [prop]: false }));
    let value = event.target.value;
    const carrying = formValues.carrying;
    let newFormValues = { ...formValues, [prop]: value };
    if (prop === "type") {
      if (!selected) {
        newFormValues = { ...initialValues, type: value };
      }
      if (value === "credit_card") {
        newFormValues.carrying = "y";
      }
    } else if (
      new Set([
        "balance",
        "balance_live",
        "payment",
        "asset-balance",
        "rate",
      ]).has(prop)
    ) {
      value = Math.abs(value);
    } else if (prop === "carrying") {
      if (carrying === "y") {
        newFormValues.carrying = "n";
      } else {
        newFormValues.carrying = "y";
      }
    }
    setFormValues(newFormValues);
  };

  const validate = () => {
    const newErrors: any = {
      owner: isMarried && !formValues.who,
      balance: !formValues.balance,
      firm: !formValues.firm,
    };

    if (isDebt) {
      newErrors.rate = !formValues.rate;
      newErrors.payment = !formValues.payment;
      if (formValues.type === "fed_loan") {
        if (enableFedLoanBreakouts) {
          newErrors.rate = false;
          newErrors.payment = false;
          newErrors.balance = false;
          formValues.fed_loans.forEach((loan: any, index: number) => {
            newErrors[`fed_loans.${index}.balance`] = !loan.balance;
            newErrors[`fed_loans.${index}.rate`] = !loan.rate;
          });
        }
        if (monthsSinceGraduation < 6) {
          newErrors.rate = false;
          newErrors.payment = false;
        }
        newErrors.firm = false;
      }
    } else if (
      [
        "auto_value",
        "home_value",
        "property_value",
        "other_assets_value",
      ].includes(formValues.type)
    ) {
      newErrors.firm = false;
    }
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleSave = () => {
    if (!validate()) {
      return;
    }
    const isSpouse = formValues.who === PROFILE_TYPE.SPOUSE;
    if (formValues.type === "fed_loan") {
      const actions: Array<FbAction<any>> = [];
      if (monthsSinceGraduation >= 6 && enableFedLoanBreakouts) {
        const fedLoans = isSpouse ? spouseFedLoans : myFedLoans;
        const newFedLoans: Array<Partial<MappedAccount>> =
          formValues.fed_loans || [];
        const totalBalance = newFedLoans.reduce(
          (result, loan) => result + (loan.balance || 0),
          0
        );
        const totalPayment = formValues.payment;
        let workingPayment = totalPayment;
        let workingBalance = totalBalance;
        newFedLoans.forEach((loan) => {
          if (loan.id && !loan?.manual?.payment) {
            workingPayment -= loan.monthly || 0;
            workingBalance -= loan.balance || 0;
          }
        });
        workingBalance = Math.max(workingBalance, 0);
        workingPayment = Math.max(workingPayment, 0);
        if (workingBalance) {
          newFedLoans.forEach((loan) => {
            if (!loan.id || loan?.manual?.payment) {
              loan.monthly =
                (workingPayment / workingBalance) * (loan.balance || 0);
            }
          });
        }
        const accountsAdd: Array<Partial<Account>> = newFedLoans
          .filter((a) => !a.id && a.balance)
          .map((a) => {
            const result = {
              type: "fed_loan",
              name: "",
              firm: formValues.firm,
              payment: a.monthly || 0,
              balance: a.balance || 0,
              rate: a.rate || 0,
              who: (formValues.who === "spouse" ? "spouse" : "applicant") as
                | "spouse"
                | "applicant",
            };
            if (
              (formValues.who === "spouse" && !spouseInSchool) ||
              (formValues.who !== "spouse" && !userInSchool)
            ) {
              result.payment = finance.PMT(
                (a.rate || 0) / 100 / 12,
                120,
                -(a.balance || 0)
              );
            }
            return result;
          });
        accountsAdd.forEach((input) =>
          actions.push(
            addAccount({
              account: input,
            })
          )
        );
        const accountsUpdate: Array<Partial<Account> | null> = fedLoans
          .map((oldLoan) => {
            const loan = newFedLoans.find(
              (newLoan) => newLoan.id === oldLoan.id
            );
            if (!loan) {
              removeLinkedAccountApi({ id: oldLoan.id }).then(() =>
                dispatch(removeAccount(oldLoan.id))
              );
              return null;
            }
            const result: Partial<Account> = {
              id: oldLoan.id,
              firm: oldLoan.firm,
              name:
                oldLoan.name === ESTIMATED_FEDERAL_LOAN_BALANCE
                  ? ""
                  : oldLoan.name,
            };
            if (
              loan?.manual?.payment &&
              ((formValues.who === "spouse" && !spouseInSchool) ||
                (formValues.who !== "spouse" && !userInSchool))
            ) {
              result.payment = loan.monthly;
            }
            if (loan?.manual?.balance) {
              result.balance = loan.balance;
            }
            if (loan?.manual?.rate) {
              result.rate = loan.rate;
            }
            return result;
          })
          .filter((loan) => loan && Object.keys(loan as any).length > 1);
        accountsUpdate.forEach((input) => {
          if (input) {
            actions.push(
              updateAccount({
                id: input.id || 0,
                update: input,
              })
            );
          }
        });
      } else {
        if (selected) {
          if (!isLinked) {
            actions.push(
              updateAccount({
                id: selected.id,
                update: {
                  balance: formValues.balance,
                  rate: formValues.rate,
                  name: ESTIMATED_FEDERAL_LOAN_BALANCE,
                },
              })
            );
          }
        } else {
          actions.push(
            addAccount({
              account: {
                firm: "",
                name: ESTIMATED_FEDERAL_LOAN_BALANCE,
                type: "fed_loan",
                balance: formValues.balance,
                rate: formValues.rate,
                payment: formValues.payment,
                who: formValues.who === "spouse" ? "spouse" : "applicant",
              },
            })
          );
        }
      }
      // update profile
      actions.push(
        updateDebts({
          update: {
            ...(isSpouse ? spouseProfileDebts : myProfileDebts),
            fed_repayment_plan: formValues.fed_repayment_plan,
          },
          who: isSpouse ? PROFILE_TYPE.SPOUSE : PROFILE_TYPE.APPLICANT,
        })
      );
      actions.forEach(dispatch);
    } else if (selected) {
      const accountInput: Partial<Account> = {
        firm: formValues.firm,
        name: formValues.name,
      };
      if (!isLinked || formValues?.manual?.rate) {
        accountInput.rate = formValues.rate;
      }
      if (!isLinked || formValues?.manual?.payment) {
        accountInput.payment = formValues.payment;
      }
      if (!isLinked || formValues?.manual?.balance) {
        if (selected.variable === "credit_card") {
          accountInput.balance_live = formValues.balance;
        } else {
          accountInput.balance = formValues.balance;
        }
      }
      if (selected.variable === "credit_card") {
        accountInput.carrying = formValues.carrying === "y" ? "y" : "n";
      }
      const payload: UpdateAccountPayload = {
        id: selected.id,
        update: accountInput,
      };
      dispatch(updateAccount(payload));
      saveInvestments(selected.id);
    } else if (isInvestmentType(formValues.type || "")) {
      const newAccount: any = {
        firm: formValues.firm,
        name: formValues.name,
        type: formValues.type,
        balance: formValues.balance,
        rate: formValues.rate,
        payment: formValues.payment,
        who: formValues.who === "spouse" ? "spouse" : "applicant",
      };
      addAccountApi(newAccount).then((addAccountResult) => {
        dispatch(addAccountSuccess(addAccountResult));
        saveInvestments(addAccountResult.id);
      });
    } else {
      const accountInputs: Array<Partial<Account>> = [];
      const actions: Array<FbAction<any>> = [];

      let generalInput: any = {};
      switch (formValues.type) {
        case "auto_loan":
          accountInputs.push({
            type: "auto_loan",
            firm: formValues.firm,
            name: formValues.name,
            balance: formValues.balance,
            payment: formValues.payment,
            rate: formValues.rate,
            who: formValues.who === "spouse" ? "spouse" : "applicant",
          });
          if (formValues.is_included !== "y" && formValues["asset-balance"]) {
            accountInputs.push({
              type: "auto_value",
              firm: formValues.firm,
              name: formValues.name,
              balance: formValues["asset-balance"],
              who: formValues.who === "spouse" ? "spouse" : "applicant",
            });
          }
          break;
        case "home_loan":
          accountInputs.push({
            type: "home_loan",
            firm: formValues.firm,
            name: formValues.name,
            balance: formValues.balance,
            payment: formValues.payment,
            rate: formValues.rate,
            who: formValues.who === "spouse" ? "spouse" : "applicant",
          });
          if (formValues.is_included !== "y" && formValues["asset-balance"]) {
            accountInputs.push({
              type: "home_value",
              firm: formValues.firm,
              name: formValues.name,
              balance: formValues["asset-balance"],
              who: formValues.who === "spouse" ? "spouse" : "applicant",
            });
          }
          break;
        case "property_loan":
          accountInputs.push({
            type: "property_loan",
            firm: formValues.firm,
            name: formValues.name,
            balance: formValues.balance,
            payment: formValues.payment,
            rate: formValues.rate,
            who: formValues.who === "spouse" ? "spouse" : "applicant",
          });
          if (formValues.is_included !== "y" && formValues["asset-balance"]) {
            accountInputs.push({
              type: "property_value",
              firm: formValues.firm,
              name: formValues.name,
              balance: formValues["asset-balance"],
              who: formValues.who === "spouse" ? "spouse" : "applicant",
            });
          }
          break;
        default:
          generalInput = {
            firm: formValues.firm,
            name: formValues.name,
            type: formValues.type,
            balance: formValues.balance,
            rate: formValues.rate,
            payment: formValues.payment,
            who: formValues.who === "spouse" ? "spouse" : "applicant",
          };
          if (formValues.type === "credit_card") {
            generalInput.carrying = formValues.carrying;
            generalInput.balance_live =
              formValues.balance_live || generalInput.balance;
            generalInput.balance =
              formValues.balance_live || generalInput.balance;
          }
          accountInputs.push(generalInput);
      }
      accountInputs.forEach((input) =>
        actions.push(
          addAccount({
            account: input,
          })
        )
      );
      actions.forEach(dispatch);
    }
    closeFlyout();
  };

  const makeAccountTypeList = () => {
    const typeSelectItemMap = (type: string) => ({
      value: type,
      inputDisplay: isDebt
        ? (DEBT_TYPES as any)[type]
        : (ASSET_TYPES as any)[type],
    });
    if (isDebt) {
      const studentLoanList = ["perkins_loan", "priv_loan"];
      if (
        selected ||
        !myFedLoans.length ||
        (isMarried && !spouseFedLoans.length)
      ) {
        studentLoanList.unshift("fed_loan");
      }
      return [
        {
          value: "_",
          inputDisplay: (
            <strong className="text-black">Student Loan Account</strong>
          ),
          disabled: true,
        },
        ...studentLoanList.map(typeSelectItemMap),
        {
          value: "_",
          inputDisplay: <strong className="text-black">Other Account</strong>,
          disabled: true,
        },
        ...[
          "credit_card",
          "home_loan",
          "property_loan",
          "auto_loan",
          "personal_loan",
          "other_debt",
        ].map(typeSelectItemMap),
      ];
    }
    return [
      {
        value: "_",
        inputDisplay: <strong className="text-black">Banking Account</strong>,
        disabled: true,
      },
      typeSelectItemMap("cash_value"),
      {
        value: "_",
        inputDisplay: <strong className="text-black">Other Account</strong>,
        disabled: true,
      },
      typeSelectItemMap("other_investments_value"),
      {
        value: "_",
        inputDisplay: (
          <strong className="text-black">Retirement Account</strong>
        ),
        disabled: true,
      },
      ...[
        "401k_value",
        "roth_401k_value",
        "roth_ira_value",
        "ira_value",
        "hsa_value",
        "other_retirement_value",
      ].map(typeSelectItemMap),
      {
        value: "_",
        inputDisplay: <strong className="text-black">Property Account</strong>,
        disabled: true,
      },
      ...[
        "home_value",
        "property_value",
        "auto_value",
        "other_assets_value",
      ].map(typeSelectItemMap),
    ];
    return [];
  };

  const makeWhoList = () => {
    const output = [
      {
        inputDisplay: userFirstName || "",
        value: "applicant",
      },
      {
        inputDisplay: spouseFirstName || "Spouse",
        value: "spouse",
      },
    ];
    // TODO implement joint accounts
    return output;
  };

  const isSpouse = formValues.who === PROFILE_TYPE.SPOUSE;
  const monthsSinceGraduation = isSpouse
    ? spouseMonthsSinceGraduation
    : myMonthsSinceGraduation;

  const handleDeleteAccount = () => {
    dispatch(deleteAccount(selected?.id || -1));
    closeFlyout();
  };

  const deleteModalTitleId = useGeneratedHtmlId();

  let whoLabel: React.ReactNode = (
    <LabelStyle>
      Owner
      <LabelAsterisk />
    </LabelStyle>
  );

  if (lockWho) {
    whoLabel = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <LabelStyle>Owner</LabelStyle>
        <LabelAsterisk />
        <InfoIcon content="You can only create one federal student loan account for each member of the household." />
      </div>
    );
  }

  return (
    <>
      <EuiFlyoutBody>
        <EuiFormRow
          label={
            <StyledSpan>
              Account Type
              <StyledBsAsterisk />
            </StyledSpan>
          }
        >
          <EuiSuperSelect
            fullWidth
            id="type"
            name="type"
            valueOfSelected={
              selected ? selected.variable : (formValues as any).type
            }
            onChange={handleSelect("type")}
            options={makeAccountTypeList()}
            disabled={!!selected}
            isInvalid={errors.type}
          />
        </EuiFormRow>

        {!!formValues.type && (
          <>
            {isMarried && (
              <>
                <StyledSpacer size="32px" />

                <EuiFormRow label={whoLabel}>
                  <EuiSuperSelect
                    isInvalid={errors.who}
                    id="who"
                    name="who"
                    valueOfSelected={(formValues as any).who}
                    onChange={handleSelect("who")}
                    options={makeWhoList()}
                    disabled={!!selected || lockWho}
                  />
                </EuiFormRow>
              </>
            )}
            {formValues.type !== "fed_loan" && (
              <>
                <StyledSpacer size="32px" />

                <EuiFormRow
                  label={
                    <LabelStyle>
                      Financial Institution
                      <LabelAsterisk />
                    </LabelStyle>
                  }
                  isInvalid={errors.firm}
                >
                  <EuiFieldText
                    id="firm"
                    name="firm"
                    value={formValues.firm}
                    onChange={handleChange("firm")}
                    isInvalid={errors.firm}
                  />
                </EuiFormRow>
                {errors.firm && <FormError>This field is required.</FormError>}
                <StyledSpacer size="32px" />

                <EuiFormRow label="Account Name" isInvalid={errors.name}>
                  <EuiFieldText
                    isInvalid={errors.name}
                    id="name"
                    name="name"
                    value={formValues.name}
                    onChange={handleChange("name")}
                  />
                </EuiFormRow>
                {errors.name && <FormError>This field is required.</FormError>}
                <StyledSpacer size="32px" />

                <EuiFormRow
                  label={
                    <LabelStyle>
                      {["auto_value", "home_value", "property_value"].includes(
                        formValues.type
                      )
                        ? "Estimated Market Value of Property"
                        : "Balance"}
                      <LabelAsterisk />
                    </LabelStyle>
                  }
                  isInvalid={errors.balance}
                >
                  <DollarTextField
                    eui
                    isInvalid={errors.balance}
                    id="balance"
                    name="balance"
                    value={formValues.balance}
                    onChange={handleChange("balance")}
                  />
                </EuiFormRow>
                {errors.balance && (
                  <FormError>This field is required.</FormError>
                )}
                {isDebt && (
                  <>
                    {formValues.type === "credit_card" && (
                      <>
                        <StyledSpacer size="32px" />

                        <EuiCheckbox
                          id="carrying"
                          label="I pay this off monthly"
                          checked={formValues.carrying === "n"}
                          onChange={handleChange("carrying")}
                        />
                      </>
                    )}

                    <StyledSpacer size="32px" />

                    <EuiFormRow
                      label={
                        <LabelStyle>
                          Interest Rate
                          <LabelAsterisk />
                        </LabelStyle>
                      }
                    >
                      <PercentTextField
                        isInvalid={errors.rate}
                        eui
                        id="rate"
                        name="rate"
                        value={formValues.rate}
                        onChange={handleChange("rate")}
                      />
                    </EuiFormRow>

                    <StyledSpacer size="32px" />

                    <EuiFormRow
                      label={
                        <LabelStyle>
                          Minimum Payment
                          <LabelAsterisk />
                        </LabelStyle>
                      }
                    >
                      <DollarTextField
                        isInvalid={errors.payment}
                        eui
                        id="payment"
                        name="payment"
                        value={formValues.payment}
                        onChange={handleChange("payment")}
                      />
                    </EuiFormRow>
                  </>
                )}
                {!selected &&
                  ["auto_loan", "home_loan", "property_loan"].includes(
                    formValues.type
                  ) && (
                    <>
                      <StyledSpacer size="32px" />
                      <EuiFormRow
                        label={
                          <LabelStyle>
                            Estimated Market Value of Property
                            <LabelAsterisk />
                          </LabelStyle>
                        }
                      >
                        <DollarTextField
                          eui
                          id="asset-balance"
                          name="asset-balance"
                          value={formValues["asset-balance"]}
                          onChange={handleChange("asset-balance")}
                        />
                      </EuiFormRow>
                    </>
                  )}
              </>
            )}
            {formValues.type === "fed_loan" && (
              <FedLoansForm
                enableBreakout={enableFedLoanBreakouts}
                setEnableBreakout={setEnableFedLoanBreakouts}
                formValues={formValues}
                setFormValues={setFormValues}
                monthsSinceGraduation={monthsSinceGraduation}
                onChange={handleChange}
                isLinked={isLinked}
                editing={!!selected}
                errors={errors}
              />
            )}
            {isInvestmentType(formValues.type) && (
              <InvestmentAllocations
                readOnly={!!matchedLinkedAccount?.holdings}
                investmentData={investmentData}
                totalBalance={formValues.balance}
              />
            )}
          </>
        )}
      </EuiFlyoutBody>
      <StyledEuiFlyoutFooter
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          {!!selected &&
            !isLinked &&
            (selected.variable !== "fed_loan" ||
              monthsSinceGraduation < 6 ||
              !enableFedLoanBreakouts) && (
              <EuiButtonEmpty
                color="danger"
                onClick={() => setDeleteDialogVisible(true)}
              >
                Delete
              </EuiButtonEmpty>
            )}
        </div>
        <div>
          <StyledEuiButton
            color="text"
            className="btn-spacing"
            onClick={closeFlyout}
          >
            Cancel
          </StyledEuiButton>
          <EuiButton
            color="primary"
            fill
            onClick={handleSave}
            disabled={
              !formValues.type || Object.values(errors).some((error) => error)
            }
          >
            {selected ? "Save" : "Add"}
          </EuiButton>
        </div>
      </StyledEuiFlyoutFooter>
      {deleteDialogVisible && (
        <EuiModal
          aria-labelledby={deleteModalTitleId}
          onClose={() => setDeleteDialogVisible(false)}
          style={{ maxWidth: 600 }}
        >
          <StyledEuiModalHeader>
            <EuiModalHeaderTitle id={deleteModalTitleId}>
              Are you sure you want to delete the
              <br />
              {selected?.name} account?
            </EuiModalHeaderTitle>
            <StyledEuiIcon
              type="cross"
              onClick={() => setDeleteDialogVisible(false)}
              style={{
                cursor: "pointer",
              }}
            />
          </StyledEuiModalHeader>
          <EuiModalBody>
            <EuiText size="m">
              <p>
                This will delete the account and all related info. You cannot
                undo this action.
              </p>
            </EuiText>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton
              color="text"
              onClick={() => setDeleteDialogVisible(false)}
            >
              Cancel
            </EuiButton>

            <EuiButton fill color="danger" onClick={handleDeleteAccount}>
              Delete
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </>
  );
};

export default AddEditManualAccountForm;
