import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Typography } from "@material-ui/core";

import { useStyles } from "../styles";
import Button from "src/components/Button";
import { PlanViewComponent } from "src/interfaces";
import { savePlan, updateAllocations } from "src/store/planBuild/actions";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
  getLiabilities,
} from "src/store/planBuild/selector";

import CenterContainer from "../../../Components/CenterContainer";
import PercentBreakdown from "src/components/PercentBreakdown";

const PerkinsLoan: PlanViewComponent = ({
  onClose,
  onSave,
  render,
  curatedIndex,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const type = "perkins_loan";

  const minLiabilities = useSelector(getLiabilities).min;
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const plan = useSelector(getCurrentPlan);
  const annualLiability = minLiabilities[type];
  const percent = (annualLiability / totalIncome) * 100;
  const [formState, setFormState] = useState<any>({
    type,
    percent,
    contribution: annualLiability,
  });

  const save = () => {
    if (formState.contribution) {
      const newAllocations = {
        ...plan.allocations[0],
        [type]: formState.contribution,
      };
      dispatch(updateAllocations(newAllocations));
      dispatch(savePlan(null));
    }
    onSave();
  };

  const min = annualLiability || 0;
  const nextDisabled = formState.contribution < min;

  return render({
    component: (
      <CenterContainer
        scrollable
        title={"Perkins Student Loan"}
        iconName="fb-scales-tripped"
      >
        <Box className="flex items-center mt-5">
          <Typography style={{ fontSize: "13px" }}>
            What % of your income would you like to use towards this payment?
          </Typography>
        </Box>
        <Box className="mt-5">
          <PercentBreakdown
            min={min}
            income={totalIncome}
            onChange={setFormState}
            values={formState}
            label="Perkins Student Loan"
            isDebt
          />
        </Box>
        {!curatedIndex && (
          <Button
            variant="outlined"
            fbColor="primary"
            onClick={onClose}
            className={styles.btnCancel}
          >
            Cancel
          </Button>
        )}
      </CenterContainer>
    ),
    nextLabel: "Save",
    onNext: save,
    nextDisabled,
  });
};

export default PerkinsLoan;
