import { SidebarContent } from "src/interfaces";

export const additionalQuestionsBlowout: SidebarContent[] = [
  {
    header: "What Is An Emergency Fund?",
    body: [
      "An emergency fund reflects a cushion of savings that allows one to meet necessary monthly expenses and debt payments. When your monthly expenses and debt payments and totaled up, multiply that by the number of months that makes you comfortable. The result is how much you should have saved in your checking and savings account(s).",
    ],
  },
  {
    header: "Recommendation",
    body: [
      "Most planners recommend 6 months of expenses to be saved. This recommendation is provided because most people who lose their job can find new employment within 4 - 6 months.",
      "However, this recommendation is misguided. For example, one who works in a very stable industry with low unemployment may not need six months of expenses. Others who have variable income,  such as self-employed individuals, may need more than 6 months of expenses saved.",
      "FitBUX recommends a minimum of 3 months. From there, you can ask yourself if having more saved would make you feel more comfortable.",
    ],
  },
];
