import { EuiText, EuiSpacer } from "@elastic/eui";
import { Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import styled from "@emotion/styled";

export const MainContainer = styled(Container)`
  padding-top: 4.82rem !important;
  overflow-y: auto;
  @media (max-width: 576px) {
    padding: 0 24px;
  }
`;

export const NumberList = styled.ol`
  list-style: none;
  padding-left: 0;

  li {
    display: flex;
    align-items: baseline;

    b {
      font-weight: bold;
    }

    span {
      margin-left: 8px;
    }
  }
`;

export const StyledEuiText = styled(EuiText)`
  font-size: 22px;
  line-height: 2.4rem;
  .landing-text {
    max-width: 600px;
    button {
      font-weight: 400;
    }
  }

  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 1.8rem;
  }
`;

export const StyledMobileRow = styled(Row)`
  @media (max-width: 576px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

export const StyledMobileEuiSpacer = styled(EuiSpacer)`
  @media (max-width: 576px) {
    block-size: 36px;
  }
`;

export const StyledImageCol = styled(Col)`
  @media (max-width: 992px) {
    order: -1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .align-left {
      align-self: flex-start;
    }

    .align-center {
      align-self: center;
    }
    .center-wrapper {
      align-self: center;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @media (min-width: 576px) and (max-width: 992px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const WelcomeLanding = styled(Container)`
  padding-top: 4.82rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
  @media (max-width: 576px) {
    padding: 0 24px;
  }
`;
