import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { checkboxStyles } from "../../theme/base";

const useStyles = makeStyles(checkboxStyles);

const FbCheckbox: React.FC<CheckboxProps> = (props) => {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
};

export default FbCheckbox;
