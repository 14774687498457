import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import {
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import colors from "src/theme/colors";

import { PlanViewComponent } from "src/interfaces";
import FbIcon from "src/components/Icon";
import CenterContainer from "../../Components/CenterContainer";
import { implementPlan } from "src/store/planBuild/actions";
import { getCurrentPlan } from "src/store/planBuild/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import StripeForm from "src/components/StripeForm";

const ImplementPlan: PlanViewComponent = ({ render }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const currentPlan = useSelector(getCurrentPlan);
  const isCurrentStudent = useSelector(getIsCurrentStudent);

  const implementAndReturn = () => {
    if (currentPlan && currentPlan.id) {
      dispatch(implementPlan(currentPlan.id));
      dispatch(push("/dashboard"));
    }
  };

  const items = [
    "Designated FitBUX Coach. Because building rapport matters.",
    "Automated FitBUX Score tracking",
    "Online account linking for easy plan implementation and tracking",
    "Real-time alerts and recommendations",
    "Access to FitBUX Student Loan Pay Off Solution",
    "Access to FitBUX Student Loan Forgiveness Solution",
    "Lifetime access to FitBUX's Online Money School (a $350 value!)",
  ];

  if (isCurrentStudent) {
    items.push("Locked in post-graduation price (Save $168 per year)");
  }

  return render({
    component: (
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item sm={12} md={6} className="my-4">
            <Typography variant="h2">
              It's Time For Your Money To Complement Your Lifestyle
            </Typography>
            <Typography variant="subtitle1" className={styles.subtitle}>
              Upgrade to Premium to implement and track your financial plan
              easily. You will also receive:
            </Typography>
            <Divider />
            <List dense={true} className="mt-4">
              {items.map((item, index) => (
                <ListItem className={styles.item}>
                  <ListItemIcon className={styles.itemIcon} key={index}>
                    <FbIcon iconName="fb-checkmark" />
                  </ListItemIcon>
                  <ListItemText className={styles.itemText} primary={item} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item sm={12} md={6}>
            <CenterContainer
              title="Payment info"
              iconName="fb-purchase"
              description="Upgrade to a Premium Membership and receive our limited time special (regular $30.00/month). If you have a promo code or belong to an association with Membership discounts, you may also enter it below."
            >
              <StripeForm onClose={implementAndReturn} />
            </CenterContainer>
          </Grid>
        </Grid>
      </Container>
    ),
    hideSidebars: true,
    onNext: () => null,
  });
};

export default ImplementPlan;

const useStyles = makeStyles({
  subtitle: {
    fontSize: 14,
    marginBottom: 30,
    marginTop: 10,
  },
  item: {
    paddingLeft: 0,
  },
  itemIcon: {
    color: colors.brandingBlue1,
    marginRight: -10,
    width: 30,
  },
  itemText: {
    "&>span": {
      fontSize: 14,
    },
  },
  label: {
    fontSize: 12,
    marginBottom: 5,
    marginTop: 10,
  },
  toggle: {
    border: `none`,
    width: "33%",
    padding: 0,
    margin: `5px 10px`,
    borderRadius: `10px !important`,
    "&:first-child": {
      marginLeft: 0,
    },
    "&:last-child": {
      marginRight: 0,
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
  toggleBody: {
    border: `1px solid ${colors.blueGray4}`,
    width: "100%",
    height: 80,
    borderRadius: 10,
    padding: `15px 10px`,
    "&.active": {
      border: `1px solid ${colors.brandingBlue1}`,
      "& p": {
        color: colors.brandingBlue1,
      },
    },
  },
  toggleTitle: {
    textTransform: "initial",
    fontSize: 14,
  },
  toggleDescription: {
    textTransform: "initial",
    textAlign: "left",
    fontSize: 10,
  },
});

// const subscriptions = [
//   {
//     id: 1,
//     label: "Yearly",
//     price: 95,
//     description: "Pay for a full year now and save $40.00.",
//   },
//   {
//     id: 2,
//     label: "Two Year",
//     price: 185,
//     description: "Pay for two full years and save $120.00.",
//   },
//   {
//     id: 3,
//     label: "Monthly",
//     price: 12.99,
//     description: "Cancel any time.",
//   }
// ];
