import React from "react";

import { Typography } from "@material-ui/core";

import Button from "src/components/Button";

const data = {
  answers: {
    heading: "Upgrade to Premium and find out. Powered by FitBUX's A.I.",
    subscribedHeading: "Get personalized answers (powered by FitBUX A.I.).",
    items: [
      {
        iconName: "fa-piggy-bank",
        target: "/emergency-fund-amount",
        label: "Emergency Fund Optimization",
      },
    ],
    showSubscribeButton: true,
  },
  calculators: {
    heading: 'An easy way to "do the math" and compare strategies.',
    items: [
      {
        iconName: "fa-sack-dollar",
        target: "/cd-returns",
        label: "Future Value of a CD",
      },
    ],
  },
  products: {
    heading: "Products that help you analyze and implement your plan.",
    items: [
      {
        iconName: "fa-graduation-cap",
        target: "/solutions?solution=moneyschool",
        label: "Money School",
      },
      {
        iconName: "fa-chalkboard-user",
        dialog: {
          title: "ConEd Discount",
          content: (
            <>
              <Typography paragraph>
                FitBUX has a special ConEd discount for our PT, OT, SLP, and
                nurse members with MedBridge.
              </Typography>
              <Typography paragraph>
                Click the link button below to visit MedBridge and use the
                coupon code when you check out to get the discount.
              </Typography>
              <Typography variant="h2" className="text-center">
                Your Coupon Code: FITBUX
              </Typography>
              <Button
                variant="contained"
                fbColor="primary"
                onClick={() =>
                  window.open("https://www.medbridgeeducation.com/fitbux")
                }
                className="mx-auto block mt-6"
              >
                Go To MedBridge
              </Button>
            </>
          ),
        },
        label: "Special ConEd Discounts For Health Care Workers",
      },
      {
        iconName: "fa-sitemap",
        dialog: {
          title: "Travel Recruiter Recommendations",
          content: (
            <>
              <Typography paragraph>
                To have a great travel experience, you need to have a great
                recruiter.
              </Typography>
              <Typography paragraph>
                Our friends at Traveltherapymentor.com have a form that takes a
                few minutes to complete and matches you with a recruiter.
              </Typography>
              <Typography paragraph>
                In addition, they will work with you to make sure you are
                matched to the right person.
              </Typography>
              <Typography paragraph>
                At the end of the form, you'll be asked how you heard about
                Traveltherapymentor.com. Be sure to let them know FitBUX sent
                you!
              </Typography>
              <Button
                variant="contained"
                fbColor="primary"
                onClick={() =>
                  window.open(
                    "https://traveltherapymentor.com/travel-therapy-recruiter-recommendations/"
                  )
                }
                className="mx-auto block mt-6"
              >
                Complete The Form
              </Button>
            </>
          ),
        },
        label: "PT, OT, SLP Travel Recruiter Recommendations",
      },
      {
        iconName: "fa-file-certificate",
        dialog: {
          title: "WanderLust Travel PT Course",
          content: (
            <>
              <Typography paragraph>
                Jess is a Travel PT with an awesome course on the ins and outs
                of Travel PT.
              </Typography>
              <Typography paragraph>
                Plus, FitBUX members get 70% off when they use the code below!
              </Typography>
              <Typography variant="h2" className="text-center">
                Your Coupon Code: FITBUX
              </Typography>
              <Button
                variant="contained"
                fbColor="primary"
                onClick={() =>
                  window.open(
                    "https://university.wanderlustpts.com/a/12301/zhjbbQ3m "
                  )
                }
                className="mx-auto block mt-6"
              >
                Get More Info
              </Button>
            </>
          ),
        },
        label: "PT-specific PT Course",
      },
      {
        iconName: "fa-suitcase-medical",
        dialog: {
          title: "Travel Jobs",
          content: (
            <>
              <Typography paragraph>
                Want to see possible locations to travel?
              </Typography>
              <Typography paragraph>
                Check out these jobs at Traveltherapymentor.com.
              </Typography>
              <Typography paragraph>
                The last question asks where did you hear about
                Traveltherapymentor.com. Be sure to let them know FitBUX sent
                you!
              </Typography>
              <Button
                variant="contained"
                fbColor="primary"
                onClick={() =>
                  window.open("https://traveltherapymentor.com/hotjobs/")
                }
                className="mx-auto block mt-6"
              >
                Check Out Travel Jobs
              </Button>
            </>
          ),
        },
        label: "Travel PT, OT, SLP Jobs",
      },
      {
        iconName: "fa-file-certificate",
        dialog: {
          title: "Travel Therapy Mentor Course",
          content: (
            <>
              <Typography paragraph>
                Whitney and Jared from Traveltherapymentor.com have been
                traveling for more than a decade.
              </Typography>
              <Typography paragraph>
                They've put together this awesome course with all the do's and
                don'ts.
              </Typography>
              <Typography variant="h2" className="text-center">
                Use the code "fitbux" &amp; get $200 off!
              </Typography>
              <Button
                variant="contained"
                fbColor="primary"
                onClick={() =>
                  window.open("https://traveltherapymentor.com/course/")
                }
                className="mx-auto block mt-6"
              >
                Get More Info
              </Button>
            </>
          ),
        },
        label: "Travel PT, OT, SLP Course",
      },
      {
        iconName: "fa-file-certificate",
        dialog: {
          title: "NPTE Exam Prep",
          content: (
            <>
              <Typography paragraph>
                FITBUX members receive 10% off the NPTE Final Frontier course at
                npteff.com.
              </Typography>
              <Typography variant="h2" className="text-center">
                Use the code "FITBUX" &amp; get 10% off!
              </Typography>
              <Button
                variant="contained"
                fbColor="primary"
                onClick={() => window.open("https://npteff.com/")}
                className="mx-auto block mt-6"
              >
                Get More Info
              </Button>
            </>
          ),
        },
        label: "NPTE Exam Prep",
      },
      {
        iconName: "fa-suitcase-medical",
        dialog: {
          title: "Career Services",
          content: (
            <>
              <Typography paragraph>
                FITBUX members receive 10% off any Career Final Frontier
                services at careerff.com.
              </Typography>
              <Typography paragraph>
                They can assist with resume, cover letter, or interview
                preparation, among other services, and they post job listings
                that you can apply to.
              </Typography>
              <Typography variant="h2" className="text-center">
                Use the code "FITBUX" &amp; get 10% off!
              </Typography>
              <Button
                variant="contained"
                fbColor="primary"
                onClick={() => window.open("https://careerff.com/")}
                className="mx-auto block mt-6"
              >
                Get More Info
              </Button>
            </>
          ),
        },
        label: "PT-specific Career Services",
      },
    ],
    showSubscribeButton: true,
  },
};

export default data;
