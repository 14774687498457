import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { IconButton } from "@material-ui/core";

import CustomDialog from "src/components/Dialogs/CustomDialog";
import Icon from "src/components/Icon";
import { clearVideo } from "src/store/dashboard/actions";
import { VIMEO_VIDEOS, YOUTUBE_VIDEOS } from "src/store/dashboard/helpTopics";
import { getVideo } from "src/store/dashboard/selector";

// const useStyles = makeStyles({
// });

const Video = () => {
  // const styles = useStyles();
  const dispatch = useDispatch();
  const videoId: string | null = useSelector(getVideo);
  let videoSrc = "";
  if (videoId) {
    if (YOUTUBE_VIDEOS[videoId]) {
      videoSrc = `https://www.youtube.com/embed/${YOUTUBE_VIDEOS[videoId]}?enablejsapi=1`;
    } else {
      videoSrc = `https://player.vimeo.com/video/${VIMEO_VIDEOS[videoId]}?autoplay=1`;
    }
  }

  const close = () => dispatch(clearVideo());

  return (
    <CustomDialog onClose={close} isOpen={!!videoId} size="md" title="">
      <IconButton
        onClick={close}
        style={{ position: "absolute", top: 20, right: 20 }}
      >
        <Icon iconName="fb-close" />
      </IconButton>
      {!!videoId && (
        <iframe
          src={videoSrc}
          width="640"
          height="480"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          style={{ width: "100%" }}
        ></iframe>
      )}
    </CustomDialog>
  );
};

export default Video;
