import produce from "immer";
import { handleActions } from "redux-actions";

import { FbAction, Item0, Item1 } from "src/interfaces";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";
import { LOG_OUT } from "../system/actions";
import { PROFILE_BUILD_STEPS } from "./constants";

const initialState: any = {
  currentStep: PROFILE_BUILD_STEPS.WELCOME_1,
  loaded: {},
  loading: {},
  error: {},
  schools: [],
  leveledSchools: {}, // TODO
  specialties: [],
  fields: [],
  professions: [],
  employments: [],
  fellowships: [],
  residencies: [],
  designations: [],
  apiMessage: "",
  message: "",
};

const reducerDefinitions: any = {
  /* schools */
  [actions.OLD_FETCH_SCHOOLS + START]: (state: any) =>
    produce(state, (draft: any) => {
      draft.loading.schools = true;
      draft.error.schools = null;
    }),
  [actions.OLD_FETCH_SCHOOLS + SUCCESS]: (
    state: any,
    { payload }: FbAction<Array<Item0>>
  ) =>
    produce(state, (draft: any) => {
      draft.loading.schools = false;
      draft.loaded.schools = true;
      draft.error.schools = null;
      draft.schools = payload;
    }),
  [actions.OLD_FETCH_SCHOOLS + FAIL]: (state: any, { payload }: any) =>
    produce(state, (draft: any) => {
      draft.loading.schools = false;
      draft.error.schools = payload;
      draft.error = payload;
    }),

  /* schools (leveled) TODO: use constants instead */
  [actions.OLD_FETCH_LEVELED_SCHOOLS + START]: (state: any) =>
    produce(state, (draft: any) => {
      draft.loading.leveledSchools = true;
      draft.error.leveledSchools = null;
    }),
  [actions.OLD_FETCH_LEVELED_SCHOOLS + SUCCESS]: (
    state: any,
    { payload }: FbAction<any> // TODO: type
  ) =>
    produce(state, (draft: any) => {
      draft.loading.leveledSchools = false;
      draft.loaded.leveledSchools = true;
      draft.error.leveledSchools = null;
      draft.leveledSchools[payload.level] = payload.data;
    }),
  [actions.OLD_FETCH_LEVELED_SCHOOLS + FAIL]: (state: any, { payload }: any) =>
    produce(state, (draft: any) => {
      draft.loading.leveledSchools = false;
      draft.error.leveledSchools = payload;
    }),

  /* specialities */
  [actions.OLD_FETCH_SPECIALTIES + START]: (state: any) =>
    produce(state, (draft: any) => {
      draft.loading.specialties = true;
      draft.error.specialties = null;
    }),
  [actions.OLD_FETCH_SPECIALTIES + SUCCESS]: (
    state: any,
    { payload }: FbAction<Array<Item0>>
  ) =>
    produce(state, (draft: any) => {
      draft.loading.specialties = false;
      draft.loaded.specialties = true;
      draft.error.specialties = null;
      draft.specialties = payload;
    }),
  [actions.OLD_FETCH_SPECIALTIES + FAIL]: (state: any, { payload }: any) =>
    produce(state, (draft: any) => {
      draft.loading.specialties = false;
      draft.error.specialties = payload;
    }),

  /* fields */
  [actions.OLD_FETCH_FIELDS + START]: (state: any) =>
    produce(state, (draft: any) => {
      draft.loading.fields = true;
      draft.error.fields = null;
    }),
  [actions.OLD_FETCH_FIELDS + SUCCESS]: (
    state: any,
    { payload }: FbAction<Array<Item0>> // TODO: type
  ) =>
    produce(state, (draft: any) => {
      draft.loading.fields = false;
      draft.loaded.fields = true;
      draft.error.fields = null;
      draft.fields = payload;
    }),
  [actions.OLD_FETCH_FIELDS + FAIL]: (state: any, { payload }: any) =>
    produce(state, (draft: any) => {
      draft.loading.fields = false;
      draft.error.fields = payload;
    }),

  /* professions */
  [actions.OLD_FETCH_PROFESSIONS + START]: (state: any) =>
    produce(state, (draft: any) => {
      draft.loading.professions = true;
      draft.loaded.professions = true;
      draft.error.professions = null;
    }),
  [actions.OLD_FETCH_PROFESSIONS + SUCCESS]: (
    state: any,
    { payload }: FbAction<Array<Item1>> // TODO: type
  ) =>
    produce(state, (draft: any) => {
      draft.loading.professions = false;
      draft.error.professions = null;
      draft.professions = payload;
    }),
  [actions.OLD_FETCH_PROFESSIONS + FAIL]: (state: any) =>
    produce(state, (draft: any) => {
      draft.loading.professions = false;
      draft.error.professions = null;
    }),

  /* employments */
  [actions.OLD_FETCH_EMPLOYMENTS + START]: (state: any) =>
    produce(state, (draft: any) => {
      draft.loading.employments = true;
      draft.error.employments = null;
    }),
  [actions.OLD_FETCH_EMPLOYMENTS + SUCCESS]: (
    state: any,
    { payload }: FbAction<Array<Item0>> // TODO: type
  ) =>
    produce(state, (draft: any) => {
      draft.loading.employments = false;
      draft.loaded.employments = true;
      draft.error.employments = null;
      draft.employments = payload;
    }),
  [actions.OLD_FETCH_EMPLOYMENTS + FAIL]: (state: any) =>
    produce(state, (draft: any) => {
      draft.loading.employments = false;
      draft.error.employments = null;
    }),

  /* fellowships & residencies */
  [actions.OLD_FETCH_FELLOWSHIPS + START]: (state: any) =>
    produce(state, (draft: any) => {
      draft.loading.fellowships = true;
      draft.error.fellowships = null;
    }),
  [actions.OLD_FETCH_FELLOWSHIPS + SUCCESS]: (
    state: any,
    {
      payload,
    }: FbAction<{
      fellowship: Array<Item0>;
      residency: Array<Item0>;
    }>
  ) =>
    produce(state, (draft: any) => {
      draft.loading.fellowships = false;
      draft.loaded.fellowships = true;
      draft.error.fellowships = null;
      draft.fellowships = payload.fellowship;
      draft.residencies = payload.residency;
    }),
  [actions.OLD_FETCH_FELLOWSHIPS + FAIL]: (state: any) =>
    produce(state, (draft: any) => {
      draft.loading.fellowships = false;
      draft.error.fellowships = null;
    }),

  /* designations */
  [actions.OLD_FETCH_DESIGNATIONS + START]: (state: any) =>
    produce(state, (draft: any) => {
      draft.loading.designations = true;
      draft.error.designations = null;
    }),
  [actions.OLD_FETCH_DESIGNATIONS + SUCCESS]: (
    state: any,
    { payload }: FbAction<Array<Item0>> // TODO: type
  ) =>
    produce(state, (draft: any) => {
      draft.loading.designations = false;
      draft.loaded.designations = true;
      draft.error.designations = null;
      draft.designations = payload;
    }),
  [actions.OLD_FETCH_DESIGNATIONS + FAIL]: (state: any) =>
    produce(state, (draft: any) => {
      draft.loading.designations = false;
      draft.error.designations = null;
    }),
  [actions.OLD_SET_PROFILE_STEP]: (
    state: any,
    { payload }: FbAction<PROFILE_BUILD_STEPS>
  ) =>
    produce(state, (draft: any) => {
      draft.currentStep = payload;
    }),
  [LOG_OUT]: () => initialState,
};

const oldProfileBuildReducer = handleActions<any, any>(
  reducerDefinitions,
  initialState
);

export default oldProfileBuildReducer;
