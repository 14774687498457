import produce from "immer";

export const setLoading = (state: any) =>
  produce(state, (draft: any) => {
    draft.error = null;
    draft.loading = true;
  });

export const apiSuccess = (state: any, { payload }: any) =>
  produce(state, (draft: any) => {
    draft.loading = false;
    draft.apiResult = payload;
    draft.loaded = true;
  });

export const errorReducer = (state: any, { payload }: any) =>
  produce(state, (draft: any) => {
    draft.loading = false;
    draft.error = payload;
  });
