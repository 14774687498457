import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { fetchProfessions } from "src/store/profileBuild/actions";
import {
  getProfileLoaded,
  getProfileLoading,
  getProfessions,
} from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { DollarTextField } from "src/utils";

export const EmploymentDetails = ({
  next,
  goBack,
  formValues,
  handleChange,
  errors,
}: any) => {
  const dispatch = useDispatch();
  const loading = useSelector(getProfileLoading);
  const loaded = useSelector(getProfileLoaded);
  const professions = useSelector(getProfessions);
  const isMarried = useSelector(getIsMarried);

  useEffect(() => {
    if (!loaded.professions && !loading.professions) {
      dispatch(fetchProfessions());
    }
  }, [dispatch]);

  const ready =
    !!formValues.profession && !!formValues.income && !!formValues.assets;

  return (
    <Card title="Confirm Employment &amp; Financial Details">
      <Box className="flex justify-between">
        <Box />
        <Box className="w-1/2 flex flex-col">
          <FormLabel className="mb-2 mt-4">Employment Status</FormLabel>
          <Select
            variant="outlined"
            name="employment"
            onChange={handleChange}
            value={formValues.employment}
          >
            <MenuItem value="full_time">Employed (Full Time)</MenuItem>
            <MenuItem value="part_time">Employed (Part Time)</MenuItem>
            <MenuItem value="self_employed">Self-Employed</MenuItem>
            <MenuItem value="student">Student</MenuItem>
            <MenuItem value="foreign_student">Foreign Student</MenuItem>
            <MenuItem value="retired">Retired</MenuItem>
            <MenuItem value="homemaker">Homemaker</MenuItem>
            <MenuItem value="disabled">Disabled (Not Working)</MenuItem>
            <MenuItem value="unemployed">Unemployed</MenuItem>
          </Select>
          <FormLabel className="mb-2 mt-4">Occupation</FormLabel>
          <Autocomplete
            options={professions || []}
            disabled={loading.professions}
            getOptionLabel={(option) => option?.name || "-"}
            fullWidth
            placeholder="Occupation"
            onChange={(e, profession) =>
              handleChange({
                target: { name: "profession", value: profession?.id || "" },
              })
            }
            value={
              professions?.find(
                (profession: { id: string | undefined }) =>
                  profession?.id === formValues?.profession
              ) || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Occupation"
              />
            )}
          />
          <FormLabel className="mb-2 mt-4">
            Your Estimated Total Income
          </FormLabel>
          <DollarTextField
            variant="outlined"
            onChange={handleChange}
            name="income"
            value={formValues.income}
            fullWidth
          />
          {isMarried && (
            <>
              <FormLabel className="mb-2 mt-4">
                Your Spouse's Estimated Total Income
              </FormLabel>
              <DollarTextField
                variant="outlined"
                onChange={handleChange}
                name="spouseIncome"
                value={formValues.spouseIncome}
                fullWidth
              />
            </>
          )}
          <FormLabel className="mb-2 mt-4">
            Est. Household Investable Assets
          </FormLabel>
          <DollarTextField
            error={errors.has("assets")}
            helperText={errors.has("assets")}
            variant="outlined"
            onChange={handleChange}
            name="assets"
            value={formValues.assets}
            fullWidth
          />
          <Box className="flex mt-4 justify-center">
            <Button className="mr-4" onClick={goBack}>
              Back
            </Button>
            <Tooltip
              disableFocusListener={ready}
              disableHoverListener={ready}
              disableTouchListener={ready}
              title={ready ? "" : "Please complete the required form fields."}
            >
              <span>
                <Button fbColor="primary" onClick={next}>
                  Next
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default EmploymentDetails;
