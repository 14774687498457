import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikProps } from "formik";
import { Redirect } from "react-router";
import { push } from "connected-react-router";

import { resetPasswordApi } from "src/apiService";

import { getRouterState } from "src/store/router/selector";
import { loginStateSelector } from "src/store/system/selector";
import { passwordResetSuccess } from "src/store/system/actions";

import {
  EuiTitle,
  EuiButton,
  EuiImage,
  EuiShowFor,
  EuiFormRow,
  EuiIcon,
  EuiFieldPassword,
} from "@elastic/eui";
import { Container } from "react-bootstrap";
import styled from "@emotion/styled";
import login_image from "src/assets/svgs/login_landing.svg";
import mobile_login_image from "src/assets/svgs/mobile_login.svg";
import {
  StyledSpacer,
  StyledSpan,
} from "src/components/Global/StyledComponents";

const LoginContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  height: 100vh;
  padding: 0;
`;

const PasswordRuleText = styled.span<{ isValid: boolean }>`
  color: ${({ isValid }) => (isValid ? "inherit" : "#BD271E")};
`;

interface MyFormValues {
  password: string;
}

const ResetPassword = () => {
  const dispatch = useDispatch();
  const router = useSelector(getRouterState);
  const { loggedIn } = useSelector(loginStateSelector);
  const resetkey = useRef(router.location?.query?.resetkey || "");
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasPasswordInput, setHasPasswordInput] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [minLength, setMinLength] = useState(false);
  const [hasCombination, setHasCombination] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  if (loggedIn) {
    return <Redirect to="/plan-summary" />;
  }

  const handlePasswordChange = (e: any) => {
    const password = e.target.value;

    // Check if password is at least 8 characters long
    setMinLength(password.length >= 8);

    // Check if password includes a combination of letters, numbers, and symbols
    setHasCombination(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(password)
    );

    // Clear passwordError state if password meets the requirements
    if (
      password.length >= 8 &&
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(password)
    ) {
      setPasswordError(null);
    }
  };

  const onFormikSubmit = async (
    values: MyFormValues
    // { setSubmitting }: FormikActions<MyFormValues>
  ) => {
    setSubmitAttempted(true);
    setIsPasswordFocused(true);
    setHasPasswordInput(true);

    if (!minLength || !hasCombination) {
      setPasswordError("Valid password is required.");
      return;
    }

    if (values.password && !passwordError && !success && !loading) {
      resetPasswordApi(resetkey.current, values.password)
        .then(() => {
          setLoading(false);
          dispatch(passwordResetSuccess());
          setSuccess(true);
          setTimeout(() => dispatch(push("/login")), 3000);
        })
        .catch(() => {
          setLoading(false);
          // setApiError(true);
        });
    }
  };
  return (
    <LoginContainer fluid className="login-main">
      <div className="half info-container">
        <div className="info-content">
          <EuiImage
            src={login_image}
            alt="work life balance image"
            size="original"
          />
        </div>
      </div>
      <div className="half field-container">
        <div className="field-content">
          <EuiShowFor sizes={["xs", "s", "m"]}>
            <EuiImage
              src={mobile_login_image}
              alt="work life balance image"
              size="original"
            />
          </EuiShowFor>

          <EuiTitle size="l">
            <h1>Reset password</h1>
          </EuiTitle>

          <StyledSpacer size="32px" />

          <Formik
            initialValues={{ password: "" }}
            onSubmit={onFormikSubmit}
            render={(formikBag: FormikProps<MyFormValues>) => (
              <form noValidate onSubmit={formikBag.handleSubmit}>
                <EuiFormRow
                  label={<StyledSpan>New password</StyledSpan>}
                  className={`input-size password-input ${
                    hasPasswordInput && (!minLength || !hasCombination)
                      ? "invalid"
                      : ""
                  }`}
                  isInvalid={!!passwordError}
                  error={passwordError}
                >
                  <EuiFieldPassword
                    name="password"
                    type="dual"
                    autoComplete="current-password"
                    onChange={(e) => {
                      formikBag.handleChange(e);
                      handlePasswordChange(e);
                      setHasPasswordInput(true);
                    }}
                    value={formikBag.values.password}
                    isInvalid={
                      hasPasswordInput && (!minLength || !hasCombination)
                    }
                    onFocus={() => setIsPasswordFocused(true)}
                    onBlur={() => setIsPasswordFocused(false)}
                  />
                </EuiFormRow>

                <StyledSpacer size="8px" />

                {(isPasswordFocused || hasPasswordInput) && (
                  <div className="pass-valid">
                    <p>Your password must:</p>
                    <StyledSpacer size="8px" />
                    <div className="pass-align">
                      <EuiIcon
                        type={
                          minLength
                            ? "check"
                            : submitAttempted
                            ? "cross"
                            : "dot"
                        }
                        color={
                          minLength
                            ? "success"
                            : submitAttempted
                            ? "danger"
                            : "default"
                        }
                      />
                      <PasswordRuleText isValid={minLength || !submitAttempted}>
                        Be a minimum of 8 characters
                      </PasswordRuleText>
                    </div>
                    <StyledSpacer size="8px" />
                    <div className="pass-align">
                      <EuiIcon
                        type={
                          hasCombination
                            ? "check"
                            : submitAttempted
                            ? "cross"
                            : "dot"
                        }
                        color={
                          hasCombination
                            ? "success"
                            : submitAttempted
                            ? "danger"
                            : "default"
                        }
                      />
                      <PasswordRuleText
                        isValid={hasCombination || !submitAttempted}
                      >
                        Include a combination of letters, numbers, and symbols
                      </PasswordRuleText>
                    </div>
                  </div>
                )}

                <StyledSpacer size="32px" />

                <EuiButton
                  className="login-button"
                  type="submit"
                  color="primary"
                  fill
                  isLoading={loading}
                >
                  {!loading && "Reset"}
                </EuiButton>

                <StyledSpacer size="24px" />
              </form>
            )}
          />
        </div>
      </div>
    </LoginContainer>
  );
};

export default ResetPassword;
