const DISCLAIMER =
  "Disclaimer: This calculator assumes fixed interest rates under a straight-line amortization schedule. It does not take into account other types of mortgages such as interest only or adjustable rates. All associated charts on this analysis are estimates and included for illustrative purposes only. Nothing in this message constitutes investment advice. Fitbux, Inc., its associates, employees, and officers shall not be liable for any loss or liability suffered by you resulting from your use of the information or your reliance in any way on the information.";

const PRE_PAYMENT_HELP = [
  {
    header: `Prepayments`,
    body: [
      `Prepayments are amounts you pay in addition to your required payment. The purpose is to pay off your loans faster and save money.`,
      `Prepayments can be paid anytime you'd like, i.e. bi-monthly, monthly, quarterly, etc. However, most do it monthly. Therefore, our calculator assumes you'll be making monthly prepayments.`,
    ],
  },
];

enum STEP {
  MORTGAGE_DETAILS = "MORTGAGE_DETAILS",
  MONTHLY_REPAYMENTS = "MONTHLY_REPAYMENTS",
}

export { DISCLAIMER, STEP, PRE_PAYMENT_HELP };
