import React, { useMemo, useState } from "react";
import {
  Box,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import Chart from "src/components/Chart";
import { PayoffAnalysisResponse } from "src/apiService";
import colors from "src/theme/colors";
import {
  DollarTextField,
  formatPercent,
  formatWholeDollars,
  PercentTextField,
} from "src/utils";

interface PayoffResultsProps {
  tab: number;
  setTab: (tab: number) => void;
  totals: any;
  totalPayment: number;
  extend?: boolean;
  loaded?: boolean;
  data?: PayoffAnalysisResponse;
  loadNewResults: (extraPrepay: number, dropoffRate: number) => Promise<void>;
  loading: boolean;
  onBack: VoidFunction;
}

const useStyles = makeStyles({
  root: {
    maxWidth: 900,
    margin: "0 auto",
  },
  topBox: {
    marginBottom: 20,
    marginTop: 40,
    padding: 20,
  },
  inputRow: {
    alignItems: "center",
    display: "flex",
    height: 45,
    justifyContent: "space-between",
    marginTop: 5,
  },
  tabs: {
    height: 42,
    padding: 4,
    margin: "16px 0px 6px",
  },
  chartContainer: {},
  summaryRow: {},
  groupHeader: {},
  columnHeader: {
    wordBreak: "break-word",
  },
  rowHeader: {
    fontWeight: 600,
    wordBreak: "break-word",
  },
  positive: {
    color: colors.success,
  },
  negative: {
    color: colors.error,
  },
  paragraph: {
    display: "block",
    marginTop: 6,
  },
});

export const PayoffResults = ({
  tab,
  setTab,
  totals,
  totalPayment,
  extend,
  loaded,
  data,
  loadNewResults,
  onBack,
}: PayoffResultsProps) => {
  const styles = useStyles();
  const [extraPrepay, setExtraPrepay] = useState(0);
  const [activePrepay, setActivePrepay] = useState(0);
  const [dropoffRate, setDropoffRate] = useState(0);

  const selectTab = (e: React.ChangeEvent<any>, newTab: number) =>
    setTab(newTab);

  const submit = () => {
    setActivePrepay(extraPrepay);
    loadNewResults(extraPrepay, dropoffRate);
  };

  const handleFormChange = (e: React.ChangeEvent<any>) => {
    if (e.target.name === "extra_prepay") {
      setExtraPrepay(+e.target.value);
    } else {
      setDropoffRate(e.target.value);
    }
  };

  const detail: any = data?.detail || {};

  const displaySteps = () => {
    const AVAILABLE_STEPS = {
      EXTEND: "Extend selected loan(s) out to desired term(s).",
      MAKE_REQUIRED_PAYMENT: `Make the initial total required monthly payment of ${formatWholeDollars(
        detail.extend_total_payment
      )}.`,
      APPLY_PREPAYMENT: `Apply the total prepayment of ${formatWholeDollars(
        detail.discretionary
      )} to the highest interest rate loan first.`,
      MAKE_ONLY_REQUIRED_PAYMENT: `Make the initial total required monthly payment of ${formatWholeDollars(
        detail.extend_total_payment
      )} throughout the entire payment period. No extra prepayment will be applied.`,
      REALLOCATE_AGGRESSIVE: `When a loan is paid off, allocate the amount which was applied to that loan’s required monthly payment along with the total prepayment of ${formatWholeDollars(
        detail.discretionary
      )} to the next highest interest rate loan.`,
      REALLOCATE_MODERATE: `Once that loan is paid off, your total monthly required payment will drop accordingly. However, continue making your ${formatWholeDollars(
        detail.discretionary
      )} prepayment and apply it towards the next highest interest rate loan.`,
      REALLOCATE_NO_PREPAYMENT:
        "When a loan is paid off, apply the amount previously applied to that loan’s required monthly payment as a prepayment to the next highest interest rate loan. Note that if all your loans have the same duration, you will not save any money or time.",
      PREPAY_UNTIL_DROPOFF: `Continue prepaying until all loans with an interest rate equal to or above ${formatPercent(
        dropoffRate
      )} are paid off. For loans below that threshold, only make their required monthly payment until they are fully paid off.`,
      PREPAY_UNTIL_PAYOFF: "Continue prepaying until all loans are paid off.",
      SAME_PAYMENT_THROUGHOUT: `In effect, you will be making a total monthly payment of ${formatWholeDollars(
        detail.prepay_total_payment
      )} throughout the entire repayment period.`,
      CONTINUE_UNTIL_PAYOFF: "Continue until all loans are paid off.",
    };
    const steps: string[] = [];
    if (extend) {
      steps.push(AVAILABLE_STEPS.EXTEND);
    }
    if (tab === 3) {
      steps.push(AVAILABLE_STEPS.MAKE_ONLY_REQUIRED_PAYMENT);
      steps.push(AVAILABLE_STEPS.REALLOCATE_NO_PREPAYMENT);
    } else {
      steps.push(AVAILABLE_STEPS.MAKE_REQUIRED_PAYMENT);
      steps.push(AVAILABLE_STEPS.APPLY_PREPAYMENT);
    }
    if (tab === 2) {
      steps.push(AVAILABLE_STEPS.REALLOCATE_AGGRESSIVE);
      if (dropoffRate) {
        steps.push(AVAILABLE_STEPS.PREPAY_UNTIL_DROPOFF);
      } else {
        steps.push(AVAILABLE_STEPS.PREPAY_UNTIL_PAYOFF);
      }
    }
    if (tab === 1) {
      steps.push(AVAILABLE_STEPS.REALLOCATE_MODERATE);
      steps.push(AVAILABLE_STEPS.PREPAY_UNTIL_PAYOFF);
    }
    if (tab === 0) {
      steps.push(AVAILABLE_STEPS.REALLOCATE_AGGRESSIVE);
      steps.push(AVAILABLE_STEPS.SAME_PAYMENT_THROUGHOUT);
      steps.push(AVAILABLE_STEPS.CONTINUE_UNTIL_PAYOFF);
    }
    return steps.map((step, index) => (
      <Typography variant="body1" className={styles.paragraph}>
        {index + 1}. {step}
      </Typography>
    ));
  };

  const monthlyPaymentChange =
    (detail.normal_total_payment || 0) - (detail.prepay_total_payment || 0);
  let monthlyPaymentChangeText = "Stay the same";
  let monthlyPaymentChangeClassName = "";
  if (monthlyPaymentChange > 0) {
    monthlyPaymentChangeText = `Decrease by ${formatWholeDollars(
      monthlyPaymentChange
    )}`;
    monthlyPaymentChangeClassName = styles.positive;
  } else if (monthlyPaymentChange < 0) {
    monthlyPaymentChangeText = `Increase by ${formatWholeDollars(
      -monthlyPaymentChange
    )}`;
    monthlyPaymentChangeClassName = styles.negative;
  }

  const totalPaymentChange =
    (detail.normal_overall_payment || 0) - (detail.prepay_overall_payment || 0);
  let totalPaymentChangeText = "Stay the same";
  let totalPaymentChangeClassName = "";
  if (totalPaymentChange > 0) {
    totalPaymentChangeText = `Decrease by ${formatWholeDollars(
      totalPaymentChange
    )}`;
    totalPaymentChangeClassName = styles.positive;
  } else if (totalPaymentChange < 0) {
    totalPaymentChangeText = `Increase by ${formatWholeDollars(
      -totalPaymentChange
    )}`;
    totalPaymentChangeClassName = styles.negative;
  }

  const paymentTermChange =
    (detail.normal_years || 0) - (detail.prepay_years || 0);
  let paymentTermChangeText = "Stay the same";
  let paymentTermChangeClassName = "";
  if (paymentTermChange > 0) {
    paymentTermChangeText = `Decrease by ${paymentTermChange.toFixed(1)} years`;
    paymentTermChangeClassName = styles.positive;
  } else if (paymentTermChange < 0) {
    paymentTermChangeText = `Increase by ${-paymentTermChange.toFixed(
      1
    )} years`;
    paymentTermChangeClassName = styles.negative;
  }

  const monthlyPaymentExtendChange =
    (detail.normal_total_payment || 0) - (detail.extend_total_payment || 0);
  let monthlyPaymentExtendChangeText = "Stay the same";
  let monthlyPaymentExtendChangeClassName = "";
  if (monthlyPaymentExtendChange > 0) {
    monthlyPaymentExtendChangeText = `Decrease by ${formatWholeDollars(
      monthlyPaymentExtendChange
    )}`;
    monthlyPaymentExtendChangeClassName = styles.positive;
  } else if (monthlyPaymentExtendChange < 0) {
    monthlyPaymentExtendChangeText = `Increase by ${formatWholeDollars(
      -monthlyPaymentExtendChange
    )}`;
    monthlyPaymentExtendChangeClassName = styles.negative;
  }

  const totalPaymentExtendChange =
    (detail.normal_overall_payment || 0) - (detail.extend_overall_payment || 0);
  let totalPaymentExtendChangeText = "Stay the same";
  let totalPaymentExtendChangeClassName = "";
  if (totalPaymentExtendChange > 0) {
    totalPaymentExtendChangeText = `Decrease by ${formatWholeDollars(
      totalPaymentExtendChange
    )}`;
    totalPaymentExtendChangeClassName = styles.positive;
  } else if (totalPaymentExtendChange < 0) {
    totalPaymentExtendChangeText = `Increase by ${formatWholeDollars(
      -totalPaymentExtendChange
    )}`;
    totalPaymentExtendChangeClassName = styles.negative;
  }

  const paymentTermExtendChange =
    (detail.normal_years || 0) - (detail.extend_years || 0);
  let paymentTermExtendChangeText = "Stay the same";
  let paymentTermExtendChangeClassName = "";
  if (paymentTermExtendChange > 0) {
    paymentTermExtendChangeText = `Decrease by ${paymentTermExtendChange.toFixed(
      1
    )} years`;
    paymentTermExtendChangeClassName = styles.positive;
  } else if (paymentTermExtendChange < 0) {
    paymentTermExtendChangeText = `Increase by ${-paymentTermExtendChange.toFixed(
      1
    )} years`;
    paymentTermExtendChangeClassName = styles.negative;
  }

  const formattedData = useMemo(() => {
    if (!data || !data.extend || !data.extend.length) {
      return [];
    }
    return data.extend.map((value, index) => ({
      month: index,
      extend: value,
      normal: data.normal[index],
      prepay: data.prepay[index],
    }));
  }, [data]);

  return (
    <Box className={styles.root}>
      <Paper className={styles.topBox}>
        <Box className={styles.inputRow}>
          <Typography variant="body1">
            Existing Required Monthly Payment
          </Typography>
          <Typography variant="body1">
            {formatWholeDollars(totalPayment)}
          </Typography>
        </Box>
        {extend && (
          <Box className={styles.inputRow}>
            <Typography variant="body1">
              New Required Payment With Extension of Loan(s)
            </Typography>
            <Typography variant="body1">
              {formatWholeDollars(totals.payment)}
            </Typography>
          </Box>
        )}
        <Box className={styles.inputRow}>
          <Typography variant="body1">
            What total monthly prepayment would you like to make/consider
            making?
          </Typography>
          <DollarTextField
            variant="outlined"
            name="extra_prepay"
            value={extraPrepay}
            onChange={handleFormChange}
          />
        </Box>
        <Box className={styles.inputRow}>
          <Typography variant="body1">
            New Monthly Payment + Prepayment Amount
          </Typography>
          <Typography variant="body1">
            {formatWholeDollars(totals.payment + extraPrepay)}
          </Typography>
        </Box>
        <Box className={styles.inputRow}>
          <Typography variant="body1">
            If you only want to prepay loans above a specific interest rate
          </Typography>
          <PercentTextField
            variant="outlined"
            name="dropoff_rate"
            value={dropoffRate || ""}
            onChange={handleFormChange}
          />
        </Box>
      </Paper>
      <Button fbColor="primary" fullWidth onClick={submit}>
        View Strategies
      </Button>
      {loaded && (
        <>
          <Paper className={styles.tabs}>
            <Tabs
              value={tab}
              onChange={selectTab}
              aria-label="disabled tabs example"
              variant="fullWidth"
            >
              <Tab label="Most Aggressive Strategy" style={{ minWidth: 120 }} />
              <Tab label="Aggressive Strategy" style={{ minWidth: 120 }} />
              <Tab
                label="Aggressive Strategy With Interest Rate Threshold"
                style={{ minWidth: 120 }}
              />
              <Tab
                label="Moderately Aggressive Strategy"
                style={{ minWidth: 120 }}
              />
            </Tabs>
          </Paper>
          <Box className={styles.chartContainer}>
            <Chart
              dollars
              title=""
              data={formattedData}
              height="322px"
              keys={["normal", "prepay", "extend"]}
              xInterval={12}
              xKey="month"
              xMargin={50}
              yMargin={15}
              keyLabels={{
                normal: "Existing schedule",
                prepay: "Extend and prepay",
                extend: "Extend only",
              }}
              width="100%"
            />
          </Box>
          {!!(extend || activePrepay) && (
            <Paper className="mt-5 p-4">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={styles.columnHeader}>
                        Compared to your existing situation, if you:
                      </TableCell>
                      {extend && (
                        <TableCell className={styles.columnHeader}>
                          {activePrepay ? "Only " : ""}Extend your loan(s)
                        </TableCell>
                      )}
                      {!!activePrepay && (
                        <TableCell className={styles.columnHeader}>
                          {extend
                            ? "Extended and Prepaid your loan(s)"
                            : "Prepaid your loan(s)"}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={styles.columnHeader}>
                        Your initial monthly payment would
                      </TableCell>
                      {extend && (
                        <TableCell
                          className={monthlyPaymentExtendChangeClassName}
                        >
                          {monthlyPaymentExtendChangeText}
                        </TableCell>
                      )}
                      {!!activePrepay && (
                        <TableCell className={monthlyPaymentChangeClassName}>
                          {monthlyPaymentChangeText}
                        </TableCell>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell className={styles.columnHeader}>
                        Your cumulative payment would
                      </TableCell>
                      {extend && (
                        <TableCell
                          className={totalPaymentExtendChangeClassName}
                        >
                          {totalPaymentExtendChangeText}
                        </TableCell>
                      )}
                      {!!activePrepay && (
                        <TableCell className={totalPaymentChangeClassName}>
                          {totalPaymentChangeText}
                        </TableCell>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell className={styles.columnHeader}>
                        Your repayment period would
                      </TableCell>
                      {extend && (
                        <TableCell className={paymentTermExtendChangeClassName}>
                          {paymentTermExtendChangeText}
                        </TableCell>
                      )}
                      {!!activePrepay && (
                        <TableCell className={paymentTermChangeClassName}>
                          {paymentTermChangeText}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
          <Paper className="mt-5 p-4">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell className={styles.columnHeader}>
                      Existing Loan(s)
                    </TableCell>
                    {extend && (
                      <TableCell className={styles.columnHeader}>
                        Extend Loan(s) Only
                      </TableCell>
                    )}
                    {!!activePrepay && (
                      <TableCell className={styles.columnHeader}>
                        {extend
                          ? "Extend and Prepay Loan(s)"
                          : "Prepay Loan(s)"}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*<TableRow>
                  <TableCell className={styles.rowHeader}>FitBUX Score</TableCell>
                  <TableCell className={styles.columnHeader}>{detail.normal_start_score} to {detail.end_score} in {(detail.normal_years || 0).toFixed(1)} years</TableCell>
                  {extend && <TableCell className={styles.columnHeader}>{detail.extend_start_score} to {detail.end_score} in {(detail.extend_years || 0).toFixed(1)} years</TableCell>}
                  <TableCell className={styles.columnHeader}>{detail.prepay_start_score} to {detail.end_score} in {(detail.prepay_years || 0).toFixed(1)} years</TableCell>
                </TableRow>*/}
                  <TableRow>
                    <TableCell className={styles.rowHeader}>
                      Total Initial Required Monthly Payment
                    </TableCell>
                    <TableCell>
                      {formatWholeDollars(detail.normal_payment)}
                    </TableCell>
                    {extend && (
                      <TableCell>
                        {formatWholeDollars(detail.extend_payment)}
                      </TableCell>
                    )}
                    {!!activePrepay && (
                      <TableCell>
                        {formatWholeDollars(detail.prepay_payment)}
                      </TableCell>
                    )}
                  </TableRow>
                  {!!(extend || activePrepay) && (
                    <TableRow>
                      <TableCell className={styles.rowHeader}>
                        Discretionary Prepayment
                      </TableCell>
                      <TableCell />
                      {extend && <TableCell />}
                      {!!activePrepay && (
                        <TableCell>
                          {formatWholeDollars(detail.discretionary)}
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell className={styles.rowHeader}>
                      Total Combined Initial Monthly Payment
                    </TableCell>
                    <TableCell>
                      {formatWholeDollars(detail.normal_total_payment)}
                    </TableCell>
                    {extend && (
                      <TableCell>
                        {formatWholeDollars(detail.extend_total_payment)}
                      </TableCell>
                    )}
                    {!!activePrepay && (
                      <TableCell>
                        {formatWholeDollars(detail.prepay_total_payment)}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.rowHeader}>
                      Debt-to-Income Ratio
                    </TableCell>
                    <TableCell>
                      {formatPercent(detail.normal_dept_ratio)}
                    </TableCell>
                    {extend && (
                      <TableCell>
                        {formatPercent(detail.extend_dept_ratio)}
                      </TableCell>
                    )}
                    {!!activePrepay && (
                      <TableCell>
                        {formatPercent(detail.prepay_dept_ratio)}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.rowHeader}>
                      Total Cumulative Payment Amount
                    </TableCell>
                    <TableCell>
                      {formatWholeDollars(detail.normal_overall_payment)}
                    </TableCell>
                    {extend && (
                      <TableCell>
                        {formatWholeDollars(detail.extend_overall_payment)}
                      </TableCell>
                    )}
                    {!!activePrepay && (
                      <TableCell>
                        {formatWholeDollars(detail.prepay_overall_payment)}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.rowHeader}>
                      Repayment Period
                    </TableCell>
                    <TableCell>
                      {(detail.normal_years || 0).toFixed(1)} years
                    </TableCell>
                    {extend && (
                      <TableCell>
                        {(detail.extend_years || 0).toFixed(1)} years
                      </TableCell>
                    )}
                    {!!activePrepay && (
                      <TableCell>
                        {(detail.prepay_years || 0).toFixed(1)} years
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {!!(extend || activePrepay) && (
            <Card title="Repayment Strategy Steps" className="my-5">
              {displaySteps()}
            </Card>
          )}
        </>
      )}
      <Typography variant="caption" className={styles.paragraph}>
        Disclaimer:
      </Typography>
      <Typography variant="caption" className={styles.paragraph}>
        The chart and calculations above include your estimated projected
        student loans, if provided, and assumes a term of 10 years. The
        projected loan balance does not include accrued interest and is included
        for illustrative purposes only.
      </Typography>
      <Typography variant="caption" className={styles.paragraph}>
        Nothing in this message constitutes investment advice. FitBUX, Inc., and
        its affiliates, employees and officers shall not be liable for any loss
        or liability suffered by you resulting from your use of the information
        or your reliance in any way on the information.
      </Typography>
      <Button className="mt-4" fbColor="secondary" fullWidth onClick={onBack}>
        Go Back
      </Button>
    </Box>
  );
};

export default PayoffResults;
