import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forOwn } from "lodash";

import { Grid, ListSubheader, MenuItem } from "@material-ui/core";

import { tabLabels } from "../data";
import Tabs from "src/components/Tabs";
import { MappedCashflow, GROUPED_EXPENSE_TYPES } from "src/interfaces";
import BudgetCard from "../Components/BudgetCard";
import Dialog from "../Components/Dialog";
import {
  addCashflow,
  editCashflow,
  removeCashflow,
} from "src/store/cashflow/actions";
import { getExpenseSummary } from "src/store/dashboard/selector";
import { getDashboardCashflows } from "src/store/cashflow/selector";
import { getIsSubscribed } from "src/store/system/selector";

const ExpensesCard = ({ openTransactionsDialog, taxBudget }: any) => {
  const dispatch = useDispatch();
  const subscribed = useSelector(getIsSubscribed);
  const expenseSummary = useSelector(getExpenseSummary);
  const { expenses }: any = useSelector(getDashboardCashflows);

  const [currentTab, setCurrentTab] = useState(0);
  const [isEditing, setEditing] = useState(false);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const _handleTabs = (value: number) => setCurrentTab(value);

  const handleCardEditClick = (
    changes: { [stringId: string]: number },
    deletedIds: Set<number>,
    newValues: { [type: string]: number }
  ) => {
    if (isEditing) {
      forOwn(changes, (amount: number, stringId: string) => {
        const cashflow = expenses.find(
          (item: MappedCashflow) => item.id === +stringId
        );
        if (cashflow) {
          const newAmount = currentTab === 2 ? amount : amount * 12;
          const update: any = { id: +stringId, amount: newAmount };
          dispatch(editCashflow(update));
        }
      });
      Array.from(deletedIds).forEach((id: number) =>
        dispatch(removeCashflow(id))
      );
      forOwn(newValues, (rawAmount: number, type: string) => {
        if (rawAmount) {
          const amount = currentTab === 2 ? rawAmount : rawAmount * 12;
          dispatch(addCashflow({ cashflow: { amount, type } }));
        }
      });
    }
    setEditing(!isEditing);
  };

  const openAddDialog = () => {
    setAddDialogVisible(true);
  };
  const closeAddDialog = () => {
    setAddDialogVisible(() => false);
  };
  const addItem = (values: { type: string; annual: string }) => {
    dispatch(
      addCashflow({
        cashflow: { type: values.type, amount: +values.annual || 0 },
      })
    );
    closeAddDialog();
  };

  const renderAvailableTypes: JSX.Element[] = [];
  GROUPED_EXPENSE_TYPES.forEach(({ header, items }: any) => {
    renderAvailableTypes.push(
      <ListSubheader key={header} disableSticky>
        {header}
      </ListSubheader>
    );
    items.forEach((item: { label: string; value: string }) => {
      renderAvailableTypes.push(
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      );
    });
  });

  const getActual = () => {
    if (currentTab === 0) {
      return expenseSummary.current;
    } else if (currentTab === 1) {
      return expenseSummary.monthly;
    }
    return expenseSummary.annual;
  };

  const timeframes = ["current", "monthly", "annual"];
  const timeframe = timeframes[currentTab];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Tabs
          disabled={!subscribed}
          tooltip={
            !subscribed ? (
              <div style={{ fontSize: 14, lineHeight: "16px", padding: 2 }}>
                Build and implement a plan to see your cashflow history.
              </div>
            ) : (
              ""
            )
          }
          list={tabLabels}
          value={currentTab}
          _handleChange={_handleTabs}
        />
      </Grid>
      <Grid item xs={12}>
        <BudgetCard
          title="Expenses"
          budget={expenses}
          taxBudget={taxBudget}
          openTransactionsDialog={
            subscribed
              ? (key) => openTransactionsDialog("expense", key, timeframe)
              : undefined
          }
          showTax
          actual={subscribed ? getActual() : undefined}
          onSave={handleCardEditClick}
          onAdd={openAddDialog}
          editing={isEditing}
          annual={currentTab === 2}
        />
      </Grid>
      <Dialog
        title="Expenses"
        renderList={renderAvailableTypes}
        open={addDialogVisible}
        onAdd={addItem}
        onClose={closeAddDialog}
      />
    </Grid>
  );
};

export default ExpensesCard;
