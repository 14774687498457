import React from "react";
import { Paper, Tabs, Tab, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    height: 42,
    padding: 4,
    margin: "16px 0px 6px",
  },
});

const WealthTabs = ({ currentTab, setCurrentTab }: any) => {
  const styles = useStyles();
  const handleChange = (event: React.ChangeEvent<any>, newValue: number) =>
    setCurrentTab(newValue);

  return (
    <Paper square className={styles.container}>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        aria-label="disabled tabs example"
        variant="fullWidth"
      >
        <Tab label="Summary" style={{ minWidth: 120 }} />
        <Tab label="Assets" style={{ minWidth: 120 }} />
        <Tab label="Human Capital" style={{ minWidth: 120 }} />
        <Tab label="Debts" style={{ minWidth: 120 }} />
      </Tabs>
    </Paper>
  );
};

export default WealthTabs;
