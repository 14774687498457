import { createMuiTheme } from "@material-ui/core/styles";
import colors from "./colors";
import shadows from "./shadows";

export const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      light: colors.brandingBlue2,
      main: colors.brandingBlue1,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.brandingBlue2,
      contrastText: colors.white,
    },
    text: {
      primary: colors.blueGray1,
    },
    background: {
      default: colors.blueGray8,
    },
    warning: {
      main: colors.warn,
    },
    error: {
      main: colors.error,
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 12,
    body1: {
      fontSize: 12,
    },
    body2: {
      fontSize: 14,
    },
    h1: {
      color: colors.brandingBlue1,
      fontSize: 28,
      fontWeight: 600,
    },
    h2: {
      color: colors.brandingBlue1,
      fontSize: 24,
      lineHeight: 1.8,
      fontWeight: 600,
      "&.has-underline": {
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          display: "block",
          bottom: -10,
          width: 32,
          height: 2,
          left: 0,
          background: colors.blueGray5,
        },
      },
    },
  },
  shadows: shadows,
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "transparent",
        backgroundImage: "none",
      },
      root: {
        // width: "calc(100vw - 180px)",
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "50px !important",
        color: colors.brandingBlue1,
      },
    },
    MuiPaper: {
      elevation0: {
        boxShadow: "none",
      },
      elevation1: {
        borderRadius: 12,
        background: colors.gradWidget,
        boxShadow: shadows[5],
      },
      root: {
        background: colors.gradWidget,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
        backgroundColor: "#fff",
        height: "44.25px",
      },
      input: {
        padding: `15px 12px`,
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.blueGray1,
        lineHeight: 1.3,
      },
    },
    MuiTab: {
      root: {
        fontSize: "12px !important",
        fontWeight: 500,
        height: 34,
        minHeight: 34,
        paddingLeft: 8,
        paddingRight: 8,
        maxWidth: 120,
        // minWidth: "120px !important",
        textTransform: "inherit",
        "&.Mui-selected": {
          color: colors.white,
          // "&:hover": {
          //   background: colors.gradDarkVertical,
          // }
        },
      },
      wrapper: {
        height: 34,
      },
      textColorInherit: {
        color: colors.blueGray3,
        zIndex: 1,
        fontSize: 14,
        fontWeight: 600,
        borderRadius: 8,
      },
    },
    MuiTabs: {
      indicator: {
        background: colors.gradLightVertical,
        height: 34,
        zIndex: 0,
        borderRadius: 8,
        top: 0,
      },
      root: {
        height: 42,
        minHeight: 42,
      },
      centered: {
        // paddingTop: 4,
        // "&.MuiTabs-indicator"
      },
    },
    MuiCard: {
      root: {
        borderRadius: 28,
        background: colors.gradWidget,
        boxShadow: shadows[4],
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.blueGray4,
        opacity: 0.4,
      },
    },
    MuiCardContent: {
      root: {
        fontSize: 14,
        padding: 24,
        "& .head .fb-card-title": {
          color: colors.brandingBlue1,
          fontWeight: 600,
        },
        "& .head h2": {
          fontSize: 20,
        },
        "& .head h4": {
          fontSize: 18,
        },
        "& .head h6": {
          fontSize: 14,
        },
        "&.p-0": {
          "& .head>div": {
            padding: 24,
          },
        },
      },
    },
    MuiTable: {
      root: {
        tableLayout: "fixed",
      },
    },
    MuiTableCell: {
      head: {
        fontSize: "13px",
        padding: "12px 0px",
      },
      root: {
        fontSize: "14px",
        whiteSpace: "pre-wrap",
        wordBreak: "break-all",
        padding: "12px 8px 12px 0px",
      },
    },
    MuiButton: {
      root: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: "none",
        padding: "10px 16px",
        borderRadius: 10,
        boxShadow: shadows[3],
        minWidth: 100,
      },
      outlined: {
        padding: "10px 16px",
        border: "1px solid #EBEBEB !important",
        backgroundColor: colors.white,
        "&.Mui-disabled": {
          background: colors.gradButtonDisabledLight,
          color: colors.blueGray1,
          opacity: 0.4,
          border: "none !important",
          boxShadow: "none",
        },
      },
      contained: {
        color: colors.white,
        background: colors.gradButtonBright,
        boxShadow: shadows[3],
        "&:hover": {
          backgroundColor: colors.brandingBlue1,
        },
        "&.Mui-disabled": {
          background: colors.gradButtonDisabledDark,
          color: colors.white,
          opacity: 0.4,
          boxShadow: "none",
        },
        "&.accent": {
          background: colors.gradButtonAccent,
        },
      },
      containedPrimary: {
        background: colors.gradButtonPrimary,
        border: "none",
      },
      containedSecondary: {
        background: colors.gradButtonAccent,
        border: "none",
      },
    },

    MuiDialog: {
      paper: {
        borderRadius: 28,
        padding: 22,
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: 18,
        color: colors.brandingBlue1,
        padding: 0,
        marginBottom: 10,
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        marginBottom: 10,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 0,
      },
    },
    MuiListSubheader: {
      root: {
        color: colors.brandingBlue1,
        fontSize: 14,
        fontWeight: 600,
        lineHeight: "24px",
        marginTop: 8,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: `1px solid ${colors.border}`,
      },
    },
    MuiExpansionPanel: {
      root: {
        "&:before": {
          display: "none",
        },
      },
    },
    MuiLinearProgress: {
      root: {
        height: 3,
      },
      barColorPrimary: {
        background: colors.gradBlueBubbleAngle,
      },
      colorPrimary: {
        backgroundColor: colors.blueGray6,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
      },
    },
  },
});
