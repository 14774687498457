const data = {
  answers: {
    heading: "Powered by FitBUX's A.I.",
    subscribedHeading: "Powered by FitBUX's A.I.",
    items: [
      {
        iconName: "fa-coffin-cross",
        target: "/life-insurance",
        label: "How Much Life Insurance Do I Need?",
      },
      {
        iconName: "fa-truck-medical",
        target: "/disability-insurance",
        label: "How Much Disability Insurance Do I Need?",
      },
    ],
    showSubscribeButton: true,
  },
  calculators: {
    heading: "Learn more about how insurance can protect you and/or your loved ones.",
    items: [
      {
        iconName: "fa-circle-play",
        video: "disabilityInsurance",
        label: "Long-term Disability Guide",
      },
      {
        iconName: "fa-circle-play",
        video: "lifeInsurance",
        label: "Term vs Whole Life Insurance",
      },
    ],
  },
  products: {
    heading: "Products that help you analyze and implement your plan.",
    items: [],
  },
};

export default data;
