import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Container,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";

import { getScore } from "src/store/dashboard/selector";
import { getPortfolioPerformance } from "src/store/investments/selector";
import { savePlan, updateCurrentPlan } from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { getCurrentPlan, getPlans } from "src/store/planBuild/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import CenterContainer from "../../Components/CenterContainer";
import { scoreToAllocation } from "src/utils";

const EnterName: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const currentPlan = useSelector(getCurrentPlan);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const score = useSelector(getScore);
  const [name, setName] = useState("");
  const { expectedReturns } = useSelector(getPortfolioPerformance);
  const existingPlans: any[] = useSelector(getPlans);
  let nextStep = isCurrentStudent
    ? PLAN_BUILD_STEPS.EDUCATION_FUNDING
    : PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW;
  if (existingPlans && existingPlans.length) {
    nextStep = PLAN_BUILD_STEPS.REUSE_SELECTIONS;
  }

  const getInvestmentROR = () => {
    const portfolio = scoreToAllocation(score);
    return expectedReturns[portfolio];
  };

  const onNext = () => {
    const planName = name || "My Financial Plan";
    dispatch(updateCurrentPlan({
      name: planName,
      // Building a new plan, override assumptions based on current profile
      assumptions: {
        ...currentPlan.assumptions,
        investments: getInvestmentROR(),
        home_loan_rate: 5.4,
      }
    }));
    dispatch(savePlan(nextStep));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);

  const renderNameInput = () => {
    if (isCurrentStudent) {
      return (
        <Paper className="w-full h-full">
          <Container maxWidth="sm">
            <Box className="w-full py-28">
              <Typography variant="h2" component="h2" className="mb-3">
                First, let's give your plan a name
              </Typography>
              <TextField
                className="w-full"
                variant="outlined"
                placeholder="Your plan name"
                onChange={handleChange}
                value={name}
              />
            </Box>
          </Container>
        </Paper>
      );
    } else {
      return (
        <CenterContainer title={"Name My Plan"} iconName="fb-event" scrollable>
          <Box className="w-full py-5">
            <Typography className="text-sm">
              Name your plan to get started.
            </Typography>
            <TextField
              className="pt-3 text-sm"
              variant="outlined"
              placeholder="My Financial Plan"
              onChange={handleChange}
              value={name}
            />
          </Box>
          {/* </Container> */}
        </CenterContainer>
      );
    }
  };

  return render({
    component: <>{renderNameInput()}</>,
    onNext,
  });
};

export default EnterName;
