import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import colors from "src/theme/colors";

interface ITabsProps {
  _handleChange: (value: number) => void;
  disabled?: boolean;
  value: number;
  list: Array<string>;
  tooltip?: React.ReactNode;
}

const CenteredTabs = ({
  value,
  list,
  _handleChange,
  disabled,
  tooltip,
}: ITabsProps) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<any>, newValue: number) =>
    _handleChange(newValue);

  return (
    <Paper className={classes.root}>
      <Tooltip title={tooltip || ""} placement="top">
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          classes={{
            indicator: classes.indicator,
          }}
        >
          {list.map((item, index) => (
            <Tab
              key={index}
              value={index}
              label={item}
              className={`${index < list.length - 1 && classes.withDivider}`}
              disabled={disabled}
            />
          ))}
        </Tabs>
      </Tooltip>
    </Paper>
  );
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    height: 42,
    padding: 4,
  },
  indicator: {
    // background: "transparent",
    // position: "relative",
    maxWidth: 140,
    minWidth: 0,
    transform: "translateX(8px)",
  },
  withDivider: {
    "&>span:first-child": {
      borderRight: `1px solid ${colors.blueGray9}`,
      // lineHeight: "20px"
    },
  },
});

export default CenteredTabs;
