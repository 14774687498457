import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyle = makeStyles({
  animation: {
    width: "calc(100% + 4px)",
    height: "calc(100% + 4px)",
    left: "-2px",
    bottom: "-2px",
  },
});

const PingEffect = ({
  trigger,
  playDuration,
}: {
  trigger: boolean;
  playDuration?: number;
}) => {
  const style = useStyle();
  const duration = playDuration || 2000;

  const [animating, setAnimating] = useState(trigger);

  // if triggerAnimation is updated, run the animation
  useEffect(() => {
    if (trigger && !animating) {
      setAnimating(true);
      setTimeout(() => {
        setAnimating(false);
      }, duration);
    }
  }, [trigger]);

  const getAnimation = () => {
    if (animating) {
      return (
        <span
          className={`animate-fbPing bg-primary absolute rounded-xl ${style.animation}`}
        />
      );
    } else {
      return <span />;
    }
  };

  return <>{getAnimation()}</>;
};

export default PingEffect;
