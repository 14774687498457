import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import StripeForm from "src/components/StripeForm";
import { AUTH_STATUS } from "src/interfaces";

import { getAllPurchaseData } from "src/store/stripe/actions";
import { authStatusSelector, getIsSubscribed } from "src/store/system/selector";
import { EuiTitle, EuiText, EuiImage, EuiShowFor } from "@elastic/eui";
import styled from "@emotion/styled";
import default_reg from "src/assets/svgs/register_landing.svg";
import fitbux_logo_desktop from "src/assets/svgs/fb_logo_desktop.svg";
import Logo from "src/components/Global/FitbuxLogo";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const StyledEuiText = styled(EuiText)`
  font-size: 22px;
  line-height: 2.4rem;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 2.2rem;
  }
`;

const UpgradeDialog = () => {
  const dispatch = useDispatch();
  const isSubscribed = useSelector(getIsSubscribed);
  const authStatus = useSelector(authStatusSelector);

  useEffect(() => {
    dispatch(getAllPurchaseData());
  }, []);

  return authStatus === AUTH_STATUS.SUCCEED && !isSubscribed ? (
    <div>
      <div className="half info-container paywall paywall-info">
        <div className="new-logo">
          <EuiImage
            className="desktop-logo"
            src={fitbux_logo_desktop}
            alt="FitBUX logo"
            size="original"
          />
        </div>

        <StyledSpacer size="36px" />

        <div className="info-content">
          <EuiImage
            src={default_reg}
            alt="default registration image"
            size="original"
          />

          <StyledSpacer size="36px" />

          <EuiTitle size="m">
            <h1>Reach your financial goals with FitBUX</h1>
          </EuiTitle>

          <StyledSpacer size="32px" />

          <StyledEuiText className="feat-list-resp">
            <ul className="feat-list">
              <li>
                Start with a free 14-day trial then pay as little as
                $15.75/month. Legacy financial planners often charge over $3,000
                a year.
              </li>
              <StyledSpacer size="24px" className="feat-block" />
              <li>
                Get unlimited access to a financial expert to create your plan.
              </li>
              <StyledSpacer size="24px" className="feat-block" />
              <li>
                Understand your finances and compare different loan payoff
                strategies.
              </li>
            </ul>
          </StyledEuiText>
        </div>
      </div>
      <div className="half field-container paywall paywall-field">
        <div className="field-content">
          <header className="logo mobile-logo">
            <Logo link="/" className="logo-container" alt="fitbux logo" />
          </header>

          <StyledSpacer size="32px" />

          <EuiTitle size="l" className="header-mobile">
            <h1>Select your plan</h1>
          </EuiTitle>

          <StyledSpacer size="24px" />

          <EuiTitle size="m" className="header-mobile secondary-header">
            <h2>You won't be charged until after the free 14-day trial.</h2>
          </EuiTitle>

          <EuiShowFor sizes={["xs", "s", "m"]}>
            <StyledSpacer size="24px" />
          </EuiShowFor>

          <EuiShowFor sizes={["xs", "s", "m"]}>
            <EuiTitle size="s" className="header-font-lg">
              <h3>Reach your financial goals with FitBUX</h3>
            </EuiTitle>

            <StyledSpacer size="24px" />

            <StyledEuiText>
              <ul className="feat-list-mobile">
                <li>
                  Start with a free 14-day trial then pay as little as
                  $15.75/month.
                </li>
                <li>Unlimited access to a financial expert</li>
                <li>14-day money-back guarantee</li>
              </ul>
            </StyledEuiText>
          </EuiShowFor>

          <StripeForm />
        </div>
      </div>
    </div>
  ) : null;
};

export default UpgradeDialog;
