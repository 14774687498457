import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import Checkbox from "src/components/Checkbox";

import { SPECIAL_REPAYMENTS } from "src/interfaces";
import { setIdrDetails } from "src/store/dashboard/actions";
import { getIdrData as selectIdrData } from "src/store/dashboard/selector";
import { getProfile, getSpouseProfile } from "src/store/profileBuild/selector";
import { getIsSubscribed } from "src/store/system/selector";
import { investmentsStyles } from "src/theme";
import { PercentTextField } from "src/utils";

const useStyles = makeStyles({
  ...investmentsStyles,
  detailRow: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 6,
    marginTop: 6,
  },
  detail: {
    fontSize: 14,
  },
  button: {
    display: "block",
    margin: "18px auto 0",
  },
});

export const AccountsAndAssumptions = ({
  goBack,
  showSpouseData,
  toggleShowSpouseData,
}: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const subscribed = useSelector(getIsSubscribed);
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const idrData = useSelector(selectIdrData);

  const applicantIdr =
    SPECIAL_REPAYMENTS.indexOf(myProfile.fed_repayment_plan || "") >= 0;
  const spouseIdr =
    SPECIAL_REPAYMENTS.indexOf(spouseProfile.fed_repayment_plan || "") >= 0;
  const canSelectSpouse = applicantIdr && spouseIdr;
  const [formValues, setFormValues] = useState({
    ...idrData.myIdrAssumptions,
  });
  const [selectedAccountIds, setSelectedAccountIds] = useState<Set<number>>(
    new Set([])
  );

  const data = showSpouseData
    ? idrData.spouseIdrOverview
    : idrData.myIdrOverview;
  const accounts = showSpouseData
    ? idrData.spouseIdrAccounts
    : idrData.myIdrAccounts;

  const handleChange = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    return setFormValues({ ...formValues, [field]: value });
  };

  if (
    !subscribed ||
    (!applicantIdr && !spouseIdr) ||
    (data?.result?.remainingterm || 0) <= 0
  ) {
    return null;
  }

  const toggleSelectedAccount = (accountId: number) => {
    const newSelectedAccountIds: Set<number> = new Set(selectedAccountIds);
    if (selectedAccountIds.has(accountId)) {
      newSelectedAccountIds.delete(accountId);
    } else {
      newSelectedAccountIds.add(accountId);
    }
    setSelectedAccountIds(newSelectedAccountIds);
  };

  useEffect(() => {
    const newSelectedAccountIds: Set<number> = new Set([]);
    accounts.forEach((account) => {
      if (account.selected) {
        newSelectedAccountIds.add(account.id);
      }
    });
    setSelectedAccountIds(newSelectedAccountIds);
  }, [accounts]);

  const saveUpdates = () => {
    dispatch(
      setIdrDetails({
        who: showSpouseData ? "spouse" : "applicant",
        accountIds: Array.from(selectedAccountIds),
        assumptions: formValues,
      })
    );
    goBack();
  };

  return (
    <>
      <Card
        divider
        title="Change Accounts &amp; Assumptions"
        className="mb-5"
        headComponent={
          canSelectSpouse ? (
            <Select
              variant="outlined"
              onChange={toggleShowSpouseData}
              value={showSpouseData ? "spouse" : "mine"}
            >
              <MenuItem value="mine">Mine</MenuItem>
              <MenuItem value="spouse">Spouse</MenuItem>
            </Select>
          ) : undefined
        }
      >
        <Box className="flex justify-between">
          <Card
            className="w-1/2 mr-5"
            title="Which Account(s) Are You Using for IDR Tax Savings"
          >
            <Box className="pt-5">
              {accounts.map((account) => (
                <FormControlLabel
                  className="block"
                  label={account.name || account.firm}
                  control={
                    <Checkbox
                      checked={selectedAccountIds.has(account.id)}
                      onChange={() => toggleSelectedAccount(account.id)}
                    />
                  }
                />
              ))}
            </Box>
          </Card>
          <Card className="w-1/2" title="Update Assumptions">
            <Box className="pt-5">
              <Box className={classes.detailRow}>
                <Typography variant="body1">
                  Long-term expected rate of increase in income
                </Typography>
                <PercentTextField
                  className="w-16"
                  variant="outlined"
                  name="earnings_growth"
                  onChange={handleChange}
                  value={formValues.earnings_growth || 0}
                />
              </Box>
              <Box className={classes.detailRow}>
                <Typography variant="body1">
                  Long-term estimated tax rate
                </Typography>
                <PercentTextField
                  className="w-16"
                  variant="outlined"
                  name="tax_rate"
                  onChange={handleChange}
                  value={formValues.tax_rate || 0}
                />
              </Box>
              <Box className={classes.detailRow}>
                <Typography variant="body1">
                  Long-term expected annual rate of return on savings
                </Typography>
                <PercentTextField
                  className="w-16"
                  variant="outlined"
                  name="savings_rate"
                  onChange={handleChange}
                  value={formValues.savings_rate || 0}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      </Card>
      <Button
        fbColor="primary"
        className={classes.button}
        onClick={saveUpdates}
      >
        Save
      </Button>
    </>
  );
};

export default AccountsAndAssumptions;
