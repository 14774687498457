import React from "react";

import { Box, Divider, makeStyles, Typography } from "@material-ui/core";

import { planBuilderStyles } from "src/theme";
import Card from "src/components/Card";

const useStyles = makeStyles(planBuilderStyles);
const steps = [
  {
    label: "1. Work Status & Income",
    // subSteps: [PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW],
    target: 1,
    relevantSteps: [2, 3],
  },
  {
    label: "2. Employer Retirement Details",
    // subSteps: [PLAN_BUILD_STEPS.INCOME_AND_EXPENSES],
    target: 4,
    relevantSteps: [5],
  },
  {
    label: "3. Marriage",
    // subSteps: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    target: 6,
  },
  {
    label: "4. Children",
    // subSteps: [PLAN_BUILD_STEPS.RISK_MANAGEMENT],
    target: 7,
  },
  {
    label: "5. Moving",
    // subSteps: [PLAN_BUILD_STEPS.SAVINGS],
    target: 8,
  },
  {
    label: "6. Auto Loan",
    // subSteps: [PLAN_BUILD_STEPS.RECOMMENDATIONS],
    target: 9,
  },
  {
    label: "7. Recap",
    // subSteps: [PLAN_BUILD_STEPS.RECOMMENDATIONS],
    target: 10,
  },
  {
    label: "8. Simulate & Review",
    // subSteps: [PLAN_BUILD_STEPS.RECOMMENDATIONS],
    target: 11,
  },
];

interface temp {
  stepIndex: number;
  furthestStep: number;
  handleClick: (index: number) => void;
}

const LeftSidebar = ({ stepIndex, furthestStep, handleClick }: temp) => {
  const styles = useStyles();
  const reviewStyles = useReviewStyles();

  const mainStyle = stepIndex < 11 ? styles.sidebar : reviewStyles.main;

  return (
    <Card className={mainStyle} title={"Plan Setup"} noPadding titleTag="h6">
      <Divider />
      <Box className={styles.leftCenter}>
        {steps.map((step: any, index) => {
          let className = styles.step;
          let onItemClick: any = () => {
            handleClick(step.target);
          };
          if (stepIndex === step.target) {
            className = `${styles.step} ${styles.activeStep}`;
            onItemClick = undefined;
          } else if (
            stepIndex !== index &&
            step.relevantSteps &&
            step.relevantSteps.includes(stepIndex)
          ) {
            className = `${styles.step} ${styles.activeStep}`;
            onItemClick = undefined;
          } else if (furthestStep >= step.target) {
            className = `${styles.step} ${styles.completedStep}`;
          }
          return (
            <Typography
              key={step.label}
              variant="body1"
              className={className}
              onClick={onItemClick}
            >
              {step.label}
            </Typography>
          );
        })}
      </Box>
      {/* <Divider />
      <Box className={styles.leftBottom}>
        <Typography variant="body2">{planBuilderText[currentStep]}</Typography>
        <LearnMoreLink learnMoreKey={`planBuilder${stepIndex}`} />
      </Box> */}
    </Card>
  );
};

export default LeftSidebar;

const useReviewStyles = makeStyles({
  main: {
    borderRadius: 12,
    height: 620,
    maxHeight: "calc(100vh - 110px)",
    "&>div": {
      height: "100%",
    },
    maxWidth: 236,
    minWidth: 236,
  },
});
