import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { range } from "lodash";

import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import Icon from "src/components/Icon";
import SimpleTabs from "src/components/SimpleTabs";
import ReviewTransactionsDialog from "src/pages/Dashboard/MyProfile/Transactions/Dialogs/ReviewTransactionsDialog";
import {
  ACCOUNT_TYPES,
  EDUCATION_EXPENSE_TYPES,
  ALL_EXPENSE_TYPES,
  ALL_RISK_MANAGEMENT_TYPES,
} from "src/interfaces";
import OverviewTable from "src/pages/PlanBuilder/Views/Review/components/OverviewTable";
import {
  getStudentIncomeAndExpenseSummaries,
  loadStudentPlanSummary,
} from "src/store/dashboard/actions";
import {
  getExecutionSummary,
  getStudentCashflowSummaries,
  getStudentPlanSummaries,
} from "src/store/dashboard/selector";
import {
  getActivePlanYearlySummary,
  getLiveProjection,
} from "src/store/planBuild/selector";
import { getLastGraduationYearMonth } from "src/store/profileBuild/selector";
import { formatWholeDollars, formatExecutionPercent } from "src/utils";

const useStyles = makeStyles({
  chartContainer: {
    display: "flex",
    margin: "0 auto",
    maxWidth: 900,
  },
  eventsContainer: {
    margin: "20px 0px 0px 30px",
  },
  eventsContainerStudent: {
    margin: "20px 0px 0px 30px",
    maxWidth: 400,
  },
  executionSummary: {
    margin: "0px auto",
    maxWidth: 400,
  },
  executionSummaryStudent: {
    margin: "20px auto",
    width: "100%",
  },
  executionGoals: {
    margin: "auto",
    width: 900,
  },
  overviewContainer: {
    width: 900,
    marginBottom: "60px",
  },
  explainer: {
    margin: "20px auto",
    maxWidth: 670,
  },
  tabs: {
    margin: "20px auto 0",
  },
  bottomBar: {
    position: "fixed",
    bottom: "0px",
    width: "100%",
    backgroundColor: "white",
    margin: "0px -30px",
    padding: "10px 0px",
  },
  editButton: {
    display: "block",
    margin: "0 auto",
    width: 200,
  },
  loading: {
    margin: "100px auto",
    textAlign: "center",
  },
});

const nowYear = new Date(
  new Date().valueOf() - new Date().getTimezoneOffset() * 60000
).getFullYear();

const PlanSummaryContainer = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const executionSummary: any = useSelector(getExecutionSummary);
  const liveProjection = useSelector(getLiveProjection);
  const studentExecutionSummaries: any = useSelector(getStudentPlanSummaries);
  const studentPlanSummary: any = useSelector(getActivePlanYearlySummary);
  const studentCashflowSummaries: any = useSelector(
    getStudentCashflowSummaries
  );
  const [graduationYear, graduationMonth] = useSelector(
    getLastGraduationYearMonth
  );
  const [showTransactionsDialog, setShowTransactionsDialog] = useState(false);
  const [transactionQuery, setTransactionQuery] = useState({});
  const [cashMagnifyingMessage, setCashMagnifyingMessage] = useState(false);
  const [currentTab, setTab] = useState(0);
  const [showUnplannedRows, setShowUnplannedRows] = useState<any>({
    education: false,
    expenses: false,
    assets: false,
    debts: false,
    risks: false,
  });
  const planStart = liveProjection?.start || `${nowYear}-01`;
  const startYear = +planStart.slice(0, 4);
  const timeframes = range(startYear, graduationYear + 1);
  const timeframe = timeframes[currentTab];

  const yearList = range(startYear, graduationYear + 1).map((val) => "" + val);

  const unplannedValues: any = useMemo(() => {
    const output: any = {
      education: [],
      expenses: [],
      assets: [],
      debts: [],
      risks: [],
    };
    if (
      !studentExecutionSummaries ||
      !studentPlanSummary ||
      !studentCashflowSummaries
    ) {
      return output;
    }
    try {
      const plannedEducationKeys = new Set(
        Object.keys(studentPlanSummary.educationExpenses[timeframe])
      );
      const plannedExpenseKeys = new Set(
        Object.keys(studentPlanSummary.expenses[timeframe])
      );
      const plannedAssetKeys = new Set(
        Object.keys(studentPlanSummary.assets[timeframe])
      );
      const plannedDebtKeys = new Set(
        Object.keys(studentPlanSummary.debts[timeframe])
      );
      const plannedRiskKeys = new Set(
        Object.keys(studentPlanSummary.risks[timeframe])
      );
      studentExecutionSummaries.educationKeys.forEach((key: string) => {
        if (!plannedEducationKeys.has(key)) {
          output.education.push(key);
        }
      });
      studentCashflowSummaries.expenseKeys.forEach((key: string) => {
        if (!plannedExpenseKeys.has(key) && key !== "taxes") {
          output.expenses.push(key);
        }
      });
      studentExecutionSummaries.assetKeys.forEach((key: string) => {
        if (!plannedAssetKeys.has(key)) {
          output.assets.push(key);
        }
      });
      studentExecutionSummaries.debtKeys.forEach((key: string) => {
        if (!plannedDebtKeys.has(key)) {
          output.debts.push(key);
        }
      });
      studentCashflowSummaries.riskKeys.forEach((key: string) => {
        if (!plannedRiskKeys.has(key)) {
          output.risks.push(key);
        }
      });
    } catch (e) {
      return output;
    }
    return output;
  }, [
    studentExecutionSummaries,
    studentPlanSummary,
    studentCashflowSummaries,
    timeframe,
  ]);

  const loadSummary = (year: number) => {
    const summaryArgs = { year: year || nowYear, startMonth: 0, endMonth: 0 };
    if (startYear === nowYear) {
      summaryArgs.startMonth = +planStart.slice(5, 7);
    }
    if (graduationYear === year) {
      summaryArgs.endMonth = graduationMonth;
    }
    dispatch(loadStudentPlanSummary(summaryArgs));
    dispatch(getStudentIncomeAndExpenseSummaries(summaryArgs));
  };

  useEffect(() => {
    loadSummary(startYear);
  }, []);

  const displayActual = +timeframe <= nowYear;
  const inSchoolSummaryColumns = displayActual
    ? ["Actual", "Planned", "% Followed"]
    : ["Planned"];

  const getExecutionSummaryFooters = (noIncome: boolean) => {
    const startDate = new Date(planStart).toLocaleDateString([], {
      month: "short",
      year: "numeric",
    });
    const footers: any = [
      {
        text: `Reflects amounts since your plan was implemented in ${startDate}.`,
      },
      // TODO: unhide Execution Summary video below. Dont forget to update the link and add the video where it needs to be
      // {
      //   text: "What does this section Mean?",
      //   link: "planBuilder1",
      // },
    ];

    if (noIncome) {
      footers.splice(1, 0, {
        text:
          'Your actual gross income is needed to compute the figures in the "Actual" column. To update your income ',
        redirect: "/transactions",
      });
    }
    return footers;
  };

  const selectTab = (yearIndex: any) => {
    const year = range(startYear, graduationYear + 1)[yearIndex];
    loadSummary(year);
    setTab(yearIndex);
  };

  const getStyle = (section: string) => {
    let styleName = section;
    styleName = styleName + "Student";
    return styles[styleName as keyof typeof styles];
  };

  const refreshData = () => {
    const year = range(startYear, graduationYear + 1)[currentTab];
    loadSummary(year);
  };

  const openCashDialog = () => {
    setTransactionQuery({});
    setShowTransactionsDialog(true);
    setCashMagnifyingMessage(true);
  };

  const openTransactionsDialog = (category: string, key: string) => {
    if (key === "cash_value") {
      openCashDialog();
      return;
    }
    const query: any = {
      category,
      type: key,
    };
    const queryYear = range(startYear, graduationYear + 1)[currentTab];
    let queryStartMonth = "01";
    let queryEndMonth = "12";
    if (queryYear === +planStart.slice(0, 4)) {
      queryStartMonth = planStart.slice(5, 7);
    }
    if (queryYear === graduationYear) {
      queryEndMonth = "" + graduationMonth;
      if (queryEndMonth.length === 1) {
        queryEndMonth = "0" + queryEndMonth;
      }
    }
    query.from = `${queryYear}-${queryStartMonth}`;
    query.to = `${queryYear}-${queryEndMonth}`;
    setShowTransactionsDialog(true);
    setTransactionQuery(query);
    setCashMagnifyingMessage(false);
  };

  const toggleShowUnplanned = (key: string) => {
    setShowUnplannedRows((current: any) => {
      const result = { ...current, [key]: !current[key] };
      return result;
    });
  };

  const closeTransactionsDialog = () => {
    setShowTransactionsDialog(false);
    setTransactionQuery({});
  };

  const renderUnplannedRow = (key: string) => ({
    header: (
      <Box
        className="inline-flex items-center cursor-pointer"
        onClick={() => toggleShowUnplanned(key)}
      >
        <Box>Unplanned</Box>
        <Box className="ml-2">
          <Icon
            style={{ fontSize: 12 }}
            iconName={
              showUnplannedRows[key] ? "fb-chevron-down" : "fb-chevron-right"
            }
          />
        </Box>
      </Box>
    ),
    columns: [],
  });

  const renderEducationChildRow = (key: string) => ({
    header:
      EDUCATION_EXPENSE_TYPES[key as keyof typeof EDUCATION_EXPENSE_TYPES],
    columns: displayActual
      ? [
          <Box className="flex items-center justify-center">
            {!!studentExecutionSummaries.education[key] && (
              <IconButton
                color="primary"
                onClick={() => openTransactionsDialog("education", key)}
                style={{ height: 0, marginLeft: -26, marginRight: 3, width: 0 }}
              >
                <Icon iconName="fa-pencil" />
              </IconButton>
            )}
            <Box>
              {formatWholeDollars(studentExecutionSummaries.education[key])}
            </Box>
          </Box>,
          formatWholeDollars(
            studentPlanSummary.educationExpenses[timeframe][key]
          ),
          formatExecutionPercent(
            studentExecutionSummaries.education[key],
            studentPlanSummary.educationExpenses[timeframe][key]
          ),
        ]
      : [
          formatWholeDollars(
            studentPlanSummary.educationExpenses[timeframe][key]
          ),
        ],
  });
  const renderExpenseChildRow = (key: string) => {
    const actualKey = key === "tax" ? "taxes" : key;
    return {
      header: ALL_EXPENSE_TYPES[key as keyof typeof ALL_EXPENSE_TYPES],
      columns: displayActual
        ? [
            <Box className="flex items-center justify-center">
              {!!studentCashflowSummaries.expenses[actualKey] && (
                <IconButton
                  color="primary"
                  onClick={() => openTransactionsDialog("expense", actualKey)}
                  style={{
                    height: 0,
                    marginLeft: -26,
                    marginRight: 3,
                    width: 0,
                  }}
                >
                  <Icon iconName="fa-pencil" />
                </IconButton>
              )}
              <Box>
                {formatWholeDollars(
                  studentCashflowSummaries.expenses[actualKey]
                )}
              </Box>
            </Box>,
            formatWholeDollars(studentPlanSummary.expenses[timeframe][key]),
            formatExecutionPercent(
              studentCashflowSummaries.expenses[actualKey],
              studentPlanSummary.expenses[timeframe]
            ),
          ]
        : [formatWholeDollars(studentPlanSummary.expenses[timeframe][key])],
    };
  };
  const renderAssetChildRow = (key: string) => ({
    header: ACCOUNT_TYPES[key as keyof typeof ACCOUNT_TYPES],
    columns: displayActual
      ? [
          <Box className="flex items-center justify-center">
            {!!studentExecutionSummaries.asset[key] && (
              <IconButton
                style={{ height: 0, marginLeft: -26, marginRight: 3, width: 0 }}
                color="primary"
                onClick={() => openTransactionsDialog("contribution", key)}
              >
                <Icon style={{ padding: 0 }} iconName="fa-pencil" />
              </IconButton>
            )}
            <Box>
              {formatWholeDollars(studentExecutionSummaries.asset[key])}
            </Box>
          </Box>,
          formatWholeDollars(studentPlanSummary.assets[timeframe][key]),
          formatExecutionPercent(
            studentExecutionSummaries.asset[key],
            studentPlanSummary.assets[timeframe][key]
          ),
        ]
      : [formatWholeDollars(studentPlanSummary.assets[timeframe][key])],
  });
  const renderDebtChildRow = (key: string) => ({
    header: ACCOUNT_TYPES[key as keyof typeof ACCOUNT_TYPES],
    columns: displayActual
      ? [
          <Box className="flex items-center justify-center">
            {!!studentExecutionSummaries.debt[key] && (
              <IconButton
                color="primary"
                onClick={() => openTransactionsDialog("contribution", key)}
                style={{ height: 0, marginLeft: -26, marginRight: 3, width: 0 }}
              >
                <Icon iconName="fa-pencil" />
              </IconButton>
            )}
            <Box>{formatWholeDollars(studentExecutionSummaries.debt[key])}</Box>
          </Box>,
          formatWholeDollars(studentPlanSummary.debts[timeframe][key]),
          formatExecutionPercent(
            studentExecutionSummaries.debt[key],
            studentPlanSummary.debts[timeframe][key]
          ),
        ]
      : [formatWholeDollars(studentPlanSummary.debts[timeframe][key])],
  });
  const renderRiskChildRow = (key: string) => {
    return {
      header:
        ALL_RISK_MANAGEMENT_TYPES[
          key as keyof typeof ALL_RISK_MANAGEMENT_TYPES
        ],
      columns: displayActual
        ? [
            <Box className="flex items-center justify-center">
              {!!studentCashflowSummaries.risks[key] && (
                <IconButton
                  color="primary"
                  onClick={() => openTransactionsDialog("risk", key)}
                  style={{
                    height: 0,
                    marginLeft: -26,
                    marginRight: 3,
                    width: 0,
                  }}
                >
                  <Icon iconName="fa-pencil" />
                </IconButton>
              )}
              <Box>
                {formatWholeDollars(studentCashflowSummaries.risks[key])}
              </Box>
            </Box>,
            formatWholeDollars(studentPlanSummary.risks[timeframe][key]),
            formatExecutionPercent(
              studentCashflowSummaries.risks[key],
              studentPlanSummary.risks[timeframe]
            ),
          ]
        : [formatWholeDollars(studentPlanSummary.risks[timeframe][key])],
    };
  };

  const getSummaryRows = React.useMemo(() => {
    let loading = false;
    let rows: any[] = [];
    try {
      if (!studentPlanSummary?.educationExpenses?.[timeframe]) {
        loading = true;
        return { loading, rows };
      }
      const educationDetailChildren: any[] = Object.keys(
        studentPlanSummary.educationExpenses[timeframe]
      )
        .filter((key) => key !== "total")
        .map(renderEducationChildRow);
      if (displayActual && unplannedValues.education.length) {
        educationDetailChildren.push(renderUnplannedRow("education"));
        if (showUnplannedRows.education) {
          educationDetailChildren.push(
            ...unplannedValues.education.map(renderEducationChildRow)
          );
        }
      }
      const expenseDetailChildren: any[] = Object.keys(
        studentPlanSummary.expenses[timeframe]
      )
        .filter((key) => key !== "total" && key !== "tax")
        .map(renderExpenseChildRow);
      expenseDetailChildren.unshift(renderExpenseChildRow("tax"));
      if (displayActual && unplannedValues.expenses.length) {
        expenseDetailChildren.push(renderUnplannedRow("expenses"));
        if (showUnplannedRows.expenses) {
          expenseDetailChildren.push(
            ...unplannedValues.expenses.map(renderExpenseChildRow)
          );
        }
      }
      const assetsDetailChildren: any[] = Object.keys(
        studentPlanSummary.assets[timeframe]
      )
        .filter((key) => key !== "total")
        .map(renderAssetChildRow);
      if (displayActual && unplannedValues.assets.length) {
        assetsDetailChildren.push(renderUnplannedRow("assets"));
        if (showUnplannedRows.assets) {
          assetsDetailChildren.push(
            ...unplannedValues.assets.map(renderAssetChildRow)
          );
        }
      }
      const debtsDetailChildren: any[] = Object.keys(
        studentPlanSummary.debts[timeframe]
      )
        .filter((key) => key !== "total")
        .map(renderDebtChildRow);
      if (displayActual && unplannedValues.debts.length) {
        debtsDetailChildren.push(renderUnplannedRow("debts"));
        if (showUnplannedRows.debts) {
          debtsDetailChildren.push(
            ...unplannedValues.debts.map(renderDebtChildRow)
          );
        }
      }
      const risksDetailChildren: any[] = Object.keys(
        studentPlanSummary.risks[timeframe]
      )
        .filter((key) => key !== "total")
        .map(renderRiskChildRow);
      if (displayActual && unplannedValues.risks.length) {
        risksDetailChildren.push(renderUnplannedRow("risks"));
        if (showUnplannedRows.risks) {
          risksDetailChildren.push(
            ...unplannedValues.risks.map(renderRiskChildRow)
          );
        }
      }
      rows = [
        {
          header: "Education Costs",
          columns: displayActual
            ? [
                formatWholeDollars(studentExecutionSummaries.education.total),
                formatWholeDollars(
                  studentPlanSummary.educationExpenses[timeframe].total
                ),
                formatExecutionPercent(
                  studentExecutionSummaries.education.total,
                  studentPlanSummary.educationExpenses[timeframe].total
                ),
              ]
            : [
                formatWholeDollars(
                  studentPlanSummary.educationExpenses[timeframe].total
                ),
              ],
          children: educationDetailChildren,
        },
        {
          header: "Day to Day Expenses",
          columns: displayActual
            ? [
                formatWholeDollars(studentCashflowSummaries.expenses.total),
                formatWholeDollars(
                  studentPlanSummary.expenses[timeframe].total
                ),
                formatExecutionPercent(
                  studentCashflowSummaries.expenses.total,
                  studentPlanSummary.expenses[timeframe].total
                ),
              ]
            : [
                formatWholeDollars(
                  studentPlanSummary.expenses[timeframe].total
                ),
              ],
          children: expenseDetailChildren,
        },
        {
          header: "Assets",
          columns: displayActual
            ? [
                formatWholeDollars(studentExecutionSummaries.asset.total),
                formatWholeDollars(studentPlanSummary.assets[timeframe].total),
                formatExecutionPercent(
                  studentExecutionSummaries.asset.total,
                  studentPlanSummary.assets[timeframe].total
                ),
              ]
            : [formatWholeDollars(studentPlanSummary.assets[timeframe].total)],
          children: assetsDetailChildren,
        },
        {
          header: "Debts",
          columns: displayActual
            ? [
                formatWholeDollars(studentExecutionSummaries.debt.total),
                formatWholeDollars(studentPlanSummary.debts[timeframe].total),
                formatExecutionPercent(
                  studentExecutionSummaries.debt.total,
                  studentPlanSummary.debts[timeframe].total
                ),
              ]
            : [formatWholeDollars(studentPlanSummary.debts[timeframe].total)],
          children: debtsDetailChildren,
        },
        {
          header: "Risk Management",
          columns: displayActual
            ? [
                formatWholeDollars(studentCashflowSummaries.risks.total),
                formatWholeDollars(studentPlanSummary.risks[timeframe].total),
                formatExecutionPercent(
                  studentCashflowSummaries.risks.total,
                  studentPlanSummary.risks[timeframe].total
                ),
              ]
            : [formatWholeDollars(studentPlanSummary.risks[timeframe].total)],
          children: risksDetailChildren,
        },
      ];
    } catch (summaryError) {
      loading = true;
      rows = [];
    }
    return { loading, rows };
  }, [
    studentExecutionSummaries,
    studentPlanSummary,
    studentCashflowSummaries,
    executionSummary,
    timeframe,
    showUnplannedRows,
  ]);

  const renderTable = () => (
    <OverviewTable
      title="My Financial Plan Execution Summary"
      noGrid
      columns={inSchoolSummaryColumns}
      rows={getSummaryRows.rows}
      learnMoreRows={getExecutionSummaryFooters(false)}
    />
  );

  return (
    <Box className={getStyle("executionSummary")}>
      <Box className={styles.tabs}>
        {!getSummaryRows.loading && (
          <SimpleTabs list={yearList} value={currentTab} onChange={selectTab} />
        )}
        <br />
      </Box>
      {getSummaryRows.loading && <CircularProgress size={21} />}
      {!getSummaryRows.loading && renderTable()}
      <ReviewTransactionsDialog
        onAdd={loadSummary}
        onClose={closeTransactionsDialog}
        open={showTransactionsDialog}
        query={transactionQuery}
        cashMessage={cashMagnifyingMessage}
        onRemove={refreshData}
      />
    </Box>
  );
};

export default PlanSummaryContainer;
