import React from "react";

import {
  CardProps,
  Container,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Card from "src/components/Card";
import { SidebarContent } from "src/interfaces";

interface ICenterContainerProps {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  iconName?: string;
  tooltip?: string;
  scrollable?: boolean;
  help?: SidebarContent[];
  review?: boolean;
}

const CenterContainer = ({
  children,
  title,
  description,
  iconName,
  scrollable,
  help,
  review,
  ...rest
}: ICenterContainerProps & CardProps) => {
  const styles = useStyles();

  const getScrollStyle = () => {
    if (!review && scrollable) {
      return styles.content;
    } else if (review && scrollable) {
      return styles.reviewScrollable;
    } else if (review) {
      return styles.reviewFixed;
    } else {
      return "";
    }
  };

  return (
    <Card
      title={title}
      noPadding
      titleTag="h6"
      className={review ? styles.reviewRoot : styles.root}
      iconName={iconName}
      help={help}
      {...rest}
    >
      <Divider className={styles.divider} />
      <Container className={getScrollStyle()}>
        {description && (
          <Typography className={styles.description}>{description}</Typography>
        )}
        {children}
      </Container>
    </Card>
  );
};

export default CenterContainer;

const useStyles = makeStyles({
  root: {
    borderRadius: 12,
    minHeight: "calc(100vh - 110px)",
  },
  reviewRoot: {
    borderRadius: 12,
    minHeight: "calc(100vh - 170px)",
  },
  content: {
    position: "relative",
    height: "calc(100vh - 185px)",
    overflowY: "auto",
  },
  reviewFixed: {
    position: "relative",
    height: "calc(100vh - 280px)",
  },
  reviewScrollable: {
    position: "relative",
    height: "calc(100vh - 280px)",
    overflowY: "auto",
  },
  description: {
    fontSize: 14,
    marginTop: -10,
    marginBottom: 20,
  },
  divider: {
    width: "95%",
    margin: "0 auto",
  },
});
