export const wealthPageText = [
  // net
  "Visualize how to convert your human capital to net worth by building a customized plan today.",
  // assets
  "Over time, your Human Capital will convert into additional financial assets to consider as you plan for retirement. The key is to ensure the appropriate asset allocation across the board to meet your goals and income needs throughout retirement.",
  // hc
  "Human Capital represents the value of one's future earnings and is the largest asset for most young professionals. By saving one's earnings over time, intangible Human Capital is converted into tangible financial assets. FitBUX looks at your Human Capital, including your income potential and any risks to your income, to help you build a customized financial plan and inform key financial decisions (e.g., Should you buy vs. rent?).",
  // debt
  "Sometimes your best investment is paying off debt.  Doing so reduces your financial risk and increases your FitBUX Score.",
];

export const riskPageText =
  "Risk Management, a.k.a purchasing insurance (life, disability etc) is key to limiting the risk to your projected income. Enter your monthly insurance premiums below.";
