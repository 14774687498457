import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { usePlaidLink, PlaidLinkOptions } from "react-plaid-link";

import clsx from "clsx";

import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";

import { getPlaidPublicTokenApi } from "src/apiService";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Icon from "src/components/Icon";
import {
  getProviderAccounts,
  relinkLinkedAccount,
} from "src/store/account/actions";
import { formatDollars, DOLLAR_FORMATS } from "src/utils";

import useStyles from "./styles";

const MainContent = ({
  accounts,
  provider,
  onClose,
  goToNextAfterFix,
  showWaitingMessage,
  noProviders,
  loading,
  polling,
}: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [publicToken, setPublicToken] = useState<any>("");

  useEffect(() => {
    if (provider && provider.item_id) {
      dispatch(getProviderAccounts(provider));
      getPlaidPublicTokenApi({ itemId: provider.item_id }).then((data) => {
        setPublicToken(data?.link_token || "");
      });
    } else {
      // setAccounts([]);
      // setLoadingAccounts(true);
    }
  }, [provider]);

  const config: PlaidLinkOptions = useMemo(
    () => ({
      token: publicToken,
      onSuccess: () => {
        if (provider && provider.item_id) {
          dispatch(relinkLinkedAccount(provider.item_id));
          goToNextAfterFix(provider.item_id);
        }
      },
      onExit: () => null,
    }),
    [provider, publicToken]
  );

  const { open: relink, ready } = usePlaidLink(config);

  const sortedAccounts = useMemo(
    () =>
      [...accounts].sort((a, b) => {
        if (a.balance === b.balance) {
          if (a.name < b.name) {
            return 1;
          }
          return -1;
        }
        return b.balance - a.balance;
      }),
    [accounts]
  );

  const renderClosedAccountIcon = () => (
    <Tooltip title="This account is ignored in your plan. Contact your coach for questions.">
      <span>
        <Icon iconName="fa-eye-slash" className="ml-2" />
      </span>
    </Tooltip>
  );

  const close = () => {
    // setAccounts([]);
    // setLoadingAccounts(true);
    onClose();
  };

  const lastDate = accounts?.length
    ? accounts[0].last_updated
    : provider?.update;

  return (
    <Card
      className={styles.main}
      title={
        noProviders
          ? ""
          : `Linked Accounts${provider ? " Of " + provider.name : ""}`
      }
      noPadding
      titleTag="h6"
      headComponent={
        <IconButton color="primary" onClick={close} style={{ height: 40 }}>
          <Icon iconName="fb-close" />
        </IconButton>
      }
      divider
    >
      <Box className="pl-10">
        {noProviders && (
          <Box className={clsx(styles.providerErrorHeader, "mt-8")}>
            <Typography variant="h4" className={styles.errorHeaderText}>
              Click the buttons in the sidebar to link your financial
              institution.
            </Typography>
          </Box>
        )}
        {!provider?.error && showWaitingMessage && (
          <Box className={clsx(styles.providerErrorHeader, "mt-8")}>
            <Typography variant="h4" className={styles.errorHeaderText}>
              Hold tight as it may take a minute to fetch your account
              information.
            </Typography>
          </Box>
        )}
        {!!provider?.error && (
          <Box className={styles.providerErrorHeader}>
            <Typography variant="h4" className={styles.errorHeaderText}>
              We encountered issues connecting to your institution. Click below
              to resolve.
            </Typography>
            <Button
              fbColor="primary"
              variant="contained"
              onClick={() => relink()}
              disabled={loading || !ready}
            >
              Fix
            </Button>
          </Box>
        )}
        {/*loadingAccounts && <CircularProgress size={40} />*/}
        {!!sortedAccounts.length && (
          <Box className={styles.accountList}>
            {!!sortedAccounts.length && (
              <Box className={styles.accountRow}>
                <Box className={styles.tableHeader}>Account Name</Box>
                <Box className={styles.accountBalance}>Balance</Box>
              </Box>
            )}
            <>
              {sortedAccounts.map((account: any) => (
                <Box className={styles.accountRow} key={account.id}>
                  <Box
                    className={clsx(
                      styles.accountName,
                      account.ignore ? styles.closedAccount : ""
                    )}
                  >
                    {account.name}
                    {account.ignore ? renderClosedAccountIcon() : ""}
                  </Box>
                  <Box
                    className={clsx(
                      styles.accountBalance,
                      account.ignore ? styles.closedAccount : ""
                    )}
                  >
                    {formatDollars(
                      account.balance,
                      DOLLAR_FORMATS.DOLLARS_AND_CENTS
                    )}
                  </Box>
                </Box>
              ))}
            </>
            <Box className="mt-8">
              {polling
                ? "It can take a minute to fetch your latest account information."
                : `Last Updated: ${new Date(lastDate).toLocaleDateString()}`}
            </Box>
          </Box>
        )}
      </Box>
      {!showWaitingMessage && !!provider && !provider.error && (
        <Box className={styles.mainBottom}>
          Don't see one of your accounts here?{" "}
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              relink();
            }}
          >
            Click here to grant access.
          </a>
        </Box>
      )}
    </Card>
  );
};

export default MainContent;
