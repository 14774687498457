import React from "react";

import GeneralPage from "../GeneralPage";
import data from "./data";

export const HomeBuying = () => (
  <GeneralPage data={data} title="Home Ownership Tools and Products" />
);

export default HomeBuying;
