import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import { radioStyles } from "../../theme/base";

const useStyles = makeStyles(radioStyles);

const FbRadio: React.FC<RadioProps> = (props) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      color="primary"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative radio" }}
      {...props}
    />
  );
};

export default FbRadio;
