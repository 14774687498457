import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { formatWholeDollars } from "src/utils/formatter";

const useStyles = makeStyles({
  text: { fontSize: 14 },
  buttonContainer: {
    margin: "40px auto",
    display: "flex",
    justifyContent: "center",
  },
});

const Results = ({ back, result }: any) => {
  const styles = useStyles();

  return (
    <>
      <Card title="">
        <Typography className={styles.text}>
          The future value of this CD is {formatWholeDollars(result)}.
        </Typography>
      </Card>
      <Box className={styles.buttonContainer}>
        <Button fbColor="primary" onClick={back}>
          Back
        </Button>
      </Box>
    </>
  );
};

export default Results;
