import React, { useEffect, useState } from "react";
import { sortBy } from "lodash";

import {
  Box,
  makeStyles,
  Popper,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { fetchTickerOptions } from "src/apiService";

import Button from "src/components/Button";
import CustomDialog from "src/components/Dialogs/CustomDialog";

interface TickerOption {
  Code: string;
  Name: string;
  Exchange: string;
}

const useStyles = makeStyles({
  tickerSymbol: {
    maxWidth: 60,
    overflowX: "hidden",
    marginRight: 10,
    width: 60,
    textOverflow: "ellipsis",
  },
});

export const AddAllocationDialog = ({ open, onClose, addAllocation }: any) => {
  const classes = useStyles();
  const [options, setOptions] = useState<TickerOption[]>([]);
  const [tickerInputValue, setTickerInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    let active = true;
    if (!tickerInputValue) {
      setOptions([]);
      return undefined;
    }

    if (fetchTickerOptions) {
      (fetchTickerOptions as any)(tickerInputValue).then((results: any[]) => {
        if (active) {
          const sorted = sortBy(results, (item) => {
            if (item.Exchange === "US") {
              return 1;
            }
            if (item.Exchange === "CC") {
              return 2;
            }
            return 3;
          });
          setOptions(sorted);
        }
      });
    }
    return () => {
      active = false;
    };
  }, [tickerInputValue]);

  const close = () => {
    setOptions([]);
    setTickerInputValue("");
    setSelectedValue("");
    onClose();
  };

  const save = () => {
    addAllocation(selectedValue);
    close();
  };

  return (
    <CustomDialog
      title="Add Investment Allocation"
      size="xs"
      isOpen={open}
      onClose={close}
    >
      <Box className="py-3">
        <Autocomplete
          className="input"
          id="select-ticker"
          getOptionLabel={(option) => {
            return typeof option === "string" ? option : option.Code;
          }}
          filterOptions={(x) => x}
          options={options}
          filterSelectedOptions
          forcePopupIcon={false}
          getOptionSelected={(option) =>
            selectedValue === `${option.Code}.${option.Exchange}`
          }
          PopperComponent={(pProps) => (
            <Popper {...pProps} style={{ width: 420 }} />
          )}
          ListboxProps={{ style: { width: 900 } }}
          value={(selectedValue || ".").split(".")[0] as any}
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setSelectedValue(
              newValue ? `${newValue.Code}.${newValue.Exchange}` : ""
            );
          }}
          onInputChange={(event, newInputValue) => {
            setTickerInputValue(newInputValue);
          }}
          renderInput={(params) => <TextField className="input" {...params} />}
          renderOption={(option: any) => {
            return (
              <Box
                className="flex justify-between w-96"
                key={option.Name + option.Code}
              >
                <Box className="flex">
                  <Typography className={classes.tickerSymbol}>
                    {option.Code}
                  </Typography>
                  <Typography className="mr-4">{option.Name}</Typography>
                </Box>
                <Typography>{option.Exchange}</Typography>
              </Box>
            );
          }}
        />
      </Box>
      <Box className="w-full text-right mt-5">
        <Button fbColor="primary" disabled={!selectedValue} onClick={save}>
          <span>Confirm</span>
        </Button>
      </Box>
    </CustomDialog>
  );
};

export default AddAllocationDialog;
