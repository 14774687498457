import * as React from "react";

import GeneralPage from "../GeneralPage";
import data from "./data";

export const OtherItems = () => (
  <GeneralPage data={data} title={"Other Financial Tools and Products"} />
);

export default OtherItems;
