import React, { useEffect, useState } from "react";

import { pick } from "lodash";
import Radio from "src/components/Radio";

import {
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import { ViewComponent } from "src/interfaces/viewComponent.interface";
import { getScore, setRefreshNeeded } from "src/store/dashboard/actions";
import {
  fetchEmployments,
  fetchFellowships,
  setProfileStep,
  updateCareer,
  UpdateCareerPayload,
} from "src/store/oldProfileBuild/actions";
import { useDispatch, useSelector } from "react-redux";
import { CareerSection, YesNo } from "src/interfaces";

import {
  getCareer,
  getProfileLoading,
  getEducation,
  getSpouseCareer,
  getSpouseEducation,
  getStudentStatus,
} from "src/store/profileBuild/selector";
import {
  getCurrentStep,
  getDesignations,
  getEmployments,
  getFellowships,
  getProfessions,
  getResidencies,
} from "src/store/oldProfileBuild/selector";
import { Autocomplete } from "@material-ui/lab";
import { CAREER_KEYS } from "src/store/profileBuild/selectorKeys";
import {
  PHYSICAL_THERAPY_ID,
  PROFILE_BUILD_STEPS,
  PROFILE_TYPE,
} from "src/store/oldProfileBuild/constants";
import { DollarTextField } from "src/utils/inputMask";

interface FormValues extends CareerSection {
  changed?: boolean;
  has_fellowship?: YesNo;
  has_residency?: YesNo;
  step?: number;
}

const CareerForm: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const isMine: boolean =
    useSelector(getCurrentStep) === PROFILE_BUILD_STEPS.MY_CAREER;
  const career = useSelector(isMine ? getCareer : getSpouseCareer);
  const education = useSelector(isMine ? getEducation : getSpouseEducation);
  const loading = useSelector(getProfileLoading);
  const professions = useSelector(getProfessions);
  const employments = useSelector(getEmployments);
  const fellowships = useSelector(getFellowships);
  const residencies = useSelector(getResidencies);
  const designations = useSelector(getDesignations);
  const monthsSinceGraduation = useSelector(getStudentStatus);

  const [formValues, setFormValues] = useState<FormValues>({});

  useEffect(() => {
    setFormValues({
      ...career,
      changed: false,
      step: 0,
    });
  }, [career]);

  useEffect(() => {
    if (formValues?.profession === PHYSICAL_THERAPY_ID && !employments.length) {
      dispatch(fetchEmployments());
      if (education.adid) {
        dispatch(fetchFellowships(education.adid));
      }
    }
  }, [formValues?.profession, employments.length, education]);

  const handleChange = (prop: keyof FormValues) => (
    event: React.ChangeEvent<any>,
    t?: any
  ) => {
    let value = event.target.value;

    if (
      prop === "profession" ||
      prop === "fellowship" ||
      prop === "residency" ||
      prop === "designation_name_1" ||
      prop === "designation_name_2" ||
      prop === "designation_name_3" ||
      prop === "designation_name_4" ||
      prop === "designation_name_5"
    ) {
      // autocomplete
      value = t?.id || undefined;
    }

    setFormValues({ ...formValues, [prop]: value });
  };

  if (!render) {
    return <div />;
  }

  return render({
    component: (
      <Container className="p-0 pb-32">
        <Card className="rounded-3xl" style={{ minHeight: 500 }}>
          <CardContent>
            <Typography
              component="h5"
              variant="body1"
              className="text-blue font-bold text-lg mb-3"
            >
              Add {isMine ? "My" : "Spouse"} Career
            </Typography>
            <Divider></Divider>
            <Container maxWidth="xs" className="ml-0 mt-6">
              <Grid container spacing={3}>
                {formValues?.step === 0 && (
                  <Grid item xs={12}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        What is your profession or what will be your profession
                        post graduation?
                      </FormLabel>

                      <Autocomplete
                        options={professions || []}
                        disabled={loading.professions}
                        getOptionLabel={(option) => option?.name || "-"}
                        fullWidth
                        placeholder="Profession"
                        className="mr-4"
                        onChange={handleChange("profession")}
                        value={
                          professions?.find(
                            (profession: { id: string | undefined }) =>
                              profession?.id === formValues?.profession
                          ) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Profession"
                            variant="outlined"
                            placeholder="Profession"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
                {formValues?.step === 1 && (
                  <>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" className="mb-4">
                          What type of employment setting do you work in?
                        </FormLabel>
                        <Select
                          variant="outlined"
                          fullWidth
                          disabled={loading.employments}
                          value={
                            formValues?.pt_employ_setting === undefined
                              ? "-1"
                              : formValues?.pt_employ_setting
                          }
                          onChange={handleChange("pt_employ_setting")}
                        >
                          <MenuItem value="-1">-</MenuItem>
                          {employments?.map((em: any, index: number) => (
                            <MenuItem value={em.id} key={index}>
                              {em.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {!!education.adid && (
                      <>
                        {fellowships && (
                          <Grid item xs={12}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend" className="mb-4">
                                Are your pursuing or have you completed a
                                fellowship?
                              </FormLabel>
                              <RadioGroup
                                value={formValues?.has_fellowship || "n"}
                                className="flex flex-row"
                                onChange={handleChange("has_fellowship")}
                              >
                                <FormControlLabel
                                  value="n"
                                  control={<Radio />}
                                  label="No"
                                />
                                <FormControlLabel
                                  value="y"
                                  control={<Radio />}
                                  label="Yes"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        )}
                        {formValues?.has_fellowship === "y" && (
                          <Grid item xs={12}>
                            <FormLabel component="legend" className="mb-4">
                              Select your fellowship:
                            </FormLabel>
                            <Autocomplete
                              options={fellowships || []}
                              disabled={loading.fellowships}
                              getOptionLabel={(option) => option?.name || "-"}
                              fullWidth
                              placeholder="Profession"
                              className="mr-4"
                              onChange={handleChange("fellowship")}
                              value={
                                fellowships?.find(
                                  (f: { id: number | undefined }) =>
                                    f?.id === formValues?.fellowship
                                ) || null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Fellowship"
                                  variant="outlined"
                                  placeholder="Fellowship"
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {residencies && (
                          <Grid item xs={12}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend" className="mb-4">
                                Are your pursuing or have you completed a
                                residency?
                              </FormLabel>
                              <RadioGroup
                                value={formValues?.has_residency || "n"}
                                className="flex flex-row"
                                onChange={handleChange("has_residency")}
                              >
                                <FormControlLabel
                                  value={"n"}
                                  control={<Radio />}
                                  label="No"
                                />
                                <FormControlLabel
                                  value={"y"}
                                  control={<Radio />}
                                  label="Yes"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        )}
                        {formValues?.has_residency === "y" && (
                          <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth>
                              <FormLabel component="legend" className="mb-4">
                                Select your residency:
                              </FormLabel>
                              <Autocomplete
                                options={residencies || []}
                                disabled={loading.fellowships}
                                getOptionLabel={(option) => option?.name || "-"}
                                fullWidth
                                placeholder="Residency"
                                className="mr-4"
                                onChange={handleChange("residency")}
                                value={
                                  residencies?.find(
                                    (f: { id: number | undefined }) =>
                                      f?.id === formValues?.residency
                                  ) || null
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Residency"
                                    variant="outlined"
                                    placeholder="Residency"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                )}

                {formValues?.step === 2 && (
                  <>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" className="mb-4 h-9">
                          How many years of experience in your profession do you
                          have?
                        </FormLabel>
                        <TextField
                          label="Years"
                          variant="outlined"
                          onChange={handleChange("years_work_exp")}
                          type="number"
                          value={formValues.years_work_exp}
                        />
                      </FormControl>
                    </Grid>
                    {monthsSinceGraduation >= 18 && monthsSinceGraduation < 60 && (
                      <Grid item xs={12}>
                        <FormControl component="fieldset" fullWidth>
                          <FormLabel component="legend" className="mb-4 h-9">
                            What was your starting income for your first job
                            post-graduation?
                          </FormLabel>
                          <DollarTextField
                            label="Income"
                            variant="outlined"
                            onChange={handleChange("prior_annual_salary")}
                            value={formValues.prior_annual_salary}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </>
                )}
                {formValues?.step === 3 && (
                  <>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" className="mb-4 h-8">
                          How many professional designations have you obtained?
                        </FormLabel>
                        <Select
                          variant="outlined"
                          fullWidth
                          value={formValues.number_designations || 0}
                          placeholder="Number of Designations"
                          onChange={handleChange("number_designations")}
                        >
                          {Array.from(Array(6).keys()).map((i) => (
                            <MenuItem value={i} key={i}>
                              {i}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {Array.from(
                      Array(formValues.number_designations || 0).keys()
                    ).map((i) => (
                      <Grid item xs={12} key={i}>
                        <Autocomplete
                          options={designations || []}
                          disabled={loading.designations}
                          getOptionLabel={(option) => option?.name || "-"}
                          fullWidth
                          placeholder={`Designation ${i + 1}`}
                          className="mr-4"
                          onChange={handleChange(
                            CAREER_KEYS[6 + i] as
                              | "designation_name_1"
                              | "designation_name_2"
                              | "designation_name_3"
                              | "designation_name_4"
                              | "designation_name_5"
                          )}
                          value={
                            designations?.find(
                              (school: { id: number | undefined }) =>
                                school?.id ===
                                formValues[
                                  CAREER_KEYS[6 + i] as
                                    | "designation_name_1"
                                    | "designation_name_2"
                                    | "designation_name_3"
                                    | "designation_name_4"
                                    | "designation_name_5"
                                ]
                            ) || null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`Designation ${i + 1}`}
                              variant="outlined"
                              placeholder={`Designation ${i + 1}`}
                            />
                          )}
                        />
                      </Grid>
                    ))}
                  </>
                )}
                {formValues?.step === 4 && (
                  <>
                    <Grid item xs={12} className="mb-4">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          If you took a licensure exam, did you pass on the
                          first try?
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.qual_license_exam || ""}
                          className="flex flex-row"
                          onChange={handleChange("qual_license_exam")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={""}
                            control={<Radio />}
                            label="Does Not Apply to Me"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-4">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Did you pass all parts of a professional designation
                          on the first try?
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.qual_desig_exam || ""}
                          className="flex flex-row"
                          onChange={handleChange("qual_desig_exam")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={""}
                            control={<Radio />}
                            label="Does Not Apply to Me"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-4">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Where you offered a full-time job after your last
                          intership/clinical/fieldwork?
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.qual_internship || ""}
                          className="flex flex-row"
                          onChange={handleChange("qual_internship")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={""}
                            control={<Radio />}
                            label="Does Not Apply to Me"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-4">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Are you part of an industry trade group or
                          association?
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.qual_trade_group || ""}
                          className="flex flex-row"
                          onChange={handleChange("qual_trade_group")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={""}
                            control={<Radio />}
                            label="Does Not Apply to Me"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-4">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Have you managed a team at work?
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.qual_team_leader || ""}
                          className="flex flex-row"
                          onChange={handleChange("qual_team_leader")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={""}
                            control={<Radio />}
                            label="Does Not Apply to Me"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-4">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Have you done speaking engagements
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.qual_speaking || ""}
                          className="flex flex-row"
                          onChange={handleChange("qual_speaking")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={""}
                            control={<Radio />}
                            label="Does Not Apply to Me"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-4">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Do you participate in networking groups?
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.qual_networking || ""}
                          className="flex flex-row"
                          onChange={handleChange("qual_networking")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={""}
                            control={<Radio />}
                            label="Does Not Apply to Me"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-4">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Have you sat on the Board of Directors of a company or
                          organization?
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.qual_director || ""}
                          className="flex flex-row"
                          onChange={handleChange("qual_director")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={""}
                            control={<Radio />}
                            label="Does Not Apply to Me"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Container>
          </CardContent>
        </Card>
      </Container>
    ),
    nextDisabled: loading.profile,
    onPrev: () => {
      if (formValues?.step === 0) {
        window.setTimeout(() => dispatch(getScore()), 500);
        dispatch(setProfileStep(PROFILE_BUILD_STEPS.HUMAN_CAPITAL_OVERVIEW));
      } else {
        let step = formValues?.step ? formValues.step - 1 : 0;
        if (step === 1 && formValues?.profession !== PHYSICAL_THERAPY_ID) {
          step = 0;
        }
        setFormValues({ ...formValues, step });
      }
    },
    onNext: () => {
      const { step, profession } = formValues || {};
      if (step === 0) {
        setFormValues({
          ...formValues,
          step: profession === PHYSICAL_THERAPY_ID ? 1 : 2,
        });
      } else if (step && step < 4) {
        setFormValues({ ...formValues, step: step + 1 });
      } else {
        const careerValues = pick(formValues, CAREER_KEYS);
        const payload: UpdateCareerPayload = {
          who: isMine ? PROFILE_TYPE.APPLICANT : PROFILE_TYPE.SPOUSE,
          update: careerValues,
          nextPage: PROFILE_BUILD_STEPS.HUMAN_CAPITAL_OVERVIEW,
        };
        dispatch(updateCareer(payload));
        window.setTimeout(() => {
          dispatch(getScore());
          dispatch(setRefreshNeeded({ summaries: true }));
        }, 500);
      }
    },
  });
};

export default CareerForm;
