import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, makeStyles } from "@material-ui/core";

import Card from "src/components/Card";
import Button from "src/components/Button";
import {
  getAccountProviders as selectProviders,
  getAccountsLoaded,
} from "src/store/account/selector";
import { getAccountProviders } from "src/store/account/actions";
import AccountLinkPage from "src/pages/Dashboard/MyProfile/Accounts/components/AccountLinkPage";
import AddManualTransactionDialog from "../Dialogs/AddManualTransactionDialog";

export const AddTransactionsCard = ({ openConfirmTransactionsDialog }: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const providers = useSelector(selectProviders);
  const loaded = useSelector(getAccountsLoaded);

  const [linkAccountDialogVisible, setLinkAccountDialogVisible] = useState(
    false
  );
  const [addDialogVisible, setAddDialogVisible] = useState(false);

  useEffect(() => {
    if ((!providers || !providers.length) && !loaded) {
      dispatch(getAccountProviders());
    }
  }, [dispatch]);

  const closeDialogs = () => {
    setLinkAccountDialogVisible(false);
    setAddDialogVisible(false);
  };

  return (
    <Card title="Add Transactions">
      <Box className="my-5">
        <Button
          fbColor="primary"
          className={styles.button}
          onClick={() => setLinkAccountDialogVisible(true)}
        >
          By Linking Accounts
        </Button>
        <Button
          fbColor="primary"
          className={styles.button}
          onClick={() => setAddDialogVisible(true)}
        >
          Manually
        </Button>
      </Box>
      <AccountLinkPage
        isOpen={linkAccountDialogVisible}
        onClose={() => setLinkAccountDialogVisible(false)}
      />
      <AddManualTransactionDialog
        visible={addDialogVisible}
        onClose={closeDialogs}
        openConfirmDialog={openConfirmTransactionsDialog}
      />
    </Card>
  );
};

export default AddTransactionsCard;

const useStyles = makeStyles({
  button: {
    width: "100%",
    margin: "7px 0",
  },
});
