import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";

import GraduatedReview from "./Review";
import Review from "./InSchoolReview";
import Compare from "./Compare";
import Recommendations from "./Recommendations";

const reviewViews = {
  [PLAN_BUILD_STEPS.RECOMMENDATIONS]: Recommendations,
  [PLAN_BUILD_STEPS.REVIEW]: Review,
  [PLAN_BUILD_STEPS.GRADUATED_REVIEW]: GraduatedReview,
  [PLAN_BUILD_STEPS.COMPARE]: Compare,
};

export default reviewViews;
