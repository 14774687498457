import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikProps } from "formik";
import { Redirect } from "react-router";
import { fetchLogin } from "src/store/system/actions";
import { setPasswordReset } from "src/store/system/actions";
import {
  loginStateSelector,
  passwordResetSuccessSelector,
} from "src/store/system/selector";
import {
  EuiTitle,
  EuiText,
  EuiFieldText,
  EuiButton,
  EuiImage,
  EuiShowFor,
  EuiFormRow,
  EuiFieldPassword,
  EuiLink,
} from "@elastic/eui";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import styled from "@emotion/styled";
import login_image from "src/assets/svgs/login_landing.svg";
import fitbux_logo_desktop from "src/assets/svgs/fb_logo_desktop.svg";
import mobile_login_image from "src/assets/svgs/mobile_login.svg";
import {
  StyledSpacer,
  StyledSpan,
  StyledEuiToast,
} from "src/components/Global/StyledComponents";
import FormError from "src/components/Global/FormError";

const LoginContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  height: 100vh;
  padding: 0;
`;

const StyledEuiLink = ({
  to,
  children,
  underline,
  ...rest
}: StyledEuiLinkProps) => (
  <Link to={to}>
    <EuiLink
      {...rest}
      style={{
        fontWeight: 400,
        color: "#1a1c21",
        textDecoration: underline ? "underline" : "none",
      }}
    >
      {children}
    </EuiLink>
  </Link>
);

interface StyledEuiLinkProps {
  to: string;
  children: React.ReactNode;
  underline?: boolean;
  [key: string]: any;
}

interface MyFormValues {
  email: string;
  password: string;
}

const Login = () => {
  const dispatch = useDispatch();
  const { loggedIn, loading, error } = useSelector(loginStateSelector);
  const [showToast, setShowToast] = useState(false);
  const passwordResetSuccess = useSelector(passwordResetSuccessSelector);

  useEffect(() => {
    if (passwordResetSuccess) {
      setShowToast(true);
      dispatch(setPasswordReset());
    }
  }, [passwordResetSuccess, dispatch]);

  const closeToast = () => {
    setShowToast(false);
  };

  const onFormikSubmit = async (
    values: MyFormValues
    // { setSubmitting }: FormikActions<MyFormValues>
  ) => {
    if (values.email !== "" && values.password !== "" && !loading) {
      dispatch(fetchLogin({ ...values }));
    }
  };
  if (loggedIn) {
    return <Redirect to="/plan-summary" />;
  }
  return (
    <LoginContainer fluid className="login-main">
      {showToast && (
        <StyledEuiToast
          color="success"
          iconType="checkInCircleFilled"
          onClose={closeToast}
        >
          Password successfully changed!
        </StyledEuiToast>
      )}
      <div className="half info-container">
        <div className="new-logo">
          <EuiImage
            className="desktop-logo"
            src={fitbux_logo_desktop}
            alt="FitBUX logo"
            size="original"
          />
        </div>

        <StyledSpacer size="36px" />

        <div className="info-content">
          <EuiImage
            src={login_image}
            alt="work life balance image"
            size="original"
          />
        </div>
      </div>
      <div className="half field-container">
        <div className="field-content">
          <EuiShowFor sizes={["xs", "s", "m"]}>
            <EuiImage
              src={mobile_login_image}
              alt="work life balance image"
              size="original"
            />
          </EuiShowFor>

          <StyledSpacer size="32px" />

          {error && !loading && (
            <>
              <FormError type="callout">
                Please double check and try again.
              </FormError>
              <StyledSpacer size="32px" />
            </>
          )}

          <EuiTitle size="l" className="login-title">
            <h1>Log in</h1>
          </EuiTitle>

          <StyledSpacer size="32px" />

          <Formik
            initialValues={{
              password: "",
              email: "",
            }}
            onSubmit={onFormikSubmit}
            render={(formikBag: FormikProps<MyFormValues>) => (
              <form onSubmit={formikBag.handleSubmit}>
                <EuiFormRow
                  label={<StyledSpan>Email Address</StyledSpan>}
                  className="input-size"
                  isInvalid={
                    formikBag.touched.email && !!formikBag.errors.email
                  }
                  error={formikBag.errors.email}
                >
                  <EuiFieldText
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={formikBag.handleChange}
                    onBlur={formikBag.handleBlur}
                    value={formikBag.values.email}
                    isInvalid={
                      formikBag.touched.email && !!formikBag.errors.email
                    }
                  />
                </EuiFormRow>

                <StyledSpacer size="32px" />

                <EuiFormRow
                  label={
                    <>
                      <StyledSpan>Password</StyledSpan>
                      <StyledEuiLink
                        color="text"
                        to="/forgot-password"
                        className="pass-link"
                      >
                        Forgot password?
                      </StyledEuiLink>
                    </>
                  }
                  className="input-size password-input"
                  isInvalid={
                    formikBag.touched.password && !!formikBag.errors.password
                  }
                  error={formikBag.errors.password}
                >
                  <EuiFieldPassword
                    name="password"
                    type="dual"
                    autoComplete="current-password"
                    onChange={formikBag.handleChange}
                    onBlur={formikBag.handleBlur}
                    value={formikBag.values.password}
                    isInvalid={
                      formikBag.touched.password && !!formikBag.errors.password
                    }
                  />
                </EuiFormRow>

                <StyledSpacer size="32px" />

                <EuiButton
                  className="login-button"
                  type="submit"
                  color="primary"
                  fill
                  isLoading={loading}
                >
                  {!loading && "Log in"}
                </EuiButton>

                <StyledSpacer size="24px" />

                <EuiText size="m" className="add-text">
                  <p>
                    Don't have an account?&nbsp;
                    <StyledEuiLink underline color="text" to="/register">
                      Join now
                    </StyledEuiLink>
                  </p>
                </EuiText>
              </form>
            )}
          />
        </div>
      </div>
    </LoginContainer>
  );
};

export default Login;
