import React from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { StepIconProps } from "@material-ui/core/StepIcon";
import StepConnector from "@material-ui/core/StepConnector";

import Icon from "../Icon";

import {
  stepLabelStyles,
  stepIconStyles,
  stepConnectorStyles,
} from "../../theme/base";

interface IFbStepperProps {
  steps: Array<string>;
  active?: number;
  compact?: boolean;
}

const useStyles = makeStyles(stepIconStyles);

const FbConnector = withStyles(stepConnectorStyles)(StepConnector);

const FbStepLabel = withStyles(stepLabelStyles)(StepLabel);

function FbStepIcon(props: StepIconProps) {
  const classes = useStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <Icon iconName="fb-1" />,
    2: <Icon iconName="fb-2" />,
    3: <Icon iconName="fb-3" />,
    4: <Icon iconName="fb-4" />,
    5: <Icon iconName="fb-5" />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function FbStepDot(props: StepIconProps) {
  const classes = useStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.dot, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    ></div>
  );
}

const FbStepper: React.FC<IFbStepperProps> = ({ steps, active, compact }) => {
  return (
    <Stepper
      activeStep={active}
      alternativeLabel
      connector={<FbConnector className={compact ? "compact" : ""} />}
      className={compact ? "p-3 bg-transparent" : "bg-transparent"}
      style={{ backgroundColor: "transparent", backgroundImage: "none" }}
    >
      {steps.map((step) => (
        <Step key={step}>
          <FbStepLabel StepIconComponent={compact ? FbStepDot : FbStepIcon}>
            {step}
          </FbStepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default FbStepper;
