import { createAction } from "redux-actions";
import { START, SUCCESS } from "../common";

import { Household } from "src/interfaces/household.interface";
import { Who } from "src/interfaces/common";
import {
  PersonalInfoSection,
  EducationSection,
  CareerSection,
  OtherSection,
  DebtsSection,
  Profile,
} from "src/interfaces/profile.interface";
import { PROFILE_BUILD_STEPS, PROFILE_PROGRESS } from "./constants";

export interface UpdatePersonalInfoPayload {
  who?: Who;
  update: Partial<PersonalInfoSection>;
  nextPage?: PROFILE_BUILD_STEPS;
}

export interface UpdateHouseholdPayload {
  update: Partial<Household>;
  nextPage?: PROFILE_BUILD_STEPS;
}

export interface UpdateEducationPayload {
  who?: Who;
  update: Partial<EducationSection>;
  nextPage?: PROFILE_BUILD_STEPS;
}

export interface UpdateCareerPayload {
  who?: Who;
  update: Partial<CareerSection>;
  nextPage: PROFILE_BUILD_STEPS;
}

export interface UpdateOtherHumanCapitalPayload {
  who?: Who;
  update: Partial<OtherSection>;
  nextPage: PROFILE_BUILD_STEPS;
}

export interface UpdateDebtsPayload {
  who?: Who;
  update: Partial<DebtsSection>;
  nextPage?: PROFILE_BUILD_STEPS;
}

export const OLD_UPDATE_PROFILE = "OLD_UPDATE_PROFILE";
export const OLD_FETCH_PROFILE = "OLD_FETCH_PROFILE";
export const OLD_FETCH_SPOUSE_PROFILE = "OLD_FETCH_SPOUSE_PROFILE";
export const OLD_FETCH_HOUSEHOLD = "OLD_FETCH_HOUSEHOLD";
export const OLD_SET_PROFILE_STEP = "OLD_SET_PROFILE_STEP";
export const OLD_GET_PROFILE_PROGRESS = "OLD_GET_PROFILE_PROGRESS";
export const OLD_SET_PROFILE_PROGRESS = "OLD_SET_PROFILE_PROGRESS";
export const OLD_UPDATE_PERSONAL_INFO = "OLD_UPDATE_PERSONAL_INFO";
export const OLD_UPDATE_HOUSEHOLD = "OLD_UPDATE_HOUSEHOLD";
export const OLD_INVITE_SPOUSE = "OLD_INVITE_SPOUSE";
export const OLD_FETCH_SCHOOLS = "OLD_FETCH_SCHOOLS";
export const OLD_FETCH_LEVELED_SCHOOLS = "OLD_FETCH_LEVELED_SCHOOLS"; // TODO: check school vs leveled school later
export const OLD_FETCH_SPECIALTIES = "OLD_FETCH_SPECIALITIES";
export const OLD_FETCH_FIELDS = "OLD_FETCH_UNDERGRAD_FIELDS";
export const OLD_FETCH_PROFESSIONS = "OLD_FETCH_PROFESSIONS";
export const OLD_FETCH_EMPLOYMENTS = "OLD_FETCH_EMPLOYMENTS";
export const OLD_FETCH_FELLOWSHIPS = "OLD_FETCH_FELLOWSHIPS";
export const OLD_FETCH_DESIGNATIONS = "OLD_FETCH_DESIGNATIONS";
export const OLD_UPDATE_EDUCATION = "OLD_UPDATE_HUMAN_CAPITAL";
export const OLD_UPDATE_CAREER = "OLD_UPDATE_CAREER";
export const OLD_UPDATE_OTHER_HUMAN_CAPITAL = "OLD_UPDATE_OTHER_HUMAN_CAPITAL";
export const OLD_UPDATE_DEBTS = "OLD_UPDATE_DEBTS";

export const updateProfile = createAction<Partial<Profile>>(
  OLD_UPDATE_PROFILE + START
);
export const fetchProfile = createAction(OLD_FETCH_PROFILE + START);
export const fetchSpouseProfile = createAction(
  OLD_FETCH_SPOUSE_PROFILE + START
);
export const fetchHousehold = createAction(OLD_FETCH_HOUSEHOLD + START);
export const updateHousehold = createAction<UpdateHouseholdPayload>(
  OLD_UPDATE_HOUSEHOLD + START
);
export const updateHouseholdSuccess = createAction<UpdateHouseholdPayload>(
  OLD_UPDATE_HOUSEHOLD + SUCCESS
);
export const setProfileStep = createAction<PROFILE_BUILD_STEPS>(
  OLD_SET_PROFILE_STEP
);
export const getProfileProgress = createAction(
  OLD_GET_PROFILE_PROGRESS + START
);
export const setProfileProgress = createAction<PROFILE_PROGRESS>(
  OLD_SET_PROFILE_PROGRESS + START
);
export const updatePersonalInfo = createAction<UpdatePersonalInfoPayload>(
  OLD_UPDATE_PERSONAL_INFO + START
);
export const updatePersonalInfoSuccess = createAction<UpdatePersonalInfoPayload>(
  OLD_UPDATE_PERSONAL_INFO + SUCCESS
);
export const inviteSpouse = createAction(OLD_INVITE_SPOUSE + START);
export const fetchSchools = createAction(OLD_FETCH_SCHOOLS + START);
export const fetchLeveledSchools = createAction<number>(
  OLD_FETCH_LEVELED_SCHOOLS + START
);
export const fetchSpecialties = createAction<number>(
  OLD_FETCH_SPECIALTIES + START
);
export const fetchFields = createAction(OLD_FETCH_FIELDS + START);
export const fetchProfessions = createAction(OLD_FETCH_PROFESSIONS + START);
export const fetchEmployments = createAction(OLD_FETCH_EMPLOYMENTS + START);
export const fetchFellowships = createAction(OLD_FETCH_FELLOWSHIPS + START);
export const fetchDesignations = createAction(OLD_FETCH_DESIGNATIONS + START);

export const updateEducation = createAction<UpdateEducationPayload>(
  OLD_UPDATE_EDUCATION + START
);
export const updateCareer = createAction<UpdateCareerPayload>(
  OLD_UPDATE_CAREER + START
);
export const updateOther = createAction<UpdateOtherHumanCapitalPayload>(
  OLD_UPDATE_OTHER_HUMAN_CAPITAL + START
);
export const updateDebts = createAction<UpdateDebtsPayload>(
  OLD_UPDATE_DEBTS + START
);
