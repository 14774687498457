import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { pick, rangeRight } from "lodash";

import { ViewComponent } from "src/interfaces/viewComponent.interface";
import { getScore, setRefreshNeeded } from "src/store/dashboard/actions";
import {
  MONTHS,
  PROFILE_BUILD_STEPS,
  SCHOOL_LEVEL,
  PROFILE_TYPE,
} from "src/store/profileBuild/constants";
import {
  fetchFields,
  fetchSchoolsByLevel,
  setProfileStep,
  updateEducation,
  UpdateEducationPayload,
} from "src/store/profileBuild/actions";
import { EducationSection } from "src/interfaces";
import { BLANK_PROFILE } from "src/store/profileBuild/reducers";
import {
  getCurrentStep,
  getEducation,
  getFields,
  getSchoolsByLevel,
  getProfileLoading,
  getSpouseEducation,
} from "src/store/profileBuild/selector";
import { getIsMarried, spouseSelector } from "src/store/system/selector";
import { EDUCATION_KEYS } from "src/store/profileBuild/selectorKeys";

// EDUCATION 2.0 IMPORTS

import FormError from "src/components/Global/FormError";
import {
  EuiFormRow,
  EuiButton,
  EuiFormControlLayout,
  EuiButtonEmpty,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiRadioGroup,
  EuiSuperSelect,
  EuiComboBox,
} from "@elastic/eui";
import { Col } from "react-bootstrap";
import {
  StyledBsAsterisk,
  StyledSpacer,
  StyledSpan,
  StyledEuiButton,
  StyledEuiLink,
  StyledEuiHorizontalRule,
  LabelAsterisk,
  LabelStyle,
  StyledFormRow,
  StyledEuiButtonEmpty,
} from "src/components/Global/StyledComponents";
import styled from "@emotion/styled";
import { YES_NO_OPTIONS } from "src/constants/formOptions";
import { MainContainer } from "../../components";

const StyledDualSelect = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  @media (max-width: 576px) {
    gap: 12px;
  }
`;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const years = rangeRight(1950, new Date().getFullYear() + 7);

interface Degree {
  level: string;
  field: number;
  month: string;
  year: string;
  schoolId?: number;
}

const PROFESSIONAL_ADIDS = [
  7, // Dental
  11, // Medical
  12, // OTD
  23, // DPT
];

const MASTERS_FIELDS = [
  {
    id: 3,
    name: "Business (MBA)",
  },
  {
    id: 5,
    name: "Engineering",
  },
  {
    id: 9,
    name: "Nursing",
  },
  {
    id: 13,
    name: "Occupational Therapy",
  },
  {
    id: 18,
    name: "Physician’s Assistant",
  },
];

const PROFESSIONAL_FIELDS = [
  {
    id: 7,
    name: "Medical Doctor (MD/DO)",
  },
  {
    id: 11,
    name: "Physical Therapy (DPT)",
  },
  {
    id: 12,
    name: "Dental (DDS/DDM)",
  },
  {
    id: 23,
    name: "Occupational Therapy (OTD)",
  },
];

// const PROFESSIONAL_ADIDS = [...MASTERS_PROFESSIONAL_ADIDS, ...DOCTOR_PROFESSIONAL_ADIDS];

const LEVEL_MAPPING: any = {
  a: SCHOOL_LEVEL.ASSOCIATES,
  b: SCHOOL_LEVEL.UNDERGRAD,
  m: SCHOOL_LEVEL.ADV,
  p: SCHOOL_LEVEL.ADV,
  phd: SCHOOL_LEVEL.PHD,
};

const EducationForm: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const isMine: boolean =
    useSelector(getCurrentStep) === PROFILE_BUILD_STEPS.MY_EDUCATION;
  const education = useSelector(isMine ? getEducation : getSpouseEducation);
  const isMarried = useSelector(getIsMarried);
  const spouse = useSelector(spouseSelector);
  const loading = useSelector(getProfileLoading);
  const spouseFirstName = spouse?.first_name || "";
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const schoolsByLevel = useSelector(getSchoolsByLevel);
  const fields = useSelector(getFields);
  const [degree, setDegree] = useState<string>("");
  const [degrees, setDegrees] = useState<Degree[]>([
    {
      level: "",
      field: 0,
      month: "",
      year: "",
      schoolId: 0,
    },
  ]);
  const [errors, setErrors] = useState<any>({});

  const addDegree = () => {
    setDegrees((prevDegrees) => [
      ...prevDegrees,
      {
        level: "",
        field: 0,
        month: "",
        year: "",
        schoolId: 0,
      },
    ]);
  };

  const deleteDegree = (index: number) => {
    const newDegrees = [...degrees];
    newDegrees.splice(index, 1);
    setDegrees(newDegrees);
  };

  const getEducationLevel = (education: EducationSection) => {
    if (education.phd_deg === "y") {
      return 0;
    }
    if (education.adv_deg === "y") {
      return 1;
    }
    if (education.undergrad === "y") {
      return 4;
    }
    if (education.aa_deg === "y") {
      return 7;
    }
    return 8;
  };

  const loadSchools = (level: string) => {
    if (level === "phd" && !schoolsByLevel[SCHOOL_LEVEL.PHD]) {
      dispatch(fetchSchoolsByLevel(SCHOOL_LEVEL.PHD));
    } else if (
      (level === "m" || level === "p") &&
      !schoolsByLevel[SCHOOL_LEVEL.ADV]
    ) {
      dispatch(fetchSchoolsByLevel(SCHOOL_LEVEL.ADV));
    } else if (level === "b" && !schoolsByLevel[SCHOOL_LEVEL.UNDERGRAD]) {
      dispatch(fetchSchoolsByLevel(SCHOOL_LEVEL.UNDERGRAD));
    } else if (!schoolsByLevel[SCHOOL_LEVEL.ASSOCIATES]) {
      dispatch(fetchSchoolsByLevel(SCHOOL_LEVEL.ASSOCIATES));
    }
    if (!fields || !fields.length) {
      dispatch(fetchFields());
    }
  };

  useEffect(() => {
    const educationLevel = getEducationLevel(education);
    if (educationLevel <= 7) {
      setDegree("y");
    } else {
      setDegree("n");
    }
    const newDegrees: Degree[] = [];
    if (education.phd_deg === "y") {
      newDegrees.push({
        level: "phd",
        field: education.phd_field || 0,
        year: "" + education.phd_grad_year || "",
        month: MONTHS[+(education.phd_grad_month || 1) - 1],
        schoolId: education.phd_school,
      });
      loadSchools("phd");
    }
    if (education.adv_deg === "y") {
      const adid = education.adid || 0;
      newDegrees.push({
        level: PROFESSIONAL_ADIDS.includes(adid) ? "p" : "m",
        field: education.adid || 0,
        year: "" + education.date_last_school_year || "",
        month: MONTHS[+(education.date_last_school_month || 1) - 1],
        schoolId: education.adv_school,
      });
      loadSchools("m");
    }
    if (education.undergrad === "y") {
      newDegrees.push({
        level: "b",
        field: education.undergrad_field || 0,
        year: "" + education.undergrad_grad_year || "",
        month: MONTHS[+(education.undergrad_grad_month || 1) - 1],
        schoolId: education.undergrad_school,
      });
      loadSchools("b");
    }
    if (education.aa_deg === "y") {
      newDegrees.push({
        level: "a",
        field: education.aa_field || 0,
        year: "" + education.aa_grad_year || "",
        month: MONTHS[+(education.aa_grad_month || 1) - 1],
        schoolId: education.aa_school,
      });
      loadSchools("a");
    }
    setDegrees(newDegrees);
  }, [education]);

  const handleFieldChange = (
    index: number,
    field: keyof Degree,
    value: string | number | { label: string; key: string }[]
  ) => {
    if (field === "level") {
      loadSchools(value as string);
    }
    setErrors((current: any) => ({
      ...current,
      [`degrees.${index}.${field}`]: false,
    }));
    setDegrees((prevDegrees) => {
      const newDegrees = [...prevDegrees];
      newDegrees[index] = {
        ...newDegrees[index],
        [field]: value,
      };
      return newDegrees;
    });
  };

  const save = (proceed: boolean) => {
    const update = pick(BLANK_PROFILE, EDUCATION_KEYS);
    let validationFailed = false;
    if (degree === "y") {
      const newErrors: any = { ...errors };
      for (let i = 0; i < degrees.length; i++) {
        const degree = degrees[i];
        let hasProfessionalDegree = false;
        if (!degree.month) {
          newErrors[`degrees.${i}.month`] = true;
          validationFailed = true;
        }
        if (!degree.year) {
          newErrors[`degrees.${i}.year`] = true;
          validationFailed = true;
        }
        if (!degree.field) {
          newErrors[`degrees.${i}.field`] = true;
          validationFailed = true;
        }
        switch (degree.level) {
          case "a":
            update.aa_deg = "y";
            update.aa_grad_month = MONTHS.indexOf(degree.month) + 1;
            update.aa_grad_year = +degree.year;
            update.aa_school = degree.schoolId;
            update.aa_field = +degree.field;
            break;
          case "b":
            update.undergrad = "y";
            update.undergrad_grad_month = MONTHS.indexOf(degree.month) + 1;
            update.undergrad_grad_year = +degree.year;
            update.undergrad_school = degree.schoolId;
            update.undergrad_field = +degree.field;
            break;
          case "m":
            if (!hasProfessionalDegree) {
              update.adv_deg = "y";
              update.date_last_school_month = MONTHS.indexOf(degree.month) + 1;
              update.date_last_school_year = +degree.year;
              update.adv_school = degree.schoolId;
              update.adid = +degree.field;
            }
            break;
          case "p":
            hasProfessionalDegree = true;
            update.adv_deg = "y";
            update.date_last_school_month = MONTHS.indexOf(degree.month) + 1;
            update.date_last_school_year = +degree.year;
            update.adv_school = degree.schoolId;
            update.adid = +degree.field;
            break;
          case "phd":
            update.phd_deg = "y";
            update.phd_grad_month = MONTHS.indexOf(degree.month) + 1;
            update.phd_grad_year = +degree.year;
            update.phd_school = degree.schoolId;
            update.phd_field = +degree.field;
            break;
          default:
            break;
        }
      }
      setErrors(newErrors);
    }
    if (!validationFailed) {
      const payload: UpdateEducationPayload = {
        who: isMine ? PROFILE_TYPE.APPLICANT : PROFILE_TYPE.SPOUSE,
        update,
      };
      if (proceed) {
        payload.nextPage =
          isMarried && isMine
            ? PROFILE_BUILD_STEPS.SPOUSE_EDUCATION
            : PROFILE_BUILD_STEPS.INCOME_EXPENSES_INTRO;
      }
      dispatch(updateEducation(payload));
    }
  };

  const onPrev = () => {
    save(false);
    dispatch(
      setProfileStep(
        isMarried && !isMine
          ? PROFILE_BUILD_STEPS.MY_EDUCATION
          : PROFILE_BUILD_STEPS.PERSONAL_INFO
      )
    );
  };

  const onNext = () => {
    save(true);
    window.setTimeout(() => {
      dispatch(getScore());
      dispatch(setRefreshNeeded({ summaries: true }));
    }, 500);
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  };

  const saveAndExit = () => {
    save(false);
    dispatch(push("/profile-builder-continue"));
  };

  if (!render) {
    return <div />;
  }

  const allDegreeOptions = [
    {
      value: "a",
      inputDisplay: "Associate's Degree",
    },
    {
      value: "b",
      inputDisplay: "Bachelor's Degree",
    },
    {
      value: "m",
      inputDisplay: "Master's Degree",
    },
    {
      value: "p",
      inputDisplay: "Professional Doctorate",
    },
    {
      value: "phd",
      inputDisplay: "Ph.D",
    },
  ];

  const optionsForGivenDegree = (degree: Degree) =>
    allDegreeOptions.filter(
      (item) =>
        item.value === degree.level ||
        !degrees.find((otherDegree) => otherDegree.level === item.value)
    );

  const renderSchoolSelection = (degree: Degree, index: number) => {
    let selectedOption: any[] = [];
    const availableOptions =
      schoolsByLevel[LEVEL_MAPPING[degree.level]]?.map(
        (school: { name: string; id: number }) => {
          const value = {
            label: school.name,
            key: String(school.id),
          };
          if (degree.schoolId === school.id) {
            selectedOption = [value];
          }
          return value;
        }
      ) || [];

    return (
      <EuiComboBox
        rowHeight={40}
        isClearable={false}
        options={availableOptions}
        selectedOptions={selectedOption}
        onChange={(options) => {
          if (options.length > 0) {
            const selectedSchool = schoolsByLevel[
              LEVEL_MAPPING[degree.level]
            ]?.find(
              (school: { name: string; id: number }) =>
                school.name === options[0].label
            );
            if (selectedSchool) {
              handleFieldChange(index, "schoolId", selectedSchool.id);
            }
          } else {
            // Clear the selection if the options array is empty
            handleFieldChange(index, "schoolId", 0);
          }
        }}
        singleSelection={{ asPlainText: true }}
      />
    );
  };

  const renderFieldSelection = (degree: Degree, index: number) => {
    let availableFields: any[] = fields;
    if (degree.level === "m") {
      availableFields = MASTERS_FIELDS;
    } else if (degree.level === "p") {
      availableFields = PROFESSIONAL_FIELDS;
    }
    let selectedOption: any[] = [];
    const displayOptions = availableFields.map((field) => {
      const value = {
        label: field.name,
        key: field.id,
      };
      if (degree.field === field.id) {
        selectedOption = [value];
      }
      return value;
    });
    return (
      <EuiFormControlLayout>
        <EuiComboBox
          singleSelection={{ asPlainText: true }}
          rowHeight={40}
          isClearable={false}
          options={displayOptions}
          selectedOptions={selectedOption}
          isInvalid={errors[`degrees.${index}.field`]}
          onChange={(options) => {
            if (options.length > 0) {
              handleFieldChange(index, "field", options[0].key || 0);
            } else {
              // Clear the selection if the options array is empty
              handleFieldChange(index, "field", "");
            }
          }}
        />
      </EuiFormControlLayout>
    );
  };

  return render({
    component: (
      <MainContainer fluid ref={mainContainerRef}>
        <StyledFormRow>
          <Col lg={{ span: 5, offset: 2 }}>
            <EuiButtonEmpty
              color="text"
              flush="left"
              iconType="arrowLeft"
              onClick={onPrev}
            >
              Back
            </EuiButtonEmpty>
            <StyledSpacer size="32px" />
            <EuiTitle size="l" className="header-font">
              <h1>
                {isMine ? "Your education" : `${spouseFirstName}'s education`}
              </h1>
            </EuiTitle>
            <EuiSpacer size="l" />
            <EuiText size="m">
              <p>
                {isMine ? "Your" : `${spouseFirstName}'s`} education reduces the
                risk to {isMine ? "your" : "their"} income. The risk to{" "}
                {isMine ? "your" : "their"} income affects our recommendations
                for student loan repayment plans, investments, and more.
              </p>
            </EuiText>
            <StyledSpacer size="36px" />
            <EuiFormRow
              label={
                <LabelStyle>
                  {isMine ? "Have you" : `Has ${spouseFirstName}`} completed or
                  {isMine ? " are you" : ` is ${spouseFirstName}`} pursuing a
                  degree?
                  <LabelAsterisk />
                </LabelStyle>
              }
              className="input-size"
            >
              <EuiFormControlLayout>
                <EuiRadioGroup
                  name="degree"
                  options={YES_NO_OPTIONS}
                  idSelected={degree}
                  onChange={(value) => setDegree(value)}
                />
              </EuiFormControlLayout>
            </EuiFormRow>
            {degree === "" && <FormError>This field is required.</FormError>}
            {degree === "y" && (
              <>
                {degrees.map((degree, index) => (
                  <div key={index}>
                    <StyledSpacer size="32px" />
                    <EuiFormRow
                      label={
                        <StyledSpan>
                          Degree
                          <StyledBsAsterisk />
                        </StyledSpan>
                      }
                      className="input-size"
                    >
                      <EuiSuperSelect
                        options={optionsForGivenDegree(degree)}
                        valueOfSelected={degree.level}
                        onChange={(value) => {
                          handleFieldChange(index, "level", value);
                        }}
                        isInvalid={degree.level === ""}
                      />
                    </EuiFormRow>
                    {degree.level === "" && (
                      <FormError>This field is required</FormError>
                    )}
                    <StyledSpacer size="32px" />
                    <EuiFormRow
                      label={<StyledSpan>College/University</StyledSpan>}
                      className="input-size"
                    >
                      {renderSchoolSelection(degree, index)}
                    </EuiFormRow>
                    <StyledSpacer size="32px" />
                    <EuiFormRow
                      label={
                        <StyledSpan>
                          Field of Study
                          <StyledBsAsterisk />
                        </StyledSpan>
                      }
                      className="input-size"
                      isInvalid={errors[`degrees.${index}.field`]}
                    >
                      {renderFieldSelection(degree, index)}
                    </EuiFormRow>
                    {errors[`degrees.${index}.field`] && (
                      <FormError>This field is required</FormError>
                    )}
                    <StyledSpacer size="32px" />
                    <EuiFormRow
                      label={
                        <LabelStyle>
                          Graduation Date (or Expected)
                          <LabelAsterisk />
                        </LabelStyle>
                      }
                    >
                      <StyledDualSelect>
                        <StyledDiv className="inputWithError">
                          <EuiFormControlLayout className="input-size-small">
                            <EuiComboBox
                              isInvalid={errors[`degrees.${index}.month`]}
                              rowHeight={40}
                              isClearable={false}
                              options={MONTHS.map((month) => ({
                                label: month,
                              }))}
                              selectedOptions={
                                degree.month ? [{ label: degree.month }] : []
                              }
                              onChange={(options) => {
                                if (options.length > 0) {
                                  handleFieldChange(
                                    index,
                                    "month",
                                    options[0].label
                                  );
                                } else {
                                  // Clear the selection if the options array is empty
                                  handleFieldChange(index, "month", "");
                                }
                              }}
                              singleSelection={{ asPlainText: true }}
                            />
                          </EuiFormControlLayout>
                        </StyledDiv>
                        <StyledDiv className="inputWithError">
                          <EuiFormControlLayout className="input-size-small">
                            <EuiComboBox
                              isInvalid={errors[`degrees.${index}.year`]}
                              rowHeight={40}
                              isClearable={false}
                              options={years.map((year) => ({
                                label: year.toString(),
                              }))}
                              selectedOptions={
                                degree.year ? [{ label: degree.year }] : []
                              }
                              onChange={(options) => {
                                if (options.length > 0) {
                                  handleFieldChange(
                                    index,
                                    "year",
                                    options[0].label
                                  );
                                } else {
                                  // Clear the selection if the options array is empty
                                  handleFieldChange(index, "year", "");
                                }
                              }}
                              singleSelection={{ asPlainText: true }}
                            />
                          </EuiFormControlLayout>
                        </StyledDiv>
                      </StyledDualSelect>
                    </EuiFormRow>
                    {(errors[`degrees.${index}.month`] ||
                      errors[`degrees.${index}.year`]) && (
                      <FormError>This field is required</FormError>
                    )}
                    <StyledSpacer size="32px" />
                    <StyledEuiLink
                      onClick={() => deleteDegree(index)}
                      color="danger"
                    >
                      Delete
                    </StyledEuiLink>
                    <StyledSpacer size="24px" />

                    <StyledEuiHorizontalRule maxWidth="428px" />
                  </div>
                ))}
                <StyledSpacer size="32px" />
                <StyledEuiButton
                  color="text"
                  iconType="plus"
                  onClick={addDegree}
                >
                  Add degree
                </StyledEuiButton>
              </>
            )}
            <StyledSpacer size="48px" />
            <EuiButton
              type="submit"
              color="primary"
              className="btn-text"
              fill
              onClick={onNext}
              disabled={Object.values(errors).some((error) => error)}
            >
              Next
            </EuiButton>
            <StyledEuiButtonEmpty
              color="text"
              className="btn-text"
              onClick={saveAndExit}
            >
              Save and Exit
            </StyledEuiButtonEmpty>
          </Col>
        </StyledFormRow>
      </MainContainer>
    ),
    nextDisabled: loading.profile,
  });
};

export default EducationForm;
