import axiosClient from "../axiosClient";
import makeAuthenticatedRequest from "../authenticatedRequest";

export const getAccountMetadataApi = (who: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`/v2/account/meta/${who}`);
    return data;
  });

export const getDimTokenApi = (who: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`/v2/account/dim_token/${who}`);
    return data;
  });
