import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";

import PlanBuilderHeader from "../components/Headers/PlanBuilderHeader";
import Help from "../components/Help";
import Popup from "../components/Popup";

import { planBuilderStyles, theme } from "../theme";

const useStyles = makeStyles(planBuilderStyles);

export const PlanBuilderLayout = (props: any) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PlanBuilderHeader />
      <Box className={classes.root}>{props.children || ""}</Box>
      <Help />
      {/* <Tutorial /> */}
      {/* <Video /> */}
      <Popup />
    </ThemeProvider>
  );
};

export default PlanBuilderLayout;
