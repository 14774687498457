import React, { useMemo, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  withStyles,
} from "@material-ui/core";

import Icon from "../Icon";

import {
  accordionDetailStyles,
  accordionSectionStyles,
  accordionContainerStyles,
  accordionSummaryStyles,
} from "../../theme/base";

interface IContentRow {
  text?: React.ReactNode;
  total?: React.ReactNode;
  action?: React.ReactNode;
}

interface IAccordionSection {
  disabled?: boolean;
  title: React.ReactNode;
  titleDetail?: React.ReactNode;
  content?: JSX.Element;
  contentRows?: IContentRow[];
}

interface IFbAccordionProps {
  footer?: IContentRow;
  sections: IAccordionSection[];
}

const useStyles = makeStyles(accordionContainerStyles);

const FbAccordionSection = withStyles(accordionSectionStyles)(Accordion);
const FbAccordionSummary = withStyles(accordionSummaryStyles)(AccordionSummary);
const FbAccordionDetails = withStyles(accordionDetailStyles)(AccordionDetails);

function FbAccordion(props: IFbAccordionProps) {
  const classes: any = useStyles();
  const [expanded, setExpanded] = useState<Set<number>>(new Set());
  const { footer, sections } = props;

  const renderKeys = useMemo(() => sections.map(() => "" + Math.random()), [
    sections.length,
  ]);

  const toggleExpanded = (index: number) => {
    const newExpanded = new Set(expanded);

    if (newExpanded.has(index)) {
      newExpanded.delete(index);
    } else {
      newExpanded.add(index);
    }
    setExpanded(newExpanded);
  };

  return (
    <div className={classes.root}>
      {sections.map((section: IAccordionSection, index: number) => (
        <FbAccordionSection
          key={renderKeys[index]}
          expanded={expanded.has(index)}
          onChange={() => toggleExpanded(index)}
          disabled={section.disabled}
        >
          <FbAccordionSummary
            aria-controls={`${renderKeys[index]}-content`}
            id={`${renderKeys[index]}-header`}
          >
            <div
              className="flex justify-center items-center"
              style={
                section.contentRows || section.content
                  ? {}
                  : { visibility: "hidden" }
              }
            >
              <Icon
                className="mr-2"
                iconName={
                  expanded.has(index) ? "fb-chevron-down" : "fb-chevron-right"
                }
              />
            </div>
            <div className={classes.headerRow}>
              <div>{section.title}</div>
              {!!section.titleDetail && <div>{section.titleDetail}</div>}
            </div>
          </FbAccordionSummary>
          <FbAccordionDetails>
            {section.content || null}
            {!!section.contentRows &&
              section.contentRows.map((row: IContentRow) => (
                <div className={classes.contentRow}>
                  <div className="flex">
                    <div>{row.text || ""}</div>
                    {row.action && (
                      <div className="ml-3 flex items-center">{row.action}</div>
                    )}
                  </div>
                  {!!row.total && <div>{row.total}</div>}
                </div>
              ))}
          </FbAccordionDetails>
        </FbAccordionSection>
      ))}
      {!!footer && (
        <div className={classes.footer}>
          <div>{footer.text || ""}</div>
          {!!footer.total && (
            <div className={classes.footerTotal}>{footer.total}</div>
          )}
        </div>
      )}
    </div>
  );
}

export default FbAccordion;
