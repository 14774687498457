import { SidebarContent } from "src/interfaces";

export const incomeBlowout: SidebarContent[] = [
  {
    header: "Income Should Be Your Focus!",
    body: [
      "Many people stress about cutting cost.  In fact, they spend hours, days, and weeks researching items that may only save them a few dollars a month.",
      "Although, cutting cost is good, spending too much time on it is not.  Instead, that time should be focused on answering the question: How do I grow my income?",
      "Remember this, you can only cut expenses by so much.  However, income can grow infinitely!",
    ],
  },
];

export const expenseBlowout: SidebarContent[] = [
  {
    header: "A Trick To Cut Expenses",
    body: [
      "One big mistake people make is trying to cut all their expenses at one-time, i.e. they try to change their lifestyles overnight. This is why so many people fail financially and stop pursuing a financial plan after six months.",
      "Instead, you should only pick one item on your day-to-day expenses.  For 40 days, do not do that item.  For example, do not watch a movie/Netflix for 40 days.",
      "Most of the time, after the 40 days conclude, you'll realize you can live without the item and won't go back to spending money on it or you'll spend less on it.",
      "Do this exercise over a year and you'll reduce spending on 8 - 10 items! That will make a drastic impact on not only your financials, but also your life.",
    ],
  },
];
