import React from "react";
import { Box, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import TitleCard from "src/components/TitleCard";
import Button from "src/components/Button";
import { LANDING_STEP_HELP } from "../CONSTANT";
import { useRentVsBuy } from "../UseRentVsBuy";

const LandingStep: React.FC = () => {
  const classes = useStyles();
  const { handleNext } = useRentVsBuy();
  return (
    <Box className={classes.root}>
      <TitleCard
        title="Answering A Perennial Question"
        sideContent={LANDING_STEP_HELP}
      >
        <Box className={classes.container}>
          <Typography>
            Based on my current situation, and purely from a financial
            perspective, should I buy or rent my primary residence?
          </Typography>
          <Typography>This is particularly useful if:</Typography>
          <Typography>
            1) You have the means to buy and want to see whether this makes
            financial sense; or
            <br />
            2) You currently don't have the money to buy but your rent is so
            high that buying may still make sense in the long run.
          </Typography>
        </Box>
      </TitleCard>
      <Box className={classes.actionContainer}>
        <Button fbColor="primary" onClick={handleNext}>
          Next
        </Button>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
}));
export default LandingStep;
