import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import {
  setBuildStep,
  setCurrentPlan,
  setFurthestStep,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { getCurrentPlan, getPlans } from "src/store/planBuild/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import { PlanListRecord } from "src/interfaces/plan.interface";
import Radio from "src/components/Radio";

const EnterName: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const existingPlans: PlanListRecord[] = useSelector(getPlans);
  const currentPlan = useSelector(getCurrentPlan);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const [useExisting, setUseExisting] = useState(false);
  const [selectedPlanIndex, setPlan] = useState(0);

  const onNext = () => {
    if (useExisting) {
      const existingPlan = existingPlans[selectedPlanIndex];
      dispatch(
        setCurrentPlan({
          planId: existingPlan.id,
          keepId: true,
          isImplemented: existingPlan.implemented,
        })
      );
      dispatch(setFurthestStep(PLAN_BUILD_STEPS.REVIEW));
    }
    const target = isCurrentStudent
      ? PLAN_BUILD_STEPS.EDUCATION_FUNDING
      : PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW;
    dispatch(setBuildStep(target));
    dispatch(setFurthestStep(target));
  };

  const handleUseExisting = (e: React.ChangeEvent<any>) => {
    if (e.target.value === "n") {
      setUseExisting(false);
    } else {
      setUseExisting(true);
    }
  };

  const handleSelectPlan = (e: React.ChangeEvent<any>) => {
    setPlan(e.target.value);
  };

  return render({
    component: (
      <Paper className="w-full h-full">
        <Container maxWidth="sm">
          <Box className="w-full py-28">
            <Typography variant="h2" component="h2" className="mb-3">
              Reuse selections from other plan?
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <RadioGroup
                  aria-label="reuse_selections"
                  name="reuse_selections"
                  onChange={handleUseExisting}
                  value={useExisting ? "y" : "n"}
                  className="flex flex-row"
                >
                  <FormControlLabel value="n" control={<Radio />} label="No" />
                  <FormControlLabel value="y" control={<Radio />} label="Yes" />
                </RadioGroup>
              </Grid>
              {useExisting && (
                <Grid item xs={12}>
                  <Select
                    aria-label="select_plan"
                    variant="outlined"
                    fullWidth
                    name="select_plan"
                    onChange={handleSelectPlan}
                    value={selectedPlanIndex}
                  >
                    {existingPlans
                      .filter(
                        (plan) =>
                          plan.id !== currentPlan.id &&
                          !plan.questionnaire &&
                          !plan.messages
                      )
                      .map((plan, index) => (
                        <MenuItem key={plan.id} value={index}>
                          {plan.name}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              )}
            </Grid>
          </Box>
        </Container>
      </Paper>
    ),
    onNext,
  });
};

export default EnterName;
