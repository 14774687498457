import React from "react";

import { Grid, IconButton, makeStyles } from "@material-ui/core";

import HeaderText from "src/components/HeaderText";
import Icon from "src/components/Icon";
import colors from "src/theme/colors";
import { AdapterLink } from "src/utils";

const useStyles = makeStyles({
  root: {
    minHeight: "calc(100vh - 70px)",
  },
  policygenius: {
    height: 60,
    marginBottom: 12,
    marginLeft: 8,
  },
  betterment: {
    width: 140,
    marginBottom: 12,
    marginLeft: 8,
  },
  img: {
    width: "400px",
    height: "400px",
    "& img": {
      width: "100%",
      borderRadius: "50%",
    },
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  disclaimer: {
    "& h1": {
      color: colors.brandingBlue1,
      fontSize: 10,
      lineHeight: "160%",
    },
    "& p": {
      color1: colors.blueGray1,
      fontSize: 10,
      lineHeight: "100%",
    },
  },
});

interface InterstitialProps {
  returnTo: string;
  solution: {
    heading?: string;
    headingImage?: string;
    description?: string;
    buttons?: any[];
    img?: string;
    value?: string;
  };
}

export const Interstitial = ({ solution, returnTo }: InterstitialProps) => {
  const styles = useStyles();
  const { heading, headingImage, description, buttons, img } = solution;
  return (
    <Grid item className={`${styles.root} flex justify-between flex-col`}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} className="flex justify-center items-center">
          <HeaderText
            heading={heading}
            headingImage={headingImage}
            imageClassName={
              solution.value === "betterment"
                ? styles.betterment
                : styles.policygenius
            }
            description={description}
            buttons={buttons}
            className={"flex justify-center items-center"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="flex justify-center items-end relative"
        >
          <div className={styles.img}>
            <img src={img} alt="img" />
          </div>
          <IconButton
            className={styles.closeButton}
            component={AdapterLink}
            to={returnTo}
          >
            <Icon iconName="fb-close" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <div className={styles.disclaimer}>
          <h1>Disclaimer</h1>
          <p>
            This is not an offer, or solicitation of any offer to buy or sell
            any security, investment or other product.
          </p>
          <p>
            Advisory services provided by Fitbux RIA LLC and Betterment LLC.
          </p>
          <p>
            Investments in securities: Not FDIC Insured • No Bank Guarantee •
            May Lose Value. Investing in securities involves risks, and there is
            always the potential of losing money when you invest in securities.
            Before investing, consider your investment objectives and Fitbux RIA
            LLC's charges and expenses. Fitbux RIA LLC's internet-based advisory
            services are designed to assist clients in achieving discrete
            financial goals. They are not intended to provide comprehensive tax
            advice or financial planning with respect to every aspect of a
            client's financial situation and is limited to the information
            provided in each client's Fitbux profile build at
            https://www.fitbux.com. Past performance does not guarantee future
            results, and the likelihood of investment outcomes are hypothetical
            in nature.
          </p>
        </div>
      </Grid>
    </Grid>
  );
};

export default Interstitial;
