import React from "react";

import { Typography } from "@material-ui/core";

import Button from "src/components/Button";

const data = {
  answers: {
    heading: "Upgrade to Premium and find out. Powered by FitBUX's A.I.",
    subscribedHeading: "Get personalized answers (powered by FitBUX A.I.).",
    items: [],
    showSubscribeButton: true,
  },
  calculators: {
    heading: 'An easy way to "do the math" and compare strategies.',
    items: [],
  },
  products: {
    heading: "Products that help you analyze and implement your plan.",
    items: [
      {
        iconName: "fa-arrows-rotate",
        target: "/credit-card-consolidation",
        label: "Credit Card Consolidation",
      },
      {
        iconName: "fa-hand-holding-dollar",
        dialog: {
          title: "Doc2Doc",
          content: (
            <>
              <Typography paragraph>
                Doc2Doc is a personal loan lender that specializes in Doctors
                and Dentists.
              </Typography>
              <Typography paragraph>
                They have a deep understanding of these professions so they can
                get you a loan with great rates relative to other personal loan
                companies!
              </Typography>
              <Button
                variant="contained"
                fbColor="primary"
                onClick={() =>
                  window.open("http://www.doc2doclending.com/fitbux")
                }
                className="mx-auto block mt-6"
              >
                Go To Doc2Doc
              </Button>
            </>
          ),
        },
        label: "Personal Loans For Doctors and Dentists",
      },
    ],
  },
};

export default data;
