import React, { useState } from "react";

import clsx from "clsx";
import {
  CssBaseline,
  Hidden,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";

import DashboardHeader from "../components/Headers/DashboardHeader";
import Navigator from "../components/Navigator";
import Help from "../components/Help";
import Tutorial from "../components/Tutorial";
import Video from "../components/Video";

import {
  drawerWidth,
  collapsedDrawerWidth,
  adminStyles,
  theme,
} from "../theme";
import { AppConfig } from "../config";

const useStyles = makeStyles(adminStyles);

const DashboardLayout = (props: any) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const toggleMobileOpen = () => setMobileOpen((current) => !current);
  const closeMobileOpen = () => setMobileOpen(false);
  const toggleCollapsed = () => setCollapsed((current) => !current);
  const { children, pageTitle } = props;

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <nav
          className={clsx(classes.drawer, collapsed && classes.collapsedDrawer)}
        >
          <Hidden smUp implementation="js">
            <Navigator
              PaperProps={{
                style: {
                  width: collapsed ? collapsedDrawerWidth : drawerWidth,
                },
              }}
              variant="temporary"
              open={mobileOpen}
              collapsed={collapsed}
              onClose={toggleMobileOpen}
              title={AppConfig.title}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator
              PaperProps={{
                style: {
                  width: collapsed ? collapsedDrawerWidth : drawerWidth,
                },
              }}
              onCollapse={toggleCollapsed}
              closeMobileOpen={closeMobileOpen}
              open={mobileOpen}
              collapsed={collapsed}
              variant="permanent"
              title={AppConfig.title}
            />
          </Hidden>
        </nav>
        <div className={classes.appContent}>
          <DashboardHeader
            onDrawerToggle={toggleMobileOpen}
            title={pageTitle}
          />
          {children}
          <Help />
        </div>
        <Tutorial />
        <Video />
      </div>
    </ThemeProvider>
  );
};

export default DashboardLayout;
