import React from "react";
import { useSelector } from "react-redux";

import { Box, Grid, makeStyles, Typography } from "@material-ui/core";

import {
  getLifeEventProjections,
  getCurrentPlan,
  getEmergencyMonthlyExpenses,
  getCurrentPlanMessages,
} from "src/store/planBuild/selector";
import {
  getUserInSchool,
  getSpouseInSchool,
} from "src/store/profileBuild/selector";
import { GOALS } from "src/interfaces";
import OverviewTable from "../components/OverviewTable";
import CenterContainer from "src/pages/PlanBuilder/Components/CenterContainer";
import { formatWholeDollars } from "src/utils";
import { getReviewMessage } from "src/constants/planReviewMessages";
import LinkedText from "src/components/TextWithLink";

const reformatDateString = (dateString: string) => {
  if (!dateString) {
    return dateString;
  }
  const month = dateString.slice(5, 7);
  const year = dateString.slice(2, 4);
  return `${month}/${year}`;
};

const GoalSummary = () => {
  const styles = useStyles();
  const userInSchool = useSelector(getUserInSchool);
  const spouseInSchool = useSelector(getSpouseInSchool);
  const plan = useSelector(getCurrentPlan);
  const messages = useSelector(getCurrentPlanMessages);
  const planEvents: any[] = plan.lifeevents;
  const monthlyExpenses = useSelector(getEmergencyMonthlyExpenses);
  const months = plan.emergencymonths;

  const events = useSelector(getLifeEventProjections);

  // goal summary based on user provided life event data
  const eventGoals = events.map((event) => {
    // initial a goal to be displayed
    let goal = 0;
    let date = "";

    // check if the user input a life event for this goal
    const eventData = planEvents.find(
      (e) =>
        e.eventtype === event.type &&
        Date.parse(e.date.slice(0, 7)) >= Date.parse(event.plan)
    );
    // remove the life event so that the same data isn't displayed twice
    // planEvents.splice(planEvents.indexOf(eventData), 0);

    // if the event is an emergencyfund then set the goal to be based on montly expenses
    // else set the goal to be based on what the user input into the plan
    if (event.type === "emergencyfund") {
      goal = monthlyExpenses * months;
    } else if (eventData) {
      goal = eventData.down ? eventData.down : eventData.cost || 0;
    }
    date = eventData && eventData.date ? eventData.date : "";

    return {
      ...event,
      goal,
      date,
    };
  });

  const renderPlanMessages = () =>
    messages.map((entry) => {
      const planMessage = getReviewMessage(entry, "goal");
      if (planMessage.text && !planMessage.links) {
        return (
          <Typography
            style={{
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            {planMessage.text}
          </Typography>
        );
      } else if (planMessage.text && planMessage.links) {
        return (
          <Typography
            style={{
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <LinkedText
              text={planMessage.text}
              links={planMessage.links}
              indicator="(Click here to do so)"
            />
          </Typography>
        );
      } else {
        return <div />;
      }
    });

  const boxStyle = plan.messages ? "messagesPresent" : "noMessages";

  return (
    <CenterContainer review title="2. Goal Summary" scrollable={!plan.messages}>
      <Box className="flex justify-center mt-5">
        {!!messages.length && (
          <Grid xs={6} className={styles[boxStyle]}>
            {/* <Box className="pr-2"> */}
            {renderPlanMessages()}
            {/* </Box> */}
          </Grid>
        )}

        <Box className={styles[boxStyle]}>
          <Box>
            <Typography style={{ fontSize: "14px" }}>
              Below are your goal timeline details.
            </Typography>
          </Box>
          <Box className={styles.container}>
            <OverviewTable
              noGrid
              title=""
              rowHeader="Life Events/Goals:"
              columns={[
                "Estimated Date Of Completion",
                // "Target Date Of Completion",
                "Goal",
              ]}
              rows={eventGoals
                .filter((event) => {
                  if (event.type === "graduate" || event.type === "repayment") {
                    if (
                      (event.who === "spouse" && !spouseInSchool) ||
                      (event.who !== "spouse" && !userInSchool)
                    ) {
                      return false;
                    }
                  }
                  return true;
                })
                .map((event) => {
                  let typeLabel =
                    GOALS[event.type as keyof typeof GOALS] || "Goal";
                  if (
                    (event.type === "student_loan" || event.type === "idr") &&
                    event.who === "spouse"
                  ) {
                    typeLabel += ` (spouse)`;
                  }

                  return {
                    header: typeLabel,
                    columns: [
                      // event.min ? reformatDateString(event.min) : "",
                      reformatDateString(event.plan),
                      // reformatDateString(event.date),
                      event.goal ? formatWholeDollars(event.goal) : "",
                    ],
                  };
                })}
            />
          </Box>
        </Box>
      </Box>
    </CenterContainer>
  );
};

export default GoalSummary;

const useStyles = makeStyles({
  container: {
    // width: "700px",
    margin: "0 auto",
  },
  messagesPresent: {
    margin: "1rem 0px",
    height: "calc(100vh - 300px)",
    width: "50%",
    padding: "0px 1.5rem 0 .5rem",
    overflowY: "auto",
    overflowX: "hidden",
  },
  noMessages: {
    margin: "2rem 10px",
    height: "calc(100vh - 300px)",
  },
});
