import { getDimTokenApi } from "src/apiService/modules/spinwheel";
export const SPINWHEEL_CONTAINER_ID = "spinwheel";

export const openSpinwheelComponent = ({
  onExit,
  onLoad,
  onEvent,
  onSuccess,
  module,
  who,
  ...extraOptions
}: any) => {
  getDimTokenApi(who).then((tokenResult) => {
    const handler = (window as any)["Spinwheel"].create({
      containerId: SPINWHEEL_CONTAINER_ID,
      onSuccess,
      onLoad,
      onExit,
      onEvent,
      dropinConfig: {
        module,
        token: tokenResult?.data?.token || "",
        authSuccessIconUrl: "",
        authSuccessMobileBtnStack: "0",
        authSuccessMobileBtnStackAlign: "center",
        authSuccessMsg: "",
        authSuccessMsgBold: "0",
        championName: "",
        closeDimOnComingSoonPage: "0",
        connectLoanBtnLabel: "Try Another loan",
        connectLoanDetailsHeader: "Connect Loan",
        connectLoanDetailsSubTitle:
          "Connect with your student loan servicer to securely sync your loan data.",
        connectLogoAlignment: "center",
        connectNewLoanBtnText: "",
        connenctDetailsExitDimNavig: "0",
        datePickerFormat: "MM-dd-yyyy",
        disableAddAnotherAccount: "0",
        footer: 0,
        header: 0,
        hideAutoDebitNavigation: 1,
        hideBackNav: "0",
        hideBenefitsCard: "0",
        hideComingSoonBackNav: "0",
        hideConnectLoanDetailsSubTitle: "0",
        hideConnectLogoLabel: "0",
        hideSecureConnectCard: "0",
        landingBtnNavPath: "",
        landingUrl: "",
        noServicerSubtitle: "",
        loanInfoConnecButtontLabel: "Connect Loan",
        materialInputStyle: "0",
        maxPaymentAmount: 50000.0,
        mobileHeaderCaption: "",
        mode: "oneTime",
        noServicerIconUrl: "",
        noServicerSubBtnText: "Continue without Connecting",
        noServicerTitle: "",
        platformBenefitText1: "",
        platformBenefitText2: "",
        platformBenefitText3: "",
        searchLabelText: "",
        servicerListHeaderPadding: "0",
        servicerListSearchIcon: "",
        servicerNotFoundText: "Don't see your loan servicer?",
        showConnectLoanText: "false",
        showExitNavigation: "1",
        showHeader: "false",
        ...extraOptions,
      },
    });

    handler.open();
  });
};
