import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";

import { getCouponsApi, getMoneySchoolPlansApi } from "src/apiService";

import { Coupon, SubscriptionPayload } from "src/interfaces";
import {
  selectPurchasePlans,
  selectStripeLoading,
} from "src/store/stripe/selector";
import {
  createSubscription,
  getAllPurchaseData,
} from "src/store/stripe/actions";
import {
  getIsCurrentStudent,
  getIsSubscribed,
} from "src/store/system/selector";
import { TapConverted } from "src/tapfiliate";

import { implementPlan } from "src/store/planBuild/actions";
import { logOut } from "src/store/system/actions";
import { getPopupPlan } from "src/store/dashboard/selector";

import {
  EuiTitle,
  EuiText,
  EuiFieldText,
  EuiButton,
  EuiFormRow,
  EuiSuperSelect,
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiCheckableCard,
  EuiCallOut,
  EuiHorizontalRule,
  EuiButtonIcon,
} from "@elastic/eui";
import {
  StyledSpacer,
  StyledSpan,
  StyledEuiButton,
  StyledEuiLink,
} from "src/components/Global/StyledComponents";
import FormError from "src/components/Global/FormError";
import styled from "@emotion/styled";
import useWindowSize from "src/hooks/useWindowSize";

export const PromoEuiButtonIcon = styled(EuiButtonIcon)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const PromoEuiCallOut = styled(EuiCallOut)`
  .euiSpacer {
    display: none;
  }
`;

export const CardEuiFlexGroup = styled(EuiFlexGroup)`
  @media (max-width: 576px) {
    gap: 16px;
  }
`;

interface IFormValues extends SubscriptionPayload {
  association?: string;
  name?: string;
}

const StripeForm = ({
  moneySchool,
  onClose,
}: {
  moneySchool?: boolean;
  onClose?: VoidFunction;
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const loading = useSelector(selectStripeLoading);
  const viewportWidth = useViewportWidth();
  const plans = useSelector(selectPurchasePlans) || [];
  const isSubscribed = useSelector(getIsSubscribed);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const windowSize = useWindowSize();

  const APTA_PROMO_CODE = isCurrentStudent ? "APTASTUDENT" : "APTA";

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [validPromo, setValidPromo] = useState<boolean | undefined>();
  const [promoVisible, setPromoVisible] = useState(false);
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [cardError, setCardError] = useState<StripeError>();
  const [nameError, setNameError] = useState<string | null>(null);
  const [values, setValues] = useState<IFormValues>({});
  const [moneySchoolPlans, setMoneySchoolPlans] = useState([]);
  const [promoType, setPromoType] = useState("");
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const [acceptedPolicyError, setAcceptedPolicyError] = useState("");
  const [promoInput, setPromoInput] = useState("");
  const selectedPlan = plans.find((plan) => plan.id === values.plan);
  const percentOff = coupons?.[0]?.percent_off;
  const amountOff = coupons?.[0]?.amount;
  const codeDuration = coupons?.[0]?.duration_in_months;
  const originalPrice = selectedPlan?.amount || 0;
  const applyPromo = () => handleApplyPromo(promoInput);
  let discountedPrice;
  let couponAmount;

  if (percentOff) {
    discountedPrice = (originalPrice * ((100 - percentOff) / 100)).toFixed(2);
    couponAmount = (originalPrice - parseFloat(discountedPrice)).toFixed(2);
  } else if (amountOff) {
    discountedPrice = (originalPrice - amountOff).toFixed(2);
    couponAmount = amountOff;
  } else {
    discountedPrice = originalPrice.toFixed(2);
    couponAmount = "0.00";
  }

  const popupPlan: number | null = useSelector(getPopupPlan);

  function useViewportWidth() {
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setViewportWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return viewportWidth;
  }

  useEffect(() => {
    if (onClose && isSubscribed) {
      if (popupPlan) {
        // dispatch(setTutorial("post_subscription"));
        // dispatch(setVideo("post_subscription"));
        dispatch(implementPlan(popupPlan));
      }
      onClose();
    }
  }, [onClose, isSubscribed]);

  useEffect(() => {
    if (loading) return;
    if (!moneySchool) {
      if (!plans || !plans.length) {
        dispatch(getAllPurchaseData());
      } else {
        const monthlyPlan = plans.find((plan) => plan.name === "Monthly");
        if (monthlyPlan && !values.plan) {
          setValues({
            ...values,
            plan: monthlyPlan.id,
          });
        }
      }
    }
    if (!values.promo) {
      setValidPromo(undefined);
    } else {
      setValidPromo(coupons && coupons.length ? true : false);
    }
  }, [loading, dispatch, moneySchool]);

  useEffect(() => {
    if (moneySchool && !moneySchoolPlans.length) {
      getMoneySchoolPlansApi().then((results) => {
        if (results && results.length) {
          setMoneySchoolPlans(results);
          setValues((v) => ({ ...v, plan: results[0].id }));
        }
      });
    }
  }, [moneySchool, moneySchoolPlans.length]);

  const MAX_DELAY_LIMIT = 1200;

  const handleChangeValues = (prop: keyof IFormValues) => (
    event: React.ChangeEvent<any>
  ) => {
    const value = event.target.value;
    if (prop === "name") {
      if (!value.trim()) {
        setNameError("Name on Card is required");
      } else {
        setNameError(null);
      }
    }
    if (prop === "promo") {
      setValidPromo(undefined);
      if (timer) {
        clearTimeout(timer);
        setTimer(undefined);
      }
      if (value) {
        // record promo being input
        setPromoType(prop);
        const newTimer = setTimeout(() => {
          setTimer(undefined);
          getCouponsApi(value)
            .then((result) => {
              setCoupons(result);
              if (!result.length) {
                setValidPromo(false);
                setPromoVisible(false);
              } else {
                setValidPromo(true);
                setPromoVisible(true);
              }
            })
            .catch(() => setCoupons([]));
        }, MAX_DELAY_LIMIT);
        setTimer(newTimer);
      } else {
        setPromoType("");
        setCoupons([]);
      }
    } else if (prop === "association") {
      // record association being set
      setPromoType(prop);
      const checkValue = value ? APTA_PROMO_CODE : "";
      if (value === "") {
        handleApplyPromo("");
      } else {
        getCouponsApi(checkValue)
          .then((result) => {
            setCoupons(result);
            if (!result.length) {
              setValidPromo(false);
              setPromoVisible(false);
              setPromoType("");
            } else {
              setValidPromo(true);
              setPromoVisible(true);
            }
          })
          .catch(() => setCoupons([]));
      }
    }
    setValues({
      ...values,
      [prop]: value,
    });
  };

  const handlePromoInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const promoCode = e.target.value.toUpperCase();
    setPromoInput(promoCode);
    setValidPromo(undefined);

    if (viewportWidth <= 992) {
      getCouponsApi(promoCode)
        .then((result) => {
          setCoupons(result);
          if (!result.length) {
            setValidPromo(false);
            setPromoVisible(false);
          } else {
            setValidPromo(true);
            setPromoVisible(false);
            setValues({ ...values, promo: promoCode });
          }
        })
        .catch(() => setCoupons([]));
    }
  };

  const handleApplyPromo = (promoCode = promoInput) => {
    getCouponsApi(promoCode)
      .then((result) => {
        setCoupons(result);
        if (!result.length) {
          setValidPromo(false);
          setPromoVisible(false);
        } else {
          setValidPromo(true);
          setPromoVisible(true);
          setValues({ ...values, promo: promoCode });
        }
      })
      .catch(() => setCoupons([]));
  };

  const handleCardChange = (event: any) => {
    if (event.complete) {
      setCardError(undefined);
    } else if (event.error) {
      setCardError(event.error);
    }
  };

  function formatPrice(price: any) {
    return "$" + (price % 1 === 0 ? Math.floor(price) : price.toFixed(2));
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setCardError(undefined);

    if (!values.name || !values.name.trim()) {
      setNameError("Name on Card is required");
      return;
    }

    if (!acceptedPolicy) {
      setAcceptedPolicyError("You must accept the policy to proceed.");
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      return;
    }

    const { error, token } = await stripe.createToken(cardElement);
    if (error || !token) {
      setCardError(error);
    } else {
      TapConverted(token.id, isCurrentStudent);
      const payload: SubscriptionPayload = {
        token: token.id,
        plan: values.plan,
        promo: "",
      };
      if ((values.promo || values.association === "APTA") && coupons?.length) {
        payload.promo = coupons[0].id;
      }
      dispatch(createSubscription(payload));
      // dispatch(setTutorial("post_subscription"));
      // dispatch(setVideo("post_subscription"));
      if (popupPlan) {
        dispatch(implementPlan(popupPlan));
      }
    }
  };

  const toggleAcceptedPolicy = () => {
    setAcceptedPolicyError("");
    setAcceptedPolicy((current) => !current);
  };

  const privacyPolicyLink = (
    <a
      href="https://www.fitbux.com/privacy-policy/"
      target="_blank"
      className="term-link"
    >
      Privacy Policy
    </a>
  );
  const termsOfUseLink = (
    <a
      href="https://www.fitbux.com/terms-of-use/"
      target="_blank"
      className="term-link"
    >
      Terms of Use
    </a>
  );
  const refundLink = (
    <a
      href="https://www.fitbux.com/refund-policy/"
      target="_blank"
      className="term-link"
    >
      Refund Policy
    </a>
  );
  const disclosureConsentLink = (
    <a
      href="https://www.fitbux.com/statement-of-electronic-disclosures/"
      target="_blank"
      className="term-link"
    >
      consent to Electronic Disclosures
    </a>
  );

  const getSubscriptionButton = () => {
    const percentOff = coupons?.[0]?.percent_off || 0;
    const amountOff = coupons?.[0]?.amount;
    const plansCopy = [...(moneySchool ? moneySchoolPlans : plans)];
    const sortedPlans = plansCopy.sort((a) => (a.name === "Monthly" ? -1 : 1));
    return (
      <CardEuiFlexGroup
        alignItems="center"
        style={{ flexWrap: "nowrap", justifyContent: "space-between" }}
      >
        {sortedPlans.map((plan: any) => {
          const originalPlanPrice = plan.amount;
          let annualPriceIfPaidMonthly;

          const monthlyPlan = sortedPlans.find(
            (plan) => plan.name === "Monthly"
          );
          const annualPlan = sortedPlans.find(
            (plan) => plan.name === "Annually"
          );
          let dynamicPercentage = 0;
          if (monthlyPlan && annualPlan) {
            const monthlyPrice = monthlyPlan.amount;
            const annualPrice = annualPlan.amount;
            const annualPriceIfPaidMonthly = monthlyPrice * 12;
            dynamicPercentage =
              ((annualPriceIfPaidMonthly - annualPrice) /
                annualPriceIfPaidMonthly) *
              100;
          }
          if (validPromo) {
            let discount = 0;
            if (annualPriceIfPaidMonthly) {
              discount = percentOff
                ? percentOff / 100
                : amountOff / annualPriceIfPaidMonthly;
            }
            dynamicPercentage = Math.round(
              (dynamicPercentage + discount * 100) % 100
            );
          }

          return (
            <EuiFlexItem
              grow={false}
              key={plan.id}
              className={plan.name === "Annually" ? "annual-sub" : ""}
            >
              <EuiCheckableCard
                id={plan.id}
                label={
                  <div className="sub-content">
                    <EuiTitle>
                      <h3>
                        {moneySchool
                          ? "FitBUX Money School"
                          : plan.name === "Annually"
                          ? "Annual"
                          : plan.name}
                      </h3>
                    </EuiTitle>
                    <EuiText>
                      <h3 className="price">
                        {formatPrice(originalPlanPrice)}
                      </h3>
                    </EuiText>
                    {!moneySchool &&
                      (plan.name === "Monthly" || dynamicPercentage > 0) && (
                        <EuiText className="plan-text">
                          <p>
                            {plan.name === "Monthly" ? (
                              "Cancel anytime."
                            ) : (
                              <>
                                {" "}
                                <strong>
                                  Save {dynamicPercentage.toFixed(0)}%
                                </strong>{" "}
                                {windowSize.width > 576 && "annually"}
                              </>
                            )}
                          </p>
                        </EuiText>
                      )}
                  </div>
                }
                name="subscription"
                value={plan.name}
                className={`subscriptionCard ${
                  plan.name === "Annually" ? "recommended" : ""
                }`}
                checked={values.plan === plan.id}
                onChange={(e) => {
                  if (e.target.checked) {
                    setValues({ ...values, plan: plan.id });
                  }
                }}
              />
            </EuiFlexItem>
          );
        })}
      </CardEuiFlexGroup>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <StyledSpacer size="36px" />

      <EuiFormRow label={<StyledSpan>Subscription</StyledSpan>}>
        <>{getSubscriptionButton()}</>
      </EuiFormRow>

      <StyledSpacer size="24px" />
      <EuiFormRow
        label={<StyledSpan>Name on Card</StyledSpan>}
        className="input-size"
        isInvalid={!!nameError}
        error={nameError}
      >
        <EuiFieldText
          name="firstName"
          onChange={handleChangeValues("name")}
          value={values.name || ""}
          isInvalid={!!nameError}
        />
      </EuiFormRow>

      {nameError && <FormError>{nameError}</FormError>}

      <StyledSpacer size="24px" />

      <EuiFormRow
        label={<StyledSpan>Card Number</StyledSpan>}
        className="input-size"
      >
        <CardNumberElement
          options={{ placeholder: "" }}
          onChange={handleCardChange}
        />
      </EuiFormRow>

      <StyledSpacer size="24px" />

      <EuiFlexGroup style={{ maxWidth: 444, gap: 16 }}>
        <EuiFlexItem>
          <EuiFormRow
            label={<StyledSpan>Expiration Date</StyledSpan>}
            helpText="MM/YY"
          >
            <CardExpiryElement
              options={{ placeholder: "" }}
              onChange={handleCardChange}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label={<StyledSpan>Security Code</StyledSpan>}>
            <CardCvcElement
              options={{ placeholder: "" }}
              onChange={handleCardChange}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>

      <StyledSpacer size="24px" />

      {cardError && cardError.message && (
        <>
          <FormError>{cardError.message}</FormError>
          <StyledSpacer size="24px" />
        </>
      )}

      <EuiFlexGroup style={{ maxWidth: 444, alignItems: "end" }}>
        <EuiFlexItem>
          <EuiFormRow
            label={<StyledSpan>Promo Code</StyledSpan>}
            className="input-size"
          >
            <EuiFieldText
              disabled={!!values.association}
              onChange={handlePromoInputChange}
              type="text"
              value={values.association ? "(APTA member)" : promoInput || ""}
            />
          </EuiFormRow>
        </EuiFlexItem>
        {viewportWidth > 992 && (
          <EuiFlexItem grow={false} className="promo-button">
            <EuiFormRow hasEmptyLabelSpace>
              <StyledEuiButton color="text" onClick={applyPromo}>
                Apply
              </StyledEuiButton>
            </EuiFormRow>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>

      {validPromo === false && (
        <FormError>{"Please enter a valid promo code."}</FormError>
      )}

      <StyledSpacer size="10px" />
      {validPromo && promoVisible && (
        <EuiCallOut
          title={`${values.promo || values.association} applied (${
            percentOff ? `${percentOff}% off` : `$${amountOff} dollars off`
          })`}
          color="success"
          iconType="checkInCircleFilled"
        >
          {codeDuration && <p>Code is valid for only {codeDuration} months.</p>}
          <PromoEuiButtonIcon
            iconType="cross"
            color="text"
            aria-label="Close"
            onClick={() => {
              setPromoVisible(false);
            }}
            style={{ position: "absolute", top: "1rem", right: "1rem" }}
          />
        </EuiCallOut>
      )}
      <StyledSpacer size="24px" />

      <EuiFormRow
        label={
          <StyledSpan>
            If you are a member of one of the listed associations, select it
            below. You'll get a special discount!
          </StyledSpan>
        }
        className="input-size"
      >
        <EuiSuperSelect
          options={[
            { value: "", inputDisplay: "(None)" },
            { value: "APTA", inputDisplay: "APTA" },
          ]}
          valueOfSelected={values.association || ""}
          onChange={(value) => {
            setValues({ ...values, association: value });
            setValidPromo(undefined);
            if (value === "APTA") {
              getCouponsApi("APTA")
                .then((result) => {
                  setCoupons(result);
                  if (!result.length) {
                    setValidPromo(false);
                    setPromoVisible(false);
                  } else {
                    setValidPromo(true);
                    setPromoVisible(true);
                    setValues({
                      ...values,
                      promo: "APTA",
                      association: "APTA",
                    });
                  }
                })
                .catch(() => setCoupons([]));
            } else if (value === "") {
              handleApplyPromo("");
            }
          }}
          fullWidth
          disabled={promoType === "promo"}
        />
      </EuiFormRow>

      <StyledSpacer size="24px" />

      <EuiPanel paddingSize="l" className="billing-summary">
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="baseline">
          <EuiFlexItem grow={false}>
            <EuiText>
              {selectedPlan?.name === "Annually"
                ? "Annual"
                : selectedPlan?.name}{" "}
              Plan
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText>${originalPrice.toFixed(2)}</EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>

        {validPromo && (
          <>
            <EuiFlexGroup justifyContent="spaceBetween" alignItems="baseline">
              <EuiFlexItem grow={false}>
                <EuiText>
                  Discount - {values.promo} (
                  {percentOff
                    ? `${percentOff}% off`
                    : `$${amountOff} dollars off`}
                  )
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>-${couponAmount}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </>
        )}

        <EuiHorizontalRule margin="m" />

        <EuiFlexGroup justifyContent="spaceBetween" alignItems="baseline">
          <EuiFlexItem grow={false}>
            <EuiText>
              <strong>Total</strong>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText>
              <strong>${discountedPrice}</strong>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>

      <StyledSpacer size="24px" />

      <EuiCheckbox
        id="acceptPolicy"
        label={
          <p className="term-condition">
            I acknowledge and agree to FitBUX's {privacyPolicyLink},{" "}
            {termsOfUseLink}, {refundLink}, and {disclosureConsentLink}.
          </p>
        }
        checked={acceptedPolicy}
        onChange={toggleAcceptedPolicy}
      />

      {acceptedPolicyError && (
        <FormError type="text">{acceptedPolicyError}</FormError>
      )}

      <StyledSpacer size="32px" />

      <EuiButton
        type="submit"
        fullWidth
        color="primary"
        fill
        disabled={loading || validPromo === false || timer !== undefined}
        className="login-button"
        isLoading={loading}
      >
        {!loading && "Start membership"}
      </EuiButton>

      <StyledSpacer size="32px" />

      <EuiText size="m" className="add-text">
        <p>
          Want to use a different account?&nbsp;
          <StyledEuiLink
            color="text"
            onClick={() => {
              dispatch(logOut());
            }}
          >
            Log in
          </StyledEuiLink>
        </p>
      </EuiText>

      <StyledSpacer size="48px" />

    </form>
  );
};

export default StripeForm;
