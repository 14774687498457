import React from "react";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";

import welcomeImg from "../../../../assets/svgs/change-setting-4.svg";
import { useFlyout } from "src/components/Global/FlyoutContext";
import { StyledEuiLink } from "src/components/Global/StyledComponents";
import Welcome from "../shared/Welcome";

const HumanCapitalIntro = () => {
  const { toggleFlyout } = useFlyout();
  return (
    <Welcome
      prevStep={PROFILE_BUILD_STEPS.ASSETS}
      nextStep={PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_1}
      title="Determine Your Human Capital"
      headerContent={
        <>
          <StyledEuiLink onClick={() => toggleFlyout("human-capital")}>
            Human Capital
          </StyledEuiLink>{" "}
          is the largest asset of young professionals. It's key in order to
          project your future income, the risk to your income, and determines
          your optimal financial plan.
        </>
      }
      boldStep={2}
      welcomeImg={welcomeImg}
    />
  );
};

export default HumanCapitalIntro;
