import { decodeToken } from "react-jwt";
import { getAuthToken } from "src/config";

export const track = (data: any) => {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push(data);
};

export const identify = (email: string, source = "login_completed") => {
    track({ event: source, payload: email });
    const applicantID = (decodeToken(getAuthToken()) as any).sub;
    let userID;
    // set user ID to 0 if a fitbux coach logs into an account
    if (email == "coach@fitbux.com") {
        userID = 0;
    } else {
        userID = applicantID;
    }
    track({ user_id: userID });
    (window as any).mixpanel?.identify(email);
};
