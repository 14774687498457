import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, makeStyles } from "@material-ui/core";

import Button from "src/components/Button";
import LeftSidebar from "src/components/OptimizedLeftSidebar";
import {
  convertOptimizedToManual,
  implementPlan,
  setBuildStep,
} from "src/store/planBuild/actions";
import { getCurrentPlan } from "src/store/planBuild/selector";
import { getIsSubscribed } from "src/store/system/selector";
import { planBuilderStyles } from "src/theme";
import ReviewSidebar from "../PlanBuilder/Components/ReviewSidebar";
// import CenterContainer from "../PlanBuilder/Components/CenterContainer";
import { steps } from "./common";
import MainForm from "./MainForm";

import EditOptions from "./EditOptions";
import { fetchUser } from "src/store/system/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { setPopup } from "src/store/dashboard/actions";
import { push } from "connected-react-router";
import { AdapterLink } from "src/utils";

const useStyles = makeStyles(planBuilderStyles);

const Content = () => {
  const dispatch = useDispatch();
  const currentPlan = useSelector(getCurrentPlan);
  const subscribed = useSelector(getIsSubscribed);
  const styles = useStyles();
  const local = localStyles();
  const [nextClicked, setNextClicked] = useState(0);
  const [furthestIndex, setFurthestIndex] = useState(0);
  const [reviewStep, setReviewStep] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const implementOptimizedPlan = () => {
    if (subscribed) {
      if (currentPlan && currentPlan.id) {
        dispatch(implementPlan(currentPlan.id));
      }
      dispatch(push("/plan-list"));
    } else {
      // FIXME
      // mimic PlanList's openPlanForImplementing()
      dispatch(push("/plan-builder"));
      dispatch(setBuildStep(PLAN_BUILD_STEPS.IMPLEMENT));
    }
  };

  // todo start this at the beginning of the enum
  const [stepIndex, setStepIndex] = useState(0);

  // todo this will be the NEXT handleClick
  const handleClick = () => {
    setNextClicked(nextClicked + 1);
    // incrementStep();
  };

  const incrementStep = () => {
    let nextStep = stepIndex + 1;
    nextStep = nextStep >= steps.length ? 0 : nextStep;
    handleStepIndex(nextStep);
  };

  const handleStepIndex = (index: number) => {
    setStepIndex(index);
    if (index > furthestIndex) {
      setFurthestIndex(index);
    }
  };

  const handleReviewSectionClick = (index: number) => {
    setReviewStep(index);
  };

  const handleReviewSectionEdit = () => {
    setDialogOpen(true);
  };

  const handleEditRoute = (optimized: boolean) => {
    if (optimized) {
      setStepIndex(10);
      handleDialogClose();
    } else {
      dispatch(convertOptimizedToManual());
      dispatch(fetchUser());
      // if we ever change it so graduated users can go back to school this will need to be expanded on
      dispatch(setBuildStep(PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW));
      dispatch(setPopup("Build"));
      dispatch(push("/plan-builder"));
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Box className={styles.content}>
      {stepIndex < 11 && (
        <LeftSidebar
          stepIndex={stepIndex}
          furthestStep={furthestIndex}
          handleClick={handleStepIndex}
        />
      )}

      {stepIndex >= 11 && (
        <Box style={{ height: "calc(100% - 75px)" }}>
          <ReviewSidebar
            handleClick={handleReviewSectionClick}
            handleEdit={handleReviewSectionEdit}
            optimized
          />
        </Box>
      )}

      <Box className={styles.main}>
        {/* <Box/> */}

        <MainForm
          stepIndex={stepIndex}
          reviewStepIndex={reviewStep}
          goToNext={incrementStep}
          nextClicked={nextClicked}
          onSummaryClick={handleStepIndex}
        />
      </Box>

      {stepIndex < 11 && (
        <Box
          style={{
            width: "240px",
            display: "flex",
            justifyContent: "center",
            alignItems: "end",
          }}
        >
          <Box
            style={{
              height: "75px",
              width: "140px",
            }}
          >
            <Button fbColor="primary" onClick={handleClick} className="w-full">
              Next
            </Button>
          </Box>
        </Box>
      )}

      {stepIndex >= 11 && (
        <Box className={local.footer}>
          <Box className={local.implement}>
            <Button
              fbColor="primary"
              className="h-10"
              component={AdapterLink}
              onClick={implementOptimizedPlan}
            >
              Implement This Plan
            </Button>
          </Box>
        </Box>
      )}
      <EditOptions
        open={dialogOpen}
        handleClose={handleDialogClose}
        handleClick={handleEditRoute}
      />
    </Box>
  );
};

export default Content;

const localStyles = makeStyles({
  mainContainer: {
    height: "calc(100vh - 170px)",
    width: "100%",
    paddingLeft: "20px",
    // overflowY: "hidden"
  },
  transitionContainer: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "center",
  },
  transition: {
    width: "50px",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  loaderContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 555,
    background: "rgba(255, 255, 255, .8)",
  },
  footer: {
    position: "absolute",
    backgroundColor: "white",
    bottom: "0px",
    width: "calc(100% + 70px)",
    height: "80px",
    marginLeft: "-100px",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  implement: {
    display: "flex",
    position: "relative",
    marginRight: "calc(50vw - 200px)",
  },
});
