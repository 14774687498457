import axiosClient from "../axiosClient";
import makeAuthenticatedRequest from "../authenticatedRequest";

export interface BettermentAccountPayload {
  who: "applicant" | "spouse";
  investment_account_type: string;
  goal: string;
  goal_term: number;
  email: string;
  first_name: string;
  last_name: string;
  middle_initial: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zip: string;
  };
  phone_number: string;
  date_of_birth: string;
  employment_status: string;
  profession: string;
  income: {
    applicant: number;
    spouse: number;
  };
  assets: number;
  broker_dealer: "y" | "n";
  shareholder: "y" | "n";
  backup_withholding: "y" | "n";
}

interface TickerWeight {
  ticker: string;
  weight: number;
}

export const getCurrentPortfolioApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("invest/portfolio.php");
    return data;
  });

export const setCurrentPortfolioApi = (payload: any) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("invest/portfolio.php", payload);
    return data;
  });

export const getPortfolioPerformanceApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("invest/portfolio/performance.php");
    return data;
  });

export const getHouseholdPerformanceApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("invest/portfolio/household.php");
    return data;
  });

export const interestBettermentAccountApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("ping/invest/account.php");
    return data;
  });

export const createBettermentAccountApi = (accountDetails: any) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post(
      "invest/account.php",
      accountDetails
    );
    return data;
  });

export const getTargetPortfolioPerformanceApi = (tickers: TickerWeight[]) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post(
      "v2/invest/portfolio/statistics",
      tickers
    );
    return data;
  });
