import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { makeStyles } from "@material-ui/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import {
  disableSidebar,
  setSidebarContent,
  toggleSidebar,
} from "src/store/dashboard/actions";
import { colors } from "src/theme";
import { SidebarContent } from "src/interfaces";

const useStyle = makeStyles({
  helpToggle: {
    borderBottom: "none !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

interface HelpToggleProps {
  className?: string;
  content?: SidebarContent[];
  persistOnUnmount?: boolean;
}

const HelpToggle = ({
  className,
  content,
  persistOnUnmount,
}: HelpToggleProps) => {
  const style = useStyle();
  const dispatch = useDispatch();
  const handleClick = () => {
    if (content) {
      dispatch(setSidebarContent(content));
    }
    dispatch(toggleSidebar());
  };

  useEffect(() => {
    if (!persistOnUnmount) {
      // on unmount disable the sidebar
      return () => {
        dispatch(disableSidebar());
      };
    }
  }, [persistOnUnmount]);

  return (
    <span className={clsx(className, style.helpToggle)} onClick={handleClick}>
      <HelpOutlineIcon style={{ color: colors.brandingBlue1 }} />
    </span>
  );
};

export default HelpToggle;
