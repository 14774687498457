import { Box, Card, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  mainContainer: {
    padding: "5px 0px",
  },
  container: {
    // border: "1px solid black",
    borderRadius: "15px",
    padding: "20px 20px 0px 20px",
    backgroundColor: "white",
    "& h1": {
      fontSize: "20px",
    },
  },
  innerBox: {
    padding: "20px 10px",
  },
});

const NotesSection = () => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Card className={classes.container}>
        <Typography variant="h1">Further Recommendations</Typography>
        <Box className={classes.innerBox}>
          <Typography>
            {/* TODO: unhide the numbers and the second comment once the rent vs buy is made available */}
            {/*1. */}These recommendations look only at your current financial
            situation. It does not take into account future life events. We
            highly recommend using our financial planning technology to
            determine if buying a home is affordable today and in the future.
          </Typography>
          {/* <br/>
                    <Typography >
                        2. The analysis above may indicate that you cannot afford to buy a home or that the amount you can afford is lower than what you may have expected.  If that is the case, you should also use our "Rent Vs. Buy" Recommendation tool.  While you may be in a situation where it does not seem like you can afford to buy a house, you may be spending so much in rent that it can still make sense to purchase a home.  
                    </Typography> */}
        </Box>
      </Card>
    </Box>
  );
};

export default NotesSection;
