import React from "react";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiRadioGroup,
  EuiText,
  EuiSuperSelect,
  EuiFormLabel,
} from "@elastic/eui";

import { REPAYMENT_PLANS } from "src/interfaces";
import { DollarTextField, PercentTextField } from "src/utils";
import {
  StyledEuiButton,
  StyledEuiButtonIcon,
  StyledEuiHorizontalRule,
  StyledSpacer,
  LabelStyle,
  LabelAsterisk,
} from "src/components/Global/StyledComponents";
import FormError from "src/components/Global/FormError";

interface IProps {
  formValues: any;
  setFormValues: any;
  monthsSinceGraduation: number;
  enableBreakout: boolean;
  setEnableBreakout: any;
  onChange: any;
  isLinked: boolean;
  editing: boolean;
  errors: any;
}

export const ESTIMATED_FEDERAL_LOAN_BALANCE = "estimated total balance";

export const FedLoansForm = ({
  formValues,
  setFormValues,
  monthsSinceGraduation,
  enableBreakout,
  setEnableBreakout,
  onChange,
  isLinked,
  editing,
  errors,
}: IProps) => {
  const handleSaveLoan = (key: string, value: number, index: number) => {
    setFormValues((current: any) => {
      const fedLoans = [...(current.fed_loans || [])];
      const newFormValues = {
        ...current,
        fed_loans: fedLoans,
      };
      fedLoans[index] = { ...fedLoans[index], [key]: value };
      return newFormValues;
    });
  };

  const removeLoan = (index: number) =>
    setFormValues((current: any) => {
      const fedLoans = [...(current.fed_loans || [])];
      fedLoans.splice(index, 1);
      return { ...current, fed_loans: fedLoans };
    });

  const addLoan = () =>
    setFormValues((current: any) => {
      const fedLoans = [...(current.fed_loans || [])];
      fedLoans.push({ id: 0, balance: 0, rate: 0 });
      return { ...current, fed_loans: fedLoans };
    });

  const handleChange = (prop: string) => (value: any) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [prop]: value,
    }));
  };

  return (
    <>
      {monthsSinceGraduation >= 6 && (
        <>
          <StyledSpacer size="32px" />

          <EuiFormRow
            label={
              <LabelStyle>
                Current Federal Student Loan Plan
                <LabelAsterisk />
              </LabelStyle>
            }
            helpText="Graduated plan is assumed to be amortized on a straight line basis."
            isInvalid={errors.fed_repayment_plan}
          >
            <EuiSuperSelect
              options={Object.keys(REPAYMENT_PLANS).map((type) => ({
                value: type,
                inputDisplay: REPAYMENT_PLANS[type],
              }))}
              valueOfSelected={formValues.fed_repayment_plan}
              onChange={handleChange("fed_repayment_plan")}
              isInvalid={errors.fed_repayment_plan}
            />
          </EuiFormRow>
          {errors.fed_repayment_plan && (
            <FormError>This field is required.</FormError>
          )}
          <StyledSpacer size="32px" />
          <EuiFormRow
            label={
              <LabelStyle>
                Do you want to enter an estimated total balance or individual
                loan details?
                <LabelAsterisk />
              </LabelStyle>
            }
            helpText="Entering individual loan details will make our recommendations more accurate."
          >
            <EuiRadioGroup
              name="enable_breakout"
              options={[
                {
                  id: "n",
                  label: "Estimated total balance",
                },
                {
                  id: "y",
                  label: "Individual loan details",
                },
              ]}
              idSelected={enableBreakout ? "y" : "n"}
              onChange={() => setEnableBreakout((current: boolean) => !current)}
              disabled={
                editing && formValues.name !== ESTIMATED_FEDERAL_LOAN_BALANCE
              }
            />
          </EuiFormRow>

          {enableBreakout && (
            <>
              <StyledSpacer size="32px" />

              <EuiFormRow
                label={
                  <LabelStyle>
                    Minimum Payment
                    <LabelAsterisk />
                  </LabelStyle>
                }
                isInvalid={errors.payment}
              >
                <DollarTextField
                  eui
                  id="payment_input"
                  onChange={onChange("payment")}
                  value={formValues.payment}
                  disabled={isLinked && !formValues?.manual?.payment}
                  isInvalid={errors.payment}
                />
              </EuiFormRow>
              {errors.payment && <FormError>This field is required.</FormError>}
              <StyledSpacer size="32px" />

              <EuiText>
                <p>
                  <b>Enter Individual Loan Details</b>
                </p>
              </EuiText>
              <StyledSpacer size="16px" />
              <EuiText>
                <p>You should have between 5 and 20 Federal student loans.</p>
              </EuiText>
              <StyledSpacer size="32px" />

              {(formValues.fed_loans || []).map((loan: any, index: number) => (
                <>
                  <EuiFlexGroup
                    key={index}
                    style={{ maxWidth: 428 }}
                    className="mobile-field-row"
                  >
                    <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                      <EuiFormRow>
                        <>
                          <EuiFormLabel>
                            Loan {index + 1} <br /> Current Balance*
                          </EuiFormLabel>
                          <DollarTextField
                            eui
                            id={`breakoutBalance_${index}`}
                            name="balance"
                            value={formValues.fed_loans?.[index]?.balance || 0}
                            onChange={(e: React.ChangeEvent<any>) =>
                              handleSaveLoan("balance", e.target.value, index)
                            }
                          />
                        </>
                      </EuiFormRow>
                    </EuiFlexItem>

                    <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                      <EuiFormRow>
                        <>
                          <EuiFormLabel>
                            Loan {index + 1} <br /> Interest Rate*
                          </EuiFormLabel>
                          <PercentTextField
                            eui
                            id={`breakoutRate_${index}`}
                            name="rate"
                            value={formValues.fed_loans?.[index]?.rate || 0}
                            onChange={(e: React.ChangeEvent<any>) =>
                              handleSaveLoan("rate", e.target.value, index)
                            }
                          />
                        </>
                      </EuiFormRow>
                    </EuiFlexItem>

                    <EuiFlexItem className="debt-icon">
                      <StyledEuiButtonIcon
                        iconType="cross"
                        onClick={() => removeLoan(index)}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>

                  <StyledSpacer size="24px" />

                  <StyledEuiHorizontalRule />

                  <StyledSpacer size="24px" />
                </>
              ))}
              <StyledEuiButton color="text" iconType="plus" onClick={addLoan}>
                Add Debt Account
              </StyledEuiButton>
            </>
          )}
          {!enableBreakout && (
            <>
              <StyledSpacer size="32px" />

              <EuiFormRow
                label={
                  <LabelStyle>
                    Estimated Total Balance
                    <LabelAsterisk />
                  </LabelStyle>
                }
                isInvalid={errors.balance}
              >
                <DollarTextField
                  eui
                  id="balance"
                  name="balance"
                  onChange={onChange("balance")}
                  disabled={isLinked && !formValues?.manual?.balance}
                  value={formValues.balance}
                  isInvalid={errors.balance}
                />
              </EuiFormRow>
              {errors.balance && <FormError>This field is required.</FormError>}

              <StyledSpacer size="32px" />

              <EuiFormRow
                label={
                  <LabelStyle>
                    Estimated Interest Rate
                    <LabelAsterisk />
                  </LabelStyle>
                }
                isInvalid={errors.rate}
              >
                <PercentTextField
                  eui
                  id="rate"
                  name="rate"
                  value={formValues.rate}
                  onChange={onChange("rate")}
                  disabled={isLinked && !formValues?.manual?.rate}
                  isInvalid={errors.rate}
                />
              </EuiFormRow>
              {errors.rate && <FormError>This field is required.</FormError>}

              <StyledSpacer size="32px" />

              <EuiFormRow
                label={
                  <LabelStyle>
                    Minimum Payment
                    <LabelAsterisk />
                  </LabelStyle>
                }
                isInvalid={errors.payment}
              >
                <DollarTextField
                  eui
                  id="payment_input"
                  onChange={onChange("payment")}
                  value={formValues.payment}
                  disabled={isLinked && !formValues?.manual?.payment}
                  isInvalid={errors.payment}
                />
              </EuiFormRow>
              {errors.payment && <FormError>This field is required.</FormError>}
            </>
          )}
        </>
      )}
      {monthsSinceGraduation < 6 && (
        <>
          <StyledSpacer size="32px" />
          <EuiFormRow
            label={
              <LabelStyle>
                Current Balance
                <LabelAsterisk />
              </LabelStyle>
            }
            isInvalid={errors.balance}
          >
            <DollarTextField
              eui
              id="balance"
              name="balance"
              value={formValues.balance}
              onChange={onChange("balance")}
              isInvalid={errors.balance}
            />
          </EuiFormRow>
          {errors.balance && <FormError>This field is required.</FormError>}
          <StyledSpacer size="32px" />
          <EuiFormRow
            label={
              <LabelStyle>
                Interest Rate
                <LabelAsterisk />
              </LabelStyle>
            }
            isInvalid={errors.rate}
          >
            <PercentTextField
              eui
              id="rate"
              name="rate"
              value={formValues.rate}
              onChange={onChange("rate")}
              isInvalid={errors.rate}
            />
          </EuiFormRow>
          {errors.rate && <FormError>This field is required.</FormError>}
        </>
      )}
    </>
  );
};

export default FedLoansForm;
