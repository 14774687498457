import React, { useEffect, useMemo, useState } from "react";
import { cashMessages } from "./messages";

import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { Modal } from "src/components/Dialogs";
import Icon from "src/components/Icon";
import Button from "src/components/Button";
import { Transaction } from "src/interfaces";
import colors from "src/theme/colors";
import shadows from "src/theme/shadows";
import {
  fetchFilteredTransactionsApi,
  getBreakoutBaseApi,
} from "src/apiService";
import {
  BREAKOUT_TYPES,
  CASHFLOW_TO_TYPE_AND_CATEGORY,
  TRANSACTION_TYPE_IDS,
} from "src/interfaces";
import { AdapterLink } from "src/utils";

import AddManualTransactionDialog from "./AddManualTransactionDialog";
import ConfirmIncomeDialog from "./ConfirmIncomeDialog";

const useStyles = makeStyles({
  title: {
    fontSize: "16px !important",
  },
  content: {
    height: 576,
    overflowY: "auto",
  },
  item: {
    alignItems: "center",
    minHeight: 61,
    borderBottom: `1px solid ${colors.blueGray7}`,
    padding: `0 5px 0 0`,
    display: "flex",
    justifyContent: "space-between",
  },
  description: {
    fontSize: 14,
  },
  subtitle: {
    fontSize: 10,
    color: colors.blueGray2,
  },
  negative: {
    color: colors.error,
  },
  positive: {
    color: colors.success,
  },
  amount: {
    color: colors.blueGray2,
    fontSize: 14,
    marginRight: 4,
  },
  innerItem: {
    alignItems: "center",
    display: "flex",
  },
  tabContainer: {
    height: 42,
    padding: 4,
    margin: "16px -6px 6px",
    width: "103%",
    boxShadow: shadows[3],
  },
  textInputLabel: {
    marginBottom: 4,
    marginTop: 8,
    fontSize: 10,
  },
});

// const mockTransactions = [
//   {
//     id: 1,
//     description: "Made some money",
//     date: "2021-01-01",
//     amount: 1000.0,
//     type: 7000,
//     account: 0,
//   },
//   {
//     id: 3,
//     description: "Paid more money",
//     date: "2021-06-06",
//     amount: 2000.0,
//     type: 2032,
//     account: 0,
//   },
//   {
//     id: 3,
//     description: "Paid less money",
//     date: "2020-05-07",
//     amount: 100.0,
//     type: 2022,
//     account: 0,
//   },
// ];

const emptyTransaction: Transaction = {
  account: -999,
  amount: -999,
  date: "1999-01-01",
  description: "",
  id: -999,
  type: 7000,
};

// const fuseOptions = {
//   keys: ["description", "typeLabel"],
// };

const ReviewTransactionsDialog = ({
  open,
  onClose,
  query,
  cashMessage,
  onAdd,
  onRemove,
}: any) => {
  const styles = useStyles();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [
    confirmingTransaction,
    setConfirmingTransaction,
  ] = useState<Transaction>(emptyTransaction);
  const [editing, setEditing] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [addAnotherFlag, setAddAnotherFlag] = useState(false);

  const prefill = useMemo(() => {
    if (!open || !query) {
      return undefined;
    }
    const matchingCategory: any =
      CASHFLOW_TO_TYPE_AND_CATEGORY[
        query.type as keyof typeof CASHFLOW_TO_TYPE_AND_CATEGORY
      ];
    return matchingCategory
      ? {
          category: matchingCategory.category,
          type: matchingCategory.type,
        }
      : undefined;
  }, [query, open]);

  const load = () => {
    fetchFilteredTransactionsApi(query).then((results) => {
      setTransactions(results.transactions);
    });
  };

  const handleAdd = () => {
    load();
    if (onAdd) {
      onAdd();
    }
  };

  useEffect(() => {
    if (open && !cashMessage) {
      load();
    }
  }, [open, query]);

  const getTypeLabel = (typeValue: number) => {
    let label: string =
      TRANSACTION_TYPE_IDS[
        (typeValue + "") as keyof typeof TRANSACTION_TYPE_IDS
      ];
    if (!label) {
      label = BREAKOUT_TYPES[(typeValue + "") as keyof typeof BREAKOUT_TYPES];
    }
    if (!label) {
      console.warn(`Missing type label for transaction type ${typeValue}`);
    }
    return label || "";
  };

  const openConfirmDialog = (transaction?: Transaction, toEdit?: boolean) => {
    if (!toEdit) {
      setAddAnotherFlag(true);
    }
    setEditing(!!toEdit);
    let rootTransaction = transaction;
    let promise = Promise.resolve();
    if (transaction) {
      promise = getBreakoutBaseApi(transaction.id)
        .then((result: any) => {
          rootTransaction = result?.transactions?.[0] || transaction;
        })
        .catch(console.error);
    }
    return promise.then(() => {
      if (rootTransaction) {
        setConfirmingTransaction(rootTransaction);
      } else {
        setConfirmingTransaction(emptyTransaction);
      }
      setEditing(true);
    });
  };

  const closeConfirmDialog = () => {
    setConfirmingTransaction(emptyTransaction);
    setEditing(false);
  };

  const handleUpdate = (updatedTransaction: Transaction) => {
    const transactionIndex = transactions.findIndex(
      (item) => item.id === updatedTransaction.id
    );
    if (transactionIndex >= 0) {
      const newTransactions = [...transactions];
      newTransactions[transactionIndex] = updatedTransaction;
      setTransactions(newTransactions);
    }
  };

  const removeTransaction = (id: any) => {
    const closeNow = transactions.length <= 1;
    setTransactions((current) =>
      current.filter((transaction: Transaction) => transaction.id !== id)
    );
    if (closeNow) {
      onClose();
    }
    if (onRemove) {
      onRemove();
    }
  };

  if (editing && open) {
    return (
      <ConfirmIncomeDialog
        open
        onClose={closeConfirmDialog}
        onRemove={removeTransaction}
        transaction={confirmingTransaction}
        alreadyConfirmed
        onSave={handleUpdate}
      />
    );
  }

  if (addDialogOpen && open) {
    return (
      <AddManualTransactionDialog
        visible={addDialogOpen}
        onClose={() => {
          setAddDialogOpen(false);
          setAddAnotherFlag(false);
        }}
        openConfirmDialog={openConfirmDialog}
        prefill={prefill}
        onAdd={handleAdd}
        defaultAddAnother={addAnotherFlag}
      />
    );
  }

  const handleClose = () => {
    closeConfirmDialog();
    onClose();
  };

  return (
    <Modal
      title="Review/Edit Transactions"
      size="sm"
      isOpen={open}
      onClose={handleClose}
    >
      {!cashMessage && (
        <>
          <List className={styles.content}>
            {transactions.map((transaction: any) => (
              <ListItem key={transaction.id} className={styles.item}>
                <Box className={styles.innerItem}>
                  <Box className="flex justify-between items-center">
                    <Avatar className="mr-3">
                      {transaction.description
                        ? transaction.description.substring(0, 2).toUpperCase()
                        : "#"}
                    </Avatar>
                    <Box>
                      <Typography
                        variant="body1"
                        className={styles.description}
                      >
                        {transaction.description}
                      </Typography>
                      <Typography className={styles.subtitle} variant="body2">
                        {transaction.date} | {getTypeLabel(transaction.type)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className={styles.innerItem}>
                  <Typography
                    variant="body1"
                    className={
                      transaction.amount < 0 ? styles.positive : styles.negative
                    }
                  >
                    {transaction.amount < 0 ? "+" : "-"}
                  </Typography>
                  <Typography variant="body1" className={styles.amount}>
                    ${Math.abs(transaction.amount).toFixed(2)}
                  </Typography>
                  <IconButton
                    onClick={() => openConfirmDialog(transaction, true)}
                  >
                    <Icon iconName="fa-pencil" />
                  </IconButton>
                </Box>
              </ListItem>
            ))}
          </List>
          <Box className="flex justify-center">
            <Button fbColor="primary" onClick={() => setAddDialogOpen(true)}>
              Add Manual Transaction
            </Button>
          </Box>
        </>
      )}
      {!!cashMessage && (
        <>
          <section>
            {cashMessages.map((message) => (
              <>
                <p>{message}</p>
              </>
            ))}
          </section>
          <Box style={{ display: "flex" }}>
            <Button
              style={{ margin: "auto" }}
              color="primary"
              component={AdapterLink}
              to="/accounts"
            >
              Go to Accounts Page
            </Button>
          </Box>
        </>
      )}
    </Modal>
  );
};

export default ReviewTransactionsDialog;
