import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  usePlaidLink,
  // PlaidLinkOptions,
} from "react-plaid-link";

import { Box, Dialog } from "@material-ui/core";

import {
  addPublicTokenApi,
  fetchAccountProvidersApi,
  fetchLinkedAccountsApi,
  getPlaidPublicTokenApi,
} from "src/apiService";
import { AccountProvider } from "src/interfaces";
import { SUCCESS } from "src/store/common";
import {
  GET_ACCOUNT_PROVIDERS,
  getAccountProviders as fetchAccountProviders,
  updateProvider,
} from "src/store/account/actions";
import {
  getAccountProviders,
  getNewProviders,
} from "src/store/account/selector";
import { getIsMarried } from "src/store/system/selector";
import { track } from "src/utils/tracking";

import useStyles from "./styles";
import LeftSidebar from "./LeftSidebar";
import MainContent from "./MainContent";

const POLLING_INTERVAL_MS = 5000;

const AccountLinkPage = ({
  isOpen,
  onClose,
  fixMode,
  startingProviderId,
}: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const providers = useSelector(getAccountProviders);
  const newProviders = useSelector(getNewProviders);
  const isMarried = useSelector(getIsMarried);
  const providerAccountsPollingInterval = useRef<any>();
  const [accounts, setAccounts] = useState<any[]>([]);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [activeProviderId, setProviderId] = useState("");
  const [waitingOnNewProvider, setWaitingOnNewProvider] = useState(false);
  const [userToken, setUserToken] = useState("");
  const [spouseToken, setSpouseToken] = useState("");
  const [displayPolling, setDisplayPolling] = useState(false);

  const activeProvider = useMemo(
    () =>
      providers.find((provider: any) => provider.item_id === activeProviderId),
    [providers, activeProviderId]
  );

  const loadAccounts = useCallback(
    (item_id: string) => {
      if (!item_id) {
        return Promise.resolve(true);
      }
      setLoadingAccounts(true);
      return fetchLinkedAccountsApi({ item_id })
        .then((data) => {
          setLoadingAccounts(false);
          const newLastDate = new Date(data.last_updated);
          const newLinkedAccounts = data.accounts.map((account: any) => ({
            ...account,
            item_id: item_id,
            confirmed: !data.new,
            holdings: data.holdings,
            last_updated: data.last_updated,
          }));
          setAccounts(newLinkedAccounts);
          dispatch(
            updateProvider({
              itemId: item_id,
              update: { accounts: newLinkedAccounts.length },
            })
          );
          if (
            newLastDate >
            new Date(accounts.length ? accounts[0].last_updated : 0)
          ) {
            return true;
          }
          return false;
        })
        .catch(() => {
          setAccounts([]);
          return false;
        });
    },
    [activeProvider]
  );

  const stopPolling = () => {
    if (providerAccountsPollingInterval.current) {
      clearInterval(providerAccountsPollingInterval.current);
      providerAccountsPollingInterval.current = null;
    }
  };

  useEffect(() => {
    if (isOpen && !activeProviderId && startingProviderId) {
      setProviderId(startingProviderId);
    }
  }, [isOpen, activeProviderId, startingProviderId]);

  useEffect(() => {
    if (!isOpen) {
      setProviderId("");
      setWaitingOnNewProvider(false);
      stopPolling();
    } else {
      dispatch(fetchAccountProviders());
      getPlaidPublicTokenApi({ who: "applicant" }).then((data) => {
        setUserToken(data?.link_token || "");
      });
      if (isMarried) {
        getPlaidPublicTokenApi({ who: "spouse" }).then((data) => {
          setSpouseToken(data?.link_token || "");
        });
      }
    }
  }, [isOpen]);

  const onSuccess = (who: string) => {
    const successFunc = async (public_token: string) => {
      try {
        await addPublicTokenApi({ public_token, who });
        const data: AccountProvider[] = await fetchAccountProvidersApi();
        dispatch({
          type: GET_ACCOUNT_PROVIDERS + SUCCESS,
          payload: {
            providers: data,
          },
        });
        // track Plaid successful account link in google data layer
        track({ event: "linked_plaid_account" });
        setWaitingOnNewProvider(true);
      } catch (error) {
        console.error(error);
      }
    };
    return successFunc;
  };

  const userConfig: any = {
    token: userToken,
    onSuccess: onSuccess("applicant"),
    // onExit
    // onEvent
  };

  const spouseConfig: any = {
    token: spouseToken,
    onSuccess: onSuccess("spouse"),
    // onExit
    // onEvent
  };

  const {
    // error: userError,
    open: userOpen,
    // ready: userReady,
  } = usePlaidLink(userConfig);
  const {
    // error: spouseError,
    open: spouseOpen,
    // ready: spouseReady,
  } = usePlaidLink(spouseConfig);

  useEffect(() => {
    if (waitingOnNewProvider && newProviders.length) {
      setAccounts([]);
      setProviderId(newProviders[0].item_id);
      startProviderAccountsPolling(newProviders[0].item_id);
      setWaitingOnNewProvider(false);
    }
  }, [newProviders, waitingOnNewProvider]);

  const startProviderAccountsPolling = (item_id: string) => {
    stopPolling();
    setDisplayPolling(true);
    let nPolls = 0;
    const poll = () => {
      if (nPolls > 60) {
        stopPolling();
      } else {
        nPolls++;
        loadAccounts(item_id).then((complete) => {
          if (complete) {
            setDisplayPolling(false);
            stopPolling();
          }
        });
      }
    };
    providerAccountsPollingInterval.current = setInterval(
      poll,
      POLLING_INTERVAL_MS
    );
  };

  const goToNextAfterFix = (item_id: string) => {
    if (fixMode) {
      const nextProvider = providers.find(
        (provider) => !!provider.error && provider.item_id !== item_id
      );
      if (nextProvider) {
        setProviderId(nextProvider.item_id);
        setAccounts([]);
        loadAccounts(nextProvider.item_id);
      }
    } else {
      startProviderAccountsPolling(item_id);
    }
  };

  return (
    <>
      <Dialog fullScreen open={isOpen} onClose={onClose} disableEnforceFocus>
        <Box className={styles.content}>
          <LeftSidebar
            close={onClose}
            fixMode={fixMode}
            activeProviderId={activeProviderId}
            activeProvider={activeProvider}
            providers={providers}
            selectProvider={(providerId: string) => {
              stopPolling();
              setProviderId(providerId);
              loadAccounts(providerId);
            }}
            openLinkDialog={(who: string) => {
              if (who === "spouse") {
                spouseOpen();
              } else {
                userOpen();
              }
            }}
          />
          <MainContent
            goToNextAfterFix={goToNextAfterFix}
            fixMode={fixMode}
            onClose={onClose}
            provider={activeProvider}
            accounts={accounts}
            showWaitingMessage={displayPolling}
            noProviders={!providers.length}
            loading={loadingAccounts}
            polling={displayPolling}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default AccountLinkPage;
