import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";

import {
  editTransactionApi,
  getBreakoutsApi,
  updateBreakoutsApi,
} from "src/apiService";
import {
  Breakout,
  getCategoryForType,
  INCOME_BREAKOUTS,
  isIncomeTypeToConfirm,
  STUDENT_TOP_LEVEL_CATEGORIES,
  TOP_LEVEL_CATEGORIES,
} from "src/interfaces";
import {
  addTransactionSuccess,
  getUnconfirmedTransactions,
  markTransactionsConfirmed,
  setUpdatedBreakouts,
  removeTransaction,
  updateTransaction,
} from "src/store/transaction/actions";
import { getIsCurrentStudent } from "src/store/system/selector";
import Button from "src/components/Button";
import { Modal } from "src/components/Dialogs";
import HelpToggle from "src/components/HelpToggle";
import TransactionDetails from "./TransactionDetails";
import IncomeExplainer from "./IncomeExplainer";
import InputIncome from "./InputIncome";
import InputDeductions from "./InputDeductions";
import TransactionSummary from "./TransactionSummary";

enum STEPS {
  TRANSACTION_DETAILS = 1,
  EXPLAINER,
  INPUT_INCOME,
  INPUT_DEDUCTIONS,
  SUMMARY,
}

const helpContent = [
  {
    header: "Your Income & Deductions",
    body: [
      "Remember, to track your financial plan correctly, you need to know how your overall income is allocated, using percentages.",
      "Your primary goal should be getting 50% of you Total Income allocated to money for your future self (in order to grow your assets and/or pay off debts)!",
      "To track your financial plan properly, we need to make sure your linked transactions reflect a percentage of your Total Income and not Net Income.  Therefore, on this screen enter the Total Income from your paycheck, before any deductions.",
      "Total Income should be broken out into salary and any other sources of income, such as bonuses or commission. This is important because salary and commissions have different risks which are reflected in your FitBUX Score and our recommendations. Also, income such as stipends are taxed differently.",
      "Deductions should be any item taken directly out of your paycheck before the money hits your bank account.",
    ],
  },
];

const titles = [
  "Confirm Transaction",
  "Confirm Transaction",
  "Confirm Transaction",
  <div className="flex items-center">
    <div className="mr-1">Income Details</div>
    <HelpToggle persistOnUnmount content={helpContent} className="mt-2" />
  </div>,
  <div className="flex items-center">
    <div className="mr-1">Deduction(s) Details</div>
    <HelpToggle persistOnUnmount content={helpContent} className="mt-2" />
  </div>,
];

const ConfirmIncomeDialog = ({
  open,
  onClose,
  transaction,
  alreadyConfirmed,
  onFinish,
  onRemove,
  onSave,
}: any) => {
  const dispatch = useDispatch();
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const topLevelCategories = isCurrentStudent
    ? STUDENT_TOP_LEVEL_CATEGORIES
    : TOP_LEVEL_CATEGORIES;
  const [step, setStep] = useState(STEPS.TRANSACTION_DETAILS);
  const [formValues, _setFormValues] = useState<any>({});
  const setFormValues = (arg: any) => {
    _setFormValues(arg);
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    setFormValues((current: any) => {
      return { ...current, [field]: value };
    });
  };

  const removeBreakout = (
    breakoutType: "incomes" | "deductions",
    index: number
  ) => {
    const newValues = [...formValues[breakoutType]];
    newValues.splice(index, 1);
    setFormValues({ ...formValues, [breakoutType]: newValues });
  };

  useEffect(() => {
    if (
      open &&
      transaction.isManual &&
      isIncomeTypeToConfirm(transaction.type)
    ) {
      setStep(STEPS.EXPLAINER);
    }
  }, [open, transaction]);

  useEffect(() => {
    if (transaction) {
      const category = getCategoryForType(transaction.type, topLevelCategories);
      const newFormValues = {
        ...formValues,
        category,
        ...transaction,
        amount: Math.abs(transaction.amount),
      };
      if (!alreadyConfirmed && transaction.manual !== "manual") {
        newFormValues.amount = 0;
      }
      setFormValues(newFormValues);
    }
  }, [transaction, alreadyConfirmed]);

  const submitIncome = () => {
    const absValueMap = (item: any) => ({
      ...item,
      amount: Math.abs(item.amount),
    });
    const finalBreakouts = [
      ...formValues.incomes.map(absValueMap),
      ...formValues.deductions.map(absValueMap),
    ];
    updateBreakoutsApi(transaction.id, finalBreakouts)
      .then((result) => {
        dispatch(getUnconfirmedTransactions(+transaction.id));
        if (result?.transactions?.[0]) {
          if (onSave) {
            onSave(transaction);
          }
          if (onFinish) {
            onFinish(transaction);
          }
        }
        dispatch(setUpdatedBreakouts(result.transactions));
        onClose();
      })
      .catch((e) => {
        console.error(e);
        onClose();
      });
  };

  const submitNonIncome = () => {
    const update: any = {
      type: formValues.type,
      description: formValues.description,
    };
    if (formValues.ignore === "y") {
      update.ignore = "duplicate";
    }
    if (transaction.manual === "manual") {
      update.amount =
        transaction.amount < 0
          ? -Math.abs(formValues.amount)
          : Math.abs(formValues.amount);
    }
    if (!alreadyConfirmed) {
      dispatch(markTransactionsConfirmed([transaction.id]));
    }
    return editTransactionApi(transaction.id, update)
      .then((result) => {
        if (onRemove && formValues.ignore === "y") {
          onRemove(transaction.id);
        }
        const updatedTransaction = result?.transactions?.[0];
        if (updatedTransaction) {
          if (formValues.ignore !== "y") {
            if (onSave) {
              onSave(updatedTransaction);
            }
            if (onFinish) {
              onFinish(transaction);
            }
          }
          if (!alreadyConfirmed && formValues.ignore !== "y") {
            dispatch(addTransactionSuccess(updatedTransaction));
          } else {
            dispatch(updateTransaction({ id: transaction.id, update }));
          }
        }
        onClose();
      })
      .catch((e) => {
        console.error(e);
        onClose();
      });
  };

  const goBack = () => {
    if (step === STEPS.TRANSACTION_DETAILS) {
      onClose();
    } else {
      setStep(step - 1);
    }
  };

  const goForward = () => {
    if (step === STEPS.TRANSACTION_DETAILS) {
      const update: any = {
        type: formValues.type,
        description: formValues.description,
      };
      if (formValues.ignore === "y") {
        update.ignore = "duplicate";
      }
      if (transaction.manual === "manual") {
        update.amount =
          transaction.amount < 0 ? -formValues.amount : formValues.amount;
      }
      editTransactionApi(transaction.id, update)
        .then((result) => {
          const updatedTransaction = result?.transactions?.[0];
          if (formValues.ignore === "y") {
            dispatch(removeTransaction(transaction.id));
          } else if (updatedTransaction) {
            if (onSave && formValues.ignore !== "y") {
              onSave(updatedTransaction);
            }
            dispatch(updateTransaction({ id: transaction.id, update }));
          }
        })
        .then(() => {
          getBreakoutsApi(transaction.id)
            .then((data: Breakout[]) => {
              const incomes: Breakout[] = [];
              const deductions: Breakout[] = [];
              data.forEach((item: Breakout) => {
                if (!("" + item.type in INCOME_BREAKOUTS)) {
                  deductions.push(item);
                } else {
                  incomes.push(item);
                }
              });
              if (transaction.type === 7000 || transaction.type === 7001) {
                if (!incomes.length) {
                  incomes.push({ type: 1001, amount: 0, id: Math.random() });
                }
                if (!deductions.length) {
                  deductions.push(
                    { type: 12302, amount: 0, id: Math.random() },
                    { type: 15000, amount: 0, id: Math.random() },
                    { type: 13102, amount: 0, id: Math.random() }
                  );
                }
              } else if (transaction.type === 7002) {
                incomes.push({ type: 1008, amount: 0, id: Math.random() });
              } else if (transaction.type === 7003) {
                incomes.push({ type: 1010, amount: 0, id: Math.random() });
              }
              setFormValues((current: typeof formValues) => ({
                ...current,
                incomes,
                deductions,
              }));
            })
            .catch(console.error);
        });
    }
    setStep(step + 1);
  };

  const clear = () => {
    setFormValues({});
    setStep(STEPS.TRANSACTION_DETAILS);
    onClose();
  };

  const renderContent = [
    null,
    TransactionDetails,
    IncomeExplainer,
    InputIncome,
    InputDeductions,
    TransactionSummary,
  ];

  const Content: any = renderContent[step || STEPS.TRANSACTION_DETAILS];
  const type = formValues.type || transaction.type;
  let nextFunction = goForward;
  let nextLabel = "Next";
  if (!isIncomeTypeToConfirm(type) || formValues.ignore === "y") {
    nextFunction = submitNonIncome;
    nextLabel = "Confirm";
  } else if (step === STEPS.INPUT_DEDUCTIONS) {
    nextFunction = submitIncome;
    nextLabel = "Confirm";
  }

  return (
    <Modal title={titles[step]} size="sm" isOpen={open} onClose={clear}>
      <Content
        formValues={formValues}
        handleChange={handleChange}
        transaction={transaction}
        setFormValues={setFormValues}
        removeBreakout={removeBreakout}
      />
      <Box className="flex justify-between mt-6">
        <Button fbColor="secondary" onClick={goBack}>
          {step === STEPS.TRANSACTION_DETAILS ? "Cancel" : "Back"}
        </Button>
        <Button
          fbColor="primary"
          disabled={!formValues.type}
          onClick={nextFunction}
        >
          {nextLabel}
        </Button>
      </Box>
    </Modal>
  );
};

export default ConfirmIncomeDialog;
