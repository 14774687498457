import React from "react";

import {
  Box,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { DollarTextField, PercentTextField } from "src/utils/inputMask";

const useStyles = makeStyles({
  text: { fontSize: 14 },
  buttonContainer: {
    margin: "40px auto",
    display: "flex",
    justifyContent: "center",
  },
  inputRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: "12px auto",
    maxWidth: 500,
    alignItems: "center",
  },
});

const DURATION_OPTIONS = [3, 6, 9, 12, 18, 24, 36, 48, 60];

const Input = ({
  amount,
  setAmount,
  rate,
  setRate,
  nMonths,
  setNMonths,
  back,
  next,
}: any) => {
  const styles = useStyles();

  return (
    <>
      <Card title="Your Investment Accounts">
        <Box className={styles.inputRow}>
          <Typography className={styles.text}>Invested Amount</Typography>
          <DollarTextField
            variant="outlined"
            onChange={(e: any) => setAmount(e.target.value)}
            placeholder="$1,000"
            decimalScale={0}
            value={amount}
          />
        </Box>
        <Box className={styles.inputRow}>
          <Typography className={styles.text}>Interest Rate (APY)</Typography>
          <PercentTextField
            variant="outlined"
            onChange={(e: any) => setRate(e.target.value)}
            placeholder="2%"
            value={rate}
          />
        </Box>
        <Box className={styles.inputRow}>
          <Typography className={styles.text}>Length (months)</Typography>
          <Select
            variant="outlined"
            onChange={(e) => setNMonths(e.target.value as any)}
            value={nMonths || undefined}
          >
            {DURATION_OPTIONS.map((n) => (
              <MenuItem value={n} key={"" + n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Card>
      <Box className={styles.buttonContainer}>
        <Button fbColor="primary" onClick={back} className="mr-4">
          Back
        </Button>
        <Button
          fbColor="primary"
          onClick={next}
          disabled={!amount || !rate || !nMonths}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default Input;
