import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { goBack } from "connected-react-router";
import { identity, pick } from "lodash";

import { makeStyles, Typography } from "@material-ui/core";

import {
  interestBettermentAccountApi,
  createBettermentAccountApi,
  updateHouseholdApi,
  updateProfileApi,
  updateUserApi,
} from "src/apiService";
import InvestmentsLayout from "src/layouts/InvestmentsLayout";
import { getInvestableAssetTotal } from "src/store/account/selector";
import { getMyGI, getSpouseGI } from "src/store/cashflow/selector";
import { getScore } from "src/store/dashboard/selector";
import { updatePortfolio } from "src/store/investments/actions";
import { getPortfolioSettings } from "src/store/investments/selector";
import { fetchHousehold, fetchProfile } from "src/store/profileBuild/actions";
import {
  getHousehold,
  getProfile,
  getSpouseProfile,
} from "src/store/profileBuild/selector";
import {
  PERSONAL_INFO_KEYS,
  DASHBOARD_HOUSEHOLD_KEYS,
} from "src/store/profileBuild/selectorKeys";
import { updateUser } from "src/store/system/actions";
import { spouseSelector, userSelector } from "src/store/system/selector";
import { investmentsStyles } from "src/theme";
import {
  scoreToAllocation,
  validateEmail,
  validatePhone,
  validateZip,
} from "src/utils";
import { track } from "src/utils/tracking";

import AccountDetails from "./AccountDetails";
import AccountOpeningIntro from "./AccountOpeningIntro";
import ChangeAllocation from "../shared/ChangeAllocation";
import ContactInfo from "./ContactInfo";
import EmploymentDetails from "./EmploymentDetails";
import Employment2 from "./Employment2";
import PersonalInfo from "./PersonalInfo";
import Rebalancing from "../shared/Rebalancing";
import { bettermentDisclaimerText } from "../shared/text";
import AccountComplete from "./AccountComplete";

const useStyles = makeStyles(investmentsStyles);

const isTruthy = (value: any) => !!value;

const formatters: any = {
  term: (value: string) => value.replace(/\D/g, ""),
  zip: (value: string) => value.replace(/\D/g, "").slice(0, 5),
};

const validators: any = {
  email: validateEmail,
  first_name: isTruthy,
  last_name: isTruthy,
  street_address: isTruthy,
  city: isTruthy,
  state: isTruthy,
  phone_number: validatePhone,
  term: (value: any) => +value > 0,
  zip: validateZip,
};

const profileFields = new Set([
  "first_name",
  "last_name",
  "street_address",
  "city",
  "state",
  "zip",
  "phone_number",
  "dob_day",
  "dob_month",
  "dob_year",
  "profession",
]);

export const CreateBettermentAccount = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const household = useSelector(getHousehold);
  const userProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const user = useSelector(userSelector);
  const spouse = useSelector(spouseSelector);
  const myGrossIncome = useSelector(getMyGI);
  const spouseGrossIncome = useSelector(getSpouseGI);
  const score = useSelector(getScore);
  const portfolioSettings = useSelector(getPortfolioSettings);
  const investableAssetTotal = useSelector(getInvestableAssetTotal);

  const initialFormValues: any = {
    who: "applicant",
    accountType: "roth_ira",
    purpose: "retirement",
    term: "1",
    email: user?.email || "",
    first_name: user?.first_name || "",
    mi: "",
    last_name: user?.last_name || "",
    street_address: household.street_address || "",
    address2: "",
    city: household.city,
    state: household.state,
    zip: household.zip,
    phone_number: userProfile.phone_number,
    dob_day: userProfile.dob_day,
    dob_month: userProfile.dob_month,
    dob_year: userProfile.dob_year,
    employment: "full_time",
    profession: userProfile.profession,
    income: myGrossIncome,
    spouseIncome: spouseGrossIncome,
    assets: "" + investableAssetTotal,
    employedBroker: "n",
    director: "n",
    backupWithholding: "n",
    rebalance: portfolioSettings ? portfolioSettings.rebalance : "auto",
    fitbuxAllocation: scoreToAllocation(score),
    allocation: portfolioSettings
      ? +portfolioSettings.portfolio.split("_")[1]
      : scoreToAllocation(score),
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState<Set<string>>(new Set());
  const [step, setStep] = useState(0);
  const formValuesRef = useRef(initialFormValues);
  const savedProfile = useRef(true);

  useEffect(() => {
    const initialErrors = new Set<string>([]);
    Object.keys(initialFormValues).forEach((key: string) => {
      const validator = validators[key] || null;
      const isValid = !validator || validator(initialFormValues[key]);
      if (!isValid) {
        initialErrors.add(key);
      }
    });
    setErrors(initialErrors);
  }, []);

  useEffect(() => {
    interestBettermentAccountApi();
  }, []);

  const saveProfile = (data: any) => {
    if (savedProfile.current) {
      return;
    }
    savedProfile.current = true;
    const householdUpdate = pick(data, DASHBOARD_HOUSEHOLD_KEYS);
    const profileValues = pick(data, [...PERSONAL_INFO_KEYS, "profession"]);
    const userUpdate = pick(data, ["first_name", "last_name"]);
    const promise =
      data.who === "spouse" ? Promise.resolve() : updateUserApi(userUpdate);
    promise
      .then(() => {
        if (data.who !== "spouse") {
          dispatch(updateUser(userUpdate));
        }
        return updateHouseholdApi(householdUpdate);
      })
      .then(() => {
        return updateProfileApi({
          who: data.who,
          profile: profileValues,
        } as any);
      })
      .then(() => {
        dispatch(fetchHousehold());
        dispatch(fetchProfile());
      });
  };

  const gotoStep = (target: number) => {
    if (!savedProfile.current) {
      saveProfile(formValuesRef.current);
    }
    setStep(target);
  };

  useEffect(() => {
    return () => {
      if (!savedProfile.current) {
        saveProfile(formValuesRef.current);
      }
    };
  }, []);

  const exit = () => dispatch(goBack());

  const addError = (field: string) =>
    setErrors((current) => {
      const newErrors = new Set(current);
      newErrors.add(field);
      return newErrors;
    });

  const clearError = (field: string) =>
    setErrors((current) => {
      const newErrors = new Set(current);
      newErrors.delete(field);
      return newErrors;
    });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target) {
      return;
    }
    const field = e.target.name;
    const formatter = formatters[field] || identity;
    const value = formatter(e.target.value);
    const validator = validators[field] || null;
    const isValid = !validator || validator(value);
    if (!isValid) {
      addError(field);
    } else {
      clearError(field);
    }

    setFormValues((current: any) => {
      const newFormValues = { ...current, [field]: value };
      if (field === "who") {
        if (value === "spouse") {
          newFormValues.first_name = spouse?.first_name || "";
          newFormValues.last_name = spouse?.last_name || "";
          newFormValues.email = spouse?.email || "";
          newFormValues.phone_number = spouseProfile?.phone_number || "";
          newFormValues.dob_day = spouseProfile?.dob_day || 1;
          newFormValues.dob_month = spouseProfile?.dob_month || 1;
          newFormValues.dob_year = spouseProfile?.dob_year || 1999;
          newFormValues.profession = spouseProfile?.profession || "";
          newFormValues.income = spouseGrossIncome || 0;
          newFormValues.spouseIncome = myGrossIncome || 0;
        } else {
          newFormValues.first_name = user?.first_name || "";
          newFormValues.last_name = user?.last_name || "";
          newFormValues.email = user?.email || "";
          newFormValues.phone_number = userProfile?.phone_number || "";
          newFormValues.dob_day = userProfile?.dob_day || 1;
          newFormValues.dob_month = userProfile?.dob_month || 1;
          newFormValues.dob_year = userProfile?.dob_year || 1999;
          newFormValues.profession = userProfile?.profession || "";
          newFormValues.income = myGrossIncome || 0;
          newFormValues.spouseIncome = spouseGrossIncome || 0;
        }
      }
      formValuesRef.current = newFormValues;
      return newFormValues;
    });
    if (profileFields.has(field)) {
      savedProfile.current = false;
    }
  };

  const createAccount = () => {
    let dobDay = "" + formValues.dob_day;
    let dobMonth = "" + formValues.dob_month;
    if (dobDay.length < 2) {
      dobDay = "0" + dobDay;
    }
    if (dobMonth.length < 2) {
      dobMonth = "0" + dobMonth;
    }
    const accountPayload = {
      who: formValues.who,
      investment_account_type: formValues.accountType,
      goal: formValues.purpose,
      goal_term: +formValues.term,
      email: formValues.email,
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      middle_initial: formValues.mi,
      address: {
        line1: formValues.street_address,
        line2: formValues.address2,
        city: formValues.city,
        state: formValues.state,
        zip: formValues.zip,
      },
      phone_number: formValues.phone_number,
      date_of_birth: `${formValues.dob_year}-${dobMonth}-${dobDay}`,
      employment_status: formValues.employment,
      profession: formValues.profession,
      income: {
        applicant:
          formValues.who === "spouse"
            ? +formValues.spouseIncome
            : +formValues.income,
        spouse:
          formValues.who === "spouse"
            ? +formValues.income
            : +formValues.spouseIncome,
      },
      assets: +formValues.assets,
      broker_dealer: formValues.employedBroker,
      shareholder: formValues.director,
      backup_withholding: formValues.backupWithholding,
    };
    let rebalance = formValues.rebalance;
    if (formValues.fitbuxAllocation !== formValues.allocation) {
      rebalance = "static";
    }
    const portfolioPayload = {
      portfolio: `${100 - formValues.allocation}_${formValues.allocation}`,
      rebalance,
      score,
      recommended: `${100 - formValues.fitbuxAllocation}_${
        formValues.fitbuxAllocation
      }`,
    };
    dispatch(updatePortfolio(portfolioPayload));
    createBettermentAccountApi(accountPayload).then(() => {
      gotoStep(8);
      // track end of betterment account open process in google data layer
      track({ event: "open_investment_account" });
    });
  };

  const renderContent = () => {
    switch (step) {
      case 0:
        return <AccountOpeningIntro next={() => gotoStep(1)} goBack={exit} />;
      case 1:
        return (
          <AccountDetails
            next={() => gotoStep(2)}
            goBack={() => gotoStep(0)}
            formValues={formValues}
            handleChange={handleChange}
          />
        );
      case 2:
        return (
          <ContactInfo
            next={() => gotoStep(3)}
            goBack={() => gotoStep(1)}
            formValues={formValues}
            handleChange={handleChange}
            errors={errors}
          />
        );
      case 3:
        return (
          <PersonalInfo
            next={() => gotoStep(4)}
            goBack={() => gotoStep(2)}
            formValues={formValues}
            handleChange={handleChange}
            errors={errors}
          />
        );
      case 4:
        return (
          <EmploymentDetails
            next={() => gotoStep(5)}
            goBack={() => gotoStep(3)}
            formValues={formValues}
            handleChange={handleChange}
            errors={errors}
          />
        );
      case 5:
        return (
          <Employment2
            next={() => gotoStep(6)}
            goBack={() => gotoStep(4)}
            formValues={formValues}
            handleChange={handleChange}
          />
        );
      case 6:
        return (
          <ChangeAllocation
            next={() => {
              if (formValues.fitbuxAllocation !== formValues.allocation) {
                return createAccount();
              }
              return gotoStep(7);
            }}
            goBack={() => gotoStep(5)}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case 7:
        return (
          <Rebalancing
            next={createAccount}
            goBack={() => gotoStep(6)}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case 8:
      default:
        return <AccountComplete />;
    }
  };

  return (
    <InvestmentsLayout
      goBackTarget="/investments"
      headerText="Open An Investment Account With FitBUX"
    >
      <main className={classes.content}>
        {renderContent()}
        {step !== 8 && (
          <Typography className={classes.disclaimer}>
            {bettermentDisclaimerText}
          </Typography>
        )}
      </main>
    </InvestmentsLayout>
  );
};

export default CreateBettermentAccount;
