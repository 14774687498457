import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { getPayoffAnalysisLoansApi, RefinanceResponse } from "src/apiService";
import Button from "src/components/Button";
import { getProfile, getSpouseProfile } from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import CalculatorLayout from "src/layouts/CalculatorLayout";
import { AdapterLink } from "src/utils";

import Intro from "./Intro";
import SelectMineOrSpouse from "./SelectMineOrSpouse";
import SelectLoans from "./SelectLoans";
import RefinanceDetails from "./RefinanceDetails";
import RefinancePayments from "./RefinancePayments";
// import EditAccount from "./EditAccount";
import CompareOffers from "./CompareOffers";
import ComparePayments from "./ComparePayments";
import CompareResults from "./CompareResults";

enum REFINANCE_TOOL_STEPS {
  INTRO,
  MINE_OR_SPOUSE,
  SELECT_LOANS,
  CHOOSE_OFFER,
  REFINANCE_PAYMENTS,
  EDIT_ACCOUNT,
  COMPARE_OFFERS,
  COMPARE_PAYMENTS,
  COMPARE_RESULTS,
}

const TERMS = [5, 7, 8, 10, 12, 15, 20];

interface FormValues {
  refinanceTerm: number;
  refinanceRate: number;
  prepayRefinance: number;
  prepayCurrent: number;
  compareAmount1: number;
  compareTerm1: number;
  compareRate1: number;
  comparePrepay1: number;
  compareAmount2: number;
  compareTerm2: number;
  compareRate2: number;
  comparePrepay2: number;
}

const initialValues = {
  refinanceTerm: 5,
  refinanceRate: 0,
  prepayRefinance: 0,
  prepayCurrent: 0,
  compareAmount1: 0,
  compareTerm1: 5,
  compareRate1: 0,
  comparePrepay1: 0,
  compareAmount2: 0,
  compareTerm2: 5,
  compareRate2: 0,
  comparePrepay2: 0,
};

export const Refinance = () => {
  const isMarried = useSelector(getIsMarried);
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const [step, setStep] = useState(REFINANCE_TOOL_STEPS.INTRO);
  const [isSpouse, setIsSpouse] = useState(false);
  const [loans, setLoans] = useState<any[]>([]);
  const [selectedLoans, setSelectedLoans] = useState<Set<number>>(new Set());
  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [results, setResults] = useState<RefinanceResponse | null>(null);
  const [compareResults, setCompareResults] = useState<
    [RefinanceResponse, RefinanceResponse] | null
  >(null);
  const [fixed, setFixed] = useState(false);

  const refiBalance = useMemo(
    () =>
      loans.reduce((result, loan, index) => {
        if (!selectedLoans.has(index)) {
          return result;
        }
        return result + loan.balance;
      }, 0),
    [formValues, loans]
  );

  const nextStep = () => setStep((current) => current + 1);
  const prevStep = () => setStep((current) => current - 1);

  const goToRefinance = () => {
    if (isMarried && step === REFINANCE_TOOL_STEPS.INTRO) {
      setStep(REFINANCE_TOOL_STEPS.MINE_OR_SPOUSE);
    } else {
      setStep(REFINANCE_TOOL_STEPS.SELECT_LOANS);
    }
  };

  const goToCompare = () => setStep(REFINANCE_TOOL_STEPS.COMPARE_OFFERS);

  const backToIntro = () => {
    if (isMarried && step === REFINANCE_TOOL_STEPS.SELECT_LOANS) {
      setStep(REFINANCE_TOOL_STEPS.MINE_OR_SPOUSE);
    } else {
      setStep(REFINANCE_TOOL_STEPS.INTRO);
    }
  };

  const backFromCompare = () => {
    if (fixed) {
      setFixed(false);
      setStep(REFINANCE_TOOL_STEPS.REFINANCE_PAYMENTS);
    } else {
      backToIntro();
    }
  };

  const goToFixedCompare = () => {
    setFixed(true);
    setStep(REFINANCE_TOOL_STEPS.COMPARE_OFFERS);
  };

  const fetchLoans = () => {
    return getPayoffAnalysisLoansApi().then((loans) => {
      const profile = isSpouse ? spouseProfile : myProfile;
      const repaymentPlan = profile.fed_repayment_plan;
      const excludeFedloans =
        repaymentPlan !== "std_plan" && repaymentPlan !== "ext_fixed";
      const filteredLoans = loans.filter(
        (loan: any) => !excludeFedloans || loan.type !== "federal"
      );
      if (isMarried && isSpouse) {
        setLoans(filteredLoans.filter((loan) => loan.whose === "spouse"));
      } else {
        setLoans(filteredLoans.filter((loan) => loan.whose !== "spouse"));
      }
    });
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    const value = e.target.value;
    const fieldName = e.target.name;
    setFormValues((current) => ({ ...current, [fieldName]: +value }));
  };

  const goToEditAccount = () => {
    // setEditAccountTarget(target);
    setStep(REFINANCE_TOOL_STEPS.EDIT_ACCOUNT);
  };

  const renderContent = () => {
    switch (step) {
      case REFINANCE_TOOL_STEPS.INTRO:
        return (
          <Intro goToRefinance={goToRefinance} goToCompare={goToCompare} />
        );
      case REFINANCE_TOOL_STEPS.MINE_OR_SPOUSE:
        return (
          <SelectMineOrSpouse
            goBack={backToIntro}
            setIsSpouse={setIsSpouse}
            onNext={goToRefinance}
          />
        );
      case REFINANCE_TOOL_STEPS.SELECT_LOANS:
        return (
          <SelectLoans
            fetchLoans={fetchLoans}
            loans={loans}
            onNext={nextStep}
            goBack={backToIntro}
            selectedLoans={selectedLoans}
            setSelectedLoans={setSelectedLoans}
            goToEditAccount={goToEditAccount}
          />
        );
      case REFINANCE_TOOL_STEPS.CHOOSE_OFFER:
        return (
          <RefinanceDetails
            goBack={prevStep}
            formValues={formValues}
            handleChange={handleChange}
            onNext={nextStep}
            availableTerms={TERMS}
          />
        );
      case REFINANCE_TOOL_STEPS.REFINANCE_PAYMENTS:
        return (
          <RefinancePayments
            goBack={prevStep}
            formValues={formValues}
            loans={loans}
            handleChange={handleChange}
            results={results}
            setResults={setResults}
            selectedLoans={selectedLoans}
            onNext={goToFixedCompare}
            isSpouse={isSpouse}
          />
        );
      case REFINANCE_TOOL_STEPS.COMPARE_OFFERS:
        return (
          <CompareOffers
            goBack={backFromCompare}
            formValues={formValues}
            handleChange={handleChange}
            onNext={nextStep}
            fixed={fixed}
            refiBalance={refiBalance}
            availableTerms={TERMS}
          />
        );
      case REFINANCE_TOOL_STEPS.COMPARE_PAYMENTS:
        return (
          <ComparePayments
            goBack={prevStep}
            fixed={fixed}
            formValues={formValues}
            handleChange={handleChange}
            onNext={nextStep}
            setCompareResults={setCompareResults}
            refiBalance={refiBalance}
          />
        );
      case REFINANCE_TOOL_STEPS.COMPARE_RESULTS:
        return <CompareResults goBack={prevStep} results={compareResults} />;
      default:
        return null;
    }
  };

  return (
    <CalculatorLayout
      title="Should I Refinance My Student Loans?"
      backTo="/studentloans"
      headerContent={
        <Button
          component={AdapterLink}
          fbColor="secondary"
          to="/solutions?solution=refinance"
        >
          Check My Rates
        </Button>
      }
    >
      <main className="mt-10 px-5">{renderContent()}</main>
    </CalculatorLayout>
  );
};

export default Refinance;
