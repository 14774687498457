import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";

const useStyles = makeStyles({
  text: { fontSize: 14 },
  buttonContainer: {
    margin: "40px auto",
    display: "flex",
    justifyContent: "center",
  },
});

const Introduction = ({ next }: any) => {
  const styles = useStyles();

  return (
    <>
      <Card title="View, Manage, and Pay Your Student Loans Through FitBUX">
        <Box className="mt-4">
          <Typography paragraph className={styles.text}>
            With this new FitBUX feature, you can now:
          </Typography>
          <ol>
            <li>Link and see all of your student loans loans in one place.</li>
            <li>
              See which loan to target first (if you are paying off your loans.)
            </li>
            <li>
              Make a one-time payment through FitBUX and get peace of mind
              knowing it will be applied correctly.
            </li>
            <li>Make recurring prepayments through FitBUX.</li>
          </ol>
          <Typography paragraph className={styles.text}>
            NOTE: Student loan accounts linked through this feature will not be
            visible on your FitBUX Dashboard at this time. You will still need
            to re-link in the "Accounts" section of your Dashboard to use our
            calculators and financial planning features.
          </Typography>
        </Box>
      </Card>
      <Box className={styles.buttonContainer}>
        <Button fbColor="primary" onClick={next}>
          Continue
        </Button>
      </Box>
    </>
  );
};

export default Introduction;
