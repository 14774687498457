import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, MenuItem, Select, Typography } from "@material-ui/core";

import { PlanViewComponent } from "src/interfaces";
import { savePlan, updateCurrentPlan } from "src/store/planBuild/actions";
import { getCurrentPlan } from "src/store/planBuild/selector";

import CenterContainer from "../../Components/CenterContainer";

export const ShortTermGoals = {
  marriage: "Save for a wedding",
  house: "Save to purchase a house",
  vehicle: "Save to purchase an investment",
  property: "Save to purchase a vehicle",
};

const InvestVSPayDebt: PlanViewComponent = ({ onSave, render }) => {
  const dispatch = useDispatch();
  const plan = useSelector(getCurrentPlan);
  const [priority, setPriority] = useState(plan.profile.priority || "debt");

  const handleChange = (e: React.ChangeEvent<any>) => {
    const priority = e.target.value;
    setPriority(priority);
  };

  const save = () => {
    // create a new plan where the priority is updated to the new value
    const newPlan = {
      ...plan,
      profile: {
        ...plan.profile,
        priority,
      },
    };
    dispatch(updateCurrentPlan(newPlan));
    dispatch(savePlan(null));
    onSave();
  };

  return render({
    component: (
      <CenterContainer
        title="Invest vs Paying Off Debt"
        iconName="fb-model"
        scrollable
      >
        <Box className="mt-5 mb-4">
          <Typography style={{ fontSize: "13px" }}>
            Do you want to prioritize investing or paying off debt?
          </Typography>
        </Box>
        <Select
          placeholder="Month"
          variant="outlined"
          name="month"
          onChange={handleChange}
          value={priority}
          // className={styles.autoText + " mr-4"}
        >
          <MenuItem value={"debt"}>Pay Off Debt</MenuItem>
          <MenuItem value={"asset"}>Invest</MenuItem>
        </Select>
      </CenterContainer>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default InvestVSPayDebt;
