import React from "react";
import clsx from "clsx";
import { Box, makeStyles } from "@material-ui/core";

import Icon from "src/components/Icon";
import colors from "src/theme/colors";
import { calculatePercentile } from "./ListScore";

const titles = {
  Excellent: "You're Doing Great!",
  Average: "You're Doing Well",
  Poor: "Improvement Needed",
};

const blurbs = {
  Excellent:
    "You are in great shape. Stay on pace by building and implementing your plan, and tracking your FitBUX Score.",
  Average:
    "You’re in a good place. You have moderate financial risk, and multiple financial options. Keep it up by building and implementing your plan, and tracking your FitBUX Score.",
  Poor:
    "You should take steps to lower your financial risk and gain financial flexibility. Build and implement your plan to track your FitBUX Score to hit your goals.",
};

interface ScoreReportProps {
  className?: string;
  live?: boolean;
  liveScore?: number;
  score: number;
}

const TextScoreReport = ({
  className,
  live,
  liveScore,
  score,
}: ScoreReportProps) => {
  const styles = useStyles();

  const metGoal = live && score >= (liveScore || 0);
  let face = metGoal ? "fb-face-satisfied" : "fb-face-poor";
  let blurb = metGoal
    ? "You are ahead of schedule! Track your progress below."
    : "You are behind schedule! See the Action Items and your progress below.";

  const percentile = calculatePercentile(score, 500, 250);
  if (!live) {
    face = percentile.face;
    blurb = blurbs[percentile.title as keyof typeof titles];
  }

  return (
    <div className={clsx(styles.container, className, live ? "p-2" : "p-5")}>
      <Box className={live ? "flex" : ""}>
        <Icon iconName={face} className={live ? "mr-2" : ""} />
        {!live && <h1>{titles[percentile.title as keyof typeof titles]}</h1>}
        <p>{blurb}</p>
      </Box>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    "& svg,& path": {
      fill: colors.brandingBlue1,
    },
    "& h1": {
      fontSize: 20,
      margin: "12px 0px",
      color: colors.brandingBlue1,
      fontWeight: 600,
    },
    "& p": {
      fontSize: 14,
      margin: 0,
    },
  },
});

export default TextScoreReport;
