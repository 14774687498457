import React from "react";

import {
  EuiFieldText,
  EuiFormRow,
  EuiFormControlLayout,
  EuiRadio,
} from "@elastic/eui";
import {
  StyledSpacer,
  StyledSpan,
} from "src/components/Global/StyledComponents";
import { LANGUAGE_LEVEL } from "src/store/profileBuild/constants";

const LanguageSection = ({
  formValues,
  setFormValues,
  isMine,
  spouseFirstName,
}: any) => {
  const doYou = isMine ? "Do you" : `Does ${spouseFirstName}`;

  const handleChangeLanguage = (e: any) =>
    setFormValues((current: any) => ({
      ...current,
      [e.target.name]: e.target.value,
    }));

  const renderLanguageEntry = (index: number) => {
    return (
      <>
        <StyledSpacer size="32px" />
        <EuiFormRow label={`Language #${index}`}>
          <EuiFieldText
            id={`lang_${index}`}
            name={`lang_${index}`}
            value={formValues[`lang_${index}`]}
            onChange={handleChangeLanguage}
          />
        </EuiFormRow>
        <StyledSpacer size="32px" />
        <EuiFormRow label="Speaking">
          <EuiFormControlLayout>
            <EuiRadio
              name={`lang_${index}_speaking`}
              id={`lang_${index}_speaking_1`}
              label="Beginner"
              checked={
                formValues[`lang_speaking_${index}`] === LANGUAGE_LEVEL.BEGINNER
              }
              value={LANGUAGE_LEVEL.BEGINNER}
              onChange={(e: any) =>
                setFormValues((current: any) => ({
                  ...current,
                  [`lang_speaking_${index}`]: e.target.value,
                }))
              }
            />
            <EuiRadio
              name={`lang_${index}_speaking`}
              id={`lang_${index}_speaking_2`}
              label="Proficient"
              checked={
                formValues[`lang_speaking_${index}`] ===
                LANGUAGE_LEVEL.PROFICIENT
              }
              value={LANGUAGE_LEVEL.PROFICIENT}
              onChange={(e: any) =>
                setFormValues((current: any) => ({
                  ...current,
                  [`lang_speaking_${index}`]: e.target.value,
                }))
              }
            />
            <EuiRadio
              name={`lang_${index}_speaking`}
              id={`lang_${index}_speaking_3`}
              label="Fluent"
              checked={
                formValues[`lang_speaking_${index}`] === LANGUAGE_LEVEL.FLUENT
              }
              value={LANGUAGE_LEVEL.FLUENT}
              onChange={(e: any) =>
                setFormValues((current: any) => ({
                  ...current,
                  [`lang_speaking_${index}`]: e.target.value,
                }))
              }
            />
          </EuiFormControlLayout>
        </EuiFormRow>
        <StyledSpacer size="32px" />
        <EuiFormRow label="Reading">
          <EuiFormControlLayout>
            <EuiRadio
              name={`lang_${index}_reading`}
              id={`lang_${index}_reading_1`}
              label="Beginner"
              checked={
                formValues[`lang_reading_${index}`] === LANGUAGE_LEVEL.BEGINNER
              }
              value={LANGUAGE_LEVEL.BEGINNER}
              onChange={(e: any) =>
                setFormValues((current: any) => ({
                  ...current,
                  [`lang_reading_${index}`]: e.target.value,
                }))
              }
            />
            <EuiRadio
              name={`lang_${index}_reading`}
              id={`lang_${index}_reading_2`}
              label="Proficient"
              checked={
                formValues[`lang_reading_${index}`] ===
                LANGUAGE_LEVEL.PROFICIENT
              }
              value={LANGUAGE_LEVEL.PROFICIENT}
              onChange={(e: any) =>
                setFormValues((current: any) => ({
                  ...current,
                  [`lang_reading_${index}`]: e.target.value,
                }))
              }
            />
            <EuiRadio
              name={`lang_${index}_reading`}
              id={`lang_${index}_reading_3`}
              label="Fluent"
              checked={
                formValues[`lang_reading_${index}`] === LANGUAGE_LEVEL.FLUENT
              }
              value={LANGUAGE_LEVEL.FLUENT}
              onChange={(e: any) =>
                setFormValues((current: any) => ({
                  ...current,
                  [`lang_reading_${index}`]: e.target.value,
                }))
              }
            />
          </EuiFormControlLayout>
        </EuiFormRow>
        <StyledSpacer size="32px" />
        <EuiFormRow label="Writing">
          <EuiFormControlLayout>
            <EuiRadio
              name={`lang_${index}_writing`}
              id={`lang_${index}_writing_1`}
              label="Beginner"
              checked={
                formValues[`lang_writing_${index}`] === LANGUAGE_LEVEL.BEGINNER
              }
              value={LANGUAGE_LEVEL.BEGINNER}
              onChange={(e: any) =>
                setFormValues((current: any) => ({
                  ...current,
                  [`lang_writing_${index}`]: e.target.value,
                }))
              }
            />
            <EuiRadio
              name={`lang_${index}_writing`}
              id={`lang_${index}_writing_2`}
              label="Proficient"
              checked={
                formValues[`lang_writing_${index}`] ===
                LANGUAGE_LEVEL.PROFICIENT
              }
              value={LANGUAGE_LEVEL.PROFICIENT}
              onChange={(e: any) =>
                setFormValues((current: any) => ({
                  ...current,
                  [`lang_writing_${index}`]: e.target.value,
                }))
              }
            />
            <EuiRadio
              name={`lang_${index}_writing`}
              id={`lang_${index}_writing_3`}
              label="Fluent"
              checked={
                formValues[`lang_writing_${index}`] === LANGUAGE_LEVEL.FLUENT
              }
              value={LANGUAGE_LEVEL.FLUENT}
              onChange={(e: any) =>
                setFormValues((current: any) => ({
                  ...current,
                  [`lang_writing_${index}`]: e.target.value,
                }))
              }
            />
          </EuiFormControlLayout>
        </EuiFormRow>
      </>
    );
  };

  return (
    <>
      <EuiFormRow
        label={<StyledSpan>{doYou} speak or read other languages?</StyledSpan>}
        className="input-size"
      >
        <EuiFormControlLayout>
          <EuiRadio
            name="lang"
            id="lang_n"
            label="No"
            checked={formValues.lang === "n"}
            value="n"
            onChange={(e: any) =>
              setFormValues((current: any) => ({
                ...current,
                lang: e.target.value,
              }))
            }
          />
          <EuiRadio
            name="lang"
            id="lang_y"
            label="Yes"
            checked={formValues.lang === "y"}
            value="y"
            onChange={(e: any) =>
              setFormValues((current: any) => ({
                ...current,
                lang: e.target.value,
              }))
            }
          />
        </EuiFormControlLayout>
      </EuiFormRow>
      {formValues.lang === "y" && (
        <>
          <StyledSpacer size="32px" />
          <EuiFormRow
            label={
              <StyledSpan>{doYou} speak 2 or more other languages?</StyledSpan>
            }
            className="input-size"
          >
            <EuiFormControlLayout>
              <EuiRadio
                name="two_langs"
                id="two_langs_n"
                label="No"
                checked={formValues.two_langs === "n"}
                value="n"
                onChange={(e: any) =>
                  setFormValues((current: any) => ({
                    ...current,
                    two_langs: e.target.value,
                  }))
                }
              />
              <EuiRadio
                name="two_langs"
                id="two_langs_y"
                label="Yes"
                checked={formValues.two_langs === "y"}
                value="y"
                onChange={(e: any) =>
                  setFormValues((current: any) => ({
                    ...current,
                    two_langs: e.target.value,
                  }))
                }
              />
            </EuiFormControlLayout>
          </EuiFormRow>
          {renderLanguageEntry(1)}
        </>
      )}
      {formValues.two_langs === "y" && renderLanguageEntry(2)}
    </>
  );
};

export default LanguageSection;
