import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import colors from "src/theme/colors";
import Button from "src/components/Button";
import { AdapterLink } from "src/utils";

interface ILockedLayer {
  children?: React.ReactNode;
  button?: { label: string; to?: string; onClick?: VoidFunction };
  btnLabel?: string;
  isLocked?: boolean;
}

const FbCardWithLayer: React.FC<ILockedLayer> = ({
  btnLabel,
  children,
  isLocked,
  button,
}) => {
  const classes = useStyles();

  const renderButton = () => {
    if (!button) {
      return null;
    }
    const buttonProps: any = {
      className: classes.upgradeBtn,
      fbColor: "primary",
      variant: "outlined",
    };
    if (button.onClick) {
      buttonProps.onClick = button.onClick;
    } else if (button.to) {
      buttonProps.component = AdapterLink;
      buttonProps.to = button.to;
    }
    return <Button {...buttonProps}>{button.label}</Button>;
  };

  return (
    <div className={classes.container}>
      {isLocked && (
        <div className={classes.header}>
          {!!btnLabel && (
            <Button
              variant="outlined"
              fbColor="primary"
              className={classes.upgradeBtn}
            >
              {btnLabel}
            </Button>
          )}
          {renderButton()}
        </div>
      )}
      <div className={clsx(isLocked && classes.content)}>
        {isLocked && <div className={classes.layer}></div>}
        {children}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    position: "relative",
    "& svg": {
      color: colors.brandingBlue1,
    },
  },
  content: {
    filter: "blur(2px)",
  },
  layer: {
    backgroundColor: colors.brandingBlue1,
    opacity: "0.1",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "24px",
    zIndex: 1,
  },
  header: {
    position: "initial",
  },
  upgradeBtn: {
    background: "white",
    color: colors.brandingBlue1,
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: "85%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  },
  lockIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "12%",
    left: "90%",
    transform: "translate(-50%, -50%)",
    background: colors.white,
    width: 40,
    height: 40,
    borderRadius: "50%",
    zIndex: 1,
  },
});

export default FbCardWithLayer;
