import React from "react";
import LearnMoreLink from "src/components/LearnMoreLink";

import { Box, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";

export const RefinanceIntro = ({ goToCompare, goToRefinance }: any) => {
  return (
    <Card style={{ maxWidth: 900, margin: "0 auto" }}>
      <Typography variant="body1">
        This tool allows you to estimate the savings of refinancing.
      </Typography>
      <Typography variant="body1">
        You will have the ability to compare refinancing vs your current loans
        or compare multiple refinance offers.{" "}
        <LearnMoreLink learnMoreKey="refinance" />{" "}
      </Typography>
      <Typography
        className="underline my-8 mx-auto text-center"
        variant="body1"
      >
        Choose one of the following:
      </Typography>
      <Box className="flex justify-center">
        <Button className="mr-8" fbColor="primary" onClick={goToRefinance}>
          Refinance Current Loans
        </Button>
        <Button fbColor="primary" onClick={goToCompare}>
          Compare Refinance Offers
        </Button>
      </Box>
    </Card>
  );
};

export default RefinanceIntro;
