import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, makeStyles } from "@material-ui/core";

import Card from "src/components/Card";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import AccountLinkPage from "./AccountLinkPage";
import colors from "src/theme/colors";
import {
  getAccountProviders as selectProviders,
  getAccountsLoaded,
} from "src/store/account/selector";
import { getAccountProviders } from "src/store/account/actions";
import { getRouterState } from "src/store/router/selector";

interface IAddAccountCard {
  className?: string;
  onAdd?: (manual: boolean) => void;
}

export const AddAccountCard = ({ className, onAdd }: IAddAccountCard) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const loaded = useSelector(getAccountsLoaded);
  const providers = useSelector(selectProviders);
  const router = useSelector(getRouterState);

  const [linkAccountDialogVisible, setLinkAccountDialogVisible] = useState(
    false
  );

  useEffect(() => {
    if (router.location.query?.action === "link") {
      setLinkAccountDialogVisible(true);
    }
  }, [router]);

  useEffect(() => {
    if ((!providers || !providers.length) && !loaded) {
      dispatch(getAccountProviders());
    }
  }, [dispatch]);

  const handleAddManual = () => {
    if (onAdd) {
      onAdd(true);
    }
  };

  return (
    <Card className={className} title="Accounts">
      <Box className="my-5">
        <Button
          fbColor="secondary"
          className={styles.button}
          onClick={() => setLinkAccountDialogVisible(true)}
        >
          <span style={{ height: 27, lineHeight: "27px" }}>
            Add Linked Account
          </span>
          <Icon iconName="fb-flow-chart" />
        </Button>
        <Button
          fbColor="primary"
          className={styles.button}
          onClick={handleAddManual}
        >
          <span style={{ height: 27, lineHeight: "27px" }}>
            Add Manual Account
          </span>
          <Icon iconName="fb-construction" />
        </Button>
      </Box>
      <AccountLinkPage
        isOpen={linkAccountDialogVisible}
        onClose={() => setLinkAccountDialogVisible(false)}
      />
    </Card>
  );
};

export default AddAccountCard;

const useStyles = makeStyles({
  button: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    margin: "7px 0",
  },
  lockIconContainer: {
    border: `1px solid ${colors.blueGray3}`,
    width: 27,
    height: 27,
    borderRadius: 28,
    padding: 4,
    "& svg": {
      color: colors.brandingBlue1,
      fontSize: 14,
    },
  },
});
