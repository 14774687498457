import * as React from "react";

import GeneralPage from "../GeneralPage";
import useData from "./data";

export const Investments = () => {
  const data = useData();
  return <GeneralPage data={data} title="Investment Tools and Products" />;
};

export default Investments;
