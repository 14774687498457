import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InlineWidget } from "react-calendly";

import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";

import Accordion from "src/components/Accordion";
import Button from "src/components/Button";
import Card from "src/components/Card";
import { Modal } from "src/components/Dialogs";
import Icon from "src/components/Icon";
import { getCalendlyLinkApi } from "src/apiService";
import { ActionItem } from "src/interfaces";
import { getActionItems } from "src/store/dashboard/selector";
import { hideActionItem } from "src/store/dashboard/actions";
import { PLAN_NOTIFICATION_MESSAGES } from "src/store/dashboard/planNotifications";
import colors from "src/theme/colors";
import { AdapterLink } from "src/utils";
import { LINK } from "src/store/dashboard/actionItems";
import { ALL_VIDEOS } from "src/store/dashboard/helpTopics";
import { getPlanNotifications } from "src/store/dashboard/selector";
import { getLivePlan } from "src/store/planBuild/selector";
import { getHasPlan, getIsCurrentStudent } from "src/store/system/selector";
import {
  // dismissAllPlanNotifications,
  setVideo,
} from "src/store/dashboard/actions";
import { ACTION_ITEM_IDS } from "src/store/dashboard/actionItems";
import AccountLinkPage from "src/pages/Dashboard/MyProfile/Accounts/components/AccountLinkPage";
import { useCSC } from "../hooks";
import UpdateManualAccountsDialog from "./UpdateManualAccountsDialog";

const useStyles = makeStyles({
  list: {},
  item: {},
  itemTitle: {
    fontWeight: 600,
  },
  complete: {
    color: colors.blueGray3,
    textDecoration: "line-through",
  },
  cardContent: {
    height: 260,
    overflowY: "auto",
  },
  icon: {
    // marginLeft: 14
  },
  iconComplete: {},
  content: {
    fontWeight: 400,
    fontSize: 12,
  },
  button: {
    padding: 3,
    width: 145,
  },
});

const ActionItems = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const actionItems: ActionItem[] = useSelector(getActionItems);
  const planNotifications = useSelector(getPlanNotifications);
  const hasPlan = useSelector(getHasPlan);
  const livePlan = useSelector(getLivePlan);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const { recheckItem } = useCSC();

  const [calendlyUrl, setCalendlyUrl] = useState("");
  const [accountUpdateDialogVisible, setAccountUpdateDialogVisible] = useState(
    false
  );
  const [relinkDialogVisible, setRelinkDialogVisible] = useState(false);

  const openCalendlyDialog = () => {
    getCalendlyLinkApi().then((result) => {
      let url = result.url + "?";
      if (result.prefill) {
        url += `name=${result.prefill.name}&email=${result.prefill.email}`;
        if (result.prefill.customAnswers) {
          url += `&a1=${result.prefill.customAnswers.a1}`;
        }
      }
      setCalendlyUrl(url);
    });
  };

  const openAccountUpdateDialog = () => setAccountUpdateDialogVisible(true);
  const openRelinkDialog = () => {
    setRelinkDialogVisible(true);
  };

  const actionData = {
    hasPlan,
    isCurrentStudent,
    livePlan,
    openCalendlyDialog,
    openAccountUpdateDialog,
    openRelinkDialog,
  };

  const renderButton = (item: ActionItem) => {
    const buttonProps: any = {
      className: styles.button,
      fbColor: "primary",
      variant: "outlined",
      onClick: (e: any) => {
        e.stopPropagation();
        if (item.action) {
          (item.action as any)(dispatch, actionData);
        }
      },
    };
    if (item.buttonType === LINK) {
      buttonProps.component = AdapterLink;
      buttonProps.to = item.target;
    }
    return <Button {...buttonProps}>{item.buttonLabel}</Button>;
  };

  const renderedPlanNotifications: any[] = [];
  if (planNotifications.length) {
    const item = planNotifications[0];
    const notification = PLAN_NOTIFICATION_MESSAGES.find(
      (found) => found.id === item.message
    );
    if (notification) {
      renderedPlanNotifications.push({
        disabled: false,
        title: (
          <Typography className={styles.itemTitle}>
            Plan progress update
          </Typography>
        ),
        // titleDetail: (
        //   <div className="flex items-center justify-end">
        //     <IconButton onClick={() => dispatch(dismissAllPlanNotifications())}>
        //       <Icon iconName="fa-circle-x" className={styles.icon} />
        //     </IconButton>
        //   </div>
        // ),
        content: (
          <Box className={styles.content}>
            {notification.message(item.data)}
          </Box>
        ),
      });
    }
  }

  // planNotifications.forEach((item: { id: number, message: number, data: any }) => {
  //   const notification = PLAN_NOTIFICATION_MESSAGES.find((found) => found.id === item.message);
  //   if (notification) {
  //     renderedPlanNotifications.push({
  //       disabled: false,
  //       title: <Typography className={styles.itemTitle}>Plan progress update</Typography>,
  //       titleDetail: (
  //         <div className="flex items-center justify-end">
  //           <IconButton onClick={() => dispatch(dismissPlanNotification(item.id))}>
  //             <Icon
  //               iconName="fb-checkmark-outline"
  //               className={styles.icon}
  //             />
  //           </IconButton>
  //         </div>
  //       ),
  //       content: <Box className={styles.content}>{notification.message(item.data)}</Box>,
  //     });
  //   }
  // });

  return (
    <Card title="Action Items" className="h-full">
      <Box className={styles.cardContent}>
        {/*providers.filter(provider => provider.new).map(provider => <Box key={provider.item_id} className="flex items-center justify-between px-4">
          <Typography className="font-bold">Your {provider.name} account is ready to be linked</Typography>
          <Box className="flex items-center justify-end">
            <Button
              className={styles.button}
              fbColor="primary"
              variant="outlined"
              disabled={loading}
              onClick={() =>handleConfirmLinkedAccount(provider)}
            >Confirm Account
            </Button>
            <Icon color={provider.new ? undefined : "primary"} iconName="fb-checkmark-outline" className={styles.icon} />
          </Box>
        </Box>)*/}
        <Accordion
          sections={[
            ...renderedPlanNotifications,
            ...actionItems.slice(0, 6).map((item) => ({
              title: (
                <Typography className={styles.itemTitle}>
                  {item.title}
                </Typography>
              ),
              titleDetail: (
                <div className="flex items-center justify-end">
                  {renderButton(item)}
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(hideActionItem(item.id));
                    }}
                  >
                    <Icon iconName="fa-circle-x" className={styles.icon} />
                  </IconButton>
                </div>
              ),
              content: (
                <p className={styles.content}>
                  {item.message}
                  {`actionItems_${item.id}` in ALL_VIDEOS && (
                    <>
                      {" "}
                      <a
                        href="#"
                        onClick={() =>
                          dispatch(setVideo(`actionItems_${item.id}`))
                        }
                      >
                        Learn More
                      </a>
                    </>
                  )}
                </p>
              ),
            })),
          ]}
        />
        {/*provider && (
          <ConfirmLinkedAccountDialog
            provider={provider}
            open={confirmAccountsDialogVisible}
            onClose={() => setConfirmAccountsDialogVisible(false)}
          />
        )*/}
      </Box>
      <Modal
        title=" "
        size="lg"
        isOpen={!!calendlyUrl}
        onClose={() => setCalendlyUrl("")}
      >
        {/* Override Calendly's default height of 630.
        646+ should remove the iframe's scroll bar.
        850 should show 9 timeslots on desktop */}
        <InlineWidget url={calendlyUrl} styles={{ height: 850 }} />
      </Modal>
      <UpdateManualAccountsDialog
        open={accountUpdateDialogVisible}
        onClose={() => {
          setAccountUpdateDialogVisible(false);
          recheckItem(ACTION_ITEM_IDS.ACCOUNT_UPDATE);
        }}
      />
      <AccountLinkPage
        isOpen={relinkDialogVisible}
        fixMode
        onClose={() => {
          setRelinkDialogVisible(false);
          recheckItem(ACTION_ITEM_IDS.ACCOUNT_RELINK);
        }}
      />
    </Card>
  );
};

export default ActionItems;
