import React, { useEffect, useState } from "react";

import { openSpinwheelComponent } from "src/spinwheel/functions";
import SpinwheelDialog from "src/spinwheel/SpinwheelDialog";

const SpinwheelContainer = ({
  next,
  who,
  module,
  exit,
  onEvent,
  extraOptions,
  onSettled,
  onError,
}: any) => {
  const [spinwheelLoading, setSpinwheelLoading] = useState(true);
  const [spinwheelOpen, setSpinwheelOpen] = useState(false);

  const loadSpinwheelDialog = () => {
    setSpinwheelOpen(true);
    openSpinwheelComponent({
      onEvent,
      onExit: exit,
      onLoad: () => setSpinwheelLoading(false),
      onError,
      onSettled,
      onSuccess: next,
      module,
      who,
      ...(extraOptions || {}),
    });
  };

  useEffect(() => {
    loadSpinwheelDialog();
  }, []);

  return (
    <SpinwheelDialog
      loading={spinwheelLoading}
      open={spinwheelOpen}
      onClose={exit}
    />
  );
};

export default SpinwheelContainer;
