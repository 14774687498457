import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { Box, CircularProgress, makeStyles } from "@material-ui/core";

import { creditCardPingApi } from "src/apiService";
import CalculatorLayout from "src/layouts/CalculatorLayout";
import SpinwheelContainer from "src/spinwheel/SpinwheelContainer";
import { setMiscState } from "src/store/dashboard/actions";
import { getMiscState } from "src/store/dashboard/selector";
import { getProfile, getSpouseProfile } from "src/store/profileBuild/selector";
import Introduction from "./Introduction";
import UserOrSpouse from "./UserOrSpouse";
import { getIsMarried } from "src/store/system/selector";

const useStyles = makeStyles({
  container: {
    margin: "20px auto",
    width: "90%",
    maxWidth: 1100,
  },
  loading: {
    margin: "120px auto",
    width: 0,
  },
});

const CONSOLIDATION_DISCLAIMER =
  "Disclaimer: Rates and pricing are subject to eligibility and underwriting criteria. Fitbux Services LLC has a business relationship and receives compensation if you complete a loan using one of the lenders on our platform. You do not have to complete a loan with a lender on Fitbux. You are free to shop lenders and Fitbux encourages you to do so. There are frequently other lenders available with similar services to those on our platform. Fitbux's relationship with each Lender is not an endorsement of any services, products, or service providers of the lending partners. Each Lender on Fitbux is a  separate independent entity and each Lender is not responsible for the financial condition, business, or legal obligations of the other Lenders. All services from our Lenders are their own. Once you reach them, Fitbux is not involved and has no control over the products of services involved.";

enum STEPS {
  INTRODUCTION,
  USER_OR_SPOUSE,
  IDENTITY_CONNECT,
  DEBT_CONNECT,
  PERSONAL_REFI,
}

const CreditCardConsolidation = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const isMarried = useSelector(getIsMarried);
  const miscState = useSelector(getMiscState);
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const [step, setStep] = useState(STEPS.INTRODUCTION);
  const [who, setWho] = useState("applicant");
  const [loading, setLoading] = useState(false);

  const profile = who === "spouse" ? spouseProfile : myProfile;

  useEffect(() => {
    creditCardPingApi();
  }, []);

  useEffect(() => {
    return () => {
      setStep(STEPS.INTRODUCTION);
      setWho("applicant");
    };
  }, []);

  const goToRefi = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStep(STEPS.PERSONAL_REFI);
    }, 4000);
  };

  const renderStep = () => {
    if (loading) {
      return (
        <Box className={styles.loading}>
          <CircularProgress size={150} />
        </Box>
      );
    }
    switch (step) {
      case STEPS.INTRODUCTION:
        return (
          <Introduction
            next={() => {
              if (isMarried) {
                setStep(STEPS.USER_OR_SPOUSE);
              } else if (miscState.applicant?.spinwheel_credit_report) {
                setStep(STEPS.PERSONAL_REFI);
              } else {
                setStep(STEPS.IDENTITY_CONNECT);
              }
            }}
          />
        );
      //   );
      case STEPS.USER_OR_SPOUSE:
        return (
          <UserOrSpouse
            next={(whoNext: string) => {
              setWho(whoNext);
              if (!miscState?.[whoNext]?.spinwheel_credit_report) {
                setStep(STEPS.IDENTITY_CONNECT);
              } else {
                setStep(STEPS.PERSONAL_REFI);
              }
            }}
          />
        );
      case STEPS.IDENTITY_CONNECT:
        return (
          <SpinwheelContainer
            key="identity"
            exit={() => setStep(STEPS.DEBT_CONNECT)}
            module="identity-connect"
            extraOptions={{
              month: profile.dob_month,
              day: profile.dob_day,
              year: profile.dob_year,
              phoneNumber: profile.phone_number,
            }}
            next={() => {
              dispatch(
                setMiscState({
                  ...miscState,
                  [who]: {
                    ...(miscState[who] || {}),
                    spinwheel_credit_report: true,
                  },
                })
              );
              goToRefi();
            }}
            who={who}
          />
        );
      case STEPS.DEBT_CONNECT:
        return (
          <SpinwheelContainer
            key="debt"
            exit={() => dispatch(push("/credit"))}
            module="debt-connect"
            next={() => {
              dispatch(
                setMiscState({
                  ...miscState,
                  [who]: {
                    ...(miscState[who] || {}),
                    spinwheel_credit_report: true,
                  },
                })
              );
              goToRefi();
            }}
            who={who}
          />
        );
      case STEPS.PERSONAL_REFI:
        return (
          <SpinwheelContainer
            key="refi"
            module="personal-refi"
            exit={() => dispatch(push("/credit"))}
            who={who}
          />
        );
    }
  };

  return (
    <CalculatorLayout
      title="Credit Card Consolidation"
      backTo="/credit"
      disclaimer={CONSOLIDATION_DISCLAIMER}
    >
      <Box className={styles.container}>{renderStep()}</Box>
    </CalculatorLayout>
  );
};

export default CreditCardConsolidation;
