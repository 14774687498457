import colors from "src/theme/colors";
import { theme } from "src/theme";

export const mainCardStyles: any = {
  root: {
    borderRadius: 12,
    marginBottom: 10,
  },
  container: {
    height: "90%",
    display: "grid",
    gridTemplateRows: "auto",
  },
  noContainer: {
    boxShadow: "none",
    "&>div": {
      padding: 0,
    },
  },
  content: {
    padding: 15,
    height: "100%",
  },
  icon: {
    color: colors.brandingBlue1,
    fontSize: 20,
    marginRight: 10,
  },
  icon2: {
    color: colors.brandingBlue2,
    fontSize: 20,
    marginRight: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.brandingBlue1,
  },
  title2: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.brandingBlue2,
  },
  button: {
    width: "100%",
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 13,
      fontWeight: 500,
    },
  },
  // outerTable: {
  //   height: "90%",
  //   overflow: "hidden"
  // },
  // table: {
  //   height: "100%",
  // },
  header: {
    padding: 5,
    height: 20,
    "&>div": {
      fontSize: 13,
      fontWeight: 500,
      color: colors.brandingBlue1,
      padding: "3px 10px",
    },
  },
  row: {
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    marginTop: 10,
    border: `1px solid ${colors.blueGray7}`,
    padding: `0 5px`,
    height: 40,
    "&.edit": {
      cursor: "pointer",
    },
    "&:hover": {
      "& button": {
        display: "block",
      },
    },
    "&.taller": {
      height: 60,
    },
    "&.selected": {
      "& button": {
        display: "block",
      },
      "& .select": {
        display: "block",
      },
      "& .text": {
        display: "none",
      },
      "& .input": {
        display: "block",
        "& :before": {
          borderBottom: "none",
        },
      },
    },
  },
  summaryRow: {
    display: "flex",
    margin: "8px 0px 12px",
    padding: "0 5px",
    width: "100%",
  },
  input: {
    "&:after": {
      borderBottom: "none",
    },
  },
  cell: {
    fontSize: 13,
    padding: "0px 10px",
    "&>.select": {
      height: `28px !important`,
      display: "none",
      borderRadius: 5,
      fontSize: 13,
      "&>div": {
        padding: `7px 10px`,
        width: `calc(100% - 20px)`,
      },
    },
    "&>*>.input": {
      height: `28px !important`,
      display: "none",
      borderRadius: 5,
      border: `1px solid ${colors.blueGray5}`,
      padding: "0 10px",
      width: `100%`,
    },
    "&>*>.min": {
      color: "rgb(226, 68, 92)",
    },
    "&.summary": {
      fontSize: 14,
    },
  },
  cellPrefix: {
    width: 40,
    minWidth: 40,
    "&>button": {
      boxShadow: "none",
      width: 50,
      minWidth: "auto",
      color: colors.brandingBlue2,
      display: "none",
    },
  },
  cellSuffix: {
    width: 40,
    minWidth: 40,
    textAlign: "center",
    "&>button": {
      display: "none",
    },
    "& svg": {
      fontSize: 20,
    },
  },
};
