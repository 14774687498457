import React from "react";
import { Grid } from "@material-ui/core";
import HeaderText from "src/components/HeaderText";
import { solutionsText } from "../data";

export const Header = () => {
  const { heading } = solutionsText;
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={10}
        className="flex justify-start items-center md:px-10"
      >
        <HeaderText heading={heading} description={"" /*description*/} />
      </Grid>
    </Grid>
  );
};
