import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { Grid } from "@material-ui/core";

import CustomDialog from "src/components/Dialogs/CustomDialog";
import { clearPopup, setPopupPlan } from "src/store/dashboard/actions";
import { getPopup, getPopupActive } from "src/store/dashboard/selector";
import BuildPopup from "./variants/BuildPopup";
import ComparePopup from "./variants/ComparePopup";
import PlanSelectPopup from "./variants/PlanSelectPopup";
import { implementPlan, setBuildStep } from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { getIsSubscribed } from "src/store/system/selector";
// import { setTutorial, setVideo } from "src/store/dashboard/actions";
import Icon from "../Icon";

const Popup = () => {
  const dispatch = useDispatch();
  const popup: string | null = useSelector(getPopup);
  const isPopupActive: boolean = useSelector(getPopupActive);
  const isSubscribed = useSelector(getIsSubscribed);

  const [popupIndex, setPopupIndex] = useState(0);

  const closeModal = (sendTo: any = false) => {
    dispatch(clearPopup());
    resetTransitionIndex();
    if (typeof sendTo === "string") {
      dispatch(push(`/${sendTo}`));
    }
  };

  // const pushToPlanSummary = () => {
  //   closeModal();
  //   dispatch(push("plan"));
  // };

  const pushToPlanPay = (planID: number | null = null) => {
    if (planID) {
      dispatch(setPopupPlan(planID));
    }
    dispatch(setBuildStep(PLAN_BUILD_STEPS.IMPLEMENT));
    closeModal(false);
  };

  const implement = (planID: number | null) => {
    if (popup === "Compare" && isSubscribed && planID) {
      dispatch(implementPlan(planID));
      // dispatch(setTutorial("post_subscription"));
      // dispatch(setVideo("post_subscription"));
      dispatch(push("/plan-list"));
    }
    if (planID) {
      dispatch(setPopupPlan(planID));
      transitionPopup();
    } else if (popup === "Logout") {
      setPopupIndex(2);
    }
  };

  const transitionPopup = () => {
    setPopupIndex(popupIndex + 1);
  };

  const resetTransitionIndex = () => {
    setPopupIndex(0);
  };

  const getPopupVariant = () => {
    if (popup === "Compare") {
      if (popupIndex === 0) {
        return (
          <ComparePopup close={closeModal} transitionPopup={transitionPopup} />
        );
      } else if (popupIndex === 1) {
        return <PlanSelectPopup implement={pushToPlanPay} />;
      }
    } else if (popup === "Logout") {
      if (popupIndex < 1) {
        return <PlanSelectPopup implement={implement} logout />;
      }
    } else {
      return <BuildPopup close={closeModal} transitionPopup={pushToPlanPay} />;
    }
  };

  return (
    <CustomDialog
      onClose={closeModal}
      isOpen={isPopupActive}
      size="md"
      title=""
    >
      <Icon
        style={{ float: "right" }}
        iconName={"fb-close"}
        className="-mt-1"
        onClick={closeModal}
      ></Icon>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          margin: "auto",
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        {getPopupVariant()}
        <br />
      </Grid>
    </CustomDialog>
  );
};

export default Popup;
