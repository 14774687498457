import React, { useState } from "react";
import { useSelector } from "react-redux";

import { FormLabel, Grid, makeStyles, Select } from "@material-ui/core";

import CustomDialog from "src/components/Dialogs/CustomDialog";
import Dropdown from "src/components/Dropdown";
import Button from "src/components/Button";
import { getIsMarried } from "src/store/system/selector";

import { DollarTextField } from "src/utils";

interface IState {
  title: string;
  list?: Array<{ label: string; value: string }>;
  renderList?: JSX.Element[];
  open: boolean;
  onAdd: (values: { type: string; annual: string; who?: string }) => void;
  onClose: () => void;
  showOwner?: boolean;
}

const AddItemDialog = ({
  title,
  list,
  renderList,
  open,
  onAdd,
  onClose,
  showOwner,
}: IState) => {
  const classes = useStyles();
  const [state, setState] = useState({
    type: "",
    annual: "",
    monthly: "",
    who: "applicant",
  });
  const married = useSelector(getIsMarried);

  const handleChange = (event: React.ChangeEvent<any>) => {
    const value = event.target.value;
    const field = event.target.name;
    const newState: any = { ...state, [field]: value };
    if (field === "annual") {
      newState.monthly = Math.round(value / 12);
    } else if (field === "monthly") {
      newState.annual = value * 12;
    }
    setState(newState);
  };
  const { annual, monthly, type, who } = state;

  return (
    <CustomDialog title={title} size="sm" isOpen={open} onClose={onClose}>
      <Grid container spacing={2} className={classes.container}>
        {showOwner && married && (
          <Grid item xs={12}>
            <Dropdown
              placeholder="Select"
              onChange={handleChange}
              name="who"
              value={who}
              items={[
                {
                  label: "Mine",
                  value: "applicant",
                },
                {
                  label: "Spouse",
                  value: "spouse",
                },
              ]}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {!!list && (
            <Dropdown
              placeholder="Select"
              onChange={handleChange}
              name="type"
              value={type || ""}
              items={[{ label: "", value: "" }, ...list]}
            />
          )}
          {!!renderList && (
            <>
              <FormLabel className="mt-4 mb-2">Type</FormLabel>
              <Select
                className="mt-2"
                fullWidth
                variant="outlined"
                placeholder="Select type"
                onChange={handleChange}
                name="type"
                value={type || ""}
              >
                {renderList}
              </Select>
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          <DollarTextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="annual"
            label="Annual"
            name="annual"
            onChange={handleChange}
            value={annual}
          />
        </Grid>
        <Grid item xs={6}>
          <DollarTextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="monthly"
            label="Monthly"
            name="monthly"
            onChange={handleChange}
            value={monthly}
          />
        </Grid>
        <Grid item xs={12} className={classes.footer}>
          <p />
          <div className={classes.buttons}>
            <Button className="mr-4" fbColor="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              fbColor="primary"
              disabled={!type || !monthly}
              onClick={() => {
                onAdd(state);
                setState({
                  type: "",
                  annual: "",
                  monthly: "",
                  who: "applicant",
                });
              }}
            >
              Add
            </Button>
          </div>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

const useStyles = makeStyles({
  container: {
    padding: "20px 6px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
});

export default AddItemDialog;
