import React, { useCallback, useEffect, useState, useRef } from "react";
import { debounce, map } from "lodash";

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

import Icon from "src/components/Icon";
import { NOT_APPLICABLE } from "src/constants";
import colors from "src/theme/colors";
import { theme } from "src/theme";
import { formatWholeDollars } from "src/utils";
import { DollarTextField, PercentTextField } from "src/utils/inputMask";

interface MainCardProps {
  addItems?: any;
  addLabel?: string;
  allowSelect?: boolean;
  defaultNewItem?: any;
  disableDelete?: boolean;
  enableDeleteFixed?: boolean;
  iconName?: string;
  loading?: boolean;
  title: string;
  noContainer?: boolean;
  hideTitle?: boolean;
  columns: Array<any>;
  data: Array<any>;
  className?: string;
  fixedRows?: Array<number>;
  hideHeaders?: boolean;
  onAdd?: VoidFunction;
  addTooltip?: string;
  disableAdd?: boolean;
  disableEditFields?: string[];
  onAddFromSelect?: (item: any, key?: any) => void;
  onCancelEdit?: VoidFunction;
  onDelete?: (item: any, index: number) => void;
  onEdit?: (item: any, index: number) => void;
  onSave: (item: any, update: any, index: number) => void;
  specialEdit?: {
    check: (item: any, index: number) => boolean;
    handle: (item: any, index: number) => void;
  };
  saveOnChange?: boolean;
  summaryRow?: any;
  disableSave?: boolean;
  setDirtyFlag?: VoidFunction;
}

const MainCard = ({
  addLabel,
  allowSelect,
  iconName,
  title,
  hideTitle,
  columns,
  data,
  disableDelete,
  enableDeleteFixed,
  fixedRows,
  className,
  addItems,
  hideHeaders,
  defaultNewItem,
  addTooltip,
  disableAdd,
  disableEditFields,
  onAdd,
  onAddFromSelect,
  onCancelEdit,
  onDelete,
  onSave,
  specialEdit,
  summaryRow,
  saveOnChange,
  noContainer,
  setDirtyFlag,
}: MainCardProps) => {
  const styles = useStyles();

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [changed, setChanged] = useState<boolean>(false);
  const [isNew, setNew] = useState<boolean>(false);
  const [newValues, setNewValues] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState(null);
  // const [editableColumns, setEditableColumns] = useState<string[]>([]);
  const persistChanged = useRef(false);
  const persistIndex = useRef(-1);
  const persistItem = useRef(data[0] || null);
  const persistValues = useRef<any>({});

  const save = () => {
    setChanged(false);
    onSave(persistItem.current, persistValues.current, persistIndex.current);
  };

  const quickSave = useCallback(debounce(save, 800, { leading: false }), []);

  useEffect(() => {
    persistChanged.current = changed;
    if (changed && setDirtyFlag) {
      setDirtyFlag();
    }
  }, [changed, setDirtyFlag]);

  useEffect(() => {
    persistIndex.current = selectedIndex;
    persistItem.current = data[selectedIndex] || null;
  }, [selectedIndex, data]);

  useEffect(() => {
    persistValues.current = newValues;
    if (saveOnChange && persistChanged.current) {
      quickSave();
    }
  }, [newValues]);

  useEffect(() => {
    return () => {
      onSelectOrSave(-1);
    };
  }, []);

  const openAddMenu = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeAddMenu = () => {
    setAnchorEl(null);
  };

  const handleAddItem = (item: any, key?: any) => {
    if (selectedIndex >= 0) {
      onSelectOrSave(selectedIndex);
    }
    setSelectedIndex(data.length);
    setNew(() => true);
    if (onAddFromSelect) {
      onAddFromSelect(item, key);
      setNewValues({ ...item });
      setChanged(false);
      closeAddMenu();
    } else if (onAdd) {
      if (defaultNewItem) {
        setNewValues({ ...defaultNewItem });
      }
      onAdd();
    }
  };

  const handleEnterKey = (e: React.KeyboardEvent<any>) => {
    if (e.key === "Enter" && changed) {
      save();
    }
  };

  const onSelectOrSave = (i: number) => {
    setNew(() => false);
    if (persistChanged.current) {
      save();
    } else {
      if (onCancelEdit) {
        onCancelEdit();
      }
    }
    if (i < 0) {
      return;
    }
    if (i === selectedIndex) {
      setNewValues({});
      setSelectedIndex(-1);
    } else {
      if (specialEdit && specialEdit.check(data[i], i)) {
        setSelectedIndex(-1);
        return specialEdit.handle(data[i], i);
      }
      setNewValues({ ...data[i] });
      const newEditableColumns: string[] = [];
      columns.forEach((column) => {
        if (
          column.type === "fixed" ||
          (column.formatter &&
            column.formatter(data[i][column.field]) === NOT_APPLICABLE) ||
          (column.type === "select" && !allowSelect) ||
          (disableEditFields && disableEditFields.indexOf(column.field) >= 0)
        ) {
          return true;
        }
        newEditableColumns.push(column.field);
      });
      // setEditableColumns(newEditableColumns);
      setSelectedIndex(i);
    }
  };

  const updateField = (field: string) => (e: React.ChangeEvent<any>) => {
    let value = e.target.value;
    if (value || newValues[field]) {
      setNew(false);
      setChanged(true);
    }
    if (!isNaN(+value)) {
      value = +value;
    }
    const update = { ...newValues, [field]: value };
    if (field === "annual") {
      update["monthly"] = Math.floor(value / 12);
    } else if (field === "monthly") {
      update["annual"] = value * 12;
    }
    setNewValues(update);
  };

  const renderCell = (column: any, row: any, values: any, i: number) => {
    const enableEdit =
      selectedIndex === i &&
      column.type !== "fixed" &&
      (!disableEditFields || disableEditFields.indexOf(column.field) < 0);
    let renderValue = values[column.field];
    if (values[`${column.field}Label`]) {
      renderValue = values[`${column.field}Label`];
    } else if (values[`${column.field}Items`]) {
      renderValue = column.items[values[column.field]];
    }
    if (column.formatter) {
      const formattedValue = column.formatter(renderValue);
      if (formattedValue === "N/A" && enableEdit) {
        renderValue = 0;
      } else {
        renderValue = formattedValue;
      }
    }
    if (!enableEdit || renderValue === "N/A") {
      return <span>{renderValue}</span>;
    }
    if (enableEdit && column.type === "select" && (allowSelect || row.id < 0)) {
      let items: any = column.items;
      if (items instanceof Function) {
        items = items(row);
      }
      return (
        <Select
          value={values[column.field] || ""}
          displayEmpty
          className="select"
          variant="outlined"
          onChange={updateField(column.field)}
          margin="dense"
        >
          {!!column.items &&
            map(items, (label, value) => {
              const isArray = Array.isArray(items);
              let renderLabel = label;
              if (column.selectLabelFormat) {
                renderLabel = column.selectLabelFormat(values, label);
              }
              return (
                <MenuItem
                  key={isArray ? label : value}
                  value={isArray ? label : value}
                >
                  {renderLabel}
                </MenuItem>
              );
            })}
        </Select>
      );
    }
    if (enableEdit && column.type === "number") {
      const min = row[`min_${column.field}`];
      return (
        <>
          <DollarTextField
            className="input"
            placeholder="$"
            onChange={updateField(column.field)}
            onKeyDown={handleEnterKey}
            value={
              typeof values[column.field] === "number"
                ? values[column.field] || ""
                : ""
            }
          />
          {!!min && (
            <Typography className="min" variant="caption">
              Min: {formatWholeDollars(min)}
            </Typography>
          )}
        </>
      );
    }
    if (enableEdit && column.type === "plainNumber") {
      return (
        <TextField
          className="input"
          onChange={updateField(column.field)}
          onKeyDown={handleEnterKey}
          value={"" + values[column.field] || ""}
        />
      );
    }
    if (enableEdit && column.type === "percent") {
      return (
        <>
          <PercentTextField
            className="input"
            placeholder="%"
            onChange={updateField(column.field)}
            onKeyDown={handleEnterKey}
            value={
              typeof values[column.field] === "number"
                ? values[column.field]
                : ""
            }
          />
          {!!row.min && (
            <Typography className="min" variant="caption">
              Min: {row.min.toFixed(2)}%
            </Typography>
          )}
        </>
      );
    }
  };

  const renderAddButton = () => {
    const disabled = disableAdd || isNew;
    const button = addItems ? (
      <>
        <Button
          aria-controls={`add-${title}-menu`}
          aria-haspopup="true"
          variant="outlined"
          className={styles.button}
          disabled={disabled}
          color="primary"
          endIcon={<Icon iconName="fb-add-alt" />}
          onClick={openAddMenu}
        >
          {addLabel || `Add ${title}`}
        </Button>
        <Menu
          id={`add-${title}-menu`}
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={closeAddMenu}
        >
          {map(addItems, (item: any, key: any) => (
            <MenuItem key={key} onClick={() => handleAddItem(item, key)}>
              {typeof item === "string" ? item : item.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    ) : (
      <Button
        variant="outlined"
        disabled={disabled}
        className={styles.button}
        color="primary"
        endIcon={<Icon iconName="fb-add-alt" />}
        onClick={handleAddItem}
      >
        {addLabel || `Add ${title}`}
      </Button>
    );
    if (addTooltip) {
      return (
        <Tooltip title={addTooltip}>
          <span>{button}</span>
        </Tooltip>
      );
    }
    return button;
  };

  const renderEditButton = (i: number) => (
    <Button color="primary" onClick={() => onSelectOrSave(i)}>
      {selectedIndex === i ? "Done" : "Edit"}
    </Button>
  );

  return (
    <Card
      className={
        className
          ? `${styles.root} ${className}`
          : `${styles.root} ${noContainer ? styles.noContainer : ""}`
      }
    >
      <CardContent className={styles.content}>
        {!hideTitle && (
          <Box className="flex mb-4">
            {iconName && <Icon iconName={iconName} className={styles.icon} />}
            <Typography className={styles.title}>{title}</Typography>
          </Box>
        )}
        <List className={styles.table}>
          {!hideHeaders && (
            <ListItem className={styles.header}>
              <Box className={styles.cellPrefix}></Box>
              {columns.map((column, i) => (
                <Box
                  key={i}
                  style={{ width: column.width || `${100 / columns.length}%` }}
                >
                  {column.label}
                </Box>
              ))}
              <Box className={styles.cellSuffix}></Box>
            </ListItem>
          )}
          {data.map((row, i) => {
            const values: any =
              changed && selectedIndex === i ? { ...row, ...newValues } : row;
            const selected = selectedIndex === i;
            const className = clsx(
              styles.row,
              selected && "selected",
              selected && !!row.min && "taller"
            );
            return (
              <ListItem key={JSON.stringify(row)} className={className}>
                <Box className={styles.cellPrefix}>
                  {(!fixedRows || fixedRows.indexOf(i) < 0) &&
                    renderEditButton(i)}
                </Box>
                {columns.map((column) => (
                  <Box
                    className={styles.cell}
                    key={column.field}
                    style={{
                      width: column.width || `${100 / columns.length}%`,
                    }}
                  >
                    {renderCell(column, row, values, i)}
                  </Box>
                ))}
                <Box className={styles.cellSuffix}>
                  {!disableDelete &&
                    (!fixedRows ||
                      fixedRows.indexOf(i) < 0 ||
                      enableDeleteFixed) && (
                      <IconButton
                        color="primary"
                        onClick={() => {
                          if (selectedIndex === i) {
                            setSelectedIndex(-1);
                            setNew(false);
                          }
                          return onDelete ? onDelete(row, i) : null;
                        }}
                      >
                        <Icon iconName="fb-trash-can" />
                      </IconButton>
                    )}
                </Box>
              </ListItem>
            );
          })}
        </List>
        {!!summaryRow && (
          <>
            <Divider className="mt-4" />
            <Box className={styles.summaryRow}>
              <Box className={styles.cellPrefix} />
              {columns.map((column, i) => {
                let value = summaryRow[column.field] || "";
                if (column.formatter) {
                  if (!value) {
                    value = 0;
                  }
                  value = column.formatter(value);
                }
                const style: any = {
                  width: column.width || `${100 / columns.length}%`,
                };
                return (
                  <Box
                    className={`${styles.cell} summary`}
                    key={column.field}
                    style={style}
                  >
                    {!i ? (
                      <Box style={{ marginLeft: -35 }}>{value}</Box>
                    ) : (
                      value
                    )}
                  </Box>
                );
              })}
              <Box className={styles.cellSuffix} />
            </Box>
          </>
        )}
        <Grid container spacing={2} className="mt-0">
          <Grid item xs={12}>
            {renderAddButton()}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MainCard;

export const mainCardStyles: any = {
  root: {
    borderRadius: 12,
    marginBottom: 10,
  },
  noContainer: {
    boxShadow: "none",
    "&>div": {
      padding: 0,
    },
  },
  content: {
    padding: 15,
  },
  icon: {
    color: colors.brandingBlue1,
    fontSize: 20,
    marginRight: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.brandingBlue1,
  },
  button: {
    width: "100%",
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
  table: {},
  header: {
    padding: 5,
    height: 20,
    "&>div": {
      fontSize: 12,
      fontWeight: 500,
      color: colors.brandingBlue1,
      padding: "3px 10px",
    },
  },
  row: {
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    marginTop: 10,
    border: `1px solid ${colors.blueGray7}`,
    padding: `0 5px`,
    height: 40,
    "&:hover": {
      "& button": {
        display: "block",
      },
    },
    "&.taller": {
      height: 60,
    },
    "&.selected": {
      "& button": {
        display: "block",
      },
      "& .select": {
        display: "block",
      },
      "& .text": {
        display: "none",
      },
      "& .input": {
        display: "block",
        "& :before": {
          borderBottom: "none",
        },
      },
    },
  },
  summaryRow: {
    display: "flex",
    margin: "8px 0px 12px",
    padding: "0 5px",
    width: "100%",
  },
  input: {
    "&:after": {
      borderBottom: "none",
    },
  },
  cell: {
    fontSize: 12,
    padding: "0px 10px",
    "&>.select": {
      height: `28px !important`,
      display: "none",
      borderRadius: 5,
      fontSize: 12,
      "&>div": {
        padding: `7px 10px`,
        width: `calc(100% - 20px)`,
      },
    },
    "&>.input": {
      height: `28px !important`,
      display: "none",
      borderRadius: 5,
      border: `1px solid ${colors.blueGray5}`,
      padding: "0 10px",
      width: `100%`,
    },
    "&>.min": {
      color: "rgb(226, 68, 92)",
    },
    "&.summary": {
      fontSize: 15,
    },
  },
  cellPrefix: {
    width: 40,
    minWidth: 40,
    "&>button": {
      boxShadow: "none",
      width: 50,
      minWidth: "auto",
      color: colors.brandingBlue2,
      display: "none",
    },
  },
  cellSuffix: {
    width: 40,
    minWidth: 40,
    textAlign: "center",
    "&>button": {
      display: "none",
    },
    "& svg": {
      fontSize: 20,
    },
  },
};

const useStyles = makeStyles(mainCardStyles);
