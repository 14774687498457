import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import Card from "src/components/Card";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { MONTHS } from "src/constants";
import Colors from "src/theme/colors";
import { chartStyles } from "src/theme/base";
import LockedLayer from "src/components/LockedLayer";
const useStyles = makeStyles(chartStyles);

const now = new Date();
const nowMonth = now.getMonth() + 1;
const nowYear = now.getFullYear();

const monthYearFormatter = (monthIndex: number) => {
  const targetMonth = monthIndex + nowMonth;
  const monthOffset = targetMonth % 12;
  const yearOffset = Math.floor(targetMonth / 12);
  return `${MONTHS[monthOffset]} ${nowYear + yearOffset}`;
};

const yearFormatter = (monthIndex: number) => {
  const yearOffset = Math.floor((monthIndex + nowMonth) / 12);
  return "" + (nowYear + yearOffset);
};

export const Chart = ({
  actions,
  centerTitle,
  colors,
  dollars,
  elevation,
  title,
  headComponent,
  noTitlePadding,
  data,
  loading,
  keys,
  xKey,
  xMargin,
  xInterval,
  xYearOnly,
  height,
  isLocked,
  lockedButton,
  btnLabel,
  keyLabels,
  width,
  yLabelMargin,
  yScale,
  yMargin,
}: any) => {
  const defaultColors = [
    Colors.brandingBlue1,
    Colors.brandingBlue2,
    Colors.warn,
  ];
  const displayColors = colors || defaultColors;
  const classes = useStyles();

  const CustomTooltip = ({ active, payload, label, keyLabels }: any) => {
    const classes = useStyles();
    if (active && payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          <h5 className="fb-chart-tooltip-title">
            {xYearOnly ? yearFormatter(label) : monthYearFormatter(label)}
          </h5>
          <ul>
            {payload.map((e: any) => (
              <li key={e.dataKey}>
                <span style={{ backgroundColor: e.stroke }}></span>
                {`${(keyLabels || {})[e.dataKey] || [e.dataKey]}`}
                <strong>{`${kFormatter(e.value)}`}</strong>
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return null;
  };

  const kFormatter = (value: number) => {
    if (!value) {
      return "";
    }
    let result = "" + value;
    if (Math.abs(value) > 999999) {
      result = Math.abs(value / 1e6).toFixed(3) + "M";
    } else if (Math.abs(value) > 999) {
      result = Math.abs(value / 1e3).toFixed(0) + "k";
    } else {
      result = Math.abs(value).toFixed(0);
    }
    if (dollars) {
      result = "$" + result;
    }
    if (value < 0) {
      result = "-" + result;
    }
    return result;
  };

  return (
    <LockedLayer btnLabel={btnLabel} button={lockedButton} isLocked={isLocked}>
      <Card
        centerTitle={centerTitle}
        elevation={elevation}
        title={title}
        headComponent={headComponent}
        noPadding
        className={classes.container}
        style={width ? { width } : undefined}
        titleClassName="p-0"
        noTitlePadding={noTitlePadding}
      >
        <div
          className={height ? "" : "h-60"}
          style={height ? { height } : undefined}
        >
          <ResponsiveContainer width="100%" height="101%">
            {loading ? (
              <div style={{ padding: "50px 120px 0" }}>
                <CircularProgress
                  size={100}
                  color="secondary"
                  thickness={0.5}
                />
              </div>
            ) : (
              <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: yMargin || 0,
                  right: xMargin ? xMargin / 2 : 0,
                  left: xMargin || 0,
                  bottom: 40,
                }}
              >
                <defs>
                  <filter
                    id="blurFilter"
                    x="0"
                    y="0"
                    width="200%"
                    height="200%"
                  >
                    <feOffset
                      result="offOut"
                      in="SourceGraphic"
                      dx="0"
                      dy="3"
                    />
                    <feGaussianBlur
                      result="blurOut"
                      in="offOut"
                      stdDeviation="2"
                    />
                    <feBlend in="SourceGraphic" in2="blurOut" mode="multiply" />
                  </filter>
                </defs>
                <XAxis
                  axisLine={false}
                  dataKey={xKey}
                  tickMargin={10}
                  height={0.1}
                  interval={xInterval || undefined}
                  tick={{ fontSize: 10 }}
                  tickFormatter={(i) => yearFormatter(i)}
                  tickLine={false}
                />
                <YAxis
                  axisLine={false}
                  tickMargin={
                    -60 + (yLabelMargin || (xMargin ? xMargin + 20 : 0))
                  }
                  scale={yScale || "auto"}
                  domain={yScale === "log" ? ["auto", "auto"] : undefined}
                  width={0.1}
                  tick={{ fontSize: 10 }}
                  tickFormatter={kFormatter}
                />
                <Tooltip content={<CustomTooltip keyLabels={keyLabels} />} />
                {!!keys &&
                  keys.map((key: string, index: number) => (
                    <Area
                      key={key}
                      type="monotone"
                      dataKey={key}
                      stroke={displayColors[index]}
                      fill="transparent"
                    />
                  ))}
              </AreaChart>
            )}
          </ResponsiveContainer>
        </div>
        {actions || null}
      </Card>
    </LockedLayer>
  );
};

export default Chart;
