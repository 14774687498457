import React from "react";
import { Grid } from "@material-ui/core";

import { GeneralPageData } from "src/interfaces";

import GeneralCard from "./GeneralCard";

export interface GeneralPageContentProps {
  data: GeneralPageData;
  setDialogData: any;
}

export const Content = ({ data, setDialogData }: GeneralPageContentProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <GeneralCard
          title="Calculators"
          setDialogData={setDialogData}
          {...data.calculators}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <GeneralCard
          title="AI-Powered Tools"
          setDialogData={setDialogData}
          {...data.answers}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <GeneralCard
          title="Products"
          setDialogData={setDialogData}
          {...data.products}
        />
      </Grid>
    </Grid>
  );
};
