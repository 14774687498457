import React, { useMemo } from "react";

import {
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import { PayoffAccountsPayload, PayoffAccountsResponse } from "src/apiService";
import Button from "src/components/Button";
import { formatPercent, formatWholeDollars } from "src/utils";

interface PayoffAccountsProps {
  loans: PayoffAccountsResponse[];
  totals: PayoffAccountsResponse;
  defaultExtendTerms: any;
  selectedTerms: any;
  setSelectedTerms: any;
  update: (payload?: PayoffAccountsPayload) => Promise<void>;
  getPayment: (loan: PayoffAccountsResponse) => number;
  onNext: VoidFunction;
  goBack?: VoidFunction;
}

const useStyles = makeStyles({
  root: {
    margin: "0 auto",
    maxWidth: 900,
    padding: 20,
  },
  textCell: {
    padding: "0 5px 0 0",
    wordBreak: "break-word",
  },
  disclaimer: {
    position: "fixed",
    bottom: 40,
    fontSize: 12,
  },
});

export const PayoffAccounts = ({
  loans,
  defaultExtendTerms,
  selectedTerms,
  setSelectedTerms,
  update,
  getPayment,
  onNext,
  goBack,
}: PayoffAccountsProps) => {
  const styles = useStyles();
  const updateTerm = (loan: PayoffAccountsResponse, term: number) => {
    const newTerm = term === 0 ? loan.term : term;
    const newSelectedTerms = { ...selectedTerms, ["" + loan.id]: newTerm };
    setSelectedTerms(newSelectedTerms);
    update({ selectedTerms: newSelectedTerms, defaultExtendTerms });
  };

  const updateAllTerms = (term: number) => {
    const newSelectedTerms: any = {};
    loans.forEach((loan) => {
      if (loan.type === "federal") {
        newSelectedTerms["" + loan.id] = term || loan.term;
      }
    });
    setSelectedTerms(newSelectedTerms);
    update({ selectedTerms: newSelectedTerms, defaultExtendTerms });
  };

  const getTerm = (loan: PayoffAccountsResponse) => {
    const term = selectedTerms["" + loan.id];
    return term || loan.term;
  };

  const { totalBalance, weightedInterest, totalPayment } = useMemo(() => {
    let totalBalance = 0;
    let totalInterest = 0;
    let totalPayment = 0;
    loans.forEach((loan) => {
      totalBalance += loan.balance;
      totalInterest += loan.balance * loan.interest;
      totalPayment += getPayment(loan);
    });
    return {
      totalBalance,
      totalPayment,
      weightedInterest: totalInterest / totalBalance,
    };
  }, [loans, selectedTerms]);

  return (
    <Paper className={styles.root}>
      <Typography variant="body1">
        Customize the term for each or all of the loans if you want to see the
        impact of Extending your Loan(s).
      </Typography>
      <TableContainer className="mb-3">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell className={styles.textCell}>Loan Type</TableCell>
              <TableCell className={styles.textCell}>Whose loan?</TableCell>
              <TableCell className={styles.textCell}>Current Balance</TableCell>
              <TableCell className={styles.textCell}>Interest Rate</TableCell>
              <TableCell className={styles.textCell}>Monthly Payment</TableCell>
              <TableCell className={styles.textCell}>Term</TableCell>
              <TableCell className={styles.textCell}>
                Extend to
                <Select
                  variant="outlined"
                  value={"all"}
                  className="mb-2"
                  onChange={(e: any) => updateAllTerms(e.target.value)}
                >
                  <MenuItem value="all">Apply All</MenuItem>
                  <MenuItem value={0}>No Change</MenuItem>
                  <MenuItem value={25}>25 Years</MenuItem>
                  <MenuItem value={20}>20 Years</MenuItem>
                  <MenuItem value={15}>15 Years</MenuItem>
                  <MenuItem value={10}>10 Years</MenuItem>
                  <MenuItem value={5}>5 Years</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loans.map((loan, index) => (
              <TableRow key={loan.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell className="capitalize">{loan.type}</TableCell>
                <TableCell className="capitalize">{loan.whose}</TableCell>
                <TableCell>{formatWholeDollars(loan.balance)}</TableCell>
                <TableCell>{formatPercent(loan.interest)}</TableCell>
                <TableCell>{formatWholeDollars(getPayment(loan))}</TableCell>
                <TableCell>{getTerm(loan)} Years</TableCell>
                <TableCell>
                  {loan.type === "federal" && (
                    <Select
                      variant="outlined"
                      value={selectedTerms[loan.id] || loan.term}
                      onChange={(e: any) => updateTerm(loan, e.target.value)}
                    >
                      <MenuItem value={0}>No Change</MenuItem>
                      <MenuItem value={25}>25 Years</MenuItem>
                      <MenuItem value={20}>20 Years</MenuItem>
                      <MenuItem value={15}>15 Years</MenuItem>
                      <MenuItem value={10}>10 Years</MenuItem>
                      <MenuItem value={5}>5 Years</MenuItem>
                    </Select>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell />
              <TableCell />
              <TableCell>{formatWholeDollars(totalBalance)}</TableCell>
              <TableCell>{formatPercent(weightedInterest)}</TableCell>
              <TableCell>{formatWholeDollars(totalPayment)}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="caption" className="mt-3">
        Disclaimer: This calculator assumes fixed interest rate loans under a
        straight-line amortization schedule. It does not take into account
        graduated repayment loans or customized loan repayment schedules. If you
        have questions regarding your specific loans, contact your FitBUX coach.
      </Typography>
      <Button fbColor="primary" onClick={onNext} fullWidth className="my-4">
        Next
      </Button>
      {!!goBack && (
        <Button fbColor="secondary" onClick={goBack} fullWidth>
          Back
        </Button>
      )}
    </Paper>
  );
};

export default PayoffAccounts;
