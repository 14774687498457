import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import Icon from "src/components/Icon";
import { colors, toolsStyles } from "src/theme";
import {
  formatDollarsAndCents,
  formatPercent,
  PercentTextField,
} from "src/utils";
import AddAllocationDialog from "./AddAllocationDialog";

const useStyles = makeStyles({
  ...toolsStyles,
  inputCardContent: {
    display: "flex",
    justifyContent: "center",
  },
  tableContainer: {
    width: "100%",
  },
  headerCell: {
    background: "white",
    paddingLeft: 10,
  },
  noBorderCell: {
    fontSize: 12,
    paddingBottom: 13,
    paddingLeft: 10,
    paddingTop: 13,
    border: "none",
  },
  noPadCell: {
    fontSize: 12,
    paddingBottom: 1,
    paddingLeft: 10,
    paddingTop: 1,
    border: "none",
  },
  topCell: {
    fontSize: 12,
    paddingBottom: 1,
    paddingLeft: 10,
    paddingTop: 7,
    border: "none",
  },
  totalCell: {
    borderBottom: 0,
    borderTop: `1px solid ${colors.brandingBlue1}`,
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: 10,
  },
  addButton: {
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
});

export const AnalysisInput = ({
  allocations,
  addAllocation,
  editAllocation,
  removeAllocation,
  reset,
  tickerBalances,
  total,
  allocationTotal,
  updateBalances,
}: any) => {
  const classes = useStyles();
  const allocationsTableRef = useRef<any>(null);
  const allocationsLengthRef = useRef<any>(
    allocations ? Object.keys(allocations).length : 0
  );
  const [addDialogVisible, setAddDialogVisible] = useState(false);

  const openAddDialog = () => setAddDialogVisible(true);
  const closeAddDialog = () => setAddDialogVisible(false);

  useEffect(() => {
    const allocationsLength = Object.keys(allocations).length;
    if (
      allocationsLengthRef.current &&
      allocationsLength > allocationsLengthRef.current &&
      allocationsTableRef.current
    ) {
      (window as any)["allocations"] = allocationsTableRef.current;
      allocationsTableRef.current.scroll({
        top: allocationsTableRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    allocationsLengthRef.current = allocationsLength;
  }, [allocations]);

  return (
    <>
      <Card className="mb-5">
        <Typography variant="h2" className={classes.toolsHeader}>
          Analyzing My Investment Allocations
        </Typography>
        <Typography variant="body1">
          Make adjustments to your current investment allocations to see the
          impact on risk and expected returns.
        </Typography>
        <Typography variant="h2" className={classes.toolsHeader}>
          Steps
        </Typography>
        <ul>
          <li>
            Change your allocations under "Updated Allocations," then click "Run
            Analysis." To run the tool, your updated allocations must sum to
            100%.
          </li>
          <li>
            If you do not see your investment details, go to the Accounts tab in
            your profile, add an account or click Edit on appropriate account,
            then click "Add investment."
          </li>
          <li>
            If you currently don't have investable assets such as a 401k or Roth
            IRA, simply input the allocations under "Updated Allocations," then
            click "Run Analysis."
          </li>
        </ul>
      </Card>
      <Box className={classes.inputCardContent}>
        <Card
          title="Current Allocations"
          className="mr-4"
          style={{ width: "calc(50% - 20px)" }}
        >
          <Box className={classes.tableContainer}>
            <TableContainer style={{ maxHeight: 400 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerCell}>Ticker</TableCell>
                    <TableCell className={classes.headerCell}>Amount</TableCell>
                    <TableCell className={classes.headerCell}>
                      Allocation %
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tickerBalances.map((item: any) => (
                    <TableRow key={item.ticker}>
                      <TableCell className={classes.noBorderCell}>
                        {item.ticker.split(".")[0]}
                      </TableCell>
                      <TableCell className={classes.noBorderCell}>
                        {formatDollarsAndCents(item.amount)}
                      </TableCell>
                      <TableCell className={classes.noBorderCell}>
                        {formatPercent(item.allocation)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table className="mt-3" style={{ width: "calc(100% - 20px)" }}>
              <TableFooter>
                <TableRow>
                  <TableCell className={classes.totalCell}>Total</TableCell>
                  <TableCell className={classes.totalCell}>
                    {formatDollarsAndCents(total)}
                  </TableCell>
                  <TableCell className={classes.totalCell}>100.00%</TableCell>
                </TableRow>
              </TableFooter>
            </Table>
            <Box className="flex justify-around">
              <Button
                variant="outlined"
                className="flex items-center justify-around"
                fbColor="primary"
                onClick={updateBalances}
              >
                Update My Current Investments
              </Button>
            </Box>
          </Box>
        </Card>
        <Card title="Updated Allocations" style={{ width: "calc(50% - 20px)" }}>
          <div className={classes.tableContainer}>
            <TableContainer
              style={{ maxHeight: 400 }}
              ref={allocationsTableRef}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerCell}>Ticker</TableCell>
                    <TableCell className={classes.headerCell}>Amount</TableCell>
                    <TableCell className={classes.headerCell}>
                      Allocation %
                    </TableCell>
                    <TableCell className={classes.headerCell} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allocations.map((item: any, index: number) => (
                    <TableRow key={item.ticker}>
                      <TableCell
                        className={index ? classes.noPadCell : classes.topCell}
                      >
                        {item.ticker.split(".")[0]}
                      </TableCell>
                      <TableCell
                        className={index ? classes.noPadCell : classes.topCell}
                      >
                        {formatDollarsAndCents(item.amount)}
                      </TableCell>
                      <TableCell
                        className={index ? classes.noPadCell : classes.topCell}
                      >
                        <PercentTextField
                          variant="outlined"
                          onChange={(e: any) => {
                            const value = e.target.value;
                            editAllocation(item.ticker, value);
                          }}
                          value={item.allocation}
                        />
                      </TableCell>
                      <TableCell
                        className={index ? classes.noPadCell : classes.topCell}
                      >
                        <IconButton
                          className="m-0"
                          onClick={() => removeAllocation(item.ticker)}
                        >
                          <Icon iconName="fb-trash-can" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table className="mt-3" style={{ width: "calc(100% - 20px)" }}>
              <TableFooter>
                <TableRow>
                  <TableCell className={classes.totalCell}>Total</TableCell>
                  <TableCell className={classes.totalCell}>
                    {formatDollarsAndCents(allocationTotal)}
                  </TableCell>
                  <TableCell className={`${classes.totalCell} pl-6`}>
                    {formatPercent((allocationTotal / total) * 100)}
                  </TableCell>
                  <TableCell className={classes.totalCell} />
                </TableRow>
              </TableFooter>
            </Table>
            <Box className="flex justify-around">
              <Button
                variant="outlined"
                className={classes.addButton}
                color="primary"
                endIcon={<Icon iconName="fb-add-alt" />}
                onClick={openAddDialog}
              >
                Add Investment
              </Button>
              <Button
                variant="outlined"
                className="block"
                fbColor="secondary"
                onClick={reset}
              >
                <span>Reset</span>
              </Button>
            </Box>
          </div>
        </Card>
      </Box>
      <AddAllocationDialog
        open={addDialogVisible}
        onClose={closeAddDialog}
        addAllocation={addAllocation}
      />
    </>
  );
};

export default AnalysisInput;
