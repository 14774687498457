import React from "react";
import { useSelector } from "react-redux";

import {
  FINANCIAL_SCORE_CATEGORIES,
  SCORE_CATEGORIES,
  ScoreRanks,
} from "src/interfaces";
import List from "../Components/ListScore";
import { getScoreRanks } from "src/store/dashboard/selector";

// const _getlist = (
//   list: { [key: string]: number },
//   listToShow: { [key: string]: string }
// ) => {
//   const applicantKeys = Object.keys(list);
//   const listsTOShowKeys = Object.keys(listToShow);
//   return applicantKeys
//     .filter((item) => listsTOShowKeys.includes(item))
//     .map((item) => ({ name: listToShow[item], value: list[item] }));
// };

const FinancialList = () => {
  const { applicant } = useSelector(getScoreRanks);
  const list = FINANCIAL_SCORE_CATEGORIES.map((category: string) => ({
    name: SCORE_CATEGORIES[category as keyof ScoreRanks],
    value: applicant[category as keyof ScoreRanks],
  }));

  return <List header={list[0]} list={list.slice(1)} className="md:h-72" />;
};

export default FinancialList;
