import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import { push } from "connected-react-router";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiFormControlLayout,
  EuiComboBox,
  EuiTitle,
} from "@elastic/eui";

import {
  StyledEuiButton,
  StyledFormRow,
  StyledSpacer,
  StyledSpan,
  LabelStyle,
  StyledEuiTitle,
  LabelAsterisk,
  StyledEuiHorizontalRule,
  StyledEuiLink,
  StyledEuiButtonEmpty,
} from "src/components/Global/StyledComponents";
import {
  addCashflow,
  AddCashflowItemPayload,
  editCashflow,
  removeCashflow,
  replaceCashflow,
} from "src/store/cashflow/actions";
import { getCashflows } from "src/store/cashflow/selector";
import { INCOME_TYPES, ViewComponent } from "src/interfaces";
import { setProfileStep } from "src/store/profileBuild/actions";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import { getCurrentStep } from "src/store/profileBuild/selector";
import { getIsMarried, spouseSelector } from "src/store/system/selector";
import { DollarTextField } from "src/utils";
import { MainContainer } from "../../components";
import { Global, css } from "@emotion/react";
import FormError from "src/components/Global/FormError";
import { closeTourCard } from "src/store/tourcard/actions";

const globalStyles = css`
  @media (max-width: 768px) {
    .mobile-field-row {
      flex-wrap: nowrap !important;
    }
  }
  .label-class {
    margin-bottom: 0;
  }
`;

const initialSalary = {
  monthly: 0,
  annual: 0,
};

interface EditingCashflow {
  id: number;
  type: keyof typeof INCOME_TYPES;
  typeChanged?: boolean;
  annual: number;
  monthly: number;
}

const ProfileIncome: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const isMarried = useSelector(getIsMarried);
  const step = useSelector(getCurrentStep);
  const spouse = useSelector(spouseSelector);
  const { income } = useSelector(getCashflows);
  const isMine = step === PROFILE_BUILD_STEPS.MY_INCOME;
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const firstSalaryIndex = income.findIndex(
    (item) =>
      item.type === "salary" && item.whose === (isMine ? "mine" : "spouse")
  );
  const firstSalary = income[firstSalaryIndex];
  const existingCashflows = (firstSalary
    ? income.filter((item, index) => index !== firstSalaryIndex)
    : income
  ).filter((item) => item.whose === (isMine ? "mine" : "spouse"));
  const spouseFirstName = spouse?.first_name || "";
  const [salary, setSalary] = useState(
    firstSalary
      ? { monthly: firstSalary.monthly, annual: firstSalary.annual }
      : initialSalary
  );
  const [touchedSalary, setTouchedSalary] = useState(!!firstSalary);
  const [salaryError, setSalaryError] = useState(false);
  const [extraEntries, setExtraEntries] = useState<EditingCashflow[]>(
    existingCashflows.map((item) => ({
      id: item.id,
      type: item.type,
      annual: item.annual || 0,
      monthly: item.monthly || 0,
    }))
  );

  useEffect(() => {
    if (!extraEntries.length && firstSalary && !salary.annual) {
      setExtraEntries(
        existingCashflows.map((item) => ({
          id: item.id,
          type: item.type,
          annual: item.annual || 0,
          monthly: item.monthly || 0,
        }))
      );
      if (!touchedSalary) {
        setSalary(
          firstSalary
            ? { monthly: firstSalary.monthly, annual: firstSalary.annual }
            : initialSalary
        );
      }
    }
  }, [extraEntries.length, firstSalary, salary, touchedSalary]);

  if (!render) {
    return <div />;
  }

  const your = isMine ? "your" : `${spouseFirstName}'s`;
  const doYou = isMine ? "Do you" : `Does ${spouseFirstName}`;

  const updateSalary = (e: React.ChangeEvent<any>) => {
    setTouchedSalary(true);
    setSalaryError(false);
    const isMonthly = e.target.name === "monthly";
    const rawValue = Math.floor(e.target.value);
    const annual = isMonthly ? rawValue * 12 : rawValue;
    const monthly = isMonthly ? rawValue : Math.floor(rawValue / 12);
    setSalary((current) => ({ ...current, annual, monthly }));
  };

  const setItemType = (type: string, index: number) =>
    setExtraEntries((current) => {
      const newList = [...current];
      newList[index] = {
        ...newList[index],
        type: type,
        typeChanged: true,
      };
      return newList;
    });

  const setFieldValue = (e: React.ChangeEvent<any>, index: number) => {
    const key = e.target.name;
    const splitKey = key.split("__");
    const isMonthly = splitKey[0] === "monthly";
    const rawValue = Math.floor(e.target.value);
    const annual = isMonthly ? rawValue * 12 : rawValue;
    const monthly = isMonthly ? rawValue : Math.floor(rawValue / 12);
    setExtraEntries((current) => {
      const newList = [...current];
      newList[index] = { ...newList[index], annual, monthly };
      return newList;
    });
  };

  const addEmptyItem = () =>
    setExtraEntries((current) => [
      ...current,
      { id: -1, type: "", annual: 0, monthly: 0 },
    ]);

  const save = () => {
    if (!firstSalary) {
      dispatch(
        addCashflow({
          cashflow: {
            type: "salary",
            amount: salary.annual || 0,
            who: isMine ? "applicant" : "spouse",
            inschool: false,
          },
        })
      );
    } else {
      dispatch(
        editCashflow({
          id: firstSalary.id,
          amount: salary.annual || 0,
        })
      );
    }
    extraEntries.forEach((item: any) => {
      if (item.id >= 0) {
        if (item.typeChanged) {
          const replacePayload: any = {
            id: item.id,
            newItem: {
              type: item.type,
              amount: Math.abs(item.annual || 0),
              who: isMine ? "applicant" : "spouse",
              inschool: false,
            },
          };
          dispatch(replaceCashflow(replacePayload));
        } else {
          const amount = Math.abs(item.annual || 0);
          if (!amount) {
            dispatch(removeCashflow(item.id));
          } else {
            const payload = {
              id: item.id,
              amount: Math.abs(item.annual || 0),
            };
            dispatch(editCashflow(payload));
          }
        }
      } else {
        const cashflow: any = {
          type: item.type,
          amount: Math.abs(item.annual || 0),
          inschool: false,
        };
        // let inschoolCashflow: any = null;
        if (isMine) {
          cashflow.who = "applicant";
          // if (isCurrentStudent && !userInSchool) {
          //   inschoolCashflow = { ...cashflow, inschool: true };
          // }
        } else {
          cashflow.who = "spouse";
          // if (isCurrentStudent && !spouseInSchool) {
          //   inschoolCashflow = { ...cashflow, inschool: true };
          // }
        }
        const payload: AddCashflowItemPayload = {
          cashflow,
          temporaryId: item.id,
        };
        dispatch(addCashflow(payload));
        // if (inschoolCashflow) {
        //   const inschoolPayload: AddCashflowItemPayload = {
        //     cashflow: inschoolCashflow,
        //     temporaryId: item.id,
        //   };
        //   dispatch(addCashflow(inschoolPayload));
        // }
      }
    });
    existingCashflows.forEach((item) => {
      if (
        item.whose === (isMine ? "mine" : "spouse") &&
        !extraEntries.find((entry) => entry.id === item.id)
      ) {
        dispatch(removeCashflow(item.id));
      }
    });
  };

  const deleteItem = (index: number) =>
    setExtraEntries((current) => {
      const newEntries = [...current];
      newEntries.splice(index, 1);
      return newEntries;
    });

  const onPrev = () => {
    save();
    dispatch(
      setProfileStep(
        isMine
          ? PROFILE_BUILD_STEPS.INCOME_EXPENSES_INTRO
          : PROFILE_BUILD_STEPS.MY_INCOME
      )
    );
    dispatch(closeTourCard());
  };

  const next = () => {
    if (
      !salary.monthly &&
      !touchedSalary &&
      !extraEntries.find((item) => item.monthly)
    ) {
      setSalaryError(true);
    } else {
      save();
      dispatch(
        setProfileStep(
          isMine && isMarried
            ? PROFILE_BUILD_STEPS.SPOUSE_INCOME
            : PROFILE_BUILD_STEPS.EXPENSES
        )
      );
    }
    dispatch(closeTourCard());
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  };

  const saveAndExit = () => {
    save();
    dispatch(closeTourCard());
    dispatch(push("/profile-builder-continue"));
  };

  return render({
    component: (
      <MainContainer fluid ref={mainContainerRef}>
        <Global styles={globalStyles} />
        <StyledFormRow>
          <Col
            xl={{ span: 5, offset: 2 }}
            lg={{ span: 8, offset: 1 }}
            md={{ span: 8 }}
          >
            <EuiButtonEmpty
              color="text"
              flush="left"
              iconType="arrowLeft"
              onClick={onPrev}
            >
              Back
            </EuiButtonEmpty>
            <StyledSpacer size="32px" />
            <EuiTitle size="l" className="header-font">
              <h1 className="capitalize">{your} income</h1>
            </EuiTitle>
            <EuiSpacer size="l" />
            <EuiText size="m">
              <p>
                Enter {your} total income. Total income is the amount you get
                paid before deductions such as taxes, social security,
                retirement contributions, etc.
              </p>
            </EuiText>
            <StyledSpacer size="36px" />
            <EuiFormRow
              label={
                <LabelStyle style={{ marginBottom: 0 }}>
                  What is {your} current or future salary?
                  <LabelAsterisk />
                </LabelStyle>
              }
              className="input-size"
            >
              <>
                <EuiFlexGroup
                  style={{ maxWidth: 428, marginTop: 2 }}
                  className="mobile-field-row"
                >
                  <EuiFlexItem>
                    <EuiFormRow label="Monthly">
                      <DollarTextField
                        eui
                        decimalScale={0}
                        id="monthly__salary"
                        name="monthly"
                        value={
                          salary.monthly || !!firstSalary || touchedSalary
                            ? salary.monthly
                            : undefined
                        }
                        onChange={updateSalary}
                        isInvalid={salaryError}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label="Annual">
                      <DollarTextField
                        id="annual__salary"
                        name="annual"
                        value={
                          salary.annual || !!firstSalary || touchedSalary
                            ? salary.annual
                            : undefined
                        }
                        onChange={updateSalary}
                        decimalScale={0}
                        eui
                        isInvalid={salaryError}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
                {!!salaryError && (
                  <FormError>
                    You must enter a dollar amount. If unemployed, enter $0.
                  </FormError>
                )}
              </>
            </EuiFormRow>
            <StyledSpacer size="32px" />
            <StyledEuiTitle size="xs">
              <h3>Add Income</h3>
            </StyledEuiTitle>
            <StyledSpacer size="12px" />
            <EuiText size="m">
              <p>
                {doYou} have other sources of income? Include bonuses,
                commissions, and side hustles.
              </p>
            </EuiText>
            <StyledSpacer size="32px" />
            <StyledEuiHorizontalRule maxWidth="428px" />
            <StyledSpacer size="32px" />
            <>
              {extraEntries.map((item, index) => (
                <React.Fragment key={index}>
                  <EuiFormRow
                    label={<StyledSpan>Type</StyledSpan>}
                    className="input-size"
                  >
                    <EuiFormControlLayout>
                      <EuiComboBox
                        rowHeight={40}
                        isClearable={false}
                        id={`select__${index}`}
                        selectedOptions={
                          item.type !== ""
                            ? [{ label: INCOME_TYPES[item.type as string] }]
                            : []
                        }
                        onChange={(selectedOptions) =>
                          selectedOptions[0]?.value &&
                          setItemType(selectedOptions[0].value, index)
                        }
                        options={Object.keys(INCOME_TYPES)
                          .filter(
                            (key) =>
                              key !== "salary" &&
                              (item.type === key ||
                                !extraEntries.find(
                                  (otherItem) => otherItem.type === key
                                ))
                          )
                          .map((key) => ({
                            label: INCOME_TYPES[key],
                            value: key,
                          }))}
                        singleSelection={{ asPlainText: true }}
                      />
                    </EuiFormControlLayout>
                  </EuiFormRow>
                  <StyledSpacer size="32px" />
                  <EuiFlexGroup
                    style={{ maxWidth: 428 }}
                    className="mobile-field-row"
                  >
                    <EuiFlexItem>
                      <EuiFormRow label="Monthly">
                        <DollarTextField
                          id={`monthly__${index}`}
                          name={`monthly__${item.type as string}__${index}`}
                          value={item.monthly || ""}
                          onChange={(e: any) => setFieldValue(e, index)}
                          min={0}
                          decimalScale={0}
                          eui
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label="Annual">
                        <DollarTextField
                          id={`annual__${index}`}
                          name={`annual__${item.type as string}__${index}`}
                          value={item.annual || ""}
                          onChange={(e: any) => setFieldValue(e, index)}
                          decimalScale={0}
                          eui
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <StyledSpacer size="24px" />
                  <StyledEuiLink
                    color="primary"
                    onClick={() => deleteItem(index)}
                  >
                    Delete
                  </StyledEuiLink>
                  <StyledSpacer size="32px" />
                  <StyledEuiHorizontalRule maxWidth="428px" />
                  <StyledSpacer size="32px" />
                </React.Fragment>
              ))}
            </>
            <StyledEuiButton
              color="text"
              iconType="plus"
              onClick={addEmptyItem}
            >
              Add More Income
            </StyledEuiButton>
            <StyledSpacer size="48px" />
            <EuiButton
              type="submit"
              color="primary"
              fill
              onClick={next}
              disabled={salaryError}
            >
              Next
            </EuiButton>
            <StyledEuiButtonEmpty
              color="text"
              type="submit"
              onClick={saveAndExit}
            >
              Save and Exit
            </StyledEuiButtonEmpty>
            <StyledSpacer size="32px" />
          </Col>
        </StyledFormRow>
      </MainContainer>
    ),
  });
};

export default ProfileIncome;
