import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cloneDeep } from "lodash";

import {
  Box,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import CenterContainer from "../../Components/CenterContainer";
import { updateCurrentPlan } from "src/store/planBuild/actions";
import { getStudentLoanData } from "src/store/planBuild/selector";
import { PlanViewComponent, SidebarContent } from "src/interfaces";
import ToolTipIcon from "src/components/ToolTipIcon";

const blowout: SidebarContent[] = [
  {
    header: "High Rate Vs Low Balance",
    body: [
      "The high interest rate method pays off your highest interest rate loan first and will always save you the most money.",
      "The low balance method pays off the loan with the lowest balance.  The benefits of this method are 1) behaviorally satisfying because you achieve a goal the fastest and 2) could help you qualify for another loan, such as a mortgage, faster because your DTI drops.",
      <Typography>
        For more information,{" "}
        <a
          target="blank"
          href={
            "https://www.fitbux.com/articles/dave-ramsey-student-loan-repayment/"
          }
        >
          {" "}
          check out this article.
        </a>
      </Typography>,
    ],
  },
];

const DebtPrepayment: PlanViewComponent = ({ onSave, render }) => {
  const dispatch = useDispatch();
  const studentloan = useSelector(getStudentLoanData);
  const myStudentLoanEntry = studentloan[0];
  const initialFormValues = {
    strategy: myStudentLoanEntry?.strategy || "high_interest_rate",
  };
  const [formValues, setFormValues] = useState<any>(initialFormValues);

  const save = () => {
    const updatedStudentloan = cloneDeep(studentloan);
    updatedStudentloan[0].strategy = formValues.strategy;
    updatedStudentloan[1].strategy = formValues.strategy;
    dispatch(
      updateCurrentPlan({
        studentloan: updatedStudentloan,
      })
    );
    onSave();
  };

  const setFormValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    const newFormValues = { ...formValues, [field]: value };
    setFormValues(newFormValues);
  };

  return render({
    component: (
      <CenterContainer
        scrollable
        title="Debt Prepayments"
        iconName="fb-scales-tripped"
        help={blowout}
      >
        <Box className="my-5">
          <Box className="flex items-center">
            <Typography style={{ fontSize: "13px" }}>
              Do you want to use the high interest rate or low balance method to
              prepay debt?
            </Typography>
            <ToolTipIcon
              placement="right"
              title="High interest method uses your prepayments to pay off your high interest rate loans first. Low balance targets the lowest balance loans first."
            />
          </Box>
        </Box>
        <Grid item xs={6}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend" className="mb-4">
              <Typography style={{ fontSize: "13px" }}>
                Choose a repayment method:
              </Typography>
            </FormLabel>
            <Select
              variant="outlined"
              fullWidth
              value={formValues.strategy}
              name="strategy"
              placeholder="Select"
              onChange={setFormValue}
            >
              <MenuItem value="high_interest_rate">High interest rate</MenuItem>
              <MenuItem value="low_balance">Low balance</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </CenterContainer>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default DebtPrepayment;
