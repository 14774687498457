import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { Box, makeStyles } from "@material-ui/core";

import { openSpinwheelComponent } from "src/spinwheel/functions";
import SpinwheelDialog from "src/spinwheel/SpinwheelDialog";
import CalculatorLayout from "src/layouts/CalculatorLayout";
import Introduction from "./Introduction";

const useStyles = makeStyles({
  container: {
    margin: "20px auto",
    width: "90%",
    maxWidth: 1100,
  },
});

const AUTO_REFI_DISCLAIMER =
  "Disclaimer: Rates and pricing are subject to eligibility and underwriting criteria. Fitbux Services LLC has a business relationship and receives compensation if you complete a loan using one of the lenders on our platform. You do not have to complete a loan with a lender on Fitbux. You are free to shop lenders and Fitbux encourages you to do so. There are frequently other lenders available with similar services to the to those on our platform. Fitbux's relationship with each Lender is not an endorsement of any services, products, or service providers of the lending partners. Each Lender on Fitbux are separate independent entities and are not responsible for the financial condition, business, or legal obligations of the other. All services from our lenders are their own. Once you reach them, Fitbux is not involved and has no control over the products of services involved.";

const AutoLoanRefinance = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [spinwheelLoading, setSpinwheelLoading] = useState(true);
  const [spinwheelOpen, setSpinwheelOpen] = useState(false);

  const closeSpinwheelDialog = () => dispatch(push("/credit"));

  const gotoSpinwheelConnect = () => {
    openSpinwheelComponent({
      // onExit: closeSpinwheelDialog,
      onLoad: () => setSpinwheelLoading(false),
      onSuccess: loadAutoRefi,
      module: "debt-connect",
    });
  };

  const loadAutoRefi = () => {
    setSpinwheelOpen(true);
    openSpinwheelComponent({
      // onExit: closeSpinwheelDialog,
      onLoad: () => setSpinwheelLoading(false),
      module: "auto-refi",
      onEvent: ({ eventName }: any) => {
        if (eventName === "CONNECT_LOAN_TO_CONTINUE") {
          gotoSpinwheelConnect();
        }
      },
    });
  };

  return (
    <CalculatorLayout
      title="Auto Loan Refinance"
      onBackClick={() => dispatch(push("/credit"))}
      disclaimer={AUTO_REFI_DISCLAIMER}
    >
      <Box className={styles.container}>
        <Introduction next={loadAutoRefi} />
      </Box>
      <SpinwheelDialog
        loading={spinwheelLoading}
        open={spinwheelOpen}
        onClose={closeSpinwheelDialog}
      />
    </CalculatorLayout>
  );
};

export default AutoLoanRefinance;
