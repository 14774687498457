import { Box, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import TitleCard from "src/components/TitleCard";
import { usePointsCalculator } from "../UsePointsCalculator";
import InvestmentMethodStrategy from "./InvestmentMethodStrategy";

const InvestmentMethods = () => {
  const classes = useStyles();
  const { investmentMethodResult } = usePointsCalculator();
  const { strategyOne, strategyTwo } = investmentMethodResult;

  return (
    <React.Fragment>
      <TitleCard title="Results: Investment Method Strategy 1">
        <Box className={classes.container}>
          <Typography>With this strategy, you would:</Typography>
          <div>
            <Typography>
              1) Elect to go with a "point offer" but make the (higher) payment
              of your "no point offer". By paying more than the required
              payment, you would end up paying off your mortgage early.
            </Typography>
            <Typography>
              2) Once your mortgage is paid off, invest an amount equal to the
              required payment of the "no point offer" until the date at which
              your mortgage would have been paid off if you had only made the
              required payment of your "point offer".
            </Typography>
          </div>
          <InvestmentMethodStrategy strategy={strategyOne} />
        </Box>
      </TitleCard>
      <TitleCard title="Results: Investment Method Strategy 2">
        <Box className={classes.container}>
          <Typography>With this strategy, you would:</Typography>
          <div>
            <Typography>
              1) Only make the lower required monthly payment you secured by
              paying points upfront.
            </Typography>
            <Typography>
              2) From day 1, invest the difference between your “no point”
              required payment and your “with point” required payment.
            </Typography>
          </div>

          <InvestmentMethodStrategy strategy={strategyTwo} />
        </Box>
      </TitleCard>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingBottom: theme.spacing(2),
  },
}));

export default InvestmentMethods;
