import { Who, Whose, YesNo } from "./common";

export interface Account {
  id: number;
  type?: string;
  firm: string;
  name: string;
  who: Who | Whose;
  balance: number | null;
  balance_live?: number;
  rate: number;
  payment: number;
  manual?: {
    balance: boolean;
    payment: boolean;
    rate: boolean;
  };
  carrying?: YesNo;
  "asset-balance": number;
  variable?: string;
  whose?: Who | Whose;
}

export interface MappedAccount {
  id: number;
  variable: string;
  rate?: number;
  fedloan_repayment_plan?: string;
  annual: number;
  monthly: number;
  income_percent?: number;
  manual?: {
    balance: boolean;
    payment: boolean;
    rate: boolean;
  };
  min?: number;
  whose?: Who | Whose;
  who?: Who | Whose;
  allocation?: number;
  name?: string;
  firm?: string;
  balance?: number | null;
  balance_live?: number;
  variableLabel: string;
  joint?: string;
}

export interface AssetsDebts {
  assets: MappedAccount[];
  debts: MappedAccount[];
}

export interface LinkedAccount extends Account {
  item_id?: string;
  confirmed?: boolean;
  category?: string;
  holdings?: boolean;
}

export interface AccountProvider {
  item_id: string;
  name: string;
  new: true;
  error: string;
  holdings?: boolean;
  who?: string;
}

export interface PublicToken {
  expiration?: string;
  link_token?: string;
  request_id?: string;
}

export interface AccountTicker {
  ticker_symbol: string;
  quantity: number;
  close_price: number;
}

export interface AccountState {
  accounts: Account[];
  closedAccounts: Account[];
  loaded: boolean;
  loading: boolean;
  loadedProviders: boolean;
  loadedAccountDetails: boolean;
  loadingAccountDetails: boolean;
  loadingLinkedAccounts: boolean;
  linkedAccountsError: any;
  publicToken: PublicToken;
  providers: AccountProvider[];
  newProviders: AccountProvider[];
  linkedAccounts: LinkedAccount[];
  referralCode: string | null;
}

export const INVESTMENT_TYPE_LIST = [
  "other_investments_value",
  "401k_value",
  "roth_401k_value",
  "ira_value",
  "roth_ira_value",
  "other_retirement_value",
  "hsa_value",
  "crypto",
];

export const isInvestmentType = (type: string) =>
  INVESTMENT_TYPE_LIST.indexOf(type) >= 0;
