import React from "react";

import clsx from "clsx";

import { Box, makeStyles, Typography } from "@material-ui/core";

import { colors } from "src/theme";

const useStyles = makeStyles({
  fixedBottom: {
    background: colors.brandingBlue1,
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    height: 60,
    bottom: 0,
    left: 0,
    padding: "10px 40px",
    width: "100%",
  },
  text: {
    color: "white",
  },
});

const StickyFooter = ({ children, className, text }: any) => {
  const styles = useStyles();
  return (
    <Box className={clsx(styles.fixedBottom, className || "")}>
      {!!text && (
        <Typography variant="h2" className={styles.text}>
          {text}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default StickyFooter;
