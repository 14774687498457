import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Card, makeStyles, Tooltip, Typography } from "@material-ui/core";

import { APPLICANT_WHO_TYPES, MappedCashflow } from "src/interfaces";
import { getIsMarried } from "src/store/system/selector";
import { formatAnnually, formatMonthly } from "src/utils";
import BasicCard from "src/components/BasicCard";
import Button from "src/components/Button";
import HelpToggle from "src/components/HelpToggle";
import { HOME_DEBT_TYPES, STUDENT_LOAN_TYPES } from "../common";

const useStyles = makeStyles({
  mainContainer: {
    margin: "20px 0px",
  },
  innerBox: {
    backgroundColor: "white",
    width: "80%",
    padding: "20px",
    margin: "auto",
    "& h1": {
      fontSize: "20px",
    },
  },
  transitions: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
    gap: "20px",
  },
});

const ConfirmDebts = ({
  transition,
  debts,
  setHomeDebts,
}: {
  transition: (
    next: boolean,
    showResults?: boolean,
    incomeData?: any[]
  ) => void;
  debts: any[];
  setHomeDebts: (debts: any[]) => void;
}) => {
  const classes = useStyles();
  const isMarried = useSelector(getIsMarried);
  const [newDebts, setNewDebts] = useState<any>(null);
  const [confirmedDebts, setConfirmedDebts] = useState<any[]>([...debts]);
  const [total, setTotal] = useState(0);
  const [wait, setWait] = useState(false);

  useEffect(() => {
    const filteredDebts = debts.filter((entry) => HOME_DEBT_TYPES[entry.type]);
    setConfirmedDebts(filteredDebts);
  }, [debts]);

  useEffect(() => {
    let result = 0;
    confirmedDebts.forEach((entry) => {
      result += entry.annual;
    });
    setTotal(result);
  }, [confirmedDebts]);

  useEffect(() => {
    if (wait) {
      handleNext();
    }
  }, [confirmedDebts]);

  const handleNext = (next = true) => {
    setHomeDebts(confirmedDebts);
    transition(next);
  };

  const saveDebts = (
    item: MappedCashflow,
    updateFields: any,
    index: number
  ) => {
    setNewDebts(updateFields);
    setNewDebts(null);
    const typeLabel = updateFields.type
      ? HOME_DEBT_TYPES[updateFields.type]
      : "Credit card";
    const who = updateFields.who || "applicant";
    const whoLabel =
      updateFields.who === "spouse"
        ? APPLICANT_WHO_TYPES["spouse"]
        : APPLICANT_WHO_TYPES["applicant"];
    const updatedDebts = [...confirmedDebts];
    if (item) {
      updatedDebts.splice(index, 1, {
        ...updateFields,
        typeLabel,
        whoLabel,
        who,
        payment: updateFields.payment || 0,
      });
    }
    setConfirmedDebts(updatedDebts);
  };

  const getDebtColumns = () => {
    if (isMarried) {
      return [
        {
          label: "Type",
          field: "type",
          type: "select",
          width: "30%",
          items: HOME_DEBT_TYPES,
        },
        {
          label: "Owner",
          field: "who",
          type: "select",
          width: "20%",
          items: APPLICANT_WHO_TYPES,
        },
        {
          label: "Monthly",
          field: "monthly",
          type: "number",
          width: "20%",
          formatter: formatMonthly,
        },
        {
          label: "Annual",
          field: "annual",
          type: "number",
          width: "20%",
          formatter: formatAnnually,
        },
      ];
    } else {
      return [
        {
          label: "Type",
          field: "type",
          type: "select",
          width: "40%",
          items: HOME_DEBT_TYPES,
        },
        {
          label: "Monthly",
          field: "monthly",
          type: "number",
          width: "30%",
          formatter: formatMonthly,
        },
        {
          label: "Annual",
          field: "annual",
          type: "number",
          width: "30%",
          formatter: formatAnnually,
        },
      ];
    }
  };

  const showDebts: any[] = [...confirmedDebts];
  if (newDebts) {
    showDebts.push(newDebts);
  }

  const defaultNewDebt = {
    payment: 0,
    type: "",
    who: "",
  };

  // const addNewExpense = () => setNewExpense(defaultNewExpense);
  const addNewDebt = () => setNewDebts(defaultNewDebt);

  const removeDebt = (item: any, index: number) => {
    if (index >= confirmedDebts.length) {
      setNewDebts(null);
    }
    const newIncome = confirmedDebts.filter((entry, i) => i !== index);
    setConfirmedDebts(newIncome);
  };

  // disable the next button and handleClick if there is a debt with no value that is NOT a student loan
  const nextEnabled = confirmedDebts.every(
    (entry) =>
      STUDENT_LOAN_TYPES[entry.type] ||
      (!STUDENT_LOAN_TYPES[entry.type] && entry.annual)
  );

  const handleClick = () => {
    if (nextEnabled) {
      setWait(true);
    }
  };

  return (
    <Box className={classes.mainContainer}>
      <Card className={classes.innerBox}>
        <Typography variant="h1">
          Confirm Your Debt <HelpToggle />
        </Typography>
        <BasicCard
          // iconName="fb-money"
          title=""
          columns={getDebtColumns()}
          hideTitle={true}
          data={showDebts}
          allowSelect
          // defaultNewItem={defaultNewIncome}
          onAdd={addNewDebt}
          onCancelEdit={() => setNewDebts(null)}
          onDelete={removeDebt}
          onSave={saveDebts}
          saveOnClose={wait}
          summaryRow={
            !showDebts.length
              ? undefined
              : {
                  type: "Total",
                  annual: total,
                  monthly: Math.round(total / 12),
                }
          }
        />
      </Card>

      <Box className={classes.transitions}>
        <Button
          onClick={() => {
            handleNext(false);
          }}
        >
          Back
        </Button>

        <Tooltip
          title={
            nextEnabled
              ? ""
              : "One or more debts above still require a monthly payment"
          }
          placement="top"
          leaveDelay={2000}
        >
          <Box>
            <Button
              disabled={!nextEnabled}
              onClick={handleClick}
              color="primary"
            >
              Next
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default ConfirmDebts;
