import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";

import { Box, makeStyles, Typography, Paper } from "@material-ui/core";

import DashboardLayout from "src/layouts/DashboardLayout";
import colors from "src/theme/colors";
import ReferAFriendButton from "src/ReferralCode";
import { getReferralCode } from "src/store/account/selector";

const TAPFILIATE_ADDRESS = process.env.REACT_APP_TAPFILIATE_ADDRESS || "";
const RAF_URL = process.env.REACT_APP_REFERAFRIEND_URL || "";
const rafMessage =
  "I use Fitbux for my financial planning and student loans and you should too, use my link!";

const useStyles = makeStyles({
  image: {
    position: "absolute",
    height: 300,
    top: 85,
    // width: 350,
    zIndex: -1,
  },
  box1: {
    marginTop: "100px",
    fontSize: 20,
    // width: 333,
    textAlign: "center",
    "& *": {
      fontSize: 18,
      fontWeight: 800,
    },
  },
  box2: {
    // backgroundColor: "lightpink",
    margin: "5px 50px 18px 50px",
    textAlign: "center",
    "& *": {
      fontSize: 27,
      color: colors.brandingBlue1,
      fontWeight: 500,
    },
  },
  box3: {
    margin: "5px 50px",
    textAlign: "center",
    "& *": {
      fontSize: 15,
    },
  },
  box4: {
    margin: "15px 50px",
    "& *": {
      fontSize: 14,
    },
  },
  step1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  socials: {
    position: "relative",
    display: "flex",
    height: 35,
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      margin: 5,
    },
  },
  socialMessage: {
    position: "absolute",
    right: 20,
  },
});

const Referral = () => {
  const classes: any = useStyles();
  const referralCode = useSelector(getReferralCode);
  const referralURL = `${TAPFILIATE_ADDRESS}${referralCode}`;
  const [displayMessage, setDisplayMessage] = useState(false);

  const handleClick = () => {
    if (!displayMessage) {
      setDisplayMessage(true);
    }
  };

  const getSocials = () => {
    if (referralCode) {
      return (
        <Box className={classes.socials}>
          <FacebookShareButton
            url={RAF_URL}
            quote={`${rafMessage} ${referralURL}`}
            // hashtag={"some hashtag"}
          >
            <FacebookIcon
              size={30}
              round={false}
              crossOrigin={undefined}
              path={undefined}
              borderRadius={10}
            />
          </FacebookShareButton>
          <TwitterShareButton
            url={RAF_URL}
            title={`${rafMessage} ${referralURL}`}
            // via={"via"}
            // hashtags={["hashtags"]}
            // related={["related"]}
          >
            <TwitterIcon
              size={30}
              round={false}
              crossOrigin={undefined}
              path={undefined}
              borderRadius={10}
            />
          </TwitterShareButton>
          <LinkedinShareButton
            url={RAF_URL}
            title={`${rafMessage} ${referralURL}`}
            // summary={"mysummary"}
          >
            <LinkedinIcon
              size={30}
              round={false}
              crossOrigin={undefined}
              path={undefined}
              borderRadius={10}
            />
          </LinkedinShareButton>
          <EmailShareButton
            url={RAF_URL}
            subject={"RE: I recommend this"}
            body={`${rafMessage} ${referralURL}`}
          >
            <EmailIcon
              size={30}
              round={false}
              crossOrigin={undefined}
              path={undefined}
              borderRadius={10}
            />
          </EmailShareButton>
        </Box>
      );
    }
    return (
      <Box className={classes.socials}>
        <FacebookIcon
          onClick={handleClick}
          size={30}
          round={false}
          crossOrigin={undefined}
          path={undefined}
          borderRadius={10}
        />
        <TwitterIcon
          onClick={handleClick}
          size={30}
          round={false}
          crossOrigin={undefined}
          path={undefined}
          borderRadius={10}
        />
        <LinkedinIcon
          onClick={handleClick}
          size={30}
          round={false}
          crossOrigin={undefined}
          path={undefined}
          borderRadius={10}
        />
        <EmailIcon
          onClick={handleClick}
          size={30}
          round={false}
          crossOrigin={undefined}
          path={undefined}
          borderRadius={10}
        />
        {displayMessage && getSocialMessage()}
      </Box>
    );
  };

  const getSocialMessage = () => {
    setTimeout(() => {
      setDisplayMessage(false);
    }, 2000);
    return (
      <Paper elevation={3} className={classes.socialMessage}>
        <Typography
          style={{
            backgroundColor: "darkgrey",
            color: "white",
            padding: "0 2px",
          }}
        >
          Did you get your link?
        </Typography>
      </Paper>
    );
  };

  return (
    <DashboardLayout pageTitle="Refer A Friend">
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box style={{ width: "700px" }}>
          <Box className={classes.box1}>
            <Typography> DON'T LEAVE YOUR FRIENDS BEHIND </Typography>
          </Box>

          <Box className={classes.box2}>
            <Typography> Invite Friends &amp; Earn Money </Typography>
          </Box>

          <Box className={classes.box3}>
            <Typography>
              {" "}
              Get your link, share it with your fiends, get paid, it's that
              easy! Receive $10 for each friend that signs-up for FitBUX's
              premium Membership and $20 per friend that refinances their
              student loan(s). <br />{" "}
              <a
                target="_blank"
                href="https://www.fitbux.com/refer-a-friend/"
                style={{ fontSize: "12px" }}
              >
                Terms &amp; Conditions
              </a>{" "}
            </Typography>
          </Box>

          <Box className={classes.box4}>
            <Box className={classes.step1}>
              <Typography> Step 1: Click to get your link: </Typography>{" "}
              <ReferAFriendButton />
            </Box>
            <Typography paragraph>
              {" "}
              Step 2: Copy the link above and share via email/social media or
              click the icon below to share on social media.
            </Typography>

            {getSocials()}

            <Typography paragraph> Step 3: Get paid! </Typography>
          </Box>
        </Box>

        <Box>
          <img
            src={"/assets/images/png/referafriend.png"}
            alt="Friends"
            className={classes.image}
          />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Referral;
