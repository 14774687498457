import React from "react";
import { Box, makeStyles } from "@material-ui/core";

import GraphSection from "./graph";
import NotesSection from "./notes";
import MainSection from "./main";
import { HomeResults } from "../common";
import Button from "src/components/Button";

const useStyles = makeStyles({
  mainContainer: {
    height: "100%",
  },
  innerBox: {
    width: "80%",
    maxWidth: "900px",
    display: "grid",
    margin: "20px auto",
    // border: "1px solid black"
  },
  transitions: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
});

const Results = ({
  transition,
  resultsIn,
  data,
}: {
  transition: (
    next: boolean,
    showResults?: boolean,
    incomeData?: any[]
  ) => void;
  resultsIn: boolean;
  data: HomeResults;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.innerBox}>
        <GraphSection dataReady={resultsIn} data={data} />
        <MainSection dataReady={resultsIn} data={data} />
        <NotesSection />
      </Box>
      <Box className={classes.transitions}>
        <Button
          onClick={() => {
            transition(false);
          }}
        >
          Back
        </Button>
      </Box>
    </Box>
  );
};

export default Results;
