import axiosClient from "../axiosClient";
import makeAuthenticatedRequest from "../authenticatedRequest";
import { SubscriptionPayload } from "src/interfaces";

export const getPublicKeyApi = async () => {
  const { data } = await axiosClient.get("/purchase/pubkey.php");
  return data;
};

export const getPurchasePlansApi = async () => {
  const { data } = await axiosClient.get("/purchase/plans.php");
  return data;
};

export const getMoneySchoolPlansApi = async () => {
  const { data } = await axiosClient.get("/purchase/plans.php?type=courses");
  return data;
};

export const getCouponsApi = (promo: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(
      `/purchase/coupon.php?code=${promo}`
    );
    return data;
  });

export const getSubscriptionsApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/tracking/purchase/subscribe.php");
    return data;
  });

export const createSubscriptionApi = (payload: SubscriptionPayload) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post(
      "/tracking/purchase/subscribe.php",
      payload
    );
    return data;
  });

export const deleteSubscriptionApi = (subscriptionId: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.delete(
      `/tracking/purchase/subscribe.php?subscription_id=${subscriptionId}`
    );
    return data;
  });

export const getCustomerPortalApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/tracking/purchase/portal.php");
    return data;
  });
