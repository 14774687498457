import React, { useEffect, useState } from "react";

import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";

import Accordion from "src/components/Accordion";
import Icon from "src/components/Icon";
import colors from "src/theme/colors";
import { AdapterLink } from "src/utils";

interface VideoDefinition {
  prefix: string;
  title: string;
  minutes: number;
  vimeo: number;
}

interface ContentCategory {
  name: string;
  video: VideoDefinition[];
}

export interface ContentItem {
  name: string;
  categories: ContentCategory[];
}

const useStyles = makeStyles({
  root: {},
  selected: {
    color: colors.brandingBlue1,
    fontWeight: 600,
  },
  unselected: {},
  video: {
    height: 480,
    margin: "0 auto",
    width: 640,
  },
  accordionContainer: {
    margin: "20px auto",
    width: 600,
  },
});

export const MoneySchool = ({ content }: any) => {
  const styles = useStyles();
  // const [content, setContent] = useState<ContentItem[]>([]);
  const [currentVideo, setCurrentVideo] = useState("");
  useEffect(() => {
    if (content.length) {
      const video = content?.[0]?.categories?.[0].video?.[0].vimeo;
      if (video) {
        setCurrentVideo("" + video);
      }
    }
  }, [content]);

  return (
    <Box className={styles.root}>
      <Box className="flex justify-between items-center">
        <Typography variant="h1">FitBUX Money School</Typography>
        <IconButton component={AdapterLink} to="/otheritems">
          <Icon iconName="fb-close" />
        </IconButton>
      </Box>
      <Box className={styles.video}>
        {!!currentVideo && (
          <iframe
            src={`https://player.vimeo.com/video/${currentVideo}?autoplay=1`}
            width="640"
            height="480"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            style={{ width: "100%" }}
          ></iframe>
        )}
      </Box>
      {!!content.length && (
        <Box className={styles.accordionContainer}>
          <Accordion
            sections={content.map((item: ContentItem) => ({
              title: item.name,
              content: (
                <Accordion
                  sections={item.categories.map((category) => ({
                    title: category.name,
                    contentRows: category.video.map((video) => ({
                      text: (
                        <a
                          className={
                            currentVideo === "" + video.vimeo
                              ? styles.selected
                              : styles.unselected
                          }
                          href="#"
                          onClick={() => setCurrentVideo("" + video.vimeo)}
                        >
                          {video.prefix}. {video.title}
                        </a>
                      ),
                      total: `${video.minutes} min`,
                    })),
                  }))}
                />
              ),
            }))}
          />
        </Box>
      )}
    </Box>
  );
};

export default MoneySchool;
