import React, { useState } from "react";

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@material-ui/core";

import colors from "src/theme/colors";
import Icon from "src/components/Icon";
import LearnMoreLink from "src/components/LearnMoreLink";
import { AdapterLink } from "src/utils";

const useStyles = makeStyles({
  root: {
    padding: 15,
    borderRadius: 12,
    marginBottom: 20,
  },
  title: {
    fontSize: 14,
    color: colors.brandingBlue1,
    fontWeight: 600,
  },
  table: {
    width: "100%",
    borderBottom: `1px solid ${colors.blueGray6}`,
  },
  headerCell: {
    fontSize: 12,
    color: colors.blueGray1,
    fontWeight: 500,
    borderBottom: `1px solid ${colors.blueGray6}`,
    padding: "7px 0",
    textAlign: "center",
  },
  noteCell: {
    fontSize: 12,
    color: colors.blueGray1,
    fontWeight: 500,
    // borderBottom: `1px solid ${colors.blueGray6}`,
    padding: "10px 0 0px 0",
  },
  firstCol: {
    width: 170,
  },
  row: {
    cursor: "pointer",
  },
  cell: {
    fontSize: 12,
    fontWeight: 600,
    borderBottom: "none",
    borderTop: `1px solid ${colors.blueGray6}`,
    padding: "10px 0",
    position: "relative",
    whiteSpace: "nowrap",
    "& svg": {
      fontSize: 12,
      color: colors.blueGray1,
      position: "absolute",
      left: 0,
    },
  },
  unbold: {
    fontWeight: 400,
  },
  odd: {
    color: colors.brandingBlue1,
  },
  even: {
    color: colors.brandingBlue2,
  },
  leftBorder: {
    borderLeft: `1px solid ${colors.blueGray6}`,
    textAlign: "center",
  },
  topBorder: {
    borderTop: `1px solid ${colors.blueGray6} !important`,
  },
  subCell: {
    fontSize: 12,
    fontWeight: 400,
    borderBottom: "none",
    borderTop: "none",
    padding: "7px 0",
    // whiteSpace: "nowrap",
    color: colors.blueGray2,
  },
});

export interface OverviewTableSubRow {
  header?: string; // 1st column
  columns?: React.ReactNode[];
}

export interface OverviewTableRow extends OverviewTableSubRow {
  children?: OverviewTableSubRow[];
}

interface IOverviewTable {
  advanced?: boolean;
  noColors?: boolean;
  noGrid?: boolean;
  title: string;
  rowHeader?: string;
  columns: React.ReactNode[];
  rows: OverviewTableRow[];
  learnMoreRows?: LearnMoreRow[];
}

interface LearnMoreRow {
  text: string;
  link?: string;
  redirect?: string;
}

const OverviewTable = ({
  advanced,
  noColors,
  noGrid,
  title,
  rowHeader,
  columns,
  rows,
  learnMoreRows,
}: IOverviewTable) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<Array<number>>([]);

  const isExpanded = (i: number) => {
    return expanded.indexOf(i) > -1;
  };

  const handleToggle = (i: number) => {
    const index = expanded.indexOf(i);
    if (index > -1) {
      setExpanded(expanded.filter((j) => j !== i));
      expanded.splice(index, 1);
    } else {
      setExpanded([...expanded, i]);
    }
  };

  const noGridStyle = noGrid ? { border: "0" } : undefined;

  const renderNote = () => {
    if (learnMoreRows) {
      return (
        <>
          {learnMoreRows.map((learnMoreRow, index) => (
            <Typography
              paragraph={false}
              className={`${classes.noteCell}`}
              key={index}
            >
              {learnMoreRow.text}
              {learnMoreRow.link && (
                <span>
                  {" "}
                  <LearnMoreLink learnMoreKey={learnMoreRow.link} />{" "}
                </span>
              )}
              {learnMoreRow.redirect && (
                <AdapterLink to={learnMoreRow.redirect}>click here</AdapterLink>
              )}
            </Typography>
          ))}
        </>
      );
    }
  };

  return (
    <Paper className={classes.root}>
      <Typography component="h3" variant="body1" className={classes.title}>
        {title}
      </Typography>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.headerCell} ${classes.firstCol}`}>
              {rowHeader}
            </TableCell>
            {advanced && (
              <>
                <TableCell colSpan={2}>Actual</TableCell>
                <TableCell colSpan={2}>Planned</TableCell>
              </>
            )}
            {!advanced && (
              <>
                {columns.map((col, index) => (
                  <TableCell key={index} className={classes.headerCell}>
                    {col}
                  </TableCell>
                ))}
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rIndex) => {
            let className = classes.cell;
            if (!noColors) {
              className += ` ${rIndex % 2 ? classes.even : classes.odd}`;
            } else {
              className += ` ${classes.unbold}`;
            }
            return (
              <>
                <TableRow
                  key={rIndex}
                  className={classes.row}
                  onClick={() => handleToggle(rIndex)}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={className}
                    style={noGridStyle}
                  >
                    <Box className="flex items-center relative">
                      {row.children && !!row.children.length && (
                        <Icon
                          iconName={
                            isExpanded(rIndex)
                              ? "fb-chevron-down"
                              : "fb-chevron-right"
                          }
                        ></Icon>
                      )}
                      <span className="ml-4 text-left">{row.header}</span>
                    </Box>
                  </TableCell>
                  {(row.columns || []).map((col, cIndex) => (
                    <TableCell
                      key={cIndex}
                      className={`${className} ${classes.leftBorder}`}
                      style={noGridStyle}
                    >
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
                {isExpanded(rIndex) && !!row.children && !!row.children.length && (
                  <>
                    {row.children.map((subCol, srIndex) => (
                      <TableRow key={srIndex}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={`${classes.subCell} ${
                            !srIndex ? classes.topBorder : ""
                          }`}
                          style={noGridStyle}
                        >
                          <span className="ml-4 text-primary">
                            {subCol.header}
                          </span>
                        </TableCell>
                        {(subCol.columns || []).map((col, cIndex) => (
                          <TableCell
                            key={cIndex}
                            className={`${classes.subCell} ${
                              classes.leftBorder
                            } ${!srIndex ? classes.topBorder : ""}`}
                            style={noGridStyle}
                          >
                            {col}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </>
                )}
              </>
            );
          })}
        </TableBody>
      </Table>
      {renderNote()}
    </Paper>
  );
};

export default OverviewTable;
