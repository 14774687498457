import React from "react";

import { Box, FormControlLabel, Grid, makeStyles } from "@material-ui/core";

import Card from "src/components/Card";
import Checkbox from "src/components/Checkbox";

const useStyles = makeStyles({
  topText: {
    fontSize: 14,
    marginTop: 20,
  },
  lenderBox: {
    marginLeft: 20,
    marginTop: 20,
  },
  logoRow: {
    display: "flex",
    justifyContent: "center",
    height: 120,
    marginBottom: 10,
  },
  lenderText: {
    "&>p": {
      fontSize: 14,
    },
  },
});

const SelectLenders = ({
  availableLenders,
  selectedLenders,
  toggleSelectedLender,
}: any) => {
  const styles = useStyles();
  return (
    <Card divider title="2 - Select Your Lender(s)">
      <Grid container spacing={6}>
        {availableLenders.map((lender: any) => (
          <Grid item xs={12} sm={4}>
            <Box className={styles.lenderBox}>
              <Box className={styles.logoRow}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedLenders.has(lender.id)}
                      onChange={() => toggleSelectedLender(lender.id)}
                    />
                  }
                  label={
                    <img
                      height={120}
                      title={lender.name}
                      alt={`${lender.name} logo`}
                      src={lender.logo}
                    />
                  }
                  labelPlacement="end"
                />
              </Box>
              <Box className={styles.lenderText}>{lender.text || ""}</Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default SelectLenders;
