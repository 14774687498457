import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { investmentsStyles } from "src/theme";

const useStyles = makeStyles(investmentsStyles);

export const AllocationTable = ({
  myAvg,
  fbAvg,
  myStdDev,
  fbStdDev,
  myAllocationLabel,
}: any) => {
  const classes = useStyles();

  const my5pct = (myAvg - 2 * myStdDev).toFixed(2);
  const my95pct = (myAvg + 2 * myStdDev).toFixed(2);
  const fb5pct = (fbAvg - 2 * fbStdDev).toFixed(2);
  const fb95pct = (fbAvg + 2 * fbStdDev).toFixed(2);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>FitBUX's Recommended Allocation</TableCell>
          <TableCell>Your {myAllocationLabel} Allocation</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={`${classes.wrapped} ${classes.subHeader}`}>
            Expected Long-Term Average Annual Rate Of Return
          </TableCell>
          <TableCell>{fbAvg.toFixed(2)}%</TableCell>
          <TableCell>{myAvg.toFixed(2)}%</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={`${classes.wrapped} ${classes.subHeader}`}>
            Expected Standard Deviation
          </TableCell>
          <TableCell>{fbStdDev.toFixed(2)}%</TableCell>
          <TableCell>{myStdDev.toFixed(2)}%</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={`${classes.wrapped} ${classes.subHeader}`}>
            What This Means
          </TableCell>
          <TableCell className={classes.wrapped}>
            At this allocation, there is a 95% probability that your
            investment's annual rate of return would fall between {fb5pct}% and{" "}
            {fb95pct}%.
          </TableCell>
          <TableCell className={classes.wrapped}>
            At this allocation, there is a 95% probability that your
            investment's annual rate of return would fall between {my5pct}% and{" "}
            {my95pct}%.
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default AllocationTable;
