import * as React from "react";

import { Button, makeStyles, Typography } from "@material-ui/core";

import Card from "src/components/Card";
import { investmentsStyles } from "src/theme";
import { AdapterLink } from "src/utils";

const useStyles = makeStyles(investmentsStyles);

export const EditOptions = ({ next }: any) => {
  const classes = useStyles();

  return (
    <Card>
      <Typography variant="h2" className="text-center">
        Choose One of the Following:
      </Typography>
      <Button className={classes.blockButton} component="a" onClick={next}>
        <span className={classes.bigLabel}>Change My Allocation</span>
        <span className={classes.littleLabel}>
          I want to change my allocation
        </span>
      </Button>
      <Button
        className={classes.blockButton}
        component="a"
        target="_blank"
        href="https://www.betterment.com/app/login"
      >
        <span className={classes.bigLabel}>I Will Make Edits Myself</span>
        <span className={classes.littleLabel}>
          You'll be taken to your Betterment log in screen
        </span>
      </Button>
      <Button
        className={classes.blockButton}
        component={AdapterLink}
        to="/coach#messages"
      >
        <span className={classes.bigLabel}>Message FitBUX</span>
      </Button>
    </Card>
  );
};

export default EditOptions;
