import React from "react";

import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";

import colors from "src/theme/colors";
import { formatWholeDollars } from "src/utils";

const useStyles = makeStyles({
  borderCell: {
    borderBottom: `1px solid ${colors.brandingBlue1}`,
  },
});

export const RefinanceDetails = ({ goBack, results }: any) => {
  const styles = useStyles();

  return (
    <Paper className="mt-5 p-4" style={{ maxWidth: 900, margin: "0 auto" }}>
      <Typography variant="h2" component="h2">
        Results
      </Typography>
      <TableContainer style={{ maxWidth: 1000 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="border-none" />
              <TableCell className="border-none">Refinance Offer #1</TableCell>
              <TableCell className="border-none">Refinance Offer #2</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                className="border-none"
                style={{ wordBreak: "break-word" }}
              >
                Total Required Monthly Payment
              </TableCell>
              <TableCell className="border-none">
                {formatWholeDollars(results[0].detail.refi_payment)}
              </TableCell>
              <TableCell className="border-none">
                {formatWholeDollars(results[1].detail.refi_payment)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className="border-none"
                style={{ wordBreak: "break-word" }}
              >
                Discretionary Prepayment
              </TableCell>
              <TableCell className={styles.borderCell}>
                {formatWholeDollars(results[0].detail.refi_discretionary)}
              </TableCell>
              <TableCell className={styles.borderCell}>
                {formatWholeDollars(results[1].detail.refi_discretionary)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className="border-none"
                style={{ wordBreak: "break-word" }}
              >
                Total Monthly Payment
              </TableCell>
              <TableCell className="border-none">
                {formatWholeDollars(results[0].detail.refi_total_payment)}
              </TableCell>
              <TableCell className="border-none">
                {formatWholeDollars(results[1].detail.refi_total_payment)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className="border-none"
                style={{ wordBreak: "break-word" }}
              >
                Repayment Period
              </TableCell>
              <TableCell className="border-none">
                {results[0].detail.refi_years.toFixed(1)} Years
              </TableCell>
              <TableCell className="border-none">
                {results[1].detail.refi_years.toFixed(1)} Years
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className="border-none"
                style={{ wordBreak: "break-word" }}
              >
                Total Cumulative Payment Amount
              </TableCell>
              <TableCell className="border-none">
                {formatWholeDollars(results[0].detail.refi_accu_payment)}
              </TableCell>
              <TableCell className="border-none">
                {formatWholeDollars(results[1].detail.refi_accu_payment)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button fbColor="secondary" onClick={goBack} fullWidth>
        Back
      </Button>
    </Paper>
  );
};

export default RefinanceDetails;
