import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import { Typography } from "@material-ui/core";

import { helocPingApi, submitHelocLeadApi } from "src/apiService";
import RateQuoteTemplate, {
  commonInputDefinitions,
} from "src/layouts/RateQuoteTemplate";
import { getProfessions } from "src/store/profileBuild/selector";

const neoLogo = "/assets/images/png/logo_neohomeloans.png";
const movementLogo = "/assets/images/png/logo_movementmortgage_alt.png";

const HelocRates = () => {
  const professions: any = useSelector(getProfessions);

  useEffect(() => {
    helocPingApi();
  }, []);

  const inputDefinitions = cloneDeep(commonInputDefinitions);
  inputDefinitions[0].push(
    {
      label: "Estimated Home Value",
      name: "home_value",
      type: "dollars",
    },
    {
      label: "Current Mortgage Balance",
      name: "mortgage_balance",
      type: "dollars",
    },
    {
      label: "Your Estimated Credit Score",
      name: "credit_score",
      type: "number",
    },
    {
      label: "Your Profession",
      name: "profession",
      type: "select",
      values: professions.map((item: { name: string; id: string }) => ({
        label: item.name,
        value: item.id,
      })),
    }
  );
  inputDefinitions[1].splice(1, 0, {
    label: "Houseold Total Mortgage Payment",
    name: "mortgage_payment",
    type: "dollars",
  });

  return (
    <RateQuoteTemplate
      pageTitle="Apply For A Home Equity Line Of Credit (HELOC)"
      introTitle="Getting a HELOC"
      introText={
        <>
          <Typography variant="body2" paragraph>
            A home equity line of credit (HELOC) is a great tool to reduce the
            risk of your financial plan as it offers additional financial
            flexibility. The best part is that it won't cost you anything if you
            don't need to use it.
          </Typography>
          <Typography variant="body2" paragraph>
            We've partnered with two lenders who can provide with HELOC rates.
          </Typography>
          <Typography variant="body2" paragraph>
            Click Let's Get Started to continue.
          </Typography>
        </>
      }
      isHeloc
      mortgageDetailsInputs={inputDefinitions}
      availableLenders={[
        {
          name: "Neo Home Loans",
          id: "neo",
          logo: neoLogo,
          text: (
            <>
              <Typography variant="body2" paragraph>
                FitBUX Members who have used Neo Home Loans only have good
                things to say about their experience.
              </Typography>
              <Typography variant="body2" paragraph>
                Neo Home Loans offers first time home buyers loans, VA loans,
                medical professional loans, HELOCs, investment properties loans,
                and more.
              </Typography>
            </>
          ),
        },
        {
          name: "Movement Mortgage",
          id: "movement",
          logo: movementLogo,
          text: (
            <>
              <Typography variant="body2" paragraph>
                Want to know how good Movement is? FitBUX’s founder Joseph
                Reinke has been using them for over 20 years!
              </Typography>
              <Typography variant="body2" paragraph>
                Movement offers first time home buyers loans, VA loans, medical
                professional loans, HELOCs, investment properties loans, and
                more.
              </Typography>
            </>
          ),
        },
      ]}
      endText={
        <>
          <Typography paragraph>
            Next, a loan officer from the selected lender(s) will contact you to
            set up a call to gather more details and review your offers.
          </Typography>
          <Typography paragraph>
            If you do not hear from them within 1 business day, please contact
            your FitBUX Coach, who will be happy to help.
          </Typography>
        </>
      }
      submitApi={submitHelocLeadApi}
    />
  );
};

export default HelocRates;
