import { QuestionForm } from "./questionnaire.interface";

const date = new Date();
const dateString = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}`;

export interface QuestionFormProps {
  planData: OptimizedPlan;
  planSetter: (value: OptimizedPlan) => void;
  nextClicked: number;
  goToNext: () => void;
  reviewStepIndex: number;
  onRecapClick: (i: number) => void;
  subIndex?: number;
}

export interface OptimizedPlanBuilderMarried {
  moving: QuestionForm;
  auto: QuestionForm;
  nonProfit: QuestionForm;
  incomeIncrease: QuestionForm;
  incomeDecrease: QuestionForm;
  employerRetirement: QuestionForm;
  hsa: QuestionForm;
  children: QuestionForm;
  addChildren: QuestionForm;
  addIncomeIncrease: QuestionForm;
  addIncomeDecrease: QuestionForm;
}

export interface OptimizedPlanBuilder extends OptimizedPlanBuilderMarried {
  marriage: QuestionForm;
}

export interface OptimizedPlanBuilderSpouse {
  employerRetirement: QuestionForm;
}

// Going to need to store this in redux
export const emptyPlan: OptimizedPlan = {
  back_to_school: "n",
  school_start_date: "",
  school_grad_date: "",
  will_nonprofit: "n",
  s_back_to_school: "n",
  s_school_start_date: "",
  s_school_grad_date: "",
  s_will_nonprofit: "n",
  will_marry: "n",
  marry_date: "",
  marry_cost: 0,
  marry_spouse_income: 0,
  marry_spouse_fed_loan_balance: 0,
  marry_spouse_priv_loan_balance: 0,
  children_dates: [],
  child_income_drop: [],
  s_child_income_drop: [],
  income_increase: [],
  income_increase_date: [],
  income_decrease: [],
  income_decrease_date: [],
  s_income_increase: [],
  s_income_increase_date: [],
  s_income_decrease: [],
  s_income_decrease_date: [],
  will_move: "n",
  move_date: "",
  move_zip_code: "",
  vehicle_date: "",
  vehicle_down: 0,
  vehicle_cost: 0,
  vehicle_type: "add",
  retirement_match: 100,
  retirement_limit: 0,
  retirement_additional_match: 50,
  retirement_additional_limit: 0,
  retirement_nonelective_contribution: 0,
  s_retirement_match: 100,
  s_retirement_limit: 0,
  s_retirement_additional_match: 50,
  s_retirement_additional_limit: 0,
  s_retirement_nonelective_contribution: 0,
  has_401k: "n",
  has_roth_401k: "n",
  has_hsa: "n",
  s_has_401k: "n",
  s_has_roth_401k: "n",
};

export interface OptimizedPlan {
  back_to_school: string;
  school_start_date: string;
  school_grad_date: string;
  will_nonprofit: string;
  s_back_to_school: string;
  s_school_start_date: string;
  s_school_grad_date: string;
  s_will_nonprofit: string;
  will_marry: string;
  marry_date: string;
  marry_cost: number;
  marry_spouse_income: number;
  marry_spouse_fed_loan_balance: number;
  marry_spouse_priv_loan_balance: number;
  children_dates: string[];
  child_income_drop: (number | null)[];
  s_child_income_drop: (number | null)[];
  income_increase: number[];
  income_increase_date: string[];
  income_decrease: number[];
  income_decrease_date: string[];
  s_income_increase: number[];
  s_income_increase_date: string[];
  s_income_decrease: number[];
  s_income_decrease_date: string[];
  will_move: string;
  move_date: string;
  move_zip_code: string;
  vehicle_date: string;
  vehicle_down: number;
  vehicle_cost: number;
  vehicle_type: "add" | "replace";
  retirement_match: number;
  retirement_limit: number;
  retirement_additional_match: number;
  retirement_additional_limit: number;
  retirement_nonelective_contribution: number;
  s_retirement_match: number;
  s_retirement_limit: number;
  s_retirement_additional_match: number;
  s_retirement_additional_limit: number;
  s_retirement_nonelective_contribution: number;
  has_401k: string;
  has_roth_401k: string;
  has_hsa: string;
  s_has_401k: string;
  s_has_roth_401k: string;
}

export interface recapEntryOptions {
  nonprofit?: optimizedRecapItem;
  incomeIncrease?: optimizedRecapItem;
  incomeDecrease?: optimizedRecapItem;
  employerRetirement?: optimizedRecapItem;
  married?: optimizedRecapItem;
  child?: optimizedRecapItem;
  moving?: optimizedRecapItem;
  auto?: optimizedRecapItem;
}

export interface optimizedRecapItem {
  description: string;
  details?: string;
  stepIndex: number;
  who?: "applicant" | "spouse";
  imageName?: string;
  subIndex?: number;
  resetKeys?: string[];
  resetKeysAtIndex?: string[];
}

export const singleQuestions: OptimizedPlanBuilder = {
  moving: {
    emptyValue: {
      will_move: "n",
      move_date: dateString, //!: datestring
      move_zip_code: "",
    },
    typeLabel: "5. Moving To A Different Area",
    fields: [
      {
        field: "will_move", //info that will be captured on the collection
        type: "radio", //
        gridWidth: 12,
        label:
          "Do you see yourself moving to a new city or state within the next 5 years?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "move_date",
        label: "When do you plan on moving?",
        type: "monthYear",
        enabled: ["will_move"],
      },
      {
        field: "move_zip_code",
        label: "What ZIP code do you plan on moving to?",
        type: "zip",
        gridWidth: 6,
        enabled: ["will_move"],
      },
    ],
  },
  auto: {
    emptyValue: {
      preReq: "n",
      vehicle_date: dateString,
      vehicle_down: "",
      vehicle_cost: 0,
      vehicle_type: "add",
    },
    icon: "fb-car",
    buttonLabel: "Purchase a Vehicle",
    typeLabel: "6. Purchase a Vehicle",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio", //
        gridWidth: 12,
        label: "Do you expect to buy a car within the next 3 years?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "vehicle_type",
        label: "Are you adding a new vehicle or replacing an old one?",
        type: "select",
        enabled: ["preReq"],
        select: [
          {
            label: "Adding",
            value: "add",
          },
          {
            label: "Replacing",
            value: "replace",
          },
        ],
      },
      {
        field: "vehicle_date",
        label: "When do you plan on buying a vehicle?",
        type: "monthYear",
        enabled: ["preReq"],
      },
      {
        field: "vehicle_cost",
        label:
          "What is the estimated cost of the vehicle you are thinking of buying?",
        type: "dollar",
        enabled: ["preReq"],
      },
      {
        field: "vehicle_down",
        label: "What do you expect to cover in cash (the down payment)?",
        type: "dollar",
        enabled: ["preReq"],
      },
    ],
  },
  nonProfit: {
    emptyValue: {
      preReq: "n",
      will_nonprofit: "n",
    },
    typeLabel: "1. Work Status & Income",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio", //
        gridWidth: 12,
        label: "Do you work for a non-profit",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "will_nonprofit", //info that will be captured on the collection
        type: "radio", //
        gridWidth: 12,
        label: "Are you planning to work for a non-profit long term?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
        enabled: ["preReq"],
      },
    ],
  },
  incomeIncrease: {
    emptyValue: {
      preReq: "n",
      income_increase: 0,
      income_increase_date: dateString,
    },
    typeLabel: "1. Work Status & Income",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "Do you expect to have a significant increase in income (greater than a 5% annual increase)?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "income_increase_date", //info that will be captured on the collection
        type: "monthYear",
        gridWidth: 6,
        label: "When do you expect the increase?",
        date: dateString,
        enabled: ["preReq"],
      },
      {
        field: "income_increase", //info that will be captured on the collection
        type: "dollar",
        gridWidth: 6,
        label: "How much will your new total annual income be?",
        date: dateString,
        enabled: ["preReq"],
      },
    ],
  },
  incomeDecrease: {
    emptyValue: {
      preReq: "n",
      income_decrease: 0,
      income_decrease_date: dateString,
    },
    typeLabel: "1. Work Status & Income",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "Do you expect to have a significant decrease in income (greater than a 5% annual decrease)?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "income_decrease_date", //info that will be captured on the collection
        type: "monthYear",
        gridWidth: 6,
        label: "When do you expect the decrease?",
        date: dateString,
        enabled: ["preReq"],
      },
      {
        field: "income_decrease", //info that will be captured on the collection
        type: "dollar",
        gridWidth: 6,
        label: "How much will your new total annual income be?",
        date: dateString,
        enabled: ["preReq"],
      },
    ],
  },
  employerRetirement: {
    emptyValue: {
      has_401k: "n",
      retirement_limit: 0,
      retirement_match: 100,
      preReq1: "n",
      retirement_additional_limit: 0,
      retirement_additional_match: 100,
      has_roth_401k: "n",
      retirement_nonelective_contribution: 0,
      preReq3: "n",
    },
    typeLabel: "2. Employer Retirement Plans",
    fields: [
      {
        field: "has_401k", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "Does your employer offer a 401(K), Roth 401K, 403(b), 457, or a TSP?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "retirement_limit", //info that will be captured on the collection
        type: "percent",
        gridWidth: 6,
        label: "What % of your eligible income does your employer match?",
        enabled: ["has_401k"],
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
      {
        field: "retirement_match", //info that will be captured on the collection
        type: "percent",
        gridWidth: 6,
        enabled: ["has_401k"],
        label: "Employer match %",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
      {
        field: "preReq1", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label: "Does your employer offer additional match?",
        enabled: ["has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "retirement_additional_limit", //info that will be captured on the collection
        type: "percent",
        gridWidth: 6,
        label: "What additional % of your eligible income do they match?",
        enabled: ["preReq1", "has_401k"],
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
      {
        field: "retirement_additional_match", //info that will be captured on the collection
        type: "percent", //
        gridWidth: 6,
        enabled: ["preReq1", "has_401k"],
        label: "Employer match %",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
      {
        field: "has_roth_401k", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label: "Does your employer offer a Roth 401k?",
        enabled: ["has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "preReq3", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "Does your employer offer contributions to your retirement without you having to contribute?",
        enabled: ["has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "retirement_nonelective_contribution", //info that will be captured on the collection
        type: "percent",
        gridWidth: 6,
        enabled: ["has_401k", "preReq3"],
        label: "Employer contribution as a % of my eligible income",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
    ],
  },
  hsa: {
    emptyValue: {
      has_hsa: "n",
    },
    typeLabel: "2. Health Savings Account",
    help: [
      {
        header: "Recommendation For HSA",
        body: [
          "If you choose a high deductible health insurance plan through your employer and they offer an HSA, we highly recommend contributing at least $50 per month to the HSA.  Doing so will help with retirement income diversification.",
          "Retirement income diversification is when you hold your assets in as many different types of accounts as you can.  Therefore, at retirement, if the government changes how they tax one type of an account, you have others that won't be affected.",
        ],
      },
      {
        header: "What is a Health Savings Account?",
        body: [
          "Health Savings Accounts (HSA for short) are tax advantaged accounts with special features for withdraws related to health care costs. Below is a summary of features:",
          "1) Contributions you make each year reduce how much you pay in taxes in the year the contribution was made.",
          "2) You invest the money and you don't pay taxes each year on investment gains.",
          "3) If you have a medical expense, you can take the money out anytime you want and not pay penalties or taxes on it.",
          "4) After age 65, you can withdraw the money for any reason without penalty. However, if you take the money out for non-medical reasons, you'll owe income tax on it.",
        ],
      },
    ],
    fields: [
      {
        field: "has_hsa", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label: "Does your employer offer a Health Savings Account (HSA)?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
    ],
  },
  marriage: {
    emptyValue: {
      will_marry: "n",
      eventtype: "marriage",
      marry_date: dateString,
      marry_spouse_income: 0,
      marry_cost: 0,
      // marry_spouse_income: 0,
      enable_fedloan: false,
      enable_privloan: false,
      marry_spouse_fed_loan_balance: 0,
      marry_spouse_priv_loan_balance: 0,
    },
    icon: "fb-favorite",
    buttonLabel: "Get Married",
    typeLabel: "3. Getting Married",
    fields: [
      {
        field: "will_marry", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label: "Are you planning on getting married in the next 3 years?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "marry_date",
        gridWidth: 12,
        label: "When do you plan on getting married?",
        width: "50%",
        type: "monthYear",
        enabled: ["will_marry"],
      },
      {
        field: "marry_spouse_income",
        label: "Future spouse's total annual income?",
        type: "dollar",
        enabled: ["will_marry"],
      },
      {
        field: "marry_cost",
        label: "Anticipated wedding costs?",
        type: "dollar",
        enabled: ["will_marry"],
      },
      {
        field: "enable_fedloan",
        label: "Does your spouse have federal student loans?",
        type: "radio",
        gridWidth: 12,
        enabled: ["will_marry"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "enable_privloan",
        label: "Does your spouse have private student loans?",
        type: "radio",
        gridWidth: 12,
        enabled: ["will_marry"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      // {
      // 	field: "spouseFedLoanPlan",
      // 	label: "Federal student loan repayment plan",
      // 	type: "select",
      // 	select: map(REPAYMENT_PLANS, (label, value) => ({ label, value })),
      // 	enabled: ["enable_fedloan"],
      // },
      {
        field: "marry_spouse_fed_loan_balance",
        label: "Federal student loan balance",
        type: "dollar",
        enabled: ["enable_fedloan", "will_marry"],
      },
      {
        field: "marry_spouse_priv_loan_balance",
        label: "Private student loan balance",
        type: "dollar",
        enabled: ["enable_privloan", "will_marry"],
      },
      // {
      // 	field: "filing_jointly",
      // 	label: "Expected tax filing status",
      // 	type: "select",
      // 	select: [
      // 		{
      // 			label: "Married filing jointly",
      // 			value: true,
      // 		},
      // 		{
      // 			label: "Married filing separately",
      // 			value: false,
      // 		},
      // 	],
      // 	enabled: ["spouseFedLoanPlan"],
      // 	enabled_values: SPECIAL_REPAYMENTS,
      // },
    ],
  },
  children: {
    emptyValue: {
      preReq: "n",
      eventtype: "child",
      children_dates: dateString,
      preReq1: "n",
      who: "applicant",
      child_income_drop: 0,
    },
    help: [
      {
        header: "Family Planning Made Easy!",
        body: [
          "You can use this life event for multiple purposes.  Our technology automatically adjust living expenses, investment contributions, and debt payment as your family grows.",
          "You can also compare the overall impact of staying at home vs continuing to work once your children are born.  Simply, build two plans. One showing you are going to reduce your work, the other whereby you stay working. Then compare the results!",
        ],
      },
    ],
    icon: "fb-baby",
    buttonLabel: "Having a Child",
    typeLabel: "4. Children",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio", //
        gridWidth: 12,
        label: "Will you be adding children to your family in the future?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "children_dates",
        label: "When do you plan on having a child?",
        type: "monthYear",
        enabled: ["preReq"],
      },
      {
        field: "preReq1",
        label: "Do you or your spouse plan on reducing your work hours?",
        type: "select",
        enabled: ["preReq"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "who",
        label: "Who is going to reduce their work hours?",
        type: "select",
        select: [
          {
            label: "Me",
            value: "applicant",
          },
          {
            label: "My spouse",
            value: "spouse",
          },
        ],
        enabled: ["preReq1", "preReq"],
      },
      {
        field: "child_income_drop",
        label: "What will be their new total annual income?",
        type: "dollar",
        gridWidth: 6,
        enabled: ["preReq1", "preReq"],
      },
    ],
  },
  // note: the difference between the add and the original is the label field within the fields array
  // note: need a more efficient option for this
  addChildren: {
    emptyValue: {
      preReq: "n",
      eventtype: "child",
      children_dates: dateString,
      preReq1: "n",
      who: "applicant",
      salary: "",
    },
    help: [
      {
        header: "Family Planning Made Easy!",
        body: [
          "You can use this life event for multiple purposes.  Our technology automatically adjust living expenses, investment contributions, and debt payment as your family grows.",
          "You can also compare the overall impact of staying at home vs continuing to work once your children are born.  Simply, build two plans. One showing you are going to reduce your work, the other whereby you stay working. Then compare the results!",
        ],
      },
    ],
    icon: "fb-baby",
    buttonLabel: "Having a Child",
    typeLabel: "4. Children",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio", //
        gridWidth: 12,
        label:
          "You've already added a child to your plan, would you like to add another?", //! updated label
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "children_dates",
        label: "When do you plan on having a child?",
        type: "monthYear",
        gridWidth: 6,
        enabled: ["preReq"],
      },
      {
        field: "preReq1",
        label: "Do you or your spouse plan on reducing your work hours?",
        type: "select",
        gridWidth: 6,
        enabled: ["preReq"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "who",
        label: "Who is going to reduce their work hours?",
        type: "select",
        gridWidth: 6,
        select: [
          {
            label: "Me",
            value: "applicant",
          },
          {
            label: "My spouse",
            value: "spouse",
          },
        ],
        enabled: ["preReq1", "preReq"],
      },
      {
        field: "child_income_drop",
        label: "What will be their new total annual income?",
        type: "dollar",
        gridWidth: 6,
        enabled: ["preReq1", "preReq"],
      },
    ],
  },
  addIncomeIncrease: {
    emptyValue: {
      preReq: "n",
      income_increase: 0,
      income_increase_date: dateString,
    },
    typeLabel: "1. Work Status & Income",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "You have already added a significant income increase (greater than a 5% annual increase), would you like to add another?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "income_increase_date", //info that will be captured on the collection
        type: "monthYear",
        gridWidth: 6,
        label: "When do you expect the increase?",
        date: dateString,
        enabled: ["preReq"],
      },
      {
        field: "income_increase", //info that will be captured on the collection
        type: "dollar",
        gridWidth: 6,
        label: "How much will your new total annual income be?",
        date: dateString,
        enabled: ["preReq"],
      },
    ],
  },
  addIncomeDecrease: {
    emptyValue: {
      preReq: "n",
      income_decrease: 0,
      income_decrease_date: dateString,
    },
    typeLabel: "1. Work Status & Income",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "You have already added a significant income decrease (greater than a 5% annual decrease), would you like to add another?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "income_decrease_date", //info that will be captured on the collection
        type: "monthYear",
        gridWidth: 6,
        label: "When do you expect the decrease?",
        date: dateString,
        enabled: ["preReq"],
      },
      {
        field: "income_decrease", //info that will be captured on the collection
        type: "dollar",
        gridWidth: 6,
        label: "How much will your new total annual income be?",
        date: dateString,
        enabled: ["preReq"],
      },
    ],
  },
};

export const marriedQuestions: OptimizedPlanBuilderMarried = {
  moving: {
    emptyValue: {
      will_move: "n",
      move_date: dateString, //!: datestring
      move_zip_code: "",
    },
    typeLabel: "5. Moving To A Different Area",
    fields: [
      {
        field: "will_move", //info that will be captured on the collection
        type: "radio", //
        gridWidth: 12,
        label:
          "Do you see yourself moving to a new city or state within the next 5 years?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "move_date",
        label: "When do you plan on moving?",
        type: "monthYear",
        enabled: ["will_move"],
      },
      {
        field: "move_zip_code",
        label: "What ZIP code do you plan on moving to?",
        type: "zip",
        gridWidth: 6,
        enabled: ["will_move"],
      },
    ],
  },
  auto: {
    emptyValue: {
      preReq: "n",
      vehicle_date: dateString,
      vehicle_down: "",
      vehicle_cost: 0,
      vehicle_type: "add",
    },
    icon: "fb-car",
    buttonLabel: "Purchase a Vehicle",
    typeLabel: "6. Purchase a Vehicle",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio", //
        gridWidth: 12,
        label: "Do you expect to buy a car within the next 3 years?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "vehicle_type",
        label: "Are you adding a new vehicle or replacing an old one?",
        type: "select",
        enabled: ["preReq"],
        select: [
          {
            label: "Adding",
            value: "add",
          },
          {
            label: "Replacing",
            value: "replace",
          },
        ],
      },
      {
        field: "vehicle_date",
        label: "When do you plan on buying a vehicle?",
        type: "monthYear",
        enabled: ["preReq"],
      },
      {
        field: "vehicle_cost",
        label:
          "What is the estimated cost of the vehicle you are thinking of buying?",
        type: "dollar",
        enabled: ["preReq"],
      },
      {
        field: "vehicle_down",
        label: "What do you expect to cover in cash (the down payment)?",
        type: "dollar",
        enabled: ["preReq"],
      },
    ],
  },
  nonProfit: {
    emptyValue: {
      preReq: "n",
      will_nonprofit: "n",
      s_will_nonprofit: "n",
    },
    typeLabel: "1. Work Status & Income",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio", //
        gridWidth: 12,
        label: "Do you or your spouse work for a non-profit?",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes, I do",
            value: "a",
          },
          {
            label: "Yes, My Spouse Does",
            value: "s",
          },
          {
            label: "Yes, both of us do",
            value: "y",
          },
        ],
      },
      {
        field: "will_nonprofit", //info that will be captured on the collection
        type: "radio", //
        gridWidth: 12,
        label: "Are you planning to work for a non-profit long term?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
        enabled: ["preReq"],
        enabling_values: [["y", "a"]],
      },
      {
        field: "s_will_nonprofit", //info that will be captured on the collection
        type: "radio", //
        gridWidth: 12,
        label: "Is you spouse planning to work for a non-profit long term?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
        enabled: ["preReq"],
        enabling_values: [["y", "s"]],
      },
    ],
  },
  incomeIncrease: {
    emptyValue: {
      preReq: "n",
      income_increase: null,
      income_increase_date: dateString,
      s_income_increase: null,
      s_income_increase_date: dateString,
    },
    typeLabel: "1. Work Status & Income",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "Do you expect to have a significant increase in income (greater than a 5% annual increase)?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes, I do",
            value: "a", //applicant
          },
          {
            label: "Yes, my spouse does",
            value: "s", //spouse
          },
          {
            label: "Yes, both of us do",
            value: "y",
          },
        ],
      },
      {
        field: "income_increase_date", //info that will be captured on the collection
        type: "monthYear",
        gridWidth: 6,
        label: "When does your expect the increase?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["a", "y"]],
      },
      {
        field: "income_increase", //info that will be captured on the collection
        type: "dollar",
        gridWidth: 6,
        label: "How much will your new total annual income be?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["a", "y"]],
      },
      {
        field: "s_income_increase_date", //info that will be captured on the collection
        type: "monthYear",
        gridWidth: 6,
        label: "When does your spouse expect the increase?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["s", "y"]],
      },
      {
        field: "s_income_increase", //info that will be captured on the collection
        type: "dollar",
        gridWidth: 6,
        label: "How much will your spouse's new total annual income be?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["s", "y"]],
      },
    ],
  },
  incomeDecrease: {
    emptyValue: {
      preReq: "n",
      income_decrease: null,
      income_decrease_date: dateString,
      s_income_decrease: null,
      s_income_decrease_date: dateString,
    },
    typeLabel: "1. Work Status & Income",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "Do you expect to have a significant decrease in income (greater than a 5% annual decrease)?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes, I do",
            value: "a", //applicant
          },
          {
            label: "Yes, my spouse does",
            value: "s", //spouse
          },
          {
            label: "Yes, both of us do",
            value: "y",
          },
        ],
      },
      {
        field: "income_decrease_date", //info that will be captured on the collection
        type: "monthYear",
        gridWidth: 6,
        label: "When do you expect the decrease?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["a", "y"]],
      },
      {
        field: "income_decrease", //info that will be captured on the collection
        type: "dollar",
        gridWidth: 6,
        label: "How much will your new total annual income be?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["a", "y"]],
      },
      {
        field: "s_income_decrease_date", //info that will be captured on the collection
        type: "monthYear",
        gridWidth: 6,
        label: "When does your spouse expect the decrease?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["s", "y"]],
      },
      {
        field: "s_income_decrease", //info that will be captured on the collection
        type: "dollar",
        gridWidth: 6,
        label: "How much will your spouse's new total annual income be?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["s", "y"]],
      },
    ],
  },
  employerRetirement: {
    emptyValue: {
      has_401k: "n",
      retirement_limit: 0,
      retirement_match: 100,
      preReq1: "n",
      retirement_additional_limit: 0,
      retirement_additional_match: 100,
      has_roth_401k: "n",
      retirement_nonelective_contribution: 0,
      preReq3: "n",
    },
    typeLabel: "2. Employer Retirement Plans",
    fields: [
      {
        field: "has_401k", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "Does your employer offer a 401(K), Roth 401K, 403(b), 457, or a TSP?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "retirement_limit", //info that will be captured on the collection
        type: "percent",
        gridWidth: 6,
        label: "What % of your eligible income does your employer match?",
        enabled: ["has_401k"],
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
      {
        field: "retirement_match", //info that will be captured on the collection
        type: "percent",
        gridWidth: 6,
        enabled: ["has_401k"],
        label: "Employer match %",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
      {
        field: "preReq1", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label: "Does your employer offer additional match?",
        enabled: ["has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "retirement_additional_limit", //info that will be captured on the collection
        type: "percent",
        gridWidth: 6,
        label: "What additional % of your eligible income do they match?",
        enabled: ["preReq1", "has_401k"],
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
      {
        field: "retirement_additional_match", //info that will be captured on the collection
        type: "percent", //
        gridWidth: 6,
        enabled: ["preReq1", "has_401k"],
        label: "Employer match %",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
      {
        field: "has_roth_401k", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label: "Does your employer offer a Roth 401k?",
        enabled: ["has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "preReq3", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "Does your employer offer contributions to your retirement without you having to contribute?",
        enabled: ["has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "retirement_nonelective_contribution", //info that will be captured on the collection
        type: "percent",
        gridWidth: 6,
        enabled: ["has_401k", "preReq3"],
        label: "Employer contribution as a % of my eligible income",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
    ],
  },
  hsa: {
    emptyValue: {
      has_hsa: "n",
    },
    typeLabel: "2. Health Savings Account",
    help: [
      {
        header: "Recommendation For HSA",
        body: [
          "If you choose a high deductible health insurance plan through your employer and they offer an HSA, we highly recommend contributing at least $50 per month to the HSA.  Doing so will help with retirement income diversification.",
          "Retirement income diversification is when you hold your assets in as many different types of accounts as you can.  Therefore, at retirement, if the government changes how they tax one type of an account, you have others that won't be affected.",
        ],
      },
      {
        header: "What is a Health Savings Account?",
        body: [
          "Health Savings Accounts (HSA for short) are tax advantaged accounts with special features for withdraws related to health care costs. Below is a summary of features:",
          "1) Contributions you make each year reduce how much you pay in taxes in the year the contribution was made.",
          "2) You invest the money and you don't pay taxes each year on investment gains.",
          "3) If you have a medical expense, you can take the money out anytime you want and not pay penalties or taxes on it.",
          "4) After age 65, you can withdraw the money for any reason without penalty. However, if you take the money out for non-medical reasons, you'll owe income tax on it.",
        ],
      },
    ],
    fields: [
      {
        field: "has_hsa", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "Does your or your spouse's employer offer a Health Savings Account (HSA)?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
    ],
  },
  children: {
    emptyValue: {
      preReq: "n",
      eventtype: "child",
      children_dates: dateString,
      preReq1: "n",
      who: "applicant",
      salary: "",
    },
    help: [
      {
        header: "Family Planning Made Easy!",
        body: [
          "You can use this life event for multiple purposes.  Our technology automatically adjust living expenses, investment contributions, and debt payment as your family grows.",
          "You can also compare the overall impact of staying at home vs continuing to work once your children are born.  Simply, build two plans. One showing you are going to reduce your work, the other whereby you stay working. Then compare the results!",
        ],
      },
    ],
    icon: "fb-baby",
    buttonLabel: "Having a Child",
    typeLabel: "4. Children",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio", //
        gridWidth: 12,
        label: "Will you be adding children to your family in the future?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "children_dates",
        label: "When do you plan on having a child?",
        type: "monthYear",
        enabled: ["preReq"],
      },
      {
        field: "preReq1",
        label: "Do you or your spouse plan on reducing your work hours?",
        type: "select",
        gridWidth: 6,
        enabled: ["preReq"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "who",
        label: "Who is going to reduce their work hours?",
        type: "select",
        gridWidth: 6,
        select: [
          {
            label: "Me",
            value: "applicant",
          },
          {
            label: "My spouse",
            value: "spouse",
          },
        ],
        enabled: ["preReq1", "preReq"],
      },
      {
        field: "child_income_drop",
        label: "What will be their new total annual income?",
        type: "dollar",
        gridWidth: 6,
        enabled: ["preReq1", "preReq"],
      },
    ],
  },
  // note: the difference between the add and the original is the label field within the fields array
  // note: need a more efficient option for this
  addChildren: {
    emptyValue: {
      preReq: "n",
      eventtype: "child",
      children_dates: dateString,
      preReq1: "n",
      who: "applicant",
      salary: "",
    },
    help: [
      {
        header: "Family Planning Made Easy!",
        body: [
          "You can use this life event for multiple purposes.  Our technology automatically adjust living expenses, investment contributions, and debt payment as your family grows.",
          "You can also compare the overall impact of staying at home vs continuing to work once your children are born.  Simply, build two plans. One showing you are going to reduce your work, the other whereby you stay working. Then compare the results!",
        ],
      },
    ],
    icon: "fb-baby",
    buttonLabel: "Having a Child",
    typeLabel: "4. Children",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio", //
        gridWidth: 12,
        label:
          "You've already added a child to your plan, would you like to add another?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "children_dates",
        label: "When do you plan on having a child?",
        type: "monthYear",
        enabled: ["preReq"],
      },
      {
        field: "preReq1",
        label: "Do you or your spouse plan on reducing your work hours?",
        type: "select",
        gridWidth: 6,
        enabled: ["preReq"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "who",
        label: "Who is going to reduce their work hours?",
        type: "select",
        gridWidth: 6,
        select: [
          {
            label: "Me",
            value: "applicant",
          },
          {
            label: "My spouse",
            value: "spouse",
          },
        ],
        enabled: ["preReq1"],
      },
      {
        field: "child_income_drop",
        label: "What will be their new total annual income?",
        type: "dollar",
        gridWidth: 6,
        enabled: ["preReq1"],
        whoText: "reduce",
      },
    ],
  },
  addIncomeIncrease: {
    emptyValue: {
      preReq: "n",
      income_increase: 0,
      income_increase_date: dateString,
      s_income_increase: 0,
      s_income_increase_date: dateString,
    },
    typeLabel: "1. Work Status & Income",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "You have already added a significant income increase (greater than a 5% annual increase), would you like to add another?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes, I do",
            value: "a", //applicant
          },
          {
            label: "Yes, my spouse does",
            value: "s", //spouse
          },
          {
            label: "Yes, both of us do",
            value: "y",
          },
        ],
      },
      {
        field: "income_increase_date", //info that will be captured on the collection
        type: "monthYear",
        gridWidth: 6,
        label: "When do you expect the increase?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["a", "y"]],
      },
      {
        field: "income_increase", //info that will be captured on the collection
        type: "dollar",
        gridWidth: 6,
        label: "How much will your new total annual income be?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["a", "y"]],
      },
      {
        field: "s_income_increase_date", //info that will be captured on the collection
        type: "monthYear",
        gridWidth: 6,
        label: "When does your spouse expect the increase?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["s", "y"]],
      },
      {
        field: "s_income_increase", //info that will be captured on the collection
        type: "dollar",
        gridWidth: 6,
        label: "How much will your spouse's new total annual income be?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["s", "y"]],
      },
    ],
  },
  addIncomeDecrease: {
    emptyValue: {
      preReq: "n",
      income_decrease: 0,
      income_decrease_date: dateString,
      s_income_decrease: 0,
      s_income_decrease_date: dateString,
    },
    typeLabel: "1. Work Status & Income",
    fields: [
      {
        field: "preReq", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "You have already added a significant income decrease (greater than a 5% annual decrease), would you like to add another?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes, I do",
            value: "a", //applicant
          },
          {
            label: "Yes, my spouse does",
            value: "s", //spouse
          },
          {
            label: "Yes, both of us do",
            value: "y",
          },
        ],
      },
      {
        field: "income_decrease_date", //info that will be captured on the collection
        type: "monthYear",
        gridWidth: 6,
        label: "When do you expect the decrease?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["a", "y"]],
      },
      {
        field: "income_decrease", //info that will be captured on the collection
        type: "dollar",
        gridWidth: 6,
        label: "How much will your new total annual income be?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["a", "y"]],
      },
      {
        field: "s_income_decrease_date", //info that will be captured on the collection
        type: "monthYear",
        gridWidth: 6,
        label: "When does your spouse expect the decrease?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["s", "y"]],
      },
      {
        field: "s_income_decrease", //info that will be captured on the collection
        type: "dollar",
        gridWidth: 6,
        label: "How much will your spouse's new total annual income be?",
        date: dateString,
        enabled: ["preReq"],
        enabling_values: [["s", "y"]],
      },
    ],
  },
};

export const spouseQuestions: OptimizedPlanBuilderSpouse = {
  employerRetirement: {
    emptyValue: {
      s_has_401k: "n",
      s_retirement_match: 100,
      s_retirement_limit: 0,
      preReq1: "n",
      s_retirement_additional_match: 100,
      s_retirement_additional_limit: 0,
      s_has_roth_401k: "n",
      s_retirement_nonelective_contribution: 0,
      preReq3: "n",
    },
    typeLabel: "2. Employer Retirement Plans",
    fields: [
      {
        field: "s_has_401k", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "Does your spouse's employer offer a 401(K), Roth 401K, 403(b), 457, or a TSP?",
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "s_retirement_limit", //info that will be captured on the collection
        type: "percent",
        gridWidth: 6,
        label:
          "What % of your eligible income does your spouse's employer match?",
        enabled: ["s_has_401k"],
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
      {
        field: "s_retirement_match", //info that will be captured on the collection
        type: "percent",
        gridWidth: 6,
        enabled: ["s_has_401k"],
        label: "Employer match %",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
      {
        field: "preReq1", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label: "Does your spouse's employer offer additional match?",
        enabled: ["s_has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "s_retirement_additional_limit", //info that will be captured on the collection
        type: "percent", //
        gridWidth: 6,
        enabled: ["preReq1", "s_has_401k"],
        label: "Employer match %",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
      {
        field: "s_retirement_additional_match", //info that will be captured on the collection
        type: "percent",
        gridWidth: 6,
        label: "What additional % of your eligible income do they match?",
        enabled: ["preReq1", "s_has_401k"],
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
      {
        field: "s_has_roth_401k", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label: "Does your spouse's employer offer a Roth 401k?",
        enabled: ["s_has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "preReq3", //info that will be captured on the collection
        type: "radio",
        gridWidth: 12,
        label:
          "Does your spouse's employer offer contributions to your retirement without you having to contribute?",
        enabled: ["s_has_401k"],
        select: [
          {
            label: "No",
            value: "n",
          },
          {
            label: "Yes",
            value: "y",
          },
        ],
      },
      {
        field: "s_retirement_nonelective_contribution", //info that will be captured on the collection
        type: "percent",
        gridWidth: 6,
        enabled: ["s_has_401k", "preReq3"],
        label: "Employer contribution as a % of my eligible income",
        info:
          "Includes being employed by the government, a 501(c)(3) not-for-profit, or other not-for-profit organization that provides a qualifying service.",
      },
    ],
  },
};
