import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { textFieldStyles } from "../../theme/base";

const useStyles = makeStyles(textFieldStyles);

const FbTextField: React.FC<TextFieldProps> = ({ className, ...props }) => {
  const classes = useStyles();

  return <TextField className={clsx(className, classes.root)} {...props} />;
};

export default FbTextField;
