import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";

import Income from "./Income";
import Expenses from "./Expenses";
import GetStarted from "./GetStarted";

const incomeAndExpensesView = {
  [PROFILE_BUILD_STEPS.INCOME_EXPENSES_INTRO]: GetStarted,
  [PROFILE_BUILD_STEPS.MY_INCOME]: Income,
  [PROFILE_BUILD_STEPS.SPOUSE_INCOME]: Income,
  [PROFILE_BUILD_STEPS.EXPENSES]: Expenses,
};

export default incomeAndExpensesView;
