import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { Grid } from "@material-ui/core";

import { Header } from "./Blocks/Header";
import { Cards } from "./Blocks/Cards";
import { getRouterState } from "src/store/router/selector";

const TOOL_NAMES = ["payoffAnalysis", "payoffVsIDR", "refinance", "investment"];

export const Content = () => {
  const dispatch = useDispatch();
  const router = useSelector(getRouterState);

  const gotoTool = (toolName: string) => {
    dispatch(push(`tools/${toolName}`));
  };

  useEffect(() => {
    if (router.location.hash) {
      const targetTool = router.location.hash.slice(1);
      if (new Set(TOOL_NAMES).has(targetTool)) {
        gotoTool(targetTool);
      }
    }
  }, [router]);

  return (
    <>
      <Grid container spacing={2} style={{ maxWidth: 900 }}>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <Cards setTool={gotoTool} />
        </Grid>
        {/* <Grid item xs={12}>
          <Intro
            heading={heading}
            description={description}
            buttons={buttons}
            img={img}
            specialHeading={specialHeading}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

export default Content;
