export const solutionsText = {
  heading: "FitBUX Tools",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem feugiat leo sed at pellentesque scelerisque sit nunc sapien. Ultrices auctor nam enim, sagittis varius. Sit enim.",
};

export const cardsData = [
  {
    heading: "Student Loan Pay Off Analysis",
    description: "",
    button: { text: "Analyze", value: "payoffAnalysis" },
  },
  {
    heading: "Should I Consider An Income-Driven Repayment Plan?",
    description: "",
    button: { text: "Analyze", value: "payoffVsIDR" },
  },
  {
    heading: "Should I Refinance My Student Loans?",
    description: "",
    button: { text: "Analyze", value: "refinance" },
  },
  {
    heading:
      "Get an Investment Recommendation & Build Your Investment Portfolio",
    description: "",
    button: { text: "Analyze", value: "investment" },
    subscribedOnly: true,
  },
];
