import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Grid } from "@material-ui/core";
import {
  getCurrentPlan,
  getComparePlan,
  getPlans,
} from "src/store/planBuild/selector";
import { Plan, PlanListRecord } from "src/interfaces";
import Button from "src/components/Button";

interface PlanSelectPopupProps {
  implement: (planId: number) => void;
  logout?: boolean;
}

const PlanSelectPopup = ({ implement, logout }: PlanSelectPopupProps) => {
  const [plans, setPlans]: any = useState([]);
  const currentPlan = useSelector(getCurrentPlan);
  const comparePlan = useSelector(getComparePlan);
  // TODO: get app plans
  const allPlans = useSelector(getPlans);

  useEffect(() => {
    updatePlans();
  }, []);

  const updatePlans = () => {
    if (logout) {
      setPlans([...allPlans]);
    } else {
      setPlans([currentPlan, comparePlan]);
    }
  };

  const getShortName = (name: string) => {
    if (name.length <= 33) {
      return name;
    } else {
      return `${name.slice(0, 30)}...`;
    }
  };

  const renderPlans = () => {
    if (plans) {
      return plans.map((plan: Plan | PlanListRecord, index: number) => {
        return (
          <Grid item id="Plan" key={index.toString()}>
            <Box
              style={{ width: "200px" }}
              // style={{backgroundColor: "red", whiteSpace: "nowrap", overflow: "hidden"}}
            >
              <Typography>{getShortName(plan.name)}</Typography>

              <Button
                fbColor="primary"
                onClick={() => {
                  implement(plan.id || 0);
                }}
              >
                Implement
              </Button>
            </Box>
          </Grid>
        );
      });
    }
  };

  return (
    <Box className="flex flex-col justify-between items-center h-36">
      <Typography variant="h1">Implement Your Plan</Typography>
      <Typography variant="h6">
        Which Plan Would You Like To Implement?
      </Typography>
      <Grid container spacing={10}>
        {renderPlans()}
      </Grid>
    </Box>
  );
};

export default PlanSelectPopup;
