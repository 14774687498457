import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Divider, makeStyles, MenuItem, Select } from "@material-ui/core";

import { setMonthlyOverview } from "src/store/planBuild/actions";
import {
  comparePlanAllocationTotals,
  comparePlanIncomeTotal,
  comparePlanExpenseTotal,
  comparePlanRiskManagementTotal,
  getCompareMonthlyExpenses,
  getCompareEmergencyMonths,
  currentPlanAllocationTotals,
  currentPlanIncomeTotal,
  currentPlanExpenseTotal,
  currentPlanRiskManagementTotal,
  getEmergencyMonthlyExpenses,
  getEmergencyMonths,
  livePlanAllocationTotals,
  livePlanIncomeTotal,
  livePlanExpenseTotal,
  livePlanRiskManagementTotal,
  getLiveMonthlyExpenses,
  getLiveEmergencyMonths,
  getIsMonthly,
} from "src/store/planBuild/selector";
import colors from "src/theme/colors";
import { formatPercent, formatWholeDollars } from "src/utils";
import Card from "src/components/Card";
import CircularProgressBar from "src/components/CircleProgressChart";

const styles = {
  emphasis: {
    color: colors.brandingBlue1,
    fontWeight: 600,
  },
  emphasis2: {
    color: colors.brandingBlue2,
    fontWeight: 600,
  },
  chartOption: {
    width: 100,
    // marginTop: 15,
    // marginBottom: 15,
    float: "right" as any,
    "&>div": {
      fontSize: 10,
      padding: `7px 10px`,
      color: colors.brandingBlue1,
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0px",
    "&>div": {
      fontSize: 10,
      width: "33.33%",
      textAlign: "center",
    },
    "& >div:nth-of-type(1)": {
      width: "50%",
    },
  },
};

const useStyles = makeStyles(styles);

const OverviewCard = ({
  compare,
  title,
  live,
}: {
  compare?: boolean;
  live?: boolean;
  title?: string;
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const isMonthly = useSelector(getIsMonthly);
  let incomeSelector: any = currentPlanIncomeTotal;
  let expenseSelector: any = currentPlanExpenseTotal;
  let riskSelector: any = currentPlanRiskManagementTotal;
  let monthlyExpenseSelector = getEmergencyMonthlyExpenses;
  let monthsSelector = getEmergencyMonths;
  let totalsSelector = currentPlanAllocationTotals;
  if (compare) {
    incomeSelector = comparePlanIncomeTotal;
    expenseSelector = comparePlanExpenseTotal;
    riskSelector = comparePlanRiskManagementTotal;
    monthlyExpenseSelector = getCompareMonthlyExpenses;
    monthsSelector = getCompareEmergencyMonths;
    totalsSelector = comparePlanAllocationTotals;
  } else if (live) {
    incomeSelector = livePlanIncomeTotal;
    expenseSelector = livePlanExpenseTotal;
    riskSelector = livePlanRiskManagementTotal;
    monthlyExpenseSelector = getLiveMonthlyExpenses;
    monthsSelector = getLiveEmergencyMonths;
    totalsSelector = livePlanAllocationTotals;
  }
  const income: number = useSelector(incomeSelector);
  const expenses: number = useSelector(expenseSelector);
  const risks: number = useSelector(riskSelector);
  const monthlyExpenses = useSelector(monthlyExpenseSelector);
  const emergencyMonths = useSelector(monthsSelector);
  const { assetsTotal, debtsTotal } = useSelector(totalsSelector);
  const cashAfterExpenses = income - expenses;
  const remainingMoney = cashAfterExpenses - (assetsTotal + debtsTotal + risks);

  const setIsMonthly = (e: React.ChangeEvent<any>) =>
    dispatch(setMonthlyOverview(e.target.value === "y"));

  const formatValue = (value: number) => {
    const formatted = isMonthly ? Math.round(value / 12) : value;
    return formatWholeDollars(formatted);
  };

  const chartData = [
    { key: "Total Income", value: income - remainingMoney },
    { key: "Vacation $", value: remainingMoney },
  ];

  return (
    <Card
      className="w-full mb-32"
      title={title || "Plan Details"}
      noPadding
      titleTag="h6"
      headComponent={
        <Select
          className={styles.chartOption}
          onChange={setIsMonthly}
          value={isMonthly ? "y" : "n"}
          variant="outlined"
          margin="dense"
        >
          <MenuItem value="y">Monthly</MenuItem>
          <MenuItem value="n">Annually</MenuItem>
        </Select>
      }
      style={{ borderRadius: 12 }}
    >
      <Divider />
      <Box className="flex justify-between">
        <Box className="w-2/5 p-6">
          <CircularProgressBar
            format="list"
            data={chartData}
            labelXOffset={-45}
            labelYOffset={-45}
          />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box className="pr-4 pb-3 w-1/2">
          <Box className="pt-1">
            <Box className={styles.row}>
              <Box className={`${styles.emphasis} text-left text-xs`}>
                Total Income
              </Box>
              <Box className={styles.emphasis}>{formatValue(income)}</Box>
              <Box className={styles.emphasis}>% Income</Box>
            </Box>
            <Box className={styles.row}>
              <Box className="text-left">Day-to-Day Exp.</Box>
              <Box>{formatValue(expenses)}</Box>
              <Box>{formatPercent((expenses / income) * 100)}</Box>
            </Box>
          </Box>
          <Divider />
          <Box className={styles.row}>
            <Box className="text-left">Remaining Cash</Box>
            <Box>{formatValue(cashAfterExpenses)}</Box>
            <Box>{formatPercent((cashAfterExpenses / income) * 100)}</Box>
          </Box>
          <Divider />
          <Box>
            <Box>
              <Box className={styles.row}>
                <Box className="text-left">Asset Contributions</Box>
                <Box>{formatValue(assetsTotal)}</Box>
                <Box>{formatPercent((assetsTotal / income) * 100)}</Box>
              </Box>
              <Box className={styles.row}>
                <Box className="text-left">Debt Payments</Box>
                <Box>{formatValue(debtsTotal)}</Box>
                <Box>{formatPercent((debtsTotal / income) * 100)}</Box>
              </Box>
              <Box className={styles.row}>
                <Box className="text-left">Risk Management</Box>
                <Box>{formatValue(risks)}</Box>
                <Box>{formatPercent((risks / income) * 100)}</Box>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box className={styles.row}>
            <Box className={`${styles.emphasis} text-left`}>
              Vacation/Fun Money
            </Box>
            <Box className={styles.emphasis}>{formatValue(remainingMoney)}</Box>
            <Box>{formatPercent((remainingMoney / income) * 100)}</Box>
          </Box>
          <Box className={styles.row}>
            <Box className={`${styles.emphasis2} text-left`}>
              Emergency Fund
            </Box>
            <Box className={styles.emphasis2}>
              {formatWholeDollars(monthlyExpenses * emergencyMonths)}
            </Box>
            <Box />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default OverviewCard;
