import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { SPECIAL_REPAYMENTS } from "src/interfaces";
import { getIdrData } from "src/store/dashboard/actions";
import { getIsSubscribed } from "src/store/system/selector";
import { getProfile, getSpouseProfile } from "src/store/profileBuild/selector";
import { investmentsStyles } from "src/theme";

const useStyles = makeStyles({
  ...investmentsStyles,
});

export const TaxBombIntro = ({ next, setShowSpouseData }: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const subscribed = useSelector(getIsSubscribed);
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);

  const applicantIdr =
    SPECIAL_REPAYMENTS.indexOf(myProfile.fed_repayment_plan || "") >= 0;
  const spouseIdr =
    SPECIAL_REPAYMENTS.indexOf(spouseProfile.fed_repayment_plan || "") >= 0;

  useEffect(() => {
    if (subscribed && (applicantIdr || spouseIdr)) {
      dispatch(getIdrData({ applicant: applicantIdr, spouse: spouseIdr }));
      if (spouseIdr && !applicantIdr) {
        setShowSpouseData(true);
      }
    }
  }, [subscribed, applicantIdr, spouseIdr]);

  return (
    <>
      <Card
        divider
        titleTag="h6"
        title="What This Recommendation Provides"
        className="mb-5"
      >
        <Box className="mt-5">
          <Typography className={classes.pageText} paragraph>
            When you are on an income-driven repayment plan (IBR, PAYE, REPAYE/SAVE,
            ICR) you have to pay a tax when the loans are forgiven. This
            technology:
          </Typography>
          <ol>
            <li>Estimates what the tax will be;</li>
            <li>
              Updates the tax estimate as your life changes and when tax laws
              change;
            </li>
            <li>Recommends a minimum amount you should save each month; and</li>
            <li>
              Illustrates how much you should have saved to date to be on "pace"
              to pay your tax.
            </li>
          </ol>
        </Box>
      </Card>
      <Button className={classes.continueButton} onClick={next}>
        Continue
      </Button>
    </>
  );
};

export default TaxBombIntro;
