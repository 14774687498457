import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Box, Typography } from "@material-ui/core";
import Button from "src/components/Button";
import { makeStyles } from "@material-ui/core";
import colors from "src/theme/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setUserAsAffiliate, getAffiliateLink } from "src/tapfiliate/tfclient";
import Copy from "@material-ui/icons/FilterNone";
import { userSelector } from "src/store/system/selector";
import { setReferralCode } from "src/store/account/actions";
import { getReferralCode } from "src/store/account/selector";

const TAPFILIATE_ADDRESS = process.env.REACT_APP_TAPFILIATE_ADDRESS || "";

const ReferAFriendButton = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const referralCode = useSelector(getReferralCode);
  const [state, setState] = useState({
    codeRequested: false,
    referralLink: referralCode ? `${TAPFILIATE_ADDRESS}${referralCode}` : "",
    recentlyCopied: false,
  });

  const userDetails = useSelector(userSelector);

  useEffect(() => {
    getReferralLink();
  }, []);

  useEffect(() => {
    if (referralCode) {
      setState({
        ...state,
        referralLink: `${TAPFILIATE_ADDRESS}${referralCode}`,
      });
      // setReferralURL(`${TAPFILIATE_ADDRESS}${referralCode}`);
    }
  }, [referralCode]);

  const getReferralLink = async () => {
    try {
      const userID = await getAffiliateLink();
      if (userID !== undefined) {
        setState({
          ...state,
          codeRequested: false,
          // referralLink: `${TAPFILIATE_ADDRESS}${userID}`
        });
        dispatch(setReferralCode(userID));
      }
    } catch {
      setState({ ...state, codeRequested: false });
    }
  };

  const setTFLink = async () => {
    if (!state.referralLink || state.referralLink === "") {
      setState({ ...state, codeRequested: true });
      const obj = {
        firstname: userDetails?.first_name || "",
        lastname: userDetails?.last_name || "",
        email: userDetails?.email || "",
      };
      try {
        const userID = await setUserAsAffiliate(obj);
        setState({
          ...state,
          codeRequested: false,
          // referralLink: `${TAPFILIATE_ADDRESS}${userID}`
        });
        if (typeof userID === "string") {
          dispatch(setReferralCode(userID));
        } else if (userID[0]) {
          dispatch(setReferralCode(userID[0].referrallink));
        }
      } catch (err) {
        getReferralLink();
      }
    } else {
      copyReferralLink();
    }
  };

  const copyReferralLink = () => {
    navigator.clipboard.writeText(state.referralLink);
    setState({ ...state, recentlyCopied: true });
    setTimeout(() => {
      setState({ ...state, recentlyCopied: false });
    }, 1000);
  };

  const getCopiedElement = () => {
    if (state.recentlyCopied) {
      return (
        <Box>
          {" "}
          <Typography color="primary"> Copied! </Typography>{" "}
        </Box>
      );
    } else {
      return <Copy color="primary" />;
    }
  };

  const ReferralLink = () => {
    return (
      <>
        <Box className={styles.referralLink} onClick={copyReferralLink}>
          <Grid
            container
            direction="row"
            // justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Box style={{ fontSize: "11px", textAlign: "left" }}>
                Referral Code:
              </Box>
              <Box style={{ fontSize: "11px", textAlign: "left" }}>
                {state.referralLink}
              </Box>
            </Grid>

            {/* <Grid> */}
            <Box
              style={{
                width: "25px",
                height: "20px",
                margin: "auto 20px 0px 20px",
              }}
            >
              {getCopiedElement()}
            </Box>
            {/* </Grid> */}
          </Grid>
        </Box>
        <Box />
      </>
    );
  };

  return (
    <>
      <Button
        fbColor="secondary"
        style={{ display: "block", margin: "20px auto" }}
        onClick={setTFLink}
        disabled={!!state.codeRequested}
      >
        {state.codeRequested && (
          <CircularProgress size={20} color="primary" thickness={5} />
        )}

        {!state.codeRequested && !state.referralLink && (
          <Typography>Show My Referral Link</Typography>
        )}

        {!state.codeRequested && state.referralLink && <ReferralLink />}
      </Button>
      <Box />
    </>
  );
};

const useStyles = makeStyles({
  container: {
    padding: "10px 20px",
  },
  coupons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    alignItems: "flex-end",
  },
  referralLink: {
    margin: "auto",
    padding: "0px 0px",
    borderColor: colors.blueGray7,
    borderRadius: "10px",
    maxWidth: "500px",
  },
});

export default ReferAFriendButton;
