import { all, fork } from "redux-saga/effects";
import { appSagas } from "./system/saga";
import { accountSagas } from "./account/saga";
import { cashflowSagas } from "./cashflow/saga";
import { dashboardSagas } from "./dashboard/saga";
import { investmentsSagas } from "./investments/saga";
import { oldProfileBuildSagas } from "./oldProfileBuild/saga";
import { profileBuildSagas } from "./profileBuild/saga";
import { planBuildSagas } from "./planBuild/saga";
import { stripeSagas } from "./stripe/saga";
import { transactionSagas } from "./transaction/saga";

export default function* rootSaga() {
  yield all([
    fork(appSagas),
    fork(accountSagas),
    fork(cashflowSagas),
    fork(dashboardSagas),
    fork(investmentsSagas),
    fork(oldProfileBuildSagas),
    fork(profileBuildSagas),
    fork(planBuildSagas),
    fork(stripeSagas),
    fork(transactionSagas),
  ]);
}
