import axiosClient from "../axiosClient";
import makeAuthenticatedRequest from "../authenticatedRequest";

const ACTION_ITEM_IDS = [
  "PLAN_BUILD",
  "PLAN_IMPLEMENT",
  "ACCOUNT_LINK",
  "ACCOUNT_RELINK",
  "ACCOUNT_LINK_ADDL",
  "ACCOUNT_UPDATE",
  "TRANS_REVIEW",
  "TRANS_ADD",
  "TRANS_INCOME",
  "MONEY_REFERRAL",
  "MONEY_CALL",
  "MONEY_INVEST",
  "MONEY_XFER_ROTHIRA",
  "MONEY_XFER_TRADIRA",
];

export const fetchActionItemsApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`/v2/actionitem`);
    return data;
  });

export const reportActionItemApi = (id: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(`/v2/actionitem/${id}`);
    return data;
  });

export const clearActionItemApi = (id: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.delete(`/v2/actionitem/${id}`);
    return data;
  });

export const hideActionItemApi = (id: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.patch(`/v2/actionitem/${id}`, {
      hide: true,
    });
    return data;
  });

export const refreshActionItemsApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post(
      `/v2/actionitem/refresh`,
      ACTION_ITEM_IDS
    );
    return data;
  });
