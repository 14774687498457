import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cloneDeep, map, pick } from "lodash";

import {
  Box,
  Grid,
  makeStyles,
  Typography,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import { profileBuilderStyles } from "src/theme";

import CenterContainer from "../../Components/CenterContainer";
import Button from "src/components/Button";
import { getLiabilities, updateCurrentPlan } from "src/store/planBuild/actions";
import {
  getMyFedLoanPayments,
  getSpouseFedLoanPayments,
} from "src/store/account/selector";
import {
  getCurrentPlan,
  getLiabilities as selectLiabilities,
  getStudentLoanData,
} from "src/store/planBuild/selector";
import {
  getProfileRepayment,
  getSpouseInSchool,
  getSpouseProfileRepayment,
  getUserInSchool,
} from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import {
  Earning,
  Plan,
  PLAN_INCOME_TYPES,
  PlanViewComponent,
  REPAYMENT_PLANS,
  SPECIAL_REPAYMENTS,
} from "src/interfaces";
import { DollarTextField, formatDollarsAndCents } from "src/utils";

const useStyles = makeStyles(profileBuilderStyles);

const AddOrEditStudentLoan: PlanViewComponent = ({
  initialValues,
  onClose,
  onSave,
  render,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const plan: Plan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const spouseInSchool = useSelector(getSpouseInSchool);
  const userInSchool = useSelector(getUserInSchool);
  const studentloan: any[] = useSelector(getStudentLoanData);
  const profileRepayment = useSelector(getProfileRepayment);
  const spouseProfileRepayment = useSelector(getSpouseProfileRepayment);
  const liabilities = useSelector(selectLiabilities);
  const myFedLoanPayments = useSelector(getMyFedLoanPayments);
  const spouseFedLoanPayments = useSelector(getSpouseFedLoanPayments);

  const planIncomes: Earning[] = (plan.education || []).filter(
    (item) =>
      !!PLAN_INCOME_TYPES[item.type] &&
      +(item.date || 0) === new Date().getFullYear()
  ) as Earning[];

  let myMinAllocation = 0;
  let spouseMinAllocation = 0;
  if (!userInSchool) {
    myMinAllocation = Math.max(
      myFedLoanPayments,
      liabilities.min.solo[0].fed_loan || 0
    );
  }
  if (!spouseInSchool) {
    spouseMinAllocation = Math.max(
      spouseFedLoanPayments,
      liabilities.min.solo[1].fed_loan || 0
    );
  }
  const myStudentLoanEntry = plan.studentloan[0];
  const spouseStudentLoanEntry = plan.studentloan[1] || {};
  const initialFormValues = {
    annual: plan.allocations[0].solo[0]?.fed_loan || 0,
    monthly: Math.round((plan.allocations[0].solo[0]?.fed_loan || 0) / 12),
    repayplan: myStudentLoanEntry?.repayplan || profileRepayment || "std_plan",
    filing_jointly: plan.profile.filing_jointly ? "y" : "n",
    idroption: myStudentLoanEntry?.idroption || "standard",
    strategy: myStudentLoanEntry?.strategy || "high_interest_rate",
    who: "applicant",
  };
  if (initialValues) {
    if (initialValues.who === "spouse" || initialValues.who === "Spouse") {
      initialFormValues.who = "spouse";
      initialFormValues.annual = plan.allocations[0].solo[1].fed_loan || 0;
      initialFormValues.monthly = Math.round(initialFormValues.annual / 12);
      initialFormValues.repayplan = spouseStudentLoanEntry.repayplan;
      initialFormValues.idroption = spouseStudentLoanEntry.idroption;
      initialFormValues.strategy = spouseStudentLoanEntry.strategy;
    }
  }
  const [formValues, setFormValues] = useState<any>(initialFormValues);
  let min = 0;
  if (
    (formValues.who === "spouse" && !spouseInSchool) ||
    (formValues.who !== "spouse" && !userInSchool)
  ) {
    min = formValues.who === "spouse" ? spouseMinAllocation : myMinAllocation;
  }
  useEffect(() => {
    // Initialize the min payments with the current values on the form
    estimateMinPayments("repayplan", formValues);
  }, []);

  const inschool = !!plan.education;

  const save = () => {
    const soloAllocations = [...plan.allocations[0].solo];
    const whoIndex = formValues.who === "applicant" ? 0 : 1;
    soloAllocations[whoIndex] = {
      ...soloAllocations[whoIndex],
      fed_loan: formValues.annual,
    };
    const planAllocations = [...plan.allocations];
    planAllocations[0] = { ...planAllocations[0] };
    planAllocations[0].solo = soloAllocations;
    planAllocations[0].fed_loan =
      (soloAllocations[0]?.fed_loan || 0) + (soloAllocations[1]?.fed_loan || 0);
    const newStudentLoan: any = cloneDeep(plan.studentloan);
    newStudentLoan[whoIndex] = {
      ...pick(newStudentLoan[whoIndex], [
        "perkinscancel",
        "contribution",
        "repayplan",
        "idroption",
        "strategy",
        "who",
        "start",
      ]),
      ...pick(formValues, ["repayplan", "idroption", "strategy", "who"]),
    };
    const planProfile = { ...plan.profile };
    planProfile.filing_jointly = formValues.filing_jointly === "y";
    dispatch(
      updateCurrentPlan({
        allocations: planAllocations,
        studentloan: newStudentLoan,
        profile: planProfile,
      })
    );
    onSave();
  };

  const setFormValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    const newFormValues = { ...formValues, [field]: value };
    if (field === "who") {
      if (value === "spouse") {
        newFormValues.idroption = spouseStudentLoanEntry.idroption;
        newFormValues.strategy = spouseStudentLoanEntry.strategy;
        newFormValues.repayplan = spouseProfileRepayment;
        newFormValues.annual = plan.allocations[0].solo[1]?.fed_loan || 0;
        newFormValues.monthly = Math.round(initialFormValues.annual / 12);
      } else {
        newFormValues.idroption = myStudentLoanEntry.idroption;
        newFormValues.strategy = myStudentLoanEntry.strategy;
        newFormValues.repayplan = profileRepayment;
        newFormValues.annual = plan.allocations[0].solo[0]?.fed_loan || 0;
        newFormValues.monthly = Math.round(initialFormValues.annual / 12);
      }
    } else if (field === "annual") {
      newFormValues.monthly = Math.round(value / 12);
    } else if (field === "monthly") {
      newFormValues.annual = value * 12;
    }
    setFormValues(newFormValues);
    estimateMinPayments(field, newFormValues);
  };

  const estimateMinPayments = (field: string, newFormValues: any) => {
    if (
      field === "repayplan" ||
      field === "idroption" ||
      field === "strategy" ||
      field === "filing_jointly"
    ) {
      const studentLoanDetails = [
        cloneDeep(studentloan[0]),
        cloneDeep(studentloan[1]),
      ];
      const selectedDetails =
        formValues.who === "spouse"
          ? studentLoanDetails[1]
          : studentLoanDetails[0];
      selectedDetails.repayplan = newFormValues.repayplan;
      selectedDetails.idroption = newFormValues.idroption;
      selectedDetails.strategy = newFormValues.strategy;
      dispatch(
        getLiabilities({
          filing_jointly: newFormValues.filing_jointly === "y",
          inschool,
          incomes: planIncomes,
          studentloan: studentLoanDetails,
          allocations: plan.allocations[0],
        })
      );
    }
  };

  const nextDisabled =
    !!min &&
    (formValues.annual || 0) <
      (formValues.who === "spouse" ? spouseMinAllocation : myMinAllocation);

  return render({
    component: (
      <CenterContainer title="Federal Student Loan" iconName={"fb-model"}>
        {isMarried && (
          <Box className="mt-4">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-4">
                Whose loan is this?
              </FormLabel>
              <Select
                variant="outlined"
                fullWidth
                disabled={!!initialValues}
                value={formValues.who}
                name="who"
                placeholder="Select"
                onChange={setFormValue}
              >
                <MenuItem value="applicant">Mine</MenuItem>
                <MenuItem value="spouse">My spouse's</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
        <Grid container spacing={2} className="mt-4">
          <Grid item xs={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-4">
                Choose a repayment plan:
              </FormLabel>
              <Select
                variant="outlined"
                fullWidth
                value={formValues.repayplan}
                name="repayplan"
                placeholder="Select"
                onChange={setFormValue}
              >
                {map(REPAYMENT_PLANS, (label: string, key: string) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* {SPECIAL_REPAYMENTS.indexOf(formValues.repayplan) < 0 &&
          <Grid item xs={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-4">Choose a repayment method:</FormLabel>
              <Select
                variant="outlined"
                fullWidth
                value={formValues.strategy || ""}
                name="strategy"
                placeholder="Select"
                onChange={setFormValue}
              >
                <MenuItem value="high_interest_rate">High interest rate</MenuItem>
                <MenuItem value="low_balance">Low balance</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          } */}
          {SPECIAL_REPAYMENTS.indexOf(formValues.repayplan) >= 0 && (
            <>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend" className="mb-4">
                    Select one:
                  </FormLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    value={formValues.idroption || ""}
                    name="idroption"
                    placeholder="Select"
                    onChange={setFormValue}
                  >
                    <MenuItem value="standard">
                      Pursuing forgiveness and facing tax liability
                    </MenuItem>
                    <MenuItem value="pslf">Pursuing PSLF</MenuItem>
                    <MenuItem value="payoff">
                      Will eventually pay off my loans
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {isMarried && (
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend" className="mb-4">
                      How do you plan on filing your taxes?
                    </FormLabel>
                    <Select
                      variant="outlined"
                      fullWidth
                      value={formValues.filing_jointly}
                      name="filing_jointly"
                      placeholder="Select"
                      onChange={setFormValue}
                    >
                      <MenuItem value="y">Jointly</MenuItem>
                      <MenuItem value="n">Separately</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-4">
                Monthly payment
              </FormLabel>
              <DollarTextField
                variant="outlined"
                name="monthly"
                placeholder="0"
                value={formValues.monthly}
                onChange={setFormValue}
              />
              <Typography variant="body1">
                Min: {formatDollarsAndCents(Math.ceil(min / 12))}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-4">
                Annual payment
              </FormLabel>
              <DollarTextField
                variant="outlined"
                name="annual"
                placeholder="0"
                value={formValues.annual}
                onChange={setFormValue}
              />
              <Typography variant="body1">
                Min: {formatDollarsAndCents(min)}
              </Typography>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          variant="outlined"
          fbColor="primary"
          onClick={onClose}
          className={styles.btnCancel}
        >
          Cancel
        </Button>
      </CenterContainer>
    ),
    nextDisabled,
    nextLabel: "Save",
    nextTooltip: nextDisabled
      ? "You must enter at least the minimum contribution."
      : undefined,
    onNext: save,
  });
};

export default AddOrEditStudentLoan;
