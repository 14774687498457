import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@material-ui/core";

import {
  setBuildStep,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { getIsCurrentStudent } from "src/store/system/selector";

const steps = [
  {
    label: "1. Life Events/Goals",
    step: PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW,
  },
  {
    label: "2. Day-To-Day Money",
    step: PLAN_BUILD_STEPS.INCOME_AND_EXPENSES,
  },
  {
    label: "3. Money For Future Goals",
    step: PLAN_BUILD_STEPS.ASSETS_AND_DEBTS,
  },
  {
    label: "4. Risk Management",
    step: PLAN_BUILD_STEPS.RISK_MANAGEMENT,
  },
  {
    label: "5. Additional Questions",
    step: PLAN_BUILD_STEPS.SAVINGS,
  },
];

const studentSteps = [
  {
    label: "1. Education Funding & Costs",
    step: PLAN_BUILD_STEPS.EDUCATION_FUNDING,
  },
  {
    label: "2. Expenses & Insurance",
    step: PLAN_BUILD_STEPS.EXPENSES_AND_INSURANCE,
  },
  {
    label: "3. Debt & Assets",
    step: PLAN_BUILD_STEPS.ASSETS_AND_DEBTS,
  },
  {
    label: "4. Future Questions",
    step: PLAN_BUILD_STEPS.FUTURE_QUESTIONS,
  },
  {
    label: "5. Life Events",
    step: PLAN_BUILD_STEPS.LIFE_EVENTS_OVERVIEW,
  },
];

interface EditDialogProps {
  open: boolean;
  onClose: VoidFunction;
}

const EditDialog = ({ open, onClose }: EditDialogProps) => {
  const dispatch = useDispatch();
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const displaySteps = isCurrentStudent ? studentSteps : steps;
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="edit-title">
      <DialogTitle id="edit-title">Select a section to edit:</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {displaySteps.map((item) => (
            <Box key={item.label}>
              <Link
                href="#"
                onClick={() => {
                  dispatch(setBuildStep(item.step));
                }}
                style={{ fontSize: "13px" }}
              >
                {item.label}
              </Link>
            </Box>
          ))}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default EditDialog;
