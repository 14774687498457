import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Finance } from "financejs";

import {
  getPayoffAnalysisLoansApi,
  getPayoffAnalysisResultsApi,
  PayoffAccountsPayload,
  PayoffAccountsResponse,
  PayoffAnalysisPayload,
  PayoffAnalysisResponse,
} from "src/apiService";
import CalculatorLayout from "src/layouts/CalculatorLayout";
import { getIsMarried } from "src/store/system/selector";
import PayoffAccounts from "./PayoffAccounts";
import PayoffResults from "./PayoffResults";
import UserOrSpouse from "./UserOrSpouse";

const finance = new Finance();

const DISCLAIMER =
  "This tool only provides an estimate of the cost of your student loan repayment but does not take your complete financial picture into account. Be sure to build your holistic financial plan to visualize the impact of your student loans in context.";

export const PayoffAnalysis = () => {
  const isMarried = useSelector(getIsMarried);
  const [loanData, setLoanData] = useState<PayoffAccountsResponse[]>([]);
  const [selectedTerms, setSelectedTerms] = useState<{
    [accountId: string]: number;
  }>({});
  const [resultsData, setResultsData] = useState<PayoffAnalysisResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(isMarried ? 0 : 1);
  const [currentTab, setCurrentTab] = useState(0);
  const [loadingResults, setLoadingResults] = useState(false);
  const [
    fetchAnalysisPayload,
    setAnalysisPayload,
  ] = useState<PayoffAnalysisPayload | null>(null);
  const [who, setWho] = useState("applicant");

  const goToPage = (nextPage: number) => {
    setCurrentPage(nextPage);
    window.scrollTo(0, 0);
  };

  const getPayment = (loan: PayoffAccountsResponse) => {
    if (loan.type !== "federal") {
      return loan.payment;
    }
    const term = selectedTerms["" + loan.id];
    if (!term) {
      return loan.payment;
    }
    return finance.PMT(loan.interest / 100 / 12, term * 12, -loan.balance);
  };

  const totals = useMemo(() => {
    const output = loanData.reduce(
      (result, loan) => {
        result.balance += loan.balance;
        result.interest += loan.interest;
        result.term += loan.term;
        result.payment += getPayment(loan);
        return result;
      },
      {
        id: -999,
        balance: 0,
        interest: 0,
        payment: 0,
        term: 0,
        type: "federal",
        whose: "mine",
      }
    );
    output.interest = output.interest / output.balance;
    output.term = output.term / output.balance;
    return output;
  }, [loanData]);

  const totalPayment = useMemo(() => {
    return loanData.reduce((result, loan) => {
      return result + loan.payment;
    }, 0);
  }, [loanData]);

  const fetchLoanData = (payload?: PayoffAccountsPayload) => {
    if (payload) {
      setSelectedTerms(payload.selectedTerms);
    }
    return getPayoffAnalysisLoansApi(payload).then((loans) => {
      const filteredLoans = loans.filter((item) => {
        if (who === "spouse") {
          return item.whose === "spouse";
        }
        return item.whose !== "spouse";
      });
      setLoanData(filteredLoans);
    });
  };

  useEffect(() => {
    fetchLoanData();
  }, [who]);

  const defaultExtendTerms = useMemo(
    () =>
      loanData
        ? loanData.reduce((result: any, loan) => {
            result["" + loan.id] = loan.term;
            return result;
          }, {})
        : {},
    [loanData]
  );

  const fetchAnalysisResults = (
    payload: PayoffAnalysisPayload,
    isNew?: boolean
  ) => {
    setAnalysisPayload(payload);
    setLoadingResults(true);
    return getPayoffAnalysisResultsApi(payload)
      .then((result) => {
        setLoadingResults(false);
        const index = 4 - payload.sno;
        const newResultsData = isNew ? [] : [...resultsData];
        newResultsData[index] = result;
        setResultsData(newResultsData);
      })
      .catch(console.error);
  };

  const selectTab = (tabIndex: number) => {
    if (!resultsData[tabIndex]) {
      const payload: PayoffAnalysisPayload = {
        ...(fetchAnalysisPayload as PayoffAnalysisPayload),
        sno: 4 - tabIndex,
        who,
      };
      setAnalysisPayload(payload);
      fetchAnalysisResults(payload);
    }
    setCurrentTab(tabIndex);
  };

  const loadNewResults = (extraPrepay: number, dropoffRate: number) => {
    const payload = {
      sno: 4 - currentTab,
      extra_prepay: extraPrepay,
      drop_off_ratio: dropoffRate,
      defaultExtendedTerms: defaultExtendTerms,
      selectedTerms: selectedTerms,
      who,
    };
    return fetchAnalysisResults(payload, true);
  };

  const getExtend = () => {
    for (let i = 0; i < loanData.length; i++) {
      const selectedTerm = selectedTerms["" + loanData[i].id];
      if (selectedTerm && selectedTerm !== loanData[i].term) {
        return true;
      }
    }
    return false;
  };

  const renderContent = () => {
    switch (currentPage) {
      case 0:
        return (
          <UserOrSpouse
            next={(newWho: string) => {
              setWho(newWho);
              goToPage(1);
            }}
          />
        );
      case 1:
        return (
          <PayoffAccounts
            loans={loanData}
            totals={totals}
            update={fetchLoanData}
            getPayment={getPayment}
            defaultExtendTerms={defaultExtendTerms}
            selectedTerms={selectedTerms}
            setSelectedTerms={setSelectedTerms}
            onNext={() => goToPage(2)}
            goBack={isMarried ? () => goToPage(0) : undefined}
          />
        );
      case 2:
      default:
        return (
          <PayoffResults
            totals={totals}
            totalPayment={totalPayment}
            tab={currentTab}
            setTab={selectTab}
            extend={getExtend()}
            loaded={!!resultsData.length}
            data={resultsData[currentTab]}
            loadNewResults={loadNewResults}
            onBack={() => goToPage(1)}
            loading={loadingResults}
          />
        );
    }
  };

  return (
    <CalculatorLayout
      title="Student Repayment Analysis"
      backTo="/studentloans"
      disclaimer={DISCLAIMER}
    >
      <main className="mt-10 px-5">{renderContent()}</main>
    </CalculatorLayout>
  );
};

export default PayoffAnalysis;
