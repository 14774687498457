import axiosClient from "../axiosClient";
import makeAuthenticatedRequest from "../authenticatedRequest";
import { NewTransaction, Transaction } from "src/interfaces";

interface FetchTransactionsQuery {
  category: string;
  type: string;
  period?: string;
  from?: string;
  to?: string;
}

export const fetchUnconfirmedTransactionsApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/v2/transaction?confirm=todo");
    return data;
  });

export const syncTransactionApi = (id: number) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post(
      `/v2/transaction/${id}/breakout/sync`
    );
    return data;
  });

export const fetchTransactionsApi = (from: string, to: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(
      `/v2/transaction?from=${from}&to=${to}&group=all`
    );
    return data;
  });

// TODO not rewritten yet
export const fetchFilteredTransactionsApi = (query: FetchTransactionsQuery) =>
  makeAuthenticatedRequest(async () => {
    const dateQuery = query.period
      ? `period=${query.period}`
      : `start=${query.from}&end=${query.to}`;
    const { data } = await axiosClient.get(
      `/transaction/detail/${query.category}.php?type=${query.type}&${dateQuery}`
    );
    return data;
  });

export const confirmTransactionsApi = (ids: number[]) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.patch("/v2/transaction/confirm", {
      transaction: ids,
    });
    return data;
  });

export const addTransactionApi = (transaction: NewTransaction) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("/v2/transaction", transaction);
    return data;
  });

export const editTransactionApi = (
  id: number,
  transaction: Partial<Transaction>
) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.patch(
      `/v2/transaction/${id}`,
      transaction
    );
    return data;
  });

export const getBreakoutsApi = (id: number) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`/v2/transaction/${id}/breakout`);
    return data;
  });

export const getBreakoutBaseApi = (id: number) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`/v2/transaction/${id}/root`);
    return data;
  });

export const updateBreakoutsApi = (id: number, children: any[]) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(`/v2/transaction/${id}/breakout`, {
      children,
    });
    return data;
  });

// export const fetchUnconfirmedTransactionsApi = () =>
//   makeAuthenticatedRequest(async () => {
//     const { data } = await axiosClient.get(
//       "/transaction/transaction.php?confirm=todo"
//     );
//     return data;
//   });

// export const syncTransactionApi = (id: number) =>
//   makeAuthenticatedRequest(async () => {
//     const { data } = await axiosClient.post(
//       `/transaction/breakout/sync.php?id=${id}`
//     );
//     return data;
//   });

// export const fetchTransactionsApi = (from: string, to: string) =>
//   makeAuthenticatedRequest(async () => {
//     const { data } = await axiosClient.get(
//       `/transaction/transaction.php?from=${from}&to=${to}&group=all`
//     );
//     return data;
//   });

// export const fetchFilteredTransactionsApi = (query: FetchTransactionsQuery) =>
//   makeAuthenticatedRequest(async () => {
//     const dateQuery = query.period
//       ? `period=${query.period}`
//       : `start=${query.from}&end=${query.to}`;
//     const { data } = await axiosClient.get(
//       `/transaction/detail/${query.category}.php?type=${query.type}&${dateQuery}`
//     );
//     return data;
//   });

// export const confirmTransactionsApi = (ids: number[]) =>
//   makeAuthenticatedRequest(async () => {
//     const { data } = await axiosClient.patch("/transaction/confirm.php", {
//       transaction: ids,
//     });
//     return data;
//   });

// export const addTransactionApi = (transaction: NewTransaction) =>
//   makeAuthenticatedRequest(async () => {
//     const { data } = await axiosClient.post(
//       "/transaction/transaction.php",
//       transaction
//     );
//     return data;
//   });

// export const editTransactionApi = (
//   id: number,
//   transaction: Partial<Transaction>
// ) =>
//   makeAuthenticatedRequest(async () => {
//     const { data } = await axiosClient.patch(
//       `/transaction/transaction.php?id=${id}`,
//       transaction
//     );
//     return data;
//   });

// export const getBreakoutsApi = (id: number) =>
//   makeAuthenticatedRequest(async () => {
//     const { data } = await axiosClient.get(`transaction/breakout.php?id=${id}`);
//     return data;
//   });

// export const getBreakoutBaseApi = (id: number) =>
//   makeAuthenticatedRequest(async () => {
//     const { data } = await axiosClient.get(`transaction/root.php?id=${id}`);
//     return data;
//   });

// export const updateBreakoutsApi = (id: number, children: any[]) =>
//   makeAuthenticatedRequest(async () => {
//     const { data } = await axiosClient.put(
//       `transaction/breakout.php?id=${id}`,
//       { children }
//     );
//     return data;
//   });
