import React from "react";

import { EuiToolTip, EuiIcon } from "@elastic/eui";

interface Props {
  content: React.ReactNode;
  position?: "top" | "bottom" | "left" | "right";
  [key: string]: any;
}

const InfoIcon = ({ content, position, ...iconProps }: Props) => (
  <EuiToolTip content={content} position={position}>
    <EuiIcon type="iInCircle" {...iconProps} />
  </EuiToolTip>
);

export default InfoIcon;
