import * as React from "react";

import GeneralPage from "../GeneralPage";
import data from "./data";

export const Insurance = () => (
  <GeneralPage data={data} title="Insurance Tools and Products" />
);

export default Insurance;
