import React from "react";
import { useSelector } from "react-redux";

import {
  HC_SCORE_CATEGORIES,
  SCORE_CATEGORIES,
  ScoreRanks,
} from "src/interfaces";
import List from "../Components/ListScore";
import { getScoreRanks } from "src/store/dashboard/selector";

const ListScore = () => {
  const { applicant } = useSelector(getScoreRanks);

  const list = HC_SCORE_CATEGORIES.map((category: string) => ({
    name: SCORE_CATEGORIES[category as keyof ScoreRanks],
    value: applicant[category as keyof ScoreRanks],
  }));

  return <List header={list[0]} list={list.slice(1)} className="md:h-72" />;
};

export default ListScore;
