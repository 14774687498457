import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { Box, makeStyles, Typography } from "@material-ui/core";

import { investmentsStyles } from "src/theme";
import Button from "src/components/Button";
import InvestmentsLayout from "src/layouts/InvestmentsLayout";

import { openUpgradeDialog } from "src/store/system/actions";
import { getIsSubscribed } from "src/store/system/selector";
import TitleCard from "src/components/TitleCard";

const useStyles = makeStyles(investmentsStyles);

export const HowToAdjust = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const subscribed = useSelector(getIsSubscribed);

  useEffect(() => {
    if (subscribed) {
      dispatch(push("/tools/investment"));
    }
  }, [subscribed]);

  return (
    <InvestmentsLayout
      goBackTarget="/investments"
      headerText="How Should I Adjust My Portfolio?"
    >
      <main className={classes.content}>
        <TitleCard title="The Power of Percentages">
          <Typography paragraph>
            NOTE: This is only available to premium members.
          </Typography>
          <Typography paragraph>
            Here we analyze your overall asset allocation across all of your
            accounts and think in terms of percentages across multiple asset
            types. It is then extremely easy to model different approaches and
            visualize their estimated level of risk and return.
          </Typography>
          <Typography paragraph>
            You can then compare your potential allocations with FitBUX's
            recommendation and decide accordingly.
          </Typography>
        </TitleCard>
        <Box className="w-full flex justify-center">
          <Button
            fbColor="primary"
            onClick={() => dispatch(openUpgradeDialog())}
          >
            Upgrade to Premium
          </Button>
        </Box>
      </main>
    </InvestmentsLayout>
  );
};

export default HowToAdjust;
