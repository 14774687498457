import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import TitleCard from "src/components/TitleCard";
import Button from "src/components/Button";

interface LandingStepProps {
  onNext(): void;
}

const LandingStep = ({ onNext }: LandingStepProps) => {
  const classes = useStyles();

  return (
    <>
      <TitleCard
        title="What This Recommendation Provides"
        sideContent={[
          {
            header: "",
            body: [],
            videoKey: "disabilityInsurance",
          },
        ]}
      >
        <Box className={classes.container}>
          <Typography paragraph>
            FitBUX’s disability insurance AI is here to help you make sure that
            you are covered in the event of an unfortunate accident or illness.
          </Typography>
          <Typography paragraph>
            It provides tailored advice on how much total disability insurance
            you should purchase, as well as insight into the two different types
            of coverage available: any occupation and own occupation.
          </Typography>
          <Typography paragraph>
            It will then calculate the optimal amount for each policy so you can
            get maximum coverage at a cost-effective rate.
          </Typography>
        </Box>
      </TitleCard>
      <Box className={classes.buttonContainer}>
        <Button onClick={() => onNext()} color="primary">
          Next
        </Button>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

export default LandingStep;
