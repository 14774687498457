import produce from "immer";
import { handleActions } from "redux-actions";

import { AUTH_STATUS, FbAction, SystemState } from "../../interfaces";
import { FAIL, START, SUCCESS } from "../common";
import {
  ADD_SPOUSE,
  DELETE_IMPLEMENTED_PLAN,
  FETCH_LOGIN,
  FETCH_USER,
  LOG_OUT,
  SUSPEND_UPDATES,
  RESUME_UPDATES,
  SIGNUP,
  SET_PREVIOUS_ROUTE,
  SET_GRADUATED_MODE,
  UPDATE_USER,
  UPDATE_SPOUSE,
  OPEN_UPGRADE_DIALOG,
  OPEN_MONEY_SCHOOL_DIALOG,
  CLOSE_UPGRADE_DIALOG,
  PASSWORD_RESET_SUCCESS,
  SET_PASSWORD_RESET,
} from "./actions";
import { CREATE_SUBSCRIPTION } from "../stripe/actions";
import { IMPLEMENT_PLAN } from "../planBuild/actions";

const initialState: SystemState = {
  loggedIn: false,
  user: undefined,
  spouse: undefined,
  features: {
    married: false,
    hasPlan: false,
    subscribed: false,
    inschool: false,
    planBuilder: false,
  },
  loading: false,
  error: undefined,
  authStatus: AUTH_STATUS.NONE,
  addingSpouse: false,
  spouseLinked: false,
  passwordIsReset: false,
  passwordResetFail: false,
  previousRoute: "/dashboard",
  upgradeDialogOpen: false,
  moneySchoolDialogOpen: false,
  updatesSuspended: false,
  passwordResetSuccess: false,
};

const systemReducer = handleActions<SystemState, any>(
  {
    [FETCH_LOGIN + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = undefined;
      }),
    [FETCH_LOGIN + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loggedIn = true;
        draft.error = undefined;
        draft.user = payload;
      }),
    [FETCH_LOGIN + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loggedIn = false;
        draft.error = payload;
        draft.user = undefined;
      }),
    [SIGNUP + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = undefined;
      }),
    [SIGNUP + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loggedIn = true;
        draft.error = undefined;
        draft.user = payload.user;
        draft.features = payload.features;
      }),
    [SIGNUP + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loggedIn = false;
        draft.error = payload;
        draft.user = undefined;
      }),
    [FETCH_USER + START]: (state) =>
      produce(state, (draft) => {
        draft.error = undefined;
        draft.authStatus = AUTH_STATUS.LOADING;
      }),
    [FETCH_USER + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.authStatus = AUTH_STATUS.SUCCEED;
        if (payload.user) {
          draft.user = payload.user;
        }
        if (payload.features) {
          draft.features = payload.features;
        }
        if (payload.spouse) {
          draft.spouse = payload.spouse;
        }
      }),
    [FETCH_USER + FAIL]: (state) =>
      produce(state, (draft) => {
        draft.authStatus = AUTH_STATUS.FAILED;
        draft.loggedIn = false;
      }),
    [IMPLEMENT_PLAN + SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.features.hasPlan = true;
      }),
    [DELETE_IMPLEMENTED_PLAN]: (state) =>
      produce(state, (draft) => {
        draft.features.hasPlan = false;
      }),
    [CREATE_SUBSCRIPTION + SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.features.subscribed = true;
      }),
    [ADD_SPOUSE + START]: (state) =>
      produce(state, (draft) => {
        draft.addingSpouse = true;
      }),
    [ADD_SPOUSE + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.features.married = true;
        draft.addingSpouse = false;
        if (payload.linked) {
          draft.spouseLinked = true;
        }
      }),
    [ADD_SPOUSE + FAIL]: (state) =>
      produce(state, (draft) => {
        draft.addingSpouse = false;
      }),
    [SUSPEND_UPDATES]: (state) =>
      produce(state, (draft) => {
        draft.updatesSuspended = true;
      }),
    [RESUME_UPDATES]: (state) =>
      produce(state, (draft) => {
        draft.updatesSuspended = false;
      }),
    [SET_GRADUATED_MODE]: (state) =>
      produce(state, (draft) => {
        draft.features.inschool = false;
        draft.features.hasPlan = false;
      }),
    [UPDATE_USER + SUCCESS]: (state, { payload }: any) =>
      produce(state, (draft) => {
        draft.user = { ...state.user, ...payload };
      }),
    [UPDATE_SPOUSE + SUCCESS]: (state, { payload }: any) =>
      produce(state, (draft) => {
        draft.spouse = { ...state.spouse, ...payload };
      }),
    [SET_PREVIOUS_ROUTE]: (state, { payload }: FbAction<string>) =>
      produce(state, (draft) => {
        draft.previousRoute = payload;
      }),
    [OPEN_UPGRADE_DIALOG]: (state) =>
      produce(state, (draft) => {
        draft.upgradeDialogOpen = true;
        draft.moneySchoolDialogOpen = false;
      }),
    [OPEN_MONEY_SCHOOL_DIALOG]: (state) =>
      produce(state, (draft) => {
        draft.upgradeDialogOpen = true;
        draft.moneySchoolDialogOpen = true;
      }),
    [CLOSE_UPGRADE_DIALOG]: (state) =>
      produce(state, (draft) => {
        draft.upgradeDialogOpen = false;
        draft.moneySchoolDialogOpen = false;
      }),
    [PASSWORD_RESET_SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.passwordResetSuccess = true;
      }),
    [SET_PASSWORD_RESET]: (state) =>
      produce(state, (draft) => {
        draft.passwordResetSuccess = false;
      }),
    [LOG_OUT]: () => initialState,
  },
  initialState
);

export default systemReducer;
