import React, { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { sortBy } from "lodash";

import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Popper,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  deleteAccountTickerApi,
  fetchAccountTickersApi,
  fetchTickerOptions,
  getTickerPriceApi,
  updateAccountTickerApi,
} from "src/apiService";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import { mainCardStyles } from "src/components/MainCard";
import { AccountTicker } from "src/interfaces";
import { colors } from "src/theme";
import { DollarTextField, formatDollarsAndCents } from "src/utils";
import ToolTipIcon from "src/components/ToolTipIcon";

const useStyles = makeStyles({
  ...mainCardStyles,
  editButton: {
    color: colors.brandingBlue2,
    cursor: "pointer",
    paddingLeft: 10,
    "&:hover": {
      color: colors.brandingBlue1,
    },
  },
  investmentRow: {
    border: 0,
  },
  noBorder: {
    border: 0,
  },
  noPadding: {
    border: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
  tableContainer: {
    maxHeight: 400,
    overflowY: "auto",
  },
  tickerSymbol: {
    maxWidth: 60,
    overflowX: "hidden",
    marginRight: 10,
    width: 60,
    textOverflow: "ellipsis",
  },
  addButton: {
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
});

interface TickerOption {
  Code: string;
  Exchange: string;
  Name: string;
}

const CASH_TICKER = "CASH.PLD";

export const InvestmentAllocations = ({
  account,
  linkedAccount,
  setAccountBalance,
}: any) => {
  const classes = useStyles();
  const [investments, setInvestments] = useState<AccountTicker[]>([]);
  const [unsavedInvestment, setUnsavedInvestment] = useState<any>(null);
  const [options, setOptions] = useState<TickerOption[]>([]);
  const [tickerInputValue, setTickerInputValue] = useState("");

  const readOnly = linkedAccount?.holdings;

  useEffect(() => {
    fetchAccountTickersApi(account.id).then((results) => {
      const sorted = sortBy(
        results,
        (item) => -(item.quantity * item.close_price)
      );
      setInvestments(sorted);
    });
  }, [account]);

  useEffect(() => {
    let active = true;
    const value = unsavedInvestment ? unsavedInvestment.ticker : "";
    if (!tickerInputValue) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (fetchTickerOptions) {
      (fetchTickerOptions as any)(tickerInputValue).then((results: any[]) => {
        if (active) {
          const sorted = sortBy(results, (item) => {
            if (item.Exchange === "US") {
              return 1;
            }
            if (item.Exchange === "CC") {
              return 2;
            }
            return 3;
          });
          setOptions(sorted);
        }
      });
    }
    return () => {
      active = false;
    };
  }, [unsavedInvestment, tickerInputValue, fetchTickerOptions]);

  // useEffect(() => {
  //   return () => {
  //     if (unsavedInvestment && !saving) {
  //       saveEdit(false);
  //     }
  //   };
  // }, [unsavedInvestment, saving]);

  const investmentIds = useMemo(
    () => investments.map(() => Math.random() + ""),
    [investments.length]
  );

  const saveEdit = (mounted: boolean) => {
    if (!unsavedInvestment.ticker || !unsavedInvestment.amount) {
      return Promise.resolve();
    }
    const getPrice =
      unsavedInvestment.ticker === CASH_TICKER
        ? Promise.resolve(1)
        : getTickerPriceApi(unsavedInvestment.ticker);
    return getPrice.then((price) => {
      if (!price) {
        return;
      }
      const quantity = unsavedInvestment.amount / price;
      const promises = [
        updateAccountTickerApi(account.id, unsavedInvestment.ticker, quantity),
      ];
      if (
        unsavedInvestment.original_ticker &&
        unsavedInvestment.original_ticker !== unsavedInvestment.ticker
      ) {
        promises.push(
          deleteAccountTickerApi(account.id, unsavedInvestment.original_ticker)
        );
      }
      return Promise.all(promises).then(
        () =>
          new Promise((resolve) => {
            if (mounted) {
              const newInvestment = {
                ticker_symbol: unsavedInvestment.ticker,
                quantity,
                close_price: price,
              };
              let newInvestments = [...investments];
              if (unsavedInvestment.index === investments.length) {
                newInvestments = [...investments, newInvestment];
              } else {
                newInvestments[unsavedInvestment.index] = newInvestment;
              }
              const newTotal = newInvestments.reduce(
                (result, investment) =>
                  result + investment.quantity * investment.close_price,
                0
              );
              setAccountBalance(newTotal);
              resolve(newInvestments);
              setInvestments(newInvestments);
              setUnsavedInvestment(null);
            } else {
              resolve(null);
            }
          })
      );
    });
  };

  useEffect(() => {
    return () => {
      if (unsavedInvestment) {
        saveEdit(false);
      }
    };
  }, [unsavedInvestment]);

  const addNewTicker = () => {
    const promise: any = unsavedInvestment
      ? saveEdit(true)
      : Promise.resolve(investments);
    promise.then((newInvestments: any) => {
      if (newInvestments) {
        setUnsavedInvestment({
          original_ticker: "",
          ticker: "",
          amount: 0,
          price: 0,
          index: newInvestments ? newInvestments.length : investments.length,
        });
      }
    });
  };

  const addCash = () => {
    const promise: any = unsavedInvestment
      ? saveEdit(true)
      : Promise.resolve(investments);
    promise.then((newInvestments: any) => {
      if (newInvestments) {
        setUnsavedInvestment({
          original_ticker: "",
          ticker: CASH_TICKER,
          amount: 0,
          price: 1,
          index: newInvestments.length,
        });
      }
    });
  };

  const openForEditing = (investment: AccountTicker, index: number) =>
    setUnsavedInvestment({
      original_ticker: investment.ticker_symbol,
      ticker: investment.ticker_symbol,
      amount: investment.quantity * investment.close_price,
      price: investment.close_price,
      index,
    });

  const removeItem = (index: number) => {
    if (index === investments.length) {
      return setUnsavedInvestment(null);
    }
    deleteAccountTickerApi(account.id, investments[index].ticker_symbol).then(
      () => {
        const selectedIndex = unsavedInvestment ? unsavedInvestment.index : -1;
        if (selectedIndex >= 0) {
          if (index === selectedIndex) {
            setUnsavedInvestment(null);
          } else if (index < selectedIndex) {
            setUnsavedInvestment((current: any) => ({
              ...current,
              index: current.index - 1,
            }));
          }
        }
        const newInvestments = [...investments];
        newInvestments.splice(index, 1);
        const newTotal = newInvestments.reduce(
          (result, investment) =>
            result + investment.quantity * investment.close_price,
          0
        );
        setAccountBalance(newTotal);
        setInvestments(newInvestments);
      }
    );
  };

  const cashValue =
    (unsavedInvestment && unsavedInvestment.ticker === CASH_TICKER) ||
    !!investments.find((item) => item.ticker_symbol === CASH_TICKER);

  const displayInvestments = [...investments];
  if (unsavedInvestment && unsavedInvestment.index === investments.length) {
    displayInvestments.push(unsavedInvestment);
  }

  const totalBalance = investments.reduce(
    (result, investment) =>
      result + investment.quantity * investment.close_price,
    0
  );

  return (
    <>
      <Typography variant="body1" className="mb-2" paragraph>
        {readOnly
          ? ""
          : "Add or update your holdings in this account. This is optional; however, to use our investment allocation technology, you must include your investments held in this account."}
        {!readOnly && (
          <ToolTipIcon
            title={
              'To search, type in the ticker symbol or name for any mutual fund, ETF, stock, crypto, etc. For example, you can type "Facebook" or "FB" and then choose the appropriate option.'
            }
          />
        )}
      </Typography>
      <Card className={`${classes.root} ${classes.noContainer}`}>
        <CardContent className={classes.content}>
          <List className={classes.table}>
            <ListItem className={classes.header}>
              <Box className={classes.cellPrefix}></Box>
              <Box style={{ width: "40%" }}>Ticker</Box>
              <Box style={{ width: "60%" }}>Balance</Box>
              <Box className={classes.cellSuffix}></Box>
            </ListItem>
            {displayInvestments.map((investment, index) => {
              const editingRow =
                !!unsavedInvestment && unsavedInvestment.index === index;
              return (
                <ListItem
                  className={clsx(classes.row, editingRow && "selected")}
                  key={investmentIds[index]}
                >
                  <Box className={classes.cellPrefix}>
                    {!readOnly && (
                      <MuiButton
                        color="primary"
                        onClick={
                          editingRow
                            ? () => saveEdit(true)
                            : () => openForEditing(investment, index)
                        }
                      >
                        {editingRow ? "Done" : "Edit"}
                      </MuiButton>
                    )}
                  </Box>
                  <Box className={classes.cell} style={{ width: "40%" }}>
                    {editingRow ? (
                      <Autocomplete
                        className="input"
                        id="select-ticker"
                        getOptionLabel={(option) => {
                          return typeof option === "string"
                            ? option
                            : option.Code;
                        }}
                        filterOptions={(x) => x}
                        options={options}
                        filterSelectedOptions
                        forcePopupIcon={false}
                        getOptionSelected={(option) =>
                          unsavedInvestment.ticker ===
                          `${option.Code}.${option.Exchange}`
                        }
                        PopperComponent={(pProps) => (
                          <Popper {...pProps} style={{ width: 420 }} />
                        )}
                        ListboxProps={{ style: { width: 900 } }}
                        value={(unsavedInvestment.ticker || ".").split(".")[0]}
                        onChange={(event, newValue) => {
                          setOptions(
                            newValue ? [newValue, ...options] : options
                          );
                          setUnsavedInvestment((current: any) => ({
                            ...current,
                            ticker: newValue
                              ? `${newValue.Code}.${newValue.Exchange}`
                              : "",
                          }));
                        }}
                        onInputChange={(event, newInputValue) => {
                          setTickerInputValue(newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField className="input" {...params} />
                        )}
                        renderOption={(option: any) => {
                          return (
                            <Box
                              className="flex justify-between w-96"
                              key={option.Name + option.Code}
                            >
                              <Box className="flex">
                                <Typography className={classes.tickerSymbol}>
                                  {option.Code}
                                </Typography>
                                <Typography className="mr-4">
                                  {option.Name}
                                </Typography>
                              </Box>
                              <Typography>{option.Exchange}</Typography>
                            </Box>
                          );
                        }}
                      />
                    ) : (
                      <span>{investment.ticker_symbol.split(".")[0]}</span>
                    )}
                  </Box>
                  <Box className={classes.cell} style={{ width: "60%" }}>
                    {editingRow ? (
                      <DollarTextField
                        className="input"
                        value={unsavedInvestment.amount}
                        required
                        name="edit-amount"
                        onChange={(e: React.ChangeEvent<any>) =>
                          setUnsavedInvestment((current: any) => ({
                            ...current,
                            amount: e.target.value,
                          }))
                        }
                      />
                    ) : (
                      <span>
                        {formatDollarsAndCents(
                          investment.quantity * investment.close_price
                        )}
                      </span>
                    )}
                  </Box>
                  <Box className={classes.cellSuffix}>
                    {!readOnly && (
                      <IconButton
                        color="primary"
                        onClick={() => removeItem(index)}
                      >
                        <Icon iconName="fb-trash-can" />
                      </IconButton>
                    )}
                  </Box>
                </ListItem>
              );
            })}
            <Divider className="mt-4" />
            <Box className={classes.summaryRow}>
              <Box className={classes.cellPrefix} />
              <Box
                className={`${classes.cell} summary`}
                style={{ width: "40%" }}
              >
                Total Balance
              </Box>
              <Box
                className={`${classes.cell} summary`}
                style={{ width: "60%" }}
              >
                {formatDollarsAndCents(totalBalance)}
              </Box>
              <Box className={classes.cellSuffix} />
            </Box>
          </List>
          {!readOnly && (
            <Box className="flex justify-end">
              <Button
                className={classes.addButton}
                fbColor="primary"
                variant="outlined"
                endIcon={<Icon iconName="fb-add-alt" />}
                onClick={addNewTicker}
                style={{ marginRight: 16 }}
              >
                Add Investment
              </Button>
              {!cashValue && (
                <Button
                  className={classes.addButton}
                  fbColor="primary"
                  variant="outlined"
                  endIcon={<Icon iconName="fb-add-alt" />}
                  onClick={addCash}
                >
                  Add Cash
                </Button>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default InvestmentAllocations;
