export const text = {
  title: "How Would You Like To Build Your Plan?",

  disabled: {
    tooltip: "This option is only available to those that have graduated. If you have graduated, please go to the human capital section of your profile and update your graduation date.",
  },

  // Optimized
  standard: {
    heading: "Using FitBUX's Financial Plan Optimizer (Beta)",
    /*
    body: "Our AI-powered plan optimizer will automatically recommend how to best allocate your income in order to reach your goals faster.",
    */
    body: "You’re now able to create an optimized with virtually no manual work. Since this is a Beta release, please reach out to your coach if you stumble on a bug and/or things do not work as expected.",
  },
  // Manually
  manual: {
    heading: "Manually",
    body: "Our easy-to-use plan building technology allows you to set up and simulate your plan.",
  }
};