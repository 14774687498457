import React from "react";
import { useSelector } from "react-redux";
import { map } from "lodash";

import {
  Box,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { getIsMarried } from "src/store/system/selector";

const INV_ACCOUNT_TYPES = {
  trad_401k_rollover: "401k/403b Rollover",
  roth_401k_rollover: "Roth 401k/403b Rollover",
  trad_ira: "Traditional IRA",
  roth_ira: "Roth IRA",
  sep_ira: "SEP IRA",
  taxable_investment: "Individual Taxable",
};

const PURPOSES = {
  retirement: "Retirement",
  retirement_income: "Retirement Income",
  safety_net: "Safety Net",
  general_investing: "General Investing",
  idr_tax_savings: "IDR Tax Savings",
};

export const AccountDetails = ({
  next,
  goBack,
  formValues,
  handleChange,
}: any) => {
  const isMarried = useSelector(getIsMarried);

  const ready = +formValues.term > 0;

  return (
    <Card title="Choose Account Details">
      <Box className="flex justify-between">
        <Box className="mt-6 mr-4">
          <Typography>
            Note: If you'd like to open more than one type of account, you may
            do so after you open the first account.
          </Typography>
        </Box>
        <Box className="w-1/2 flex flex-col">
          {isMarried && (
            <>
              <FormLabel className="mb-2 mt-4">
                Who is This Account For?
              </FormLabel>
              <Select
                variant="outlined"
                name="who"
                onChange={handleChange}
                value={formValues.who}
              >
                <MenuItem value="applicant">Mine</MenuItem>
                <MenuItem value="spouse">Spouse</MenuItem>
              </Select>
            </>
          )}
          <FormLabel className="mb-2 mt-4">Account Type</FormLabel>
          <Select
            variant="outlined"
            name="accountType"
            onChange={handleChange}
            value={formValues.accountType}
          >
            {map(INV_ACCOUNT_TYPES, (label, value) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <FormLabel className="mb-2 mt-4">Goal Purpose</FormLabel>
          <Select
            variant="outlined"
            name="purpose"
            onChange={handleChange}
            value={formValues.purpose}
          >
            {map(PURPOSES, (label, value) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <FormLabel className="mb-2 mt-4">Goal Term in Years</FormLabel>
          <TextField
            error={!+formValues.term}
            helperText={
              !+formValues.term ? "Please enter a number of years" : ""
            }
            variant="outlined"
            onChange={handleChange}
            name="term"
            value={formValues.term}
            fullWidth
          />
          <Box className="flex mt-4 justify-center">
            <Button className="mr-4" onClick={goBack}>
              Back
            </Button>
            <Tooltip
              disableFocusListener={ready}
              disableHoverListener={ready}
              disableTouchListener={ready}
              title={ready ? "" : "Please complete the required form fields."}
            >
              <span>
                <Button disabled={!ready} fbColor="primary" onClick={next}>
                  Next
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default AccountDetails;
