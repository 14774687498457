import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { Box, makeStyles, Typography } from "@material-ui/core";

import { investmentsStyles } from "src/theme";
import Button from "src/components/Button";
import InvestmentsLayout from "src/layouts/InvestmentsLayout";

import { openUpgradeDialog } from "src/store/system/actions";
import { getIsSubscribed } from "src/store/system/selector";
import TitleCard from "src/components/TitleCard";

const useStyles = makeStyles(investmentsStyles);

export const HowToInvest = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const subscribed = useSelector(getIsSubscribed);

  useEffect(() => {
    if (subscribed) {
      dispatch(push("/investment-allocations"));
    }
  }, [subscribed]);

  return (
    <InvestmentsLayout
      goBackTarget="/investments"
      headerText="How Should I Invest My Money?"
    >
      <main className={classes.content}>
        <TitleCard title="It's All About Risks (And Returns!)">
          <Typography paragraph>
            NOTE: This is only available to premium members.
          </Typography>
          <Typography paragraph>
            There are 2 different types of risks to take into account when
            making investment decisions: the level of risk your financial
            situation can bear, and the level of risk you are willing to take as
            an individual.
          </Typography>
          <Typography paragraph>
            By looking at your holistic financial picture as well as your
            current investments (e.g., your holdings held in 401k, Roth IRA,
            Crypto, etc.), we will calculate whether your current allocation is
            aligned with your overall risk profile and recommend adjustments if
            needed.
          </Typography>
        </TitleCard>
        <Box className="w-full flex justify-center">
          <Button
            fbColor="primary"
            onClick={() => dispatch(openUpgradeDialog())}
          >
            Next
          </Button>
        </Box>
      </main>
    </InvestmentsLayout>
  );
};

export default HowToInvest;
