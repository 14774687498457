import axios from "axios";
import { getAuthToken } from "../config";

const API_ENDPOINT = process.env.REACT_APP_API_URL || "";
export const SHORT_ENDPOINT = API_ENDPOINT.replace("/api", "");

const Client = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const unauthenticatedUrls = [
  "auth/sign_in.php",
  "auth/refresh_token.php",
  "auth/signup.php",
];

Client.interceptors.request.use(
  async (config) => {
    if ((config.url as string).indexOf("get_dashboard_data.php") >= 0) {
      config.url = (config.url as string).replace("/api", "");
    }
    if (unauthenticatedUrls.indexOf(config.url as string) >= 0) {
      return config;
    }
    const token = await getAuthToken();
    if (token && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete Client.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

export default Client;
