import React, { useState } from "react";

import { makeStyles } from "@material-ui/core";

import { Modal } from "src/components/Dialogs";
import DashboardLayout from "src/layouts/DashboardLayout";
import { headerStyles } from "src/theme";

import { Content } from "./Content";

const useStyles = makeStyles(headerStyles);

export const GeneralPage = ({ data, title }: any) => {
  const classes = useStyles();
  const [dialogData, setDialogData] = useState<any>(null);

  return (
    <DashboardLayout pageTitle={title}>
      <main className={classes.mainContent}>
        <Content data={data} setDialogData={setDialogData} />
        <Modal
          title={dialogData?.title}
          isOpen={!!dialogData}
          onClose={() => setDialogData(null)}
          size="sm"
        >
          {dialogData?.content || null}
        </Modal>
      </main>
    </DashboardLayout>
  );
};

export default GeneralPage;
