import React from "react";
import { useSelector } from "react-redux";

import BarChart from "src/components/BarChart";
import colors from "src/theme/colors";

import { ASSET_TYPES, DEBT_TYPES } from "src/interfaces/common";
import { getAccounts } from "src/store/account/selector";
import { getHcValue } from "src/store/dashboard/selector";
import { formatThousands } from "src/utils";

export const NetWealthChart = () => {
  const accounts = useSelector(getAccounts);
  const hcValue = useSelector(getHcValue);
  const calculateSum = (types: any) => {
    const keys = Object.keys(types);
    return accounts
      .filter((item) => item.variable && keys.includes(item.variable))
      .reduce((acc: number, currVal: any) => acc + currVal["balance"], 0);
  };
  const financialBalance = calculateSum(ASSET_TYPES);
  const debts = calculateSum(DEBT_TYPES);

  return (
    <BarChart
      colors={[
        colors.warn,
        colors.brandingBlue3,
        colors.brandingBlue2,
        colors.brandingBlue1,
      ]}
      formatter={formatThousands}
      hideAxis
      hideTooltip
      value="value"
      list={[]}
      dataSource={[
        {
          name: "Human Capital",
          value: hcValue,
        },
        {
          name: "Total Assets",
          value: financialBalance,
        },
        {
          name: "Total Debts",
          value: -debts,
        },
        {
          name: "Net Wealth",
          value: financialBalance - debts + hcValue,
        },
      ]}
      height="h-64"
      title="Total Net Wealth"
      paddingX="2"
    />
  );
};

export default NetWealthChart;
