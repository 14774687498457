import React from "react";
import { useSelector } from "react-redux";

import Base from "./views/base";
import Extended from "./views/extended";
import { getPlans } from "src/store/planBuild/selector";

const Logout = () => {
  const plans = useSelector(getPlans);
  const hasPlans = () => {
    return plans.length > 0;
  };

  const havePlanImplemented = () => {
    if (hasPlans()) {
      return plans[0].implemented;
    }
    return false;
  };

  if (hasPlans() && !havePlanImplemented()) {
    return <Extended />;
  }
  return <Base />;
};

export default Logout;
