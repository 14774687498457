import { makeStyles } from "@material-ui/core";
import fbColors from "src/theme/colors";

export const useStyles = makeStyles({
  cell: {
    width: "30%",
    fontSize: 13,
  },
  headerCell: {
    fontWeight: "bolder",
    width: "25%",
    fontSize: 14,
    marginBottom: 15,
    color: fbColors.brandingBlue1,
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  btnCancel: {
    display: "block",
    margin: "20px auto",
    width: "calc(100% - 50px)",
  },
});
