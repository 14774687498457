import { EducationSection } from "src/interfaces/profile.interface";

const emptyEducationLevels: Array<Partial<EducationSection>> = [
  {
    phd_deg: "y",
  }, // PHD, no higher level to clear
  {
    phd_deg: "n",
    phd_grad_month: 0,
    phd_grad_year: 0,
    phd_field: 0,
    phd_school: 0,
    adv_deg: "y",
  }, // Advanced, clear PHD section
  {
    phd_deg: "n",
    phd_grad_month: 0,
    phd_grad_year: 0,
    phd_field: 0,
    phd_school: 0,
    adv_deg: "y",
    adid: 999,
  }, // Master's certificate, clear PHD
  {
    phd_deg: "n",
    phd_grad_month: 0,
    phd_grad_year: 0,
    phd_field: 0,
    phd_school: 0,
    adv_deg: "n",
    date_last_school_month: 0,
    date_last_school_year: 0,
    adid: 0,
    adv_school: 0,
    adv_school_other: 0,
    adv_deg_gpa: 0,
    adv_deg_exam: "",
    adv_second_deg: "n",
    adv_second_deg_name: "",
    adv_second_school: "",
    adv_deg_school: "",
    adv_speciality: 0,
    adv_speciality_other: "",
    adv_speciality_other_yn: "n",
    grad_internships: 0,
    undergrad: "y",
  }, // Completed undergrad, clear certificate and advanced
  {
    phd_deg: "n",
    phd_grad_month: 0,
    phd_grad_year: 0,
    phd_field: 0,
    phd_school: 0,
    adv_deg: "n",
    date_last_school_month: 0,
    date_last_school_year: 0,
    adid: 0,
    adv_school: 0,
    adv_school_other: 0,
    adv_deg_gpa: 0,
    adv_deg_exam: "",
    adv_second_deg: "n",
    adv_second_deg_name: "",
    adv_second_school: "",
    adv_deg_school: "",
    adv_speciality: 0,
    adv_speciality_other: "",
    adv_speciality_other_yn: "n",
    grad_internships: 0,
    undergrad: "y",
  }, // Attending undergrad, clear certificate and advanced
  {
    phd_deg: "n",
    phd_grad_month: 0,
    phd_grad_year: 0,
    phd_field: 0,
    phd_school: 0,
    adv_deg: "n",
    date_last_school_month: 0,
    date_last_school_year: 0,
    adid: 0,
    adv_school: 0,
    adv_school_other: 0,
    adv_deg_gpa: 0,
    adv_deg_exam: "",
    adv_second_deg: "n",
    adv_second_deg_name: "",
    adv_second_school: "",
    adv_deg_school: "",
    adv_speciality: 0,
    adv_speciality_other: "",
    adv_speciality_other_yn: "n",
    grad_internships: 0,
    undergrad: "y",
    aa_deg: "y",
  }, // Attending undergrad, clear certificate and advanced
  {
    phd_deg: "n",
    phd_grad_month: 0,
    phd_grad_year: 0,
    phd_field: 0,
    phd_school: 0,
    adv_deg: "n",
    date_last_school_month: 0,
    date_last_school_year: 0,
    adid: 0,
    adv_school: 0,
    adv_school_other: 0,
    adv_deg_gpa: 0,
    adv_deg_exam: "",
    adv_second_deg: "n",
    adv_second_deg_name: "",
    adv_second_school: "",
    adv_deg_school: "",
    adv_speciality: 0,
    adv_speciality_other: "",
    adv_speciality_other_yn: "n",
    grad_internships: 0,
    undergrad: "n",
    undergrad_field: 0,
    undergrad_grad_month: 0,
    undergrad_grad_year: 0,
    undergrad_school: 0,
    undergrad_gpa: "",
    undergrad_internships: 0,
    aa_deg: "n",
    aa_grad_month: 0,
    aa_grad_year: 0,
    aa_field: 0,
    aa_school: 0,
  }, // Dropped out, clear certificate and advanced
  {
    phd_deg: "n",
    phd_grad_month: 0,
    phd_grad_year: 0,
    phd_field: 0,
    phd_school: 0,
    adv_deg: "n",
    date_last_school_month: 0,
    date_last_school_year: 0,
    adid: 0,
    adv_school: 0,
    adv_school_other: 0,
    adv_deg_gpa: 0,
    adv_deg_exam: "",
    adv_second_deg: "n",
    adv_second_deg_name: "",
    adv_second_school: "",
    adv_deg_school: "",
    adv_speciality: 0,
    adv_speciality_other: "",
    adv_speciality_other_yn: "n",
    grad_internships: 0,
    undergrad: "n",
    undergrad_field: 0,
    undergrad_grad_month: 0,
    undergrad_grad_year: 0,
    undergrad_school: 0,
    undergrad_gpa: "",
    undergrad_internships: 0,
    aa_deg: "y",
  }, // Completed AA, clear undergrad and higher
  {
    phd_deg: "n",
    phd_grad_month: 0,
    phd_grad_year: 0,
    phd_field: 0,
    phd_school: 0,
    adv_deg: "n",
    date_last_school_month: 0,
    date_last_school_year: 0,
    adid: 0,
    adv_school: 0,
    adv_school_other: 0,
    adv_deg_gpa: 0,
    adv_deg_exam: "",
    adv_second_deg: "n",
    adv_second_deg_name: "",
    adv_second_school: "",
    adv_deg_school: "",
    adv_speciality: 0,
    adv_speciality_other: "",
    adv_speciality_other_yn: "n",
    grad_internships: 0,
    undergrad: "n",
    undergrad_field: 0,
    undergrad_grad_month: 0,
    undergrad_grad_year: 0,
    undergrad_school: 0,
    undergrad_gpa: "",
    undergrad_internships: 0,
    aa_deg: "n",
    aa_grad_month: 0,
    aa_grad_year: 0,
    aa_field: 0,
    aa_school: 0,
  }, // Completed high school, clear all
];

export default emptyEducationLevels;
