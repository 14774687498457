import React from "react";

import { Grid } from "@material-ui/core";

import ProfileInformation from "./Blocks/ProfileInformation";
import SpouseInformation from "./Blocks/SpouseInfo";
import Subscription from "./Blocks/Subscription";
import UpdatePassword from "./Blocks/UpdatePassword";

export const Content = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProfileInformation />
      </Grid>
      <Grid item xs={12}>
        <SpouseInformation />
      </Grid>
      <Grid item xs={12}>
        <Subscription />
      </Grid>
      <Grid item xs={12}>
        <UpdatePassword />
      </Grid>
    </Grid>
  );
};
