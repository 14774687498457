import React from "react";
import { Box, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CalculatorHeader from "src/components/Headers/CalculatorHeader";

interface CalculatorLayoutProps {
  title: string;
  backTo?: string;
  headerContent?: React.ReactNode;
  onBackClick?: VoidFunction;
  disclaimer?: string;
  footer?: React.ReactNode;
  hideCloseIcon?: boolean;
}
const CalculatorLayout = ({
  backTo,
  headerContent,
  title,
  disclaimer,
  onBackClick,
  children,
  footer,
  hideCloseIcon,
}: React.PropsWithChildren<CalculatorLayoutProps>) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CalculatorHeader
        backTo={backTo}
        extraContent={headerContent}
        headerTitle={title}
        goBack={onBackClick}
        hideCloseIcon={hideCloseIcon}
      />
      <Box className={classes.container}>{children}</Box>
      {!!disclaimer && (
        <Box className={classes.disclaimerContainer}>
          <Typography className={classes.disclaimerText}>
            {disclaimer}
          </Typography>
        </Box>
      )}
      {footer || null}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    minHeight: "calc(100vh)",
    flexDirection: "column",
  },
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  disclaimerContainer: {
    padding: theme.spacing(2, 8, 4, 2),
    width: "97%",
  },
  disclaimerText: {
    fontSize: 9,
  },
}));

export default CalculatorLayout;
