import colors from "src/theme/colors";

export default {
  root: {
    height: 500,
    overflow: "hidden",
    position: "relative",
  },
  slide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  img: {
    maxHeight: 200,
    maxWidth: 200,
    background: "rgb(225 246 255 / 50%)",
    "& img": {
      width: "100%",
      mixBlendMode: "multiply",
      background: "#e1f6ff",
    },
  },
  imgFull: {
    height: "95%",
    width: "95%",
  },
  title: {
    fontSize: 28,
    fontWeight: 600,
    color: colors.blueGray1,
    margin: "10px 0",
  },
  subline: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.blueGray2,
    margin: "10px 0",
  },
  description: {
    fontSize: 14,
    color: colors.blueGray2,
  },
  dots: {
    display: "flex",
    padding: 10,
    justifyContent: "center",
    position: "absolute",
    flexDirection: "column",
    right: -10,
    top: 0,
    height: "100%",
  },
  dot: {
    border: "none",
    width: 7,
    height: 7,
    background: colors.gradDisabledBubbleDark,
    borderRadius: 7,
    padding: 5,
    margin: 5,
    cursor: "pointer",
    "&.active": {
      background: colors.brandingBlue2,
    },
    "&:forcus": {
      outline: "none",
    },
  },
};
