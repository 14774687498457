import React from "react";
import { useSelector } from "react-redux";

import {
  Box,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

import {
  getAccountTypesWithBalances,
  getMyFedLoanPayments,
  getSpouseFedLoanPayments,
} from "src/store/account/selector";
import {
  currentPlanIncomeTotal,
  currentPlanExpenseTotal,
  currentPlanRiskManagementTotal,
  getCurrentPlanSurplus,
  getCurrentPlan,
  getCurrentPlanAllocations,
  getCurrentPlanMessages,
  getLiabilities,
  isCurrentPlanImplemented,
} from "src/store/planBuild/selector";
import colors from "src/theme/colors";
import {
  formatExecutionPercent,
  formatPercent,
  formatWholeDollars,
} from "src/utils";
import CenterContainer from "src/pages/PlanBuilder/Components/CenterContainer";
import HelpToggle from "src/components/HelpToggle";
import { ASSET_TYPES, DEBT_TYPES, SidebarContent } from "src/interfaces";
import { getReviewMessage } from "src/constants/planReviewMessages";
import LinkedText from "src/components/TextWithLink";

const blowOutContent: SidebarContent[] = [
  {
    header: "Thinking In Percentages",
    body: [
      "Analyzing your financial plan using percentages is much easier and more informative than looking at absolute dollar amounts.",
      "1) It makes it easy to visualize the most significant and impactful items in your finances.  It also makes it easy to make adjustments.",
      "2) It get rids of the guesswork. For example, if you get a pay raise, you don't have to guess the amount you should now dedicate to a goal (all else equal). You simply have to look at what percentage of your income is supposed to go where according to your plan and adjust the dollar amount accordingly.",
    ],
  },
];

const PayCheckAllocation = ({ optimized }: { optimized?: boolean }) => {
  const styles = useStyles();
  const incomeTotal = useSelector(currentPlanIncomeTotal);
  const expenseTotal = useSelector(currentPlanExpenseTotal);
  const riskTotal = useSelector(currentPlanRiskManagementTotal);
  const remainingMoney = useSelector(getCurrentPlanSurplus);
  const plan = useSelector(getCurrentPlan);
  const messages = useSelector(getCurrentPlanMessages);
  const { assets, debts: rawDebts } = useSelector(getCurrentPlanAllocations);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const accountTypesWithBalances = useSelector(getAccountTypesWithBalances);
  const myFedLoanPayments = useSelector(getMyFedLoanPayments);
  const spouseFedLoanPayments = useSelector(getSpouseFedLoanPayments);
  const liabilities = useSelector(getLiabilities);
  const payCheckWidth = messages.length ? 6 : 12;
  const orderIndex = optimized ? 3 : 6;
  const title = `${orderIndex}. Paycheck Allocation`;
  const myStudentLoanLiability = currentPlanIsImplemented
    ? myFedLoanPayments * 12
    : liabilities.min.solo[0].fed_loan || 0;
  const spouseStudentLoanLiability = currentPlanIsImplemented
    ? spouseFedLoanPayments * 12
    : liabilities.min.solo[1].fed_loan || 0;

  const formatValue = (value: number) => {
    return formatWholeDollars(Math.round(value / 12));
  };

  const debts = !currentPlanIsImplemented
    ? rawDebts
    : rawDebts
        .map((debt: any) => {
          let result = { ...debt };
          if (debt.type === "fed_loan") {
            const annualLiability =
              debt.who === "spouse"
                ? spouseStudentLoanLiability
                : myStudentLoanLiability;
            const annual = Math.max(debt.annual, annualLiability);
            const percent = (annual / incomeTotal) * 100;
            result = {
              ...debt,
              min_annual: annualLiability,
              min_monthly: annualLiability / 12,
              min: (annualLiability / incomeTotal) * 100,
              percent,
            };
          }
          return {
            ...result,
            annual: Math.max(result.annual, result.min_annual),
            monthly: Math.max(result.monthly, result.min_monthly),
            percent: Math.max(result.percent, result.min),
          };
        })
        .filter((debt: any) => accountTypesWithBalances.has(debt.type));

  const hasAllocatedAssets = !!assets.filter((asset: any) => asset.annual)
    .length;
  const hasAllocatedDebts = !!debts.filter((debt: any) => debt.annual).length;
  const sortedAssets = assets.sort(
    (a: any, b: any) => b.percent - a.percent || a.type.localeCompare(b.type)
  );
  const sortedDebts = debts.sort(
    (a: any, b: any) => b.annual - a.annual || a.type.localeCompare(b.type)
  );

  const renderGraduatedContent = () => (
    <Box className="mb-3">
      <Box>
        <Box className={styles.title}>
          <Typography> Day-To-Day Money </Typography>
          <Typography className="text-right">$ Amount </Typography>

          <Box className="flex align-center justify-end">
            <Typography className="relative">
              % Of Your Income
              <span style={{ position: "absolute", bottom: "-5px" }}>
                <HelpToggle content={blowOutContent} />
              </span>
            </Typography>
          </Box>
        </Box>
        <Divider className="w-full my-2" />

        <Box className={styles.itemBox}>
          <Typography>Total Income</Typography>
          <Typography className="text-right">
            {formatValue(incomeTotal)}
          </Typography>
          <Typography className="text-right">100.00%</Typography>
        </Box>

        <Box className={styles.itemBox}>
          <Typography>Day-to-Day Expenses</Typography>
          <Typography className="text-right">
            {formatValue(expenseTotal)}
          </Typography>
          <Typography className="text-right">
            {" "}
            {formatExecutionPercent(expenseTotal, incomeTotal)}{" "}
          </Typography>
        </Box>
      </Box>

      <Box className={styles.title}>
        <Typography> Money For Future Goals</Typography>
      </Box>
      <Divider className="w-full my-2" />

      <Box>
        <Box className={styles.itemBox}>
          <Typography>Asset Contributions</Typography>
          {/* 
            Render the amount and percent for each for each asset type in the current plan allocation 0 
            Hide the dollar and percents for the the label
            Indent each of these 
            */}
          <Typography className="text-right">
            {hasAllocatedAssets ? "" : "$0"}
          </Typography>
          <Typography className="text-right">
            {hasAllocatedAssets ? "" : "0%"}
          </Typography>
        </Box>

        {hasAllocatedAssets &&
          sortedAssets.map((asset: any) => (
            <Box className={styles.subItemBox}>
              <Typography className="pl-8">
                {ASSET_TYPES[asset.type]}{" "}
                {asset.who === "spouse" ? "(Spouse)" : ""}
              </Typography>
              <Typography className="text-right">
                {formatValue(asset.annual)}
              </Typography>
              <Typography className="text-right">
                {formatPercent(asset.percent)}
              </Typography>
            </Box>
          ))}

        <Box className={styles.itemBox}>
          <Typography>Debt Payments</Typography>
          {/* 
        Render the amount and percent for each for each Debt type in the current plan allocation 0 
        Hide the dollar and percents for the the label
        Indent each of these 
      */}
          <Typography className="text-right">
            {hasAllocatedDebts ? "" : "$0"}
          </Typography>
          <Typography className="text-right">
            {hasAllocatedDebts ? "" : "0%"}
          </Typography>
        </Box>

        {hasAllocatedDebts &&
          sortedDebts.map((debt: any) => (
            <Box className={styles.subItemBox}>
              <Typography className="pl-8">
                {DEBT_TYPES[debt.type]}{" "}
                {debt.who === "spouse" ? "(Spouse)" : ""}
              </Typography>
              <Typography className="text-right">
                {formatValue(debt.annual)}
              </Typography>
              <Typography className="text-right">
                {formatPercent(debt.percent)}
              </Typography>
            </Box>
          ))}
      </Box>

      <Box>
        <Box className={styles.title}>
          <Typography> Risk Management </Typography>
        </Box>
        <Divider className="w-full my-2" />
        <Box className={styles.itemBox}>
          <Typography>Insurance Premiums</Typography>
          <Typography className="text-right">
            {formatValue(riskTotal)}
          </Typography>
          <Typography className="text-right">
            {" "}
            {formatExecutionPercent(riskTotal, incomeTotal)}{" "}
          </Typography>
        </Box>
      </Box>

      <Box>
        <Box className={styles.title}>
          <Typography> Vacation/Fun Money </Typography>
        </Box>
        <Divider className="w-full my-2" />
        <Box className={styles.itemBox}>
          <Typography> Unallocated Funds </Typography>
          <Typography className="text-right">
            {" "}
            {formatValue(remainingMoney)}{" "}
          </Typography>
          <Typography className="text-right">
            {" "}
            {formatPercent((remainingMoney / incomeTotal) * 100)}{" "}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const renderPlanMessages = () =>
    messages.map((entry) => {
      const planMessage = getReviewMessage(entry, "paycheck");
      if (planMessage.text) {
        if (!planMessage.links) {
          return (
            <Typography
              style={{
                fontSize: "14px",
                marginBottom: "10px",
              }}
            >
              {planMessage.text}
            </Typography>
          );
        }
        return (
          <Typography
            style={{
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <LinkedText
              text={planMessage.text}
              links={planMessage.links}
              indicator="(Click here to do so)"
            />
          </Typography>
        );
      }
      return null;
    });

  const boxStyle = messages.length ? "messagesPresent" : "noMessages";

  return (
    <CenterContainer review title={title} scrollable={!plan.messages}>
      <Container className="flex justify-center">
        {!!messages.length && (
          <Grid xs={6} className={styles[boxStyle]}>
            {renderPlanMessages()}
          </Grid>
        )}
        <Grid xs={payCheckWidth} className={styles[boxStyle]}>
          <Typography style={{ fontSize: "14px" }}>
            Below is a summary of how you chose to allocate your money each
            month to follow your plan and hit your goals.
          </Typography>
          {renderGraduatedContent()}
        </Grid>
      </Container>
    </CenterContainer>
  );
};

export default PayCheckAllocation;

const useStyles = makeStyles({
  actionBar: {
    background: "white",
    padding: "12px 68px",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    width: "100%",
  },
  actionButton: {
    width: 168,
  },
  cardRowCell: {
    fontSize: 15,
    width: "16%",
  },
  cardRowLabel: {
    fontSize: 15,
    paddingLeft: 10,
    width: "36%",
  },
  cardTableHeader: {},
  chart: {
    minWidth: 440,
    maxWidth: 524,
  },
  loaderContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 555,
    background: "rgba(255, 255, 255, .8)",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0px",
    "&>p": {
      color: colors.brandingBlue1,
      fontSize: 13,
      fontWeight: 500,
      width: "33%",
    },
    "&>div": {
      width: "33%",
      display: "flex",
      justifyContent: "center",
      "&>p": {
        color: colors.brandingBlue1,
        fontSize: 13,
        fontWeight: 500,
      },
    },
  },
  itemBox: {
    display: "flex",
    justifyContent: "space-between",
    "& p": {
      fontSize: 13,
      width: "36%",
      paddingLeft: 16,
    },
    "& div": {
      width: "33%",
    },
  },
  subItemBox: {
    display: "flex",
    justifyContent: "space-between",
    "& p": {
      fontSize: 13,
      width: "33%",
      textTransform: "capitalize",
    },
    "& div": {
      width: "400px",
    },
  },
  messagesPresent: {
    margin: "1rem 0px",
    height: "calc(100vh - 300px)",
    padding: "0px 1.5rem 0 .5rem",
    overflowY: "auto",
    overflowX: "hidden",
  },

  noMessages: {
    margin: "2rem 10px",
    height: "calc(100vh - 300px)",
  },
});
