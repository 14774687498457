import { NPER } from "src/utils";

interface GetTermInput {
  rate: number;
  balance: number;
  payment: number;
}
const getTerm = ({
  rate,
  balance,
  payment,
}: GetTermInput): { month: number; year: number; term: number } => {
  const nper = NPER(Number(rate / 100) / 12, -payment, balance, 0, 0);
  const term = Number(nper / 12);
  if (term <= 30) {
    const year = Math.floor(term);
    const month = Math.round((term - Math.floor(term)) * 12); //
    return {
      month: month === 12 ? 0 : month,
      year,
      term,
    };
  }
  return {
    month: 0,
    year: 30,
    term: 30,
  };
};

const convertTermToDate = (year: number) => {
  const date = new Date();
  const months = Math.round(year * 12);
  date.setMonth(date.getMonth() + months);
  const options = { year: "numeric", month: "2-digit" };
  return date.toLocaleDateString("en-US", options as any);
};

const calculateDebtToIncome = (income: number, debt: number) => {
  if (income === 0) return "N/A";
  const ratio = ((debt / income) * 100).toFixed(1);
  return `${ratio}%`;
};

export { getTerm, convertTermToDate, calculateDebtToIncome };
