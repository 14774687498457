import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Legend,
  ResponsiveContainer,
} from "recharts";
import colors from "src/theme/colors";

const COLORS = [colors.brandingBlue1, colors.brandingBlue2];

interface IChart {
  key?: string;
  value?: number;
}
interface ICircularProgress {
  data: IChart[];
  format?: string;
  labelXOffset?: number;
  labelYOffset?: number;
}

const CircularProgressBar = ({ data, format }: ICircularProgress) => {
  return (
    <div className="h-40">
      <ResponsiveContainer>
        <PieChart width={300} height={300}>
          {format === "list" && (
            <Legend
              verticalAlign="middle"
              align="center"
              iconSize={9}
              iconType="circle"
              formatter={(value) => (
                <span style={{ fontSize: 12 }}>{value}</span>
              )}
              wrapperStyle={{ paddingLeft: "10px" }}
            />
          )}
          <Pie
            data={data}
            innerRadius="80%"
            outerRadius="100%"
            startAngle={90}
            endAngle={-270}
            fill="#8884d8"
            dataKey="value"
            labelLine={false}
          >
            {data.map((item, index) => (
              <Cell
                name={item.key}
                key={`cell-${index}`}
                fill={COLORS[index]}
              />
            ))}
            {format !== "list" && (
              <Label
                position="center"
                value={`${data[0].value || 0}/${
                  (data[1].value || 0) + (data[0].value || 0)
                }`}
                style={{
                  textAnchor: "middle",
                  fontSize: "120%",
                  fontWeight: 600,
                  fill: colors.brandingBlue1,
                }}
              />
            )}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

// const useStyles = makeStyles({
//   label: {
//     height: "inherit",
//     display: "flex",
//     justifyContent: "center",
//     flexDirection: "column",
//     "&>p": {
//       fontSize: 11,
//       margin: "0px 0px 3px 0px",
//       fontWeight: 600,
//       color: colors.brandingBlue1,
//     },
//   },
//   dot: {
//     width: 8,
//     height: 8,
//     borderRadius: "50%",
//     display: "inline-block",
//     marginRight: 5,
//   },
// });

export default CircularProgressBar;
