import React from "react";

import { map, pick } from "lodash";

import {
  Box,
  Divider,
  FormLabel,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Icon from "src/components/Icon";
import { Breakout, DEDUCTION_BREAKOUTS } from "src/interfaces";
import { colors } from "src/theme";
import { DollarTextField } from "src/utils";
import BreakoutSummary from "./BreakoutSummary";

const useStyles = makeStyles({
  button: {
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
  columnHeader: {
    color: colors.brandingBlue1,
    fontSize: 12,
    fontWeight: 500,
  },
});

const InputDeductions = ({
  formValues,
  handleChange,
  removeBreakout,
  transaction,
}: any) => {
  const classes = useStyles();
  const deductions = formValues.deductions || [];
  const addItem = () => {
    const newDeductions = [
      ...deductions,
      { type: "", amount: "", id: Math.random() },
    ];
    handleChange({ target: { name: "deductions", value: newDeductions } });
  };

  const handleChangeItemType = (e: React.ChangeEvent<any>, index: number) => {
    const value = e.target.value;
    const newDeductions = [...formValues.deductions];
    newDeductions[index] = {
      ...newDeductions[index],
      type: value,
      amount: newDeductions[index].amount,
    };
    handleChange({ target: { name: "deductions", value: newDeductions } });
  };

  const handleChangeAmount = (e: React.ChangeEvent<any>, index: number) => {
    const value = e.target.value;
    const newDeductions = [...formValues.deductions];
    newDeductions[index] = {
      ...newDeductions[index],
      type: newDeductions[index].type,
      amount: value,
    };
    handleChange({ target: { name: "deductions", value: newDeductions } });
  };

  const displayType = formValues?.type || transaction?.type || "";
  let availableBreakouts: any = DEDUCTION_BREAKOUTS;
  if (+displayType === 7002) {
    availableBreakouts = pick(DEDUCTION_BREAKOUTS, [
      "12303",
      "12034",
      "12009",
      "12033",
      "12029",
    ]);
  } else if (+displayType === 7003) {
    availableBreakouts = { "12302": DEDUCTION_BREAKOUTS["12302"] };
  } else {
    availableBreakouts = pick(availableBreakouts, [
      "15000",
      "15001",
      "15002",
      "15005",
      "15006",
      "15007",
      "15008",
      "12302",
      "13102",
      "13103",
      "13104",
      "13105",
      "13106",
      "13108",
      "12026",
      "12029",
    ]);
  }

  return (
    <form>
      <FormLabel component="legend" className="mb-6">
        Please enter your deduction(s). This will alllow us to calculate your
        net income.
      </FormLabel>
      <Typography variant="h4" className={classes.columnHeader}>
        Deduction Type
      </Typography>
      {deductions.map((item: Breakout, index: number) => (
        <Box className="flex justify-between items-center" key={item.id}>
          <Select
            className="my-2 mr-2"
            fullWidth
            name="type"
            variant="outlined"
            displayEmpty
            renderValue={() =>
              DEDUCTION_BREAKOUTS[
                (item.type + "") as keyof typeof DEDUCTION_BREAKOUTS
              ] || "Deduction Type"
            }
            value={item.type}
            onChange={(e) => handleChangeItemType(e, index)}
          >
            {map(availableBreakouts, (label, key) => (
              <MenuItem key={key} value={+key}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <DollarTextField
            inputProps={{ style: { textAlign: "right" } }}
            variant="outlined"
            onChange={(e: any) => handleChangeAmount(e, index)}
            style={{ width: "150px" }}
            value={Math.abs(item.amount)}
          />
          <IconButton onClick={() => removeBreakout("deductions", index)}>
            <Icon iconName="fb-trash-can" />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="outlined"
        fbColor="primary"
        onClick={addItem}
        className={`w-full mt-2 ${classes.button}`}
        endIcon={<Icon iconName="fb-add-alt" />}
      >
        Add Deduction
      </Button>
      <Divider className="my-4" />
      <BreakoutSummary values={formValues} transaction={transaction} />
    </form>
  );
};

export default InputDeductions;
