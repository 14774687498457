import { createAction } from "redux-actions";
import { START } from "../common";

export const GET_PORTFOLIO = "GET_PORTFOLIO";
export const SET_PORTFOLIO = "SET_PORTFOLIO";
export const GET_PERFORMANCE = "GET_PERFORMANCE";
export const CREATE_BETTERMENT_ACCOUNT = "CREATE_BETTERMENT_ACCOUNT";

export const getPortfolio = createAction(GET_PORTFOLIO + START);
export const updatePortfolio = createAction(SET_PORTFOLIO + START);
export const getPerformance = createAction(GET_PERFORMANCE + START);
export const createBettermentAccount = createAction(
  CREATE_BETTERMENT_ACCOUNT + START
);
