import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { range } from "lodash";

import {
  Box,
  Grid,
  makeStyles,
  FormControlLabel,
  FormLabel,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import { addSpouseApi, authorizeSpouseApi } from "src/apiService";
import Button from "src/components/Button";
import CustomDialog from "src/components/Dialogs/CustomDialog";
import Radio from "src/components/Radio";
import { DAYS_IN_MONTH, MONTHS } from "src/constants";
import InformationCard from "../Components/InformationCard";
import { Gender } from "src/interfaces";
import {
  fetchSpouseProfile,
  updatePersonalInfo,
  UpdatePersonalInfoPayload,
} from "src/store/profileBuild/actions";
import { getSpouseProfile } from "src/store/profileBuild/selector";
import {
  addSpouse,
  addSpouseSuccess,
  AddSpousePayload,
} from "src/store/system/actions";
import { getIsMarried, getIsSpouseLinked } from "src/store/system/selector";
import colors from "src/theme/colors";

interface MyFormValues {
  enter_spouse_manually: boolean;
  married: string;
  spouse_is_member: string;
  spouse_email: string;
  spouse_sex: Gender;
  spouse_dob_day: number;
  spouse_dob_month: number;
  spouse_dob_year: number;
  spouse_password: string;
}

const SpouseInformation = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const isMarried = useSelector(getIsMarried);
  const spouseProfile = useSelector(getSpouseProfile);
  const spouseLinked = useSelector(getIsSpouseLinked);
  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);
  const [formState, setFormState] = useState<MyFormValues>({
    married: "y",
    spouse_is_member: "n",
    spouse_sex: "o",
    spouse_dob_day: 1,
    spouse_dob_month: 1,
    spouse_dob_year: 1999,
    spouse_email: "",
    spouse_password: "",
    enter_spouse_manually: false,
  });

  useEffect(() => {
    const newFormState = {
      ...formState,
      spouse_sex: spouseProfile.sex || "o",
      spouse_dob_day: spouseProfile.dob_day || 1,
      spouse_dob_month: spouseProfile.dob_month || 1,
      spouse_dob_year: spouseProfile.dob_year || 1999,
    };
    setFormState(newFormState);
  }, [spouseProfile]);

  const genders = {
    m: "Male",
    f: "Female",
    o: "Other/prefer not to answer",
  };

  const setFormValue = (e: any) => {
    const target = e.target;
    setFormState({ ...formState, [target.name]: target.value });
  };

  const save = () => {
    if (formState.married === "y" && !isMarried) {
      const spousePayload: AddSpousePayload = {};
      if (formState.spouse_is_member === "y") {
        spousePayload.spouse = {
          email: formState.spouse_email,
          password: formState.spouse_password,
        };
        authorizeSpouseApi(
          formState.spouse_email,
          formState.spouse_password
        ).then(() => {
          dispatch(addSpouseSuccess({ linked: true }));
          dispatch(fetchSpouseProfile());
        });
      } else {
        const spousePayload: UpdatePersonalInfoPayload = {
          who: "spouse",
          update: {
            sex: formState.spouse_sex,
            dob_day: formState.spouse_dob_day,
            dob_month: formState.spouse_dob_month,
            dob_year: formState.spouse_dob_year,
          },
        };
        addSpouseApi()
          .then(() => {
            dispatch(addSpouseSuccess({ linked: false }));
            dispatch(fetchSpouseProfile());
          })
          .then(() => {
            dispatch(updatePersonalInfo(spousePayload));
          });
      }
    } else if (isMarried) {
      const spousePayload: UpdatePersonalInfoPayload = {
        who: "spouse",
        update: {
          sex: formState.spouse_sex,
          dob_day: formState.spouse_dob_day,
          dob_month: formState.spouse_dob_month,
          dob_year: formState.spouse_dob_year,
        },
      };
      dispatch(updatePersonalInfo(spousePayload));
    }
    closeDialog();
  };

  const nowYear = new Date().getFullYear();
  const startYear = nowYear - 70;
  const endYear = nowYear - 16;

  return (
    <InformationCard title="Spouse Information">
      <Grid container spacing={3} className={styles.container}>
        {!isMarried && (
          <Grid item xs={12}>
            <p className={styles.message}>Spouse not linked</p>
            <Button
              className="mx-auto"
              fbColor="secondary"
              onClick={openDialog}
            >
              Add My Spouse/Fiance
            </Button>
          </Grid>
        )}
        {isMarried && (
          <>
            <Grid item xs={4}>
              Date of birth: {spouseProfile.dob_month}/{spouseProfile.dob_day}/
              {spouseProfile.dob_year}
            </Grid>
            <Grid item xs={4}>
              Gender: {genders[spouseProfile.sex]}
            </Grid>
            <Grid item xs={4}>
              <Button
                className="mx-auto"
                fbColor="secondary"
                onClick={openDialog}
              >
                Edit Details
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <CustomDialog
        title="Spouse Information"
        isOpen={dialogOpen}
        onClose={closeDialog}
        size="sm"
      >
        <form>
          {!isMarried && (
            <>
              <FormLabel component="legend" className="mt-4 mb-2">
                Is your spouse a FitBUX member?
              </FormLabel>
              <RadioGroup
                aria-label="spouse_is_member"
                name="spouse_is_member"
                value={formState.spouse_is_member}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel value="n" control={<Radio />} label="No" />
                <FormControlLabel value="y" control={<Radio />} label="Yes" />
              </RadioGroup>
              {formState.spouse_is_member === "y" && (
                <>
                  <FormLabel component="legend" className="mt-4 mb-2">
                    Enter your spouse's info to link their account.
                  </FormLabel>
                  <TextField
                    className="block mt-2"
                    variant="outlined"
                    label="Email Address"
                    placeholder="Email Address"
                    name="spouse_email"
                    onChange={setFormValue}
                  />
                  <TextField
                    className="block my-2"
                    variant="outlined"
                    label="Password"
                    placeholder="Password"
                    name="spouse_password"
                    type="password"
                    onChange={setFormValue}
                  />
                  <Button
                    color="primary"
                    disabled={
                      !formState.spouse_email || !formState.spouse_password
                    }
                    onClick={() =>
                      dispatch(
                        addSpouse({
                          spouse: {
                            email: formState.spouse_email,
                            password: formState.spouse_password,
                          },
                        })
                      )
                    }
                  >
                    Link spouse account
                  </Button>
                </>
              )}
            </>
          )}
          {spouseLinked && (
            <Typography
              component="p"
              variant="body1"
              className="my-6 has-underline"
            >
              Your spouse's profile has been successfully linked.
            </Typography>
          )}
          {!isMarried &&
            formState.married === "y" &&
            formState.spouse_is_member === "n" && (
              <Typography
                component="p"
                variant="body1"
                className="my-6 has-underline"
              >
                You can enter your spouse's financial information manually as
                you build your profile.
              </Typography>
            )}
          {(isMarried || formState.spouse_is_member !== "y") && (
            <>
              <FormLabel component="legend" className="mt-4 mb-2">
                What is your spouse's date of birth?
              </FormLabel>
              <Box className="flex">
                <Select
                  variant="outlined"
                  style={{ width: "150px" }}
                  name="spouse_dob_month"
                  onChange={setFormValue}
                  value={formState.spouse_dob_month}
                  className="mr-4"
                >
                  {MONTHS.map((month: string, index: number) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  variant="outlined"
                  style={{ width: "150px" }}
                  name="spouse_dob_day"
                  onChange={setFormValue}
                  value={formState.spouse_dob_day}
                  className="mr-4"
                >
                  {range(1, DAYS_IN_MONTH[formState.spouse_dob_month] + 1).map(
                    (day) => (
                      <MenuItem key={day} value={day}>
                        {day}
                      </MenuItem>
                    )
                  )}
                </Select>
                <Select
                  variant="outlined"
                  style={{ width: "150px" }}
                  name="spouse_dob_year"
                  onChange={setFormValue}
                  value={formState.spouse_dob_year}
                >
                  {range(startYear, endYear + 1).map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <FormLabel component="legend" className="mt-4 mb-2">
                What is your spouse's gender?
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="spouse_sex"
                value={formState.spouse_sex}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel
                  value="m"
                  className="pr-4"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="f"
                  className="pr-4"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="o"
                  className="pr-4"
                  control={<Radio />}
                  label="Other/Prefer not to say"
                />
              </RadioGroup>
            </>
          )}
          <Button className="mt-4" fbColor="primary" onClick={save}>
            Submit
          </Button>
        </form>
      </CustomDialog>
    </InformationCard>
  );
};

const useStyles = makeStyles({
  container: {
    alignItems: "center",
    paddingTop: 20,
  },
  message: {
    fontSize: 14,
    color: colors.blueGray6,
    width: "100%",
    textAlign: "center",
    lineHeight: "26px",
  },
});
export default SpouseInformation;
