import React from "react";
import { useSelector } from "react-redux";

import { sortBy } from "lodash";

import {
  FormControlLabel,
  FormLabel,
  makeStyles,
  ListSubheader,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import {
  Account,
  ACCOUNT_TYPES,
  STUDENT_TOP_LEVEL_CATEGORIES,
  TOP_LEVEL_CATEGORIES,
  TRANSACTION_ACCOUNT_TYPES,
  TRANSACTION_CATEGORIES,
  TYPE_LIST_ORDER,
} from "src/interfaces";
import { getAccounts } from "src/store/account/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import Radio from "src/components/Radio";
import { DollarTextField } from "src/utils";

const useStyles = makeStyles({
  bigText: {
    fontWeight: 600,
    fontSize: 14,
    margin: "10px 0",
  },
});

const TransactionDetails = ({ formValues, handleChange, transaction }: any) => {
  const styles = useStyles();
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const accounts: Account[] = useSelector(getAccounts);
  const topLevelCategories = isCurrentStudent
    ? STUDENT_TOP_LEVEL_CATEGORIES
    : TOP_LEVEL_CATEGORIES;

  const selectedAccount = accounts.find(
    (account) => account.id === transaction.account
  );
  const availableTypes: any = {};
  const renderTypes: JSX.Element[] = [];
  const availableCategories: any[] = topLevelCategories.filter(
    (category, index) => {
      if (!selectedAccount) {
        return false;
      }
      let allowed = false;
      const subcategories = TRANSACTION_CATEGORIES.slice(
        category.start,
        category.end
      );
      subcategories.forEach((subcategory) => {
        let subcategoryItems: [string, string][] = [];
        Object.entries(subcategory.types).forEach(
          ([typeId, label]: [string, string | undefined]) => {
            const validAccountTypes =
              TRANSACTION_ACCOUNT_TYPES[
                typeId as keyof typeof TRANSACTION_ACCOUNT_TYPES
              ];
            const accountType =
              selectedAccount?.variable || selectedAccount?.type || "";
            if (
              validAccountTypes &&
              validAccountTypes.indexOf(accountType) >= 0
            ) {
              allowed = true;
              if (
                formValues.category === index &&
                typeId !== "6001" &&
                typeId !== "6002"
              ) {
                availableTypes[typeId] = label || "Type";
                subcategoryItems.push([typeId, label || "Type"]);
              }
            }
          }
        );
        if (subcategoryItems.length) {
          subcategoryItems = sortBy(
            subcategoryItems,
            (item: [string, string]) =>
              TYPE_LIST_ORDER[item[0] as keyof typeof TYPE_LIST_ORDER]
          );
          const renderSubcategoryItems = subcategoryItems.map(
            ([typeId, label]: [string, string]) => (
              <MenuItem key={typeId} value={typeId}>
                {label || "Type"}
              </MenuItem>
            )
          );
          renderTypes.push(
            <ListSubheader key={subcategory.label} disableSticky>
              {subcategory.label}
            </ListSubheader>,
            ...renderSubcategoryItems
          );
        }
      });
      return allowed;
    }
  );

  let accountName = selectedAccount?.name;
  if (!accountName) {
    const accountType =
      selectedAccount?.variable || selectedAccount?.type || "";
    if (accountType) {
      accountName += ` ${ACCOUNT_TYPES[accountType]}`;
    }
  }

  // const toggleIgnore = () =>
  //   handleChange({
  //     target: { name: "ignore", value: formValues.ignore === "y" ? "n" : "y" },
  //   });

  let displayType = "";
  if (!formValues.type) {
    displayType = transaction.type;
  } else if (availableTypes?.[formValues.type]) {
    displayType = formValues.type;
  }
  if (!availableTypes[displayType]) {
    displayType = "";
  }

  return (
    <form>
      <Typography variant="body1" className={styles.bigText}>
        Date: {transaction.date}
      </Typography>
      <Select
        className="my-2"
        fullWidth
        name="category"
        variant="outlined"
        displayEmpty
        renderValue={(value: any) =>
          value !== ""
            ? topLevelCategories?.[value]?.label || "Category"
            : "Category"
        }
        value={formValues.category === undefined ? "" : formValues.category}
        onChange={handleChange}
      >
        {availableCategories.map((item) => (
          <MenuItem
            key={item.label}
            value={topLevelCategories.findIndex(
              (category) => category.label === item.label
            )}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <Select
        className="my-2"
        fullWidth
        disabled={formValues.category === undefined}
        name="type"
        variant="outlined"
        displayEmpty
        renderValue={(value: unknown) =>
          value !== "" && availableTypes
            ? availableTypes[("" + value) as keyof typeof availableTypes]
            : "Type"
        }
        value={displayType}
        onChange={handleChange}
      >
        {renderTypes}
      </Select>
      <Typography variant="body1" className={styles.bigText}>
        Account: {accountName}
      </Typography>
      {transaction.manual === "manual" ? (
        <>
          <FormLabel component="legend" className="mb-2">
            Amount
          </FormLabel>
          <DollarTextField
            className="mb-4"
            fullWidth
            name="amount"
            variant="outlined"
            value={formValues.amount}
            onChange={handleChange}
          />
        </>
      ) : (
        <Typography variant="body1" className={styles.bigText}>
          Amount: ${Math.abs(transaction.amount).toFixed(2)}
        </Typography>
      )}
      <FormLabel component="legend" className="mb-2">
        Description
      </FormLabel>
      <TextField
        className="mb-4"
        fullWidth
        name="description"
        variant="outlined"
        value={formValues.description || ""}
        onChange={handleChange}
      />
      <FormLabel component="legend" className="mb-2">
        Ignore Transaction
      </FormLabel>
      <RadioGroup
        value={formValues.ignore === "y" ? "y" : "n"}
        className="flex flex-row"
        name="ignore"
        onChange={handleChange}
      >
        <FormControlLabel value={"y"} control={<Radio />} label="Yes" />
        <FormControlLabel value={"n"} control={<Radio />} label="No" />
      </RadioGroup>
    </form>
  );
};

export default TransactionDetails;
