import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { AdapterLink } from "src/utils";

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
    width: "100%",
  },
});

const IdrSolutionBlock = () => {
  const styles = useStyles();

  return (
    <Card title="IDR Tax Planning Solution" className="h-full">
      <Typography paragraph>
        We help you calculate the amount of Federal taxes you may be expected to
        owe when your loans are forgiven and how much you should save monthly to
        cover this future tax liability.
      </Typography>
      <Typography paragraph>
        If you are pursuing PSLF, your forgiven amount is not expected to be
        taxed. However, we still recommend saving for a potential future tax in
        case of unexpected changes with the PSLF program.
      </Typography>
      <Box className={styles.buttonContainer}>
        <Button component={AdapterLink} to="/wealth#debt" fbColor="primary">
          Calculate Expected Future Tax Owed
        </Button>
      </Box>
    </Card>
  );
};

export default IdrSolutionBlock;
