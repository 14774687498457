import {
  Box,
  TextField,
  Theme,
  Typography,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import NumberFormat from "react-number-format";
import FbButton from "src/components/Button";
import Button from "src/components/Button";
import TitleCard from "src/components/TitleCard";
import { PercentTextField } from "src/utils";
import { usePointsCalculator } from "../UsePointsCalculator";

const PointsInterestRateDetailsScreen = () => {
  const classes = useStyles();
  const {
    calculatorValues,
    isCalculationReady,
    handleNext,
    handleBack,
    handleInputChange,
  } = usePointsCalculator();
  const {
    noPointsInterestRate,
    offerOneInterestRate,
    offerTwoInterestRate,
    offerOnePoint,
    offerTwoPoint,
  } = calculatorValues;
  return (
    <Box className={classes.root}>
      <TitleCard title="Step 2: Point & Interest Rate Details">
        <Box className={classes.container}>
          <Typography>
            Enter the interest rate and points for each mortgage offer.
          </Typography>
          <Typography>
            Enter points paid as total basis points. For example, 1.00% is 100
            basis points or 0.75% is 75 basis points so enter 100 or 75,
            respectively.
          </Typography>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.headRow}>
                <TableCell className={classes.headCell} />
                <TableCell className={classes.headCell}>
                  “No Point” Offer
                </TableCell>
                <TableCell className={classes.headCell}>
                  Point Offer 1
                </TableCell>
                <TableCell className={classes.headCell}>
                  Point Offer 2
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell className={classes.titleCell}>
                  <Typography>Interest Rate</Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <PercentTextField
                    variant="outlined"
                    placeholder="4.000%"
                    decimalScale={3}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, true)
                    }
                    value={
                      noPointsInterestRate === 0
                        ? ""
                        : noPointsInterestRate.toFixed(3)
                    }
                    name="noPointsInterestRate"
                  />
                </TableCell>
                <TableCell className={classes.cell}>
                  <PercentTextField
                    variant="outlined"
                    placeholder="3.750%"
                    decimalScale={3}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, true)
                    }
                    value={
                      offerOneInterestRate === 0
                        ? ""
                        : offerOneInterestRate.toFixed(3)
                    }
                    name="offerOneInterestRate"
                  />
                </TableCell>
                <TableCell className={classes.cell}>
                  <PercentTextField
                    variant="outlined"
                    placeholder="3.625%"
                    decimalScale={3}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, true)
                    }
                    value={
                      offerTwoInterestRate === 0
                        ? ""
                        : offerTwoInterestRate.toFixed(3)
                    }
                    name="offerTwoInterestRate"
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.titleCell}>
                  <Typography>Points Paid</Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography>0</Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <NumberFormat
                    variant="outlined"
                    placeholder="100"
                    onChange={handleInputChange}
                    value={offerOnePoint === 0 ? "" : offerOnePoint}
                    name="offerOnePoint"
                    customInput={TextField}
                    isAllowed={({ value }: any) => !value || value <= 1000}
                  />
                </TableCell>
                <TableCell className={classes.cell}>
                  <NumberFormat
                    variant="outlined"
                    placeholder="150"
                    onChange={handleInputChange}
                    value={offerTwoPoint === 0 ? "" : offerTwoPoint}
                    name="offerTwoPoint"
                    isAllowed={({ value }: any) => !value || value <= 1000}
                    customInput={TextField}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </TitleCard>
      <Box className={classes.actionContainer}>
        <FbButton onClick={() => handleBack()}>Back</FbButton>
        {isCalculationReady ? (
          <Button fbColor="primary" onClick={() => handleNext()}>
            Next
          </Button>
        ) : (
          <Tooltip title="All fields must be filled out to continue">
            <span>
              <Button disabled fbColor="primary">
                Next
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  headRow: {
    borderBottom: "1px solid #000",
  },
  headCell: {
    fontWeight: 600,
    border: 0,
    textAlign: "center",
  },
  row: {
    border: 0,
  },
  titleCell: {
    border: 0,
    textAlign: "left",
  },
  cell: {
    border: 0,
    textAlign: "center",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    gap: 20,
  },
}));

export default PointsInterestRateDetailsScreen;
