import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";

import { SolutionCard } from "../Components/Card";
import { cardsData } from "../data";
import Button from "src/components/Button";
import {
  openMoneySchoolDialog,
  openUpgradeDialog,
} from "src/store/system/actions";
import { getIsSubscribed } from "src/store/system/selector";
import { AdapterLink } from "src/utils";
import { getRouterState } from "src/store/router/selector";

export const Cards = ({ setSolution, enableMoneySchool }: any) => {
  const dispatch = useDispatch();
  const subscribed = useSelector(getIsSubscribed);
  const router = useSelector(getRouterState);
  const upgradeRoute = router.location.pathname === "/upgrade";

  useEffect(() => {
    if (upgradeRoute) {
      dispatch(openUpgradeDialog());
    }
  }, []);

  const openDialog = (moneySchool: boolean) => {
    if (moneySchool) {
      dispatch(openMoneySchoolDialog());
    } else {
      dispatch(openUpgradeDialog());
    }
  };

  return (
    <Grid container spacing={2}>
      {cardsData &&
        cardsData.map(
          ({ heading, description, button, icons, svgIcons }: any) => {
            const { to, href, text, upgrade, value } = button;
            if (upgrade && subscribed && value !== "moneyschool") {
              return null;
            }
            let label = text;
            if (value === "moneyschool" && enableMoneySchool) {
              label = "Learn";
            }
            const renderButtonProps: any = {
              fbColor: upgrade && !subscribed ? "primary" : "secondary",
            };
            if (to) {
              renderButtonProps.component = AdapterLink;
              renderButtonProps.to = to;
            } else if (
              upgrade &&
              !subscribed &&
              (value !== "moneyschool" || !enableMoneySchool)
            ) {
              renderButtonProps.onClick = () =>
                openDialog(value === "moneyschool");
            } else if (value) {
              renderButtonProps.onClick = () => setSolution(value);
            } else if (href) {
              renderButtonProps.onClick = () => window.open(href, "_blank");
            }
            return (
              <Grid
                key={heading}
                item
                xs={12}
                md={6}
                className="flex justify-start items-start"
              >
                <SolutionCard
                  heading={heading}
                  description={description}
                  icons={icons}
                  svgIcons={svgIcons}
                  Button={[<Button {...renderButtonProps}>{label}</Button>]}
                />
              </Grid>
            );
          }
        )}
    </Grid>
  );
};
