import React from "react";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import welcomeImg from "../../../../assets/svgs/welcome_landing.svg";
import { useFlyout } from "src/components/Global/FlyoutContext";
import { StyledEuiLink } from "src/components/Global/StyledComponents";
import Welcome from "../shared/Welcome";

const PersonalInfoWelcome = () => {
  const { toggleFlyout } = useFlyout();
  return (
    <Welcome
      nextStep={PROFILE_BUILD_STEPS.PERSONAL_INFO}
      title="Welcome to FitBUX"
      headerContent={
        <>
          To get started, let's build your FitBUX profile. This will value your{" "}
          <StyledEuiLink onClick={() => toggleFlyout("human-capital")}>
            human capital
          </StyledEuiLink>
          , calculate your{" "}
          <StyledEuiLink onClick={() => toggleFlyout("fitbux-score")}>
            FitBUX score
          </StyledEuiLink>
          , and get you set up to create your financial plan.
        </>
      }
      boldStep={0}
      welcomeImg={welcomeImg}
    />
  );
};

export default PersonalInfoWelcome;
