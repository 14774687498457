import {
  Box,
  MenuItem,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import TitleCard from "src/components/TitleCard";
import { DollarTextField } from "src/utils";
import { MORTGAGE_TERMS } from "../CONSTANTS";
import { usePointsCalculator } from "../UsePointsCalculator";
import Button from "src/components/Button";
import FbButton from "src/components/Button";

const MortgageDetailsScreen = () => {
  const classes = useStyles();
  const {
    handleNext,
    handleBack,
    calculatorValues,
    handleInputChange,
  } = usePointsCalculator();
  const { mortgageTerm, mortgageAmount } = calculatorValues;
  return (
    <Box className={classes.root}>
      <TitleCard title="Step 1: Mortgage Details">
        <Box className={classes.container}>
          <Box className={classes.row}>
            <Box className={classes.cell}>
              <Typography>Mortgage Term:</Typography>
            </Box>
            <Box className={classes.cell}>
              <TextField
                name="mortgageTerm"
                variant="outlined"
                select
                value={mortgageTerm}
                fullWidth
                onChange={handleInputChange}
              >
                {MORTGAGE_TERMS.map((term) => (
                  <MenuItem key={term.value} value={term.value}>
                    {term.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
          <Box className={classes.row}>
            <Box className={classes.cell}>
              <Typography>Mortgage Amount:</Typography>
            </Box>
            <Box className={classes.cell}>
              <DollarTextField
                name="mortgageAmount"
                variant="outlined"
                placeholder="$300,000"
                value={mortgageAmount === 0 ? "" : mortgageAmount}
                decimalScope="none"
                onChange={handleInputChange}
                fullWidth
              />
            </Box>
          </Box>
        </Box>
      </TitleCard>
      <Box className={classes.actionContainer}>
        <FbButton onClick={() => handleBack()}>Back</FbButton>
        {mortgageAmount > 0 ? (
          <Button fbColor="primary" onClick={() => handleNext()}>
            Next
          </Button>
        ) : (
          <Tooltip title="All fields must be filled out to continue">
            <span>
              <Button disabled fbColor="primary">
                Next
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: theme.spacing(0, 2),
    paddingBottom: theme.spacing(2),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  cell: {
    width: 150,
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    gap: 20,
  },
}));

export default MortgageDetailsScreen;
