import * as React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router";
import { Switch, useRouteMatch } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import DashboardLayout from "src/layouts/DashboardLayout";
import InvestmentsLayout from "src/layouts/InvestmentsLayout";
import { getRouterState } from "src/store/router/selector";

import { Content } from "./Content";
import InvestmentRecommendations from "./InvestmentRecommendations";
import PayoffAnalysis from "./PayoffAnalysis";
import PayoffVsIDR from "./PayoffVsIDR";
import Refinance from "./Refinance";

const useStyles = makeStyles({
  mainContent: {
    flex: 1,
    height: "100%",
    padding: "10px 30px",
    backgroundColor: "transparent",
    position: "relative",
  },
  toolContainer: {
    marginTop: 40,
    padding: "0 20px",
  },
});

const Tools = () => {
  const { path } = useRouteMatch();
  const classes = useStyles();
  const router = useSelector(getRouterState);

  return (
    <Switch>
      <Route exact path={path}>
        <DashboardLayout pageTitle="Tools">
          <main className={classes.mainContent}>
            <Content />
          </main>
        </DashboardLayout>
      </Route>
      <Route path={`${path}/payoffAnalysis`}>
        <PayoffAnalysis />
      </Route>
      <Route path={`${path}/payoffVsIDR`}>
        <PayoffVsIDR />
      </Route>
      <Route path={`${path}/refinance`}>
        <Refinance />
      </Route>
      <Route path={`${path}/investment`}>
        <InvestmentsLayout
          headerText="Investment Recommendations"
          goBackTarget={
            router.location.query?.return === "summary"
              ? "/plan-summary"
              : undefined
          }
        >
          <main className={classes.toolContainer}>
            <InvestmentRecommendations />
          </main>
        </InvestmentsLayout>
      </Route>
    </Switch>
  );
};

export default Tools;
