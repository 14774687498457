import { SidebarContent } from "src/interfaces";

export const riskBlowout: SidebarContent[] = [
  {
    header: "Big Impact But Overlooked!",
    body: [
      "Manage your risk and your return will be there is an idea we stress at FitBUX. Your financial plan has a number of risks.",
      "One major risk are items that can have a major negative impact on your life.  Events such as medical emergencies, death, disability, car accidents, etc... can ruin your financial plan.",
      "That is why you need certain types of insurance, i.e. to protect your financial plan. Insurance can reduce the overall risk to your plan.",
      "You must decide two things:",
      "1) How big is the risk of the event you are insuring, i.e. what is the risk of you becoming disabled?",
      "2) How much are you willing to pay to protect that risk?",
    ],
  },
  {
    header: "Recommendation",
    body: [
      "FitBUX highly recommends getting long-term disability insurance if you don't already have it.  Your long-term disability insurance should cover at least 50% - 70% of your income.",
      "You've worked hard to obtain your income and you have the opportunity to earn a lot over your lifespan.  One accident can make all your future income disappear.  That is why you need disability insurance. ",
    ],
  },
];
