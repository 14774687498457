import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { forOwn } from "lodash";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";

import Card from "src/components/Card";
import Accordion from "src/components/Accordion";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import FbRadio from "src/components/Radio";

import { RISK_MANAGEMENT_TYPES } from "src/interfaces";
import {
  addCashflow,
  editCashflow,
  removeCashflow,
} from "src/store/cashflow/actions";
import { getRiskSummary } from "src/store/cashflow/selector";
import { updateHousehold } from "src/store/profileBuild/actions";
import { getIsMarried } from "src/store/system/selector";
import { DollarTextField, formatWholeDollars } from "src/utils";

const Summary = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMarried = useSelector(getIsMarried);
  const summaryData: any = useSelector(getRiskSummary);
  const [editing, setEditing] = useState(false);
  const [changes, setChanges] = useState<any>(null);

  let lifeInsuranceRows: any = undefined;
  if (summaryData.my_death_benefit) {
    if (!lifeInsuranceRows) {
      lifeInsuranceRows = [];
    }
    lifeInsuranceRows.push({
      text: "My Death Benefit",
      total: formatWholeDollars(summaryData.my_death_benefit),
    });
  }
  if (summaryData.spouse_death_benefit) {
    if (!lifeInsuranceRows) {
      lifeInsuranceRows = [];
    }
    lifeInsuranceRows.push({
      text: "Spouse Death Benefit",
      total: formatWholeDollars(summaryData.spouse_death_benefit),
    });
  }

  const insuranceRows = [];
  if (lifeInsuranceRows) {
    insuranceRows.push({
      title: "Life insurance",
      titleDetail: "Yes",
      contentRows: lifeInsuranceRows,
    });
  }

  forOwn(RISK_MANAGEMENT_TYPES, (label: string, key: string) => {
    if (key === "life_insurance" && lifeInsuranceRows) {
      return true;
    }
    insuranceRows.push({
      title: label,
      titleDetail: summaryData[key] === "y" ? "Yes" : "No",
    });
  });

  const formState = { ...summaryData, ...(changes || {}) };

  const toggleEdit = () => {
    if (editing && changes) {
      const actions = [];
      if (changes.qual_will || changes.qual_trust) {
        const householdChanges = {
          qual_trust: formState.qual_trust,
          qual_will: formState.qual_will,
        };
        actions.push(updateHousehold({ update: householdChanges }));
      }
      const changedMyLifeInsurance =
        "life_insurance" in changes &&
        changes.life_insurance !== summaryData.life_insurance;
      const changedSpouseLifeInsurance =
        "spouse_life_insure" in changes &&
        changes.spouse_life_insure !== summaryData.spouse_life_insure;
      const changedMyBenefit =
        "my_death_benefit" in changes &&
        changes.my_death_benefit !== summaryData.my_death_benefit;
      const changedSpouseBenefit =
        "spouse_death_benefit" in changes &&
        changes.spouse_death_benefit !== summaryData.spouse_death_benefit;
      if (changedMyLifeInsurance) {
        if (changes.life_insurance === "n") {
          actions.push(removeCashflow(summaryData.my_life_insurance_id));
        } else {
          actions.push(
            addCashflow({
              cashflow: {
                amount: 0,
                death_benefit: formState.my_death_benefit || 0,
                type: "life_insurance",
                who: "applicant",
              },
            })
          );
        }
      } else if (changedMyBenefit) {
        actions.push(
          editCashflow({
            id: summaryData.my_life_insurance_id,
            amount: summaryData.my_life_insurance_premium,
            death_benefit: formState.my_death_benefit,
          })
        );
      }
      if (changedSpouseLifeInsurance) {
        if (changes.spouse_life_insure === "n") {
          actions.push(removeCashflow(summaryData.spouse_life_insurance_id));
        } else {
          actions.push(
            addCashflow({
              cashflow: {
                amount: 0,
                death_benefit: formState.spouse_death_benefit || 0,
                type: "life_insurance",
                who: "spouse",
              },
            })
          );
        }
      } else if (changedSpouseBenefit) {
        actions.push(
          editCashflow({
            id: summaryData.spouse_life_insurance_id,
            amount: summaryData.spouse_life_insurance_premium,
            death_benefit: formState.spouse_death_benefit,
          })
        );
      }
      actions.forEach(dispatch);
      setChanges(null);
    }
    setEditing(!editing);
  };

  const setFormValue = (e: any) => {
    const target = e.target;
    setChanges({ ...changes, [target.name]: target.value });
  };

  return (
    <Card title="Summary" className="mt-14">
      {!editing && (
        <div className="mt-2 h-80 overflow-y-auto">
          <Accordion
            sections={[
              ...insuranceRows,
              {
                title: "Will",
                titleDetail: summaryData.qual_will === "y" ? "Yes" : "No",
              },
              {
                title: "Trust",
                titleDetail: summaryData.qual_trust === "y" ? "Yes" : "No",
              },
            ]}
          />
        </div>
      )}
      {editing && (
        <form>
          <Box className="px-2 mb-2">
            <FormControl className="flex flex-row justify-between items-center">
              <FormLabel component="legend">
                Do you have life insurance?
              </FormLabel>
              <RadioGroup
                aria-label="life_insurance"
                name="life_insurance"
                value={formState["life_insurance"]}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel value="n" control={<FbRadio />} label="No" />
                <FormControlLabel value="y" control={<FbRadio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Box>
          {formState.life_insurance === "y" && (
            <Box className="px-2 mb-2">
              <FormControl className="flex flex-row justify-between items-center">
                <FormLabel component="legend">
                  What is the death benefit amount?
                </FormLabel>
                <DollarTextField
                  aria-label="my_death_benefit"
                  className="w-32"
                  name="my_death_benefit"
                  value={formState.my_death_benefit}
                  variant="outlined"
                  onChange={setFormValue}
                />
              </FormControl>
            </Box>
          )}
          {isMarried && (
            <>
              <Box className="px-2 mb-2">
                <FormControl className="flex flex-row justify-between items-center">
                  <FormLabel component="legend">
                    Does your spouse have life insurance?
                  </FormLabel>
                  <RadioGroup
                    aria-label="spouse_life_insure"
                    name="spouse_life_insure"
                    value={formState.spouse_life_insure}
                    className="flex flex-row"
                    onChange={setFormValue}
                  >
                    <FormControlLabel
                      value="n"
                      control={<FbRadio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="y"
                      control={<FbRadio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {formState.spouse_life_insure === "y" && (
                <Box className="px-2 mb-2">
                  <FormControl className="flex flex-row justify-between items-center">
                    <FormLabel component="legend">
                      What is your spouse's death benefit amount?
                    </FormLabel>
                    <DollarTextField
                      aria-label="spouse_death_benefit"
                      className="w-32"
                      name="spouse_death_benefit"
                      value={formState.spouse_death_benefit}
                      variant="outlined"
                      onChange={setFormValue}
                    />
                  </FormControl>
                </Box>
              )}
            </>
          )}
          <Box className="px-2 mb-2">
            <FormControl className="flex flex-row justify-between items-center">
              <FormLabel component="legend">Do you have a trust?</FormLabel>
              <RadioGroup
                aria-label="qual_trust"
                name="qual_trust"
                value={formState["qual_trust"]}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel value="n" control={<FbRadio />} label="No" />
                <FormControlLabel value="y" control={<FbRadio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box className="px-2 mb-2">
            <FormControl className="flex flex-row justify-between items-center">
              <FormLabel component="legend">Do you have a will?</FormLabel>
              <RadioGroup
                aria-label="qual_will"
                name="qual_will"
                value={formState["qual_will"]}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel value="n" control={<FbRadio />} label="No" />
                <FormControlLabel value="y" control={<FbRadio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Box>
        </form>
      )}
      <Button
        variant="outlined"
        fbColor="primary"
        className={`w-full mt-5 ${classes.button}`}
        onClick={toggleEdit}
        endIcon={<Icon iconName="fa-pencil" />}
      >
        {editing ? "Done" : "Edit"}
      </Button>
    </Card>
  );
};
const useStyles = makeStyles({
  button: {
    width: "100%",
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
});

export default Summary;
