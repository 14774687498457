import React from "react";
import { useSelector } from "react-redux";

import { Box, makeStyles, Tooltip, Typography } from "@material-ui/core";

import TitleCard from "src/components/TitleCard";
import { getIsCurrentStudent } from "src/store/system/selector";
import Button from "src/components/Button";

interface LandingStepProps {
  onNext(): void;
}

const LandingStep = ({ onNext }: LandingStepProps) => {
  const classes = useStyles();
  const isCurrentStudent = useSelector(getIsCurrentStudent);

  let button = (
    <Button
      onClick={() => onNext()}
      color="primary"
      disabled={isCurrentStudent}
    >
      Next
    </Button>
  );

  if (isCurrentStudent) {
    button = (
      <Tooltip title="This tool is only available to those that have graduated. If you have graduated, please go to the human capital section of your profile and update your graduation date.">
        <span>{button}</span>
      </Tooltip>
    );
  }

  // export interface SidebarContent {
  //   header: string;
  //   body: (string | React.ReactNode)[];
  //   videoKey?: string;
  //   lists?: Array<{
  //     title: string;
  //     list: Array<string>;
  //   }>;
  // }

  return (
    <>
      <TitleCard
        title="What This Recommendation Provides"
        sideContent={[
          {
            header: "",
            body: [],
            videoKey: "lifeInsurance",
          },
        ]}
      >
        <Box className={classes.container}>
          <Typography paragraph>
            FitBUX’s life insurance AI is here to help you make sure that your
            loved ones are covered in the event that something unexpected
            happens to you.
          </Typography>
          <Typography paragraph>
            It provides tailored advice on how much life insurance you should
            purchase to protect your loved ones.
          </Typography>
        </Box>
      </TitleCard>
      <Box className={classes.buttonContainer}>{button}</Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

export default LandingStep;
