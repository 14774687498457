import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  // makeStyles,
} from "@material-ui/core";

// import { profileBuilderStyles } from "src/theme";
import { formatAnnually, formatMonthly, formatPercent } from "src/utils";

import {
  addCashflow,
  AddCashflowItemPayload,
  editCashflow,
  removeCashflow,
  replaceCashflow,
} from "src/store/cashflow/actions";
import { getDashboardCashflows } from "src/store/cashflow/selector";
import {
  addEarning,
  addExpense,
  editEarning,
  editExpense,
  estimateCurrentPlanTaxes,
  removeEarning,
  removeExpense,
  savePlanAndCashflows,
  setBuildStep,
  setPlanDirty,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  currentPlanExpenseTotal,
  currentPlanIncomeTotal,
  getCurrentPlanCashflows,
  getPlanIsDirty,
  getMonthlyTax,
  isCurrentPlanImplemented,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import {
  EXPENSE_TYPES,
  INCOME_TYPES,
  PLAN_INCOME_TYPES,
  PLAN_EXPENSE_TYPES,
  IncomeExpenses,
  MappedCashflow,
  Earning,
  Expense,
  PlanViewComponent,
} from "src/interfaces";
import BasicCard from "src/components/BasicCard";
import { expenseBlowout, incomeBlowout } from "./blowoutContent";
import CenterContainer from "../../Components/CenterContainer";

// const useStyles = makeStyles(profileBuilderStyles);

const MainForm: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const isMarried = useSelector(getIsMarried);
  const { ...planCashflows }: IncomeExpenses = useSelector(
    getCurrentPlanCashflows
  );
  const dirty = useSelector(getPlanIsDirty);
  const planIncomeTotal = useSelector(currentPlanIncomeTotal);
  const planExpenseTotal = useSelector(currentPlanExpenseTotal);
  const profileCashflows = useSelector(getDashboardCashflows);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const estimatedTaxes = useSelector(getMonthlyTax);
  const [newExpense, setNewExpense] = useState<any>(null);
  const [newIncome, setNewIncome] = useState<any>(null);

  const { income, expenses } = useMemo(() => {
    if (currentPlanIsImplemented) {
      return {
        income: profileCashflows.income.map((item) => ({
          ...item,
          whoLabel: item.whose === "spouse" ? "Spouse" : "Mine",
        })),
        expenses: profileCashflows.expenses,
      };
    }
    return planCashflows;
  }, [currentPlanIsImplemented, profileCashflows, planCashflows]);

  const incomeTotal = useMemo(() => {
    if (!isCurrentPlanImplemented) {
      return planIncomeTotal;
    }
    return income.reduce((result, item) => result + (item.annual || 0), 0);
  }, [income, planIncomeTotal]);

  const expenseTotal = useMemo(() => {
    if (!isCurrentPlanImplemented) {
      return planExpenseTotal;
    }
    return expenses.reduce((result, item) => result + (item.annual || 0), 0);
  }, [expenses, planExpenseTotal]);

  const displayTax = useMemo(() => {
    const taxPercentOfIncome = ((estimatedTaxes * 12) / incomeTotal) * 100;
    return {
      id: 0,
      monthly: estimatedTaxes,
      percent: taxPercentOfIncome,
      annual: estimatedTaxes * 12,
      type: "tax",
      typeLabel: "Taxes",
    };
  }, [estimatedTaxes, incomeTotal]);

  const displayExpenses = useMemo(() => {
    if (!currentPlanIsImplemented) {
      return expenses;
    }
    return expenses.map((item: any) => ({
      ...item,
      percent: (item.annual / incomeTotal) * 100,
      who: item.whose === "spouse" ? "spouse" : "applicant",
    }));
  }, [currentPlanIsImplemented, income, expenses]);

  const saveItem = (item: MappedCashflow, updateFields?: MappedCashflow) => {
    if (!item) {
      setNewIncome(null);
      setNewExpense(null);
      return;
    }
    const update = { ...item, ...updateFields };
    if (item.id >= 0) {
      if (
        (update.type && item.type !== update.type) ||
        (update.whose && item.whose !== update.whose)
      ) {
        const replacePayload: any = {
          id: item.id,
          newItem: {
            type: update.type,
            amount: Math.abs(update.annual || 0),
          },
        };
        if (update.whose) {
          replacePayload.newItem.who = update.whose;
        }
        return dispatch(replaceCashflow(replacePayload));
      }
      const payload = {
        id: item.id,
        amount: Math.abs(update.annual || 0),
      };
      return dispatch(editCashflow(payload));
    } else {
      const cashflow: any = {
        type: update.type,
        amount: Math.abs(update.annual || 0),
      };
      if (update.who === "spouse") {
        cashflow.who = "spouse";
      } else {
        cashflow.who = "applicant";
      }
      const payload: AddCashflowItemPayload = {
        cashflow,
        temporaryId: item.id,
      };
      // refreshScore();
      dispatch(addCashflow(payload));
    }
    setNewIncome(null);
    setNewExpense(null);
  };

  const saveItemPlan = (
    item: MappedCashflow,
    updateFields: Partial<MappedCashflow>,
    index: number,
    isExpense: boolean
  ) => {
    const listToUpdate = isExpense ? [displayTax, ...expenses] : income;
    const isNew = index === listToUpdate.length;
    let action;
    let update: Expense | Earning;
    if (isExpense) {
      update = {
        payment: Math.abs(updateFields.annual || item.annual || 0),
        type: updateFields.type || item.type,
      };
      if (isNew) {
        setNewExpense(null);
        if (!update.type) {
          return;
        }
        action = addExpense(update);
      } else {
        action = editExpense({ index: index - 1, update });
      }
    } else {
      update = {
        earning: Math.abs(updateFields.annual || item.annual || 0),
        type: updateFields.type || item.type,
        who: updateFields.who || item.who || "applicant",
      };
      if (isNew) {
        setNewIncome(null);
        if (!update.type) {
          return;
        }
        action = addEarning(update);
      } else {
        action = editEarning({ index, update });
      }
    }
    dispatch(action);
    if (!isExpense) {
      dispatch(estimateCurrentPlanTaxes());
    }
    dispatch(savePlanAndCashflows(null));
  };

  const saveEarning = (
    item: MappedCashflow,
    updateFields: Partial<MappedCashflow>,
    index: number
  ) => saveItemPlan(item, updateFields, index, false);

  const saveExpense = (
    item: MappedCashflow,
    updateFields: Partial<MappedCashflow>,
    index: number
  ) => saveItemPlan(item, updateFields, index, true);

  const onNext = () => {
    if (dirty) {
      dispatch(savePlanAndCashflows(PLAN_BUILD_STEPS.ASSETS_AND_DEBTS));
    } else {
      dispatch(setBuildStep(PLAN_BUILD_STEPS.ASSETS_AND_DEBTS));
    }
  };

  const defaultNewExpense = currentPlanIsImplemented
    ? {
        id: -999,
        annual: 0,
        monthly: 0,
        type: "",
      }
    : {
        payment: "",
        type: "",
      };
  const defaultNewIncome = currentPlanIsImplemented
    ? {
        id: -999,
        annual: 0,
        monthly: 0,
        type: "",
        who: "applicant",
      }
    : {
        earning: "",
        type: "",
        who: "applicant",
      };
  const addNewExpense = () => setNewExpense(defaultNewExpense);
  const addNewIncome = () => setNewIncome(defaultNewIncome);

  const removePlanItem = (
    item: Earning | Expense,
    index: number,
    isExpense: boolean
  ) => {
    if (isExpense && index === expenses.length + 1) {
      setNewExpense(null);
    } else if (!isExpense && index === income.length) {
      setNewIncome(null);
    } else {
      dispatch(isExpense ? removeExpense(index - 1) : removeEarning(index));
      if (!isExpense) {
        dispatch(estimateCurrentPlanTaxes());
      }
      dispatch(savePlanAndCashflows(null));
    }
  };

  const removeItem = (item: any, index: number, isExpense: boolean) => {
    if (!currentPlanIsImplemented) {
      return removePlanItem(item, index, isExpense);
    }
    if (item.id < 0) {
      setNewIncome(null);
      setNewExpense(null);
    } else {
      dispatch(removeCashflow(item.id));
      // refreshScore();
    }
  };

  const removeEarningItem = (item: Earning | Expense, index: number) =>
    removeItem(item, index, false);

  const removeExpenseItem = (item: Earning | Expense, index: number) =>
    removeItem(item, index, true);

  const expenseColumns = [
    {
      label: "Type",
      field: "type",
      type: "select",
      width: "40%",
      items: currentPlanIsImplemented ? EXPENSE_TYPES : PLAN_EXPENSE_TYPES,
    },
    {
      label: "% of Total Income",
      field: "percent",
      type: "percent",
      width: "20%",
      formatter: formatPercent,
    },
    {
      label: "Monthly",
      field: "monthly",
      type: "number",
      width: "20%",
      formatter: formatMonthly,
    },
    {
      label: "Annual",
      field: "annual",
      type: "number",
      width: "20%",
      formatter: formatAnnually,
    },
  ];

  const incomeColumns = [
    {
      label: "Type",
      field: "type",
      type: "select",
      width: "60%",
      items: currentPlanIsImplemented ? INCOME_TYPES : PLAN_INCOME_TYPES,
    },
    {
      label: "Monthly",
      field: "monthly",
      type: "number",
      width: "20%",
      formatter: formatMonthly,
    },
    {
      label: "Annual",
      field: "annual",
      type: "number",
      width: "20%",
      formatter: formatAnnually,
    },
  ];
  if (isMarried) {
    incomeColumns.push({
      label: "Owner",
      field: "who",
      type: "select",
      width: "30%",
      items: {
        applicant: "Mine",
        spouse: "Spouse",
      },
    });
    incomeColumns[0].width = "30%";
  }
  const showIncome: any[] = income.map((item) => ({
    ...item,
    who: item.whose === "spouse" ? "spouse" : "applicant",
    typeLabel: INCOME_TYPES[item.type],
  }));
  if (newIncome) {
    showIncome.push(newIncome);
  }
  const showExpense: any[] = [displayTax, ...displayExpenses];
  if (newExpense) {
    showExpense.push(newExpense);
  }

  const displayExpenseTotal = expenseTotal + (displayTax?.annual || 0);

  return render({
    component: (
      <CenterContainer title="Day-To-Day Money" scrollable iconName="fb-wallet">
        <Box className="mt-5">
          <BasicCard
            help={incomeBlowout}
            iconName="fb-money"
            title="Income"
            // secondaryStyle
            additionalText="Enter your income."
            allowSelect={!currentPlanIsImplemented || !!newIncome}
            columns={incomeColumns}
            data={showIncome}
            defaultNewItem={defaultNewIncome}
            onAdd={addNewIncome}
            onCancelEdit={() => setNewIncome(null)}
            onDelete={removeEarningItem}
            onSave={currentPlanIsImplemented ? saveItem : saveEarning}
            total={incomeTotal}
            summaryRow={
              !showIncome.length
                ? undefined
                : {
                    type: "Total",
                    annual: incomeTotal,
                    monthly: Math.round(incomeTotal / 12),
                  }
            }
            setDirtyFlag={() => dispatch(setPlanDirty(true))}
          />
          <BasicCard
            help={expenseBlowout}
            iconName="fb-wallet"
            title="Day-To-Day Expenses"
            additionalText="Enter your day-to-day expenses."
            columns={expenseColumns}
            data={showExpense}
            allowSelect={!currentPlanIsImplemented || !!newIncome}
            defaultNewItem={defaultNewExpense}
            fixedRows={[0]}
            // secondaryStyle
            onAdd={addNewExpense}
            onCancelEdit={() => setNewExpense(null)}
            onDelete={removeExpenseItem}
            onSave={currentPlanIsImplemented ? saveItem : saveExpense}
            total={incomeTotal}
            summaryRow={
              !showIncome.length
                ? undefined
                : {
                    type: "Total",
                    annual: displayExpenseTotal,
                    monthly: Math.round(displayExpenseTotal / 12),
                    percent: (displayExpenseTotal / incomeTotal) * 100,
                  }
            }
            setDirtyFlag={() => dispatch(setPlanDirty(true))}
          />
        </Box>
      </CenterContainer>
    ),
    onNext,
    nextLabel: "Next Section",
  });
};

export default MainForm;
