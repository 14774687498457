import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  // makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import TitleCard from "src/components/TitleCard";
import BigButton from "src/components/BigButton";
import { GeneralPageCardDefinition } from "src/interfaces";
import { setVideo } from "src/store/dashboard/actions";
import { openUpgradeDialog } from "src/store/system/actions";
import { getIsSubscribed } from "src/store/system/selector";
import { AdapterLink } from "src/utils";

// const useStyles = makeStyles({});

const GeneralCard = ({
  title,
  heading,
  subscribedHeading,
  items,
  showSubscribeButton,
  setDialogData,
}: GeneralPageCardDefinition & {
  setDialogData: (data: any) => void;
  title: string;
}) => {
  // const styles = useStyles();
  const dispatch = useDispatch();
  const subscribed = useSelector(getIsSubscribed);

  let displayHeading = heading;
  if (!items.length) {
    displayHeading =
      "Watch this space as we are working on a lot of great things!";
  } else if (subscribed && subscribedHeading) {
    displayHeading = subscribedHeading;
  }

  const extraRowHeight = 120 + 2 * 8;

  const nExtraRows = Math.max(0, Math.ceil((items.length - 4) / 2));
  const height = 430 + extraRowHeight * nExtraRows;
  const gridHeight = 272 + extraRowHeight * nExtraRows;

  return (
    <TitleCard title={title} style={{ width: "auto", height }}>
      <Typography className="h-12">{displayHeading}</Typography>
      <Grid
        container
        spacing={2}
        className="my-4"
        style={{ height: gridHeight }}
      >
        {items.map((item) => {
          let onClick;
          if (item.dialog) {
            onClick = () => setDialogData(item.dialog);
          } else if (!item.target) {
            onClick = () => dispatch(setVideo(item.video || ""));
          }
          const button = (
            <BigButton
              iconImage={item.iconImage}
              iconName={item.iconName}
              variant="outlined"
              fbColor="primary"
              component={
                !item.target || item.disabled ? undefined : AdapterLink
              }
              // disabled={item.disabled}
              to={
                !item.target || item.disabled
                  ? undefined
                  : subscribed && item.subscribedTarget
                  ? item.subscribedTarget
                  : item.target
              }
              onClick={onClick}
            >
              {item.label}
            </BigButton>
          );
          let element = button;
          if (item.tooltip) {
            element = (
              <Tooltip title={item.tooltip}>
                <span>{button}</span>
              </Tooltip>
            );
          }
          return (
            <Grid item xs={6} key={item.label}>
              {element}
            </Grid>
          );
        })}
      </Grid>
      {!subscribed && showSubscribeButton && (
        <Box className="text-center mt-6">
          <Button
            fbColor="primary"
            onClick={() => dispatch(openUpgradeDialog())}
          >
            Upgrade to Premium
          </Button>
        </Box>
      )}
    </TitleCard>
  );
};

export default GeneralCard;
