import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { EuiButton } from "@elastic/eui";
import { getProfileProgress } from "src/store/profileBuild/selector";
import { logOut } from "src/store/system/actions";
import { StyledEuiLink } from "src/components/Global/StyledComponents";
import { useFlyout } from "src/components/Global/FlyoutContext";
import Welcome from "./Views/shared/Welcome";
import continue_img from "src/assets/svgs/pb_landing.svg";

const ProfileBuilderContinue = () => {
  const dispatch = useDispatch();
  const { toggleFlyout } = useFlyout();
  const progress = useSelector(getProfileProgress);
  let step = 0;
  if (progress > 2) {
    if (progress > 6) {
      step = 2;
    } else {
      step = 1;
    }
  }

  return (
    <Welcome
      title="Finish Building Your Profile"
      headerContent={
        <>
          Continue building your profile to set up your account. This will value
          your{" "}
          <StyledEuiLink
            onClick={() => {
              if (toggleFlyout) {
                toggleFlyout("human-capital");
              }
            }}
          >
            human capital
          </StyledEuiLink>
          , calculate your{" "}
          <StyledEuiLink
            onClick={() => {
              if (toggleFlyout) {
                toggleFlyout("fitbux-score");
              }
            }}
          >
            FitBUX score
          </StyledEuiLink>
          , and get you set up to create your financial plan.
        </>
      }
      boldStep={step}
      welcomeImg={continue_img}
      nextAction={() => dispatch(push("/profile-builder"))}
      nextLabel="Continue"
      secondaryButton={
        <EuiButton onClick={() => dispatch(logOut())}>Log Out</EuiButton>
      }
    />
  );
};

export default ProfileBuilderContinue;
