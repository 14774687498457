import { Account, ActionItem } from "src/interfaces";

import { openPlanForEditing } from "src/store/planBuild/util";
import {
  getUnconfirmedTransactions,
  openConfirmIncomeDialog,
} from "src/store/transaction/actions";

const openLivePlanBuilder = (
  dispatch: any,
  { isCurrentStudent, livePlan }: any
) => openPlanForEditing(dispatch, isCurrentStudent, livePlan);
const openNewAccountRelinkDialog = (
  dispatch: any,
  { openRelinkDialog }: any
) => {
  openRelinkDialog();
};
const openNewAccountUpdatesDialog = (
  dispatch: any,
  { openAccountUpdateDialog }: any
) => {
  openAccountUpdateDialog();
};
const openNewTransactionDialog = (dispatch: any) => {
  dispatch(getUnconfirmedTransactions());
  dispatch(openConfirmIncomeDialog());
};

export enum ACTION_ITEM_IDS {
  PLAN_NCF = "PLAN_NCF",
  // MONEY_LIFEINS = "MONEY_LIFEINS",
  PLAN_BUILD = "PLAN_BUILD",
  PLAN_IMPLEMENT = "PLAN_IMPLEMENT",
  ACCOUNT_RELINK = "ACCOUNT_RELINK",
  ACCOUNT_UPDATE = "ACCOUNT_UPDATE",
  TRANS_REVIEW = "TRANS_REVIEW",
  TRANS_ADD = "TRANS_ADD",
  TRANS_INCOME = "TRANS_INCOME",
  MONEY_INVEST = "MONEY_INVEST",
  MONEY_XFER_ROTHIRA = "MONEY_XFER_ROTHIRA",
  MONEY_XFER_TRADIRA = "MONEY_XFER_TRADIRA",
  PLAN_PROFILECHG = "PLAN_PROFILECHG",
  ACCOUNT_LINK = "ACCOUNT_LINK",
  ACCOUNT_LINK_ADDL = "ACCOUNT_LINK_ADDL",
  MONEY_REFERRAL = "MONEY_REFERRAL",
  MONEY_CALL = "MONEY_CALL",
}

export const LINK = "LINK";
export const CSC_ACTION_ITEMS = [
  ACTION_ITEM_IDS.PLAN_NCF,
  // ACTION_ITEM_IDS.MONEY_LIFEINS,
  // ACTION_ITEM_IDS.PLAN_BUILD,
  // ACTION_ITEM_IDS.PLAN_IMPLEMENT,
  ACTION_ITEM_IDS.ACCOUNT_RELINK,
  ACTION_ITEM_IDS.ACCOUNT_UPDATE,
  // ACTION_ITEM_IDS.TRANS_REVIEW,
  // ACTION_ITEM_IDS.TRANS_ADD,
  // ACTION_ITEM_IDS.TRANS_INCOME,
  // ACTION_ITEM_IDS.MONEY_INVEST,
  // ACTION_ITEM_IDS.MONEY_XFER_ROTHIRA,
  // ACTION_ITEM_IDS.MONEY_XFER_TRADIRA,
];

const ACTION_ITEMS: { [id: string]: ActionItem } = {
  PLAN_NCF: {
    id: ACTION_ITEM_IDS.PLAN_NCF,
    title: "Your Plan Has Negative Cash Flow",
    message:
      "We noticed changes to your profile that cause your plan to have negative cash flow. You should update your plan ASAP.",
    buttonLabel: "Update My Plan",
    action: openLivePlanBuilder,
    trigger: (data) => {
      const { livePlanSurplus, hasPlan } = data;
      return hasPlan && livePlanSurplus < 0;
    },
  },
  PLAN_PROFILECHG: {
    id: ACTION_ITEM_IDS.PLAN_PROFILECHG,
    title: "Major Change To Your Profile",
    message:
      "We noticed significant changes to your profile. Don't forget to update your financial plan accordingly, if necessary.",
    buttonLabel: "Update My Plan",
    action: openLivePlanBuilder,
    // TODO
    trigger: (data) => !data,
  },
  PLAN_BUILD: {
    id: ACTION_ITEM_IDS.PLAN_BUILD,
    title: "Build Your Plan",
    message:
      "Your first step is to build a plan. You can build multiple plans for easy comparison before making a decision.",
    buttonLabel: "Build Plan",
    buttonType: LINK,
    target: "/build-plan",
    trigger: ({ savedPlans }) => !savedPlans.length,
  },
  PLAN_IMPLEMENT: {
    id: ACTION_ITEM_IDS.PLAN_IMPLEMENT,
    title: "Implement Your Plan",
    message:
      "Congrats for building your plan! Now it's time to implement it and make sure you are following it.",
    buttonLabel: "Implement Plan",
    buttonType: LINK,
    target: "/plan-list",
    trigger: ({ hasPlan, savedPlans }) => !hasPlan && !!savedPlans.length,
  },
  ACCOUNT_LINK: {
    id: ACTION_ITEM_IDS.ACCOUNT_LINK,
    title: "Link Your Accounts",
    message:
      "Don't forget to link your accounts to ensure that your finances are up to date.",
    buttonLabel: "Link My Accounts",
    buttonType: LINK,
    // TODO: implement the automatic 'open link account modal' behavior
    target: "/accounts?action=link",
    // TODO
    trigger: (data) => !data,
  },
  ACCOUNT_RELINK: {
    id: ACTION_ITEM_IDS.ACCOUNT_RELINK,
    title: "Update Your Linked Account(s)",
    message:
      "One or more of your financial institutions has updated their technology and your account(s) need to be relinked/refreshed.",
    buttonLabel: "Relink Account(s)",
    action: openNewAccountRelinkDialog,
    trigger: ({ providers }) =>
      !!providers.find((provider: any) => provider.error),
  },
  ACCOUNT_LINK_ADDL: {
    id: ACTION_ITEM_IDS.ACCOUNT_LINK_ADDL,
    title: "Link More Accounts",
    message: "Automate your financial plan by linking more financial accounts.",
    buttonLabel: "Link My Accounts",
    buttonType: LINK,
    // TODO: implement the automatic 'open link account modal' behavior
    target: "/accounts?action=link",
    // TODO
    trigger: (data) => !data,
  },
  ACCOUNT_UPDATE: {
    id: ACTION_ITEM_IDS.ACCOUNT_UPDATE,
    title: "Update Your Accounts",
    message:
      "Some of your account balances are over 60 days old. Please review and update those balances to ensure that you're using the most up-to-date information.",
    buttonLabel: "Update Accounts",
    action: openNewAccountUpdatesDialog,
    // TODO
    trigger: (data) => !data,
  },
  TRANS_REVIEW: {
    id: ACTION_ITEM_IDS.TRANS_REVIEW,
    title: "Review Transactions",
    message:
      "Congratulations, your first transaction just came in. Review your transaction details to ensure that your plan uses the latest data.",
    buttonLabel: "Review Transactions",
    buttonType: LINK,
    target: "/transactions",
    // TODO
    trigger: (data) => !data,
  },
  TRANS_ADD: {
    id: ACTION_ITEM_IDS.TRANS_ADD,
    title: "Add Transactions",
    message:
      "Make sure to either link your accounts or manually enter transactions to track your progress.",
    buttonLabel: "Enter Transactions",
    buttonType: LINK,
    target: "/transactions",
    // TODO
    trigger: (data) => !data,
  },
  TRANS_INCOME: {
    id: ACTION_ITEM_IDS.TRANS_INCOME,
    title: "Confirm Your Income",
    message:
      "You have new income transactions to confirm. Be sure to do this to properly track your financial plan.",
    buttonLabel: "Confirm Transactions",
    action: openNewTransactionDialog,
    trigger: (data) => !data,
    // trigger: ({ unconfirmedTransactions }) =>
    //   !!unconfirmedTransactions.income.length,
  },
  MONEY_REFERRAL: {
    id: ACTION_ITEM_IDS.MONEY_REFERRAL,
    title: "Refer A Friend",
    message: "Make money by referring your friends to FitBUX.",
    buttonLabel: "Refer A Friend",
    buttonType: LINK,
    // TODO: check this link target
    target: "/referral",
    // TODO
    trigger: (data) => !data,
  },
  MONEY_CALL: {
    id: ACTION_ITEM_IDS.MONEY_CALL,
    title: "Schedule A Call",
    message: "Schedule a call with a Coach.",
    buttonLabel: "Schedule A Call",
    // TODO: check this link target
    action: (dispatch, { openCalendlyDialog }) => openCalendlyDialog(),
    // TODO
    trigger: (data) => !data,
  },
  MONEY_INVEST: {
    id: ACTION_ITEM_IDS.MONEY_INVEST,
    title: "Open an Investment Account",
    message:
      "You can now open new IRAs and taxable brokerage accounts and/or roll over existing 401(k)/403(b) accounts with FitBUX (via our partner Betterment). FitBUX can now manage these account(s) and update your investments based on your FitBUX Score over time.",
    buttonLabel: "Open Account",
    buttonType: LINK,
    target: "/betterment/create",
    trigger: ({ hasPlan, accounts, livePlan }) => {
      const missingAccount = !accounts.find(
        (account: Account) =>
          account.variable === "roth_ira_value" ||
          account.variable === "ira_value"
      );
      if (hasPlan) {
        return (
          (livePlan.allocations[0].roth_ira_value ||
            livePlan.allocations[0].ira_value) &&
          missingAccount
        );
      }
      return missingAccount;
    },
  },
  MONEY_XFER_ROTHIRA: {
    id: ACTION_ITEM_IDS.MONEY_XFER_ROTHIRA,
    title: "Transfer Your Roth IRA",
    message:
      "FitBUX can now manage your Roth IRA (in partbership with Betterment) and update your investments based on your FitBUX Score. Transfer your Roth IRA today.  As always, you can schedule a call with your Coach should you have questions.",
    buttonLabel: "Open Account",
    buttonType: LINK,
    target: "/betterment/create?return=plan-summary",
    trigger: ({ hasPlan, accounts }) =>
      hasPlan &&
      !!accounts.find(
        (account: Account) => account.variable === "roth_ira_value"
      ),
  },
  MONEY_XFER_TRADIRA: {
    id: ACTION_ITEM_IDS.MONEY_XFER_TRADIRA,
    title: "Transfer Your Traditional IRA",
    message:
      "FitBUX can now manage your IRA (in partnership with Betterment) and update your investments based on your FitBUX Score over time. Transfer your IRA today.  As always, you can schedule a call with your Coach should you have questions.",
    buttonLabel: "Open Account",
    buttonType: LINK,
    target: "/betterment/create?return=plan-summary",
    trigger: ({ hasPlan, accounts }) =>
      hasPlan &&
      !!accounts.find((account: Account) => account.variable === "ira_value"),
  },
  // MONEY_LIFEINS: {
  //   id: ACTION_ITEM_IDS.MONEY_LIFEINS,
  //   title: "Consider (Additional) Life Insurance. Check Your Premiums Now",
  //   message:
  //     "Based on your profile, we recommend that you get (additional) life insurance. Use our tools to learn more.",
  //   buttonLabel: "Learn More",
  //   buttonType: LINK,
  //   target: "/insurance",
  //   trigger: ({ cashflows, insuranceLiabilities }: any) => {
  //     const foundLifeInsurance = cashflows.filter(
  //       (cashflow: Cashflow) => cashflow.type === "life_insurance"
  //     );
  //     const target = Object.values<number>(insuranceLiabilities || {}).reduce(
  //       (result: number, value: number) => result + value,
  //       0
  //     );
  //     if (!foundLifeInsurance.length) {
  //       return true;
  //     }
  //     for (let i = 0; i < foundLifeInsurance.length; i++) {
  //       const insurance = foundLifeInsurance[i];
  //       if (!insurance.death_benefit || insurance.death_benefit < target) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   },
  // },
};

export default ACTION_ITEMS;
