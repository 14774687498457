import React from "react";

import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import Chart from "src/components/Chart";
import { formatPercent, formatWholeDollars } from "src/utils/formatter";

const useStyles = makeStyles({
  text: { fontSize: 14 },
  buttonContainer: {
    margin: "40px auto",
    display: "flex",
    justifyContent: "center",
  },
  table: {
    margin: "20px auto",
    maxWidth: 800,
  },
  cell: {
    border: 0,
  },
  columnHeader: {
    border: 0,
    fontWeight: 600,
    wordBreak: "break-word",
  },
  rowHeader: {
    border: 0,
    fontWeight: 600,
    wordBreak: "break-word",
  },
  summaryRow: {
    fontSize: 14,
    marginTop: 12,
  },
});

const Results = ({ back, results }: any) => {
  const styles = useStyles();

  return (
    <>
      <Card title="Results">
        <Chart
          dollars
          title=""
          elevation={0}
          data={results.data}
          height="322px"
          keys={["value_75", "value_50", "value_25"]}
          xInterval={4}
          xKey="month"
          xYearOnly
          xMargin={60}
          yMargin={15}
          yLabelMargin={65}
          keyLabels={{
            value_75: "High Case",
            value_50: "Base Case",
            value_25: "Low Case",
          }}
          width="100%"
        />
        <TableContainer className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.columnHeader} />
                <TableCell className={styles.columnHeader}>
                  Projected Balance in {results.years} Years to Retirement Age
                </TableCell>
                <TableCell className={styles.columnHeader}>
                  Avg. Annual Return
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={styles.rowHeader}>
                  High Case (75th Percentile)
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.amount_75)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatPercent(results.annual_return_75 * 100)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.rowHeader}>
                  Base Case (Average)
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.amount_50)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatPercent(results.annual_return_50 * 100)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.rowHeader}>
                  Low Case (25th Percentile)
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatWholeDollars(results.amount_25)}
                </TableCell>
                <TableCell className={styles.cell}>
                  {formatPercent(results.annual_return_25 * 100)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Box className={styles.buttonContainer}>
        <Button fbColor="primary" onClick={back}>
          Back
        </Button>
      </Box>
    </>
  );
};

export default Results;
