import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import { Grid } from "@material-ui/core";

import Questionnaire from "src/components/Questionnaire";
import {
  marriedQuestions,
  QuestionFormProps,
  singleQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";
import CenterContainer from "src/pages/PlanBuilder/Components/CenterContainer";
import { getIsMarried } from "src/store/system/selector";
// import CenterContainer from "../PlanBuilder/Components/CenterContainer";

const HSA = ({
  planSetter,
  planData,
  goToNext,
  nextClicked,
}: QuestionFormProps) => {
  // note: hold initial next click count
  const [nextClick] = useState(nextClicked);
  const isMarried = useSelector(getIsMarried);
  const questionCollection = isMarried ? marriedQuestions : singleQuestions;
  const questionData: QuestionForm = questionCollection.hsa;

  const startingValues = cloneDeep(questionData.emptyValue);
  for (const key in questionData.emptyValue) {
    // check the questionnaire for the key
    // if key exists then use that value
    const savedData = planData[key as keyof typeof planData];
    if (savedData) {
      startingValues[key] = savedData;
    }
  }
  const [values, setValues] = useState<Question>(startingValues);

  const handleChange = (newValues: any) => {
    updatePlanData(newValues);
    setValues(newValues);
  };

  useEffect(() => {
    // note: if next click count changed after mount then run goToNext
    if (nextClicked !== nextClick) {
      goToNext();
    }
  }, [nextClicked]);

  const updatePlanData = (changedValues: any) => {
    const newPlan = { ...planData };
    newPlan.has_hsa = changedValues.has_hsa;
    planSetter(newPlan);
  };

  return (
    <CenterContainer
      title={questionData.typeLabel}
      help={questionData.help}
      iconName="fb-event"
      scrollable
    >
      <Grid container>
        <Questionnaire
          questions={questionData}
          values={values}
          onChange={handleChange}
        />
      </Grid>
    </CenterContainer>
  );
};

export default HSA;
