import React from "react";
import { Box, Card, makeStyles, Slider, Typography } from "@material-ui/core";
import { formatWholeDollars } from "src/utils";
import { HomeResults } from "../common";
import { colors } from "src/theme";

// const marks = [
//   {
//     value: 0,
//     label: "Optimal",
//   },
//   {
//     value: 1,
//     label: "Okay",
//   },
//   {
//     value: 2,
//     label: "Risky",
//   },
// ];

const useStyles = makeStyles({
  mainContainer: {
    padding: "5px 0px",
  },
  container: {
    // border: "1px solid black",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 0px",
    // backgroundColor: "white",
    borderRadius: "15px",
  },
  innerBox: {},
  headers: {
    width: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "0px",
    textAlign: "center",
    "& p": {
      minWidth: "60px",
    },
  },
  row: {
    width: "90%",
    height: "5px",
    display: "flex",
    justifyContent: "space-between",
  },
});

const GraphSection = ({
  dataReady,
  data,
}: {
  dataReady: boolean;
  data: HomeResults;
}) => {
  const classes = useStyles();

  const renderDollars = (dollarValue: number) => (
    <Typography style={{ fontWeight: "bold", color: colors.brandingBlue1 }}>
      {formatWholeDollars(dollarValue, 1000)}
    </Typography>
  );

  return (
    <Box className={classes.mainContainer}>
      <Card className={classes.container}>
        <Box className={classes.row}>
          <div className={classes.headers}>
            <Typography>
              {dataReady && data.optimal && !data.optimal.unaffordable ? (
                <> {renderDollars(data.optimal.home_value)} or less</>
              ) : (
                "N/A"
              )}
            </Typography>
          </div>

          <div className={classes.headers}>
            <Typography>
              {dataReady && data.ok && !data.ok.unaffordable ? (
                <>{renderDollars(data.ok.home_value)}</>
              ) : (
                "N/A"
              )}
            </Typography>
          </div>

          <div className={classes.headers}>
            <Typography>
              {dataReady && data.risky && !data.risky.unaffordable ? (
                <>{renderDollars(data.risky.home_value)} or more</>
              ) : (
                "N/A"
              )}
            </Typography>
          </div>
        </Box>

        <Slider
          defaultValue={[0, 1, 2]}
          disabled={true}
          max={2}
          min={0}
          style={{ width: "90%", margin: "5px 0px" }}
          // marks={marks}
          track={false}
        />

        <Box className={classes.row}>
          <div className={classes.headers}>
            <Typography variant="h6">Optimal</Typography>
          </div>

          <div className={classes.headers}>
            <Typography variant="h6">Okay</Typography>
          </div>

          <div className={classes.headers}>
            <Typography variant="h6">Risky</Typography>
          </div>
        </Box>
      </Card>
    </Box>
  );
};

export default GraphSection;
