import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { DollarTextField } from "src/utils";
import Button from "src/components/Button";
import TitleCard from "src/components/TitleCard";
import { useRentVsBuy } from "../UseRentVsBuy";

const HomeInputScreen = () => {
  const classes = useStyles();
  const {
    homeInputData,
    handleChangeHomeInputStep,
    handleNext,
    homeInputError,
    setHomeInputError,
    handleBack,
  } = useRentVsBuy();

  return (
    <Box className={classes.root}>
      <TitleCard title="Expected Down Payment &amp; Home Location">
        <Box className={classes.container}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell className={classes.wideCell}>
                  <Typography>
                    What down payment would you expect to make?
                  </Typography>

                  <Typography>
                    (If unsure, leave blank and we will assume 3.5% of the
                    estimated purchase price.)
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <DollarTextField
                    variant="outlined"
                    placeholder="$10,000"
                    value={
                      homeInputData.downPayment === 0
                        ? ""
                        : homeInputData.downPayment
                    }
                    name="downPayment"
                    onChange={handleChangeHomeInputStep}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.wideCell}>
                  <Typography>
                    What ZIP code would the home be located in?
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <TextField
                    variant="outlined"
                    placeholder="78705"
                    name="zipCode"
                    value={homeInputData.zipCode}
                    onChange={handleChangeHomeInputStep}
                    helperText={
                      homeInputError ? "Enter a valid ZIP code." : undefined
                    }
                    error={homeInputError}
                    onFocus={() => setHomeInputError(false)}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </TitleCard>
      <Box className={classes.actionContainer}>
        <Button onClick={handleBack}>Back</Button>
        <Button fbColor="primary" onClick={handleNext}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  table: {
    width: "95%",
    margin: "auto",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: 10,
    marginBottom: "10px",
  },
  wideCell: {
    border: 0,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0 !important",
  },
  cell: {
    border: 0,
    display: "flex",
    width: "200px",
    padding: "0 !important",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
  },
}));

export default HomeInputScreen;
