import React from "react";

import { Box, Divider, makeStyles, Typography } from "@material-ui/core";

import Icon from "src/components/Icon";
import colors from "src/theme/colors";
import shadows from "src/theme/shadows";

interface AccountItemProps {
  className?: string;
  title?: string;
  value?: number | string;
  onEdit?: (id: any) => void;
  onConfirm?: (id: any) => void;
  onSelect?: VoidFunction;
  selected?: boolean;
  error?: boolean;
  helperText?: string;
  hasIcon?: boolean;
  id?: string | number;
  nAccounts?: number;
}

export const AccountItem = ({
  className,
  title,
  value,
  error,
  selected,
  helperText,
  onEdit,
  onConfirm,
  onSelect,
  hasIcon,
  id,
  nAccounts,
}: AccountItemProps) => {
  const styles = useStyles();
  const selectedClassName = onSelect
    ? selected
      ? "selected "
      : "selectable "
    : "";
  return (
    <>
      <Box
        className={`${styles.root} ${
          error ? "error" : ""
        } ${selectedClassName}${className}`}
        onClick={() => {
          if (onSelect) {
            onSelect();
          }
          if (onEdit) {
            onEdit(id);
          }
        }}
      >
        <Box className="flex items-center">
          {hasIcon && (
            <Icon
              iconName="fb-flow-chart"
              className={`${styles.icon} ${error ? "error" : ""}`}
            ></Icon>
          )}
          {selected && (
            <Icon
              iconName="fb-checkmark-outline"
              className={styles.icon}
            ></Icon>
          )}
          <Typography className={styles.title}>{title}</Typography>
          {(onEdit || onConfirm) && (
            <Divider orientation="vertical" flexItem></Divider>
          )}
          {!!onEdit && <Box className={styles.editButton}>Edit</Box>}
          {!!onConfirm && (
            <Typography className="ml-2">
              {value}
              {nAccounts && nAccounts > 1 ? ` (${nAccounts} accounts)` : ""}
            </Typography>
          )}
        </Box>
        {onConfirm ? (
          <Box className={styles.editButton} onClick={() => onConfirm(id)}>
            Confirm
          </Box>
        ) : (
          <Typography>{value}</Typography>
        )}
      </Box>
      {helperText && error && (
        <Typography className={`${styles.helperText} ${error ? "error" : ""}`}>
          {helperText}
        </Typography>
      )}
    </>
  );
};

export default AccountItem;

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: 12,
    border: `1px solid ${colors.blueGray7}`,
    borderRadius: 10,
    fontSize: 12,
    marginBottom: 12,
    boxShadow: shadows[3],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&.error": {
      borderColor: colors.error,
    },
    "&.selected": {
      borderColor: colors.brandingBlue1,
    },
    "&.selectable": {
      cursor: "pointer",
    },
  },

  icon: {
    fontSize: 14,
    color: colors.brandingBlue1,
    marginRight: 10,
    "&.error": {
      color: colors.error,
    },
  },
  title: {
    marginRight: 10,
  },
  editButton: {
    color: colors.brandingBlue2,
    cursor: "pointer",
    paddingLeft: 10,
    "&:hover": {
      color: colors.brandingBlue1,
    },
  },
  helperText: {
    fontSize: 10,
    width: "100%",
    textAlign: "right",
    marginTop: -10,
    marginBottom: 5,
    "&.error": {
      color: colors.error,
    },
  },
});
