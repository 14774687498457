import React from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  Paper,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { HomeResults, ResultData } from "../common";
import { formatWholeDollars } from "src/utils";
import { DEBT_TYPES } from "src/interfaces";

const HOME_DEBTS_TYPES = {
  ...DEBT_TYPES,
  housing_expense: "Housing expense",
  student_loan: "Student loan",
};

const useStyles = makeStyles({
  mainContainer: {
    padding: "20px 0px",
  },
  container: {
    height: "100%",
    padding: "10px",
    borderRadius: "15px",
    display: "grid",
    gridTemplateRows: "1fr auto",
    justifyItems: "stretch",
    backgroundColor: "white",
  },
  sections: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  card: {
    minWidth: "200px",
    // height: "100%",
    display: "grid",
    gridTemplateRows: "auto 150px auto 1fr auto",
    overflow: "hidden",
    padding: "0px 15px 10px 15px",
    borderRadius: "10px",
    margin: "5px",
    "& h1": {
      fontSize: "20px",
    },
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  debts: {
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 15px",
    // height: "20px"
    overflow: "hidden",
  },
  spinner: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notes: {
    padding: "10px 5px",
  },
  total: {
    display: "flex",
    justifyContent: "space-between",
  },
  totalAmount: {
    justifySelf: "",
    padding: "0px 15px",
  },
});

const MainSection = ({
  dataReady,
  data,
}: {
  dataReady: boolean;
  data: HomeResults;
}) => {
  const classes = useStyles();

  const Spinny = () => (
    <Box className={classes.spinner}>
      <CircularProgress />
    </Box>
  );

  const renderMessage = (amount: number, payment: number, category: any) => {
    switch (category) {
      case "Optimal":
        return `Your optimal situation would be to purchase a home with a price of ${formatWholeDollars(
          amount,
          1000
        )} or less.`;
      case "Okay":
        return `You can purchase a home with a price of up to ${formatWholeDollars(
          amount,
          1000
        )}, but the risk of your financial profile would increase substantially.`;
      case "Risky":
        return `Although you may qualify for a loan, if you buy a house with a purchase price of ${formatWholeDollars(
          amount,
          1000
        )} or more, you are taking on significant financial risk.`;
      default:
        return "";
    }
  };

  const DataCard = ({
    category,
    data,
  }: {
    category: string;
    data: ResultData;
  }) => {
    let homeLoan = 0;
    data.debts.forEach((entry) => {
      if (entry.type === "home_loan") {
        homeLoan = entry.payment;
      }
    });
    const total: number = data.debts.reduce((accumulator: any, debt: any) => {
      if (debt.payment) {
        return accumulator + debt.payment;
      } else {
        return accumulator;
      }
    }, 0);
    return (
      <Paper elevation={7} className={classes.card}>
        <Box>
          <Typography variant="h1">{category}</Typography>
        </Box>

        <Box className={classes.message}>
          {dataReady && renderMessage(data.home_value, homeLoan, category)}
        </Box>

        <Typography variant="h6">Monthly Debt Payments</Typography>

        <Box className={classes.debts}>
          {!dataReady ? (
            <Spinny />
          ) : !data.debts.length ? (
            "N/A"
          ) : (
            <>
              {data.debts.map((dataSet) => (
                <Box className={classes.row}>
                  <Typography style={{ width: "200px" }}>
                    {HOME_DEBTS_TYPES[dataSet.type]}
                  </Typography>
                  <Typography>{formatWholeDollars(dataSet.payment)}</Typography>
                </Box>
              ))}
            </>
          )}
        </Box>

        <Box className={classes.total}>
          <Box style={{ width: "100%", textAlign: "center" }}>Total:</Box>
          <Box className={classes.totalAmount}>
            <Box>
              <Divider />
              {total ? formatWholeDollars(total) : "N/A"}
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  };

  const NACard = ({ category }: { category: string }) => {
    return (
      <Paper elevation={7} className={classes.card}>
        <Box>
          <Typography variant="h1">{category}</Typography>
        </Box>

        <Box className={classes.message}>
          At your current income and debt levels, you shouldn't add more debt to
          your finances.
        </Box>

        <Box />

        <Box className={classes.debts} />

        <Box className={classes.total} />
      </Paper>
    );
  };

  return (
    <Box className={classes.mainContainer}>
      <Card className={classes.container}>
        <Box className={classes.sections}>
          {data.optimal.unaffordable ? (
            <NACard category={"Optimal"} />
          ) : (
            <DataCard category={"Optimal"} data={data.optimal} />
          )}

          {data.ok.unaffordable ? (
            <NACard category={"Okay"} />
          ) : (
            <DataCard category={"Okay"} data={data.ok} />
          )}

          {data.risky.unaffordable ? (
            <NACard category={"Risky"} />
          ) : (
            <DataCard category={"Risky"} data={data.risky} />
          )}
        </Box>

        <Box className={classes.notes}>
          <Typography variant="caption">
            Notes: The analysis above disregards any existing primary residence,
            mortgage, and related housing costs from your FitBUX profile and
            only reflects the purchase of a new home. Other Housing Expenses
            include estimates for property tax, insurance, and maintenance
            costs. For Federal Student Loans, if your monthly payment is $0, we
            assumed a normalized monthly payment based on your repayment plan.
            If your repayment plan is unknown, we assume the standard 10 year
            plan. If you are on an income-driven repayment plan (PAYE, REPAYE/SAVE,
            IBR, PSLF) and not pursuing PSLF, we assume that you are saving a
            minimum amount for the tax liability when the loans are forgiven.
            This minimum amount is also included in the Student Loans payment.
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default MainSection;
