import React from "react";
import { useSelector } from "react-redux";

import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";

import {
  getAssetProjection,
  getMinFutureProjections,
} from "src/store/planBuild/selector";
import { PROJECTION_ASSETS, SidebarContent } from "src/interfaces";
import { formatWholeDollars } from "src/utils";

import OverviewTable from "../components/OverviewTable";
import Chart from "src/components/Chart";
import CenterContainer from "src/pages/PlanBuilder/Components/CenterContainer";
import HelpToggle from "src/components/HelpToggle";
import { colors } from "src/theme";
import { getIsSubscribed } from "src/store/system/selector";

const blowOutContent: SidebarContent[] = [
  {
    header: "Investment Allocation",
    body: [
      "How you allocate your investments is based on both how much risk your current financial situation can support and your own willingness to take risk.",
      "When it comes to investments, risk is defined by the range of fluctations a given invesment exhibits over time.",
      "For example, Investment A has an average return of 4% but fluctuates between 1% and 7% per year.",
      "Investment B has an average return of 6% but fluctuates between -5% and 17% per year.",
      "Investment B is said to be riskier because the range of possible returns is much greater than Investment A.",
    ],
  },
];

const yourInvestmentsMessages = {
  free:
    "After you upgrade your membership, we will provide more details on how to adjust your investments to better reflect your ability to take risk.",
  paid:
    "After you implement your plan, you'll be shown your recommended investment  allocation on your dashboard.",
};

const AssetsInvestments = ({ optimized }: { optimized?: boolean }) => {
  const styles = useStyles();
  const assetProjection = useSelector(getAssetProjection);
  const future = useSelector(getMinFutureProjections);
  const isSubscribed = useSelector(getIsSubscribed);
  const retirementTotal = assetProjection.length
    ? assetProjection[assetProjection.length - 1].asset
    : 0;
  const orderIndex = optimized ? "4" : "3";
  const title = `${orderIndex}. Assets/Investments`;

  const now = new Date();
  const nowYear = now.getFullYear();
  const years = [
    "Now",
    "" + (nowYear + 5),
    "" + (nowYear + 10),
    future.retirementYear || "",
  ];

  const assetKeyList = Array.from(future.assetKeys as Set<string>).filter(
    (key: string) => key !== "total"
  );

  return (
    <CenterContainer review title={title}>
      <Grid container spacing={3} className="mt-2 h-full">
        <Grid item xs={6}>
          <Chart
            dollars
            centerTitle
            title="Projected Financial Assets"
            data={assetProjection}
            height="322px"
            keys={["asset", "min"]}
            xKey="month"
            noTitlePadding
            keyLabels={{
              asset: "Plan Followed",
              min: "Plan Not Followed",
            }}
            width="100%"
          />
        </Grid>

        <Grid
          item
          xs={6}
          style={{
            height: "100%",
            overflowY: "scroll",
          }}
        >
          {!!assetProjection.length && (
            <OverviewTable
              title="Financial Assets"
              columns={years}
              rows={[
                {
                  header: "Total if plan followed",
                  columns: years.map((year: string, index: number) =>
                    formatWholeDollars(future.assets[index].plan.total)
                  ),
                  children: assetKeyList.map((key: string) => ({
                    header:
                      PROJECTION_ASSETS[key as keyof typeof PROJECTION_ASSETS],
                    columns: years.map((year, index: number) => {
                      const value = future.assets[index].plan[key];
                      if (value) {
                        return formatWholeDollars(value);
                      }
                      return "";
                    }),
                  })),
                },
                {
                  header: "Total if plan not followed",
                  columns: years.map((year: string, index: number) =>
                    formatWholeDollars(future.assets[index].min.total)
                  ),
                  children: assetKeyList.map((key: string) => ({
                    header:
                      PROJECTION_ASSETS[key as keyof typeof PROJECTION_ASSETS],
                    columns: years.map((year, index: number) => {
                      const value = future.assets[index].min[key];
                      if (value) {
                        return formatWholeDollars(value);
                      }
                      return "";
                    }),
                  })),
                },
              ]}
            />
          )}

          <Grid item xs={12}>
            <Paper className="px-4 py-5">
              <Box>
                <Typography variant="h2" className={styles.title}>
                  Details
                </Typography>
              </Box>

              <Box className="mt-2">
                <Typography variant="body1" className={styles.subheading}>
                  Your Plan
                </Typography>

                <Typography style={{ fontSize: "13px" }}>
                  Following this plan will provide you with{" "}
                  {formatWholeDollars(retirementTotal)} at retirement.
                </Typography>
              </Box>

              <Box className="mt-2">
                <Typography variant="body1" className={styles.subheading}>
                  Your Investments
                </Typography>

                <Box className="flex align-center">
                  <Typography className="relative" style={{ fontSize: "13px" }}>
                    {isSubscribed
                      ? yourInvestmentsMessages.paid
                      : yourInvestmentsMessages.free}
                    <span style={{ position: "absolute", bottom: -5 }}>
                      <HelpToggle content={blowOutContent} />
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </CenterContainer>
  );
};

export default AssetsInvestments;

const useStyles = makeStyles({
  actionBar: {
    background: "white",
    padding: "12px 68px",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    width: "100%",
  },
  actionButton: {
    width: 168,
  },
  cardRowCell: {
    fontSize: 12,
    width: "16%",
  },
  cardRowLabel: {
    fontSize: 12,
    paddingLeft: 10,
    width: "36%",
  },
  cardTableHeader: {},
  chart: {
    minWidth: 440,
    maxWidth: 524,
  },
  loaderContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 555,
    background: "rgba(255, 255, 255, .8)",
  },
  title: {
    fontSize: 14,
    color: colors.brandingBlue1,
    fontWeight: 600,
  },
  subheading: {
    fontSize: 13,
    color: colors.brandingBlue2,
    fontWeight: 600,
    marginTop: "10px",
  },
});
