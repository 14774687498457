import React, { useEffect, useState } from "react";

import {
  LifeInsuranceResult,
  getLifeInsuranceRecommendationAPI,
} from "src/apiService";
import CalculatorLayout from "src/layouts/CalculatorLayout";
import LandingStep from "./LandingStep";
import ResultStep from "./ResultStep";

enum STEPS {
  LANDING_SCREEN,
  RESULT_SCREEN,
}

const DisabilityInsuranceRecommendation = () => {
  const [step, setStep] = useState(STEPS.LANDING_SCREEN);
  const [result, setResult] = useState<LifeInsuranceResult>({
    life_insurance_recommendation: 0,
    message: "LI1",
    s_life_insurance_recommendation: 0,
    s_message: "LI1",
  });

  useEffect(() => {
    getLifeInsuranceRecommendationAPI().then((data) => {
      setResult(data);
    });
  }, []);

  const renderStep = () => {
    switch (step) {
      case STEPS.LANDING_SCREEN:
        return <LandingStep onNext={() => setStep(STEPS.RESULT_SCREEN)} />;
      case STEPS.RESULT_SCREEN:
        return (
          <ResultStep
            {...result}
            goBack={() => setStep(STEPS.LANDING_SCREEN)}
          />
        );
    }
  };

  return (
    <CalculatorLayout
      title="How Much Life Insurance Do I Need?"
      backTo="/insurance"
      disclaimer=""
    >
      {renderStep()}
    </CalculatorLayout>
  );
};

export default DisabilityInsuranceRecommendation;
