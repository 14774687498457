import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles, Typography } from "@material-ui/core";

import { getScore } from "src/store/dashboard/selector";
import InvestmentsLayout from "src/layouts/InvestmentsLayout";
import { updatePortfolio } from "src/store/investments/actions";
import { getPortfolioSettings } from "src/store/investments/selector";
import ChangeAllocation from "../shared/ChangeAllocation";
import EditCompleted from "./EditCompleted";
import EditOptions from "./EditOptions";
import Rebalancing from "../shared/Rebalancing";
import { bettermentDisclaimerText } from "../shared/text";
import ViewEditLink from "./ViewEditLink";

import { investmentsStyles } from "src/theme";
import { scoreToAllocation } from "src/utils";

const useStyles = makeStyles(investmentsStyles);

export const Investments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const portfolioSettings = useSelector(getPortfolioSettings);
  const score = useSelector(getScore);

  const initialFormValues = {
    fitbuxAllocation: scoreToAllocation(score),
    allocation: portfolioSettings
      ? +portfolioSettings.portfolio.split("_")[1]
      : scoreToAllocation(score),
    rebalance: portfolioSettings ? portfolioSettings.rebalance : "auto",
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [step, setStep] = useState(0);

  const submitEdit = () => {
    let rebalance = formValues.rebalance;
    if (formValues.fitbuxAllocation !== formValues.allocation) {
      rebalance = "static";
    }
    const portfoloPayload = {
      portfolio: `${100 - formValues.allocation}_${formValues.allocation}`,
      rebalance,
      score,
      recommended: `${100 - formValues.fitbuxAllocation}_${
        formValues.fitbuxAllocation
      }`,
    };
    dispatch(updatePortfolio(portfoloPayload));
    setStep(4);
  };

  const renderContent = () => {
    switch (step) {
      case 0:
        return <ViewEditLink next={() => setStep(1)} />;
      case 1:
        return <EditOptions next={() => setStep(2)} />;
      case 2:
        return (
          <ChangeAllocation
            next={() => {
              if (formValues.allocation !== formValues.fitbuxAllocation) {
                return submitEdit();
              }
              return setStep(3);
            }}
            goBack={() => setStep(1)}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case 3:
        return (
          <Rebalancing
            next={submitEdit}
            goBack={() => setStep(2)}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case 4:
      default:
        return <EditCompleted />;
    }
  };
  return (
    <InvestmentsLayout
      goBackTarget="/investments"
      headerText="Your Betterment Account"
    >
      <main className={classes.content}>
        {renderContent()}
        {(step === 2 || step === 3) && (
          <Typography className={classes.disclaimer}>
            {bettermentDisclaimerText}
          </Typography>
        )}
      </main>
    </InvestmentsLayout>
  );
};

export default Investments;
