import React from "react";
import { makeStyles } from "@material-ui/core";
import colors from "src/theme/colors";
import shadows from "src/theme/shadows";

interface TextProps {
  className?: string;
  heading?: string;
  description?: React.ReactNode;
  Button?: any[];
  icons?: any[];
}
export const SolutionCard = ({
  className,
  heading,
  description,
  Button,
  icons,
}: TextProps) => {
  const styles = useStyles();
  return (
    <div className={`${styles.container} ${className ? className : ""}`}>
      <h1>{heading}</h1>
      <p>{description}</p>
      <div className={styles.footer}>
        {Button && <div className={styles.buttons}>{Button}</div>}
        {icons && (
          <div className={styles.icons}>
            {icons.map((item) => (
              <div>
                <img src={item} alt="logo" />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
const useStyles = makeStyles({
  container: {
    borderRadius: 28,
    background: colors.white,
    boxShadow: shadows[4],
    padding: 24,
    height: "100%",
    width: "100%",
    "& h1": {
      margin: "0px 0px 12px 0px",
      color: colors.brandingBlue1,
      whiteSpace: "pre-line",
      fontSize: 14,
      lineHeight: "25px",
    },
    "& p": {
      color: colors.blueGray1,
      whiteSpace: "pre-line",
      fontSize: 13,
      lineHeight: "20px",
    },
  },
  buttons: {},
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  icons: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    "& div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      margin: "5px 2px",
    },
  },
});
