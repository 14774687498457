import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";

import { getHousingTenureAPI, validateZipApi } from "src/apiService";
import { HousingTenureResult } from "src/interfaces/tools.interface";
import { getHousehold } from "src/store/profileBuild/selector";
import { formatZip } from "src/utils";
import { STEP } from "../CONSTANT";

interface RentVsBuyContextProps {
  activeStep: number;
  handleNext(): void;
  handleBack(): void;
  setRentInputError: React.Dispatch<
    React.SetStateAction<{
      rent: boolean;
      zipCode: boolean;
    }>
  >;
  setHomeInputError: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeHomeInputStep(e: React.ChangeEvent<HTMLInputElement>): void;
  handleChangeRentInputStep(e: React.ChangeEvent<HTMLInputElement>): void;
  homeInputData: {
    zipCode: string;
    downPayment: number;
  };
  rentInputData: {
    rent: number;
    rentInsurance: number;
    zipCode: string;
  };
  rentInputError: {
    rent: boolean;
    zipCode: boolean;
  };
  homeInputError: boolean;
  result?: HousingTenureResult;
}

const RentVsBuyContext = createContext<RentVsBuyContextProps>({
  activeStep: 0,
  handleNext: () => undefined,
  handleBack: () => undefined,
  handleChangeHomeInputStep: () => undefined,
  handleChangeRentInputStep: () => undefined,
  homeInputData: {
    zipCode: "",
    downPayment: 0,
  },
  rentInputData: {
    rent: 0,
    rentInsurance: 0,
    zipCode: "",
  },
  rentInputError: {
    rent: false,
    zipCode: false,
  },
  homeInputError: false,
  setHomeInputError: () => null,
  setRentInputError: () => null,
});

const { Provider } = RentVsBuyContext;

type RentVsBuyProviderProps = {
  children: React.ReactChild | boolean | Array<JSX.Element>;
};

export const RentVsBuyProvider = ({ children }: RentVsBuyProviderProps) => {
  const [step, setStep] = React.useState(0);
  const household = useSelector(getHousehold);
  const [homeInputData, setHomeInputData] = React.useState({
    zipCode: household.zip,
    downPayment: 0,
  });
  const [homeInputError, setHomeInputError] = React.useState(false);
  const [rentInputData, setRentInputData] = React.useState({
    rent: 0,
    rentInsurance: 0,
    zipCode: "",
  });
  const [rentInputError, setRentInputError] = React.useState({
    rent: false,
    zipCode: false,
  });
  const [result, setResult] = React.useState<HousingTenureResult | undefined>(
    undefined
  );

  const handleChangeHomeInputStep = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHomeInputData((preValues) => ({
      ...preValues,
      [e.target.name]:
        e.target.name === "zipCode"
          ? formatZip(e.target.value)
          : Number(e.target.value),
    }));
  };
  const handleChangeRentInputStep = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRentInputData((preValues) => ({
      ...preValues,
      [e.target.name]:
        e.target.name === "zipCode"
          ? formatZip(e.target.value)
          : Number(e.target.value),
    }));
  };

  const handleNext = async () => {
    switch (step) {
      case STEP.HOUSE_INPUT_STEP: {
        const data = await validateZipApi(homeInputData.zipCode);
        if (!data) return setHomeInputError(true);
        setRentInputData((preValues) => ({
          ...preValues,
          zipCode: homeInputData.zipCode,
        }));
        return setStep((pre) => pre + 1);
      }
      case STEP.RENT_INPUT_STEP: {
        const data = await validateZipApi(rentInputData.zipCode);
        if (!data || rentInputData.rent === 0)
          return setRentInputError({
            rent: rentInputData.rent === 0,
            zipCode: !data,
          });
        const res = await getHousingTenureAPI({
          home_down: homeInputData.downPayment,
          home_zip_code: homeInputData.zipCode,
          rent_amount: rentInputData.rent,
          rent_insurance: rentInputData.rentInsurance,
          rent_zip_code: rentInputData.zipCode,
        });
        if (res) {
          setResult(res);
          return setStep((pre) => pre + 1);
        }
        break;
      }

      default:
        setStep((pre) => pre + 1);
        break;
    }
  };
  const handleBack = () => {
    switch (step) {
      case STEP.LANDING_STEP:
        break;
      case STEP.HOUSE_INPUT_STEP: {
        setHomeInputError(false);
        setStep((pre) => pre - 1);
        break;
      }
      case STEP.RENT_INPUT_STEP: {
        setRentInputError({
          rent: false,
          zipCode: false,
        });
        setStep((pre) => pre - 1);
        break;
      }
      default: {
        setStep((pre) => pre - 1);
        break;
      }
    }
  };

  const values: RentVsBuyContextProps = {
    activeStep: step,
    homeInputData,
    handleNext,
    handleBack,
    handleChangeHomeInputStep,
    handleChangeRentInputStep,
    homeInputError,
    setHomeInputError,
    setRentInputError,
    rentInputData,
    rentInputError,
    result,
  };
  return <Provider value={values}>{children}</Provider>;
};

export const useRentVsBuy = () => {
  const { ...values } = useContext(RentVsBuyContext);
  return {
    ...values,
  };
};
