import React from "react";

import { Box, makeStyles } from "@material-ui/core";

import Button from "src/components/Button";

const useStyles = makeStyles({
  bigButton: {
    display: "block",
    height: 80,
    margin: "20px auto",
    width: 500,
  },
});

const UserOrSpouse = ({ next }: any) => {
  const styles = useStyles();

  return (
    <Box className="mt-12">
      <Button
        variant="outlined"
        fbColor="primary"
        className={styles.bigButton}
        onClick={() => next("applicant")}
      >
        This Is For My Credit Cards
      </Button>
      <Button
        variant="outlined"
        fbColor="primary"
        className={styles.bigButton}
        onClick={() => next("spouse")}
      >
        This Is For My Spouse's Credit Cards
      </Button>
    </Box>
  );
};

export default UserOrSpouse;
