import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { statusIndicatorStyles } from "src/theme/base";
import clsx from "clsx";
const useStyles = makeStyles(statusIndicatorStyles);

interface IStatusProps {
  fill?: string;
  title?: string;
  fontLight?: boolean;
}

const StatusIndicator = ({ fill, title, fontLight }: IStatusProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <span className={classes.indicator} style={{ background: fill }}></span>
      <p className={clsx(classes.text, `${fontLight && "font-normal"}`)}>
        {title}
      </p>
    </div>
  );
};

export default StatusIndicator;
