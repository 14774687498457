import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { investmentsStyles } from "src/theme";

const useStyles = makeStyles(investmentsStyles);

export const AllocationTable = ({
  myAvg,
  fbAvg,
  myStdDev,
  fbStdDev,
  myAllocationLabel,
  extraAvg,
  extraStdDev,
  extraColumn,
}: any) => {
  const classes = useStyles();

  const my5pct = (myAvg - 2 * myStdDev).toFixed(2);
  const my95pct = (myAvg + 2 * myStdDev).toFixed(2);
  const fb5pct = (fbAvg - 2 * fbStdDev).toFixed(2);
  const fb95pct = (fbAvg + 2 * fbStdDev).toFixed(2);
  const extra5pct = extraColumn ? (extraAvg - 2 * extraStdDev).toFixed(2) : 0;
  const extra95pct = extraColumn ? (extraAvg + 2 * extraStdDev).toFixed(2) : 0;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell className={classes.wrapped}>
            FitBUX's Recommended Allocation
          </TableCell>
          <TableCell className={classes.wrapped}>
            Your {myAllocationLabel} Allocation
          </TableCell>
          {extraColumn && (
            <TableCell className={classes.wrapped}>
              Your Adjusted Allocation
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.wrapped}>
            Expected Long-Term Average Annual Rate Of Return
          </TableCell>
          <TableCell>{fbAvg.toFixed(2)}%</TableCell>
          <TableCell>{myAvg.toFixed(2)}%</TableCell>
          {extraColumn && <TableCell>{extraAvg.toFixed(2)}%</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell className={classes.wrapped}>
            Expected Standard Deviation
          </TableCell>
          <TableCell>{fbStdDev.toFixed(2)}%</TableCell>
          <TableCell>{myStdDev.toFixed(2)}%</TableCell>
          {extraColumn && <TableCell>{extraStdDev.toFixed(2)}%</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell>What This Means</TableCell>
          <TableCell className={classes.wrapped}>
            At this allocation, there is a 95% probability that your
            investment's annual rate of return would fall between {fb5pct}% and{" "}
            {fb95pct}%.
          </TableCell>
          <TableCell className={classes.wrapped}>
            At this allocation, there is a 95% probability that your
            investment's annual rate of return would fall between {my5pct}% and{" "}
            {my95pct}%.
          </TableCell>
          {extraColumn && (
            <TableCell className={classes.wrapped}>
              At this allocation, there is a 95% probability that your
              investment's annual rate of return would fall between {extra5pct}%
              and {extra95pct}%.
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default AllocationTable;
