import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

import { IDRVsPrepayResultsResponse } from "src/apiService";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Chart from "src/components/Chart";

import { getIsMarried } from "src/store/system/selector";
import colors from "src/theme/colors";
import { formatWholeDollars } from "src/utils";

interface PayoffVsIDRResultsProps {
  results: IDRVsPrepayResultsResponse[];
  loadNewResults: (tabIndex?: number) => void;
  goBack: VoidFunction;
  who: string;
}

const useStyles = makeStyles({
  root: {
    margin: "0 auto",
    maxWidth: 1000,
  },
  topContainer: {
    display: "flex",
  },
  highlight: {
    background: colors.blueGray6,
    fontSize: 16,
    marginBottom: 20,
    marginTop: 20,
    padding: 10,
  },
  textHeader: {
    marginBottom: 10,
    marginTop: 10,
    fontSize: 16,
    fontWeight: 600,
  },
  detailRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  subheading: {
    fontWeight: 600,
    marginBottom: 5,
    marginTop: 8,
    textTransform: "capitalize",
  },
  emphasis: {
    fontWeight: 600,
    color: colors.brandingBlue1,
    fontSize: 14,
    marginBottom: 10,
    marginTop: 5,
  },
  tabs: {
    height: 42,
    padding: 4,
    margin: "16px 0px 6px",
  },
  chartContainer: {},
  backButton: {
    display: "block",
    margin: "20px auto",
    width: 200,
  },
});

export const PayoffVsIDRResults = ({
  results,
  loadNewResults,
  goBack,
  who,
}: PayoffVsIDRResultsProps) => {
  const styles = useStyles();
  const isMarried = useSelector(getIsMarried);
  const [currentTab, setCurrentTab] = useState(0);

  const changeTab = (e: React.ChangeEvent<any>, tabIndex: number) => {
    if (!results[tabIndex]) {
      loadNewResults(tabIndex);
    }
    setCurrentTab(tabIndex);
  };

  const detail = results?.[currentTab]?.detail || {};

  const formattedData = useMemo(() => {
    const data = results?.[currentTab];
    if (!data || !data.ppm || !data.ppm.length) {
      return [];
    }
    return data.ppm.map((value, index) => ({
      month: index,
      payoff: value,
      idr: data.idr[index],
    }));
  }, [results, currentTab]);

  const roundToOnePlace = (value: number) =>
    (Math.round((value || 0) * 10) / 10).toFixed(1);

  const youCaps = who === "spouse" ? "Your spouse" : "You";
  const youMake = who === "spouse" ? "your spouse makes" : "you make";
  const spousal = who === "spouse" ? "spousal" : "your";
  const yourTheir = who === "spouse" ? "their" : "your";
  const you = who === "spouse" ? "your spouse" : "you";
  const youThey = who === "spouse" ? "they" : "you";
  const youEnroll = who === "spouse" ? "your spouse enrolls" : "you enroll";
  const yourSpouses = who === "spouse" ? "your" : "your spouse's";
  const your = who === "spouse" ? "your spouse's" : "your";

  const yourPayoffLoansDetail = (
    <>
      {!!isMarried && (
        <Typography variant="body1" className={styles.subheading}>
          {your} Loans
        </Typography>
      )}
      <Box className={styles.detailRow}>
        <Typography variant="body1">Federal</Typography>
        <Typography variant="body1">
          {formatWholeDollars(Math.ceil(detail.prepay_uf))}
        </Typography>
      </Box>
      <Box className={styles.detailRow + " mb-3"}>
        <Typography variant="body1">Tax Obligation</Typography>
        <Typography variant="body1">N/A</Typography>
      </Box>
      <Box className={styles.detailRow}>
        <Typography variant="body1">Private</Typography>
        <Typography variant="body1">
          {formatWholeDollars(Math.ceil(detail.prepay_up))}
        </Typography>
      </Box>
    </>
  );

  const spousePayoffLoansDetail = isMarried ? (
    <>
      <Typography variant="body1" className={styles.subheading}>
        {yourSpouses} Loans
      </Typography>
      <Box className={styles.detailRow}>
        <Typography variant="body1">Federal</Typography>
        <Typography variant="body1">
          {formatWholeDollars(Math.ceil(detail.prepay_sf))}
        </Typography>
      </Box>
      <Box className={styles.detailRow}>
        <Typography variant="body1">Private</Typography>
        <Typography variant="body1">
          {formatWholeDollars(Math.ceil(detail.prepay_sp))}
        </Typography>
      </Box>
    </>
  ) : null;

  const payoffResultsDetail = (
    <>
      {yourPayoffLoansDetail}
      {spousePayoffLoansDetail}
    </>
  );

  const yourIDRLoansDetail = (
    <>
      {!!isMarried && (
        <Typography variant="body1" className={styles.subheading}>
          {your} Loans
        </Typography>
      )}
      <Box className={styles.detailRow}>
        <Typography variant="body1">Federal</Typography>
        <Typography variant="body1">
          {formatWholeDollars(Math.ceil(detail.idr_uf))}
        </Typography>
      </Box>
      <Box className={styles.detailRow + " mb-3"}>
        <Typography variant="body1">Tax Obligation</Typography>
        <Typography variant="body1">
          {formatWholeDollars(Math.ceil(detail.tax))}
        </Typography>
      </Box>
      <Box className={styles.detailRow}>
        <Typography variant="body1">Private</Typography>
        <Typography variant="body1">
          {formatWholeDollars(Math.ceil(detail.idr_up))}
        </Typography>
      </Box>
    </>
  );

  const spouseIDRLoansDetail = isMarried ? (
    <>
      <Typography variant="body1" className={styles.subheading}>
        {yourSpouses} Loans
      </Typography>
      <Box className={styles.detailRow}>
        <Typography variant="body1">Federal</Typography>
        <Typography variant="body1">
          {formatWholeDollars(Math.ceil(detail.idr_sf))}
        </Typography>
      </Box>
      <Box className={styles.detailRow}>
        <Typography variant="body1">Private</Typography>
        <Typography variant="body1">
          {formatWholeDollars(Math.ceil(detail.idr_sp))}
        </Typography>
      </Box>
    </>
  ) : null;

  const IDRResultsDetail = (
    <>
      {yourIDRLoansDetail}
      {spouseIDRLoansDetail}
    </>
  );

  return (
    <Box className={styles.root}>
      {!!detail && (
        <Box className={styles.topContainer}>
          <Card title="Pay Off Strategy" className="mr-4 w-1/2">
            <Typography variant="h3" className={styles.highlight}>
              {youCaps} will have savings of{" "}
              {formatWholeDollars(Math.ceil(detail.prepay_total_saving))} in{" "}
              {(detail.saving_period || 0).toFixed(1)} years.
            </Typography>
            <Typography variant="h4" className={styles.textHeader}>
              Summary
            </Typography>
            <Typography variant="body1" className="mb-2">
              This strategy assumes that {youMake} monthly prepayments to all
              loans (including federal, private, and {spousal} loans, if
              applicable), targeting {your} highest interest rate loan first.
            </Typography>
            <Typography variant="body1" className="mb-2">
              Once that highest interest rate loan is paid off, {you} would
              continue to make the same total monthly payment, with any
              prepayment going towards the next highest interest rate loan.
            </Typography>
            <Typography variant="body1" className="mb-2">
              Once {your} loans are fully paid off, {you} would save and invest
              the amount that {youThey} were previously using to make{" "}
              {yourTheir} student loan payments.
            </Typography>
            <Typography variant="h4" className={styles.textHeader}>
              Total Cost of Strategy
            </Typography>
            {payoffResultsDetail}
            <Divider />
            <Box className={`${styles.detailRow} ${styles.emphasis}`}>
              <Typography variant="body1" className={styles.emphasis}>
                Total
              </Typography>
              <Typography variant="body1" className={styles.emphasis}>
                {formatWholeDollars(Math.ceil(detail.prepay_total_pmt))}
              </Typography>
            </Box>
            <Box className={styles.detailRow}>
              <Typography variant="body1">Initial Monthly Payment</Typography>
              <Typography variant="body1">
                {formatWholeDollars(Math.ceil(detail.prepay_ini_payment))}
              </Typography>
            </Box>
            <Box className={styles.detailRow}>
              <Typography variant="body1">Repayment Period</Typography>
              <Typography variant="body1">
                {roundToOnePlace(detail.prepay_pmt_period)} years
              </Typography>
            </Box>
          </Card>
          <Card title="IDR Strategy" className="w-1/2">
            <Typography variant="h3" className={styles.highlight}>
              {youCaps} will have savings of{" "}
              {formatWholeDollars(Math.ceil(detail.idr_total_saving))} in{" "}
              {roundToOnePlace(detail.saving_period)} years.
            </Typography>
            <Typography variant="h4" className={styles.textHeader}>
              Summary
            </Typography>
            <Typography variant="body1" className="mb-2">
              This strategy assumes that {youEnroll} and make payments in an IDR
              plan for {yourTheir} federal loans.
            </Typography>
            <Typography variant="body1" className="mb-2">
              For all other loans (including {your} private loans and{" "}
              {yourSpouses} federal and private loans, if applicable), we assume
              you make your existing standard loan payments.
            </Typography>
            <Typography variant="body1" className="mb-2">
              Immediately save and invest the difference between the monthly
              payments on the Pay Off Strategy versus the reduced payments under
              the IDR Strategy.
            </Typography>
            <Typography variant="h4" className={styles.textHeader}>
              Total Cost of Strategy
            </Typography>
            {IDRResultsDetail}
            <Divider />
            <Box className={`${styles.detailRow} ${styles.emphasis}`}>
              <Typography variant="body1" className={styles.emphasis}>
                Total
              </Typography>
              <Typography variant="body1" className={styles.emphasis}>
                {formatWholeDollars(Math.ceil(detail.idr_total_pmt))}
              </Typography>
            </Box>
            <Box className={styles.detailRow}>
              <Typography variant="body1">Initial Monthly Payment</Typography>
              <Typography variant="body1">
                {formatWholeDollars(Math.ceil(detail.idr_ini_payment))}
              </Typography>
            </Box>
            <Box className={styles.detailRow}>
              <Typography variant="body1">Repayment Period</Typography>
              <Typography variant="body1">
                {roundToOnePlace(detail.idr_pmt_period)} years
              </Typography>
            </Box>
          </Card>
        </Box>
      )}
      <Box className={styles.tabs}>
        <Tabs value={currentTab} onChange={changeTab} variant="fullWidth">
          {/*<Tab label="Pay Off (Save After)" style={{ minWidth: 120 }} />*/}
          <Tab label="IDR (Save Difference)" style={{ minWidth: 120 }} />
          <Tab label="IDR (Save for Tax)" style={{ minWidth: 120 }} />
          <Tab label="IDR (Don't Save)" style={{ minWidth: 120 }} />
        </Tabs>
      </Box>
      <Box className={styles.chartContainer}>
        <Chart
          dollars
          title=""
          data={formattedData}
          height="322px"
          keys={["payoff", "idr"]}
          xInterval={12}
          xKey="month"
          xMargin={60}
          yLabelMargin={70}
          yMargin={15}
          keyLabels={{
            payoff: "Strategy 1: Pay Off",
            idr: "Strategy 2: IDR",
          }}
          width="100%"
        />
      </Box>
      <Button fbColor="secondary" className="mt-4" fullWidth onClick={goBack}>
        Go Back
      </Button>
    </Box>
  );
};

export default PayoffVsIDRResults;
