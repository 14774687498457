import { useSelector } from "react-redux";

import { SPECIAL_REPAYMENTS } from "src/interfaces";
import { getProfile, getSpouseProfile } from "src/store/profileBuild/selector";
import { /*getHasPlan, */ getIsMarried } from "src/store/system/selector";

const useData = () => {
  const isMarried = useSelector(getIsMarried);
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  // const hasPlan = useSelector(getHasPlan);

  const applicantIdr =
    SPECIAL_REPAYMENTS.indexOf(myProfile.fed_repayment_plan || "") >= 0;
  const spouseIdr =
    isMarried &&
    SPECIAL_REPAYMENTS.indexOf(spouseProfile?.fed_repayment_plan || "") >= 0;

  const data: any = {
    answers: {
      heading:
        "Upgrade to Premium to get personalized answers (powered by FitBUX A.I.) and to make debt payments directly from FitBUX",
      subscribedHeading:
        "Get personalized answers (powered by FitBUX A.I.) and make debt payments directly from FitBUX.",
      items: [
        // {
        //   iconName: "fa-calendar-circle-exclamation",
        //   target: "/spinwheel-payments",
        //   label: "Pay & Manage My Student Loans",
        // },
        {
          iconName: "fa-bomb",
          target: "/tax-bomb",
          label: "Am I Saving Enough For The IDR Tax Bomb?",
        },
        // {
        //   iconName: "fa-graduation-cap",
        //   target: "/pay-my-loans",
        //   label: "Pay My Student Loans",
        // },
      ],
      showSubscribeButton: true,
    },
    calculators: {
      heading: 'An easy way to "do the math" and compare strategies.',
      items: [
        {
          iconName: "fa-chart-line-down",
          target: "/tools/payoffAnalysis",
          label: "Student Loan Pay Off",
        },
        {
          iconName: "fa-calendar-circle-user",
          target: "/tools/payoffVsIDR",
          label: "Income-Driven Repayment Plans & PSLF",
        },
        {
          iconName: "fa-arrows-rotate",
          target: "/tools/refinance",
          label: "Student Loan Refinance",
        },
      ],
    },
    products: {
      heading: "Products that help you analyze and implement your plan.",
      items: [
        {
          iconName: "fa-arrows-rotate",
          target: "/solutions?solution=refinance",
          label: "Student Loan Refinance",
        },
        {
          iconName: "fa-graduation-cap",
          target: "/solutions?solution=newStudentLoans",
          label: "In-School Student Loans",
        },
      ],
    },
  };

  if (!applicantIdr && !spouseIdr) {
    data.answers.items[0].disabled = true;
    data.answers.items[0].tooltip =
      "Your current plan reflects a loan payoff strategy. Build a new plan if you are thinking of switching to an income-driven repayment plan.";
  }

  return data;
};

export default useData;
