import * as React from "react";
import { Link } from "react-router-dom";

export * from "./dates";
export * from "./formatter";
export * from "./inputMask";

// required for react-router-dom < 6.0.0
// see https://github.com/ReactTraining/react-router/issues/6056#issuecomment-435524678
export const AdapterLink = React.forwardRef(
  (props: any, ref: React.Ref<HTMLAnchorElement>) => (
    <Link innerRef={ref} {...props} />
  )
);

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

(window as any)["validateEmail"] = validateEmail;

export const validatePassword = (password: string) => {
  if (
    password.length < 8 ||
    !password.match(/\d/) ||
    !password.match(/[A-Z]/) ||
    !password.match(/[a-z]/)
  ) {
    return false;
  }
  return true;
};

// ! note
export const validateZip = (zip: string) => {
  if (zip.length !== 5 || zip.match(/\D/)) {
    return false;
  }
  return true;
};

export const validatePhone = (phone: string) =>
  !!phone && phone.replace(/\D/g, "").length === 10;

export const scoreToAllocation = (score: number) =>
  Math.min(100 - Math.ceil((100 - (score + 25) / 10) / 5) * 5, 95);

export const isTruthy = (value: any) => !!value;

export const expectedReturnsForAllocation: any = {
  "0": 2.44,
  "5": 2.42,
  "10": 2.99,
  "15": 3.33,
  "20": 3.69,
  "25": 4.26,
  "30": 4.62,
  "35": 5.05,
  "40": 5.48,
  "45": 5.92,
  "50": 6.71,
  "55": 6.74,
  "60": 7.05,
  "65": 7.43,
  "70": 7.77,
  "75": 8.15,
  "80": 8.55,
  "85": 9.0,
  "90": 9.45,
  "95": 9.84,
};

export const stdDevsForAllocation: any = {
  "0": 1.48,
  "5": 1.63,
  "10": 2.04,
  "15": 2.33,
  "20": 2.75,
  "25": 3.37,
  "30": 3.88,
  "35": 4.42,
  "40": 5.03,
  "45": 5.65,
  "50": 6.76,
  "55": 6.83,
  "60": 7.4,
  "65": 8.07,
  "70": 8.64,
  "75": 9.23,
  "80": 9.83,
  "85": 10.5,
  "90": 11.1,
  "95": 11.77,
};

export function NPER(
  rate: number,
  payment: number,
  present: number,
  future: number,
  type: number
) {
  type = type === undefined ? 0 : type;
  future = future === undefined ? 0 : future;

  if (rate === 0) {
    return -(present + future) / payment;
  } else {
    const num = payment * (1 + rate * type) - future * rate;
    const den = present * rate + payment * (1 + rate * type);

    return Math.log(num / den) / Math.log(1 + rate);
  }
}

interface GetTermInput {
  rate: number;
  balance: number;
  payment: number;
}
export const getPaidOffTerm = ({
  rate,
  balance,
  payment,
}: GetTermInput): number => {
  const nper = NPER(Number(rate / 100) / 12, -payment, balance, 0, 0);
  const term = Number((Math.ceil(nper) / 12).toFixed(2));
  if (term <= 30) return term;
  return 30;
};

export const payDownData = (
  startBalance: number,
  rate: number,
  payment: number
) => {
  if (startBalance * rate > payment) {
    return [];
  }
  const data: Array<any> = [];
  let balance = +startBalance;

  while (balance > 0) {
    balance += balance * rate;
    balance -= payment;
    data.push(Math.max(balance, 0));
  }
  return data;
};
