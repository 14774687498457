import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import { Grid } from "@material-ui/core";

import CenterContainer from "src/components/CenterContainer";
import Questionnaire from "src/components/Questionnaire";
import {
  marriedQuestions,
  QuestionFormProps,
  singleQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";
import { getIsMarried } from "src/store/system/selector";
// import CenterContainer from "../PlanBuilder/Components/CenterContainer";

const incomeDecrease = ({
  planSetter,
  planData,
  nextClicked,
  goToNext,
  subIndex,
}: QuestionFormProps) => {
  // note: hold initial next click count
  const [nextClick] = useState(nextClicked);
  const [questionIndex, setQuestionIndex] = useState(subIndex || 0);
  const isMarried = useSelector(getIsMarried);
  const questionCollection = isMarried ? marriedQuestions : singleQuestions;
  const questionData: QuestionForm = questionIndex
    ? questionCollection.addIncomeDecrease
    : questionCollection.incomeDecrease;
  const startingValues = cloneDeep(questionData.emptyValue);
  const isEdit = !!planData.income_decrease[questionIndex];
  if (isEdit && !isMarried) {
    startingValues.preReq = "y";
    startingValues.income_decrease = planData.income_decrease[questionIndex];
    startingValues.income_decrease_date =
      planData.income_decrease_date[questionIndex];
  } else if (isEdit && isMarried) {
    startingValues.income_decrease = planData.income_decrease[questionIndex];
    startingValues.income_decrease_date =
      planData.income_decrease_date[questionIndex];
    startingValues.s_income_decrease =
      planData.s_income_decrease[questionIndex];
    startingValues.s_income_decrease_date =
      planData.s_income_decrease_date[questionIndex];
    const hasApplicantDecrease = startingValues.income_decrease_date;
    const hasSpouseDecrease = startingValues.income_decrease_date;
    startingValues.preReq = "y";
    if (hasApplicantDecrease && !hasSpouseDecrease) {
      startingValues.preReq = "a";
    } else if (!hasApplicantDecrease && hasSpouseDecrease) {
      startingValues.preReq = "s";
    }
  }
  const [values, setValues] = useState<Question>(startingValues);

  const handleChange = (newValues: any) => {
    updatePlanData(newValues);
    setValues(newValues);
  };

  const handleTransition = () => {
    const hasDate = planData.income_decrease_date[questionIndex];
    const hasDateSpouse = planData.s_income_decrease_date[questionIndex];
    if (hasDate || hasDateSpouse) {
      setQuestionIndex(questionIndex + 1);
      setValues({ ...questionCollection.addIncomeDecrease.emptyValue });
    } else {
      goToNext();
    }
  };

  useEffect(() => {
    if (nextClick !== nextClicked) {
      handleTransition();
    }
  }, [nextClicked]);

  const handleSingleUpdate = (changedValues: any) => {
    const newPlan = { ...planData };

    if (changedValues.preReq === "n") {
      // if preReq is n then remove the income increase question data
      newPlan.income_decrease.splice(questionIndex, 1);
      newPlan.income_decrease_date.splice(questionIndex, 1);
    } else if (changedValues.preReq === "y") {
      // if preReq is n then set will_nonprofit to n
      newPlan.income_decrease[questionIndex] = changedValues.income_decrease;
      newPlan.income_decrease_date[questionIndex] =
        changedValues.income_decrease_date;
    }
    planSetter(newPlan);
  };

  const handleMarriedUpdate = (changedValues: any) => {
    const newPlan = { ...planData };

    if (changedValues.preReq === "n") {
      // if preReq is n then remove the income decrease question data
      newPlan.income_decrease.splice(questionIndex, 1);
      newPlan.income_decrease_date.splice(questionIndex, 1);
      newPlan.s_income_decrease.splice(questionIndex, 1);
      newPlan.s_income_decrease_date.splice(questionIndex, 1);
    } else if (changedValues.preReq === "y") {
      // if preReq is n then set will_nonprofit to n
      newPlan.income_decrease[questionIndex] = changedValues.income_decrease;
      newPlan.income_decrease_date[questionIndex] =
        changedValues.income_decrease_date;
      newPlan.s_income_decrease[questionIndex] =
        changedValues.s_income_decrease;
      newPlan.s_income_decrease_date[questionIndex] =
        changedValues.s_income_decrease_date;
    } else if (changedValues.preReq === "a") {
      // if preReq is n then set will_nonprofit to n
      newPlan.income_decrease[questionIndex] = changedValues.income_decrease;
      newPlan.income_decrease_date[questionIndex] =
        changedValues.income_decrease_date;
      newPlan.s_income_decrease = newPlan.s_income_decrease.filter(
        (e, i) => e !== undefined && i < questionIndex
      );
      newPlan.s_income_decrease_date = newPlan.s_income_decrease_date.filter(
        (e, i) => e !== undefined && i < questionIndex
      );
    } else if (changedValues.preReq === "s") {
      // if preReq is n then set will_nonprofit to n
      newPlan.s_income_decrease[questionIndex] =
        changedValues.s_income_decrease;
      newPlan.s_income_decrease_date[questionIndex] =
        changedValues.s_income_decrease_date;
      newPlan.income_decrease = newPlan.income_decrease.filter(
        (e, i) => e !== undefined && i < questionIndex
      );
      newPlan.income_decrease_date = newPlan.income_decrease_date.filter(
        (e, i) => e !== undefined && i < questionIndex
      );
    }
    planSetter(newPlan);
  };

  const updatePlanData = isMarried ? handleMarriedUpdate : handleSingleUpdate;

  return (
    <CenterContainer
      title={questionData.typeLabel}
      help={questionData.help}
      iconName="fb-event"
      scrollable
    >
      <Grid container>
        <Questionnaire
          questions={questionData}
          values={values}
          onChange={handleChange}
        />
      </Grid>
    </CenterContainer>
  );
};

export default incomeDecrease;
