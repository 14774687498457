import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import CenterContainer from "../../Components/CenterContainer";
import Button from "src/components/Button";
import {
  savePlan,
  updateAllocations,
  estimateCurrentPlanTaxes,
} from "src/store/planBuild/actions";
import {
  getCurrentPlan,
  getMy401kEligibleIncome,
  getSpouse401kEligibleIncome,
  getMyMaxTradIRADollarContribution,
  getMyMaxRothIRADollarContribution,
  getSpouseMaxTradIRADollarContribution,
  getSpouseMaxRothIRADollarContribution,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { TAX_DEFERRED_INVESTMENT_TYPES, Plan } from "src/interfaces";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import { ASSET_TYPES, SidebarContent } from "src/interfaces";

import { useStyles } from "./styles";
import { CURATED_PLAN_BUILD_STEPS } from "./common";
import PercentBreakdown from "src/components/PercentBreakdown";

const roth_ira_blowout: SidebarContent[] = [
  {
    header: "Recommendation For ROTH IRA",
    body: [
      "If you are eligible to contribute to a Roth IRA, we highly recommend contributing $50 monthly.",
      "Diversifiying your retirement income and using different account types will help reduce the risk to that income. For instance, if the government changes the tax treatment of assets held in a specific type of account negatively, assets held in a different type of account won't be affeced, all else equal.",
    ],
  },
  {
    header: "What Is A Roth IRA?",
    body: [
      "Roth IRAs are tax-advantaged accounts with special features for withdrawals in retirement:",
      "1) Contributions you make each year do not reduce how much you pay in taxes in the tax year the contribution is made.",
      "2) If you withdraw the money after age 59 1/2, you pay no taxes and no penalties.",
      "3) Early withdrawals are subject to taxes and penalties. However, there are special withdrawals that may not incur taxes, such as using Roth IRA money for a down payment on a house.",
    ],
  },
];

const AddOrEditIRA: PlanViewComponent = ({
  initialValues,
  onClose,
  onSave,
  render,
  type,
  curatedIndex,
}) => {
  const isCuratedFlow =
    curatedIndex === CURATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT;
  const isEditFlow = !!initialValues;
  const styles = useStyles();
  const dispatch = useDispatch();
  const plan: Plan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const curatedWho = "applicant";
  const addWho = isMarried ? "" : "applicant";
  const addEditWho = isEditFlow ? initialValues.who : addWho;
  const initialWho = isCuratedFlow ? curatedWho : addEditWho;
  const initialFormValues: any = {
    who: initialWho,
  };
  if (initialValues) {
    initialFormValues.who = initialValues.who;
    const itemIndex = isMarried && initialValues.who === "spouse" ? 1 : 0;
    const soloAllocation = plan.allocations[0].solo[itemIndex];
    initialFormValues.contribution =
      soloAllocation[type as "ira_value" | "roth_ira_value"];
  }
  const [formValues, setFormValues] = useState<any>(initialFormValues);
  let maxSelector = getMyMaxTradIRADollarContribution;

  if (formValues.who === "spouse") {
    if (type === "ira_value") {
      maxSelector = getSpouseMaxTradIRADollarContribution;
    } else {
      maxSelector = getSpouseMaxRothIRADollarContribution;
    }
  } else if (type === "roth_ira_value") {
    maxSelector = getMyMaxRothIRADollarContribution;
  }
  const maxDollars = useSelector(maxSelector);
  const selectedIncome =
    formValues.who === "spouse"
      ? getSpouse401kEligibleIncome
      : getMy401kEligibleIncome;
  const eligibleIncome = useSelector(selectedIncome);
  const setFormWho = (e: React.ChangeEvent<any>) => {
    const who = e.target.value;
    const newFormValues = { ...formValues, who };
    setFormValues(newFormValues);
  };

  const help = type === "roth_ira_value" ? roth_ira_blowout : undefined;
  const accountType =
    type === "roth_ira_value" ? "Roth IRA" : "Traditional IRA";

  const save = () => {
    const whoIndex = isMarried && formValues.who === "spouse" ? 1 : 0;
    const soloAllocations = plan.allocations[0].solo;
    const newSoloAllocations = [...soloAllocations];
    const annualContribution = formValues.contribution;
    newSoloAllocations[whoIndex] = {
      ...soloAllocations[whoIndex],
      [type]: annualContribution,
    };
    if (initialValues && formValues.who !== initialValues.who) {
      const otherIndex = whoIndex ? 0 : 1;
      newSoloAllocations[otherIndex] = {
        ...newSoloAllocations[otherIndex],
        [type]: 0,
      };
    }
    let combinedContribution =
      newSoloAllocations[0][type as "ira_value" | "roth_ira_value"] || 0;
    if (isMarried) {
      combinedContribution +=
        newSoloAllocations[1][type as "ira_value" | "roth_ira_value"] || 0;
    }
    const newAllocations = {
      ...plan.allocations[0],
      solo: newSoloAllocations,
      [type]: combinedContribution,
    };
    dispatch(updateAllocations(newAllocations));
    if (type in TAX_DEFERRED_INVESTMENT_TYPES) {
      dispatch(estimateCurrentPlanTaxes());
    }
    dispatch(savePlan(null));
    onSave();
  };

  const nextDisabled = formValues.contribution > maxDollars;

  return render({
    component: (
      <CenterContainer
        scrollable
        title={accountType}
        iconName={"fb-model"}
        help={help}
      >
        {formValues.who === "" && (
          <Box className="mt-4">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-4">
                Whose contribution is this?
              </FormLabel>
              <Select
                variant="outlined"
                fullWidth
                value={formValues.who}
                name="who"
                placeholder="Select"
                onChange={setFormWho}
              >
                <MenuItem value="applicant">Mine</MenuItem>
                <MenuItem value="spouse">My spouse's</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
        {formValues.who !== "" && (
          <Box className="my-6">
            <PercentBreakdown
              max={maxDollars}
              income={eligibleIncome}
              onChange={setFormValues}
              values={formValues}
              label={ASSET_TYPES[type]}
              unlimited
              incomeType="Eligible"
            />
          </Box>
        )}
        {!curatedIndex && (
          <Button
            variant="outlined"
            fbColor="primary"
            onClick={onClose}
            className={styles.btnCancel}
          >
            Cancel
          </Button>
        )}
      </CenterContainer>
    ),
    nextDisabled,
    nextLabel: "Save",
    nextTooltip: nextDisabled
      ? "You must enter an amount that does not exceed the maximum contribution."
      : undefined,
    onNext: save,
  });
};

export default AddOrEditIRA;
