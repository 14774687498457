import * as React from "react";
import Icon from "src/components/Icon";

import { NavigatorEntry } from "src/interfaces";

export const nestedNavigation: Array<{
  title: string;
  icon: string;
  items: NavigatorEntry[];
}> = [
  {
    title: "My Financial Plan",
    icon: "fa-map-location-dot",
    items: [
      {
        id: "plan-summary",
        title: "Plan Summary",
        href: "/plan-summary",
      },
      {
        id: "build-plan",
        title: "Build My Plan",
        href: "/build-plan",
      },
      {
        id: "plan-list",
        title: "Edit/Review Plans",
        href: "/plan-list",
      },
      {
        id: "compare-plans",
        title: "Compare Plans",
        href: "/compare-plans",
      },
    ],
  },
  {
    title: "My Money Details",
    icon: "fa-magnifying-glass-dollar",
    items: [
      {
        id: "budget",
        title: "Day-to-Day Money",
        href: "/budget",
      },
      {
        id: "wealth",
        title: "Net Wealth",
        href: "/wealth",
      },
      {
        id: "risk",
        title: "Risk Management",
        href: "/risk",
      },
    ],
  },
  {
    title: "My Profile",
    icon: "fa-head-side-brain",
    items: [
      {
        id: "accounts",
        title: "Accounts",
        href: "/accounts",
      },
      {
        id: "transactions",
        title: "Transactions",
        href: "/transactions",
      },
      {
        id: "human-capital",
        title: "Human Capital",
        href: "/wealth#hc",
      },
    ],
  },
  {
    title: "Tools & Products",
    icon: "fa-calculator",
    items: [
      {
        id: "studentloans",
        title: "Student Loans",
        href: "/studentloans",
        isBottom: true,
      },
      {
        id: "investments",
        title: "Investments",
        href: "/investments",
        isBottom: true,
      },
      {
        id: "homebuying",
        title: "Home Ownership",
        href: "/homebuying",
        isBottom: true,
      },
      {
        id: "insurance",
        title: "Insurance",
        href: "/insurance",
        isBottom: true,
      },
      {
        id: "credit",
        title: "Credit/Personal Loans",
        href: "/credit",
        isBottom: true,
      },
      {
        id: "otheritems",
        title: "Other Financial Items",
        href: "/otheritems",
        isBottom: true,
      },
    ],
  },
];

export const navigation: NavigatorEntry[] = [
  {
    id: "plan-summary",
    title: "Plan Summary",
    icon: <Icon iconName="fb-report" />,
    href: "/plan-summary",
  },
  {
    id: "plan-list",
    title: "Plan List",
    icon: <Icon iconName="fb-report" />,
    href: "/plan-list",
  },
  {
    id: "build-plan",
    title: "Build a Plan",
    icon: <Icon iconName="fb-report" />,
    href: "/build-plan",
  },
  {
    id: "accounts",
    title: "Accounts",
    icon: <Icon iconName="fb-finance" />,
    href: "/accounts",
  },
  {
    id: "transactions",
    title: "Transactions",
    icon: <Icon iconName="fb-money" />,
    href: "/transactions",
  },
  {
    id: "budget",
    title: "Budget",
    icon: <Icon iconName="fb-piggy-bank" />,
    href: "/budget",
  },
  {
    id: "wealth",
    title: "Net Wealth",
    icon: <Icon iconName="fb-model" />,
    href: "/wealth",
  },
  {
    id: "human-capital",
    title: "Human Capital",
    icon: <Icon iconName="fb-model" />,
    href: "/wealth",
  },
  {
    id: "risk",
    title: "Risk Management",
    icon: <Icon iconName="fb-warning" />,
    href: "/risk",
  },
  {
    id: "studentloans",
    title: "Student Loans",
    icon: <Icon iconName="fb-user-education" />,
    href: "/studentloans",
    isBottom: true,
  },
  {
    id: "coach",
    title: "FitBUX Coach",
    icon: <Icon iconName="fb-user-education" />,
    href: "/coach",
    isBottom: true,
  },
  {
    id: "upgrade",
    title: "Upgrade Membership",
    href: "/upgrade",
    freeOnly: true,
    isBottom: true,
  },
  {
    id: "homebuying",
    title: "Home Ownership",
    icon: <Icon iconName="fb-settings" />,
    href: "/homebuying",
    isBottom: true,
  },
  {
    id: "insurance",
    title: "Insurance",
    icon: <Icon iconName="fb-settings" />,
    href: "/insurance",
    isBottom: true,
  },
  {
    id: "credit",
    title: "Credit/Personal Loans",
    icon: <Icon iconName="fb-settings" />,
    href: "/credit",
    isBottom: true,
  },
  {
    id: "otheritems",
    title: "Other Financial Items",
    icon: <Icon iconName="fb-settings" />,
    href: "/otheritems",
    isBottom: true,
  },
  // {
  //   id: "solutions",
  //   title: "Solutions",
  //   icon: <Icon iconName="fb-idea" />,
  //   href: "/solutions",
  //   isBottom: true,
  // },
  // {
  //   id: "tools",
  //   title: "Tools",
  //   icon: <Icon iconName="fb-tool-alt" />,
  //   href: "/tools",
  //   isBottom: true,
  // },
  // {
  //   id: "recommendation",
  //   title: "Recommendation",
  //   icon: <Icon iconName="fb-user-education" />,
  //   href: "/recommendations",
  //   isBottom: true
  // },
  {
    id: "investments",
    title: "Investments",
    icon: <Icon iconName="fb-settings" />,
    href: "/investments",
    isBottom: true,
  },
  {
    id: "referral",
    title: "Refer A Friend",
    icon: <Icon iconName="fb-user-avatar-filled-alt" />,
    href: "/referral",
    isBottom: true,
  },
  // {
  //   id: "settings",
  //   title: "Settings",
  //   icon: <Icon iconName="fb-settings" />,
  //   href: "/settings",
  //   isBottom: true,
  // },
  // {
  //   id: "widgets",
  //   title: "Widgets (for dev)",
  //   icon: <Icon iconName="fb-model-alt" />,
  //   href: "/widgets",
  // },
];
