import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";

import { Grid } from "@material-ui/core";

import Questionnaire from "src/components/Questionnaire";
import {
  QuestionFormProps,
  singleQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";
import CenterContainer from "src/pages/PlanBuilder/Components/CenterContainer";
// import CenterContainer from "../PlanBuilder/Components/CenterContainer";

const Children = ({
  planSetter,
  planData,
  nextClicked,
  goToNext,
  subIndex,
}: QuestionFormProps) => {
  const [nextClick] = useState(nextClicked);
  const [questionIndex, setQuestionIndex] = useState(subIndex || 0);

  // if questionIndex is 0 render the main children question
  // else render the additional version of the question
  const questionData: QuestionForm = questionIndex
    ? singleQuestions.addChildren
    : singleQuestions.children;

  const startingValues = cloneDeep(questionData.emptyValue);
  const isEdit = !!planData.children_dates[questionIndex];
  if (isEdit) {
    const income = planData.child_income_drop[questionIndex];
    const spouseIncome = planData.s_child_income_drop[questionIndex];
    const willDropIncome = income !== null;
    const willDropIncomeSpouse = spouseIncome !== null;
    const startingDrop = willDropIncome ? income : spouseIncome;
    let who = "";
    if (willDropIncome) {
      who = "applicant";
    } else if (willDropIncomeSpouse) {
      who = "spouse";
    }
    const enablePreReq1 = who ? "y" : "n";
    startingValues.who = who;
    startingValues.preReq = "y";
    startingValues.preReq1 = enablePreReq1;
    startingValues.child_income_drop = startingDrop;
    startingValues.children_dates = planData.children_dates[questionIndex];
  }
  const [values, setValues] = useState<Question>(startingValues);

  const handleChange = (newValues: any) => {
    updatePlanData(newValues);
    setValues(newValues);
  };

  const handleTransition = () => {
    if (planData.children_dates[questionIndex]) {
      setQuestionIndex(questionIndex + 1);
      setValues({ ...questionData.emptyValue });
    } else {
      goToNext();
    }
  };

  useEffect(() => {
    if (nextClick !== nextClicked) {
      handleTransition();
    }
  }, [nextClicked]);

  const updatePlanData = (changedValues: any) => {
    const newPlan = { ...planData };
    if (changedValues.preReq === "n") {
      // if preReq is y then update will_nonprofit accordingly
      newPlan.children_dates[questionIndex] = "";
      newPlan.child_income_drop.splice(questionIndex, 1);
      newPlan.s_child_income_drop.splice(questionIndex, 1);
    } else if (changedValues.preReq === "y") {
      // if preReq is n then set will_nonprofit to n
      newPlan.children_dates[questionIndex] = changedValues.children_dates;
    }
    // depending on the selected person to drop income up their income drop data
    if (changedValues.who === "applicant") {
      newPlan.child_income_drop[questionIndex] =
        changedValues.child_income_drop || null;
      newPlan.s_child_income_drop[questionIndex] = null;
    } else if (changedValues.who === "spouse") {
      newPlan.s_child_income_drop[questionIndex] =
        changedValues.child_income_drop || null;
      newPlan.child_income_drop[questionIndex] = null;
    }
    planSetter(newPlan);
  };

  return (
    <CenterContainer
      title={questionData.typeLabel}
      help={questionData.help}
      iconName="fb-event"
      scrollable
    >
      <Grid container>
        <Questionnaire
          questions={questionData}
          values={values}
          onChange={handleChange}
        />
      </Grid>
    </CenterContainer>
  );
};

export default Children;
