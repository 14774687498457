import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  FormLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";

import { getIDRVsPrepayTableApi } from "src/apiService";
import Button from "src/components/Button";
import { STATES } from "src/constants";
import { getIsMarried } from "src/store/system/selector";
import { DollarTextField, PercentTextField } from "src/utils";

interface PayoffVsIDRFormProps {
  formValues: any;
  onChange: (e: React.ChangeEvent<any>) => void;
  goBack: VoidFunction;
  next: VoidFunction;
  setTableResults: any;
  who: string;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    padding: 20,
    margin: "0 auto",
    maxWidth: 800,
  },
  inputRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  inputField: {
    minWidth: 120,
  },
  groupHeader: {},
  capitalized: {
    textTransform: "capitalize",
  },
  disclaimer: {
    position: "fixed",
    bottom: 40,
    fontSize: 12,
  },
});

export const PayoffVsIDRForm = ({
  formValues,
  onChange,
  goBack,
  next,
  setTableResults,
  who,
}: PayoffVsIDRFormProps) => {
  const styles = useStyles();
  const isMarried = useSelector(getIsMarried);

  const goToStepTwo = () => {
    const payload = {
      idrcalc_tax_filing_status: isMarried
        ? formValues.idrcalc_tax_filing_status || 2
        : 1,
      idrcalc_household_size: formValues.idrcalc_household_size || 1,
      idrcalc_graduate_loans: formValues.idrcalc_graduate_loans || "n",
      idrcalc_agi: formValues.idrcalc_agi || 0,
      idrcalc_spouse_agi: formValues.idrcalc_spouse_agi || 0,
      idrcalc_agi_ann_incr: formValues.idrcalc_agi_ann_incr || 0,
      idrcalc_state: formValues.idrcalc_state || "TX",
      idrcalc_pslf: formValues.idrcalc_pslf || "n",
      idrcalc_who: who,
    };
    getIDRVsPrepayTableApi(payload).then((result) => {
      setTableResults(result);
      next();
    });
  };

  const your = who === "spouse" ? "your spouse's" : "your";
  const youAndYourSpouse = isMarried ? "you and your spouse" : "you";
  const areYou = who === "spouse" ? "Is your spouse" : "Are you";

  return (
    <Paper className={styles.root}>
      {isMarried && (
        <Box className={styles.inputRow}>
          <FormLabel>What is your tax filing status?</FormLabel>
          <Select
            variant="outlined"
            name="idrcalc_tax_filing_status"
            value={formValues.idrcalc_tax_filing_status || ""}
            onChange={onChange}
            className={styles.inputField}
          >
            <MenuItem value={2}>Married filing jointly</MenuItem>
            <MenuItem value={3}>Married filing separately</MenuItem>
          </Select>
        </Box>
      )}
      <Box className={styles.inputRow}>
        <FormLabel>What is your household size?</FormLabel>
        <TextField
          variant="outlined"
          type="number"
          name="idrcalc_household_size"
          value={formValues.idrcalc_household_size || ""}
          onChange={onChange}
          className={styles.inputField}
        />
      </Box>
      <Box className={styles.inputRow}>
        <FormLabel>Were any of {your} loans for graduate school?</FormLabel>
        <Select
          variant="outlined"
          name="idrcalc_graduate_loans"
          value={formValues.idrcalc_graduate_loans || ""}
          onChange={onChange}
          className={styles.inputField}
        >
          <MenuItem value={"y"}>Yes</MenuItem>
          <MenuItem value={"n"}>No</MenuItem>
        </Select>
      </Box>
      <Box className={styles.inputRow}>
        <FormLabel>What is your annual gross income/AGI?</FormLabel>
        <DollarTextField
          variant="outlined"
          name="idrcalc_agi"
          value={formValues.idrcalc_agi || ""}
          onChange={onChange}
          className={styles.inputField}
        />
      </Box>
      {isMarried && (
        <Box className={styles.inputRow}>
          <FormLabel>What is your spouse's annual gross income/AGI?</FormLabel>
          <DollarTextField
            variant="outlined"
            name="idrcalc_spouse_agi"
            value={formValues.idrcalc_spouse_agi || ""}
            onChange={onChange}
            className={styles.inputField}
          />
        </Box>
      )}
      <Box className={styles.inputRow}>
        <FormLabel>
          What long-term annual rate of increase in income do {youAndYourSpouse}{" "}
          expect?
        </FormLabel>
        <PercentTextField
          variant="outlined"
          name="idrcalc_agi_ann_incr"
          value={formValues.idrcalc_agi_ann_incr || ""}
          onChange={onChange}
          className={styles.inputField}
        />
      </Box>
      <Box className={styles.inputRow}>
        <FormLabel>Which state do you live in?</FormLabel>
        <Select
          variant="outlined"
          name="idrcalc_state"
          value={formValues.idrcalc_state || ""}
          onChange={onChange}
          className={styles.inputField}
        >
          {STATES.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className={styles.inputRow}>
        <FormLabel>
          {areYou} pursuing Public Service Loan Forgiveness?
        </FormLabel>
        <Select
          variant="outlined"
          name="idrcalc_pslf"
          value={formValues.idrcalc_pslf || ""}
          onChange={onChange}
          className={styles.inputField}
        >
          <MenuItem value={"y"}>Yes</MenuItem>
          <MenuItem value={"n"}>No</MenuItem>
        </Select>
      </Box>
      <Button
        className="my-3"
        fbColor="primary"
        onClick={goToStepTwo}
        fullWidth
      >
        Next
      </Button>
      {!!isMarried && (
        <Button fbColor="secondary" onClick={goBack} fullWidth>
          Back
        </Button>
      )}
    </Paper>
  );
};

export default PayoffVsIDRForm;
