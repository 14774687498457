import { createSelector } from "reselect";

import { AppState } from "..";
import {
  isAssetType,
  isDebtType,
  isEducationCost,
  isEducationFunding,
  isExpenseType,
  isIncomeType,
  isIncomeTypeToConfirm,
  isRiskType,
  isTransferType,
  Transaction,
  TransactionState,
} from "src/interfaces";

export const getTransactionState = (state: AppState) => state.transaction;
export const getUnconfirmedTransactions = createSelector(
  [getTransactionState],
  (state: TransactionState) => state.unconfirmedTransactions
);
export const getUnconfirmedIncomeAndExpenses = createSelector(
  getUnconfirmedTransactions,
  (transactions) => {
    const income: Transaction[] = [];
    const expenses: Transaction[] = [];
    const transfers: Transaction[] = [];
    transactions.forEach((transaction) => {
      if (isIncomeTypeToConfirm(transaction.type)) {
        income.push(transaction);
      } else {
        expenses.push(transaction);
      }
      if (isTransferType(transaction.type)) {
        transfers.push(transaction);
      }
    });
    return { income, expenses, transfers };
  }
);

export const getTransactionsByCategory = createSelector(
  getTransactionState,
  (state: TransactionState) => {
    const income: Transaction[] = [];
    const expenses: Transaction[] = [];
    const assets: Transaction[] = [];
    const debts: Transaction[] = [];
    const risk: Transaction[] = [];
    const educationCosts: Transaction[] = [];
    const educationFunding: Transaction[] = [];
    state.transactions.forEach((transaction) => {
      switch (true) {
        case isEducationCost(transaction.type):
          return educationCosts.push(transaction);
        case isEducationFunding(transaction.type):
          return educationFunding.push(transaction);
        case isIncomeType(transaction.type):
          return income.push(transaction);
        case isAssetType(transaction.type):
          return assets.push(transaction);
        case isDebtType(transaction.type):
          return debts.push(transaction);
        case isRiskType(transaction.type):
          return risk.push(transaction);
        case isExpenseType(transaction.type):
        default:
          return expenses.push(transaction);
      }
    });
    return {
      all: state.transactions,
      income,
      expenses,
      assets,
      debts,
      risk,
      educationCosts,
      educationFunding,
    };
  }
);

export const getConfirmationModalStatus = createSelector(
  getTransactionState,
  (state) => state.confirmationModalStatus
);

export const getConfirmationModalOpen = createSelector(
  getTransactionState,
  (state) => state.showConfirmationModal
);

export const getLoadingUnconfirmedTransactions = createSelector(
  getTransactionState,
  (state) => state.loadingUnconfirmedTransactions
);
