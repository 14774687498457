import React from "react";
import { useSelector } from "react-redux";

import { Box, Container, makeStyles, Typography } from "@material-ui/core";

import { headerStyles } from "src/theme";

import Button from "src/components/Button";
import DashboardLayout from "src/layouts/DashboardLayout";
import { getPlans } from "src/store/planBuild/selector";
import { AdapterLink } from "src/utils";

const useHeaderStyles = makeStyles(headerStyles);
const useStyles = makeStyles({
  img: {
    maxHeight: 250,
    maxWidth: 250,
    "& img": {
      width: "100%",
    },
  },
  listItem: {
    "& li::marker": {
      content: 'counter(list-item) ") "',
    },
  },
});

const BuildPlanContainer = () => {
  const headerStyles = useHeaderStyles();
  const contentStyles = useStyles();
  const plans: any[] = useSelector(getPlans);

  return (
    <DashboardLayout pageTitle="Build My Plan">
      <main className={headerStyles.mainContent}>
        <Container className="py-48 flex justify-center">
          <Box className="w-3/4">
            <Typography variant="h2" component="h2" className="mb-3">
              Build, Simulate, and Implement Your Financial Plan
            </Typography>
            {plans.length < 3 && (
              <>
                <Typography variant="body2">
                  There are three steps to implement your plan and start
                  crushing your goals:
                </Typography>
                <ol className={contentStyles.listItem}>
                  <li>Set up your plan</li>
                  <li>Simulate and review your plan</li>
                  <li>Implement your plan and track your progress</li>
                </ol>
                <Typography variant="body2">
                  Let's start making money complement your life style, not
                  dictate it!
                </Typography>
                <Button
                  className="mt-12"
                  fbColor="primary"
                  component={AdapterLink}
                  to="/plan-select"
                >
                  Build My Plan
                </Button>
              </>
            )}
            {plans.length >= 3 && (
              <>
                <Typography paragraph variant="body2">
                  You already have three plans. To build a new plan, you must
                  delete one of your existing plans first.
                </Typography>
                <Button
                  className="mt-12"
                  fbColor="primary"
                  component={AdapterLink}
                  to="/plan-list"
                >
                  Manage Plans
                </Button>
              </>
            )}
          </Box>
          <Box className={contentStyles.img}>
            <img src="/assets/images/png/map.png" alt="map" />
          </Box>
        </Container>
      </main>
    </DashboardLayout>
  );
};

export default BuildPlanContainer;
