import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cloneDeep } from "lodash";

import {
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { profileBuilderStyles } from "src/theme";

import CenterContainer from "../../Components/CenterContainer";
import Button from "src/components/Button";
import { savePlan, updateCurrentPlan } from "src/store/planBuild/actions";
import { getCurrentPlan } from "src/store/planBuild/selector";

import { getAssetBalanceTotals } from "src/store/account/selector";
import { ASSET_TYPES, Plan, PlanViewComponent } from "src/interfaces";
import { DollarTextField, formatWholeDollars } from "src/utils";

const useStyles = makeStyles(profileBuilderStyles);

const UseAssets: PlanViewComponent = ({ onClose, onSave, render }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const currentBalances = useSelector(getAssetBalanceTotals);
  const plan: Plan = useSelector(getCurrentPlan);
  const initialValues: any = {};
  (plan.education || []).forEach((item) => {
    if (ASSET_TYPES[item.type]) {
      initialValues[item.type] = item.payment;
    }
  });
  const [formValues, setFormValues] = useState<any>(initialValues);

  const save = () => {
    const education = cloneDeep(plan.education || []);
    Object.keys(formValues).forEach((key: string) => {
      const existingItem = education.find((item) => item.type === key);
      if (existingItem) {
        existingItem.payment = formValues[key];
      } else {
        education.push({
          type: key,
          payment: formValues[key],
        });
      }
    });
    dispatch(updateCurrentPlan({ education }));
    dispatch(savePlan(null));
    onSave();
  };

  const setFormValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    const newFormValues = { ...formValues, [field]: value };
    setFormValues(newFormValues);
  };

  const renderRows = () => {
    return Object.entries(currentBalances).map(([accountType, balance]) => {
      return (
        <TableRow>
          <TableCell style={{ border: "none" }}>
            {ASSET_TYPES[accountType]}
          </TableCell>
          <TableCell style={{ border: "none" }}>
            {formatWholeDollars(balance)}
          </TableCell>
          <TableCell style={{ border: "none" }}>
            <DollarTextField
              variant="outlined"
              name={accountType}
              value={formValues[accountType] || ""}
              onChange={setFormValue}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const rows = renderRows();

  return render({
    component: (
      <CenterContainer title="Use Assets I Currently Have" iconName="fb-model">
        {!!rows.length && (
          <>
            <TableContainer style={{ maxWidth: 900 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ border: "none" }}>Funds</TableCell>
                    <TableCell style={{ border: "none" }}>
                      Amount on Profile
                    </TableCell>
                    <TableCell style={{ border: "none" }}>
                      Amount to Use for Education
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
              </Table>
            </TableContainer>
            <Typography variant="caption">
              Note: For 401(k)/403(b)/457/TSP and Traditional IRA, we will
              assume that there is a 10% withdrawal penalty. Therefore, only 90%
              of the amount used for education will appear under Education
              Funding. For Roth accounts, you will be penalized if you withdraw
              gains in the account. Also, before liquidating an account, be sure
              to speak to an accountant regarding any tax liability you may
              incur.
            </Typography>
          </>
        )}
        {!rows.length && (
          <Typography variant="h3" className="mt-5 text-base">
            No assets available.
          </Typography>
        )}
        <Button
          variant="outlined"
          fbColor="primary"
          onClick={onClose}
          className={styles.btnCancel}
        >
          Cancel
        </Button>
      </CenterContainer>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default UseAssets;
