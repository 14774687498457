import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import { QuestionFormProps } from "src/interfaces/optimizedPlanBuild.interface";
import CenterContainer from "src/pages/PlanBuilder/Components/CenterContainer";

const Entry = ({ nextClicked, goToNext }: QuestionFormProps) => {
  // note: hold initial next click count
  const [nextClick] = useState(nextClicked);

  useEffect(() => {
    // note: if next click count changed after mount then run goToNext
    if (nextClicked !== nextClick) {
      goToNext();
    }
  }, [nextClicked]);

  return (
    <CenterContainer title="Welcome To Your Optimized Plan">
      <Container className="mt-4">
        Our AI-powered optimization engine uses your profile information, so
        make sure your profile is complete and upd-to-date before proceeding.
      </Container>
    </CenterContainer>
  );
};

export default Entry;
