import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { openSpinwheelComponent } from "src/spinwheel/functions";
import SpinwheelDialog from "src/spinwheel/SpinwheelDialog";

const PrecisionPay = ({ returnToConnect, who }: any) => {
  const dispatch = useDispatch();
  const [spinwheelLoading, setSpinwheelLoading] = useState(true);
  const [spinwheelOpen, setSpinwheelOpen] = useState(false);

  const exit = () => dispatch(push("/studentloans"));

  const loadPrecisionPay = () => {
    setSpinwheelOpen(true);
    openSpinwheelComponent({
      // onExit: closeSpinwheelDialog,
      onLoad: () => setSpinwheelLoading(false),
      module: "precision-pay",
      onEvent: ({ eventName }: any) => {
        if (eventName === "CONNECT_LOAN_TO_CONTINUE") {
          returnToConnect();
        }
      },
      who,
    });
  };

  useEffect(() => {
    loadPrecisionPay();
  }, []);

  return (
    <SpinwheelDialog
      loading={spinwheelLoading}
      open={spinwheelOpen}
      onClose={exit}
    />
  );
};

export default PrecisionPay;
