import React from "react";
import {
  Box,
  Slider,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";

const MySlider = withStyles({
  root: {
    position: "absolute",
    width: 500,
  },
})(Slider);

const FbSlider = withStyles({
  root: {
    position: "absolute",
    width: 500,
  },
})(Slider);

const FbValueLabelComponent = ({ children, open }: any) => {
  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="bottom"
      title={
        <Box className="text-center">
          <Box>FitBUX's Recommended Risk Allocation</Box>
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};
const MyValueLabelComponent = ({ children, open }: any) => {
  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={
        <Box className="text-center">
          <Box>Your Current Risk Allocation</Box>
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};

export const RiskSlider = ({ fitbuxStdDev, myStdDev }: any) => {
  const max = Math.max(fitbuxStdDev, myStdDev) + 2;
  return (
    <Box className="flex justify-center my-12">
      <Typography className="mt-1 mr-4">Most Conservative</Typography>
      <Box className="relative" style={{ width: 500 }}>
        <FbSlider
          disabled
          value={fitbuxStdDev || 0}
          ValueLabelComponent={FbValueLabelComponent}
          valueLabelDisplay="on"
          min={0}
          max={max}
        />
        <MySlider
          value={myStdDev}
          onChange={() => null}
          ValueLabelComponent={MyValueLabelComponent}
          valueLabelDisplay="on"
          min={0}
          max={max}
        />
      </Box>
      <Typography className="mt-1 ml-4">Most Aggressive</Typography>
    </Box>
  );
};

export default RiskSlider;
