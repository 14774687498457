import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Select,
  makeStyles,
  MenuItem,
  Card,
  Divider,
} from "@material-ui/core";

import { HOME_CASH_FLOW } from "../common";
import HelpToggle from "src/components/HelpToggle";
import { getIsMarried } from "src/store/system/selector";
import { formatWholeDollars } from "src/utils";
import Button from "src/components/Button";
import { colors } from "src/theme";

const useStyles = makeStyles({
  mainContainer: {
    // margin: "20px 0px",
  },
  innerBox: {
    backgroundColor: "white",
    // border: "1px black solid",
    width: "80%",
    height: "400px",
    padding: "20px",
    margin: "20px auto",
    "& h1": {
      fontSize: "20px",
    },
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "0px 20px",
  },
  textBody: {
    paddingTop: "20px",
  },
  sideHustleElement: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
  },
  row: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "center",
    textAlign: "center",
    margin: "5px 0px",
  },
  heading: {
    color: colors.brandingBlue1,
    fontSize: "12px",
    fontWeight: "bold",
  },
  marriedRow: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    margin: "5px 0px",
    textAlign: "center",
  },
  transitions: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
  },
});

const ConfirmSideHustle = ({
  incomes,
  setIncomes,
  transition,
}: {
  incomes: HOME_CASH_FLOW[];
  setIncomes: (value: any[]) => void;
  transition: (
    next?: boolean,
    showResults?: boolean,
    incomeData?: any[]
  ) => void;
}) => {
  const classes = useStyles();
  const isMarried = useSelector(getIsMarried);
  const [confirmedIncomes, setConfirmedIncomes] = useState<any[]>([...incomes]);

  const renderSideHustles = () => {
    return confirmedIncomes.map((incomeItem, index) => {
      if (incomeItem.type === "side_hustle") {
        return <SideHustle sideIncome={incomeItem} index={index} key={index} />;
      }
    });
  };

  const saveEarning = (updateFields: HOME_CASH_FLOW, index: number) => {
    const updatedIncomes = [...confirmedIncomes];
    updatedIncomes.splice(index, 1, {
      ...updateFields,
    });
    setConfirmedIncomes(updatedIncomes);
  };

  const handleTransition = (next?: boolean) => {
    setIncomes(confirmedIncomes);
    transition(next);
  };

  const SideHustle = ({
    sideIncome,
    index,
  }: {
    sideIncome: HOME_CASH_FLOW;
    index: number;
  }) => {
    const drop = sideIncome.drop ? 1 : 0;

    const handleChange = () => {
      saveEarning({ ...sideIncome, drop: !drop }, index);
    };

    return (
      <Box className={classes[rowType]}>
        <Box>
          <Typography>{sideIncome.typeLabel}</Typography>
        </Box>

        {isMarried && (
          <Box>
            <Typography>{sideIncome.whoseLabel}</Typography>
          </Box>
        )}

        <Box>
          <Typography>{formatWholeDollars(sideIncome.annual || 0)}</Typography>
        </Box>
        <Box>
          <Select
            value={drop}
            onChange={handleChange}
            variant="outlined"
            style={{ height: "30px" }}
          >
            <MenuItem value={0}>Yes</MenuItem>
            <MenuItem value={1}>No</MenuItem>
          </Select>
        </Box>
      </Box>
    );
  };

  const rowType = isMarried ? "marriedRow" : "row";

  return (
    <Box className={classes.mainContainer}>
      <Card className={classes.innerBox}>
        <Typography variant="h1">
          Confirm Your Income <HelpToggle />
        </Typography>

        <Box className={classes.container}>
          <Box className={classes.textBody}>
            For each of the following incomes, have you had this level of income
            or greater than this level of income for the previous two years?
          </Box>

          <br />

          <Box className={classes[rowType]}>
            <Box>
              <Typography className={classes.heading}>
                Type Of Income
              </Typography>
            </Box>
            {isMarried && (
              <Box>
                <Typography className={classes.heading}>Owner</Typography>
              </Box>
            )}
            <Box>
              <Typography className={classes.heading}>Annual Income</Typography>
            </Box>

            <Box>
              <Typography className={classes.heading}>
                Has Income Been At This Level or Greater For At Least 2 Years?
              </Typography>
            </Box>
          </Box>

          <Divider />

          <Box>{renderSideHustles()}</Box>
        </Box>
      </Card>

      <Box className={classes.transitions}>
        <Button
          onClick={() => {
            handleTransition(false);
          }}
        >
          Back
        </Button>

        <Button
          onClick={() => {
            handleTransition();
          }}
          color="primary"
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmSideHustle;
