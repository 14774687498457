import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";

import { IconButton, Link, Menu, MenuItem } from "@material-ui/core";
import Icon from "src/components/Icon";
// import { setTutorial } from "src/store/dashboard/actions";
// import { getIsSubscribed } from "src/store/system/selector";
import { AdapterLink } from "src/utils";

const UserMenu = () => {
  // const dispatch = useDispatch();
  // const isSubscribed = useSelector(getIsSubscribed);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const openMenu = (e: React.MouseEvent<any>) => setMenuAnchor(e.currentTarget);
  const closeMenu = () => setMenuAnchor(null);

  return (
    <>
      <IconButton
        aria-controls="user-manu"
        color="inherit"
        className="p-1"
        onClick={openMenu}
      >
        <Icon iconName="fb-user-avatar-filled" />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={!!menuAnchor}
        onClose={closeMenu}
      >
        <MenuItem component={AdapterLink} to="/logout" onClick={closeMenu}>
          Log out
        </MenuItem>
        <MenuItem component={AdapterLink} to="/referral" onClick={closeMenu}>
          Refer a Friend
        </MenuItem>
        <MenuItem component={Link} href="https://www.youtube.com/playlist?list=PLYk9ILj711p4xl0yQOm2G5QSMp_ZTBPeG" target="_blank" onClick={closeMenu}>
          Learn How It Works
        </MenuItem>
        {/*<MenuItem
          onClick={() => {
            dispatch(setTutorial("post_profile"));
          }}
        >
          Dashboard features
        </MenuItem>*/}
        {/*!!isSubscribed && (
          <MenuItem
            onClick={() => {
              dispatch(setTutorial("post_subscription"));
            }}
          >
            Paid features
          </MenuItem>
        )*/}
        {/*<MenuItem
          onClick={() => {
            dispatch(setVideo("post_signup"));
            dispatch(setTutorial("post_signup"));
          }}
        >Upgraded profile walkthrough
        </MenuItem>*/}
      </Menu>
    </>
  );
};

export default UserMenu;
