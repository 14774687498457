import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { omit, pickBy } from "lodash";

import { Box } from "@material-ui/core";

import MainCard from "src/components/MainCard";
import {
  savePlan,
  updateAllocations,
  estimateCurrentPlanStudentTaxes,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  currentPlanStudentAllocationTotals,
  getCurrentPlan,
  getFormattedStudentAllocations,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import {
  SPECIAL_ALLOCATIONS,
  Plan,
  TAX_DEFERRED_INVESTMENT_TYPES,
} from "src/interfaces";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import { formatAnnually, formatMonthly } from "src/utils";
import AddAssetOrDebt from "./AddAssetOrDebt";

const MainForm: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const isMarried = useSelector(getIsMarried);
  const plan: Plan = useSelector(getCurrentPlan);
  const { formattedAssets, formattedDebts }: any = useSelector(
    getFormattedStudentAllocations
  );
  const { assetsTotal, debtsTotal }: any = useSelector(
    currentPlanStudentAllocationTotals
  );
  const [addingItem, setAddingItem] = useState(false);
  const [isDebt, setIsDebt] = useState(false);
  const [editingSpecialType, setEditingSpecialType] = useState<any>(null);

  const onNext = () => dispatch(savePlan(PLAN_BUILD_STEPS.FUTURE_QUESTIONS));
  const addDebt = () => {
    setIsDebt(true);
    setAddingItem(true);
  };
  const addAsset = () => {
    setIsDebt(false);
    setAddingItem(true);
  };

  const openForEditing = (item: any) => {
    setEditingSpecialType(item);
  };

  const remove401kAllocation = (soloIndex: number) => {
    const soloAllocations = plan.allocations[0].solo;
    const newSoloAllocations = [...soloAllocations];
    const updateSoloAllocation = { ...newSoloAllocations[soloIndex] };
    const dollarContribution = updateSoloAllocation["401k_value"] || 0;
    updateSoloAllocation["401k_value"] = 0;
    newSoloAllocations[soloIndex] = updateSoloAllocation;
    let newAllocations = {
      ...plan.allocations[0],
      solo: newSoloAllocations,
    };
    const new401kValue =
      (plan.allocations[0]["401k_value"] || 0) - dollarContribution;
    if (new401kValue <= 0.01) {
      newAllocations = omit(newAllocations, "401k_value");
    } else {
      newAllocations["401k_value"] = new401kValue;
    }
    return newAllocations;
  };

  const removeItem = (item: any) => {
    let newAllocations = { ...plan.allocations[0] };
    if (SPECIAL_ALLOCATIONS.indexOf(item.type) >= 0) {
      const soloIndex = item.who === "spouse" ? 1 : 0;
      if (item.type === "401k_value") {
        newAllocations = remove401kAllocation(soloIndex);
      } else {
        const otherIndex = soloIndex ? 0 : 1;
        const soloAllocations = plan.allocations[0].solo;
        const newSoloAllocations = [...soloAllocations];
        newSoloAllocations[soloIndex] = {
          ...newSoloAllocations[soloIndex],
          [item.type]: 0,
        };
        newAllocations = { ...newAllocations, solo: newSoloAllocations };
        if (isMarried) {
          const otherSoloAllocation: any = newSoloAllocations[otherIndex];
          if (!otherSoloAllocation || !otherSoloAllocation[item.type]) {
            newAllocations = {
              ...pickBy(
                newAllocations,
                (value: any, key: string) => key !== item.type
              ),
              solo: newSoloAllocations,
            };
          } else {
            newAllocations = {
              ...newAllocations,
              solo: newSoloAllocations,
              [item.type]: otherSoloAllocation[item.type],
            };
          }
        } else {
          newAllocations = {
            ...pickBy(
              plan.allocations[0],
              (value: any, key: string) => key !== item.type
            ),
            solo: newAllocations.solo,
          };
        }
      }
    } else {
      newAllocations = {
        ...pickBy(
          plan.allocations[0],
          (value: any, key: string) => key !== item.type
        ),
        solo: newAllocations.solo,
      };
    }
    dispatch(updateAllocations(newAllocations));
    if (item.type in TAX_DEFERRED_INVESTMENT_TYPES) {
      dispatch(estimateCurrentPlanStudentTaxes());
    }
    dispatch(savePlan(null));
  };

  const saveItem = (item: any, newValues: any) => {
    let value = newValues.annual;
    if (item.min) {
      value = Math.max(item.min, value);
    }
    const newAllocations = { ...plan.allocations[0], [item.type]: value };
    dispatch(updateAllocations(newAllocations));
    if (item.type in TAX_DEFERRED_INVESTMENT_TYPES) {
      dispatch(estimateCurrentPlanStudentTaxes());
    }
    dispatch(savePlan(null));
  };

  const closeAddEdit = () => {
    setAddingItem(false);
    setEditingSpecialType(null);
  };

  if (addingItem || editingSpecialType) {
    return (
      <AddAssetOrDebt
        editingSpecialType={editingSpecialType}
        isDebt={isDebt}
        onClose={closeAddEdit}
        render={render}
      />
    );
  }

  const assetColumns = [
    { label: "Type", field: "type", type: "fixed", width: "54%" },
    {
      label: "Monthly",
      field: "monthly",
      type: "number",
      width: "23%",
      formatter: formatMonthly,
    },
    {
      label: "Annual",
      field: "annual",
      type: "number",
      width: "23%",
      formatter: formatAnnually,
    },
  ];

  const debtColumns = [
    { label: "Type", field: "type", type: "fixed", width: "54%" },
    {
      label: "Monthly",
      field: "monthly",
      type: "number",
      width: "23%",
      formatter: formatMonthly,
    },
    {
      label: "Annual",
      field: "annual",
      type: "number",
      width: "23%",
      formatter: formatAnnually,
    },
  ];

  if (isMarried) {
    assetColumns[0].width = "31%";
    assetColumns.push({
      label: "Owner",
      field: "who",
      type: "fixed",
      width: "23%",
    });
    debtColumns[0].width = "31%";
    debtColumns.push({
      label: "Owner",
      field: "who",
      type: "fixed",
      width: "23%",
    });
  }

  return render({
    component: (
      <Box className="pb-6">
        <MainCard
          iconName="fb-model"
          addLabel="Add Asset Contribution"
          title="Assets"
          columns={assetColumns}
          data={formattedAssets}
          // plan={plan}
          onAdd={addAsset}
          onDelete={removeItem}
          specialEdit={{
            check: (item: any) => SPECIAL_ALLOCATIONS.indexOf(item.type) >= 0,
            handle: openForEditing,
          }}
          onSave={saveItem}
          summaryRow={
            !formattedAssets.length
              ? undefined
              : {
                  type: "Total Financial Assets",
                  annual: assetsTotal,
                  monthly: Math.round(assetsTotal / 12),
                }
          }
        />
        <MainCard
          iconName="fb-scales-tripped"
          title="Debts"
          addLabel="Add Debt Payments"
          columns={debtColumns}
          data={formattedDebts}
          onAdd={addDebt}
          onDelete={removeItem}
          specialEdit={{
            check: (item: any) => SPECIAL_ALLOCATIONS.indexOf(item.type) >= 0,
            handle: openForEditing,
          }}
          onSave={saveItem}
          summaryRow={
            !formattedDebts.length
              ? undefined
              : {
                  type: "Total Debts",
                  annual: debtsTotal,
                  monthly: Math.round(debtsTotal / 12),
                }
          }
        />
      </Box>
    ),
    nextLabel: "Next Section",
    onNext,
  });
};

export default MainForm;
