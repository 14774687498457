import React, { useEffect } from "react";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles as any);

interface IFbSlide {
  image: string;
  subline: string;
  title: string;
  description: string;
}

interface IFbSlideProps {
  slides: IFbSlide[];
  slide?: number;
  onChanged?: (n: number) => void;
  imagesOnly?: boolean;
  height?: number;
}
const FbSlider = ({
  slides,
  slide,
  onChanged,
  imagesOnly,
  height,
}: IFbSlideProps) => {
  const classes = useStyles();
  const veryShortScreen = useMediaQuery("(max-height:630px)");

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, slider] = useKeenSlider({
    spacing: 10,
    slidesPerView: 2,
    centered: true,
    vertical: true,
    loop: true,
    slideChanged(s) {
      const n = s.details().relativeSlide;
      if (n !== (slide || 0) && onChanged) {
        onChanged(n);
      }
      setCurrentSlide(n);
    },
  });

  useEffect(() => {
    if (slider && slide !== undefined && slide >= 0 && slide < slides.length) {
      slider.moveToSlideRelative(slide || 0);
    }
  }, [slide, slider]);

  const displayHeight = veryShortScreen ? 420 : height || undefined;

  return (
    <div
      ref={sliderRef as React.RefObject<HTMLDivElement>}
      className={classes.root}
      style={{ height: displayHeight }}
    >
      {slides.map((slide, i) => (
        <div className={`keen-slider__slide ${classes.slide}`} key={i}>
          {imagesOnly && (
            <div className={classes.imgFull}>
              <img
                src={slide.image}
                alt={slide.title}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          )}
          {!imagesOnly && (
            <>
              <div className={classes.img}>
                <img src={slide.image} alt={slide.title} />
              </div>
              {!!slide.subline && (
                <h5 className={classes.subline}>{slide.subline}</h5>
              )}
              <h2 className={classes.title}>{slide.title}</h2>
              <p className={classes.description}>{slide.description}</p>
            </>
          )}
        </div>
      ))}
      {slider && slides.length > 1 && (
        <div className={classes.dots}>
          {[...Array(slider.details().size).keys()].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  slider.moveToSlideRelative(idx);
                }}
                className={
                  classes.dot + (currentSlide === idx ? " active" : "")
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FbSlider;
