import { createSelector } from "reselect";
import { AppState } from "..";

export const getInvestmentsState = (state: AppState) => state.investments;
export const getPortfolioSettings = createSelector(
  getInvestmentsState,
  (state) => state.portfolioSettings
);
export const getPortfolioPerformance = createSelector(
  getInvestmentsState,
  (state) => {
    const expectedReturns: any = {};
    const standardDeviations: any = {};
    const result = { expectedReturns, standardDeviations };
    if (!state.portfolioPerformance) {
      return result;
    }
    state.portfolioPerformance.forEach((value) => {
      const stringIndex = value.id.split("_")[1];
      expectedReturns[stringIndex] = value.inception.annual_return * 100;
      standardDeviations[stringIndex] =
        value.inception.standard_deviation * 100;
    });
    return result;
  }
);
