import React, { useState } from "react";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { changePasswordApi } from "src/apiService";
import InformationCard from "../Components/InformationCard";
import TextField from "src/components/TextField";
import Button from "src/components/Button";
import colors from "src/theme/colors";
import { validatePassword } from "src/utils";

const UpdatePassword = () => {
  const styles = useStyles();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewError] = useState(false);
  const [currentPasswordError, setCurrentError] = useState(false);
  const [success, setSuccess] = useState(false);
  const submit = () => {
    if (success) {
      return setSuccess(false);
    }
    const valid = validatePassword(newPassword);
    if (valid) {
      changePasswordApi(currentPassword, newPassword)
        .then(() => {
          setCurrentPassword("");
          setNewPassword("");
          setSuccess(true);
        })
        .catch(() => {
          setCurrentError(true);
        });
    } else {
      setNewError(true);
    }
  };

  const checkPassword = () => setNewError(!validatePassword(newPassword));

  return (
    <InformationCard title="Update Password">
      <Grid container spacing={3} className={styles.container}>
        <Grid item xs={12} md={5} className="-mb-4">
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            error={currentPasswordError}
            onFocus={() => {
              setCurrentError(false);
              setSuccess(false);
            }}
            helperText={
              currentPasswordError
                ? "The password you entered did not match. Please check your input and try again."
                : undefined
            }
            name="currentPassword"
            label="Current Password"
            placeholder="Current Password"
            id="name"
            onChange={(e: React.ChangeEvent<any>) =>
              setCurrentPassword(e.target.value)
            }
            type="password"
            value={currentPassword}
          />
        </Grid>
        <Grid item xs={12} md={5} className="-mb-4">
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="phno"
            error={newPasswordError}
            onFocus={() => {
              setNewError(false);
              setSuccess(false);
            }}
            onBlur={checkPassword}
            helperText={
              newPasswordError
                ? "Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, and one numeral."
                : undefined
            }
            label="New Password"
            name="newPassword"
            placeholder="New Password"
            onChange={(e: React.ChangeEvent<any>) =>
              setNewPassword(e.target.value)
            }
            type="password"
            value={newPassword}
          />
        </Grid>
        <Grid item xs={12} md={2} className="flex justify-end mt-5">
          <Button
            disabled={newPasswordError || currentPasswordError}
            style={
              success ? { backgroundColor: colors.success, color: "white" } : {}
            }
            fbColor="secondary"
            className="w-full -mb-3"
            onClick={submit}
          >
            {success ? "Success" : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </InformationCard>
  );
};

const useStyles = makeStyles({
  container: {
    paddingTop: 20,
    display: "flex",
    alignItems: "flex-start",
  },
});
export default UpdatePassword;
