import React from "react";
import {
  Box,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import Radio from "src/components/Radio";

export const FinancialDetails = ({
  next,
  goBack,
  formValues,
  handleChange,
}: any) => {
  return (
    <Card title="Confirm Employment &amp; Financial Details">
      <Box className="flex justify-between">
        <Box />
        <Box className="w-1/2 flex flex-col">
          <FormLabel className="mb-2 mt-4">
            Are you employed by or associated with a broker dealer?
          </FormLabel>
          <RadioGroup
            aria-label="employedBroker"
            name="employedBroker"
            value={formValues.employedBroker}
            className="flex flex-row"
            onChange={handleChange}
          >
            <FormControlLabel value="n" control={<Radio />} label="No" />
            <FormControlLabel value="y" control={<Radio />} label="Yes" />
          </RadioGroup>
          <FormLabel className="mb-2 mt-4">
            Are you a 10% shareholder, director, or policy maker of a publicly
            traded company?
          </FormLabel>
          <RadioGroup
            aria-label="director"
            name="director"
            value={formValues.director}
            className="flex flex-row"
            onChange={handleChange}
          >
            <FormControlLabel value="n" control={<Radio />} label="No" />
            <FormControlLabel value="y" control={<Radio />} label="Yes" />
          </RadioGroup>
          <FormLabel className="mb-2 mt-4">
            Have you been notified by the IRS that you are subject to a backup
            withholding?
          </FormLabel>
          <RadioGroup
            aria-label="backupWithholding"
            name="backupWithholding"
            value={formValues.backupWithholding}
            className="flex flex-row"
            onChange={handleChange}
          >
            <FormControlLabel value="n" control={<Radio />} label="No" />
            <FormControlLabel value="y" control={<Radio />} label="Yes" />
          </RadioGroup>
          <Box className="flex mt-4 justify-center">
            <Button className="mr-4" onClick={goBack}>
              Back
            </Button>
            <Button fbColor="primary" onClick={next}>
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default FinancialDetails;
