import React from "react";

import { Box, makeStyles } from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";
import Icon from "../Icon";

interface IBigButtonProps {
  fbColor?: "inherit" | "primary" | "default" | "secondary" | "accent";
  [propName: string]: any;
}

const useStyles = makeStyles({
  root: {
    display: "block",
    height: 120,
    paddingTop: 20,
    maxWidth: 180,
  },
});

const BigButton = ({
  fbColor,
  className,
  children,
  iconImage,
  iconName,
  variant = "contained",
  ...rest
}: ButtonProps & IBigButtonProps) => {
  const classes = useStyles();
  const icon = iconImage ? (
    <img src={iconImage} height={24} />
  ) : (
    <Icon iconName={iconName} color="primary" />
  );
  return fbColor === "primary" ||
    fbColor === "default" ||
    fbColor === "inherit" ||
    fbColor === "secondary" ? (
    <Button
      className={`${className} ${classes.root}`}
      color={fbColor}
      variant={variant}
      {...rest}
    >
      <Box className="w-full text-center">{icon}</Box>
      <Box className="w-full text-center">{children}</Box>
    </Button>
  ) : (
    <Button
      className={`${className} ${fbColor} ${classes.root}`}
      variant={variant}
      {...rest}
    >
      <Box className="w-full text-center">{icon}</Box>
      <Box className="w-full text-center">{children}</Box>
    </Button>
  );
};

export default BigButton;
