import React from "react";
import { makeStyles } from "@material-ui/core";
import Card from "src/components/Card";
import HeaderText from "src/components/HeaderText";
import { aboutRecommendations } from "../data";

const Recommendations = () => {
  const { heading, description, img } = aboutRecommendations;
  const styles = useStyles();
  return (
    <Card noPadding className={styles.container}>
      <HeaderText
        heading={heading}
        description={description}
        img={img}
        fontSize="18px"
      />
    </Card>
  );
};

const useStyles = makeStyles({
  container: {
    padding: "30px 38px",
  },
});
export default Recommendations;
