import React from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";

import Card from "src/components/Card";
import IncomeTransactionList from "src/components/IncomeTransactionList";
import { getUnconfirmedIncomeAndExpenses } from "src/store/transaction/selector";

const useStyles = makeStyles({
  title: {
    fontSize: "16px !important",
  },
});

const ConfirmIncome = ({ openConfirmDialog }: any) => {
  const styles = useStyles();
  const { income } = useSelector(getUnconfirmedIncomeAndExpenses);

  return (
    <Card titleClassName={styles.title} title="Confirm Income">
      <IncomeTransactionList
        items={income}
        openConfirmDialog={openConfirmDialog}
      />
    </Card>
  );
};

export default ConfirmIncome;
