import React from "react";
import { useSelector } from "react-redux";

import { Box, makeStyles, Theme } from "@material-ui/core";

import { LifeInsuranceResult } from "src/apiService";
import Button from "src/components/Button";
import TitleCard from "src/components/TitleCard";
import { User } from "src/interfaces";
import {
  getIsMarried,
  spouseSelector,
  userSelector,
} from "src/store/system/selector";
import { formatWholeDollars } from "src/utils";

const MESSAGES: any = {
  M1: `Based on your current FitBUX Profile, you need XXX in life insurance to cover your final expenses plus provide for your children until they are 18 if you pass away.`,
  M2: `Based on your FitBUX Profile, you need XXX in life insurance to cover final expenses plus the debt on your profile that your estate will be responsible for if you pass away.`,
  M3: `Based on your FitBUX Profile, you need XXX in life insurance to cover final expenses plus the debt on your profile that your estate will be responsible for if you pass away.`,
  M4: `Based on your FitBUX Profile, you need XXX in life insurance to cover final expenses if you pass away.`,
  M5: `Based on your FitBUX Profile, you need XXX in life insurance to replace your lost wages should you pass away.`,
};

interface ResultStepProps extends LifeInsuranceResult {
  goBack(): void;
}

const ResultStep = ({
  life_insurance_recommendation,
  message,
  s_life_insurance_recommendation,
  s_message,
  goBack,
}: ResultStepProps) => {
  const classes = useStyles();
  const user: User | undefined = useSelector(userSelector);
  const spouse: User | undefined = useSelector(spouseSelector);
  const isMarried = useSelector(getIsMarried);

  if (!user) {
    return null;
  }

  return (
    <>
      <TitleCard title="You Need">
        <Box className={classes.container}>
          {!!message &&
            (MESSAGES[message] || "").replace(
              "XXX",
              formatWholeDollars(life_insurance_recommendation)
            )}
        </Box>
      </TitleCard>
      {isMarried && !!spouse && (
        <TitleCard title="Your Spouse Needs">
          <Box className={classes.container}>
            {(MESSAGES[s_message] || "")
              .replace(
                "XXX",
                formatWholeDollars(s_life_insurance_recommendation)
              )
              .replace("you need", "your spouse needs")
              .replace("you pass", "they pass")
              .replace("your lost", "their lost")}
          </Box>
        </TitleCard>
      )}
      <Box className={classes.transitions}>
        <Button onClick={() => goBack()}>Back</Button>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  flexItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    minWidth: 400,
    position: "relative",
  },
  flexItemWithBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    minWidth: 400,
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
  },
  transitions: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
    gap: "20px",
  },
}));

export default ResultStep;
