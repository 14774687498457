export enum DOLLAR_FORMATS {
  THOUSANDS,
  AMOUNTS_PER_MONTH,
  AMOUNTS_PER_YEAR,
  DOLLARS,
  DOLLARS_AND_CENTS,
  RAW,
}

export const formatDollars = (
  rawAmount: number | null,
  format?: DOLLAR_FORMATS
) => {
  const amount = rawAmount || 0;
  switch (format) {
    case DOLLAR_FORMATS.THOUSANDS:
      return `${amount < 0 ? "-" : ""}$${Math.floor(
        Math.abs(amount / 1000)
      ).toLocaleString()}k`;
    case DOLLAR_FORMATS.AMOUNTS_PER_MONTH:
      return `${amount < 0 ? "-" : ""}$${Math.floor(
        Math.abs(amount)
      ).toLocaleString()}/mo`;
    case DOLLAR_FORMATS.AMOUNTS_PER_YEAR:
      return `${amount < 0 ? "-" : ""}$${Math.floor(
        Math.abs(amount)
      ).toLocaleString()}/yr`;
    case DOLLAR_FORMATS.DOLLARS:
      return `${amount < 0 ? "-" : ""}$${Math.floor(
        Math.abs(amount)
      ).toLocaleString()}`;
    case DOLLAR_FORMATS.DOLLARS_AND_CENTS:
      return `${amount < 0 ? "-" : ""}$${Math.abs(amount).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}`;
    case DOLLAR_FORMATS.RAW:
    default:
      return `${amount < 0 ? "-" : ""}$${Math.abs(amount).toLocaleString()}`;
  }
};

export const formatWholeDollars = (amount: number, roundTo = 1) =>
  formatDollars(Math.round(amount / roundTo) * roundTo, DOLLAR_FORMATS.DOLLARS);

export const formatDollarsAndCents = (amount: number) =>
  formatDollars(amount, DOLLAR_FORMATS.DOLLARS_AND_CENTS);

export const formatThousands = (amount: number) =>
  formatDollars(amount, DOLLAR_FORMATS.THOUSANDS);

export const formatMonthly = (amount: number) =>
  formatDollars(amount, DOLLAR_FORMATS.AMOUNTS_PER_MONTH);

export const formatAnnually = (amount: number) =>
  formatDollars(amount, DOLLAR_FORMATS.AMOUNTS_PER_YEAR);

export const formatPercent = (amount: number) =>
  amount ? amount.toFixed(2) + "%" : "0.00%";

export const reformatDateString = (dateString: string) => {
  // const month = dateString[5] === "0" ? dateString.slice(6, 7) : dateString.slice(5, 7);
  const month = dateString.slice(5, 7);
  const year = dateString.slice(2, 4);
  return `${month}/${year}`;
};

export const formatZip = (value: string) => {
  return value.replace(/\D/g, "").slice(0, 5);
};

export const formatExecutionPercent = (actual: number, plan: number) => {
  if (!plan) {
    return "N/A";
  }
  if (!actual) {
    return "0.00%";
  }
  const percent = actual / plan;
  if (percent >= 10) {
    return ">1000%";
  }
  if (percent <= 0) {
    return "0.00%";
  }
  return formatPercent(percent * 100);
};
