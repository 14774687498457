import { MessageCode } from "src/interfaces";
import {
  goalMessages,
  messageOptionsI,
  payMessages,
  PlanReviewMessage,
  highPriorityMessages,
} from "src/interfaces/planMessage.interface";

export const getReviewMessage = (
  messageData: MessageCode,
  section: "paycheck" | "goal"
) => {
  const message: PlanReviewMessage = {};
  const who = messageData.who;

  const collection =
    section === "goal" ? goalSummaryMessages : payCheckMessages;
  const messagesOptions: messageOptionsI =
    collection[messageData.id as keyof typeof collection];

  if (messagesOptions) {
    if (who && messagesOptions[who]) {
      message.text = messagesOptions[who];
      message.links = messagesOptions.links;
    } else {
      message.text = messagesOptions.house;
      message.links = messagesOptions.links;
    }

    message.text = message.text || "";
    const injectAtIndex = message.text?.indexOf("[x]");
    if (injectAtIndex && injectAtIndex >= 0) {
      const addText = messageData.months_to_save?.toString();
      message.text = `${message.text?.slice(
        0,
        injectAtIndex
      )} ${addText} ${message.text?.slice(injectAtIndex + 3)}`;
    }
  }
  return message;
};

const highPrioritySummaryMessages: highPriorityMessages = {
  NCF: {
    house:
      "After your day-to-day expenses and monthly debt payments are factored into your financial plan, you have negative cash flow each month. You may take one of two actions: 1) Go to the budget page of your FitBUX profile and reduce your expenses (Click here to do so) or 2) Schedule a call with a FitBUX Coach to customize your plan (Click here to do so).",
    links: ["/budget", "/plan-summary#schedule"],
  },
  PP1: {
    house:
      "Since a significant amount of your income is derived from self-employment and/or 1099s, you have access to special investment options. We recommend scheduling a call with your FitBUX Coach.",
  },
  PP9: {
    house:
      "You currently do not have enough saved for an emergency fund, and this should be your first priority. Therefore, we allocate a minimum amount to both investing and debt repayment initially. Once your emergency fund is saved, we will automatically update and optimize your plan and provide further information then. If you believe this is an error and you have enough saved for your emergency fund, please make sure your FitBUX is complete and up-to-date and refresh your optimized plan. Please reach out to your FitBUX Coach should you have any questions.",
  },
  PP10: {
    house:
      "Since you have short term goals, we initially allocate a minimum amount towards investing and debt repayment. Once you hit your short term goals, we will re-optimize your plan and provide further details.",
  },
  DI4: {
    house:
      "Based on your current level of high-interest debt (credit card(s) or personal loan(s)), you should focus on repaying this debt first. We’ve optimized your plan accordingly.",
  },
};

const goalSummaryMessages: goalMessages = {
  BH1: {
    house:
      "Based on your overall situation, you should consider buying a home. We’ve included a home purchase in your plan based on how much you can afford based on the information you provided. as well as a savings goal for the down payment. ",
  },
  BH2: {
    house:
      "While you can afford to buy a home, it would still make more sense, purely financial perspective, to keep renting and save the difference between the higher mortgage payment and your current rent.",
  },
  BH3: {
    house:
      "Buying a home would be a stretch in your situation. However, since your rent is high, we recommend that you stretch and purchase a home. We’ve included a purchase in your plan as well as a savings goal for the down payment.",
  },
  BH4: {
    house:
      "Based on your current income, obligations and expected life events, you cannot afford to buy a home. Therefore, your financial plan assumes that you will keep renting for now.",
  },
  DI1: {
    house:
      "Based on your overall situation, it makes more sense for you to pay off your debt before investing.",
  },
  DI2: {
    house:
      "Based on your overall situation, it makes more sense to invest aggressively relative to paying off your debt.",
  },
  DI3: {
    applicant:
      "Since you’re repaying your student loans, you should explore refinancing to see how much you could save.",
    spouse:
      "Since your spouse is repaying his/her student loans, your spouse should explore refinancing to see how much he/she could save.",
    both:
      "Since you and your spouse are repaying your student loans, you should explore refinancing to see how much the both of you could save.",
  },
  DI4: highPrioritySummaryMessages.DI4,
  DI5: {
    applicant:
      "It would make sense for you to pay off your debt overall. If you elect to not refinance your Federal student loans, you should take advantage of REPAYE/SAVE in the short term to minimize your required payment. Once your income increases or you achieve your other goals, you can then make prepayments on your Federal loans. We’ve assumed that you will follow this strategy in your plan.",
    spouse:
      "It would make sense for you and your spouse to payoff your overall debt. If your spouse elects to not refinance his/her Federal student loans, he/she should take advantage of REPAYE/SAVE in the short term to minimize his/her required payment. Once your spouse's income increases or you both achieve your other goals, your spouse can then make prepayments on his/her Federal loans. We’ve assumed that your spouse will follow this strategy in this plan.",
    both:
      "It would make sense for you and your spouse to pay your overall debts. If you both elect to not refinance your Federal student loans, the two of you should take advantage of REPAYE/SAVE in the short term to minimize your required payments. Once your incomes increase or you both have achieved your other goals, you can then make prepayments on your Federal loans. We’ve assumed that you and your spouse are not refinancing but are taking advantage of REPAYE/SAVE in this plan.",
  },
  DI6: {
    applicant:
      "Overall, it makes financial sense for you to pay off your debt. When it comes to your Federal student loans however, we recommend that you pursue loan forgiveness via an Income Driven Repayment (IDR) plan. Because the forgiven amount is treated as taxable income, we’re including the amount you should save for this tax liability in your financial plan.",
    spouse:
      "It would make sense for you and your spouse to payoff your overall debt. When it comes to your spouse's Federal student loans however, we recommend that your spouse pursue loan forgiveness via an Income Driven Repayment (IDR) plan. Because the forgiven amount is treated as taxable income, we’re including the amount your spouse should save for this tax liability in your financial plan.",
    both:
      "Overall, it makes financial sense for you and your spouse to pay off your debts. When it comes to your Federal student loans however, we recommend that you and your spouse pursue loan forgiveness via Income Driven Repayment (IDR) plans. Because the forgiven amount is treated as taxable income, we’re including the amount you and your spouse should save for this tax liability in your financial plan.",
  },
  DI7: {
    applicant:
      "It would make sense for you to payoff your overall debt, except for your Federal student loans, in which case it would make sense for you to pursue Public Service Loan Forgiveness (PSLF).",
    spouse:
      "It would make sense for you and your spouse to payoff your overall debt, except for your spouse's Federal student loans, in which case it would make sense for him/her to pursue Public Service Loan Forgiveness (PSLF).",
    both:
      "If you and your spouse work at non-profits, you should pursue PSLF for your Federal student loans and only make your minimum required payments. With your excess funds, you should pay off all of your other debts.",
  },
  DI8: {
    applicant:
      "In your situation, you should pursue loan forgiveness for your Federal student loans instead of paying your loans off and invest the money you would otherwise use to pay off your loans. Since the amount forgiven via loan forgiveness is treated as taxable income, we’ve included the amount you should save to cover this tax liability in your plan.",
    spouse:
      "Your spouse should pursue loan forgiveness for his/her Federal student loans instead of paying them off and investing his/her money instead. Since the amount forgiven via loan forgiveness is treated as taxable income, we’ve included the amount your spouse should save to cover this tax liability in your plan.",
    both:
      "You and your spouse should pursue loan forgiveness for your Federal student loans instead of paying them off and investing your money instead. Since the amount forgiven via loan forgiveness is treated as taxable income, we’ve included the amount you and your spouse should save to cover this tax liability in your plan.",
  },
  DI9: {
    applicant:
      "In your situation, it would make sense to pursue Public Student Loan Forgiveness (PSLF) for your Federal student loans and invest the money you would have otherwise used to pay off your loans. Although the amount forgiven via PSLF is not taxable, we recommend saving for 2-4 years to cover what your tax liability would be should you no longer work for a non-profit, as the forgiven amount on a similar plan would then become taxable. For the most efficient way to save, schedule a call with your FitBUX Coach.",
    spouse:
      "In your spouse's situation, it makes sense to pursue Public Student Loan Forgiveness (PSLF) for his/her Federal student loans and invest the money he/she would have otherwise used to pay off his/her loans. Although the amount forgiven via PSLF is not taxable, we recommend saving for 2-4 years to cover what your spouse's tax liability would be should your spouse no longer work for a non-profit, as the forgiven amount on a similar plan would then become taxable. For the most efficient way to save, schedule a call with your FitBUX Coach.",
    both:
      "It makes sense for you and your spouse to pursue Public Student Loan Forgiveness (PSLF) for your Federal student loans and invest the money you would have otherwise used to pay off your loans. Although the amount forgiven via PSLF is not taxable, we recommend saving for 2-4 years to cover what your tax liabilities would be should your and your spouse no longer work for non-profits, as the forgiven amount on a similar plan would then become taxable. For the most efficient way to save, schedule a call with your FitBUX Coach.",
  },
  DI10: {
    applicant:
      "In your situation, it makes sense to pursue the Extended Fixed repayment plan for your Federal student loans. To make payments in the most efficient way, first extend the loans to 25 years to drop the required payment, then make prepayments to target the highest interest rate loan. If you decide to pursue this route, you may make payments directly from FitBUX and we’ll help to make sure your loan servicer applies the prepayment correctly. You’ll also want to look into refinancing your high interest rate student loans.",
    spouse:
      "In your spouse's situation, it makes sense for him/her to pursue the Extended Fixed repayment plan for your spouse's Federal student loans. To make payments in the most efficient way, first extend the loans to 25 years to drop the required payment, then make prepayments to target the highest interest rate loan. If your spouse decides to pursue this route, your spouse may make payments directly from FitBUX and we’ll help to make sure your spouse's loan servicer applies the prepayment correctly. Your spouse will also want to look into refinancing his/her high interest rate student loans.",
    both:
      "For you and your spouse, it makes sense for both of you to pursue the Extended Fixed repayment plan for your Federal student loans. To make payments in the most efficient way, first extend the loans to 25 years to drop the required payment, then make prepayments to target the highest interest rate loan. If you and your spouse decide to pursue this route, you and your spouse may make payments directly from FitBUX and we’ll help to make sure your loan servicers apply the prepayments correctly. You and your spouse will also want to look into refinancing your high interest rate student loans.",
  },
  DI11: {
    applicant:
      "In your situation, it makes sense to pursue the Standard 10 year repayment plan for your Federal student loans. Use prepayments to target your highest interest rate loan. If you decide to pursue this route, you may make payments directly from FitBUX and we’ll help to make sure your loan servicer applies the prepayment correctly.  You’ll also want to look into refinancing your high interest rate student loans.",
    spouse:
      "In your spouse's situation, it makes sense for him/her to pursue the Standard 10 year repayment plan for your spouse's Federal student loans. Use prepayments to target your spouse's highest interest rate loan. If your spouse decides to pursue this route, your spouse may make payments directly from FitBUX and we’ll help to make sure your spouse's loan servicer applies the prepayment correctly.  Your spouse will also want to look into refinancing your high interest rate student loans.",
    both:
      "For you and your spouse, it makes sense to pursue the Standard 10 year repayment plan for your Federal student loans. Use prepayments to target your highest interest rate loan. If you decide to pursue this route, you and your spouse may make payments directly from FitBUX and we’ll help to make sure your loan servicers apply the prepayments correctly.  You and your spouse will also want to look into refinancing your high interest rate student loans.",
  },
  LI1: {
    applicant:
      "Congratulations, you currently have the proper amount of life insurance.",
    spouse:
      "Congratulations, your spouse currently has the proper amount of life insurance.",
    both:
      "Congratulations, you and your spouse currently have the proper amount of life insurance.",
  },
  LI2: {
    applicant:
      "You currently don’t have enough life insurance coverage based on your overall situation.  When you implement this plan, we will recommend how much additional insurance your should purchase and how much you should expect the premiums to be.",
    spouse:
      "Your spouse currently does not have enough life insurance coverage based on you and your spouse's overall situation.  When you implement this plan, we will recommend how much additional life insurance your spouse should add and how much you should expect the premiums to be.",
    both:
      "You and your spouse currently do not have enough life insurance coverage based on your overall situation.  When you implement this plan, we will recommend how much life insurance you and your spouse should add and how much you should expect the premiums to be.",
  },
  NCF: highPrioritySummaryMessages.NCF,
  PP1: highPrioritySummaryMessages.PP1,
  PP9: highPrioritySummaryMessages.PP9,
  PP10: highPrioritySummaryMessages.PP10,
};

const payCheckMessages: payMessages = {
  EF: {
    house:
      "Based on your financial stability and sources of income, we recommend having [x] months in an emergency fund. ",
  },
  PP1: highPrioritySummaryMessages.PP1,
  PP2: {
    applicant:
      "We have allocated $50 per month to your Traditional IRA. You should talk to your FitBUX Coach and CPA about moving to a Roth IRA via a “back door conversion” as this may be beneficial from a tax perspective.  Right now, your available funds should first be used to pay off your debt aggressively. In the plan we’ve created, we’re increasing your retirement contributions once your debt is paid off.",
    spouse:
      "We have allocated $50 per month to your spouse's Traditional IRA. Your spouse should talk to your FitBUX Coach and CPA about moving to a Roth IRA via a “back door conversion” as this may be beneficial from a tax perspective.  Right now, your spouse's available funds should first be used to pay off his/her debt aggressively. In the plan we’ve created, we’re increasing your spouse's retirement contributions once his/her debt is paid off.",
    both:
      "We have allocated $50 per month to both you and your spouse's Traditional IRAs. You and your spouse should talk to your FitBUX Coach and CPA about moving to a Roth IRA via a “back door conversion” as this may be beneficial from a tax perspective.  Right now, you and your spouse's available funds should first be used to pay off your debts aggressively. In the plan we’ve created, we’re increasing your retirement contributions once your debts are paid off.",
  },
  PP3: {
    house:
      "Since you’re offered an HSA, we have allocated $50 monthly in your financial plan while you pay off your debt. Once your debt is paid off, we increase the amount going to your HSA account in your plan.",
  },
  PP4: {
    applicant:
      "We have allocated $50 per month to your Roth IRA and the rest of your funds are used to pay off your debt aggressively.  After your debt is paid off, we increase your contributions to your Roth IRA in the projections.",
    spouse:
      "We have allocated $50 per month to your spouse's Roth IRA and the rest of his/her funds are used to pay off his/her debt aggressively. After your spouse's debt is paid off, we increase his/her contributions to your spouse's Roth IRA in the projections.",
    both:
      "We have allocated $50 per month to you and your spouse's Roth IRAs and the rest of your funds are used to pay off your debts aggressively. After your debts are paid off, we increase your contributions to you and your spouse's Roth IRAs in the projections.",
  },
  PP5: {
    house:
      "We’ve optimized your plan such that you invest most of your available funds. Should you have debt, we’ve modeled only paying the required monthly payments.",
  },
  PP6: {
    applicant:
      "Your employer offers a Roth 401k as well as a match for retirement contributions. Since you have debt to pay off, our optimized plan only includes the minimum contribution(s) needed to get your match. Once your debt is paid off, we increase your contribution to your retirement account(s) in your plan. We will provide you with more details once your debt is paid off.",
    spouse:
      "Your spouse’s employer offers a Roth 401k as well as a match for retirement contributions. Since your spouse has debt to pay off, our optimized plan only includes the minimum contribution(s) needed to get his/her match. Once your spouse's debt is paid off, we increase his/her contribution to your spouse's retirement account(s) in your plan. We will provide you with more details once your spouse's debt is paid off.",
    both:
      "You and your spouse’s employers offer a Roth 401k as well as a match for retirement contributions. Since you have debts to pay off, our optimized plan only includes the minimum contribution(s) needed to get your matches. Once your debts are paid off, we increase your contributions to you and your spouse's retirement accounts in your plan. We will provide you with more details once your debts are paid off.",
  },
  PP7: {
    applicant:
      "Your employer offers a retirement account with a match. Therefore, we include the minimum amount you need to contribute to maximize your match. After you pay off your debt, we increase your contribution to your retirement account in the projections.",
    spouse:
      "Your spouse's employer offers a retirement account with a match. Therefore, we include the minimum amount your spouse will need to contribute to maximize your spouse's match. After your spouse pays off his/her debt, we increase your spouse's contribution to his/her retirement account in the projections.",
    both:
      "You and your spouse's employers offer retirement accounts with a match. Therefore, we include the minimum amount you and your spouse will need to contribute to maximize your matches. After you pay off your debts, we increase your contributions to you and your spouse's retirement accounts in the projections.",
  },
  PP8: {
    house:
      "To optimize your financial plan, you'll want to pay off your debt and then invest afterwards. Therefore, we assume you'll put minimal amounts to various investments and focus on repaying debt.",
  },
  PP9: highPrioritySummaryMessages.PP9,
  PP10: highPrioritySummaryMessages.PP10,
  NCF: highPrioritySummaryMessages.NCF,
  DI4: highPrioritySummaryMessages.DI4,
};
