import { useSelector } from "react-redux";

import { getPortfolioSettings } from "src/store/investments/selector";

const useData = () => {
  const portfolioSettings = useSelector(getPortfolioSettings);

  const hasBettermentAccount = !!portfolioSettings;

  const data = {
    answers: {
      heading:
        "Upgrade to Premium to view recommended allocations. Powered by FitBUX's A.I.",
      subscribedHeading: "Get personalized answers (powered by FitBUX A.I.).",
      items: [
        {
          iconName: "fa-sack-dollar",
          target: "/how-to-invest",
          subscribedTarget: "/investment-allocations",
          label: "How Should I Invest My Money?",
        },
        {
          iconName: "fa-sliders",
          target: "/how-to-adjust",
          subscribedTarget: "/tools/investment",
          label: "How Should I Adjust My Portfolio?",
        },
        {
          iconName: "fa-piggy-bank",
          target: "/emergency-fund-amount?return=investments",
          label: "Emergency Fund Optimization",
        },
      ],
      showSubscribeButton: true,
    },
    calculators: {
      heading: 'An easy way to "do the math" and compare strategies.',
      items: [
        {
          iconName: "fa-sack-dollar",
          target: "/cd-returns?return=investments",
          label: "Future Value of a CD",
        },
        {
          iconName: " fa-chart-mixed-up-circle-currency",
          target: "/investment-projections",
          label: "Investment Projections",
        },
      ],
    },
    products: {
      heading: "Products that help you analyze and implement your plan.",
      items: [
        {
          iconImage: "/assets/images/jpg/fbsquare.jpg",
          iconName: "fb-search",
          target: "/betterment/create",
          label: "Open Investment Account",
        },
      ],
    },
  };

  if (hasBettermentAccount) {
    data.products.items.push({
      iconImage: "/assets/images/png/bettermentlogo.png",
      iconName: "fb-search",
      target: "/betterment/edit",
      label: "View, Edit, Link My Betterment Account",
    });
  }

  return data;
};

export default useData;
