import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { map } from "lodash";

import BarChart from "src/components/BarChart";
import { ScoreRanks, SCORE_CATEGORIES } from "src/interfaces";
import { getScoreRanks } from "src/store/dashboard/selector";

interface ICategories {
  name: string;
  value: number;
}

const ComparisonChart = () => {
  const { applicant, average }: any = useSelector(getScoreRanks);
  const [categoryValue, setCategoryValue] = useState<keyof ScoreRanks>(
    "humanCapital"
  );
  const [categories, setCategories] = useState<ICategories[]>([]);

  useEffect(() => {
    if (applicant && average) {
      if (applicant.humanCapital && !categories.length) {
        setCategories([
          { name: "You", value: applicant.humanCapital },
          { name: "Others", value: average.humanCapital },
        ]);
      }
    }
  }, [applicant]);

  const onChange = (event: React.ChangeEvent<any>) => {
    const value = event.target.value as keyof ScoreRanks;
    setCategoryValue(value);
    setCategories([
      { name: "You", value: applicant[value] },
      { name: "Others", value: average[value] },
    ]);
  };

  return (
    <BarChart
      noNegative
      formatter={(n) => n.toFixed(0)}
      isDropdown
      hideTooltip
      value={categoryValue}
      onChange={onChange}
      list={map(SCORE_CATEGORIES, (label: string, value: string) => ({
        label,
        value,
      }))}
      labelYOffset={20}
      dataSource={categories}
      title="Your Score vs. Other Members"
      height="h-64 pb-5"
      xDomain={100}
    />
  );
};

export default ComparisonChart;
