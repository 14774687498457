import { createAction } from "redux-actions";
import { START } from "../common";

import { SubscriptionPayload } from "../../interfaces/stripe.interface";

export const GET_PUBLIC_KEY = "GET_PUBLICK_KEY";
export const GET_PURCHASE_PLANS = "GET_PURCHASE_PLANS";
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const GET_COUPONS = "GET_COUPONS";
export const GET_ALL_PURCHASE_DATA = "GET_ALL_PURCHASE_DATA";
export const GET_MONEY_SCHOOL_PURCHASE_DATA = "GET_MONEY_SCHOOL_PURCHASE_DATA";

export const getPublicKey = createAction(GET_PUBLIC_KEY + START);
export const getPurchasePlans = createAction(GET_PURCHASE_PLANS + START);
export const getSubscriptions = createAction(GET_SUBSCRIPTIONS + START);
export const deleteSubscription = createAction<string>(
  DELETE_SUBSCRIPTION + START
);
export const createSubscription = createAction<SubscriptionPayload>(
  CREATE_SUBSCRIPTION + START
);
export const getCoupons = createAction<string>(GET_COUPONS + START);
export const getAllPurchaseData = createAction(GET_ALL_PURCHASE_DATA + START);
export const getMoneySchoolPurchaseData = createAction(
  GET_MONEY_SCHOOL_PURCHASE_DATA + START
);
