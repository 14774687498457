import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import {
//   makeStyles,
// } from "@material-ui/core";

import { formatAnnually, formatMonthly, formatPercent } from "src/utils";

import {
  addCashflow,
  editCashflow,
  removeCashflow,
  replaceCashflow,
} from "src/store/cashflow/actions";
import { getRiskCashflows } from "src/store/cashflow/selector";
import {
  addRisk,
  editRisk,
  removeRisk,
  savePlan,
  setBuildStep,
  setPlanDirty,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  currentPlanIncomeTotal,
  currentPlanRiskManagementTotal,
  getCurrentPlanCashflows,
  getPlanIsDirty,
  isCurrentPlanImplemented,
  livePlanRiskManagementTotal,
} from "src/store/planBuild/selector";
import {
  RISK_MANAGEMENT_TYPES,
  IncomeExpenses,
  MappedCashflow,
  PlanViewComponent,
} from "src/interfaces";
import CenterContainer from "../../Components/CenterContainer";
import BasicCard from "src/components/BasicCard";
import { riskBlowout } from "./blowoutContent";

const MainForm: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const { risks: planRisks }: IncomeExpenses = useSelector(
    getCurrentPlanCashflows
  );
  const cashflows: MappedCashflow[] = useSelector(getRiskCashflows);
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const dirty = useSelector(getPlanIsDirty);
  const planTotal = useSelector(currentPlanRiskManagementTotal);
  const liveTotal = useSelector(livePlanRiskManagementTotal);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const [newItem, setNewItem] = useState<any>(null);

  const total = currentPlanIsImplemented ? liveTotal : planTotal;

  const displayItems = useMemo(() => {
    if (!currentPlanIsImplemented) {
      return planRisks;
    }
    const items = cashflows.map((item) => ({
      ...item,
      percent: ((item.annual || 0) / totalIncome) * 100,
      typeLabel: RISK_MANAGEMENT_TYPES[item.type],
    }));
    if (newItem) {
      items.push(newItem);
    }
    return items;
  }, [cashflows, currentPlanIsImplemented, newItem, planRisks]);

  const saveItem = (
    item: MappedCashflow,
    updateFields: Partial<MappedCashflow>,
    index: number
  ) => {
    if (!currentPlanIsImplemented) {
      const updatePayload = { ...item, ...updateFields };
      const update = {
        payment: Math.abs(updatePayload.annual || 0),
        type: updatePayload.type,
      };
      dispatch(editRisk({ index, update }));
      dispatch(savePlan(null));
    } else {
      if (item.id >= 0) {
        if (updateFields.type && item.type !== updateFields.type) {
          const replacePayload: any = {
            id: item.id,
            newItem: {
              type: updateFields.type,
              amount: Math.abs(updateFields.annual || 0),
              who: "applicant",
            },
          };
          return dispatch(replaceCashflow(replacePayload));
        }
        const payload = {
          id: item.id,
          amount: Math.abs(updateFields.annual || 0),
        };
        return dispatch(editCashflow(payload));
      }
      const cashflow: any = {
        type: newItem.type,
        amount: Math.abs(updateFields.annual || 0),
      };
      if (updateFields.whose === "spouse") {
        cashflow.who = "spouse";
      } else {
        cashflow.who = "applicant";
      }
      const payload = {
        cashflow,
        temporaryId: item.id,
      };
      // refreshScore();
      dispatch(addCashflow(payload));
      setNewItem(null);
    }
  };

  const onNext = () => {
    if (!currentPlanIsImplemented && dirty) {
      dispatch(savePlan(PLAN_BUILD_STEPS.SAVINGS));
    } else {
      dispatch(setBuildStep(PLAN_BUILD_STEPS.SAVINGS));
    }
  };

  const availableItems = RISK_MANAGEMENT_TYPES;

  const addItem = (label: string, key: string) => {
    if (currentPlanIsImplemented) {
      setNewItem({
        type: key,
        annual: 0,
        monthly: 0,
        percent: 0,
        payment: 0,
        typeLabel: RISK_MANAGEMENT_TYPES[key],
      });
    } else {
      dispatch(
        addRisk({
          type: key,
          payment: 0,
        })
      );
    }
  };

  const removeItem = (item: MappedCashflow, index: number) => {
    if (!currentPlanIsImplemented) {
      dispatch(removeRisk(index));
    } else if (index === cashflows.length) {
      setNewItem(null);
    } else {
      dispatch(removeCashflow(item.id));
    }
  };

  return render({
    component: (
      <CenterContainer
        title="Risk Management"
        iconName="fb-manage-protection"
        help={riskBlowout}
        scrollable
      >
        <BasicCard
          addLabel="Add Insurance &amp; Premium Payments"
          hideTitle
          title="Insurance"
          columns={[
            { label: "Type", field: "type", type: "fixed", width: "30%" },
            {
              label: "% of Total Income",
              field: "percent",
              type: "percent",
              width: "30%",
              formatter: formatPercent,
            },
            {
              label: "Monthly",
              field: "monthly",
              type: "number",
              width: "20%",
              formatter: formatMonthly,
            },
            {
              label: "Annual",
              field: "annual",
              type: "number",
              width: "20%",
              formatter: formatAnnually,
            },
          ]}
          data={displayItems}
          addItems={availableItems}
          onAddFromSelect={addItem}
          onDelete={removeItem}
          onSave={saveItem}
          className="p-0 shadow-none"
          total={totalIncome}
          summaryRow={
            !displayItems.length
              ? undefined
              : {
                  type: "Total",
                  monthly: Math.round(total / 12),
                  annual: total,
                  percent: (total / totalIncome) * 100,
                }
          }
          setDirtyFlag={() => dispatch(setPlanDirty(true))}
        />
      </CenterContainer>
    ),
    onNext,
    nextLabel: "Next Section",
  });
};

export default MainForm;
