import React from "react";

import { map, omit } from "lodash";

import {
  Box,
  Divider,
  FormLabel,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Icon from "src/components/Icon";
import { Breakout, INCOME_BREAKOUTS } from "src/interfaces";
import { colors } from "src/theme";
import { DollarTextField } from "src/utils";
import BreakoutSummary from "./BreakoutSummary";

const useStyles = makeStyles({
  button: {
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
  columnHeader: {
    color: colors.brandingBlue1,
    fontSize: 12,
    fontWeight: 500,
  },
});

const InputIncome = ({
  formValues,
  handleChange,
  removeBreakout,
  transaction,
}: any) => {
  const classes = useStyles();
  const incomes = formValues.incomes || [];
  const addItem = () => {
    const newIncomes = [
      ...incomes,
      { type: "", amount: "", id: Math.random() },
    ];
    handleChange({ target: { name: "incomes", value: newIncomes } });
  };

  const handleChangeItemType = (e: React.ChangeEvent<any>, index: number) => {
    const value = e.target.value;
    const newIncomes = [...formValues.incomes];
    newIncomes[index] = {
      ...newIncomes[index],
      type: value,
      amount: newIncomes[index].amount,
    };
    handleChange({ target: { name: "incomes", value: newIncomes } });
  };

  const handleChangeAmount = (e: React.ChangeEvent<any>, index: number) => {
    const value = e.target.value;
    const newIncomes = [...formValues.incomes];
    newIncomes[index] = {
      ...newIncomes[index],
      type: newIncomes[index].type,
      amount: value,
    };
    handleChange({ target: { name: "incomes", value: newIncomes } });
  };

  const displayType = formValues?.type || transaction?.type || "";
  let availableBreakouts: any = INCOME_BREAKOUTS;
  if (+displayType === 7002) {
    availableBreakouts = { "1008": INCOME_BREAKOUTS["1008"] };
  } else if (+displayType === 7003) {
    availableBreakouts = { "1010": INCOME_BREAKOUTS["1010"] };
  } else {
    availableBreakouts = omit(availableBreakouts, ["1008", "1010"]);
  }

  return (
    <form>
      <FormLabel component="legend" className="mb-6">
        Please enter the Total Income amount for this transaction (i.e. the
        amount before any deductions).
      </FormLabel>
      <Typography variant="h4" className={classes.columnHeader}>
        Income Type
      </Typography>
      {incomes.map((item: Breakout, index: number) => (
        <Box className="flex justify-between items-center" key={item.id}>
          <Select
            className="my-2 mr-2 w-3/4"
            fullWidth
            name="type"
            variant="outlined"
            displayEmpty
            renderValue={() =>
              INCOME_BREAKOUTS[
                (item.type + "") as keyof typeof INCOME_BREAKOUTS
              ] || "Income Type"
            }
            value={item.type}
            onChange={(e) => handleChangeItemType(e, index)}
          >
            {map(availableBreakouts, (label, key) => (
              <MenuItem key={key} value={+key}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <DollarTextField
            inputProps={{ style: { textAlign: "right" } }}
            variant="outlined"
            onChange={(e: any) => handleChangeAmount(e, index)}
            style={{ width: "150px" }}
            value={Math.abs(item.amount)}
          />
          <IconButton onClick={() => removeBreakout("incomes", index)}>
            <Icon iconName="fb-trash-can" />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="outlined"
        fbColor="primary"
        onClick={addItem}
        className={`w-full mt-2 ${classes.button}`}
        endIcon={<Icon iconName="fb-add-alt" />}
      >
        Add Income
      </Button>
      <Divider className="my-4" />
      <BreakoutSummary values={formValues} transaction={transaction} />
    </form>
  );
};

export default InputIncome;
