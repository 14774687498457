import React from "react";
import { Grid } from "@material-ui/core";

import { SolutionCard } from "../Components/Card";
import { cardsData } from "../data";
import Button from "src/components/Button";
import { AdapterLink } from "src/utils";

export const Cards = ({ setTool }: any) => {
  return (
    <Grid container spacing={2}>
      {cardsData &&
        cardsData.map(({ heading, description, button, icons }: any) => {
          return (
            <Grid
              item
              xs={12}
              md={6}
              className="flex justify-start items-center"
            >
              <SolutionCard
                heading={heading}
                description={description}
                icons={icons}
                Button={[
                  <Button
                    fbColor="primary"
                    onClick={() => setTool(button.value)}
                  >
                    {button.text}
                  </Button>,
                ]}
              />
            </Grid>
          );
        })}

      <Grid item xs={12} md={6} className="flex justify-start items-center">
        <SolutionCard
          heading="How Much Home Can I Afford?"
          // icons={}
          Button={[
            <Button
              fbColor="primary"
              component={AdapterLink}
              to="/homeaffordability"
            >
              Analyze
            </Button>,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={6} className="flex justify-start items-center">
        <SolutionCard
          heading="Mortgage Repayment Calculator"
          Button={[
            <Button
              fbColor="primary"
              component={AdapterLink}
              to="/mortgage-payment"
            >
              Analyze
            </Button>,
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6} className="flex justify-start items-center">
        <SolutionCard
          heading="Mortgage Points Calculator"
          // icons={}
          Button={[
            <Button
              fbColor="primary"
              component={AdapterLink}
              to="/points-calculator"
            >
              Analyze
            </Button>,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={6} className="flex justify-start items-center">
        <SolutionCard
          heading="Property Tax Estimator"
          Button={[
            <Button
              fbColor="primary"
              component={AdapterLink}
              to="/property-tax-estimate"
            >
              Analyze
            </Button>,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={6} className="flex justify-start items-center">
        <SolutionCard
          heading="What Would My Cost of Living Be?"
          Button={[
            <Button
              fbColor="primary"
              component={AdapterLink}
              to="/cost-of-living"
            >
              Analyze
            </Button>,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={6} className="flex justify-start items-center">
        <SolutionCard
          heading="Emergency Fund Optimization"
          Button={[
            <Button
              fbColor="primary"
              component={AdapterLink}
              to="/emergency-fund-amount"
            >
              Analyze
            </Button>,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={6} className="flex justify-start items-center">
        <SolutionCard
          heading="Should I Rent vs. Buy?"
          Button={[
            <Button fbColor="primary" component={AdapterLink} to="/rent-vs-buy">
              Analyze
            </Button>,
          ]}
        />
      </Grid>
    </Grid>
  );
};
