import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { Box, makeStyles } from "@material-ui/core";

import BasicCard from "src/components/BasicCard";
import Button from "src/components/Button";
import TitleCard from "src/components/TitleCard";
import {
  APPLICANT_WHO_TYPES,
  EARNED_INCOME_TYPES,
  MappedCashflow,
  WHO_TYPES,
} from "src/interfaces";
import { getIsMarried } from "src/store/system/selector";
import { formatAnnually, formatMonthly } from "src/utils";

interface IncomeStepProps {
  goBack(): void;
  goForward(): void;
  newIncome: any;
  setNewIncome(arg: any): void;
  confirmedIncomes: any[];
  setConfirmedIncomes(arg: any): void;
}

const IncomeStep = ({
  goBack,
  goForward,
  newIncome,
  setNewIncome,
  confirmedIncomes,
  setConfirmedIncomes,
}: IncomeStepProps) => {
  const classes = useStyles();
  const isMarried = useSelector(getIsMarried);
  const [waitForUpdate, setWaitForUpdate] = useState(false);

  useEffect(() => {
    if (waitForUpdate) {
      goForward();
    }
  }, [waitForUpdate]);

  const saveEarning = (
    item: MappedCashflow,
    updateFields: MappedCashflow,
    index: number
  ) => {
    setNewIncome(updateFields);
    setNewIncome(null);
    const typeLabel = updateFields.type
      ? EARNED_INCOME_TYPES[updateFields.type]
      : "Salary";
    const who = updateFields.whose || "applicant";
    const whoseLabel =
      who === "spouse"
        ? APPLICANT_WHO_TYPES[who]
        : APPLICANT_WHO_TYPES["applicant"];
    const updatedIncomes = [...confirmedIncomes];
    if (item) {
      updatedIncomes.splice(index, 1, {
        ...item,
        ...updateFields,
        who,
        typeLabel,
        whoseLabel,
        amount: updateFields.annual || 0,
      });
    }
    setConfirmedIncomes(updatedIncomes);
  };

  const getIncomeColumns = () => {
    if (isMarried) {
      return [
        {
          label: "Type",
          field: "type",
          type: "select",
          width: "30%",
          items: EARNED_INCOME_TYPES,
        },
        {
          label: "Owner",
          field: "whose",
          type: "select",
          width: "20%",
          items: WHO_TYPES,
        },
        {
          label: "Monthly",
          field: "monthly",
          type: "number",
          width: "20%",
          formatter: formatMonthly,
        },
        {
          label: "Annual",
          field: "annual",
          type: "number",
          width: "20%",
          formatter: formatAnnually,
        },
      ];
    } else {
      return [
        {
          label: "Type",
          field: "type",
          type: "select",
          width: "40%",
          items: EARNED_INCOME_TYPES,
        },
        {
          label: "Monthly",
          field: "monthly",
          type: "number",
          width: "30%",
          formatter: formatMonthly,
        },
        {
          label: "Annual",
          field: "annual",
          type: "number",
          width: "30%",
          formatter: formatAnnually,
        },
      ];
    }
  };

  const showIncome: any[] = useMemo(() => {
    const result = [...confirmedIncomes];
    if (newIncome) {
      result.push(newIncome);
    }
    return result;
  }, [confirmedIncomes, newIncome]);

  const defaultNewIncome = {
    earning: 0,
    type: "",
    whose: "",
  };

  const addNewIncome = () => setNewIncome(defaultNewIncome);

  const removeIncome = (item: any, index: number) => {
    if (index >= confirmedIncomes.length) {
      setNewIncome(null);
    }
    const newIncome = confirmedIncomes.filter((entry, i) => i !== index);
    setConfirmedIncomes(newIncome);
  };

  const total = useMemo(
    () => showIncome.reduce((result, item) => result + item.annual, 0),
    [showIncome]
  );

  const next = () => {
    setWaitForUpdate(true);
  };

  return (
    <>
      <TitleCard title="Confirm Your Income">
        <BasicCard
          // iconName="fb-money"
          title=""
          hideTitle={true}
          columns={getIncomeColumns()}
          data={showIncome}
          allowSelect
          noContainer
          // saveOnChange
          className="noContainer"
          // defaultNewItem={defaultNewIncome}
          saveOnChange
          onAdd={addNewIncome}
          onCancelEdit={() => setNewIncome(null)}
          onDelete={removeIncome}
          onSave={saveEarning}
          summaryRow={
            !showIncome.length
              ? undefined
              : {
                  type: "Total",
                  annual: total,
                  monthly: Math.round(total / 12),
                }
          }
        />
      </TitleCard>
      <Box className={classes.transitions}>
        <Button onClick={() => goBack()}>Back</Button>
        <Button onClick={next} color="primary" disabled={total <= 0}>
          Next
        </Button>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  transitions: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
    gap: "20px",
  },
}));

export default IncomeStep;
