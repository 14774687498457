import React from "react";

import { Box, makeStyles, Link } from "@material-ui/core";

import Button from "src/components/Button";

const useStyles = makeStyles({
  buttonContainer: {
    width: 500,
    margin: "48px auto",
  },
  bigButton: {
    display: "block",
    height: 80,
    marginBottom: 20,
    width: 500,
  },
});

const ReturningUser = ({ next }: any) => {
  const styles = useStyles();

  return (
    <>
      <Box className={styles.buttonContainer}>
        <Button
          variant="outlined"
          fbColor="primary"
          className={styles.bigButton}
          onClick={() => next("pay")}
        >
          Make A Payment or Manage Student Loans
        </Button>
        <Link href="#" onClick={() => next("link")}>
          +Link Another Student Loan Account
        </Link>
      </Box>
    </>
  );
};

export default ReturningUser;
