import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  // Divider,
  IconButton,
  // InputAdornment,
  makeStyles,
  // TextField,
  // Typography,
} from "@material-ui/core";

import CustomDialog from "src/components/Dialogs/CustomDialog";
import Icon from "src/components/Icon";
import { TutorialItem } from "src/interfaces";
import { clearTutorial } from "src/store/dashboard/actions";
import { getTutorial } from "src/store/dashboard/selector";

const useStyles = makeStyles({
  actions: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  image: {
    display: "block",
    maxHeight: "100%",
    maxWidth: "100%",
    margin: "0 auto",
  },
});

const Tutorial = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const tutorial: TutorialItem | null = useSelector(getTutorial);
  const [currentImage, setCurrentImage] = useState(0);

  const close = () => dispatch(clearTutorial());
  let images: string[] = [];
  if (tutorial) {
    if (tutorial.nImages) {
      for (let i = 0; i < tutorial.nImages; i++) {
        images.push(
          `assets/images/png/tutorials/${tutorial.name}/Screen-${i + 1}.jpg`
        );
      }
    } else if (tutorial.images) {
      images = tutorial.images;
    }
  }

  const next = () => {
    if (currentImage < images.length - 1) {
      setCurrentImage(currentImage + 1);
    } else {
      close();
    }
  };

  return (
    <CustomDialog
      onClose={close}
      isOpen={!!tutorial}
      size="xl"
      title={tutorial?.topic || ""}
    >
      {!!images.length && (
        <Box style={{ minHeight: 530 }}>
          <img src={images[currentImage]} className={styles.image} />
        </Box>
      )}
      <Box className={styles.actions}>
        <IconButton
          color="primary"
          onClick={() => setCurrentImage((n) => n - 1)}
          disabled={!currentImage}
        >
          <Icon iconName="fb-chevron-left" />
        </IconButton>
        <IconButton color="primary" onClick={next}>
          <Icon iconName="fb-chevron-right" />
        </IconButton>
      </Box>
    </CustomDialog>
  );
};

export default Tutorial;
