export enum PROFILE_BUILD_STEPS {
  WELCOME = "WELCOME",
  PERSONAL_INFO = "PERSONAL_INFO",
  MY_EDUCATION = "MY_EDUCATION",
  SPOUSE_EDUCATION = "SPOUSE_EDUCATION",
  INCOME_EXPENSES_INTRO = "INCOME_EXPENSES_INTRO",
  MY_INCOME = "MY_INCOME",
  SPOUSE_INCOME = "SPOUSE_INCOME",
  EXPENSES = "EXPENSES",
  DEBTS = "DEBTS",
  ASSETS = "ASSETS",
  HUMAN_CAPITAL_INTRO = "HUMAN_CAPITAL_INTRO",
  MY_HUMAN_CAPITAL_1 = "MY_HUMAN_CAPITAL_1",
  SPOUSE_HUMAN_CAPITAL_1 = "SPOUSE_HUMAN_CAPITAL_1",
  MY_HUMAN_CAPITAL_2 = "MY_HUMAN_CAPITAL_2",
  SPOUSE_HUMAN_CAPITAL_2 = "SPOUSE_HUMAN_CAPITAL_2",
  MY_HUMAN_CAPITAL_3 = "MY_HUMAN_CAPITAL_3",
  SPOUSE_HUMAN_CAPITAL_3 = "SPOUSE_HUMAN_CAPITAL_3",
  MY_HUMAN_CAPITAL_4 = "MY_HUMAN_CAPITAL_4",
  SPOUSE_HUMAN_CAPITAL_4 = "SPOUSE_HUMAN_CAPITAL_4",
}

// export const PROFILE_PROGRESS = [
//   PROFILE_BUILD_STEPS.WELCOME,
//   PROFILE_BUILD_STEPS.PERSONAL_INFO,
//   PROFILE_BUILD_STEPS.MY_EDUCATION,
//   PROFILE_BUILD_STEPS.SPOUSE_EDUCATION,
//   PROFILE_BUILD_STEPS.INCOME_EXPENSES_INTRO,
//   PROFILE_BUILD_STEPS.MY_INCOME,
//   PROFILE_BUILD_STEPS.SPOUSE_INCOME,
//   PROFILE_BUILD_STEPS.EXPENSES,
//   PROFILE_BUILD_STEPS.DEBTS,
//   PROFILE_BUILD_STEPS.ASSETS,
//   PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO,
//   PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_1,
//   PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_1,
//   PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_2,
//   PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_2,
//   PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_3,
//   PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_3,
//   PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_4,
//   PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_4,
// ];

export const PROFILE_PROGRESS = [
  PROFILE_BUILD_STEPS.WELCOME,
  PROFILE_BUILD_STEPS.PERSONAL_INFO,
  PROFILE_BUILD_STEPS.MY_EDUCATION,
  PROFILE_BUILD_STEPS.MY_INCOME,
  PROFILE_BUILD_STEPS.EXPENSES,
  PROFILE_BUILD_STEPS.DEBTS,
  PROFILE_BUILD_STEPS.ASSETS,
  PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO,
];

export enum PROFILE_TYPE {
  APPLICANT = "applicant",
  SPOUSE = "spouse",
}

export enum SCHOOL_LEVEL {
  ASSOCIATES = 2,
  UNDERGRAD = 3,
  ADV = 5,
  PHD = 6,
}

export const HIGHEST_LEVEL_EDUCATIONS = [
  "Attending Or Completed Ph.D",
  "Master's Certificate",
  "Completed Bachelor's Degree",
  "Completed Associate's Degree",
];

export enum EDIT_EDU_LEVEL { // HIGHEST_LEVEL_EDUCATIONS
  INIT = -1 /* PREPARING STEP */,
  PHD = 6,
  MASTER = 5,
  BS = 3,
  AA = 2,
}

export enum DB_EDU_LEVEL /* related to DB */ {
  C4 = 0, // Currently attending a 4-year college
  C4_DROP = 1, // Attended some years at a 4-year college
  C4_AA = 2, // Finished Associate's degree, attended some years at a 4-year college
  AA = 3, // Completed Associate's degree
  HS1 = 4, // Completed High School degree
  HS2 = 5, // Some High School
}

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const ADIDS = [
  { id: 3, name: "Business (MBA)", has_specialty: true },
  { id: 4, name: "Law (JD)", has_specialty: true },
  { id: 5, name: "Engineering (Master's)", has_specialty: true },
  { id: 7, name: "Medical Doctor (MD/DO)", has_specialty: true },
  { id: 9, name: "Nursing (Master's)", has_specialty: true },
  { id: 11, name: "Doctor of Physical Therapy (DPT)", has_specialty: false },
  { id: 12, name: "Dental (DDS/DDM)", has_specialty: false },
  {
    id: 13,
    name: "Master of Occupational Therapy (MOT)",
    has_specialty: false,
  },
  { id: 18, name: "Physician's Assistant (PA)", has_specialty: false },
  { id: 21, name: "Pharmacy", has_specialty: false },
  {
    id: 23,
    name: "Doctor of Occupational Therapy (OTD)",
    has_specialty: false,
  },
  { id: 999, name: "Other", has_specialty: false },
];

export const PHYSICAL_THERAPY_ID = "29-1123";

export enum LANGUAGE_LEVEL {
  BEGINNER = "1",
  PROFICIENT = "2",
  FLUENT = "3",
}

export const LANGUAGE_LEVEL_ARRAY = [
  "unknown",
  "beginner",
  "proficient",
  "fluent",
];
