import { createSelector } from "reselect";
import { AppState } from "..";

export const getProfileBuildState = (state: AppState) => state.oldProfileBuild;

export const getCurrentStep = createSelector(
  getProfileBuildState,
  (state: any) => state.currentStep
);
export const getSchools = createSelector(
  getProfileBuildState,
  (state: any) => state.schools
);
export const getLeveledSchools = createSelector(
  getProfileBuildState,
  (state: any) => state.leveledSchools
);
export const getSpecialties = createSelector(
  getProfileBuildState,
  (state: any) => state.specialties
);
export const getFields = createSelector(
  getProfileBuildState,
  (state: any) => state.fields
);
export const getProfessions = createSelector(
  getProfileBuildState,
  (state: any) => state.professions
);
export const getEmployments = createSelector(
  getProfileBuildState,
  (state: any) => state.employments
);
export const getFellowships = createSelector(
  getProfileBuildState,
  (state: any) => state.fellowships
);
export const getResidencies = createSelector(
  getProfileBuildState,
  (state: any) => state.residencies
);
export const getDesignations = createSelector(
  getProfileBuildState,
  (state: any) => state.designations
);
