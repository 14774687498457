import React from "react";

import { RentVsBuyProvider } from "./UseRentVsBuy";
import Content from "./Content";

const RentVsBuy = () => {
  return (
    <RentVsBuyProvider>
      <Content />
    </RentVsBuyProvider>
  );
};

export default RentVsBuy;
