import React from "react";
import { useSelector } from "react-redux";

import { Box, makeStyles, Paper, Typography } from "@material-ui/core";

import { getMinFutureProjections } from "src/store/planBuild/selector";
import colors from "src/theme/colors";
import HelpToggle from "src/components/HelpToggle";
import { SidebarContent } from "src/interfaces";
import { getProfile } from "src/store/profileBuild/selector";

const useStyles = makeStyles({
  root: {
    // position: "fixed",
    // zIndex: 1,
  },
  closed: {
    bottom: 80,
    right: 20,
    width: "190px",
    padding: 10,
    cursor: "pointer",
  },
  open: {
    // right: 40,
    // bottom: 260,
    // width: "45%",
    width: "100%",
  },
  content: {
    padding: "20px",
  },
  subheading: {
    fontSize: 13,
    color: colors.brandingBlue2,
    fontWeight: 600,
    marginTop: "10px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 14,
    color: colors.brandingBlue1,
    fontWeight: 600,
  },
});

const blowOutContent: SidebarContent[] = [
  {
    header: "Your FitBUX Score",
    body: [
      "Your FitBUX Score combines your income, expenses, assets, debts, human capital, etc. into one easy to understand data point.  It provides a snapshot of your complete financial picture today as well as in the future.",
      "Once you implement your financial plan, tracking your Score makes it easy to visualize whether you are folllowing your plan. Your Score variations make it easy to outline recommended actions and adjustments if needed.",
    ],
  },
];

const ScoreFactors = () => {
  const styles = useStyles();
  const future = useSelector(getMinFutureProjections);
  // [ now, 5 years, 10 years, retirement ]
  const RETIREMENT_INDEX = 3;
  const retirementScore =
    future.score.length > RETIREMENT_INDEX
      ? future.score[RETIREMENT_INDEX].plan
      : 0;

  const profile = useSelector(getProfile);
  const dobYear = profile.dob_year;
  const retirementYear = Number(future.retirementYear);
  const retirementAge = retirementYear - dobYear;

  return (
    <Paper
      className={`${styles.open} ${styles.root}`}
      // onClick={isOpen ? undefined : toggleOpen}
    >
      <Box className={styles.content}>
        <Box className={styles.header}>
          <Box className="flex align-center">
            <Typography component="h3" variant="body1" className={styles.title}>
              Understanding Your Score
            </Typography>
            <HelpToggle content={blowOutContent} />
          </Box>
          {/*<span onClick={toggleOpen}>Hide</span>*/}
        </Box>
        <Typography variant="h6" className={styles.subheading}>
          Short-Term
        </Typography>
        {/* {scoreMessages.overTime.slice(0, 1).map((message) => (<Typography variant="body1">{message}</Typography>))} */}
        <Typography style={{ fontSize: "13px" }}>
          Your current FitBUX Score means that you have significant risk in your
          financial plan. By following this optimized plan, you can
          significantly reduce that risk over the next 10 years.
        </Typography>
        <Typography variant="h6" className={styles.subheading}>
          Long-Term
        </Typography>
        {/* <Typography variant="body1">{scoreMessages.retirement}</Typography> */}
        <Typography style={{ fontSize: "13px" }}>
          `If you implement and follow this plan, you’ll have, approximately,
          a(n) {retirementScore / 10}% probability of retiring at age{" "}
          {retirementAge} with enough money to last you through retirement.
        </Typography>
      </Box>
    </Paper>
  );
};

export default ScoreFactors;
