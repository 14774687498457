import { createSelector } from "reselect";
import { AppState } from "..";

export const selectStripeState = (state: AppState) => state.stripe;

export const selectPublicKey = createSelector(
  [selectStripeState],
  (state) => state.pubkey
);
export const selectPurchasePlans = createSelector(
  [selectStripeState],
  (state) => state.plans
);
export const selectCoupons = createSelector(
  [selectStripeState],
  (state) => state.coupons
);
export const selectSubscriptions = createSelector(
  [selectStripeState],
  (state) => state.subscriptions
);
export const selectPromo = createSelector(
  [selectStripeState],
  (state) => state.promo
);
export const selectPortal = createSelector(
  [selectStripeState],
  (state) => state.portal
);

export const selectStripeLoading = createSelector(
  [selectStripeState],
  (state) => state.loading
);
export const selectStripeLoaded = createSelector(
  [selectStripeState],
  (state) => state.loaded
);
export const selectStripeError = createSelector(
  [selectStripeState],
  (state) => state.error
);
