import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Container, IconButton, Typography } from "@material-ui/core";

import { PlanListRecord } from "src/interfaces";
import { getRefreshNeeded } from "src/store/dashboard/selector";
import {
  deletePlan,
  fetchLivePlanProjection,
  fetchSavedPlanProjections,
  implementPlan,
} from "src/store/planBuild/actions";
import {
  getDashboardNetProjection,
  getLivePlan,
  getLoadedSavedProjections,
  getLoadingSavedProjections,
  getPlans,
  getSavedProjectionsError,
  savedPlanProjectionData,
} from "src/store/planBuild/selector";
import { openPlanForEditing } from "src/store/planBuild/util";
import { getHasGraduated } from "src/store/profileBuild/selector";
import { deleteImplementedPlan } from "src/store/system/actions";
import { getIsCurrentStudent } from "src/store/system/selector";
import { planListText } from "src/text";

import Button from "src/components/Button";
import Chart from "src/components/Chart";
import Icon from "src/components/Icon";

const PlanList = () => {
  const dispatch = useDispatch();
  const loadedProjections = useSelector(getLoadedSavedProjections);
  const loadingProjections = useSelector(getLoadingSavedProjections);
  const projectionsError = useSelector(getSavedProjectionsError);
  const projections = useSelector(savedPlanProjectionData);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const plans = useSelector(getPlans);
  const livePlan = useSelector(getLivePlan);
  const refreshNeeded = useSelector(getRefreshNeeded);
  const hasGraduated = useSelector(getHasGraduated);
  const liveProjection = useSelector(getDashboardNetProjection);

  useEffect(() => {
    if (!loadingProjections) {
      if (
        (!loadedProjections && !projectionsError) ||
        refreshNeeded.liveProjection
      ) {
        dispatch(fetchLivePlanProjection(false));
      }
      if (
        (!loadedProjections && !projectionsError) ||
        refreshNeeded.savedProjections
      ) {
        dispatch(fetchSavedPlanProjections());
      }
    }
  }, [loadedProjections, loadingProjections, projectionsError]);

  const handleDeletePlan = (id: number) => {
    if (id === livePlan?.id) {
      dispatch(deleteImplementedPlan());
    }
    dispatch(deletePlan(id));
  };

  return (
    <Container className="p-10 pr-0 mr-0">
      <Box className="w-full">
        <Typography variant="h2" component="h2" className="mb-3">
          Your FitBUX Plan
        </Typography>
        <Typography component="p" className="my-6" variant="body2">
          {planListText}
        </Typography>
        {/*plans.length < 3 && !hasGraduated && (
          <Button
            className="mr-4"
            fbColor="primary"
            component={AdapterLink}
            to="/plan-builder"
            onClick={loadDefaultPlan}
          >
            Build a New Plan
          </Button>
        )}
        {hasGraduated && (
          <Button className="mr-4" fbColor="primary" onClick={openGradDialog}>
            Build a New Plan
          </Button>
        )}
        {/*plans.length >= 1 && (
          <Button
            disabled={!compareReady}
            fbColor="secondary"
            to="/plan-builder"
            onClick={launchCompare}
          >
            Compare Plans
          </Button>
        )*/}
      </Box>
      <Box className="flex mt-24">
        {plans.map((plan: PlanListRecord, i: number) => {
          const projection = plan.implemented ? liveProjection : projections[i];
          // hide if plan has messages and the questionnaire is dropped;
          return (
            <Box key={plan.id} className="mr-6">
              <Chart
                // isLocked={disabled}
                dollars
                actions={
                  <Box className="flex justify-between items-center w-10/12 my-4 mx-auto">
                    <Button
                      disabled={isCurrentStudent && hasGraduated}
                      style={{ display: "block", textAlign: "center" }}
                      fbColor="secondary"
                      onClick={() =>
                        openPlanForEditing(dispatch, isCurrentStudent, plan)
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      disabled={
                        plan.implemented || (isCurrentStudent && hasGraduated)
                      }
                      style={{ display: "block", textAlign: "center" }}
                      fbColor="primary"
                      onClick={() => dispatch(implementPlan(plan.id))}
                    >
                      {plan.implemented ? "Implemented" : "Implement"}
                    </Button>
                    <IconButton onClick={() => handleDeletePlan(plan.id)}>
                      <Icon iconName="fb-trash-can" />
                    </IconButton>
                  </Box>
                }
                data={projection}
                loading={loadingProjections}
                title={plan.name}
                keys={["net"]}
                keyLabels={{
                  net: "Net Wealth",
                }}
                width={320}
                xKey="month"
              />
            </Box>
          );
        })}
      </Box>
    </Container>
  );
};

export default PlanList;
