import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

import { headerStyles } from "src/theme";

import DashboardLayout from "src/layouts/DashboardLayout";
import NoPlanSummary from "./NoPlanSummary";
import PlanSummary from "./PlanSummary";
import { getHasPlan } from "src/store/system/selector";

const PlanSummaryContainer = () => {
  const classes = useStyles();
  const hasPlan = useSelector(getHasPlan);

  return (
    <DashboardLayout pageTitle="Plans">
      <main className={classes.mainContent}>
        {hasPlan ? <PlanSummary /> : <NoPlanSummary />}
      </main>
    </DashboardLayout>
  );
};

const useStyles = makeStyles(headerStyles);

export default PlanSummaryContainer;
