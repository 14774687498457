import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { getHouseholdPerformanceApi } from "src/apiService";
import { getScore } from "src/store/dashboard/selector";
import {
  getPortfolioPerformance,
  getPortfolioSettings,
} from "src/store/investments/selector";
import { investmentsStyles } from "src/theme";
import { AdapterLink, scoreToAllocation } from "src/utils";
import RiskSlider from "./RiskSlider";
import AllocationTable from "../BettermentAccount/shared/AllocationTable";

const disclaimerText =
  "Disclaimer: FitBUX's recommended allocation is based on our proprietary models developed by FitBUX RIA LLC. Your current allocation details are based on the oldest common date in which your investments existed. If the oldest common date is less than 10 years, we replace your investments with less than 10 years of history with similar investments that are older than 10 years. FitBUX's recommended allocation is based on the completeness of your FitBUX Profile. Therefore, FitBUX is not liable for incomplete or non-updated profiles. FitBUX is also not liable for you implementing the recommended allocation or not implementing. Thus, implementing the recommended allocation is your responsibility. Your Current Allocation includes any cash/checking/savings accounts you currently have on FitBUX whereby the cash is not being used to save for a goal in your financial plan.";

const useStyles = makeStyles({
  ...investmentsStyles,
  instructions: {},
  instructionsList: {
    fontSize: 16,
    "& > li": { marginBottom: 10 },
  },
  accountRow: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: 20,
  },
  accountCard: {
    marginRight: 20,
    width: "calc(100% - 50px)",
  },
  buttonContainer: {
    marginTop: 40,
    textAlign: "center",
  },
  addButton: {
    width: 200,
    "& > span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
  blockButton: {
    display: "block",
    margin: "20px auto",
  },
  fixedBottom: {
    background: "white",
    display: "flex",
    position: "fixed",
    justifyContent: "space-between",
    height: 60,
    bottom: 0,
    left: 0,
    padding: "10px 40px",
    width: "100%",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
  },
  smallText: {
    fontSize: 11,
    marginTop: 30,
  },
  pageText: {
    fontSize: "14px",
    marginLeft: "21px",
  },
  disclaimer: {
    paddingBottom: "80px",
  },
});

export const RiskSummary = ({ goBack }: any) => {
  const classes = useStyles();
  const score = useSelector(getScore);
  const { expectedReturns, standardDeviations } = useSelector(
    getPortfolioPerformance
  );
  const portfolioSettings = useSelector(getPortfolioSettings);
  const [myPerformance, setMyPerformance] = useState({
    standard_deviation: 10,
    annual_return: 5,
  });
  const [error, setError] = useState(false);
  const fitbuxAllocation = scoreToAllocation(score);

  const fbStdDev = standardDeviations["" + fitbuxAllocation];
  const fbAvg = expectedReturns["" + fitbuxAllocation];
  const myStdDev = myPerformance.standard_deviation * 100;
  const myAvg = myPerformance.annual_return * 100;

  const tooAggressive = myStdDev > fbStdDev;

  useEffect(() => {
    getHouseholdPerformanceApi()
      .then((result: any) => setMyPerformance(result.inception))
      .catch(() => setError(true));
  }, []);

  const hasBettermentAccount = !!portfolioSettings;

  if (error) {
    return (
      <>
        <Box>
          <Typography paragraph>
            One or more of your investments do not have a long enough history
            for analysis.
          </Typography>
          <Typography paragraph>
            Our investment team has been notified and will make the appropriate
            adjustment to run the analysis.
          </Typography>
          <Typography paragraph>
            Come back and check this screen within 24 business hours and the
            analysis will be ready.
          </Typography>
          <Typography paragraph>Sorry for the inconvenience.</Typography>
        </Box>
        <Box className={classes.actions}>
          <Button fbColor="primary" onClick={goBack} className="ml-4">
            Close
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      <Card title="Ability To Take Risk" className="mb-5">
        <Box className="mt-5">
          <Typography className={classes.pageText} paragraph>
            Investment allocation is based on your ability to take risk and your
            willingness to take risk.
          </Typography>
          <Typography className={classes.pageText} paragraph>
            Based on your ability to take risk, you are too{" "}
            {tooAggressive ? "aggressively" : "conservatively"} invested.
          </Typography>
          <Typography className={classes.pageText} paragraph>
            To make your portfolio{" "}
            {tooAggressive
              ? "more conservative, invest more into bonds."
              : "more aggressive, invest more into equity investments."}
          </Typography>
        </Box>
      </Card>
      <Card title="Risk &amp; Return Summary" className="mb-5">
        <RiskSlider fitbuxStdDev={fbStdDev} myStdDev={myStdDev} />
        <AllocationTable
          myAllocationLabel="Current"
          myAvg={myAvg}
          fbAvg={fbAvg}
          myStdDev={myStdDev}
          fbStdDev={fbStdDev}
        />
        <Typography className={classes.smallText}>
          To see the impact of changing investments in your portfolio and/or to
          build a portfolio with different investments,{" "}
          <AdapterLink to="/tools/investment#step2">click here.</AdapterLink>
        </Typography>
      </Card>
      <Card title="Willingness To Take Risk">
        <Box className="mt-5">
          <Typography className={classes.pageText} paragraph>
            Willingess to take risk always takes precedence over the ability to
            take risk.
          </Typography>
          <Typography className={classes.pageText} paragraph>
            {tooAggressive
              ? "Therefore, we are saying you do not have the ability to take as much risk as you are currently taking. However, if you are willing to take on more risk, then leave your holdings as is."
              : "Based on your profile and your FitBUX Score,  you have the ability to take more risk. However, if you are more comfortable not taking more risk (i.e., your willingness to take risk is not as high), leave your holdings as-is."}
          </Typography>
        </Box>
      </Card>
      <Button className={classes.blockButton} onClick={goBack}>
        Back
      </Button>
      <Typography className={classes.disclaimer}>{disclaimerText}</Typography>
      <Box />
      {!hasBettermentAccount && (
        <Box className={classes.fixedBottom}>
          <Typography variant="h2" component="h2">
            Want FitBUX To Allocate Money For You? Open An Investment Account
            With Us Today!
          </Typography>
          <Button
            fbColor="primary"
            component={AdapterLink}
            to="/betterment/create"
          >
            Open
          </Button>
        </Box>
      )}
    </>
  );
};

export default RiskSummary;
