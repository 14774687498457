const INVESTMENT_RETURN = 5;

const POINTS_CALCULATOR_DISCLAIMER =
  "Disclaimer: This calculator assumes fixed interest rates under a straight-line amortization schedule.  It does not take into account other types of mortgages such as interest only or adjustable rates. All associated charts on this analysis are estimates and included for illustrative purposes only.  Nothing in this message constitutes investment advice.  Fitbux, Inc., its associates, employees, and officers shall not be liable for any loss or liability suffered by you resulting from your use of the information or your reliance in any way on the information. ";

enum STEP {
  INTRO_SCREEN,
  MORTGAGE_DETAILS,
  POINTS_INTEREST_RATE_DETAILS,
  RESULT_SCREEN,
}

const INTRO_SCREEN_HELP = [
  {
    header: `Points`,
    body: [
      `When securing a mortgage, you can "buy down" the interest rate by buying "points". `,
      `Buying points means paying a portion of your balance up front in exchange for a lower interest rate. Over time, this lower interest rate means that you'll end up paying less interest overall.`,
      `A point is equivalent to 1% (or 100 basis point) of your initial mortgage balance.`,
      `For example:`,
      `Let's say you're getting a $300,000 mortgage with a 5% interest rate.`,
      `The lender may offer you to buy 1 point to get a 4.5% interest rate instead of 5%.`,
      `In this case, you'd pay 1% of your initial balance upfront, $3,000 and get a 4.5% rate on your mortgage. `,
      `There are 3 different ways to calculate if buying points is worth it for a given offer, and if so, how many.`,
      `A key variable is the length of time you expect to keep the underlying property as this is have an impact on whether paying part of your mortgage upfront makes mathematical sense.  `,
      `In addition, points are considered prepaid interest and can be used as a tax write off over the course of your mortgage.`,
      `If you have more questions, about points or using this calculator, be sure to reach out to a FitBUX Coach.      `,
    ],
  },
];

const MORTGAGE_TERMS = [
  {
    label: "15 Years",
    value: 15,
  },
  {
    label: "20 Years",
    value: 20,
  },
  {
    label: "30 Years",
    value: 30,
  },
];

const TAB_VALUES = ["Break-Even Method", "Investment Methods"];

export {
  POINTS_CALCULATOR_DISCLAIMER,
  STEP,
  INTRO_SCREEN_HELP,
  MORTGAGE_TERMS,
  TAB_VALUES,
  INVESTMENT_RETURN,
};
