import React from "react";

import {
  Box,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Icon from "src/components/Icon";
import { Transaction } from "src/interfaces";
import { colors } from "src/theme";
import { formatDollarsAndCents } from "src/utils";

interface IncomeTransactionListProps {
  items: Transaction[];
  onSelect?: (item: Transaction) => void;
  openConfirmDialog: (item: Transaction) => void;
  selectedIds?: Set<number>;
  variableHeight?: boolean;
}

const useStyles = makeStyles((theme) => ({
  content: {
    height: 600,
    overflowY: "auto",
  },
  variableHeightContent: {
    height: "45vh",
    overflowY: "auto",
  },
  item: {
    alignItems: "center",
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    minHeight: 55,
    marginTop: 10,
    border: `1px solid ${colors.blueGray7}`,
    padding: `0 5px`,
    display: "flex",
    justifyContent: "space-between",
  },
  subtitle: {
    fontSize: 10,
    color: colors.blueGray2,
  },
  rightSide: {
    alignItems: "center",
    display: "flex",
  },
  amount: {
    color: colors.success,
    marginRight: 12,
  },
  confirmButton: {
    fontSize: 10,
    minWidth: 58,
    width: 58,
  },
}));

const IncomeTransactionList = ({
  items,
  onSelect,
  openConfirmDialog,
  selectedIds,
  variableHeight,
}: IncomeTransactionListProps) => {
  const styles = useStyles();
  return (
    <List
      className={variableHeight ? styles.variableHeightContent : styles.content}
    >
      {items.map((item) => (
        <ListItem className={styles.item} key={item.id}>
          <Box className="flex items-center">
            {!!onSelect && !!selectedIds && (
              <IconButton onClick={() => onSelect(item)}>
                <Icon
                  size={17}
                  variant={selectedIds.has(item.id) ? "fa-solid" : "fa-light"}
                  color={selectedIds.has(item.id) ? "primary" : undefined}
                  iconName="fa-circle-check"
                />
              </IconButton>
            )}
            <Box>
              <Typography variant="body1">{item.description}</Typography>
              <Typography className={styles.subtitle} variant="body2">
                {item.date}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.rightSide}>
            <Box className={styles.amount}>
              {item.amount >= 0 ? "-" : "+"}
              {formatDollarsAndCents(Math.abs(item.amount))}
            </Box>
            {!onSelect && (
              <Button
                className={styles.confirmButton}
                fbColor="primary"
                onClick={() => openConfirmDialog(item)}
              >
                Confirm
              </Button>
            )}
            {!!onSelect && (
              <IconButton onClick={() => openConfirmDialog(item)}>
                <Icon iconName="fa-pencil" />
              </IconButton>
            )}
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default IncomeTransactionList;
