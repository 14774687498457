import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  makeStyles,
  Typography,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import { profileBuilderStyles } from "src/theme";

import CenterContainer from "../../Components/CenterContainer";
import Button from "src/components/Button";
import {
  savePlan,
  updateAllocations,
  estimateCurrentPlanStudentTaxes,
} from "src/store/planBuild/actions";
import { getCurrentPlan } from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { Plan } from "src/interfaces/plan.interface";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import { DollarTextField } from "src/utils";
import { TAX_DEFERRED_INVESTMENT_TYPES } from "src/interfaces";

const useStyles = makeStyles(profileBuilderStyles);

const AddOrEditIRA: PlanViewComponent = ({
  initialValues,
  onClose,
  onSave,
  render,
  type,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const plan: Plan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const initialFormValues: any = {
    who: "applicant",
  };
  if (initialValues) {
    initialFormValues.who = initialValues.who;
    const itemIndex = isMarried && initialValues.who === "spouse" ? 1 : 0;
    const soloAllocation = plan.allocations[0].solo[itemIndex];
    initialFormValues.annual =
      soloAllocation[type as "ira_value" | "roth_ira_value"];
    initialFormValues.monthly = Math.round(initialFormValues.annual / 12);
  }
  const [formValues, setFormValues] = useState<any>(initialFormValues);

  const setFormValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    const newFormValues = { ...formValues, [field]: value };
    if (field === "annual") {
      newFormValues.monthly = Math.round(value / 12);
    } else if (field === "monthly") {
      newFormValues.annual = value * 12;
    }
    setFormValues(newFormValues);
  };
  const accountType =
    type === "roth_ira_value" ? "Roth IRA" : "Traditional IRA";

  const save = () => {
    const whoIndex = isMarried && formValues.who === "spouse" ? 1 : 0;
    const soloAllocations = plan.allocations[0].solo;
    const newSoloAllocations = [...soloAllocations];
    newSoloAllocations[whoIndex] = {
      ...soloAllocations[whoIndex],
      [type]: formValues.annual || 0,
    };
    if (initialValues && formValues.who !== initialValues.who) {
      const otherIndex = whoIndex ? 0 : 1;
      newSoloAllocations[otherIndex] = {
        ...newSoloAllocations[otherIndex],
        [type]: 0,
      };
    }
    let combinedContribution = formValues.annual;
    if (isMarried) {
      combinedContribution +=
        newSoloAllocations[1][type as "ira_value" | "roth_ira_value"] || 0;
    }
    const newAllocations = {
      ...plan.allocations[0],
      solo: newSoloAllocations,
      [type]: combinedContribution,
    };
    dispatch(updateAllocations(newAllocations));
    if (type in TAX_DEFERRED_INVESTMENT_TYPES) {
      dispatch(estimateCurrentPlanStudentTaxes());
    }
    dispatch(savePlan(null));
    onSave();
  };

  return render({
    component: (
      <CenterContainer title={accountType} iconName={"fb-model"}>
        {isMarried && (
          <Box className="mt-4">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-4">
                Whose contribution is this?
              </FormLabel>
              <Select
                variant="outlined"
                fullWidth
                value={formValues.who}
                name="who"
                placeholder="Select"
                onChange={setFormValue}
              >
                <MenuItem value="applicant">Mine</MenuItem>
                <MenuItem value="spouse">My spouse's</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
        <Box className="w-full flex font-bold mt-6 mb-2">
          <Box style={{ width: "40%" }} />
          <Box style={{ width: "30%" }}>Monthly</Box>
          <Box style={{ width: "30%" }}>Annually</Box>
        </Box>
        <Box className="w-full flex items-center">
          <Box className="font-bold" style={{ width: "40%" }}>
            {accountType} Contribution
          </Box>
          <Box style={{ width: "30%", paddingRight: 10 }}>
            <DollarTextField
              variant="outlined"
              fullWidth
              name="monthly"
              value={formValues.monthly || ""}
              placeholder="0"
              max={500}
              onChange={setFormValue}
            />
          </Box>
          <Box style={{ width: "30%", paddingTop: 19 }}>
            <DollarTextField
              variant="outlined"
              fullWidth
              name="annual"
              value={formValues.annual || ""}
              placeholder="0"
              max={500}
              onChange={setFormValue}
            />
            <Typography variant="body1">Max: $7,000</Typography>
          </Box>
        </Box>
        <Button
          variant="outlined"
          fbColor="primary"
          onClick={onClose}
          className={styles.btnCancel}
        >
          Cancel
        </Button>
      </CenterContainer>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default AddOrEditIRA;
