import React from "react";
import Card from "src/components/Card";
import Icon from "src/components/Icon";
import { makeStyles } from "@material-ui/core/styles";
import StatusIndicator from "src/components/StatusIndicator";
import colors from "src/theme/colors";
import clsx from "clsx";

interface ListProps {
  header: {
    name: string;
    value: number;
  };
  list: {
    name: string;
    value: number;
  }[];
  className?: string;
}

export const calculatePercentile = (
  value: number,
  excellentThreshold: number,
  averageThreshold: number
) => {
  if (value >= excellentThreshold) {
    return {
      title: "Excellent",
      fill: colors.success,
      face: "fb-face-satisfied",
    };
  }
  if (value >= averageThreshold && value < excellentThreshold) {
    return { title: "Average", fill: colors.warn, face: "fb-face-neutral" };
  }
  return { title: "Poor", fill: colors.error, face: "fb-face-poor" };
};

const ListScore = ({ list, header, className }: ListProps) => {
  const styles = useStyles();

  const headComponent = () => {
    const headPercentile = calculatePercentile(header.value, 70, 40);
    return (
      <div className={styles.headComponent}>
        <Icon iconName={headPercentile.face} />
        <h1>{headPercentile.title}</h1>
      </div>
    );
  };

  return (
    <Card title={header.name} headComponent={headComponent()}>
      <div className={clsx(className, "mt-6")}>
        {list.map(({ name, value }, index) => {
          const excellentThreshold = name === "Discretionary Income" ? 40 : 70;
          const averageThreshold = name === "Discretionary Income" ? 20 : 40;
          const { title, fill } =
            calculatePercentile(value, excellentThreshold, averageThreshold) ||
            {};
          return (
            <div
              className={clsx(
                styles.list,
                `${index === list.length - 1 && styles.noBorder}`
              )}
              key={name}
            >
              <p>{name}</p>
              <div className={styles.rightBox}>
                <StatusIndicator title={title} fill={fill} fontLight />
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};
const useStyles = makeStyles({
  container: {
    padding: "0px 30px",
  },
  headComponent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h1": {
      fontWeight: 600,
      color: colors.brandingBlue1,
      fontSize: 18,
      marginLeft: 10,
    },
    "& svg": {
      color: colors.brandingBlue1,
    },
  },
  list: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${colors.blueGray7}`,
  },
  rightBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& svg": {
      marginLeft: 15,
    },
  },
  noBorder: {
    border: 0,
  },
});
export default ListScore;
