import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { pick, range } from "lodash";
import DateFnsUtils from "@date-io/date-fns";

import {
  Box,
  makeStyles,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Button from "src/components/Button";
import CustomDialog from "src/components/Dialogs/CustomDialog";
import Icon from "src/components/Icon";
import Radio from "src/components/Radio";

import {
  reportActionItemApi,
  updateHouseholdApi,
  updateUserApi,
} from "src/apiService";
import { DAYS_IN_MONTH, MONTHS, STATES } from "src/constants";
import { fetchTaxes } from "src/store/cashflow/actions";
import {
  updateHouseholdSuccess,
  updatePersonalInfo,
  UpdatePersonalInfoPayload,
} from "src/store/profileBuild/actions";
import { getHousehold, getPersonalInfo } from "src/store/profileBuild/selector";
import { updateUser } from "src/store/system/actions";
import { getHasPlan, userSelector } from "src/store/system/selector";
import {
  PERSONAL_INFO_KEYS,
  DASHBOARD_HOUSEHOLD_KEYS,
} from "src/store/profileBuild/selectorKeys";
import { PersonalInfoSection, Household, User } from "src/interfaces";

import InformationCard from "../Components/InformationCard";
import ProfileInformationCard from "../Components/profileInformationCard";
import {
  calculateAge,
  formatZip,
  PhoneNumberInput,
  validateEmail,
  validateZip,
} from "src/utils";

interface MyFormValues extends PersonalInfoSection, User, Partial<Household> {
  zip: string;
  children_qty: number;
  child_1_age: number;
  child_2_age: number;
  child_3_age: number;
  child_4_age: number;
  child_5_age: number;
  children: Array<{ name: string; dob: any }>;
  hasChildren: string;
}

const ProfileInformation = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);
  const existingProfile = useSelector(getPersonalInfo);
  const household = useSelector(getHousehold);
  const user = useSelector(userSelector);
  const hasPlan = useSelector(getHasPlan);
  const [emailError, setEmailError] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [childErrors, setChildErrors] = useState<boolean[]>([]);
  const [formState, setFormState] = useState<MyFormValues>({
    email: "",
    alt_email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    dob_month: 1,
    dob_day: 1,
    dob_year: 1999,
    sex: "o",
    zip: "",
    hasChildren: "n",
    children: [],
    children_qty: 0,
    child_1_age: 0,
    child_2_age: 0,
    child_3_age: 0,
    child_4_age: 0,
    child_5_age: 0,
    spouse_first_name: "",
    spouse_last_name: "",
  });

  const setFormValue = (e: any) => {
    const target = e.target;
    let value = target.value;
    if (target.name === "zip") {
      value = formatZip(value);
    }
    if (target.name.slice(0, 5) === "child") {
      value = Math.floor(+value);
    }
    const update = { ...formState, [target.name]: value };
    if (target.name === "children_qty") {
      if (target.value === 0) {
        update.child_1_age = 0;
        update.child_2_age = 0;
        update.child_3_age = 0;
        update.child_4_age = 0;
        update.child_5_age = 0;
      }
    }
    if (target.name === "hasChildren") {
      if (value === "n") {
        update.children = [];
        update.child_1_age = 0;
        update.child_2_age = 0;
        update.child_3_age = 0;
        update.child_4_age = 0;
        update.child_5_age = 0;
        update.children_qty = 0;
      } else {
        if (!update.children.length) {
          update.children = [{ name: "", dob: null }];
        }
      }
    }
    setFormState(update);
  };

  const handleDateChange = (date: Date | null, index: number) => {
    if (!date?.valueOf()) {
      return;
    }
    setFormState((current) => {
      const children = [...current.children];
      children[index] = {
        ...children[index],
        dob: date?.toISOString().slice(0, 10),
      };
      return { ...current, children };
    });
    setChildErrors((current) => {
      const newErrors = [...current];
      newErrors[index] = false;
      return newErrors;
    });
  };

  const handleNameChange = (name: string, index: number) => {
    setFormState((current) => {
      const children = [...current.children];
      children[index] = { ...children[index], name };
      return { ...current, children };
    });
    setChildErrors((current) => {
      const newErrors = [...current];
      newErrors[index] = false;
      return newErrors;
    });
  };

  const addChild = () => {
    setFormState((current) => {
      const children = [...current.children];
      children.push({ name: "", dob: null });
      return { ...current, children };
    });
  };

  const removeChild = (index: number) => {
    setFormState((current) => {
      const children = [...current.children];
      children.splice(index, 1);
      return { ...current, children };
    });
  };

  useEffect(() => {
    if (dialogOpen) {
      const foundHousehold = household || {};
      const foundUser = user || {};
      const newFormState = {
        ...formState,
        ...foundUser,
        ...existingProfile,
        ...foundHousehold,
      };
      if (foundHousehold.children_qty || foundHousehold.children?.length) {
        newFormState.hasChildren = "y";
      }
      setFormState(newFormState);
    }
  }, [existingProfile, household, user, dialogOpen]);

  const save = () => {
    const profileValues = pick(formState, PERSONAL_INFO_KEYS);
    const payload: UpdatePersonalInfoPayload = { update: profileValues };
    const userUpdate = pick(formState, ["first_name", "last_name", "email"]);
    const householdUpdate: any = pick(formState, DASHBOARD_HOUSEHOLD_KEYS);
    if (formState.hasChildren === "y") {
      householdUpdate.children = formState.children;
      let sortedChildren = [...formState.children];
      sortedChildren.sort((a, b) => a.dob - b.dob);
      sortedChildren = sortedChildren.slice(0, 5);
      householdUpdate.children_qty = sortedChildren.length;
      for (let i = 0; i < 5; i++) {
        if (sortedChildren.length > i) {
          const child = sortedChildren[i];
          if (!child.name || !child.dob) {
            setChildErrors((current: boolean[]) => {
              const newErrors = [...current];
              newErrors[i] = true;
              return newErrors;
            });
            return;
          }
          householdUpdate[`child_${i + 1}_age`] = calculateAge(
            new Date(sortedChildren[i].dob)
          );
        } else {
          householdUpdate[`child_${i + 1}_age`] = 0;
        }
      }
    } else {
      householdUpdate.children = [];
    }
    if (
      hasPlan &&
      (formState.children_qty > household.children_qty ||
        householdUpdate.zip !== household.zip)
    ) {
      reportActionItemApi("PLAN_PROFILECHG");
    }
    updateUserApi(userUpdate)
      .then(() => {
        dispatch(updateUser(userUpdate));
        return updateHouseholdApi(householdUpdate);
      })
      .then(() => {
        dispatch(updateHouseholdSuccess({ update: householdUpdate }));
        dispatch(updatePersonalInfo(payload));
        dispatch(fetchTaxes(null));
        closeDialog();
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          setDuplicateEmail(true);
        }
      });
  };

  const checkEmail = () => setEmailError(!validateEmail(formState.email));
  const checkZip = () => setZipError(!validateZip(formState.zip));

  const nowYear = new Date().getFullYear();
  const startYear = nowYear - 70;
  const endYear = nowYear - 16;
  const childKeys = useMemo(
    () => formState.children.map(() => "" + Math.random()),
    [formState.children.length]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <InformationCard title="Profile Information">
        <Box className={styles.container}>
          <ProfileInformationCard
            title="Personal Info"
            handleClick={openDialog}
          />
          <ProfileInformationCard
            title="Human Capital"
            handleClick={() => dispatch(push("/wealth#hc"))}
          />
          <ProfileInformationCard
            title={`Income & Expenses`}
            handleClick={() => dispatch(push("/budget"))}
          />
          <ProfileInformationCard
            title={`Assets & Debts`}
            handleClick={() => dispatch(push("/accounts"))}
          />
          <ProfileInformationCard
            title="Risk Management"
            handleClick={() => dispatch(push("/risk"))}
          />
        </Box>
        <CustomDialog
          title="Personal Information"
          isOpen={dialogOpen}
          onClose={closeDialog}
          size="sm"
        >
          <form>
            <Box className="flex">
              <Box className="mr-2">
                <FormLabel component="legend" className="mb-2 mt-4">
                  First name
                </FormLabel>
                <TextField
                  variant="outlined"
                  placeholder=""
                  name="first_name"
                  value={formState.first_name}
                  onChange={setFormValue}
                />
              </Box>
              <Box>
                <FormLabel component="legend" className="mb-2 mt-4">
                  Last name
                </FormLabel>
                <TextField
                  variant="outlined"
                  placeholder=""
                  name="last_name"
                  value={formState.last_name}
                  onChange={setFormValue}
                />
              </Box>
            </Box>
            <FormLabel component="legend" className="mb-2 mt-4">
              Email
            </FormLabel>
            <TextField
              variant="outlined"
              placeholder=""
              name="email"
              error={emailError || duplicateEmail}
              helperText={
                duplicateEmail
                  ? "Another user with the given email address already exists."
                  : emailError
                  ? "Please enter a valid email address."
                  : undefined
              }
              value={formState.email}
              onBlur={checkEmail}
              onFocus={() => {
                setDuplicateEmail(false);
                setEmailError(false);
              }}
              onChange={setFormValue}
            />
            <FormLabel component="legend" className="mb-2 mt-4">
              Date of birth
            </FormLabel>
            <Box className="flex">
              <Select
                variant="outlined"
                style={{ width: "150px" }}
                name="dob_month"
                onChange={setFormValue}
                value={formState.dob_month}
                className="mr-4"
              >
                {MONTHS.map((month: string, index: number) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
              <Select
                variant="outlined"
                style={{ width: "150px" }}
                name="dob_day"
                onChange={setFormValue}
                value={formState.dob_day}
                className="mr-4"
              >
                {range(1, DAYS_IN_MONTH[formState.dob_month] + 1).map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
              <Select
                variant="outlined"
                style={{ width: "150px" }}
                name="dob_year"
                onChange={setFormValue}
                value={formState.dob_year}
              >
                {range(startYear, endYear + 1).map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <FormLabel component="legend" className="mb-2 mt-4">
              Street Address
            </FormLabel>
            <TextField
              variant="outlined"
              placeholder=""
              name="street_address"
              value={formState.street_address}
              onChange={setFormValue}
            />
            <FormLabel component="legend" className="mb-2 mt-4">
              City
            </FormLabel>
            <TextField
              variant="outlined"
              name="city"
              value={formState.city}
              onChange={setFormValue}
            />
            <Box className="flex">
              <Box className="mr-4">
                <FormLabel component="legend" className="mb-2 mt-4">
                  State
                </FormLabel>
                <Select
                  variant="outlined"
                  name="state"
                  value={formState.state}
                  onChange={setFormValue}
                >
                  {STATES.map((state) => (
                    <MenuItem value={state}>{state}</MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <FormLabel component="legend" className="mb-2 mt-4">
                  ZIP Code
                </FormLabel>
                <TextField
                  variant="outlined"
                  placeholder="90210"
                  name="zip"
                  error={zipError}
                  helperText={
                    zipError ? "Please enter a valid ZIP code." : undefined
                  }
                  onBlur={checkZip}
                  onFocus={() => setZipError(false)}
                  value={formState.zip}
                  onChange={setFormValue}
                />
              </Box>
            </Box>
            <FormLabel component="legend" className="mb-2 mt-4">
              Phone number
            </FormLabel>
            <PhoneNumberInput
              variant="outlined"
              name="phone_number"
              value={formState.phone_number}
              onChange={setFormValue}
            />
            <FormLabel component="legend" className="mb-2 mt-4">
              Gender
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="sex"
              value={formState.sex}
              className="flex flex-row"
              onChange={setFormValue}
            >
              <FormControlLabel
                value="m"
                className="pr-4"
                control={<Radio />}
                label="Male"
              />
              <FormControlLabel
                value="f"
                className="pr-4"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel
                value="o"
                className="pr-4"
                control={<Radio />}
                label="Other/Prefer not to say"
              />
            </RadioGroup>
            <FormLabel component="legend" className="mb-2 mt-4">
              Do you have any children?
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="hasChildren"
              value={formState.hasChildren}
              className="flex flex-row"
              onChange={setFormValue}
            >
              <FormControlLabel
                value="y"
                className="pr-4"
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value="n"
                className="pr-4"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
            {formState.hasChildren === "y" &&
              formState.children.map((child, index) => (
                <Box
                  key={childKeys[index]}
                  className="flex items-center justify-between"
                >
                  <TextField
                    label="Name"
                    className="mr-2 mt-2"
                    name="name"
                    variant="outlined"
                    value={child.name || ""}
                    onChange={(e: any) =>
                      handleNameChange(e.target.value, index)
                    }
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    placeholder="Enter date"
                    fullWidth
                    label="Date of birth"
                    value={child.dob ? new Date(child.dob) : undefined}
                    onChange={(value: Date | null) =>
                      handleDateChange(value, index)
                    }
                    keyboardIcon={<Icon iconName="fb-calendar" />}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <IconButton
                    color="primary"
                    onClick={() => removeChild(index)}
                  >
                    <Icon iconName="fb-trash-can" />
                  </IconButton>
                </Box>
              ))}
            {formState.hasChildren === "y" && (
              <Button
                color="primary"
                endIcon={<Icon iconName="fb-add-alt" />}
                onClick={addChild}
              >
                Add child
              </Button>
            )}
            <Button
              fbColor="primary"
              style={{ display: "block", margin: "20px auto" }}
              onClick={save}
              disabled={
                !validateEmail(formState.email) ||
                !validateZip(formState.zip) ||
                childErrors.some((e) => e)
              }
            >
              Save
            </Button>
          </form>
        </CustomDialog>
      </InformationCard>
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    minWidth: 0,
    paddingTop: 20,
  },
});
export default ProfileInformation;
