import React from "react";

import {
  Box,
  FormLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";

import { IDRVsPrepayTableResponse } from "src/apiService";
import Button from "src/components/Button";
import { DollarTextField, PercentTextField } from "src/utils";
import IDRTable from "./IDRTable";

interface PayoffVsIDRFormProps {
  formValues: any;
  onChange: (e: React.ChangeEvent<any>) => void;
  submit: (tabIndex?: number) => void;
  goBack: VoidFunction;
  tableResults: IDRVsPrepayTableResponse | null;
  who: string;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    padding: 20,
    margin: "0 auto",
    maxWidth: 800,
  },
  inputRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  inputField: {
    minWidth: 120,
  },
  groupHeader: {},
  capitalized: {
    textTransform: "capitalize",
  },
  disclaimer: {
    position: "fixed",
    bottom: 40,
    fontSize: 12,
  },
});

export const PayoffVsIDRForm = ({
  formValues,
  onChange,
  submit,
  goBack,
  tableResults,
  who,
}: PayoffVsIDRFormProps) => {
  const styles = useStyles();

  const your = who === "spouse" ? "your spouse's" : "your";
  const yourTheir = who === "spouse" ? "their" : "your";
  const you = who === "spouse" ? "your spouse" : "you";
  const doYou = who === "spouse" ? "Does your spouse" : "Do you";

  return (
    <>
      <IDRTable loading={false} data={tableResults} />
      <Paper className={styles.root}>
        <Typography variant="h6" className={styles.groupHeader}>
          For <span className={styles.capitalized}>{your}</span> Pay Off
          Strategy (Strategy 1):
        </Typography>
        <Box className={styles.inputRow}>
          <FormLabel>
            {doYou} want to extend {yourTheir} Federal loans to 25 years?
          </FormLabel>
          <Select
            variant="outlined"
            name="idrcalc_federal_extended"
            value={formValues.idrcalc_federal_extended || ""}
            onChange={onChange}
            className={styles.inputField}
          >
            <MenuItem value={"y"}>Yes</MenuItem>
            <MenuItem value={"n"}>No</MenuItem>
          </Select>
        </Box>
        <Box className={styles.inputRow}>
          <FormLabel>Would {you} be making monthly prepayments?</FormLabel>
          <DollarTextField
            variant="outlined"
            name="idrcalc_mthly_pmt"
            value={formValues.idrcalc_mthly_pmt || ""}
            onChange={onChange}
          />
        </Box>
        <Typography variant="h6" className={styles.groupHeader}>
          For <span className={styles.capitalized}>{your}</span> Federal IDR
          Strategy (Strategy 2):
        </Typography>
        <Box className={styles.inputRow}>
          <FormLabel>Select a Federal IDR Plan:</FormLabel>
          <Select
            variant="outlined"
            name="idrcalc_select_plan"
            value={
              formValues.idrcalc_select_plan === undefined
                ? ""
                : formValues.idrcalc_select_plan
            }
            onChange={onChange}
            className={styles.inputField}
          >
            <MenuItem value={0}>REPAYE/SAVE</MenuItem>
            <MenuItem value={1}>IBR</MenuItem>
            <MenuItem value={2}>IBR for new borrowers</MenuItem>
            <MenuItem value={3}>PAYE</MenuItem>
          </Select>
        </Box>
        <Box className={styles.inputRow}>
          <FormLabel>
            What rate of return would {you} expect to make on {yourTheir}{" "}
            investments?
          </FormLabel>
          <PercentTextField
            variant="outlined"
            name="idrcalc_breakeven_ret"
            value={formValues.idrcalc_breakeven_ret || ""}
            onChange={onChange}
          />
        </Box>
        <Button
          className="mb-2"
          fbColor="primary"
          onClick={() => submit()}
          fullWidth
        >
          Next
        </Button>
        <Button fbColor="secondary" onClick={goBack} fullWidth>
          Back
        </Button>
      </Paper>
    </>
  );
};

export default PayoffVsIDRForm;
