import NodeClient from "src/apiService/nodeClient";
import makeAuthenticatedRequest from "src/apiService/authenticatedRequest";

export const setUserAsAffiliate = (obj) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await NodeClient.post(`referralCode`, obj);
    return data;
  });

export const getAffiliateLink = (obj) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await NodeClient.get(`referralCode`, { params: obj });
    return data;
  });
