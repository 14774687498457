import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import CenterContainer from "src/pages/PlanBuilder/Components/CenterContainer";

const Home = () => {
  const styles = useStyles();

  return (
    <CenterContainer review title="Plan Review">
      <Box className={styles.textBox}>
        <Typography>
          Use the menu to the left to navigate the details of your financial
          plan.
        </Typography>

        <Typography>
          When ready, click "Implement This Plan" to begin tracking your plan
          progress. You will receive step-by-step guidance.
        </Typography>
      </Box>
    </CenterContainer>
  );
};

export default Home;

const useStyles = makeStyles({
  textBox: {
    padding: "20px",
    "& p": {
      marginBottom: "15px",
      fontSize: "14px",
    },
  },
});
