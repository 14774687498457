import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, makeStyles, TextField, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { Message } from "src/interfaces";
import {
  getMessages,
  markMessageRead,
  sendMessage,
} from "src/store/dashboard/actions";
import { getMessages as selectMessages } from "src/store/dashboard/selector";
import colors from "src/theme/colors";
import shadows from "src/theme/shadows";

const useStyles = makeStyles({
  container: {},
  messageList: {
    margin: "16px 0",
    maxHeight: 340,
    overflowY: "auto",
    width: "100%",
  },
  message: {
    border: "1px solid",
    borderColor: colors.blueGray6,
    borderRadius: 8,
    boxShadow: shadows[2],
    fontWeight: 400,
    marginBottom: 24,
    padding: 16,
    position: "relative",
    width: "calc(100% - 25px)",
  },
  incoming: {
    marginLeft: 11,
    "&::after": {
      content: '""',
      position: "absolute",
      top: 15,
      left: -10,
      borderBottom: "10px solid transparent",
      borderTop: "10px solid transparent",
      borderRight: "10px solid white",
      borderLeftColor: colors.blueGray8,
      width: 0,
      height: 0,
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 13,
      left: -12,
      borderBottom: "11px solid transparent",
      borderTop: "12px solid transparent",
      borderRight: "12px solid",
      borderRightColor: colors.blueGray6,
      width: 0,
      height: 0,
    },
  },
  outgoing: {
    backgroundColor: colors.blueGray8,
    marginRight: 11,
    "&::after": {
      content: '""',
      position: "absolute",
      top: 15,
      right: -10,
      borderBottom: "10px solid transparent",
      borderTop: "10px solid transparent",
      borderLeft: "10px solid",
      borderLeftColor: colors.blueGray8,
      width: 0,
      height: 0,
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 13,
      right: -12,
      borderBottom: "11px solid transparent",
      borderTop: "12px solid transparent",
      borderLeft: "12px solid",
      borderLeftColor: colors.blueGray6,
      width: 0,
      height: 0,
    },
  },
  messageHeader: {
    display: "flex",
    fontWeight: 500,
    justifyContent: "space-between",
    marginBottom: 16,
  },
  actionBox: {
    display: "flex",
    justifyContent: "center",
  },
  action: {
    margin: 16,
  },
  newMessage: {
    cursor: "pointer",
  },
  newText: {
    fontWeight: 600,
  },
});

export const Messages = ({
  centerSendButton,
}: {
  goBack?: any;
  centerSendButton?: boolean;
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const messages: Message[] = useSelector(selectMessages);
  const [newMessageText, setNewText] = useState("");

  const lastMessage = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    dispatch(getMessages());
  }, []);

  useEffect(() => {
    if (lastMessage.current !== null) {
      lastMessage?.current?.scrollIntoView();
    }
  }, [lastMessage]);

  const send = () => {
    dispatch(sendMessage(newMessageText));
    setNewText("");
  };

  const markRead = (id: number) => dispatch(markMessageRead(id));

  const handleInputChange = (e: React.ChangeEvent<any>) =>
    setNewText(e.target.value);

  return (
    <Card title="Messages" className={styles.container}>
      <Box className={styles.messageList}>
        {messages.map((message) => {
          const style =
            message.direction === "apps" ? styles.incoming : styles.outgoing;
          const newStyle = message.new ? " " + styles.newMessage : "";
          return (
            <Box
              key={message.date}
              className={`${styles.message} ${style}${newStyle}`}
              onClick={message.new ? () => markRead(message.id) : undefined}
            >
              <Box className={styles.messageHeader}>
                <Typography variant="body1">
                  {message.direction === "apps" ? "FitBUX Coach" : "Me"}
                </Typography>
                <Typography variant="body1">{message.date}</Typography>
              </Box>
              <Typography
                variant="body1"
                className={message.new ? styles.newText : undefined}
              >
                {message.body}
              </Typography>
            </Box>
          );
        })}
        <div ref={lastMessage} />
      </Box>
      <Box>
        <TextField
          fullWidth
          label="New message"
          placeholder="Type something ..."
          variant="outlined"
          multiline
          rows={2}
          onChange={handleInputChange}
          value={newMessageText}
        />
      </Box>
      <Box className={centerSendButton ? styles.actionBox : ""}>
        <Button
          className={styles.action}
          fbColor="primary"
          disabled={!newMessageText}
          onClick={send}
        >
          Send
        </Button>
      </Box>
    </Card>
  );
};

export default Messages;
