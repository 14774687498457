import React from "react";

import Content from "./Content";
import { PointsCalculatorContextProvider } from "./UsePointsCalculator";

const PointsCalculator = () => {
  return (
    <PointsCalculatorContextProvider>
      <Content />
    </PointsCalculatorContextProvider>
  );
};

export default PointsCalculator;
