import React from "react";
import clsx from "clsx";

import { Icon, SvgIcon, IconProps, SvgIconProps } from "@material-ui/core";

interface IFbIconProps {
  size?: number;
  iconName: string;
  variant?: string;
}

const DFLT_ICON_SIZE = 19;

const CUSTOM_SIZES: any = {
  "fa-pencil": 17,
  "fa-circle-x": 17,
  "fa-link-slash": 15,
  "fa-link": 15,
  "fa-circle-play": 17,
  "fa-coffin-cross": 18,
  "fa-truck-medical": 18,
  "fa-sack-dollar": 18,
  "fa-file-certificate": 18,
};

const FbIcon = ({
  size,
  iconName,
  variant,
  ...rest
}: IFbIconProps & IconProps & SvgIconProps) => {
  const isCustomIcon = iconName && iconName.includes("fb-");
  if (isCustomIcon) {
    return (
      <SvgIcon {...rest}>
        <use xlinkHref={`/assets/images/svg/sprite.svg#${iconName}`} />
      </SvgIcon>
    );
  }
  const isFaIcon = iconName && iconName.includes("fa-");
  if (isFaIcon) {
    const { className, style, ...props } = rest;

    return (
      <Icon
        className={clsx(className, variant || "fa-thin", iconName)}
        style={{
          ...style,
          fontSize: size || CUSTOM_SIZES[iconName] || DFLT_ICON_SIZE,
          width: 24,
        }}
        {...props}
      />
    );
  }
  return <Icon {...rest}>{iconName}</Icon>;
};

export default FbIcon;
