import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Icon from "src/components/Icon";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import colors from "src/theme/colors";
import { getIsMarried } from "src/store/system/selector";
import { useSelector } from "react-redux";

export interface IItem {
  leftPrimary?: string;
  leftSecondary?: string;
  right?: string;
}

interface IMainCard {
  iconName: string;
  title: string;
  data: Array<IItem>;
  onClick: (mine: boolean) => void;
}

type MainCardProps = IMainCard;

const MainCard: React.FC<MainCardProps> = ({
  iconName,
  title,
  data,
  onClick,
}) => {
  const styles = useStyles();
  const isMarried = useSelector(getIsMarried);

  return (
    <Card className={styles.root}>
      <CardContent className={styles.content}>
        <Box className="flex mb-4">
          <Icon iconName={iconName} className={styles.icon} />
          <Typography className={styles.title}>{title}</Typography>
        </Box>
        {(!data || !data.length) && (
          <Typography className="py-8 text-disabled text-center">
            No Items
          </Typography>
        )}
        {data.map((e: IItem, i) => (
          <Box className="flex justify-between" key={i}>
            <Box className="flex">
              <Typography className="mr-3 text-primary">
                {e.leftPrimary}
              </Typography>
              <Typography className="text-disabled">
                {e.leftSecondary}
              </Typography>
            </Box>
            <Box>
              <Typography className="text-disabled">{e.right}</Typography>
            </Box>
          </Box>
        ))}

        <Grid container spacing={2} className="mt-4">
          <Grid item xs={isMarried ? 6 : 12}>
            <Button
              variant="outlined"
              className={styles.button}
              color="primary"
              onClick={() => {
                onClick(true);
              }}
              endIcon={<Icon iconName="fb-add-alt" />}
            >
              Add/Edit My {title}
            </Button>
          </Grid>
          {isMarried && (
            <Grid item xs={6}>
              <Button
                variant="outlined"
                className={styles.button}
                color="primary"
                onClick={() => {
                  onClick(false);
                }}
                endIcon={<Icon iconName="fb-add-alt" />}
              >
                Add/Edit Spouse's {title}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MainCard;

const useStyles = makeStyles({
  root: {
    borderRadius: 12,
    marginBottom: 10,
  },
  content: {
    padding: 15,
  },
  icon: {
    color: colors.brandingBlue1,
    fontSize: 20,
    marginRight: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.brandingBlue1,
  },
  button: {
    width: "100%",
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
});
