import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { Box, makeStyles, Tooltip } from "@material-ui/core";

import { validateZipApi } from "src/apiService";
import Button from "src/components/Button";
import Card from "src/components/Card";
import TwoColumnInputCard from "src/components/TwoColumnInputCard";
import { Account, Household, InputDefinition, Profile } from "src/interfaces";

import { MortgageLeadRequest } from "src/apiService";
import { STATES } from "src/constants";
import {
  getDebtObligations,
  getHomeValue,
  getPrimaryMortgage,
} from "src/store/account/selector";
import { getHouseholdGI } from "src/store/cashflow/selector";
import { fetchProfessions } from "src/store/profileBuild/actions";
import {
  getHousehold,
  getProfile,
  getProfileLoaded,
  getProfileLoading,
} from "src/store/profileBuild/selector";
import { userSelector } from "src/store/system/selector";
import { isTruthy, validateEmail, validatePhone } from "src/utils";

import CalculatorLayout from "../CalculatorLayout";
import SelectLenders from "./SelectLenders";

const rateQuoteDisclaimer = `Disclaimer: Rates and pricing are subject to eligibility and underwriting criteria. Fitbux Mortgage LLC is a licensed mortgage broker in the state of Texas and is a wholly-owned subsidiary of Fitbux Inc. Fitbux Mortgage LLC has a business relationship and receives advertising revenue from each lender (the "Lender(s)") on the Fitbux membership pages, Fitbux web content, and other consumer facing assets owned by Fitbux. Compensation is received by Fitbux regardless of loan status. You do not have to complete a loan with a Lender of Fitbux. You are free to shop the Lenders as well as shop other lenders and Fitbux encourages you to do so. There are frequently other lenders available with similar services to the Lenders. Fitbux's advertising relationship with each Lender is not an endorsement of any services, products, or service providers of the lending partners. This site is directed at and made available to persons in the continental U.S., Alaska, and Hawaii only. Each Lender on Fitbux, Fitbux inc., and Fitbux Mortgage LLC are separate independent entities and are not responsible for the financial condition, business, or legal obligations of the other. All services from our Lenders are their own. Once you reach them, Fitbux is not involved and has no control over the products of services involved. More information can be found at www.consumerfinance.gov/owning-a-home/`;

const useStyles = makeStyles({
  container: {
    margin: "20px auto",
    width: "90%",
    maxWidth: 1100,
  },
  buttonContainer: {
    margin: "40px auto",
    display: "flex",
    justifyContent: "center",
  },
});

const validators: any = {
  email: validateEmail,
  first_name: isTruthy,
  last_name: isTruthy,
  state: isTruthy,
  phone_number: validatePhone,
};

const formatters: any = {
  credit_score: (val: any) => {
    const result = val.replace(/\D/g, "");
    return result;
  },
};

interface RateQuoteTemplateProps {
  pageTitle: string;
  introTitle: string;
  introText: React.ReactNode;
  contactInfoRequired?: boolean;
  mortgageDetailsInputs: InputDefinition[][];
  availableLenders: any[];
  // endTitle: string;
  endText: React.ReactNode;
  suppressPrimaryMortgage?: boolean;
  submitApi: (payload: any) => any;
  isHeloc?: boolean;
}

const userInputDefinitions: InputDefinition[][] = [
  [
    {
      label: "First Name",
      name: "first_name",
      type: "text",
    },
    {
      label: "Last Name",
      name: "last_name",
      type: "text",
    },
    {
      label: "Phone Number",
      name: "phone_number",
      type: "phone",
    },
    {
      label: "Email",
      name: "email",
      type: "text",
    },
  ],
  [
    {
      label: "Phone",
      name: "contact_phone",
      type: "toggle",
    },
    {
      label: "Email",
      name: "contact_email",
      type: "toggle",
    },
    {
      label: "Text",
      name: "contact_sms",
      type: "toggle",
    },
  ],
];

export const commonInputDefinitions: InputDefinition[][] = [
  [
    {
      label: "Property Located In...",
      name: "state",
      type: "select",
      values: STATES,
    },
    {
      label: "Type of Residence",
      name: "residence",
      type: "select",
      values: [
        {
          label: "Primary",
          value: "primary_residence",
        },
        {
          label: "Second Home",
          value: "second_home",
        },
        {
          label: "Investment Property",
          value: "investment_property",
        },
      ],
    },
  ],
  [
    {
      label: "Household Total Income",
      name: "income",
      type: "dollars",
    },
    {
      label: "Household Student Loan Payment",
      name: "student_loan_payment",
      type: "dollars",
    },
    {
      label: "Household Auto Payments",
      name: "auto_loan_payment",
      type: "dollars",
    },
    {
      label: "All Other Debt Payments",
      name: "other_debt_payment",
      type: "dollars",
    },
  ],
];

const RateQuoteTemplate = ({
  pageTitle,
  introTitle,
  introText,
  contactInfoRequired,
  mortgageDetailsInputs,
  availableLenders,
  // endTitle,
  endText,
  submitApi,
  suppressPrimaryMortgage,
  isHeloc,
}: RateQuoteTemplateProps) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const profile: Profile = useSelector(getProfile);
  const household: Household = useSelector(getHousehold);
  const user: any = useSelector(userSelector);
  const householdGI: number = useSelector(getHouseholdGI);
  const primaryMortgage: Account | null = useSelector(getPrimaryMortgage);
  const homeValue = useSelector(getHomeValue);
  const debts: any = useSelector(getDebtObligations);
  const loading = useSelector(getProfileLoading);
  const loaded = useSelector(getProfileLoaded);

  useEffect(() => {
    if (!loaded.professions && !loading.professions) {
      dispatch(fetchProfessions());
    }
  }, [dispatch]);

  const initialValues: any = {
    first_name: user.first_name,
    last_name: user.last_name,
    phone_number: profile.phone_number || "",
    email: user.email,
    contact_phone: "y",
    contact_email: "y",
    contact_sms: "y",
    state: household.state,
    residence: "primary_residence",
    down_payment: 0,
    credit_score: 0,
    profession: profile.profession || "",
    home_value: (suppressPrimaryMortgage ? 0 : homeValue).toFixed(2),
    mortgage_balance: (suppressPrimaryMortgage
      ? 0
      : primaryMortgage?.balance || 0
    ).toFixed(2),
    mortgage_payment: (suppressPrimaryMortgage
      ? 0
      : primaryMortgage?.payment || 0
    ).toFixed(2),
    income: (householdGI / 12).toFixed(2),
    student_loan_payment: debts.student.toFixed(2),
    auto_loan_payment: debts.auto.toFixed(2),
    other_debt_payment: debts.other.toFixed(2),
  };

  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  const [selectedLenders, setSelectedLenders] = useState<Set<string>>(
    new Set(availableLenders.map((lender) => lender.id))
  );
  const [errors, setErrors] = useState<Set<string>>(new Set([]));
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized && !household.state && household.zip) {
      validateZipApi(household.zip).then((result: any) => {
        setErrors((current) => {
          const newErrors = new Set(current);
          newErrors.delete("state");
          return newErrors;
        });
        setFormValues((current: any) => ({ ...current, state: result.state }));
      });
    }
    setInitialized(true);
  }, [initialized, household.state, household.zip]);

  useEffect(() => {
    const initialErrors = new Set<string>([]);
    Object.keys(initialValues).forEach((key: string) => {
      const validator = validators[key] || null;
      const isValid = !validator || validator(initialValues[key]);
      if (!isValid) {
        initialErrors.add(key);
      }
    });
    setErrors(initialErrors);
  }, []);

  const toggleSelectedLender = (lender: string) =>
    setSelectedLenders((current) => {
      const newState = new Set(current);
      if (newState.has(lender)) {
        newState.delete(lender);
      } else {
        newState.add(lender);
      }
      return newState;
    });

  const submit = () => {
    const payload: MortgageLeadRequest = {
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      phone_number: formValues.phone_number,
      email: formValues.email,
      contact: {
        phone: formValues.contact_phone === "y",
        email: formValues.contact_email === "y",
        sms: formValues.contact_sms === "y",
      },
      state: formValues.state,
      residence: formValues.residence,
      home_value: +formValues.home_value,
      credit_score: +formValues.credit_score,
      profession: formValues.profession,
      income: +formValues.income,
      payments: {
        student: +formValues.student_loan_payment,
        auto: +formValues.auto_loan_payment,
        other: +formValues.other_debt_payment,
      },
      lenders: Array.from(selectedLenders),
    };
    if (!suppressPrimaryMortgage) {
      payload.mortgage_balance = +formValues.mortgage_balance;
      if (isHeloc) {
        payload.payments.home = +formValues.mortgage_payment;
      }
    } else {
      payload.down_payment = +formValues.down_payment;
    }
    submitApi(payload);
  };

  const getButtons = () => {
    if (step === 0) {
      return (
        <Button fbColor="primary" onClick={() => setStep(1)}>
          Let's Get Started
        </Button>
      );
    }
    if (step === 4) {
      return (
        <Button
          fbColor="primary"
          onClick={() => dispatch(push("/plan-summary"))}
        >
          Go to My Dashboard
        </Button>
      );
    }
    const nextButtonProps: any = {
      fbColor: "primary",
      onClick: () => {
        if (step === 3) {
          submit();
        }
        setStep((current) => current + 1);
      },
    };
    let tooltipTitle = "";
    if (errors.size) {
      nextButtonProps.disabled = true;
      tooltipTitle =
        "Please enter your contact information. All fields are required.";
      if (errors.has("email")) {
        tooltipTitle = "Please enter a valid email address.";
      } else if (errors.has("phone_number")) {
        tooltipTitle = "Please enter a valid phone number.";
      }
    } else if (!selectedLenders.size) {
      nextButtonProps.disabled = true;
      tooltipTitle =
        "Please select at least one lender in order to request quotes.";
    }

    const nextButton = <Button {...nextButtonProps}>Next</Button>;
    return (
      <>
        <Button
          onClick={() => setStep((current) => current - 1)}
          className="mr-4"
        >
          Back
        </Button>
        {errors.size || !selectedLenders.size ? (
          <Tooltip title={tooltipTitle}>
            <span>{nextButton}</span>
          </Tooltip>
        ) : (
          nextButton
        )}
      </>
    );
  };
  return (
    <CalculatorLayout
      disclaimer={rateQuoteDisclaimer}
      title={pageTitle}
      backTo="/homebuying"
    >
      <Box className={styles.container}>
        {step === 0 && (
          <Card divider title={introTitle}>
            <Box className="mt-5">{introText}</Box>
          </Card>
        )}
        {step === 1 && (
          <TwoColumnInputCard
            title="1 - Confirm Your Information"
            innerTitle1={`Contact Information${
              contactInfoRequired ? " (Required)" : ""
            }`}
            innerTitle2="Preferred Contact Method(s)"
            inputs1={userInputDefinitions[0]}
            inputs2={userInputDefinitions[1]}
            formValues={formValues}
            setFormValues={setFormValues}
            errors={errors}
            setErrors={setErrors}
            validators={validators}
          />
        )}
        {step === 2 && (
          <TwoColumnInputCard
            title="1 - Confirm Your Information"
            innerTitle1="Your Home And You (Optional)"
            innerTitle2="Monthly Income And Debt Obligations (Optional)"
            inputs1={mortgageDetailsInputs[0]}
            inputs2={mortgageDetailsInputs[1]}
            formValues={formValues}
            setFormValues={setFormValues}
            formatters={formatters}
          />
        )}
        {step === 3 && (
          <SelectLenders
            availableLenders={availableLenders}
            selectedLenders={selectedLenders}
            toggleSelectedLender={toggleSelectedLender}
          />
        )}
        {step === 4 && (
          <Card divider title="3 - Discuss Your Options With Our Partners">
            <Box className="mt-5">{endText}</Box>
          </Card>
        )}
        <Box className={styles.buttonContainer}>{getButtons()}</Box>
      </Box>
    </CalculatorLayout>
  );
};

export default RateQuoteTemplate;
