import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";

// import { makeStyles } from "@material-ui/core";

import { emptyPlan } from "src/interfaces/optimizedPlanBuild.interface";
import { setOptimizedPlan } from "src/store/planBuild/actions";
import { getCurrentPlan, getPlans } from "src/store/planBuild/selector";
// import { planBuilderStyles } from "src/theme";
// import CenterContainer from "../PlanBuilder/Components/CenterContainer";
import { steps } from "./common";
import Recap from "./Views/Recap";

// const useStyles = makeStyles(planBuilderStyles);

interface FormProps {
  stepIndex: number;
  nextClicked: number;
  goToNext: () => void;
  reviewStepIndex: number;
  onSummaryClick: (i: number) => void;
  // planData: OptimizedPlan
  // planSetter:(value: OptimizedPlan)=>void
}

const MainForm = ({
  stepIndex,
  nextClicked,
  goToNext,
  reviewStepIndex,
  onSummaryClick,
}: FormProps) => {
  const dispatch = useDispatch();
  const currentPlan = useSelector(getCurrentPlan);
  const [planData, setPlanData] = useState(cloneDeep(emptyPlan));
  const [subIndex, setSubIndex] = useState(0);
  // const styles = useStyles();
  const plans = useSelector(getPlans);
  const optimizedPlanCount =
    plans.filter((plan) => plan.questionnaire || plan.messages).length + 1;
  const planName = `Optimized Plan ${optimizedPlanCount}`;
  const useCurrentPlanId =
    !!currentPlan.id && currentPlan.id > 0 ? currentPlan.id : 0;

  useEffect(() => {
    if (currentPlan.questionnaire) {
      setPlanData(currentPlan.questionnaire);
    }
  }, [currentPlan]);

  // send questionnaire to db and get plan
  useEffect(() => {
    if (stepIndex === 11) {
      dispatch(
        setOptimizedPlan({
          planId: useCurrentPlanId || 0,
          keepId: !!useCurrentPlanId,
          planName: useCurrentPlanId ? currentPlan.name : planName,
          questionnaire: planData,
        })
      );
    }
  }, [stepIndex]);

  const handleSummary = (index: number, subI: number) => {
    setSubIndex(subI);
    onSummaryClick(index);
  };

  const updatePlan = (planDetails: any) => {
    setPlanData(planDetails);
  };

  useEffect(() => {
    setSubIndex(0);
  }, [stepIndex]);

  useEffect(() => {
    // on unmount filter the decrease in income
    return filterDecreases();
  }, [stepIndex]);

  const filterDecreases = () => {
    const planCopy = cloneDeep(planData);
    planCopy.income_increase = planCopy.income_increase.filter(
      (e) => e !== undefined
    );
    planCopy.income_increase_date = planCopy.income_increase_date.filter(
      (e) => e !== undefined
    );
    planCopy.s_income_increase = planCopy.s_income_increase.filter(
      (e) => e !== undefined
    );
    planCopy.s_income_increase_date = planCopy.s_income_increase_date.filter(
      (e) => e !== undefined
    );
    planCopy.income_decrease = planCopy.income_decrease.filter(
      (e) => e !== undefined
    );
    planCopy.income_decrease_date = planCopy.income_decrease_date.filter(
      (e) => e !== undefined
    );
    planCopy.s_income_decrease = planCopy.s_income_decrease.filter(
      (e) => e !== undefined
    );
    planCopy.s_income_decrease_date = planCopy.s_income_decrease_date.filter(
      (e) => e !== undefined
    );
    setPlanData(planCopy);
  };

  const Step = steps[stepIndex];

  return (
    <>
      {(stepIndex < 10 || stepIndex === 11) && (
        <Step
          planData={planData}
          planSetter={updatePlan}
          nextClicked={nextClicked}
          goToNext={goToNext}
          reviewStepIndex={reviewStepIndex}
          onRecapClick={onSummaryClick}
          subIndex={subIndex}
        />
      )}
      {stepIndex === 10 && (
        <Recap
          planData={planData}
          planSetter={updatePlan}
          nextClicked={nextClicked}
          goToNext={goToNext}
          reviewStepIndex={reviewStepIndex}
          onRecapClick={onSummaryClick}
          handleClick={handleSummary}
          subIndex={subIndex}
        />
      )}
    </>
  );
};

export default MainForm;
