import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import Button from "../Button";
import HelpToggle from "../HelpToggle";
import LearnMoreLink from "../LearnMoreLink";
import { SidebarContent } from "src/interfaces";
import { heroTextStyle } from "src/theme/base";
import { AdapterLink } from "src/utils";
const useStyles = makeStyles(heroTextStyle);

interface TextProps {
  className?: string;
  imageClassName?: string;
  heading?: string;
  headingImage?: string;
  content?: React.ReactNode;
  description?: string;
  description2?: string;
  buttons?: any[];
  img?: string;
  fontSize?: string;
  special?: React.ReactNode;
  helpContent?: SidebarContent[];
  helpTopic?: string;
  inLineLink?: string;
  inLineText?: string;
}

const HeaderText = ({
  className,
  heading,
  headingImage,
  imageClassName,
  content,
  description,
  description2,
  buttons,
  fontSize,
  img,
  special,
  helpContent,
  helpTopic,
  inLineLink,
  inLineText,
}: TextProps) => {
  const classes: any = useStyles();
  return (
    <Grid
      container
      className={`classes.container${className ? " " + className : ""} `}
    >
      <Grid item xs={12} md={img ? 8 : 10}>
        <div className={classes.content}>
          <div className={classes.heading}>
            <h1 style={{ fontSize: fontSize }}>
              {heading} {!!special && <span>{special}</span>}{" "}
              {!!helpContent && <HelpToggle content={helpContent} />}
            </h1>
            {!!headingImage && (
              <img
                alt="heading image"
                className={imageClassName || ""}
                src={headingImage}
              />
            )}
          </div>
          {!!content && content}
          {!!description && (
            <>
              <p className={classes.text}>
                {description}
                {!description2 ? (
                  <>
                    {helpTopic ? (
                      <span>
                        {" "}
                        <LearnMoreLink learnMoreKey={helpTopic} />
                      </span>
                    ) : (
                      ""
                    )}
                    {inLineLink ? (
                      <a href={inLineLink} target="_blank">
                        {inLineText}{" "}
                      </a>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </p>

              {description2 ? (
                <>
                  <p className={classes.text}>
                    {" "}
                    {description2}
                    {helpTopic ? (
                      <span>
                        {" "}
                        <LearnMoreLink learnMoreKey={helpTopic} />
                      </span>
                    ) : (
                      ""
                    )}
                    {inLineLink ? (
                      <a href={inLineLink} target="_blank">
                        {inLineText}{" "}
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                </>
              ) : (
                ""
              )}
            </>
          )}
          {buttons && (
            <div className={classes.buttons}>
              {buttons.map((item) => {
                if (item.text) {
                  const { text, color, onClick, href, to } = item;
                  const buttonProps: any = {};
                  if (onClick) {
                    buttonProps.onClick = onClick;
                  } else if (href) {
                    buttonProps.href = href;
                    buttonProps.component = "a";
                    buttonProps.target = "_blank";
                  } else if (to) {
                    buttonProps.to = to;
                    buttonProps.component = AdapterLink;
                  }
                  return (
                    <Button
                      key={text?.toString() || ""}
                      fbColor={color}
                      className="mr-3"
                      {...buttonProps}
                    >
                      {text}
                    </Button>
                  );
                }
                return item;
              })}
            </div>
          )}
        </div>
      </Grid>
      {img && (
        <Grid item xs={12} md={4} className={classes.img}>
          <img src={img} alt="headerImg" />
        </Grid>
      )}
    </Grid>
  );
};

export default HeaderText;
