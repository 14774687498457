import React from "react";
import {
  BarChart as Chart,
  LabelList,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { makeStyles } from "@material-ui/core/styles";
import { chartStyles } from "src/theme/base";
import colors from "src/theme/colors";
import shadows from "src/theme/shadows";
import Dropdown from "src/components/Dropdown";
import { AdapterLink } from "src/utils";

const COLORS = [
  colors.brandingBlue1,
  colors.brandingBlue3,
  colors.brandingBlue2,
];
const useStyles = makeStyles(chartStyles);

interface List {
  name: string;
  value: number;
}
interface Props {
  noNegative?: boolean;
  title?: string;
  dataSource: List[];
  colors?: string[];
  height?: string;
  formatter?: (value: any) => string;
  isDropdown?: boolean;
  value?: string;
  hideAxis?: boolean;
  hideTooltip?: boolean;
  labelYOffset?: number;
  list?: Array<{ label: string; value: string }>;
  onChange?: (event: React.ChangeEvent<any>) => void;
  paddingX?: string;
  xDomain?: number;
  titleLink?: {
    to: string;
    label: string;
  };
}

const BarChart = ({
  noNegative,
  title,
  colors,
  dataSource,
  isDropdown,
  height,
  onChange,
  value,
  labelYOffset,
  list,
  formatter,
  hideAxis,
  hideTooltip,
  paddingX,
  titleLink,
  xDomain,
}: Props) => {
  const classes = boxStyles();

  const displayColors: string[] = colors || COLORS;

  const CustomTooltip = ({ active, payload, label }: any) => {
    const classes = useStyles();
    if (active && payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          <h5 className="fb-chart-tooltip-title">{label}</h5>
          <ul>
            {payload.map((e: any) => {
              const formattedValue = formatter ? formatter(e.value) : e.value;
              return (
                <li>
                  <span style={{ backgroundColor: e.fill }}></span>
                  {`${e.dataKey}`}
                  <strong>{formattedValue}</strong>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
    return null;
  };

  const renderCustomizedLabelValues = ({ x, y, width, height, value }: any) => {
    const formattedValue = formatter ? formatter(value) : value;
    const fireOffset = formattedValue.toString().length < 8;
    const negativeOffset = !noNegative && value < 0 ? 60 : 0;
    const offset = (fireOffset ? -40 : -20) + negativeOffset;
    return (
      <text
        x={x + width - offset + 10}
        y={y + height - 20 + 4 * dataSource.length}
        fontSize={12}
        fontWeight={600}
        fill={fireOffset ? "#636672" : "#fff"}
        textAnchor="end"
      >
        {formattedValue}
      </text>
    );
  };

  const customLabelName = ({ x, y, name, width }: any) => {
    const isNegative = Boolean(width < 0);
    return (
      <text
        x={isNegative && !noNegative ? x - 70 : x}
        y={y + 34 + (labelYOffset || 0)}
        fill={"#636672"}
        fontSize={12}
        fontWeight={600}
        textAnchor="start"
      >
        {name}
      </text>
    );
  };

  const padding = paddingX ? ` px-${paddingX}` : "";

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h2>{title}</h2>
        {!!titleLink && (
          <AdapterLink className={classes.link} to={titleLink.to}>
            {titleLink.label}
          </AdapterLink>
        )}
        {isDropdown && (
          <Dropdown onChange={onChange} value={value} items={list} />
        )}
      </div>
      <div className={`${height}${padding}`}>
        <ResponsiveContainer width="100%" height="100%">
          <Chart
            layout="vertical"
            width={600}
            height={300}
            data={dataSource}
            barCategoryGap={"30%"}
            margin={{
              right: 60,
              bottom: 20,
              left: 60,
              top: 0,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" vertical horizontal={false} />
            <XAxis
              tick={!hideAxis}
              type="number"
              axisLine={false}
              tickLine={false}
              dy={20}
              tickFormatter={formatter}
              domain={!xDomain ? undefined : [0, xDomain]}
            />
            <YAxis
              width={0}
              dataKey="name"
              type="category"
              axisLine={false}
              tick={false}
              tickLine={false}
            />
            {!hideTooltip && <Tooltip content={<CustomTooltip />} />}
            <Bar
              dataKey="value"
              fill="#00a0fc"
              radius={5}
              label={customLabelName}
              isAnimationActive={false}
            >
              {dataSource.map((_: any, index: number) => (
                <Cell key={`cell-${index}`} fill={displayColors[index]} />
              ))}
              <LabelList
                dataKey="value"
                content={renderCustomizedLabelValues}
                position="insideRight"
                style={{ fill: "white" }}
              />
            </Bar>
          </Chart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const boxStyles = makeStyles({
  container: {
    borderRadius: 28,
    background: colors.white,
    boxShadow: shadows[4],
  },
  header: {
    padding: "24px 24px 0px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h2": {
      color: colors.brandingBlue1,
      fontWeight: 600,
      margin: 0,
    },
    "&> div": {
      margin: 0,
      width: "max-content",
      height: "40px",
    },
    "& select": {
      lineHeight: "17px",
      background: "transparent !important",
    },
  },
  link: {
    color: colors.brandingBlue1,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

export default BarChart;
