import React from "react";
import { useSelector } from "react-redux";
import { MappedAccount } from "src/interfaces";

import {
  EuiLink,
  EuiToolTip,
  EuiBasicTableColumn,
  EuiBasicTable,
} from "@elastic/eui";
import { ACCOUNT_TYPES } from "src/interfaces";
import {
  getAllFormattedAssetsAndDebts,
  getNewProviders,
} from "src/store/account/selector";
import {
  getIsMarried,
  spouseSelector,
  userSelector,
} from "src/store/system/selector";
import { formatDollars } from "src/utils";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { Global, css } from "@emotion/react";

const globalStyles = css`
  .truncateText {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table-border {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    tr:last-child td {
      border-bottom: none;
    }
  }

  .table-container {
    max-height: 500px;
    overflow-y: auto;
    width: 100%;

    @media (min-width: 983px) {
      max-width: 983px;
    }
  }

  .newRow {
    background-color: #e6f9f7;
    animation: fadeout 5s;
  }

  @keyframes fadeout {
    from {
      background-color: #e6f9f7;
    }
    to {
      background-color: transparent;
    }
  }
`;

interface IProps {
  openForEditing: (account: MappedAccount) => void;
  showDebts?: boolean;
}

const AccountsTable = ({ showDebts, openForEditing }: IProps) => {
  const user = useSelector(userSelector);
  const isMarried = useSelector(getIsMarried);
  const spouse = useSelector(spouseSelector);
  const newProviders = useSelector(getNewProviders);
  const spouseName = spouse?.first_name || "";
  const myName = user?.first_name || "";
  const { assets, debts } = useSelector(getAllFormattedAssetsAndDebts);
  const truncateText = (text: string, maxLength = 15) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };
  const getRowProps = (item: any) => {
    const props = {
      className: item.isNew ? "newRow" : "",
    };
    return props;
  };

  const accounts: any[] = (showDebts
    ? debts.filter((account) => account.variable !== "fed_loan")
    : assets
  ).slice();
  accounts.sort((a, b) => b.id - a.id);
  if (showDebts) {
    const myFedLoans = {
      id: -1,
      variable: "fed_loan",
      who: "mine",
      balance: 0,
      firm: "",
      name: "My Federal Loans",
    };
    const spouseFedLoans = {
      id: -1,
      variable: "fed_loan",
      who: "spouse",
      balance: 0,
      firm: "",
      name: `${spouseName}'s Federal Loans`,
    };
    let myLoansExist = false;
    let spouseLoansExist = false;
    debts.forEach((account) => {
      if (account.variable === "fed_loan") {
        if (account.who === "spouse") {
          spouseLoansExist = true;
          spouseFedLoans.balance += account.balance || 0;
          spouseFedLoans.id = account.id;
        } else {
          myLoansExist = true;
          myFedLoans.balance += account.balance || 0;
          myFedLoans.id = account.id;
        }
      }
    });
    if (myLoansExist) {
      accounts.push(myFedLoans);
    }
    if (isMarried && spouseLoansExist) {
      accounts.push(spouseFedLoans);
    }
  }

  if (newProviders.length) {
    accounts.unshift(
      ...newProviders.map((provider, index) => ({
        id: -index,
        variable: "pending",
        firm: provider.name,
        who: provider.who,
        balance: 0,
        name: "",
      }))
    );
  }

  const columns: EuiBasicTableColumn<any>[] = [
    {
      field: "variable",
      name: "Type",
      sortable: false,
      truncateText: true,
      render: (value: string) => {
        if (value === "pending") {
          return "Pending linked account(s)";
        }
        const type = (ACCOUNT_TYPES as any)[value];
        const truncatedType = truncateText(type);
        return (
          <EuiToolTip content={type} position="bottom">
            <div>{truncatedType}</div>
          </EuiToolTip>
        );
      },
    },
    {
      field: "who",
      name: "Owner",
      sortable: false,
      truncateText: false,
      render: (value: string, account: MappedAccount) => {
        if (!value) {
          return "";
        }
        if (account.joint === "joint") {
          return "Joint";
        }
        return value === "spouse" ? spouseName : myName;
      },
    },
    {
      field: "firm",
      name: "Institution",
      sortable: false,
      truncateText: true,
      render: (firm: string) => {
        const truncatedFirm = truncateText(firm);
        return (
          <EuiToolTip content={firm} position="bottom">
            <div className="truncateText">{truncatedFirm}</div>
          </EuiToolTip>
        );
      },
    },
    {
      field: "name",
      name: "Account",
      sortable: false,
      truncateText: true,
      render: (name: string) => {
        const truncatedName = truncateText(name);
        return (
          <EuiToolTip content={name} position="bottom">
            <div className="truncateText">{truncatedName}</div>
          </EuiToolTip>
        );
      },
    },
    {
      field: "balance",
      name: "Balance",
      sortable: false,
      truncateText: false,
      render: (balance: number | null) => {
        if (!balance) {
          return "";
        }
        return formatDollars(balance);
      },
    },
    {
      field: "edit",
      name: "",
      width: "68px",
      sortable: false,
      truncateText: false,
      render: (value: undefined, account: any) => {
        if (account.variable === "pending") {
          return null;
        }
        return <EuiLink onClick={() => openForEditing(account)}>Edit</EuiLink>;
      },
    },
  ];

  return (
    <>
      <Global styles={globalStyles} />

      <StyledSpacer size="32px" />

      <EuiBasicTable
        className="table-border table-container"
        columns={columns}
        items={accounts}
        rowProps={getRowProps}
        responsive={true}
        tableLayout="fixed"
      />
    </>
  );
};

export default AccountsTable;
