import React from "react";

import { Box, Grid, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import { AdapterLink } from "src/utils";

const useStyles = makeStyles({
  description: {
    fontSize: 18,
    marginTop: 40,
    textAlign: "center",
  },
  items: {
    alignItems: "center",
    marginBottom: 40,
    marginTop: 20,
  },
  item: {
    marginTop: 10,
  },
  logo: {
    display: "block",
    margin: "0 auto",
  },
  getRates: {
    display: "block",
    marginTop: 6,
    textAlign: "center",
  },
  a: {},
});

export const Solution = ({ data }: any) => {
  const styles = useStyles();
  const { heading, description, buttons, img, links } = data;

  return (
    <>
      <Typography variant="h2" component="h2">
        {heading}
      </Typography>
      <Typography variant="body1" className={styles.description}>
        {description}
      </Typography>
      <Box className="my-5 flex justify-center">
        {!!buttons &&
          !!buttons.length &&
          buttons.map((button: any) => (
            <Button
              key={button.text}
              fbColor={button.color}
              component={AdapterLink}
              to={button.to}
            >
              {button.text}
            </Button>
          ))}
      </Box>
      {!!links && !!links.length && (
        <Grid container spacing={2} className={styles.items}>
          {links.map((link: any) => (
            <Grid key={link.href} item xs={6} sm={4} className={styles.item}>
              <a href={link.href} className={styles.a} target="_blank">
                <img src={link.image} className={styles.logo} />
                <Typography className={styles.getRates}>Get Rates</Typography>
              </a>
            </Grid>
          ))}
        </Grid>
      )}
      <Box className="w-full my-5 text-center">
        <Button variant="outlined" component={AdapterLink} to="/studentloans">
          Back
        </Button>
      </Box>
      <Typography variant="caption" className="block w-full text-center d">
        FitBUX receives compensation from the lenders on this platform. FitBUX
        does not charge a fee for any student loan borrowing services.
      </Typography>
    </>
  );
};

export default Solution;
