import * as React from "react";
import {
  Box,
  // makeStyles,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { AdapterLink } from "src/utils";

export const EditCompleted = () => {
  return (
    <Card title="">
      <Typography paragraph>
        Your investment allocations have been submitted. The updates will take
        effect within the next 24 hours.
      </Typography>
      <Box>
        <Button fbColor="primary" component={AdapterLink} to="/investments">
          Done
        </Button>
      </Box>
    </Card>
  );
};

export default EditCompleted;
