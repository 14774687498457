import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  Box,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { SPECIAL_REPAYMENTS } from "src/interfaces";
import { getAnyFedLoansLinked } from "src/store/account/selector";
import { getIdrData as selectIdrData } from "src/store/dashboard/selector";
import { getProfile, getSpouseProfile } from "src/store/profileBuild/selector";
import { investmentsStyles } from "src/theme";
import { AdapterLink, formatWholeDollars } from "src/utils";
import { AppState } from "src/store";

const useStyles = makeStyles({
  ...investmentsStyles,
  contentColumns: {
    margin: "20px auto",
    maxWidth: 600,
  },
  detailRow: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 6,
    marginTop: 6,
  },
  detail: {
    fontSize: 14,
  },
  button: {
    display: "block",
    margin: "18px auto 0",
  },
  notLinked: {},
});

export const IdrDetails = ({
  next,
  showSpouseData,
  toggleShowSpouseData,
}: any) => {
  const classes = useStyles();
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const idrData = useSelector(selectIdrData);

  const applicantIdr =
    SPECIAL_REPAYMENTS.indexOf(myProfile.fed_repayment_plan || "") >= 0;
  const spouseIdr =
    SPECIAL_REPAYMENTS.indexOf(spouseProfile.fed_repayment_plan || "") >= 0;

  useEffect(() => {
    if (!showSpouseData && !applicantIdr && spouseIdr) {
      toggleShowSpouseData();
    }
  }, [showSpouseData]);

  const data = showSpouseData
    ? idrData.spouseIdrOverview
    : idrData.myIdrOverview;
  const accounts = showSpouseData
    ? idrData.spouseIdrAccounts
    : idrData.myIdrAccounts;
  const selectedAccounts = accounts.filter((account) => account.selected);
  const canSelectSpouse = applicantIdr && spouseIdr;
  const linkedAccounts = useSelector((state: AppState) =>
    getAnyFedLoansLinked(state, showSpouseData)
  );

  const remainingTerm = data?.result?.remainingterm || 0;
  const remainingTermText = (remainingTerm / 12).toFixed(1);

  const getSaved = (target: any[][]) => {
    const nowMonthYear = new Date().toISOString().slice(0, 7);
    const targetMonthYear = target.find(
      (item: any[]) => item?.[0] === nowMonthYear
    );
    return targetMonthYear?.[1] || 0;
  };

  const loansNotLinked = linkedAccounts.length == 0;

  return (
    <>
      {loansNotLinked && (
        <Card title="IDR Details" className="mb-5">
          <Typography paragraph>
            IDR data can only be calculated if you have linked accounts
            currently in repayment.
          </Typography>
          <Typography paragraph>
            <AdapterLink to="/accounts">Please link your accounts.</AdapterLink>
          </Typography>
        </Card>
      )}
      {!loansNotLinked && (
        <>
          <Card
            divider
            title="IDR Details"
            className="mb-5"
            headComponent={
              canSelectSpouse ? (
                <Select
                  variant="outlined"
                  onChange={toggleShowSpouseData}
                  value={showSpouseData ? "spouse" : "mine"}
                >
                  <MenuItem value="mine">Mine</MenuItem>
                  <MenuItem value="spouse">Spouse</MenuItem>
                </Select>
              ) : undefined
            }
          >
            <Box className={classes.contentColumns}>
              {remainingTerm > 0 && (
                <>
                  <Box className={classes.detailRow}>
                    <Typography className={classes.detail}>
                      Expected Tax Liability in {remainingTermText} Years
                    </Typography>
                    <Typography className={classes.detail}>
                      {formatWholeDollars(data?.result?.taxliability || 0)}
                    </Typography>
                  </Box>
                  <Box className={classes.detailRow}>
                    <Typography className={classes.detail}>
                      Expected Amount Forgiven in {remainingTermText} Years
                    </Typography>
                    <Typography className={classes.detail}>
                      {formatWholeDollars(data?.result?.forgiven || 0)}
                    </Typography>
                  </Box>
                </>
              )}
              <Box className={classes.detailRow}>
                <Typography className={classes.detail}>
                  Expected Saved Amount to Date
                </Typography>
                <Typography className={classes.detail}>
                  {formatWholeDollars(getSaved(data?.target || []))}
                </Typography>
              </Box>
              <Box className={classes.detailRow}>
                <Typography className={classes.detail}>
                  Actual Saved Amount to Date
                </Typography>
                <Typography className={classes.detail}>
                  {formatWholeDollars(getSaved(data?.actual || []))}
                </Typography>
              </Box>
              <Box className={classes.detailRow}>
                <Typography className={classes.detail}>
                  Recommended Minimum Monthly Savings
                </Typography>
                <Typography className={classes.detail}>
                  {formatWholeDollars(data?.result?.monthlyamount || 0)}
                </Typography>
              </Box>
            </Box>
            <Typography className={classes.detail + " mt-5"}>
              Accounts used to save for tax:
              {!selectedAccounts.length ? " None" : ""}
            </Typography>
            {!!selectedAccounts.length && (
              <ul>
                {selectedAccounts.map((account) => (
                  <li>{account.name}</li>
                ))}
              </ul>
            )}
          </Card>
          <Button className={classes.continueButton} onClick={next}>
            Change Accounts &amp; Assumptions
          </Button>
        </>
      )}
    </>
  );
};

export default IdrDetails;
