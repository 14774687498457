import React from "react";

import {
  Box,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Chart from "src/components/Chart";
import TitleCard from "src/components/TitleCard";
import { MortgageCalculatorSummary } from "src/interfaces";
import { DollarTextField, formatWholeDollars } from "src/utils";
import { PRE_PAYMENT_HELP } from "./Constants";

interface MonthlyPrePaymentProps {
  totalMonthlyPayment: number;
  monthlyPayment: number;
  prePayment: number;
  isCalculated: boolean;
  formattedData: any;
  totalIncome: number;
  mortgageDetails: {
    month: number;
    year: number;
    rate: number;
    balance: number;
    term: number;
  };
  onPrePaymentChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onBackClick(): void;
  onCalculate(): void;
  result?: MortgageCalculatorSummary;
}

const MonthlyPrePayment = ({
  monthlyPayment,
  prePayment,
  onPrePaymentChange,
  onBackClick,
  onCalculate,
  totalMonthlyPayment,
  isCalculated,
  formattedData,
  result,
}: MonthlyPrePaymentProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <TitleCard title=" Monthly Prepayments" sideContent={PRE_PAYMENT_HELP}>
        <Table className={classes.prePaymentContentContainer}>
          <TableRow className={classes.prePaymentContentItem}>
            <TableCell className={classes.cell}>
              <Typography>Estimated required monthly payment.</Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography variant="body2">
                {formatWholeDollars(monthlyPayment)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow className={classes.prePaymentContentItem}>
            <TableCell className={classes.cell}>
              <Typography>
                What total monthly prepayment would you like to make/consider
                making?
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <DollarTextField
                className={classes.prePaymentText}
                value={prePayment}
                variant="outlined"
                onChange={onPrePaymentChange}
                decimalScale={"none"}
              />
            </TableCell>
          </TableRow>
          <Divider />
          <TableRow className={classes.prePaymentContentItem}>
            <TableCell className={classes.cell}>
              <Typography variant="h6">Total Monthly Payment</Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography variant="subtitle1">
                {formatWholeDollars(totalMonthlyPayment)}
              </Typography>
            </TableCell>
          </TableRow>
          <Divider />
        </Table>
      </TitleCard>
      <Box className={classes.prePaymentActionContainer}>
        <Button onClick={onBackClick}>Back</Button>
        <Button fbColor="primary" onClick={onCalculate}>
          Calculate
        </Button>
      </Box>
      {isCalculated && !!result && (
        <>
          <Box className={classes.resultRoot}>
            <Chart
              dollars
              title="Pay Off Comparison"
              data={formattedData}
              height="322px"
              keys={["normal", "prepay"]}
              xInterval={12}
              xKey="month"
              xMargin={50}
              yMargin={15}
              keyLabels={{
                normal: "Existing schedule",
                prepay: "Schedule with prepayment",
              }}
              width="100%"
            />
          </Box>
          <TitleCard title="Summary of Payments">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />

                    <TableCell className={classes.columnHeader}>
                      No Prepayment
                    </TableCell>
                    <TableCell className={classes.columnHeader}>
                      With Prepayment
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.rowHeader}>
                      Required Monthly Payment Amount
                    </TableCell>
                    <TableCell>
                      {formatWholeDollars(result?.normal.monthlyPayment)}
                    </TableCell>
                    <TableCell>
                      {formatWholeDollars(result.withPrePayment.monthlyPayment)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.rowHeader}>
                      Monthly Prepayment Amount
                    </TableCell>
                    <TableCell />
                    <TableCell>
                      {formatWholeDollars(
                        result?.withPrePayment.monthlyPrePayment || 0
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.rowHeader}>
                      Total Monthly Payment
                    </TableCell>
                    <TableCell>
                      {formatWholeDollars(result?.normal.totalMonthlyPayment)}
                    </TableCell>
                    <TableCell>
                      {formatWholeDollars(
                        result?.withPrePayment.totalMonthlyPayment
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.rowHeader}>
                      Debt-to-Income Ratio
                    </TableCell>
                    <TableCell>{result.normal.debtToIncome}</TableCell>
                    <TableCell>{result.withPrePayment.debtToIncome}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.rowHeader}>
                      Total Cumulative Payment Amount
                    </TableCell>
                    <TableCell>
                      {formatWholeDollars(
                        result?.normal.cumulativePaymentAmount
                      )}
                    </TableCell>
                    <TableCell>
                      {formatWholeDollars(
                        result?.withPrePayment.cumulativePaymentAmount
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.rowHeader}>
                      Repayment Period
                    </TableCell>
                    <TableCell>{result.normal.repaymentPeriod} Years</TableCell>
                    <TableCell>
                      {result?.withPrePayment.repaymentPeriod} Years
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.rowHeader}>
                      Estimated Date Of Completion
                    </TableCell>
                    <TableCell>{result.normal.dateOfCompletions}</TableCell>
                    <TableCell>
                      {result.withPrePayment.dateOfCompletions}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TitleCard>
          <Box className={classes.prePaymentActionContainer}>
            <Button onClick={onBackClick}>Back</Button>
          </Box>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  prePaymentContainer: {
    width: "100%",
  },
  prePaymentContentContainer: {
    margin: theme.spacing(0),
    padding: 0,
  },
  prePaymentContentItem: {
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: 10,
  },
  cell: {
    border: 0,
    display: "flex",
  },
  resultRoot: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    margin: "20px auto",
  },
  prePaymentText: {
    width: 150,
  },
  prePaymentActionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
    gap: 40,
  },
  topBox: {
    marginBottom: 20,
    marginTop: 40,
    padding: 20,
  },
  inputRow: {
    alignItems: "center",
    display: "flex",
    height: 45,
    justifyContent: "space-between",
    marginTop: 5,
  },
  tabs: {
    height: 42,
    padding: 4,
    margin: "16px 0px 6px",
  },
  chartContainer: {},
  summaryRow: {},
  groupHeader: {},
  columnHeader: {
    wordBreak: "break-word",
  },
  rowHeader: {
    fontWeight: 600,
    wordBreak: "break-word",
  },
}));

export default MonthlyPrePayment;
