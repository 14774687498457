import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";

import PersonalInfo from "./MainForm";
import Welcome from "./Welcome";

const personalInfoViews = {
  [PROFILE_BUILD_STEPS.WELCOME]: Welcome,
  [PROFILE_BUILD_STEPS.PERSONAL_INFO]: PersonalInfo,
};

export default personalInfoViews;
