import React from "react";

import { Box, makeStyles } from "@material-ui/core";

import Button from "src/components/Button";

const useStyles = makeStyles({
  bigButton: {
    display: "block",
    height: 80,
    margin: "20px auto",
    width: 500,
  },
  disclaimer: {
    position: "fixed",
    bottom: 40,
    fontSize: 12,
  },
});

const UserOrSpouse = ({ next }: any) => {
  const styles = useStyles();

  return (
    <>
      <Box className="mt-12">
        <Button
          variant="outlined"
          fbColor="primary"
          className={styles.bigButton}
          onClick={() => next("applicant")}
        >
          This Is For My Loans
        </Button>
        <Button
          variant="outlined"
          fbColor="primary"
          className={styles.bigButton}
          onClick={() => next("spouse")}
        >
          This Is For My Spouse's Loans
        </Button>
      </Box>
    </>
  );
};

export default UserOrSpouse;
