import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, makeStyles, Typography } from "@material-ui/core";

import TitleCard from "src/components/TitleCard";
import { openUpgradeDialog } from "src/store/system/actions";
import { getIsSubscribed } from "src/store/system/selector";
import Button from "src/components/Button";

interface LandingStepProps {
  onNext(): void;
}

const LandingStep = ({ onNext }: LandingStepProps) => {
  const dispatch = useDispatch();
  const paidAccount = useSelector(getIsSubscribed);
  const classes = useStyles();

  return (
    <>
      <TitleCard title="How Much Should I Have In An Emergency Fund?">
        <Box className={classes.container}>
          <Typography>
            FitBUX's AI-powered technology analyzes your income, expenses, and
            the level of risk associated to your income in order to recommend an
            optimized emergency fund amount.
          </Typography>
          <Typography>
            As always, make sure that your profile is up-to-date before using
            this tool.
          </Typography>
        </Box>
      </TitleCard>

      <Box className={classes.buttonContainer}>
        {!paidAccount ? (
          <Button
            onClick={() => dispatch(openUpgradeDialog())}
            className="text-white"
            color="primary"
          >
            Upgrade to Premium
          </Button>
        ) : (
          <Button onClick={() => onNext()} color="primary">
            Next
          </Button>
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

export default LandingStep;
