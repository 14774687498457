import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import { colors } from "src/theme";
import fbColors from "src/theme/colors";
import {
  DollarTextField,
  formatWholeDollars,
  PercentTextField,
} from "src/utils";

interface PercentBreakdownProps {
  min?: number;
  max?: number;
  income: number;
  onChange: (value: any) => void;
  values: any;
  label: string;
  isDebt?: boolean;
  who?: "applicant" | "spouse";
  incomeType?: string;
  unlimited?: boolean;
}

const whoStrings = {
  single: "",
  applicant: " My",
  spouse: " Spouse's",
};

const PercentBreakdown = ({
  min,
  onChange,
  income,
  values,
  label,
  isDebt,
  max,
  who,
  incomeType,
  unlimited,
}: PercentBreakdownProps) => {
  const styles = useStyles();
  const whoText = who ? whoStrings[who] : whoStrings["single"];
  const incomeText = incomeType ? `${incomeType} Income` : "Total Income";

  const handleContributionChange = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;

    let contribution;
    if (field === "annual") {
      contribution = value;
    } else if (field === "monthly") {
      contribution = value * 12;
    } else {
      contribution = Math.round((value * income) / 100);
    }

    const newFormValues = {
      ...values,
      contribution,
      percent: (contribution / income) * 100,
    };

    onChange(newFormValues);
  };

  return (
    <Box>
      <Box className={styles.row}>
        <Box className={styles.headerContainer} />
        <Box
          className={styles.headerCell}
        >{`% of${whoText} ${incomeText}`}</Box>
        <Box className={styles.headerCell}>Monthly</Box>
        <Box className={styles.headerCell}>Annually</Box>
      </Box>
      <Box className={styles.row}>
        <Box className={styles.typeLabel}>
          {label} {isDebt ? "Payment" : "Contribution"}
        </Box>
        <Box className={styles.cell}>
          <PercentTextField
            name="contribution"
            variant="outlined"
            value={
              values.percent ||
              ((values.contribution || 0) / income) * 100 ||
              null
            }
            placeholder="0%"
            onChange={handleContributionChange}
            unlimited={unlimited}
          />
          {typeof max === "number" && (
            <Typography variant="body1">
              Max: {(Math.floor((max / income) * 100 * 100) / 100).toFixed(2)}%
            </Typography>
          )}
          {typeof min === "number" && (
            <Typography variant="body1" style={{ color: colors.error }}>
              Min: {(Math.ceil((min / income) * 100 * 100) / 100).toFixed(2)}%
            </Typography>
          )}
        </Box>
        <Box className={styles.cell}>
          <DollarTextField
            name="monthly"
            placeholder="$0"
            onChange={handleContributionChange}
            variant="outlined"
            max={typeof max === "number" ? Math.floor(max / 12) : undefined}
            value={Math.round((values.contribution || 0) / 12)}
          />
          {typeof max === "number" && (
            <Typography variant="body1">
              Max: {formatWholeDollars(Math.floor(max / 12))}
            </Typography>
          )}
          {typeof min === "number" && (
            <Typography variant="body1" style={{ color: colors.error }}>
              Min: {formatWholeDollars(Math.ceil(min / 12))}
            </Typography>
          )}
        </Box>
        <Box className={styles.cell}>
          <DollarTextField
            name="annual"
            placeholder="$0"
            onChange={handleContributionChange}
            variant="outlined"
            max={typeof max === "number" ? Math.floor(max) : undefined}
            value={values.contribution}
          />
          {typeof max === "number" && (
            <Typography variant="body1">
              Max: {formatWholeDollars(Math.floor(max))}
            </Typography>
          )}
          {!!min && (
            <Typography variant="body1" style={{ color: colors.error }}>
              Min: {formatWholeDollars(Math.ceil(min))}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PercentBreakdown;

const useStyles = makeStyles({
  cell: {
    width: "30%",
    marginLeft: 4,
    fontSize: 12,
  },
  headerCell: {
    fontWeight: "bolder",
    width: "30%",
    fontSize: 14,
    marginBottom: 15,
    color: fbColors.brandingBlue1,
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  typeLabel: {
    "text-transform": "capitalize",
    fontWeight: "bold",
    width: "35%",
    fontSize: 14,
  },
  headerContainer: {
    width: "35%",
  },
});
