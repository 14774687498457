import React from "react";
import { useSelector } from "react-redux";

import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";

import {
  getNetProjection,
  getMinFutureProjections,
} from "src/store/planBuild/selector";
import { formatWholeDollars } from "src/utils";
import OverviewTable from "../components/OverviewTable";
import Chart from "src/components/Chart";
import CenterContainer from "src/pages/PlanBuilder/Components/CenterContainer";
import { colors } from "src/theme";

const NetWealth = ({ optimized }: { optimized?: boolean }) => {
  const styles = useStyles();
  const netProjection = useSelector(getNetProjection);
  const future = useSelector(getMinFutureProjections);
  const orderIndex = optimized ? 6 : 5;
  const title = `${orderIndex}. Net Wealth Summary`;

  const now = new Date();
  const nowYear = now.getFullYear();
  const years = [
    "Now",
    "" + (nowYear + 5),
    "" + (nowYear + 10),
    future.retirementYear || "",
  ];

  return (
    <CenterContainer review title={title}>
      <Grid container spacing={3} className="mt-2 h-full">
        <Grid item xs={6}>
          <Chart
            dollars
            noTitlePadding
            centerTitle
            title="Projected Total Net Wealth"
            data={netProjection}
            height="322px"
            keys={["net", "min", "hc"]}
            xKey="month"
            keyLabels={{
              net: "Plan Followed",
              min: "Plan Not Followed",
              hc: "Human Capital",
            }}
            width="100%"
          />
        </Grid>

        <Grid
          item
          xs={6}
          style={{
            height: "100%",
            overflowY: "scroll",
          }}
        >
          {!!future.net.length && (
            <OverviewTable
              title="Net Wealth:"
              columns={years}
              rows={[
                {
                  header: "Total if plan followed",
                  columns: years.map((year: string, index: number) =>
                    formatWholeDollars(future.net[index].plan)
                  ),
                  children: [
                    {
                      header: "Human Capital",
                      columns: years.map((year, index: number) =>
                        formatWholeDollars((future.hc as any)[index].plan)
                      ),
                    },
                    {
                      header: "Financial Assets",
                      columns: years.map((year, index: number) =>
                        formatWholeDollars(future.assets[index].plan.total)
                      ),
                    },
                    {
                      header: "(Less) Debt",
                      columns: years.map((year, index: number) =>
                        formatWholeDollars(future.debts[index].plan.total)
                      ),
                    },
                  ],
                },
                {
                  header: "Total if plan not followed",
                  columns: years.map((year: string, index: number) =>
                    formatWholeDollars(future.net[index].min)
                  ),
                  children: [
                    {
                      header: "Human Capital",
                      columns: years.map((year, index: number) =>
                        formatWholeDollars((future.hc as any)[index].min)
                      ),
                    },
                    {
                      header: "Financial Assets",
                      columns: years.map((year, index: number) =>
                        formatWholeDollars(future.assets[index].min.total)
                      ),
                    },
                    {
                      header: "(Less) Debt",
                      columns: years.map((year, index: number) =>
                        formatWholeDollars(future.debts[index].min.total)
                      ),
                    },
                  ],
                },
              ]}
            />
          )}

          <Grid item xs={12}>
            <Paper className="px-4 py-5">
              <Box>
                <Typography variant="h2" className={styles.title}>
                  Details
                </Typography>
              </Box>

              <Box className="mt-2">
                {future.retirementYear && (
                  <>
                    <Typography style={{ fontSize: "13px" }}>
                      When we are young, most of our net wealth is in the form
                      of human capital. Over time, one's human capital is
                      converted into income, which is then used to pay off debt
                      or invested to grow assets.
                    </Typography>

                    <Typography className="my-2" style={{ fontSize: "13px" }}>
                      Therefore, one of your top goals is to maximize your
                      tangible net wealth (i.e., the sum of your assets less
                      your debts).
                    </Typography>

                    <Typography style={{ fontSize: "13px" }}>
                      This chart shows how efficiently you convert your human
                      capital to tangible net wealth.
                    </Typography>
                  </>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </CenterContainer>
  );
};

export default NetWealth;

const useStyles = makeStyles({
  actionBar: {
    background: "white",
    padding: "12px 68px",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    width: "100%",
  },
  actionButton: {
    width: 168,
  },
  cardRowCell: {
    fontSize: 12,
    width: "16%",
  },
  cardRowLabel: {
    fontSize: 12,
    paddingLeft: 10,
    width: "36%",
  },
  cardTableHeader: {},
  chart: {
    minWidth: 440,
    maxWidth: 524,
  },
  loaderContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 555,
    background: "rgba(255, 255, 255, .8)",
  },
  title: {
    fontSize: 14,
    color: colors.brandingBlue1,
    fontWeight: 600,
  },
  subheading: {
    fontSize: 12,
    color: colors.brandingBlue2,
    fontWeight: 600,
    marginTop: "10px",
  },
});
