import React from "react";
import { CircularProgress, Container } from "@material-ui/core";

export const AppLoader = () => {
  return (
    <Container className="h-full justify-center flex items-center">
      <CircularProgress size={150} color="secondary" thickness={0.5} />
    </Container>
  );
};
