import React from "react";

import { Box, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";

export const SelectMineOrSpouse = ({ goBack, onNext, setIsSpouse }: any) => {
  const chooseMine = () => {
    setIsSpouse(false);
    onNext();
  };
  const chooseSpouse = () => {
    setIsSpouse(true);
    onNext();
  };

  return (
    <Card style={{ maxWidth: 900, margin: "0 auto" }}>
      <Typography variant="body1">
        Whose loans are you looking to refinance?
      </Typography>
      <Typography
        className="underline my-8 mx-auto text-center"
        variant="body1"
      >
        Choose one of the following:
      </Typography>
      <Box className="flex justify-center">
        <Button className="mr-8" fbColor="primary" onClick={chooseMine}>
          My Loans
        </Button>
        <Button fbColor="primary" onClick={chooseSpouse}>
          My Spouse's Loans
        </Button>
      </Box>
      <Button className="mt-12" fbColor="secondary" onClick={goBack} fullWidth>
        Back
      </Button>
    </Card>
  );
};

export default SelectMineOrSpouse;
