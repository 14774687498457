import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { Box, makeStyles, Typography } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

import { getRouterState } from "src/store/router/selector";
import { colors } from "src/theme";
import shadows from "src/theme/shadows";

const useStyles = makeStyles({
  container: {
    margin: "0 auto",
    maxWidth: 1000,
  },
  bigButton: {
    alignItems: "center",
    background: colors.gradButtonLight,
    // border: `1px solid ${colors.brandingBlue1}`,
    borderRadius: 12,
    boxShadow: shadows[2],
    cursor: "pointer",
    display: "flex",
    height: 100,
    justifyContent: "space-between",
    margin: "30px auto",
    padding: "0 20px",
    width: 800,
    "&:hover": {
      background: colors.gradLightHorizontal,
    },
    "&:active": {
      background: colors.gradButtonBright,
    },
  },
  buttonText: {
    fontSize: 15,
  },
});

export const SelectChoices = ({ next }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useSelector(getRouterState);

  const goToAllocations = () => {
    let target = "/investment-allocations";
    if (router.location.query?.return) {
      target += `?return=${router.location.query.return}`;
    }
    dispatch(push(target));
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h2" className="text-center">
        Choose One Of The Following:
      </Typography>
      <Box className={classes.bigButton} onClick={goToAllocations}>
        <Typography variant="body1" className={classes.buttonText}>
          Review my current investment allocation details relative to FitBUX's
          recommended investment allocations.
        </Typography>
        <DoubleArrowIcon />
      </Box>
      <Box className={classes.bigButton} onClick={next}>
        <Typography variant="body1" className={classes.buttonText}>
          Make changes to my existing investment allocations and compare the
          results to FitBUX's recommended investment allocations.
        </Typography>
        <DoubleArrowIcon />
      </Box>
    </Box>
  );
};

export default SelectChoices;
