import React, { useMemo, useState } from "react";

import {
  Box,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import Icon from "src/components/Icon";
import { INVESTMENT_TYPE_LIST, INVESTMENT_TYPES } from "src/interfaces";
import { colors } from "src/theme";
import { DollarTextField } from "src/utils/inputMask";

const useStyles = makeStyles({
  text: { fontSize: 14 },
  headerCell: {
    border: 0,
    color: colors.brandingBlue1,
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
  },
  cell: {
    border: 0,
    fontSize: 14,
  },
  rightColumn: {},
  addButton: {
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 13,
      fontWeight: 500,
    },
  },
  buttonContainer: {
    margin: "40px auto",
    display: "flex",
    justifyContent: "center",
  },
  tableContainer: {
    margin: "12px auto",
    maxWidth: 900,
  },
});

const Input = ({ back, next, investments, setInvestments }: any) => {
  const styles = useStyles();
  const [menuAnchor, setMenuAnchor] = useState(null);

  const addAccount = (accountType: string, isSpouse: boolean) => {
    setInvestments((current: any[]) => [
      ...current,
      {
        type: accountType,
        balance: 0,
        contribution: 0,
        who: isSpouse ? "spouse" : "applicant",
      },
    ]);
    setMenuAnchor(null);
  };

  const removeAccount = (index: number) =>
    setInvestments((current: any[]) => {
      const newInvestments = [...current];
      newInvestments.splice(index, 1);
      return newInvestments;
    });

  const setInvestmentValue = (value: number, index: number, key: string) => {
    setInvestments((current: any[]) => {
      const newInvestments = [...current];
      newInvestments[index] = { ...newInvestments[index], [key]: value };
      return newInvestments;
    });
  };

  const openAddMenu = (event: React.MouseEvent<any>) => {
    setMenuAnchor(event.currentTarget);
  };

  const submit = () => {
    next();
  };

  const totalBalance = useMemo(
    () =>
      investments.reduce((total: number, item: any) => total + item.balance, 0),
    [investments]
  );

  const nextDisabled = totalBalance <= 0;

  return (
    <>
      <Card title="Your Investment Accounts">
        <TableContainer className={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.headerCell} />
                <TableCell className={styles.headerCell}>
                  Current Balance
                </TableCell>
                <TableCell className={styles.headerCell}>
                  Monthly Contribution
                </TableCell>
                <TableCell className={styles.headerCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {investments.map((item: any, index: number) => (
                <TableRow key={item.type + item.who + index}>
                  <TableCell className={styles.cell}>
                    {INVESTMENT_TYPES[item.type] +
                      (item.who === "spouse" ? " (Spouse)" : "")}
                  </TableCell>
                  <TableCell className={styles.cell}>
                    <DollarTextField
                      variant="outlined"
                      onChange={(e: any) =>
                        setInvestmentValue(e.target.value, index, "balance")
                      }
                      placeholder="$0"
                      value={item.balance}
                    />
                  </TableCell>
                  <TableCell className={styles.cell}>
                    <DollarTextField
                      variant="outlined"
                      onChange={(e: any) =>
                        setInvestmentValue(
                          e.target.value,
                          index,
                          "contribution"
                        )
                      }
                      placeholder="$0"
                      value={item.contribution}
                    />
                  </TableCell>
                  <TableCell className={styles.cell}>
                    <IconButton
                      color="primary"
                      onClick={() => removeAccount(index)}
                    >
                      <Icon iconName="fb-trash-can" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          aria-controls="add-account-menu"
          aria-haspopup="true"
          variant="outlined"
          className={styles.addButton}
          color="primary"
          endIcon={<Icon iconName="fb-add-alt" />}
          onClick={openAddMenu}
        >
          Add Account
        </Button>
        <Menu
          id="add-account-menu"
          anchorEl={menuAnchor}
          keepMounted
          open={!!menuAnchor}
          onClose={() => setMenuAnchor(null)}
        >
          <>
            {INVESTMENT_TYPE_LIST.slice(0, -1).map((accountType: string) => (
              <MenuItem
                key={accountType}
                onClick={() => addAccount(accountType, false)}
              >
                {INVESTMENT_TYPES[accountType]}
              </MenuItem>
            ))}
            {INVESTMENT_TYPE_LIST.slice(0, -1).map((accountType: string) => (
              <MenuItem
                key={accountType + "s"}
                onClick={() => addAccount(accountType, true)}
              >
                {INVESTMENT_TYPES[accountType]} (Spouse)
              </MenuItem>
            ))}
          </>
        </Menu>
      </Card>
      <Box className={styles.buttonContainer}>
        <Button fbColor="primary" onClick={back} className="mr-4">
          Back
        </Button>
        <Button fbColor="primary" onClick={submit} disabled={nextDisabled}>
          Next
        </Button>
      </Box>
    </>
  );
};

export default Input;
