import React from "react";

import { Box, Divider, makeStyles, Typography } from "@material-ui/core";

import {
  Breakout,
  DEDUCTION_BREAKOUTS,
  INCOME_BREAKOUTS,
} from "src/interfaces";
import colors from "src/theme/colors";
import { formatDollarsAndCents } from "src/utils";

const useStyles = makeStyles({
  bolder: {
    color: colors.brandingBlue1,
    fontSize: 14,
    fontWeight: 600,
    margin: "10px 0",
  },
});

const TransactionSummary = ({ formValues }: any) => {
  const styles = useStyles();
  const incomes: Breakout[] = formValues.incomes || [];
  const deductions: Breakout[] = formValues.deductions || [];
  const gross = incomes.reduce(
    (result: number, item: Breakout) => result + Math.abs(item.amount),
    0
  );
  const deductionTotal = deductions.reduce(
    (result: number, item: Breakout) => result + Math.abs(item.amount),
    0
  );
  const calculatedNet = gross - deductionTotal;

  return (
    <Box>
      <Typography variant="h2" component="h2">
        Transaction Summary
      </Typography>
      <Typography variant="body1">
        Confirm that the information below is correct.
      </Typography>
      <Box className="flex justify-between">
        <Typography variant="body1" className={styles.bolder}>
          Total Income
        </Typography>
        <Typography variant="body1" className={styles.bolder}>
          {formatDollarsAndCents(gross)}
        </Typography>
      </Box>
      <>
        {incomes.map((item: Breakout, index: number) => (
          <Box className="flex justify-between" key={index}>
            <Typography variant="body1">
              {INCOME_BREAKOUTS[
                (item.type + "") as keyof typeof INCOME_BREAKOUTS
              ] || ""}
            </Typography>
            <Typography variant="body1">
              {formatDollarsAndCents(Math.abs(item.amount))}
            </Typography>
          </Box>
        ))}
      </>
      <Box className="flex justify-between">
        <Typography variant="body1" className={styles.bolder}>
          Total Deductions
        </Typography>
        <Typography variant="body1" className={styles.bolder}>
          {formatDollarsAndCents(deductionTotal)}
        </Typography>
      </Box>
      <>
        {deductions.map((item: Breakout, index: number) => (
          <Box className="flex justify-between" key={index}>
            <Typography variant="body1">
              {DEDUCTION_BREAKOUTS[
                (item.type + "") as keyof typeof DEDUCTION_BREAKOUTS
              ] || ""}
            </Typography>
            <Typography variant="body1">
              {formatDollarsAndCents(Math.abs(item.amount))}
            </Typography>
          </Box>
        ))}
      </>
      <Divider className="my-4" />
      <Box className="flex justify-between">
        <Typography variant="body1" className={styles.bolder}>
          Net Pay
        </Typography>
        <Typography variant="body1" className={styles.bolder}>
          {formatDollarsAndCents(calculatedNet)}
        </Typography>
      </Box>
    </Box>
  );
};

export default TransactionSummary;
