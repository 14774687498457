import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiFormRow,
  EuiComboBox,
} from "@elastic/eui";
import {
  StyledEuiButton,
  StyledEuiButtonEmpty,
  StyledFormRow,
  StyledSpacer,
  StyledEuiTitle,
  StyledEuiHorizontalRule,
  StyledEuiIcon,
  StyledEuiLink,
} from "src/components/Global/StyledComponents";
import {
  addCashflow,
  AddCashflowItemPayload,
  editCashflow,
  removeCashflow,
  replaceCashflow,
} from "src/store/cashflow/actions";
import { getCashflows } from "src/store/cashflow/selector";
import {
  PROFILE_EXPENSE_TYPES,
  EXTRA_EXPENSE_TYPES,
  ViewComponent,
} from "src/interfaces";
import { setProfileStep } from "src/store/profileBuild/actions";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import { getIsMarried } from "src/store/system/selector";
import { DollarTextField } from "src/utils";
import { MainContainer } from "../../components";
import useWindowSize from "src/hooks/useWindowSize";
import { closeTourCard } from "src/store/tourcard/actions";

const globalStyles = css`
  .euiComboBoxOptionsList__rowWrap div {
    width: 100% !important;
  }
  .euiComboBoxOptionsList div.euiFlexItem {
    width: 1rem !important;
  }
  div.euiComboBoxTitle {
    font-size: 16px;
    font-weight: 600;
  }
  @media (min-width: 769px) {
    .euiComboBoxOptionsList {
      width: 300px !important;
    }
  }
  @media (max-width: 769px) {
    .mobile-field-row {
      flex-wrap: nowrap !important;
    }
    .mobile-type {
      max-width: 100% !important;
    }
    .euiComboBoxPill {
      max-width: none !important;
    }
  }
  @media (max-width: 576px) {
    .mobile-group {
      flex-direction: column !important;
    }

    .mobile-group .mobile-field {
      flex: 1 0 50% !important;
      max-width: calc(50% - 4px) !important;
      margin-right: 4px !important;
    }

    .mobile-group .mobile-field:last-child {
      margin-right: 0 !important;
    }

    .mobile-field-row {
      flex-wrap: nowrap !important;
    }
  }
`;

const StyledEuiFlexGroup = styled(EuiFlexGroup)`
  padding-right: 44px;
`;

const now = -Date.now();
const emptyExpenses: any[] = [
  {
    id: now - 1,
    type: "grocery",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 2,
    type: "hoa",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 3,
    type: "prop_tax",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 4,
    type: "rent",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 5,
    type: "clothing",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 6,
    type: "fuel",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 7,
    type: "power",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 8,
    type: "phone",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 9,
    type: "water",
    annual: 0,
    monthly: 0,
  },
];

const ProfileExpenses: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const isMarried = useSelector(getIsMarried);
  const { expenses } = useSelector(getCashflows);
  const { width } = useWindowSize();
  const isMobileView = width <= 768;
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const [firstItemWidth, setFirstItemWidth] = useState<number | null>(null);
  const startingExpenses = [...emptyExpenses].map((emptyItem) => {
    const existingExpense = expenses.find(
      (existing) => existing.type === emptyItem.type
    );
    if (existingExpense) {
      return existingExpense;
    }
    return emptyItem;
  });
  const [displayExpenses, setDisplayExpenses] = useState(startingExpenses);
  const [extraEntries, setExtraEntries] = useState<any[]>(
    expenses.filter(
      (item) => !emptyExpenses.find((emptyItem) => item.type === emptyItem.type)
    )
  );

  if (!render) {
    return <div />;
  }

  useEffect(() => {
    const updateWidth = (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        setFirstItemWidth(entry.contentRect.width);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);
    const firstItem = document.querySelector(".first-item"); // Select the first EuiFlexItem

    if (firstItem) {
      resizeObserver.observe(firstItem);
    }

    return () => {
      if (firstItem) {
        resizeObserver.unobserve(firstItem);
      }
    };
  }, []);

  const setItemType = (value: string, index: number) =>
    setExtraEntries((current) => {
      const newList = [...current];
      newList[index] = {
        ...newList[index],
        type: value,
        typeChanged: true,
      };
      return newList;
    });

  const setFieldValue = (
    e: React.ChangeEvent<any>,
    index: number,
    isExtra: boolean
  ) => {
    const key = e.target.name;
    const splitKey = key.split("__");
    const isMonthly = splitKey[0] === "monthly";
    const rawValue = Math.floor(e.target.value);
    const annual = isMonthly ? rawValue * 12 : rawValue;
    const monthly = isMonthly ? rawValue : Math.floor(rawValue / 12);
    const updateFn = (current: any) => {
      const newList = [...current];
      newList[index] = { ...newList[index], annual, monthly };
      return newList;
    };
    if (isExtra) {
      setExtraEntries(updateFn);
    } else {
      setDisplayExpenses(updateFn);
    }
  };

  const addEmptyItem = () =>
    setExtraEntries((current) => [
      ...current,
      { id: -1, type: "", annual: 0, monthly: 0 },
    ]);

  // const truncatedLabels = Object.fromEntries(
  //   Object.entries(EXTRA_EXPENSE_TYPES).map(([key, value]) => [
  //     key,
  //     value.length > MAX_LENGTH ? value.substring(0, MAX_LENGTH) + '...' : value,
  //   ])
  // );

  const save = () => {
    displayExpenses.forEach((item: any) => {
      if (item.id >= 0) {
        dispatch(
          editCashflow({
            id: item.id,
            amount: Math.abs(item.annual || 0),
          })
        );
      } else if (item.annual) {
        dispatch(
          addCashflow({
            cashflow: {
              type: item.type,
              amount: Math.abs(item.annual || 0),
            },
            temporaryId: item.id,
          })
        );
      }
    });
    extraEntries.forEach((item: any) => {
      if (item.id >= 0) {
        if (item.typeChanged) {
          const replacePayload: any = {
            id: item.id,
            newItem: {
              type: item.type,
              amount: Math.abs(item.annual || 0),
            },
          };
          dispatch(replaceCashflow(replacePayload));
        } else {
          const amount = Math.abs(item.annual || 0);
          if (!amount) {
            dispatch(removeCashflow(item.id));
          } else {
            const payload = {
              id: item.id,
              amount: Math.abs(item.annual || 0),
            };
            dispatch(editCashflow(payload));
          }
        }
      } else if (item.annual) {
        const cashflow: any = {
          type: item.type,
          amount: Math.abs(item.annual || 0),
        };
        const payload: AddCashflowItemPayload = {
          cashflow,
          temporaryId: item.id,
        };
        dispatch(addCashflow(payload));
      }
    });
    expenses.forEach((item) => {
      if (
        !(PROFILE_EXPENSE_TYPES as any)[item.type] &&
        !extraEntries.find((entry) => entry.type === item.type)
      ) {
        dispatch(removeCashflow(item.id));
      }
    });
  };

  const deleteItem = (index: number) =>
    setExtraEntries((current) => {
      const newEntries = [...current];
      newEntries.splice(index, 1);
      return newEntries;
    });

  const onPrev = () => {
    save();
    dispatch(
      setProfileStep(
        isMarried
          ? PROFILE_BUILD_STEPS.SPOUSE_INCOME
          : PROFILE_BUILD_STEPS.MY_INCOME
      )
    );
    dispatch(closeTourCard());
  };

  const next = () => {
    save();
    dispatch(setProfileStep(PROFILE_BUILD_STEPS.DEBTS));
    dispatch(closeTourCard());
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  };

  const saveAndExit = () => {
    save();
    dispatch(closeTourCard());
    dispatch(push("/profile-builder-continue"));
  };

  return render({
    component: (
      <MainContainer fluid ref={mainContainerRef}>
        <Global styles={globalStyles} />
        <StyledFormRow>
          <Col
            xl={{ span: width > 1401 ? 6 : 7, offset: 2 }}
            lg={{ span: 9, offset: 1 }}
            md={{ span: 12 }}
          >
            <EuiButtonEmpty
              color="text"
              flush="left"
              iconType="arrowLeft"
              onClick={onPrev}
            >
              Back
            </EuiButtonEmpty>

            <StyledSpacer size="32px" />

            <EuiTitle size="l" className="header-font">
              <h1>Household expenses</h1>
            </EuiTitle>

            <StyledSpacer size="32px" />

            <EuiText size="m">
              <p>
                Enter either the monthly or annual amount rounded to the nearest
                dollar and the other field will complete automatically.
              </p>
            </EuiText>

            <StyledSpacer size="36px" />

            <>
              {displayExpenses.map((item, index) => (
                <>
                  <StyledEuiFlexGroup
                    justifyContent="spaceBetween"
                    alignItems="flexEnd"
                    className={isMobileView ? "mobile-group" : ""}
                  >
                    <EuiFlexItem
                      grow={2}
                      key={item.type as string}
                      className={index === 0 ? "first-item" : ""}
                    >
                      <StyledEuiTitle size="xs">
                        <h3>{(PROFILE_EXPENSE_TYPES as any)[item.type]}</h3>
                      </StyledEuiTitle>
                    </EuiFlexItem>

                    {isMobileView ? (
                      <EuiFlexGroup className="mobile-field-row">
                        <EuiFlexItem
                          grow={4}
                          style={{ maxWidth: 200 }}
                          className="mobile-field"
                        >
                          <EuiFormRow label="Monthly">
                            <DollarTextField
                              eui
                              decimalScale={0}
                              id={`monthly__${item.type as string}`}
                              name={`monthly__${item.type as string}`}
                              value={item.monthly || ""}
                              onChange={(e: any) =>
                                setFieldValue(e, index, false)
                              }
                            />
                          </EuiFormRow>
                        </EuiFlexItem>

                        <EuiFlexItem
                          grow={4}
                          style={{ maxWidth: 200 }}
                          className="mobile-field"
                        >
                          <EuiFormRow label="Annual">
                            <DollarTextField
                              eui
                              decimalScale={0}
                              id={`annual__${item.type as string}`}
                              name={`annual__${item.type as string}`}
                              value={item.annual || ""}
                              onChange={(e: any) =>
                                setFieldValue(e, index, false)
                              }
                            />
                          </EuiFormRow>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    ) : (
                      <>
                        <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                          <EuiFormRow
                            label={index === 0 ? "Monthly" : undefined} // Only show label for the first row
                          >
                            <DollarTextField
                              eui
                              decimalScale={0}
                              id={`monthly__${item.type as string}`}
                              name={`monthly__${item.type as string}`}
                              value={item.monthly || ""}
                              onChange={(e: any) =>
                                setFieldValue(e, index, false)
                              }
                            />
                          </EuiFormRow>
                        </EuiFlexItem>

                        <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                          <EuiFormRow
                            label={index === 0 ? "Annual" : undefined} // Only show label for the first row
                          >
                            <DollarTextField
                              eui
                              decimalScale={0}
                              id={`annual__${item.type as string}`}
                              name={`annual__${item.type as string}`}
                              value={item.annual || ""}
                              onChange={(e: any) =>
                                setFieldValue(e, index, false)
                              }
                            />
                          </EuiFormRow>
                        </EuiFlexItem>
                      </>
                    )}
                  </StyledEuiFlexGroup>

                  <StyledSpacer size="32px" />

                  <StyledEuiHorizontalRule />

                  <StyledSpacer size="32px" />
                </>
              ))}
            </>
            <EuiSpacer size="m" />
            <>
              {extraEntries.map((item, index) => (
                <>
                  <div key={(item.type as string) + index}>
                    {index > 0 && <StyledSpacer size="32px" />}

                    <EuiFlexGroup
                      justifyContent="spaceBetween"
                      alignItems="flexEnd"
                    >
                      <EuiFlexItem
                        grow={2}
                        style={{ maxWidth: firstItemWidth || "100%" }}
                        className="mobile-type"
                      >
                        <EuiFormRow label="Type">
                          <EuiComboBox
                            rowHeight={40}
                            className="truncate-label"
                            fullWidth={true}
                            aria-label="Add custom expense"
                            isClearable={false}
                            singleSelection={{ asPlainText: true }}
                            options={Object.keys(EXTRA_EXPENSE_TYPES)
                              .filter(
                                (key) =>
                                  item.type === key ||
                                  !extraEntries.find(
                                    (otherItem) => otherItem.type === key
                                  )
                              )
                              .map((key) => ({
                                label:
                                  EXTRA_EXPENSE_TYPES[
                                    key as keyof typeof EXTRA_EXPENSE_TYPES
                                  ],
                                value: key,
                              }))}
                            selectedOptions={
                              item.type
                                ? [
                                    {
                                      label:
                                        EXTRA_EXPENSE_TYPES[
                                          item.type as keyof typeof EXTRA_EXPENSE_TYPES
                                        ],
                                      value: item.type,
                                    },
                                  ]
                                : []
                            } // Use the label from EXTRA_EXPENSE_TYPES
                            onChange={(selectedOptions) =>
                              selectedOptions[0]?.value &&
                              setItemType(selectedOptions[0].value, index)
                            }
                            onCreateOption={(inputValue) =>
                              setItemType(inputValue, index)
                            }
                            renderOption={(option) => <div>{option.label}</div>}
                          />
                        </EuiFormRow>
                      </EuiFlexItem>

                      {isMobileView ? (
                        <EuiFlexGroup className="mobile-field-row">
                          <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                            <EuiFormRow label="Monthly">
                              <DollarTextField
                                eui
                                decimalScale={0}
                                id={`monthly__${index}`}
                                name={`monthly__${
                                  item.type as string
                                }__${index}`}
                                value={item.monthly || ""}
                                onChange={(e: any) =>
                                  setFieldValue(e, index, true)
                                }
                              />
                            </EuiFormRow>
                          </EuiFlexItem>

                          <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                            <EuiFormRow label="Annual">
                              <DollarTextField
                                eui
                                decimalScale={0}
                                id={`annual__${index}`}
                                name={`annual__${
                                  item.type as string
                                }__${index}`}
                                value={item.annual || ""}
                                onChange={(e: any) =>
                                  setFieldValue(e, index, true)
                                }
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      ) : (
                        <>
                          <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                            <EuiFormRow>
                              <DollarTextField
                                eui
                                decimalScale={0}
                                id={`monthly__${index}`}
                                name={`monthly__${
                                  item.type as string
                                }__${index}`}
                                value={item.monthly || ""}
                                onChange={(e: any) =>
                                  setFieldValue(e, index, true)
                                }
                              />
                            </EuiFormRow>
                          </EuiFlexItem>

                          <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                            <EuiFormRow>
                              <DollarTextField
                                eui
                                decimalScale={0}
                                id={`annual__${index}`}
                                name={`annual__${
                                  item.type as string
                                }__${index}`}
                                value={item.annual || ""}
                                onChange={(e: any) =>
                                  setFieldValue(e, index, true)
                                }
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                        </>
                      )}

                      {!isMobileView && (
                        <EuiFlexItem grow={false}>
                          <EuiFormRow hasEmptyLabelSpace>
                            <StyledEuiIcon
                              type="cross"
                              onClick={() => deleteItem(index)}
                            />
                          </EuiFormRow>
                        </EuiFlexItem>
                      )}
                    </EuiFlexGroup>

                    {isMobileView && (
                      <>
                        <StyledSpacer size="24px" />
                        <StyledEuiLink
                          color="primary"
                          onClick={() => deleteItem(index)}
                        >
                          Delete
                        </StyledEuiLink>
                      </>
                    )}

                    <StyledSpacer size="32px" />

                    <StyledEuiHorizontalRule />
                  </div>
                </>
              ))}
            </>

            <StyledSpacer size="32px" />

            <StyledEuiButton
              color="text"
              iconType="plus"
              onClick={addEmptyItem}
            >
              Add household expense
            </StyledEuiButton>

            <StyledSpacer size="48px" />

            <EuiButton type="submit" color="primary" fill onClick={next}>
              Next
            </EuiButton>

            <StyledEuiButtonEmpty color="text" onClick={saveAndExit}>
              Save and Exit
            </StyledEuiButtonEmpty>

            <StyledSpacer size="32px" />
          </Col>
        </StyledFormRow>
      </MainContainer>
    ),
  });
};

export default ProfileExpenses;
