import React from "react";

import Button, { ButtonProps } from "@material-ui/core/Button";

interface IFbButtonProps {
  fbColor?: "inherit" | "primary" | "default" | "secondary" | "accent";
  [propName: string]: any;
}

const FbButton = ({
  fbColor,
  className,
  children,
  variant = "contained",
  ...rest
}: ButtonProps & IFbButtonProps) => {
  return fbColor === "primary" ||
    fbColor === "default" ||
    fbColor === "inherit" ||
    fbColor === "secondary" ? (
    <Button className={className} color={fbColor} variant={variant} {...rest}>
      {children}
    </Button>
  ) : (
    <Button className={`${className} ${fbColor}`} variant={variant} {...rest}>
      {children}
    </Button>
  );
};

export default FbButton;
