import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fbColors from "src/theme/colors";
import { Box, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import {
  ASSET_ALLOCATIONS,
  DEBT_ALLOCATIONS,
  SPECIAL_ALLOCATIONS,
  PlanViewComponent,
  HouseholdMinLiabilities,
  Plan,
  TAX_DEFERRED_INVESTMENT_TYPES,
} from "src/interfaces";
import {
  savePlan,
  updateAllocations,
  estimateCurrentPlanStudentTaxes,
} from "src/store/planBuild/actions";
import { getCurrentPlan, getLiabilities } from "src/store/planBuild/selector";
import { DollarTextField, formatDollarsAndCents } from "src/utils";

import AssetCategories from "./AssetCategories";
import AddEdit401k from "./AddEdit401k";
import AddEditIRA from "./AddEditIRA";
import FedStudentLoan from "./FedStudentLoan";
import CenterContainer from "../../Components/CenterContainer";

const AddAssetOrDebt: PlanViewComponent = ({
  editingSpecialType,
  isDebt,
  onClose,
  render,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const minLiabilities = (useSelector(getLiabilities) as any).min;
  const plan: Plan = useSelector(getCurrentPlan);
  const types = isDebt ? DEBT_ALLOCATIONS : ASSET_ALLOCATIONS;
  const [formState, setFormState] = useState<any>({
    type: "cash_value",
    annual: "",
    monthly: "",
  });
  const [showCategories, setShowCategories] = useState(!editingSpecialType);
  const [addingSpecialType, setAddingSpecialType] = useState<any>(
    editingSpecialType
  );
  const allocationType: any =
    types[formState.type as keyof typeof types] || ASSET_ALLOCATIONS.cash_value;
  const typeLabel = allocationType.label;

  const updateField = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    const newFormState = { ...formState, [field]: value };
    if (field === "annual") {
      newFormState.monthly = "" + Math.round(value / 12);
    } else if (field === "monthly") {
      newFormState.annual = "" + value * 12;
    }
    setFormState(newFormState);
  };

  const save = () => {
    if (!showCategories && !addingSpecialType) {
      if (+formState.annual) {
        const newAllocations = {
          ...plan.allocations[0],
          [formState.type]: +formState.annual,
        };
        dispatch(updateAllocations(newAllocations));
        if (formState.type in TAX_DEFERRED_INVESTMENT_TYPES) {
          dispatch(estimateCurrentPlanStudentTaxes());
        }
        dispatch(savePlan(null));
      }
    }
    onClose();
  };

  const selectCategory = (category: any) => {
    setShowCategories(false);
    if (SPECIAL_ALLOCATIONS.indexOf(category.key) >= 0) {
      setAddingSpecialType(category);
    }
    setFormState({ type: category.key, annual: "", monthly: "" });
  };

  const renderSpecialType = () => {
    let SubComponent = null;
    switch (addingSpecialType.key || addingSpecialType.type) {
      case "fed_loan":
        SubComponent = FedStudentLoan;
        break;
      case "roth_401k_value":
      case "401k_value":
        SubComponent = AddEdit401k;
        break;
      case "roth_ira_value":
      case "ira_value":
      default:
        SubComponent = AddEditIRA;
    }
    return (
      <SubComponent
        initialValues={editingSpecialType}
        onClose={closeSpecialType}
        onSave={save}
        render={render}
        type={addingSpecialType.key || addingSpecialType.type}
      />
    );
  };

  const renderCategories = () => (
    <AssetCategories isDebt={isDebt} onSelect={selectCategory} />
  );

  const closeSpecialType = () => {
    if (editingSpecialType) {
      onClose();
    } else {
      setAddingSpecialType(null);
      setShowCategories(true);
    }
  };

  if (addingSpecialType && (addingSpecialType.type || addingSpecialType.key)) {
    return renderSpecialType();
  }

  // let max = 0;

  let min = 0;
  if (formState.type in minLiabilities) {
    min = minLiabilities[formState.type as keyof HouseholdMinLiabilities];
  }

  const nextDisabled = !!min && (formState.annual || 0) < min;

  return render({
    component: showCategories ? (
      renderCategories()
    ) : (
      <CenterContainer
        title={typeLabel}
        iconName={isDebt ? "fb-scales-tripped" : "fb-model"}
      >
        <Box className="mt-5">
          <Box className={styles.row}>
            <Box className={styles.headerCell}>Monthly</Box>
            <Box className={styles.headerCell}>Annually</Box>
          </Box>
          <Box className={styles.row}>
            <Box className={styles.cell}>
              <DollarTextField
                name="monthly"
                value={formState.monthly}
                variant="outlined"
                onChange={updateField}
              />
            </Box>
            <Box className={styles.cell}>
              <DollarTextField
                name="annual"
                value={formState.annual}
                variant="outlined"
                // max={max || undefined}
                onChange={updateField}
              />
              {!!min && (
                <Typography variant="body1">
                  Min: {formatDollarsAndCents(min)}
                </Typography>
              )}
              {/* {!!max && (
                <Typography variant="body1">
                  Max: {formatDollarsAndCents(max)}
                </Typography>
              )} */}
            </Box>
          </Box>
        </Box>
        <Button
          variant="outlined"
          fbColor="primary"
          onClick={onClose}
          className={styles.btnCancel}
        >
          Cancel
        </Button>
      </CenterContainer>
    ),
    nextDisabled,
    nextLabel: "Save",
    nextTooltip: nextDisabled
      ? "You must enter at least the minimum contribution."
      : undefined,
    onNext: save,
  });
};

export default AddAssetOrDebt;

const useStyles = makeStyles({
  cell: {
    width: "30%",
    marginRight: 10,
    fontSize: 12,
  },
  headerCell: {
    fontWeight: "bolder",
    width: "30%",
    fontSize: 12,
    marginBottom: 15,
    marginRight: 10,
    color: fbColors.brandingBlue1,
  },
  row: {
    display: "flex",
  },
  btnCancel: {
    display: "block",
    margin: "20px auto",
    width: "calc(100% - 50px)",
  },
});
