import React from "react";

import { Box, CardContent, Divider, Typography } from "@material-ui/core";
import Card, { CardProps } from "@material-ui/core/Card";

import Icon from "../Icon";
import HelpToggle from "../HelpToggle";
import { SidebarContent } from "src/interfaces";

interface IFbCardProps {
  centerTitle?: boolean;
  children?: React.ReactNode;
  headerImage?: string;
  imageClassName?: string;
  title?: string;
  titleTag?: "h2" | "h4" | "h6";
  noPadding?: boolean;
  noTitlePadding?: boolean;
  iconName?: string;
  headComponent?: React.ReactNode;
  secondRowHeadComponent?: React.ReactNode;
  titleClassName?: string;
  help?: SidebarContent[];
  divider?: boolean;
  titleButton?: JSX.Element;
}

const FbCard = ({
  centerTitle,
  title,
  children,
  headComponent,
  secondRowHeadComponent,
  headerImage,
  imageClassName,
  titleTag,
  noPadding,
  noTitlePadding,
  iconName,
  titleClassName,
  help,
  divider,
  titleButton,
  ...rest
}: IFbCardProps & CardProps) => {
  const style = rest.style || {};
  if (rest.elevation === 0) {
    style.boxShadow = "none";
  }
  return (
    <Card {...rest} style={style}>
      <CardContent
        className={`${noPadding ? "p-0" : ""}`}
        style={divider ? { padding: 20 } : undefined}
      >
        {(title || headComponent) && (
          <Box className="head flex justify-between items-center">
            <Box
              className={`flex ${
                noTitlePadding ? "p-0 " : ""
              }items-center w-full`}
            >
              {!!iconName && (
                <Box className="text-blue h-5 mr-2">
                  <Icon iconName={iconName} className="-mt-1"></Icon>
                </Box>
              )}
              {!!headerImage && (
                <img
                  alt="header illustration"
                  src={headerImage}
                  className={imageClassName || ""}
                />
              )}
              <Typography
                component={titleTag || "h2"}
                className={`fb-card-title${
                  centerTitle ? " text-center w-full" : ""
                } ${titleClassName || ""}`}
                style={divider ? { fontSize: 15, marginBottom: 16 } : undefined}
              >
                {title}
                {help && (
                  <span className="absolute">
                    <HelpToggle content={help} />
                  </span>
                )}
              </Typography>
              {titleButton || null}
            </Box>
            {!!headComponent && <Box className="max-w-xs">{headComponent}</Box>}
          </Box>
        )}
        {secondRowHeadComponent || null}
        {divider && <Divider />}
        {children}
      </CardContent>
    </Card>
  );
};

export default FbCard;
