import React from "react";
import {
  Box,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import Radio from "src/components/Radio";
import { investmentsStyles } from "src/theme";

const useStyles = makeStyles(investmentsStyles);

const autoLabel =
  "This choice will periodically auto-adjust the asset allocations and risk levels in your investment account according to your FitBUX Score. This method is recommended.";

const manualLabel =
  "This option does not auto-adjust your allocations and risk level of your investments over time. ";

export const Rebalancing = ({
  next,
  goBack,
  formValues,
  setFormValues,
}: any) => {
  const classes = useStyles();

  return (
    <Card title="Update Allocations As FitBUX Score Changes">
      <Box className="flex justify-between">
        <Box />
        <Box className="w-3/4 mt-4 flex flex-col">
          <Typography variant="h3" className={classes.subHeader}>
            Select One Of The Following Choices:
          </Typography>
          <FormLabel className="mb-2 mt-4 ml-10 text-lg">Auto-Adjust</FormLabel>
          <FormControlLabel
            control={<Radio checked={formValues.rebalance === "auto"} />}
            onClick={() =>
              setFormValues((current: any) => ({
                ...current,
                rebalance: "auto",
              }))
            }
            label={autoLabel}
          />
          <FormLabel className="mb-2 mt-4 ml-10 text-lg">
            Keep the Same Target Allocations
          </FormLabel>
          <FormControlLabel
            control={<Radio checked={formValues.rebalance === "static"} />}
            onClick={() =>
              setFormValues((current: any) => ({
                ...current,
                rebalance: "static",
              }))
            }
            label={manualLabel}
          />
          <Box className="flex mt-4 justify-center">
            <Button className="mr-4" onClick={goBack}>
              Back
            </Button>
            <Button fbColor="primary" onClick={next}>
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default Rebalancing;
