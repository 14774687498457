import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  fetchSecondaryEducationApi,
  updateProfileApi,
  updateHouseholdApi,
  fetchSpecialtiesApi,
  getFieldsApi,
  getProfessionsApi,
  getEmploymentsApi,
  getFellowshipsApi,
  getDesignationsApi,
} from "src/apiService/modules/profileBuild";
import { FbAction, Item0 } from "../../interfaces";
import { getScore } from "../dashboard/saga";

import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";

function* updateProfileSlice(
  actionType: string,
  { payload }: FbAction<any>
): any {
  try {
    const { nextPage, update, who } = payload;
    yield call(updateProfileApi, { who, profile: update });
    yield put({ type: actionType + SUCCESS, payload });
    if (nextPage) {
      yield put({ type: actions.OLD_SET_PROFILE_STEP, payload: nextPage });
    }
    yield* getScore();
  } catch (error) {
    yield put({ type: actionType + FAIL, payload: error });
  }
}

function* updateHousehold({
  payload,
}: FbAction<actions.UpdateHouseholdPayload>) {
  try {
    const { nextPage: step, update } = payload;
    yield call(updateHouseholdApi, update);
    yield put({ type: actions.OLD_UPDATE_HOUSEHOLD + SUCCESS, payload });
    if (step) {
      yield put({ type: actions.OLD_SET_PROFILE_STEP, payload: step });
    }
  } catch (error) {
    yield put({ type: actions.OLD_UPDATE_HOUSEHOLD + FAIL, payload: error });
  }
}

function* fetchSchools() {
  try {
    const { data } = yield call(fetchSecondaryEducationApi);
    yield put({ type: actions.OLD_FETCH_SCHOOLS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.OLD_FETCH_SCHOOLS + FAIL, payload: error });
  }
}

function* fetchLeveledSchools({ payload }: FbAction<number>) {
  try {
    const { data } = yield call(fetchSecondaryEducationApi, payload);
    yield put({
      type: actions.OLD_FETCH_LEVELED_SCHOOLS + SUCCESS,
      payload: {
        level: payload,
        data,
      },
    });
  } catch (error) {
    yield put({
      type: actions.OLD_FETCH_LEVELED_SCHOOLS + FAIL,
      payload: error,
    });
  }
}

function* fetchSpecialties({ payload }: FbAction<number>) {
  try {
    const { data } = yield call(fetchSpecialtiesApi, payload);
    yield put({ type: actions.OLD_FETCH_SPECIALTIES + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.OLD_FETCH_SPECIALTIES + FAIL, payload: error });
  }
}

function* fetchFields(): any {
  try {
    const data = yield call(getFieldsApi);
    yield put({ type: actions.OLD_FETCH_FIELDS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.OLD_FETCH_FIELDS + FAIL, payload: error });
  }
}

function* fetchProfessions(): any {
  try {
    const data = yield call(getProfessionsApi);
    yield put({ type: actions.OLD_FETCH_PROFESSIONS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.OLD_FETCH_PROFESSIONS + FAIL, payload: error });
  }
}

function* fetchEmployments() {
  try {
    const { data } = yield call(getEmploymentsApi);
    yield put({ type: actions.OLD_FETCH_EMPLOYMENTS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.OLD_FETCH_EMPLOYMENTS + FAIL, payload: error });
  }
}

function* fetchFellowships({ payload }: FbAction<number>) {
  try {
    const {
      data,
    }: {
      data: {
        fellowship: Array<Item0>;
        residency: Array<Item0>;
      };
    } = yield call(getFellowshipsApi, payload);
    yield put({ type: actions.OLD_FETCH_FELLOWSHIPS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.OLD_FETCH_FELLOWSHIPS + FAIL, payload: error });
  }
}

function* fetchDesignations() {
  try {
    const { data } = yield call(getDesignationsApi);
    yield put({
      type: actions.OLD_FETCH_DESIGNATIONS + SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: actions.OLD_FETCH_DESIGNATIONS + FAIL, payload: error });
  }
}

export function* oldProfileBuildSagas() {
  yield all([
    takeLatest(actions.OLD_FETCH_LEVELED_SCHOOLS + START, fetchLeveledSchools),
    takeEvery<string, any>(
      actions.OLD_UPDATE_PERSONAL_INFO + START,
      updateProfileSlice,
      actions.OLD_UPDATE_PERSONAL_INFO
    ),
    takeEvery(actions.OLD_UPDATE_HOUSEHOLD + START, updateHousehold),
    takeLatest(actions.OLD_FETCH_SCHOOLS + START, fetchSchools),
    takeLatest(actions.OLD_FETCH_SPECIALTIES + START, fetchSpecialties),
    takeLatest(actions.OLD_FETCH_FIELDS + START, fetchFields),
    takeLatest(actions.OLD_FETCH_PROFESSIONS + START, fetchProfessions),
    takeLatest(actions.OLD_FETCH_EMPLOYMENTS + START, fetchEmployments),
    takeLatest(actions.OLD_FETCH_FELLOWSHIPS + START, fetchFellowships),
    takeLatest(actions.OLD_FETCH_DESIGNATIONS + START, fetchDesignations),

    takeEvery<string, any>(
      actions.OLD_UPDATE_EDUCATION + START,
      updateProfileSlice,
      actions.OLD_UPDATE_EDUCATION
    ),
    takeEvery<string, any>(
      actions.OLD_UPDATE_CAREER + START,
      updateProfileSlice,
      actions.OLD_UPDATE_CAREER
    ),
    takeEvery<string, any>(
      actions.OLD_UPDATE_OTHER_HUMAN_CAPITAL + START,
      updateProfileSlice,
      actions.OLD_UPDATE_OTHER_HUMAN_CAPITAL
    ),
    takeEvery<string, any>(
      actions.OLD_UPDATE_DEBTS + START,
      updateProfileSlice,
      actions.OLD_UPDATE_DEBTS
    ),
  ]);
}
