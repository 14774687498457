import React from "react";
import { Grid } from "@material-ui/core";

import HeaderText from "src/components/HeaderText";
import Recommendations from "./Blocks/Recommendations";
import InsurancePremium from "./Blocks/Insurance";
import Summary from "./Blocks/Summary";

import { aboutRisk } from "./data";

export const Content = () => {
  const { heading, description } = aboutRisk;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <HeaderText heading={heading} description={description} />
      </Grid>
      <Grid item xs={12} md={6} className="flex justify-start items-center">
        <Recommendations />
      </Grid>
      <Grid item xs={12} md={6}>
        <Summary />
      </Grid>
      <Grid item xs={12} md={6}>
        <InsurancePremium />
      </Grid>
    </Grid>
  );
};
