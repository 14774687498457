import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";

import Intro from "./Intro";
import Main from "./MainForm";
import Education from "./EducationForm";

const humanCapitalViews = {
  [PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO]: Intro,
  [PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_1]: Main,
  [PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_2]: Main,
  [PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_3]: Main,
  [PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_4]: Main,
  [PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_1]: Main,
  [PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_2]: Main,
  [PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_3]: Main,
  [PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_4]: Main,
  [PROFILE_BUILD_STEPS.MY_EDUCATION]: Education,
  [PROFILE_BUILD_STEPS.SPOUSE_EDUCATION]: Education,
};

export default humanCapitalViews;
