export const OPEN_TOUR_CARD = 'OPEN_TOUR_CARD';
export const CLOSE_TOUR_CARD = 'CLOSE_TOUR_CARD';

export const openTourCard = () => ({ type: OPEN_TOUR_CARD });
export const closeTourCard = () => ({ type: CLOSE_TOUR_CARD });

interface OpenTourCardAction {
  type: typeof OPEN_TOUR_CARD;
}

interface CloseTourCardAction {
  type: typeof CLOSE_TOUR_CARD;
}

export type ActionType = OpenTourCardAction | CloseTourCardAction;