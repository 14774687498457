import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePlaidLink, PlaidLinkOptions } from "react-plaid-link";

import { Button, makeStyles, Typography } from "@material-ui/core";

import Card from "src/components/Card";
import { getPublicToken } from "src/store/account/actions";
import { getPublicToken as selectPublicToken } from "src/store/account/selector";
import { investmentsStyles } from "src/theme";

const useStyles = makeStyles(investmentsStyles);

export const ViewEditLink = ({ next }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const publicToken = useSelector(selectPublicToken);

  useEffect(() => {
    dispatch(getPublicToken({}));
  }, []);

  const config: PlaidLinkOptions = {
    token: publicToken?.link_token || "",
    onSuccess: () => null,
    // onExit
    // onEvent
  };

  const { open, ready } = usePlaidLink(config);

  const linkBettermentAccount = () => open();

  return (
    <Card>
      <Typography variant="h2" className="text-center">
        Choose One of the Following:
      </Typography>
      <Button
        className={classes.blockButton}
        component="a"
        href="https://www.betterment.com/app/login"
        target="_blank"
      >
        <span className={classes.bigLabel}>View My Betterment Account</span>
        <span className={classes.littleLabel}>
          You'll be taken to your Betterment log in screen
        </span>
      </Button>
      <Button className={classes.blockButton} component="a" onClick={next}>
        <span className={classes.bigLabel}>Edit My Betterment Account</span>
      </Button>
      <Button
        className={classes.blockButton}
        component="a"
        onClick={linkBettermentAccount}
        disabled={!ready}
      >
        <span className={classes.bigLabel}>
          Link My Betterment Account To FitBUX
        </span>
      </Button>
    </Card>
  );
};

export default ViewEditLink;
