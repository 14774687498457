import { createSelector } from "reselect";
import { AppState } from "..";

const getSystemState = (state: AppState) => state.system;

export const isLoggedInSelector = createSelector(
  getSystemState,
  (system) => system.loggedIn
);
export const loginStateSelector = createSelector(getSystemState, (system) => ({
  loggedIn: system.loggedIn,
  loading: system.loading,
  error: system.error?.message,
}));
export const authStatusSelector = createSelector(
  getSystemState,
  (system) => system.authStatus
);
export const userSelector = createSelector(
  getSystemState,
  (system) => system.user
);
export const spouseSelector = createSelector(
  getSystemState,
  (system) => system.spouse
);
export const passwordResetSuccessSelector = createSelector(
  getSystemState,
  (system) => system.passwordResetSuccess
);
export const featuresSelector = createSelector(
  getSystemState,
  (system) => system.features
);
export const getUpdatesSuspended = createSelector(
  getSystemState,
  (system) => system.updatesSuspended
);

export const getIsMarried = createSelector(
  featuresSelector,
  (features) => features.married
);
export const getIsSpouseLinked = createSelector(
  getSystemState,
  (state) => state.spouseLinked
);
export const getIsSubscribed = createSelector(
  featuresSelector,
  (features) => features.subscribed
);
export const getHasPlan = createSelector(
  featuresSelector,
  (features) => features.hasPlan
);
export const getIsCurrentStudent = createSelector(
  featuresSelector,
  (features) => features.inschool
);
export const getPreviousRoute = createSelector(
  getSystemState,
  (state) => state.previousRoute
);
export const isUpgradeDialogOpen = createSelector(
  getSystemState,
  (state) => state.upgradeDialogOpen
);
export const isMoneySchoolDialogOpen = createSelector(
  getSystemState,
  (state) => state.moneySchoolDialogOpen
);
