import React from "react";

import {
  AppBar,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";

import Icon from "../../Icon";

import { headerStyles } from "src/theme";
import { AdapterLink } from "src/utils";

const useStyles = makeStyles(headerStyles);

const PlanBuilderHeader = ({
  text,
  extraContent,
  goBack,
  goBackTarget,
}: any) => {
  const classes = useStyles();

  return (
    <AppBar elevation={0} className={classes.planBuilder} position="absolute">
      <Toolbar>
        <Grid container spacing={3} className="pt-4 px-2">
          <Grid item xs={9} className="flex justify-between">
            <Typography component="h2" variant="h2">
              {text}
            </Typography>
            {extraContent || null}
          </Grid>
          <Grid item xs={3}>
            <IconButton
              color="inherit"
              component={AdapterLink}
              className="absolute right-2"
              to={goBackTarget}
              onClick={goBack ? goBack : () => null}
            >
              <Icon iconName="fb-close" className="text-4xl" />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default PlanBuilderHeader;
