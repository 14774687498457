import React from "react";

import { EuiFlexGroup, EuiFlexItem, EuiText, EuiFormRow } from "@elastic/eui";
import { Popper } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "src/components/TextField";

import {
  StyledEuiButtonIcon,
  StyledSpacer,
  StyledEuiHorizontalRule,
  StyledEuiButton,
} from "src/components/Global/StyledComponents";
import { DollarTextField, formatDollarsAndCents } from "src/utils";
import { Global, css } from "@emotion/react";

const globalStyles = css`
  .euiComboBoxOptionsList__rowWrap div {
    width: 100% !important;
  }
  .euiComboBoxOptionsList div.euiFlexItem {
    width: 400px !important;
  }
  .euiComboBoxOptionsList {
    width: 400px !important;
  }
  .euiComboBoxOption__content {
    display: flex;
    justify-content: space-between;
  }
  .hide-clear-indicator {
    display: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .makeStyles-root-518 {
    margin-bottom: 0px;
  }
  .MuiFormControl-root.MuiTextField-root.input {
    margin-bottom: 0px !important;
  }
  .field-row {
    fontfamily: "Inter, sans-serif";
  }
  .euiComboBox .euiComboBox__input {
    block-size: 12px;
  }
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
  }
`;

export const InvestmentAllocations = ({
  readOnly,
  investmentData,
}: {
  readOnly: boolean;
  investmentData: any;
  totalBalance: number;
}) => {
  const {
    addCash,
    addNewTicker,
    cashValue,
    investments,
    options,
    removeItem,
    setFocusedItem,
    setOptions,
    setTickerInputValue,
    totalBalance,
    updateBalance,
    updateItemTicker,
  } = investmentData;
  return (
    <>
      <Global styles={globalStyles} />
      <StyledSpacer size="32px" />
      <EuiText>
        <span>
          <b>Edit Holdings</b>
        </span>
        <StyledSpacer size="16px" />
        {readOnly ? (
          ""
        ) : (
          <span>
            Add or update your holdings in this account. This is optional.
            However, to use our investment allocation technology, you must
            include your investments held in this account.
          </span>
        )}
      </EuiText>

      <StyledSpacer size="32px" />

      {investments.map((investment: any, index: number) => (
        <>
          <EuiFlexGroup
            key={index}
            style={{ maxWidth: 428, flexWrap: "nowrap" }}
            className="mobile-field-row"
          >
            <EuiFlexItem grow={4} style={{ maxWidth: 250 }}>
              <EuiFormRow label="">
                <Autocomplete
                  clearOnEscape={false}
                  className="input"
                  id="select-ticker"
                  getOptionLabel={(option: any) => {
                    return typeof option === "string" ? option : option.Code;
                  }}
                  onFocus={() => setFocusedItem(investment)}
                  filterOptions={(x) => x}
                  options={options}
                  filterSelectedOptions
                  forcePopupIcon={false}
                  getOptionSelected={(option: any) =>
                    investment.ticker_symbol === option ||
                    investment.ticker_symbol ===
                      `${option.Code}.${option.Exchange}`
                  }
                  PopperComponent={(pProps) => (
                    <Popper
                      {...pProps}
                      style={{
                        width: "100%",
                        maxWidth: 400,
                        left: -50,
                        right: 50,
                        position: "fixed",
                      }}
                      disablePortal
                    />
                  )}
                  ListboxProps={{ style: { maxWidth: 400 } }}
                  value={(investment.ticker_symbol || ".").split(".")[0]}
                  onChange={(event, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    updateItemTicker(investment, newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setTickerInputValue(newInputValue);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      placeholder="Select Ticker"
                      className="input"
                    />
                  )}
                  renderOption={(option: any) => {
                    return (
                      <div style={{ width: "100%" }}>
                        <div className="field-row">
                          <b>{option.Code}</b> - {option.Name} (
                          {option.Exchange})
                          <StyledSpacer size="8px" />
                        </div>

                        <StyledEuiHorizontalRule margin="none" />
                      </div>
                    );
                  }}
                  classes={{
                    root: "euiComboBox",
                    inputRoot: "euiComboBox__inputWrap",
                    input: "euiComboBox__input",
                    popupIndicator: "euiComboBox__openIndicator",
                    option: "euiComboBoxOption",
                    noOptions: "euiComboBoxOption__empty",
                    clearIndicator: "hide-clear-indicator",
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>

            <EuiFlexItem grow={4} style={{ maxWidth: 120 }}>
              <EuiFormRow label="">
                <DollarTextField
                  eui
                  value={investment.balance}
                  name="edit-amount"
                  onChange={(e: React.ChangeEvent<any>) =>
                    updateBalance(investment, e.target.value)
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>

            <EuiFlexItem className="debt-icon" style={{ maxWidth: 58 }}>
              <StyledEuiButtonIcon
                iconType="cross"
                onClick={() => removeItem(investment)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          <StyledSpacer size="24px" />

          <StyledEuiHorizontalRule />

          <StyledSpacer size="24px" />
        </>
      ))}

      <EuiFlexGroup>
        <EuiFlexItem>
          <strong>Total Balance</strong>
        </EuiFlexItem>
        <EuiFlexItem>
          <strong>{formatDollarsAndCents(totalBalance)}</strong>
        </EuiFlexItem>
      </EuiFlexGroup>
      {!readOnly && (
        <>
          <StyledSpacer size="32px" />
          <EuiFlexGroup>
            <StyledEuiButton
              color="text"
              iconType="plus"
              onClick={addNewTicker}
            >
              Add Investment
            </StyledEuiButton>
            {!cashValue && (
              <StyledEuiButton color="text" iconType="plus" onClick={addCash}>
                Add Cash
              </StyledEuiButton>
            )}
          </EuiFlexGroup>
        </>
      )}

      <StyledSpacer size="32px" />
    </>
  );
};

export default InvestmentAllocations;
