import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import {
  AppBar,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";

import Icon from "../../Icon";
import Stepper from "../../Stepper";

import { headerStyles } from "src/theme";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { getCurrentStep } from "src/store/planBuild/selector";
import { setPopupActive, setHeaderClicked } from "src/store/dashboard/actions";
import { getIsCurrentStudent } from "src/store/system/selector";
import { setBuildStep } from "src/store/planBuild/actions";
import { isCurrentPlanImplemented } from "src/store/planBuild/selector";
import { getRouterState } from "src/store/router/selector";

const studentSteps = [
  {
    label: "Income & Expenses",
    subSteps: [PLAN_BUILD_STEPS.INCOME_AND_EXPENSES],
  },
  {
    label: "Assets & Debts",
    subSteps: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
  },
  {
    label: "Debt & Assets",
    subSteps: [PLAN_BUILD_STEPS.STUDENT_ASSETS_AND_DEBTS],
  },
  {
    label: "Education Funding and Costs",
    subSteps: [PLAN_BUILD_STEPS.EDUCATION_FUNDING],
  },
  {
    label: "Risk Management",
    subSteps: [PLAN_BUILD_STEPS.RISK_MANAGEMENT],
  },
  {
    label: "Expenses and Insurance",
    subSteps: [PLAN_BUILD_STEPS.EXPENSES_AND_INSURANCE],
  },
  {
    label: "Life Events/Goals",
    subSteps: [PLAN_BUILD_STEPS.LIFE_EVENTS_OVERVIEW],
  },
  {
    label: "Emergency fund",
    subSteps: [PLAN_BUILD_STEPS.SAVINGS],
  },
  {
    label: "Future Questions",
    subSteps: [PLAN_BUILD_STEPS.FUTURE_QUESTIONS],
  },
  {
    label: "Review",
    subSteps: [PLAN_BUILD_STEPS.RECOMMENDATIONS],
  },
  {
    label: "Implement",
    subSteps: [PLAN_BUILD_STEPS.IMPLEMENT],
  },
];

const useStyles = makeStyles(headerStyles);

const PlanBuilderHeader = () => {
  const classes = useStyles();
  const currentStep = useSelector(getCurrentStep);
  const dispatch = useDispatch();
  const currentStudent = useSelector(getIsCurrentStudent);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const router = useSelector(getRouterState);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);

  const graduatedSteps = [
    {
      label: "Set Up My Financial Plan",
      subSteps: [PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW],
    },
    {
      label: "Set Up My Financial Plan",
      subSteps: [PLAN_BUILD_STEPS.INCOME_AND_EXPENSES],
    },
    {
      label: "Set Up My Financial Plan",
      subSteps: [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS],
    },
    {
      label: "Set Up My Financial Plan",
      subSteps: [PLAN_BUILD_STEPS.STUDENT_ASSETS_AND_DEBTS],
    },
    {
      label: "Set Up My Financial Plan",
      subSteps: [PLAN_BUILD_STEPS.EDUCATION_FUNDING],
    },
    {
      label: "Set Up My Financial Plan",
      subSteps: [PLAN_BUILD_STEPS.RISK_MANAGEMENT],
    },
    {
      label: "Set Up My Financial Plan",
      subSteps: [PLAN_BUILD_STEPS.EXPENSES_AND_INSURANCE],
    },
    {
      label: "Set Up My Financial Plan",
      subSteps: [PLAN_BUILD_STEPS.SAVINGS],
    },
    {
      label: "Set Up My Financial Plan",
      subSteps: [PLAN_BUILD_STEPS.FUTURE_QUESTIONS],
    },
    {
      label: "Set Up My Financial Plan",
      subSteps: [PLAN_BUILD_STEPS.RECOMMENDATIONS],
    },
    {
      label: currentPlanIsImplemented
        ? "Implemented Plan"
        : "Simulate & Review",
      subSteps: [PLAN_BUILD_STEPS.GRADUATED_REVIEW],
    },
    {
      label: "Implement",
      subSteps: [PLAN_BUILD_STEPS.IMPLEMENT],
    },
  ];

  const steps = currentStudent ? studentSteps : graduatedSteps;

  const isOptimizedRoute = router.location.pathname.includes("optimized");
  const headerTitle = `Set Up My ${
    isOptimizedRoute ? "Optimized " : ""
  }Financial Plan`;

  let activeStep = 0;
  if (
    currentStep === PLAN_BUILD_STEPS.REVIEW ||
    currentStep === PLAN_BUILD_STEPS.GRADUATED_REVIEW
  ) {
    activeStep = 1;
  } else if (currentStep === PLAN_BUILD_STEPS.IMPLEMENT) {
    activeStep = 2;
  }
  const currentStepIndex = steps.findIndex((step) => {
    const index = step.subSteps.findIndex(
      (stepName) => stepName === currentStep
    );
    return index > -1;
  });

  const onClose = () => {
    if (currentStep === PLAN_BUILD_STEPS.IMPLEMENT) {
      dispatch(
        setBuildStep(
          isCurrentStudent
            ? PLAN_BUILD_STEPS.REVIEW
            : PLAN_BUILD_STEPS.GRADUATED_REVIEW
        )
      );
    } else if (!currentPlanIsImplemented || isCurrentStudent) {
      dispatch(setPopupActive(true));
    } else {
      if (currentPlanIsImplemented) {
        dispatch(push("/plan-summary"));
      } else {
        dispatch(push("/plan-list"));
      }
    }
  };

  const renderStepper = () => {
    if (
      currentPlanIsImplemented &&
      currentStep === PLAN_BUILD_STEPS.GRADUATED_REVIEW
    ) {
      return null;
    }
    return (
      <Stepper
        steps={["Plan Setup", "Simulate & Review", "Implement"]}
        active={activeStep}
        compact={true}
      />
    );
  };

  return (
    <AppBar elevation={0} className={classes.planBuilder}>
      <Toolbar>
        <Grid container spacing={3}>
          <Grid
            item
            xs={3}
            className="flex items-center"
            onClick={() => {
              dispatch(setHeaderClicked(true));
            }}
            style={{ height: 98 }}
          >
            <Typography
              component="h1"
              variant="body1"
              className={classes.headerTitle}
            >
              {currentStepIndex > -1
                ? steps[currentStepIndex].label
                : headerTitle}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            onClick={() => {
              dispatch(setHeaderClicked(true));
            }}
          >
            {renderStepper()}
          </Grid>
          <Grid item xs={3}>
            <IconButton
              color="inherit"
              className="absolute right-0"
              onClick={onClose}
            >
              <Icon iconName="fb-close" className="text-4xl" />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default PlanBuilderHeader;
