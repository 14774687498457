import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";

import {
  Avatar,
  Box,
  Button,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import createStyles from "@material-ui/core/styles/createStyles";

import { logOut } from "src/store/system/actions";
import { loginStateSelector } from "src/store/system/selector";
import { AdapterLink } from "src/utils";
import { setPopup, setPopupActive } from "src/store/dashboard/actions";

const redirect = process.env.REACT_APP_OVERRIDE_REDIRECT !== "true";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

const ExtendedLogout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector(loginStateSelector);
  if (!loggedIn) {
    return <Redirect to="/login" />;
  }
  return (
    <>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOpenOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className="mb-4">
          Log Out
        </Typography>
        <Typography variant="h1">
          Before logging out, would you like to implement your plan?
        </Typography>
        <form>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            href="#"
            size="large"
            className={classes.submit}
            onClick={() => {
              dispatch(setPopupActive(true));
              dispatch(setPopup("Logout"));
            }}
          >
            Yes, Implement A Plan
          </Button>
        </form>
        <Box className="flex justify-center">
          <Button
            className="mr-4"
            size="small"
            href="#"
            component={AdapterLink}
            to="/plan-summary"
            color="secondary"
            type="submit"
            onClick={() => {
              dispatch(logOut());
              if (redirect) {
                window.location.replace(
                  "https://www.fitbux.com/refer-a-friend-log-out/"
                );
              }
            }}
          >
            Log Out Now
          </Button>
          <Button
            size="small"
            href="#"
            component={AdapterLink}
            to="/plan-summary"
            color="secondary"
          >
            Back to Home
          </Button>
        </Box>
      </div>
    </>
  );
};

export default ExtendedLogout;
