import {
  Theme,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { formatWholeDollars } from "src/utils";
import { INVESTMENT_RETURN } from "../CONSTANTS";
import { InvestmentMethodResult } from "../UsePointsCalculator";

interface InvestmentMethodStrategyProps {
  strategy: InvestmentMethodResult;
}

const InvestmentMethodStrategy = ({
  strategy,
}: InvestmentMethodStrategyProps) => {
  const classes = useStyles();
  const {
    monthlyRequiredPayment,
    monthlyInvestmentAmount,
    loanPaidInYears,
    yearsAvailableToInvest,
    investmentValue,
    futureValueOfPointsPaid,
    netInvestmentValue,
  } = strategy;
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.headRow}>
          <TableCell className={classes.headCell} colSpan={2} />
          <TableCell className={classes.headCell}>“No Point” Offer</TableCell>
          <TableCell className={classes.headCell}>Point Offer 1</TableCell>
          <TableCell className={classes.headCell}>Point Offer 2</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.row}>
          <TableCell className={classes.titleCell} colSpan={2}>
            <Typography>Monthly Required Payment </Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>
              {formatWholeDollars(monthlyRequiredPayment.noPointsOffer)}
            </Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>
              {formatWholeDollars(monthlyRequiredPayment.pointOfferOne)}
            </Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>
              {formatWholeDollars(monthlyRequiredPayment.pointOfferTwo)}
            </Typography>
          </TableCell>
        </TableRow>
        {strategy.monthlyPrepayment && (
          <TableRow className={classes.row}>
            <TableCell className={classes.titleCell} colSpan={2}>
              <Typography>Monthly Prepayment</Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography>N/A</Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography>
                {formatWholeDollars(strategy.monthlyPrepayment.pointOfferOne)}
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography>
                {formatWholeDollars(strategy.monthlyPrepayment.pointOfferTwo)}
              </Typography>
            </TableCell>
          </TableRow>
        )}

        {strategy.totalMonthlyPayment && (
          <TableRow className={classes.row}>
            <TableCell className={classes.titleCell} colSpan={2}>
              <Typography>Total Monthly Payment</Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography>
                {formatWholeDollars(strategy.totalMonthlyPayment.noPointsOffer)}
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography>
                {formatWholeDollars(strategy.totalMonthlyPayment.pointOfferOne)}
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography>
                {formatWholeDollars(strategy.totalMonthlyPayment.pointOfferTwo)}
              </Typography>
            </TableCell>
          </TableRow>
        )}

        <TableRow className={classes.row}>
          <TableCell className={classes.titleCell} colSpan={2}>
            <Typography>Loan Paid Off In (Years)</Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>{loanPaidInYears.noPointsOffer.toFixed(1)}</Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>{loanPaidInYears.pointOfferOne.toFixed(1)}</Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>{loanPaidInYears.pointOfferTwo.toFixed(1)}</Typography>
          </TableCell>
        </TableRow>

        <TableRow className={classes.row}>
          <TableCell className={classes.titleCell} colSpan={2}>
            <Typography>Years Available To Invest</Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>
              {yearsAvailableToInvest.noPointsOffer.toFixed(1)}
            </Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>
              {yearsAvailableToInvest.pointOfferOne.toFixed(1)}
            </Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>
              {yearsAvailableToInvest.pointOfferTwo.toFixed(1)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow className={classes.row}>
          <TableCell className={classes.titleCell} colSpan={2}>
            <Typography>Monthly Investment Amount</Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>N/A</Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>
              {formatWholeDollars(monthlyInvestmentAmount.pointOfferOne)}
            </Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>
              {formatWholeDollars(monthlyInvestmentAmount.pointOfferTwo)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow className={classes.row}>
          <TableCell className={classes.titleCell} colSpan={2}>
            <Typography>
              Investment Value After {loanPaidInYears.noPointsOffer.toFixed(1)}{" "}
              Years
            </Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            {investmentValue.noPointsOffer !== undefined && (
              <Typography>
                {formatWholeDollars(investmentValue.noPointsOffer)}
              </Typography>
            )}
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>
              {formatWholeDollars(investmentValue.pointOfferOne)}
            </Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>
              {formatWholeDollars(investmentValue.pointOfferTwo)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow className={classes.row}>
          <TableCell className={classes.titleCell} colSpan={2}>
            <Typography>
              Less: Future Value Of Points Paid
              <Tooltip title="Future value (FV) is the value of a current asset at a future date based on an assumed rate of growth. ">
                <Info />
              </Tooltip>
            </Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            {futureValueOfPointsPaid.noPointsOffer !== undefined && (
              <Typography>
                {formatWholeDollars(futureValueOfPointsPaid.noPointsOffer)}
              </Typography>
            )}
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>
              {formatWholeDollars(futureValueOfPointsPaid.pointOfferOne)}
            </Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Typography>
              {formatWholeDollars(futureValueOfPointsPaid.pointOfferTwo)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow className={classes.breakDownRow}>
          <TableCell className={classes.breakDownTitleCell} colSpan={2}>
            <Typography className={classes.title}>
              Net Investment Value After{" "}
              {loanPaidInYears.noPointsOffer.toFixed(1)} Years
            </Typography>
            <br />
            <Typography>
              (Assuming a {INVESTMENT_RETURN}% average annual return)
            </Typography>
          </TableCell>
          <TableCell className={classes.breakDownCell}>
            <Typography>
              {formatWholeDollars(netInvestmentValue.noPointsOffer)}
            </Typography>
          </TableCell>
          <TableCell className={classes.breakDownCell}>
            <Typography>
              {formatWholeDollars(netInvestmentValue.pointOfferOne)}
            </Typography>
          </TableCell>
          <TableCell className={classes.breakDownCell}>
            <Typography>
              {formatWholeDollars(netInvestmentValue.pointOfferTwo)}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    marginTop: theme.spacing(2),
  },
  headRow: {
    borderBottom: "1px solid #000",
  },
  headCell: {
    fontWeight: 600,
    border: 0,
    textAlign: "center",
    wordBreak: "normal",
  },

  row: {
    border: 0,
  },
  breakDownRow: {
    border: 0,
    backgroundColor: "#f0fafe",
  },
  breakDownTitleCell: {
    padding: theme.spacing(4, 2),
    border: 0,
    "& .MuiTypography-root": {
      wordBreak: "normal",
    },
  },
  breakDownCell: {
    border: 0,
    textAlign: "center",
    "& .MuiTypography-root": {
      wordBreak: "normal",
    },
  },
  titleCell: {
    border: 0,
    textAlign: "left",
    "& .MuiTypography-root": {
      wordBreak: "normal",
    },
  },
  cell: {
    border: 0,
    textAlign: "center",
    "& .MuiTypography-root": {
      wordBreak: "normal",
    },
  },
  title: {
    fontWeight: 600,
  },
}));

export default InvestmentMethodStrategy;
