import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";

export const planLandingPageText =
  "FitBUX makes it easy to maximize your human capital and increase your FitBUX Score. Once you've built your plan, upgrade to the FitBUX Solution to implement your plan, track your progress, and receive step-by-step guidance with unlimited access to your FitBUX Coach.";

export const planListText =
  "These are the plans you've created and/or implemented. Select the plan you would like to edit, review, or delete.";

export const planBuilderText: {
  [key: number]: string;
} = {
  [PLAN_BUILD_STEPS.NAME]: "",
  [PLAN_BUILD_STEPS.REUSE_SELECTIONS]:
    "Compare 2 different strategies starting from a shared baseline (i.e. paying off student lans vs. IDR). Duplicate an existing plan then tweak it to compare.",
  [PLAN_BUILD_STEPS.INCOME_AND_EXPENSES]:
    "FitBUX formula 1: Income - Expenses = Remaining Cash (a.k.a your budget). Having a clear understanding of your current situation is key to building an actionable plan.",
  [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS]:
    "FitBUX Formula 2: Assets - Debt = Net Worth.  Allocate a percentage of your Total Income to assets and debt.  This makes it easy to evaluate your plan and implement it over time.",
  [PLAN_BUILD_STEPS.RISK_MANAGEMENT]:
    "Risk Management, a.k.a purchasing insurance (life, disability etc) is key to limiting the risk to your projected income. Enter your insurance premiums as a percentage of your Total Income.",
  [PLAN_BUILD_STEPS.LIFE_EVENTS_OVERVIEW]:
    "Add the life events/goals you would like to your plan. This is an easy way to compare plans (ie. compare your financial outcome whether you buy or rent a home etc)..",
  [PLAN_BUILD_STEPS.SAVINGS]:
    "Building an emergency fund should always be your number one goal. The FitBUX Solution will dynamically calculate your emergency fund based on your actual spending patterns.",
  [PLAN_BUILD_STEPS.REVIEW]:
    "Building an emergency fund should always be your number one goal. The FitBUX Solution will dynamically calculate your emergency fund based on your actual spending patterns.",
  [PLAN_BUILD_STEPS.COMPARE]:
    "Building an emergency fund should always be your number one goal. The FitBUX Solution will dynamically calculate your emergency fund based on your actual spending patterns.",
  [PLAN_BUILD_STEPS.IMPLEMENT]:
    "Building an emergency fund should always be your number one goal. The FitBUX Solution will dynamically calculate your emergency fund based on your actual spending patterns.",
};
