import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button, makeStyles } from "@material-ui/core";

import Card from "src/components/Card";
import Accordion from "src/components/Accordion";
// import { Modal } from "src/components/Dialogs";
import Icon from "src/components/Icon";
import { Account, ASSET_TYPES } from "src/interfaces";
import { getAssetSummary } from "src/store/account/selector";
import { formatThousands } from "src/utils";
import AddEditManualAccountDialog from "src/pages/Dashboard/MyProfile/Accounts/components/AddEditManualAccountDialog";

const AssetSummary: React.FC = () => {
  const styles = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>();

  const summary = useSelector(getAssetSummary);

  useEffect(() => {
    if (!dialogOpen) {
      setSelectedAccount(undefined);
    }
  }, [dialogOpen]);

  const headButton = () => (
    <Button
      variant="outlined"
      className={styles.button}
      color="primary"
      onClick={() => {
        setSelectedAccount(undefined);
        setDialogOpen(true);
      }}
      endIcon={<Icon iconName="fb-add-alt" />}
    >
      Add account
    </Button>
  );

  const sections: any[] = [];

  const sectionNames = [
    {
      section: "cash",
      label: "Cash/Checking/Savings",
    },
    {
      section: "otherInvestments",
      label: "Taxable Investments",
    },
    {
      section: "retirement",
      label: "Retirement",
    },
    {
      section: "realEstate",
      label: "Real Estate",
    },
    {
      section: "auto",
      label: "Auto",
    },
    {
      section: "otherAssets",
      label: "Other Assets",
    },
  ];
  sectionNames.forEach((item) => {
    const sectionName = item.section;
    if (summary[`${sectionName}Total`]) {
      sections.push({
        title: item.label,
        titleDetail: formatThousands(summary[`${sectionName}Total`]),
        contentRows: summary[`${sectionName}Detail`]
          ? summary[`${sectionName}Detail`].map((account: Account) => ({
              text:
                account.name ||
                ASSET_TYPES[account.variable as keyof typeof ASSET_TYPES],
              total: formatThousands(account.balance || 0),
              action: (
                <Icon
                  iconName="fa-pencil"
                  className="text-blue cursor-pointer"
                  onClick={() => {
                    setSelectedAccount(account);
                    setDialogOpen(true);
                  }}
                />
              ),
            }))
          : undefined,
      });
    }
  });

  return (
    <Card title="Asset Summary" headComponent={headButton}>
      <div className="mt-2">
        <Accordion
          sections={sections}
          footer={{
            text: "Total Assets",
            total: formatThousands(summary.grandTotal),
          }}
        />
      </div>
      <AddEditManualAccountDialog
        open={dialogOpen}
        isLinked={false}
        accountTypes={ASSET_TYPES}
        selected={selectedAccount}
        setSelected={setSelectedAccount}
        closeOnSave
        onClose={() => {
          setDialogOpen(false);
        }}
      />
    </Card>
  );
};
const useStyles = makeStyles({
  button: {
    width: 200,
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
});
export default AssetSummary;
