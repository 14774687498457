import { createAction } from "redux-actions";
import { START } from "../common";

export interface SetRefreshPayload {
  summaries?: boolean;
  projection?: boolean;
  score?: boolean;
}

export interface IdrDetailsPayload {
  who?: "applicant" | "spouse";
  accountIds: number[];
  assumptions: {
    earnings_growth: number;
    tax_rate: number;
    savings_rate: number;
  };
}

export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const GET_SCORE = "GET_SCORE";
export const GET_SCORE_RANKS = "GET_SCORE_RANKS";
export const LOAD_ALL_ACCOUNT_DETAILS = "LOAD_ALL_ACCOUNT_DETAILS";
export const GET_MESSAGES = "GET_MESSAGES";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const MARK_MESSAGE_READ = "MARK_MESSAGE_READ";
export const GET_CALLS = "GET_CALLS";
export const GET_PLAN_NOTIFICATIONS = "GET_PLAN_NOTIFICATIONS";
export const DISMISS_PLAN_NOTIFICATION = "DISMISS_PLAN_NOTIFICATION";
export const DISMISS_ALL_PLAN_NOTIFICATIONS = "DISMISS_ALL_PLAN_NOTIFICATIONS";
export const LOAD_PLAN_SUMMARY = "LOAD_PLAN_SUMMARY";
export const LOAD_STUDENT_PLAN_SUMMARY = "LOAD_STUDENT_PLAN_SUMMARY";
export const LOAD_DASHBOARD_DATA = "LOAD_DASHBOARD_DATA";
export const GET_STUDENT_INCOME_AND_EXPENSE_SUMMARIES =
  "GET_STUDENT_INCOME_AND_EXPENSE_SUMMARIES";
export const GET_INCOME_AND_EXPENSE_SUMMARIES =
  "GET_INCOME_AND_EXPENSE_SUMMARIES";
export const SET_REFRESH_NEEDED = "SET_REFRESH_NEEDED";
export const GET_IDR_DATA = "GET_IDR_DATA";
export const SET_IDR_DETAILS = "SET_IDR_DETAILS";
export const GET_MISC_STATE = "GET_MISC_STATE";
export const SET_MISC_STATE = "SET_MISC_STATE";
export const SET_HELP = "SET_HELP";
export const CLEAR_HELP = "CLEAR_HELP";
export const SET_TUTORIAL = "SET_TUTORIAL";
export const CLEAR_TUTORIAL = "CLEAR_TUTORIAL";
export const SET_VIDEO = "SET_VIDEO";
export const CLEAR_VIDEO = "CLEAR_VIDEO";
export const SET_POPUP = "SET_POPUP";
export const CLEAR_POPUP = "CLEAR_POPUP";
export const SET_POPUP_ACTIVE = "SET_POPUP_ACTIVE";
export const SET_HEADER_CLICKED = "SET_HEADER_CLICKED";
export const SET_POPUP_PLAN = "SET_POPUP_PLAN";
export const ENABLE_SIDEBAR = "ENABLE_SIDEBAR";
export const DISABLE_SIDEBAR = "DISABLE_SIDEBAR";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const SET_SIDEBAR_CONTENT = "SET_SIDEBAR_CONTENT";
export const FETCH_ACTION_ITEMS = "FETCH_ACTION_ITEMS";
export const HIDE_ACTION_ITEM = "HIDE_ACTION_ITEM";
export const CLEAR_ACTION_ITEM = "CLEAR_ACTION_ITEM";

export const setActivePage = createAction(SET_ACTIVE_PAGE);
export const getScore = createAction(GET_SCORE + START);
export const getScoreRanks = createAction(GET_SCORE_RANKS + START);
export const getMessages = createAction(GET_MESSAGES + START);
export const sendMessage = createAction<string>(SEND_MESSAGE + START);
export const markMessageRead = createAction<number>(MARK_MESSAGE_READ + START);
export const getCalls = createAction(GET_CALLS + START);
export const getPlanNotifications = createAction(
  GET_PLAN_NOTIFICATIONS + START
);
export const dismissPlanNotification = createAction<number>(
  DISMISS_PLAN_NOTIFICATION + START
);
export const dismissAllPlanNotifications = createAction(
  DISMISS_ALL_PLAN_NOTIFICATIONS + START
);
export const getIncomeAndExpenseSummaries = createAction(
  GET_INCOME_AND_EXPENSE_SUMMARIES + START
);
export const getStudentIncomeAndExpenseSummaries = createAction(
  GET_STUDENT_INCOME_AND_EXPENSE_SUMMARIES + START
);
export const loadDashboardData = createAction(LOAD_DASHBOARD_DATA + START);
export const loadPlanSummary = createAction(LOAD_PLAN_SUMMARY + START);
export const loadStudentPlanSummary = createAction(
  LOAD_STUDENT_PLAN_SUMMARY + START
);
export const setRefreshNeeded = createAction<SetRefreshPayload>(
  SET_REFRESH_NEEDED
);
export const getIdrData = createAction<{ applicant: boolean; spouse: boolean }>(
  GET_IDR_DATA + START
);
export const setIdrDetails = createAction<IdrDetailsPayload>(
  SET_IDR_DETAILS + START
);
export const getMiscState = createAction(GET_MISC_STATE + START);
export const setMiscState = createAction(SET_MISC_STATE + START);
export const setHelp = createAction<string>(SET_HELP);
export const clearHelp = createAction(CLEAR_HELP);
export const setTutorial = createAction<string>(SET_TUTORIAL);
export const clearTutorial = createAction(CLEAR_TUTORIAL);
export const setVideo = createAction<string>(SET_VIDEO);
export const clearVideo = createAction(CLEAR_VIDEO);
export const setPopup = createAction<string>(SET_POPUP);
export const setPopupActive = createAction<boolean>(SET_POPUP_ACTIVE);
export const setHeaderClicked = createAction<boolean>(SET_HEADER_CLICKED);
export const clearPopup = createAction(CLEAR_POPUP);
export const setPopupPlan = createAction(SET_POPUP_PLAN);
export const disableSidebar = createAction(DISABLE_SIDEBAR);
export const enableSidebar = createAction(ENABLE_SIDEBAR);
export const toggleSidebar = createAction(TOGGLE_SIDEBAR);
export const setSidebarContent = createAction(SET_SIDEBAR_CONTENT);
export const fetchActionItems = createAction(FETCH_ACTION_ITEMS + START);
export const hideActionItem = createAction(HIDE_ACTION_ITEM + START);
export const clearActionItem = createAction(CLEAR_ACTION_ITEM + START);
