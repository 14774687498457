import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Divider, makeStyles, Typography } from "@material-ui/core";

import { getStaleAccountsApi, touchAccountApi } from "src/apiService";
import { Account, ACCOUNT_TYPES } from "src/interfaces";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import CustomDialog from "src/components/Dialogs/CustomDialog";
import { updateAccount } from "src/store/account/actions";
import { getAccounts } from "src/store/account/selector";
import { getIsMarried } from "src/store/system/selector";
import { DollarTextField, DOLLAR_FORMATS, formatDollars } from "src/utils";

const useStyles = makeStyles({
  accountList: {},
  typeRow: {
    marginTop: 12,
  },
  accountRow: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  dialogTitle: {
    "& > h2": {
      fontSize: 24,
    },
  },
  dialogInstructions: {
    fontSize: 18,
  },
  expandIcon: {
    cursor: "pointer",
  },
});

const UpdateManualAccountsDialog = ({ open, onClose }: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const isMarried = useSelector(getIsMarried);
  const accounts: Account[] = useSelector(getAccounts);
  const [accountIds, setAccountIds] = useState<Set<number>>(new Set([]));
  const [newBalances, setNewBalances] = useState<any>({});
  // const [saving, setSaving] = useState(false);
  const [expanded, setExpanded] = useState<Set<string>>(new Set([]));

  const toggleExpanded = (whoType: string) => {
    const newExpanded = new Set(expanded);
    if (newExpanded.has(whoType)) {
      newExpanded.delete(whoType);
    } else {
      newExpanded.add(whoType);
    }
    setExpanded(newExpanded);
  };

  useEffect(() => {
    if (open) {
      getStaleAccountsApi().then((result) => {
        setAccountIds(new Set(result));
      });
    }
  }, [open]);
  const groupedAccounts = useMemo(() => {
    const result: any = {};
    accounts.forEach((account: Account) => {
      if (!accountIds.has(account.id) || account.variable === "bursar") {
        return;
      }
      const whoType = `${account.who}__${account.variable}`;
      if (!result[whoType]) {
        result[whoType] = {
          accounts: [],
          total: 0,
        };
      }
      result[whoType].accounts.push(account);
      result[whoType].total +=
        account.id in newBalances ? newBalances[account.id] : account.balance;
    });
    return result;
  }, [accounts, accountIds, newBalances]);

  const setNewBalance = (accountId: number, value: number) =>
    setNewBalances({ ...newBalances, ["" + accountId]: value });

  const submit = () => {
    // setSaving(true);
    Object.keys(newBalances).forEach((accountId: any) => {
      const update: any = { balance: newBalances[accountId] };
      const account = accounts.find((item) => item.id === accountId);
      if (account) {
        update.name = account.name;
        update.firm = account.firm;
      }
      dispatch(
        updateAccount({
          id: accountId,
          update,
        })
      );
    });
    const promises: Array<Promise<any>> = [];
    Array.from(accountIds).forEach((accountId) => {
      if (!(accountId in newBalances)) {
        promises.push(touchAccountApi(accountId));
      }
    });
    Promise.all(promises).then(onClose);
  };

  const renderAccountGroup = (whoType: string) => {
    const [who, accountType] = whoType.split("__");
    let label = ACCOUNT_TYPES[accountType];
    if (isMarried) {
      label += ` (${who === "spouse" ? "Spouse" : "Mine"})`;
    }
    return (
      <Box className={styles.typeRow}>
        <Divider />
        <Box className={styles.accountRow}>
          <Box className="flex">
            <Typography variant="body1">{label}</Typography>
            {accountType === "fed_loan" && (
              <Icon
                iconName={
                  expanded.has(whoType) ? "fb-chevron-down" : "fb-chevron-right"
                }
                onClick={() => toggleExpanded(whoType)}
                className={styles.expandIcon}
              />
            )}
          </Box>
          {formatDollars(
            groupedAccounts[whoType].total,
            DOLLAR_FORMATS.DOLLARS_AND_CENTS
          )}
        </Box>
        {(accountType !== "fed_loan" || expanded.has(whoType)) && (
          <>
            {groupedAccounts[whoType].accounts.map((account: Account) => {
              let accountLabel = ACCOUNT_TYPES[accountType];
              if (account.firm) {
                if (account.name) {
                  accountLabel = `${account.firm} - ${account.name}`;
                } else {
                  accountLabel = account.firm;
                }
              } else if (account.name) {
                accountLabel = account.name;
              }
              return (
                <Box className={styles.accountRow}>
                  <Typography variant="body1">{accountLabel}</Typography>
                  <DollarTextField
                    variant="outlined"
                    placeholder="$"
                    value={
                      account.id in newBalances
                        ? newBalances[account.id]
                        : account.balance
                    }
                    onChange={(event: any) =>
                      setNewBalance(account.id, event.target.value)
                    }
                    className="w-36"
                  />
                </Box>
              );
            })}
          </>
        )}
      </Box>
    );
  };

  return (
    <CustomDialog
      onClose={onClose}
      isOpen={open}
      size="md"
      title="It's Time To Update Your Manual Account Balances"
      titleClassName={styles.dialogTitle}
    >
      <Typography variant="body1" className={styles.dialogInstructions}>
        Please update these balances as they are more than 60 days old.
      </Typography>
      <Box className={styles.accountList}>
        {Object.keys(groupedAccounts)
          .sort((a: string, b: string) => {
            const aWho = a.split("__")[1];
            const bWho = b.split("__")[1];
            if (aWho === "spouse" && bWho !== "spouse") {
              return 1;
            }
            if (aWho !== "spouse" && bWho === "spouse") {
              return -1;
            }
            return 0;
          })
          .map(renderAccountGroup)}
      </Box>
      <Box className={styles.actions}>
        <Button className="mr-4" fbColor="primary" onClick={submit}>
          Save
        </Button>
      </Box>
    </CustomDialog>
  );
};

export default UpdateManualAccountsDialog;
