import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { range } from "lodash";

import {
  Box,
  makeStyles,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import { profileBuilderStyles } from "src/theme";

import CenterContainer from "../../Components/CenterContainer";
import Button from "src/components/Button";
import { MONTHS } from "src/constants";
import { savePlan, updateCurrentPlan } from "src/store/planBuild/actions";
import { getCurrentPlan } from "src/store/planBuild/selector";
import { getGraduationYear } from "src/store/profileBuild/selector";
import { Plan, PlanViewComponent } from "src/interfaces";
import { DollarTextField } from "src/utils";

const useStyles = makeStyles(profileBuilderStyles);

const now = new Date();
const nowYear = now.getFullYear();
const nowMonth = now.getMonth() + 1;

const Grants: PlanViewComponent = ({ onClose, onSave, render }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const plan: Plan = useSelector(getCurrentPlan);
  const graduationYear = useSelector(getGraduationYear);

  const [formValues, setFormValues] = useState<any>({
    year: nowYear,
    month: nowMonth,
  });

  const save = () => {
    let month = formValues.month;
    if (+month < 10) {
      month = "0" + month;
    }
    const newItem = {
      type: "grant",
      date: `${formValues.year}-${month}`,
      payment: formValues.payment,
    };
    const planUpdate = {
      education: [...(plan.education || []), newItem],
    };
    dispatch(updateCurrentPlan(planUpdate));
    dispatch(savePlan(null));
    onSave();
  };

  const setFormValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    const newFormValues = { ...formValues, [field]: value };
    setFormValues(newFormValues);
  };

  // TODO Icon
  return render({
    component: (
      <CenterContainer
        title="Scholarships/Grants/Family Aid/Gifts/529 Plan/Coverdell ESA"
        iconName="fb-scales-tripped"
      >
        <Box className="flex mt-4">
          <FormControl>
            <FormLabel className="mb-4">Amount</FormLabel>
            <DollarTextField
              variant="outlined"
              name="payment"
              value={formValues.payment}
              onChange={setFormValue}
            />
          </FormControl>
          <FormControl className="ml-4">
            <FormLabel className="mb-4">
              Planned Date You'll Receive Funds
            </FormLabel>
            <Box className="flex">
              <Select
                variant="outlined"
                style={{ width: "150px" }}
                name="month"
                onChange={setFormValue}
                value={formValues.month}
                className="mr-4"
              >
                {MONTHS.map((month: string, index: number) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
              <Select
                variant="outlined"
                style={{ width: "150px" }}
                name="year"
                onChange={setFormValue}
                value={formValues.year}
              >
                {range(nowYear, graduationYear + 1).map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </FormControl>
        </Box>
        <Button
          variant="outlined"
          fbColor="primary"
          onClick={onClose}
          className={styles.btnCancel}
        >
          Cancel
        </Button>
      </CenterContainer>
    ),
    nextDisabled: !formValues.payment,
    nextLabel: "Save",
    nextTooltip: !formValues.payment
      ? "Please enter a loan amount."
      : undefined,
    onNext: save,
  });
};

export default Grants;
