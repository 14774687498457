import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "src/components/Icon";
import colors from "src/theme/colors";
import clsx from "clsx";
import { calculatePercentile } from "./ListScore";

const titles = {
  Excellent: "You're Doing Great!",
  Average: "You're Doing Well",
  Poor: "Improvement Needed",
};

const blurbs = {
  Excellent:
    "You are in great shape. Stay on pace by building and implementing your plan, and tracking your FitBUX Score.",
  Average:
    "You’re in a good place. You have moderate financial risk, and multiple financial options. Keep it up by building and implementing your plan, and tracking your FitBUX Score.",
  Poor:
    "You should take steps to lower your financial risk and gain financial flexibility. Build and implement your plan to track your FitBUX Score to hit your goals.",
};

interface ScoreReportProps {
  className?: string;
  score: number;
}
const TextScoreReport = ({ className, score }: ScoreReportProps) => {
  const styles = useStyles();
  const percentile = calculatePercentile(score, 500, 250);
  return (
    <div className={clsx(styles.container, className)}>
      <Icon iconName={percentile.face} />
      <h1>{titles[percentile.title as keyof typeof titles]}</h1>
      <p>{blurbs[percentile.title as keyof typeof titles]}</p>
    </div>
  );
};
const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: 20,
    "& svg,& path": {
      fill: colors.brandingBlue1,
    },
    "& h1": {
      fontSize: 20,
      margin: "12px 0px",
      color: colors.brandingBlue1,
      fontWeight: 600,
    },
    "& p": {
      fontSize: 14,
      margin: 0,
    },
  },
});

export default TextScoreReport;
