import React from "react";
import { useSelector } from "react-redux";

import clsx from "clsx";

import {
  Button,
  Container,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { getAccounts } from "src/store/account/selector";
import { isCurrentPlanImplemented } from "src/store/planBuild/selector";
import colors from "src/theme/colors";
import shadows from "src/theme/shadows";
import {
  ASSET_ALLOCATIONS,
  DEBT_ALLOCATIONS,
  DEBT_TYPES,
} from "src/interfaces";
import Icon from "src/components/Icon";
import CenterContainer from "../../Components/CenterContainer";

const UNAVAILABLE_DEBT_TOOLTIP =
  "You do not have any outstanding debts of this kind. Please update your debt accounts on your profile first in order to allocate money to this category.";

const AllocationCategories = ({ isDebt, onSelect }: any) => {
  const styles = useStyles();
  const accounts = useSelector(getAccounts);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const items = isDebt ? DEBT_ALLOCATIONS : ASSET_ALLOCATIONS;
  const availableDebtTypes = new Set<string>([]);
  if (currentPlanIsImplemented) {
    accounts.forEach((account) => {
      const isDebtType = DEBT_TYPES[account.variable as any];
      if (isDebtType && !!account.balance) {
        availableDebtTypes.add(account.variable as string);
      }
    });
  }

  return (
    <CenterContainer
      scrollable
      title={isDebt ? "Debts" : "Assets"}
      iconName={isDebt ? "fb-scales-tripped" : "fb-model"}
    >
      <Container maxWidth="sm" className={styles.main}>
        <Grid container spacing={2}>
          {Object.values(items).map((item) => {
            const disabled =
              currentPlanIsImplemented &&
              isDebt &&
              !availableDebtTypes.has(item.key);
            const component = (
              <Grid item xs={4}>
                <Button
                  fullWidth
                  className={styles.card}
                  onClick={() => onSelect(item)}
                  disabled={disabled}
                >
                  <Icon iconName={item.icon}></Icon>
                  <Typography
                    className={
                      disabled
                        ? clsx(styles.cardText, styles.disabledCardText)
                        : styles.cardText
                    }
                  >
                    {item.label}
                  </Typography>
                </Button>
              </Grid>
            );
            if (disabled) {
              return (
                <Tooltip title={UNAVAILABLE_DEBT_TOOLTIP}>{component}</Tooltip>
              );
            }
            return component;
          })}
        </Grid>
      </Container>
    </CenterContainer>
  );
};

export default AllocationCategories;

const useStyles = makeStyles({
  main: {
    height: "calc(100vh - 210px)",
    padding: 50,
  },
  card: {
    boxShadow: shadows[2],
    height: 110,
    border: `1px solid ${colors.blueGray7}`,
    "&>span": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // color: colors.brandingBlue1,
      cursor: "pointer",
    },
  },
  cardText: {
    fontSize: 12,
    color: colors.brandingBlue1,
    fontWeight: 500,
    textAlign: "center",
    marginTop: 5,
    height: 24,
  },
  disabledCardText: {
    color: colors.gray4,
  },
});
