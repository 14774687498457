import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Card, makeStyles, Typography } from "@material-ui/core";

import BasicCard from "src/components/BasicCard";
import { APPLICANT_WHO_TYPES, MappedCashflow } from "src/interfaces";
import { getIsMarried } from "src/store/system/selector";
import { formatAnnually, formatMonthly } from "src/utils";
import HelpToggle from "src/components/HelpToggle";
import Button from "src/components/Button";
import { HOME_INCOME_TYPES } from "../common";

const useStyles = makeStyles({
  mainContainer: {
    margin: "20px 0px",
  },
  innerBox: {
    backgroundColor: "white",
    width: "80%",
    padding: "20px",
    margin: "auto",
    "& h1": {
      fontSize: "20px",
    },
  },
  transitions: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
    gap: "20px",
  },
});

const ConfirmIncome = ({
  transition,
  income,
  setHomeIncome,
}: {
  transition: (
    next: boolean,
    showResults?: boolean,
    incomeData?: any[]
  ) => void;
  income: any[];
  setHomeIncome: (incomes: any[]) => void;
}) => {
  const classes = useStyles();
  const isMarried = useSelector(getIsMarried);
  const [newIncome, setNewIncome] = useState<any>(null);
  const [confirmedIncomes, setConfirmedIncomes] = useState<any[]>([...income]);
  const [total, setTotal] = useState(0);
  const [wait, setWait] = useState(false);

  useEffect(() => {
    const filteredIncomes = income.filter(
      (entry) => HOME_INCOME_TYPES[entry.type]
    );
    setConfirmedIncomes(filteredIncomes);
  }, [income]);

  useEffect(() => {
    let result = 0;
    confirmedIncomes.forEach((entry) => {
      result += entry.amount;
    });
    setTotal(result);
  }, [confirmedIncomes]);

  const handleNext = (next = true) => {
    setHomeIncome(confirmedIncomes);
    if (next) {
      transition(next, false, confirmedIncomes);
    } else {
      transition(next);
    }
  };

  useEffect(() => {
    if (wait) {
      handleNext();
    }
  }, [confirmedIncomes]);

  const saveEarning = (
    item: MappedCashflow,
    updateFields: MappedCashflow,
    index: number
  ) => {
    setNewIncome(updateFields);
    setNewIncome(null);
    const typeLabel = updateFields.type
      ? HOME_INCOME_TYPES[updateFields.type]
      : "Salary";
    const who = updateFields.who || "applicant";
    const whoLabel =
      who === "spouse"
        ? APPLICANT_WHO_TYPES[who]
        : APPLICANT_WHO_TYPES["applicant"];
    const updatedIncomes = [...confirmedIncomes];
    if (item) {
      updatedIncomes.splice(index, 1, {
        ...updateFields,
        typeLabel,
        whoLabel,
        who,
        amount: updateFields.annual || 0,
      });
    }
    setConfirmedIncomes(updatedIncomes);
  };

  const getIncomeColumns = () => {
    if (isMarried) {
      return [
        {
          label: "Type",
          field: "type",
          type: "select",
          width: "30%",
          items: HOME_INCOME_TYPES,
        },
        {
          label: "Owner",
          field: "who",
          type: "select",
          width: "20%",
          items: APPLICANT_WHO_TYPES,
        },
        {
          label: "Monthly",
          field: "monthly",
          type: "number",
          width: "20%",
          formatter: formatMonthly,
        },
        {
          label: "Annual",
          field: "annual",
          type: "number",
          width: "20%",
          formatter: formatAnnually,
        },
      ];
    } else {
      return [
        {
          label: "Type",
          field: "type",
          type: "select",
          width: "40%",
          items: HOME_INCOME_TYPES,
        },
        {
          label: "Monthly",
          field: "monthly",
          type: "number",
          width: "30%",
          formatter: formatMonthly,
        },
        {
          label: "Annual",
          field: "annual",
          type: "number",
          width: "30%",
          formatter: formatAnnually,
        },
      ];
    }
  };

  const showIncome: any[] = [...confirmedIncomes];
  if (newIncome) {
    showIncome.push(newIncome);
  }

  const defaultNewIncome = {
    earning: 0,
    type: "",
    who: "",
  };

  const addNewIncome = () => setNewIncome(defaultNewIncome);

  const removeIncome = (item: any, index: number) => {
    if (index >= confirmedIncomes.length) {
      setNewIncome(null);
    }
    const newIncome = confirmedIncomes.filter((entry, i) => i !== index);
    setConfirmedIncomes(newIncome);
  };

  return (
    <Box className={classes.mainContainer}>
      <Card className={classes.innerBox}>
        <Typography variant="h1">
          Confirm Your Income <HelpToggle />
        </Typography>
        <BasicCard
          // iconName="fb-money"
          title=""
          hideTitle={true}
          columns={getIncomeColumns()}
          data={showIncome}
          allowSelect
          noContainer
          // saveOnChange
          saveOnClose={wait}
          className="noContainer"
          // defaultNewItem={defaultNewIncome}
          onAdd={addNewIncome}
          onCancelEdit={() => setNewIncome(null)}
          onDelete={removeIncome}
          onSave={saveEarning}
          summaryRow={
            !showIncome.length
              ? undefined
              : {
                  type: "Total",
                  annual: total,
                  monthly: Math.round(total / 12),
                }
          }
        />
      </Card>

      <Box className={classes.transitions}>
        <Button
          onClick={() => {
            handleNext(false);
          }}
          disabled={wait}
        >
          Back
        </Button>

        <Button
          onClick={() => {
            setWait(true);
          }}
          color="primary"
          disabled={wait}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmIncome;
