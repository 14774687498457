import React from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";

import FitbuxScore from "./Blocks/FitbuxScore";
import ComparisonChart from "./Blocks/ComparisonChart";
import HumanCapitalList from "./Blocks/HumanCapitalList";
import FinancialList from "./Blocks/FinancialList";
import { getScoreRanks } from "src/store/dashboard/actions";

export const Content = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getScoreRanks());
  }, []);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <FitbuxScore />
      </Grid>
      <Grid item xs={12} md={6}>
        <ComparisonChart />
      </Grid>
      <Grid item xs={12} md={6}>
        <HumanCapitalList />
      </Grid>
      <Grid item xs={12} md={6}>
        <FinancialList />
      </Grid>
    </Grid>
  );
};
