export const PERSONAL_INFO_KEYS = [
  "phone_number",
  "dob_month",
  "dob_day",
  "dob_year",
  "sex",
];

export const EDUCATION_KEYS = [
  "aa_deg",
  "aa_grad_month",
  "aa_grad_year",
  "aa_field",
  "aa_school",
  "undergrad",
  "undergrad_field",
  "undergrad_grad_month",
  "undergrad_grad_year",
  "undergrad_school",
  "undergrad_gpa",
  "undergrad_internships",
  "highest_degree_education",
  "adv_deg",
  "date_last_school_month",
  "date_last_school_year",
  "adid",
  "adv_school",
  "adv_school_other",
  "adv_deg_gpa",
  "adv_deg_exam",
  "adv_second_deg",
  "adv_second_deg_name",
  "adv_second_school",
  "adv_deg_school",
  "adv_speciality",
  "adv_speciality_other",
  "adv_speciality_other_yn",
  "grad_internships",
  "phd_deg",
  "phd_grad_month",
  "phd_grad_year",
  "phd_field",
  "phd_school",
  "qual_gifted_and_talented",
  "qual_intl_bacc",
  "qual_ap_scholar",
  "qual_scholarship",
  "qual_full_scholarship",
];

export const CAREER_KEYS = [
  "profession",
  "pt_employ_setting",
  "years_work_exp",
  "number_designations",
  "fellowship",
  "residency",
  "designation_name_1",
  "designation_name_2",
  "designation_name_3",
  "designation_name_4",
  "designation_name_5",
  "qual_license_exam",
  "qual_desig_exam",
  "qual_team_leader",
  "qual_speaking",
  "qual_networking",
  "qual_director",
  "qual_internship",
  "qual_trade_group",
  "prior_annual_salary",
];

export const OTHER_HC_KEYS = [
  "lang",
  "lang_1",
  "lang_speaking_1",
  "lang_reading_1",
  "lang_writing_1",
  "lang_2",
  "lang_speaking_2",
  "lang_reading_2",
  "lang_writing_2",
  "qual_ap_scholar", // TODO: confirm edu?
  "qual_military",
  "qual_military_yrs",
  "qual_military_leader",
  "qual_peace_corps",
  "qual_church",
  "qual_church_yrs",
  "qual_church_leader",
  "qual_community",
  "qual_community_yrs",
  "qual_community_leader",
  "qual_gifted_and_talented", // TODO: confirm edu?
  "qual_hospital",
  "qual_hospital_yrs",
  "qual_hospital_leader",
  "qual_intl_bacc", // TODO: confirm edu?
  "qual_scholarship", // TODO: confirm edu?
  "qual_full_scholarship", // TODO: confirm edu?
  "delinquency", // TODO, // debts?
  "qual_nonprofit",
  "qual_nonprofit_yrs",
  "qual_nonprofit_leader",
  "qual_trade_group_leader",
  "qual_networking_leader",
  "qual_articles",
  "qual_articles_qty",
  "qual_speaking_qty",
  "qual_work_prog",
  "qual_work_prog_qty",
  "qual_work_prog_leader",
  "qual_club_sports",
  "qual_club_sports_yrs",
  "qual_club_sports_leader",
  "qual_ncaa_sports",
  "qual_ncaa_sports_yrs",
  "qual_ncaa_sports_d1",
  "qual_ncaa_sports_leader",
  "qual_prof_sports",
  "qual_prof_sports_yrs",
  "qual_prof_sports_leader",
  "qual_hobby_sports",
  "qual_hobby_sports_yrs",
  "qual_marathons",
  "qual_marathons_qty",
  "qual_electronics",
  "qual_electronics_yrs",
  "qual_cs",
  "qual_cs_yrs",
  "qual_web",
  "qual_web_yrs",
  "qual_blog",
  "qual_blog_yrs",
  "qual_entrep",
  "qual_entrep_yrs",
  "qual_diy",
  "qual_diy_yrs",
  "qual_craft",
  "qual_craft_yrs",
  "qual_autobody",
  "qual_autobody_yrs",
];

export const DEBTS_KEYS = [
  "fed_repayment_plan",
  "fed_repayment_start",
  "student_loan_prepayments",
  "perkins_lf",
  "delinquency",
  "delinquency_mths",
];

export const HOUSEHOLD_KEYS = [
  "zip",
  "children_qty",
  "child_1_age",
  "child_2_age",
  "child_3_age",
  "child_4_age",
  "child_5_age",
];

export const DASHBOARD_HOUSEHOLD_KEYS = [
  ...HOUSEHOLD_KEYS,
  "street_address",
  "city",
  "state",
];

export const HOUESHOLD_RISK_KEYS = ["qual_will", "qual_trust"];
