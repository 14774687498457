import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, forOwn, map, pickBy, range } from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import colors from "src/theme/colors";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Link,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import Icon from "src/components/Icon";
import { MONTHS, YEARS_FROM_NOW } from "src/constants";
import {
  ALL_LIFE_EVENTS,
  Graduation,
  LifeEvent,
  LifeEventType,
  LIFE_EVENTS,
  PlanViewComponent,
  Retirement,
} from "src/interfaces";
import {
  addLifeEvent,
  editLifeEvent,
  removeLifeEvent,
  savePlan,
  setBuildStep,
  updateCurrentPlan,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  getCurrentPlan,
  getCurrentPlanYearlySummary,
  isCurrentPlanImplemented,
} from "src/store/planBuild/selector";
import { fillInMissingFunding } from "src/store/planBuild/util";
import {
  updateEducation,
  UpdateEducationPayload,
} from "src/store/profileBuild/actions";
import {
  getProfile,
  getSpouseInSchool,
  getSpouseProfile,
  getUserInSchool,
} from "src/store/profileBuild/selector";
import { getHasPlan, getIsMarried } from "src/store/system/selector";
import { getIsHomeowner } from "src/store/account/selector";
import LifeEventCategories from "./LifeEventCategories";
import NoGraduationEdit from "./NoGraduationEdit";
import CenterContainer from "../../Components/CenterContainer";
import Button from "src/components/Button";
import { DollarTextField } from "src/utils";
dayjs.extend(relativeTime);

const LifeEvents: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const plan = useSelector(getCurrentPlan);
  const planIsImplemented = useSelector(isCurrentPlanImplemented);
  const isHomeowner = useSelector(getIsHomeowner);
  const isMarried = useSelector(getIsMarried);
  const spouseInSchool = useSelector(getSpouseInSchool);
  const userInSchool = useSelector(getUserInSchool);
  const myProfile = useSelector(getProfile);
  const hasPlan = useSelector(getHasPlan);
  const spouseProfile = useSelector(getSpouseProfile);
  const summary = useSelector(getCurrentPlanYearlySummary);
  const styles = useStyles();
  const [showCategories, setShowCategories] = useState(false);
  const [showAddEditForm, setShowAddEditForm] = useState(false);
  const [showNoGraduationEdit, setShowNoGraduationEdit] = useState<any>(null);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [updateFields, setUpdateFields] = useState<any>(null);

  let monthIndex = 12;
  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth() + 1;

  let year: number = nowYear + 1;
  if (editingIndex >= 0 && updateFields) {
    const monthString = updateFields.date.slice(5, 7);
    monthIndex = +monthString;
    year = +updateFields.date.slice(0, 4);
  }
  const [selectedMonth, selectMonth] = useState(monthIndex + 1);
  const [selectedYear, selectYear] = useState(year);

  const setCategory = (type: LifeEventType) => {
    setUpdateFields({ ...LIFE_EVENTS[type].emptyValue });
    setShowCategories(false);
    setShowAddEditForm(true);
  };

  const openNoGraduationEdit = () => {
    setShowAddEditForm(true);
    setShowNoGraduationEdit(true);
  };
  const closeNoGraduationEdit = () => {
    setShowAddEditForm(false);
    setShowNoGraduationEdit(false);
  };

  const updateGraduationDate = () => {
    const isSpouse = updateFields.who === "spouse";
    const profile = isSpouse ? spouseProfile : myProfile;
    const existingEvent = plan.lifeevents[editingIndex];
    if (existingEvent.eventtype !== "graduate") {
      return;
    }
    const existingYear = +existingEvent.date.slice(0, 4);
    const existingMonth = +existingEvent.date.slice(5, 7);
    const educationUpdate: UpdateEducationPayload = {
      update: {},
      who: updateFields.who,
    };
    if (
      profile.phd_grad_year === existingYear &&
      profile.phd_grad_month === existingMonth
    ) {
      educationUpdate.update.phd_grad_year = selectedYear;
      educationUpdate.update.phd_grad_month = selectedMonth;
    }
    if (
      profile.date_last_school_year === existingYear &&
      profile.date_last_school_month === existingMonth
    ) {
      educationUpdate.update.date_last_school_year = selectedYear;
      educationUpdate.update.date_last_school_month = selectedMonth;
    }
    if (
      profile.undergrad_grad_year === existingYear &&
      profile.undergrad_grad_month === existingMonth
    ) {
      educationUpdate.update.undergrad_grad_year = selectedYear;
      educationUpdate.update.undergrad_grad_month = selectedMonth;
    }
    if (
      profile.aa_grad_year === existingYear &&
      profile.aa_grad_month === existingMonth
    ) {
      educationUpdate.update.aa_grad_year = selectedYear;
      educationUpdate.update.aa_grad_month = selectedMonth;
    }
    dispatch(updateEducation(educationUpdate));
  };

  const save = () => {
    let eventToSave: any = pickBy(
      updateFields,
      (value: any, key: string) => key !== "age" && !key.startsWith("enable")
    );
    if (updateFields.eventtype === "retire") {
      const profile = updateFields.who === "spouse" ? spouseProfile : myProfile;
      const dobYear = profile.dob_year;
      const retirementYear = dobYear + updateFields.age;
      eventToSave.date = `${retirementYear}-06-01`;
    } else {
      let monthString = "" + selectedMonth;
      if (monthString.length < 2) {
        monthString = "0" + monthString;
      }
      eventToSave.date = `${selectedYear}-${monthString}-01`;
      if (updateFields.eventtype === "child") {
        if (updateFields.enable_reduce !== "y") {
          eventToSave = {
            eventtype: "child",
            date: eventToSave.date,
            reduce: null,
            salary: null,
          };
        }
      }
      if (
        (updateFields.eventtype === "graduate" && !hasPlan) ||
        planIsImplemented
      ) {
        updateGraduationDate();
      }
    }
    const eventDefinition = ALL_LIFE_EVENTS[updateFields.eventtype];
    eventDefinition.fields.forEach((item: any) => {
      if (item.type === "dollar" && !eventToSave[item.field]) {
        eventToSave[item.field] = 0;
      }
    });
    if (!showCategories) {
      if (editingIndex >= 0) {
        dispatch(editLifeEvent({ index: editingIndex, update: eventToSave }));
      } else {
        dispatch(addLifeEvent(eventToSave));
      }
      dispatch(savePlan(PLAN_BUILD_STEPS.LIFE_EVENTS_OVERVIEW));
    }
    setShowAddEditForm(false);
    setEditingIndex(-1);
  };

  const handleSelectMonth = (e: any) => selectMonth(e.target.value);
  const handleSelectYear = (e: any, newYear: number | null) => {
    if (newYear) {
      selectYear(newYear);
    }
  };

  const setFormValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    let value = e.target.value;
    if (e.target.type === "number") {
      value = +value;
    }
    const update = { ...updateFields, [field]: value };
    if (field === "enable_reduce" && value === "y") {
      update.reduce = "applicant";
      update.salary = 0;
    }
    setUpdateFields(update);
  };

  const removeItem = (index: number) => dispatch(removeLifeEvent(index));

  const setEnableFields = (item: any) => {
    const result: any = { ...item };
    switch (item.eventtype) {
      case "house":
        if (item.rent) {
          result.enable_rent = "y";
        } else {
          result.enable_rent = "n";
        }
        break;
      case "child":
        if (item.reduce) {
          result.enable_reduce = "y";
        } else {
          result.enable_reduce = "n";
        }
        break;
      default:
        break;
    }
    return result;
  };

  const openEdit = (item: LifeEvent, index: number) => {
    if (item.eventtype === "graduate" && planIsImplemented) {
      return openNoGraduationEdit();
    }
    const dateString = item.date;
    const year = dateString.slice(0, 4);
    const month = dateString.slice(5, 7);
    selectMonth(+month);
    selectYear(+year);
    setEditingIndex(index);
    const update = setEnableFields(item);
    setUpdateFields(update);
    setShowAddEditForm(true);
  };

  const onNext = () => {
    if (showCategories) {
      setShowCategories(false);
    } else if (showAddEditForm) {
      save();
    } else {
      const nextStep = PLAN_BUILD_STEPS.REVIEW;
      const { addLoans, years } = fillInMissingFunding(summary);
      if (addLoans) {
        const education = cloneDeep(plan.education || []);
        forOwn(years, (amount: number, year: string) => {
          education.push({
            date: `${year}-01`,
            type: "priv_loan",
            who: "applicant",
            payment: -amount,
          });
        });
        dispatch(updateCurrentPlan({ education }));
        dispatch(savePlan(nextStep));
      } else {
        dispatch(setBuildStep(nextStep));
      }
    }
  };

  const renderEditLink = (event: LifeEvent, index: number) => (
    <Link href="#" className="text-xs" onClick={() => openEdit(event, index)}>
      Edit
    </Link>
  );
  const renderRetirementAgeItems = () => {
    const profile = updateFields.who === "spouse" ? spouseProfile : myProfile;
    const dobYear = profile.dob_year;
    const currentAge = new Date().getFullYear() - dobYear;
    const ages = range(currentAge, 121);
    return ages.map((age: number) => (
      <MenuItem key={age} value={age}>
        {age}
      </MenuItem>
    ));
  };

  const selectedItemDefinition = updateFields
    ? ALL_LIFE_EVENTS[updateFields.eventtype]
    : null;
  let title =
    showAddEditForm && selectedItemDefinition
      ? selectedItemDefinition.typeLabel
      : "Life Events/Goals";
  if (showNoGraduationEdit) {
    title = "Graduation Date";
  }

  const dateInThePast =
    year < nowYear || (selectedYear === nowYear && selectedMonth < nowMonth);

  return render({
    component: (
      <CenterContainer title={title} iconName="fb-event" scrollable={true}>
        {showCategories && <LifeEventCategories onSelect={setCategory} />}
        {showAddEditForm && !showNoGraduationEdit && (
          <Grid container spacing={3} className="mt-5">
            {map(selectedItemDefinition.fields, (item) => {
              if (item.enabled) {
                if (
                  (item.enabled === "profile_married" && !isMarried) ||
                  (item.enabled === "profile_homeowner" && !isHomeowner) ||
                  (item.enabled_values &&
                    item.enabled_values.indexOf(updateFields[item.enabled]) <
                      0) ||
                  (!item.enabled.startsWith("profile") &&
                    updateFields[item.enabled] !== "y")
                ) {
                  return null;
                }
              }
              let label = item.label;
              if (item.whoText) {
                if (updateFields[item.whoText] !== "spouse") {
                  label = label
                    .replace("their", "your")
                    .replace("they", "you")
                    .replace("does your spouse", "do you");
                }
              }
              return (
                <Grid key={item.field} item xs={item.gridWidth || 6}>
                  {item.type === "monthYear" && (
                    <FormControl
                      component="fieldset"
                      className="mt-4 h-full"
                      fullWidth={!item.gridWidth}
                    >
                      <FormLabel component="legend">{label}</FormLabel>
                      <Box className="flex mt-auto">
                        <Select
                          placeholder="Month"
                          variant="outlined"
                          style={{ width: "50%" }}
                          name="month"
                          onChange={handleSelectMonth}
                          value={selectedMonth}
                          className={styles.autoText + " mr-4"}
                        >
                          {MONTHS.map((month: string, index: number) => (
                            <MenuItem key={index + 1} value={index + 1}>
                              {month}
                            </MenuItem>
                          ))}
                        </Select>
                        {/*<Autocomplete
                          id="month_selector"
                          options={Months}
                          getOptionLabel={(option) => option.name}
                          style={{ width: "50%" }}
                          placeholder="Month"
                          className={`mr-4`}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              label="Month"
                              variant="outlined"
                              placeholder="Month"
                              className={styles.autoText}
                            />
                          }
                          onChange={handleSelectMonth}
                          value={selectedMonth}
                        />*/}
                        <Autocomplete
                          id="year_selector"
                          options={YEARS_FROM_NOW}
                          getOptionLabel={(option) => String(option)}
                          style={{ width: "50%" }}
                          placeholder="Year"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Year"
                              className={styles.autoText}
                              name="dob_year"
                            />
                          )}
                          onChange={handleSelectYear}
                          value={selectedYear}
                        />
                      </Box>
                    </FormControl>
                  )}
                  {item.type === "age" && (
                    <FormControl
                      component="fieldset"
                      className="mt-4 h-full"
                      fullWidth
                    >
                      <FormLabel component="legend">{label}</FormLabel>
                      <Select
                        className="mt-auto"
                        variant="outlined"
                        fullWidth
                        value={updateFields[item.field]}
                        name={item.field}
                        placeholder="Select"
                        onChange={setFormValue}
                      >
                        {renderRetirementAgeItems()}
                      </Select>
                    </FormControl>
                  )}
                  {item.type === "select" && (
                    <FormControl
                      component="fieldset"
                      className="mt-4 h-full"
                      fullWidth
                    >
                      <FormLabel component="legend">{label}</FormLabel>
                      <Select
                        className="mt-auto"
                        variant="outlined"
                        fullWidth
                        value={updateFields[item.field]}
                        name={item.field}
                        placeholder="Select"
                        onChange={setFormValue}
                      >
                        {item.select
                          ? item.select.map(
                              (selectItem: { label: string; value: any }) => (
                                <MenuItem
                                  key={selectItem.value}
                                  value={selectItem.value}
                                >
                                  {selectItem.label}
                                </MenuItem>
                              )
                            )
                          : []}
                      </Select>
                    </FormControl>
                  )}
                  {item.type === "dollar" && (
                    <FormControl
                      component="fieldset"
                      className="mt-4 h-full"
                      fullWidth
                    >
                      <FormLabel component="legend">{label}</FormLabel>
                      <DollarTextField
                        className="mt-auto"
                        variant="outlined"
                        fullWidth
                        name={item.field}
                        value={updateFields[item.field]}
                        placeholder="0"
                        onChange={setFormValue}
                      />
                    </FormControl>
                  )}
                  {item.type === "string" && (
                    <FormControl
                      component="fieldset"
                      className="mt-4 h-full"
                      fullWidth
                    >
                      <FormLabel component="legend" className="mb-4">
                        {label}
                      </FormLabel>
                      <TextField
                        className="mt-auto"
                        variant="outlined"
                        fullWidth
                        name={item.field}
                        value={updateFields[item.field]}
                        placeholder=""
                        onChange={setFormValue}
                        type="text"
                      />
                    </FormControl>
                  )}
                </Grid>
              );
            })}
            <Button
              variant="outlined"
              fbColor="primary"
              onClick={() => setShowAddEditForm(false)}
              className={styles.btnCancel}
            >
              Cancel
            </Button>
          </Grid>
        )}

        {showAddEditForm && showNoGraduationEdit && (
          <NoGraduationEdit goBack={closeNoGraduationEdit} />
        )}
        {!showAddEditForm && !showCategories && (
          <Box>
            <>
              {plan.lifeevents.map((event: LifeEvent, index) => {
                const eventDefinition: any = ALL_LIFE_EVENTS[event.eventtype];
                if (!eventDefinition) {
                  return null;
                }
                let typeLabel = eventDefinition.typeLabel;
                let dateLabel = dayjs(event.date).fromNow();
                if (event.eventtype === "retire") {
                  let dobYear = 1999;
                  if ((event as Retirement).who === "spouse") {
                    if (!isMarried) {
                      return null;
                    } else {
                      dobYear = spouseProfile.dob_year;
                      typeLabel += " (Spouse)";
                    }
                  } else {
                    dobYear = myProfile.dob_year;
                  }
                  const retirementYear = +event.date.slice(0, 4);
                  dateLabel = `Age ${retirementYear - dobYear}`;
                } else if (event.eventtype === "graduate") {
                  const isSpouse = (event as Graduation).who === "spouse";
                  if (isSpouse) {
                    typeLabel += " (Spouse)";
                  }
                  if (
                    (isSpouse && (!isMarried || !spouseInSchool)) ||
                    (!isSpouse && !userInSchool)
                  ) {
                    return null;
                  }
                }
                return (
                  <Paper
                    key={(event.eventtype as string) + event.date}
                    className={styles.item}
                  >
                    <Box className="flex items-center text-blue">
                      <Icon iconName={eventDefinition.icon} className="mr-3" />
                      <Box className="w-48 ">
                        <Typography className="text-blue text-bold">
                          {typeLabel}
                        </Typography>
                        <Box className={styles.editRow}>
                          <Typography className="text-disabled">
                            {dateLabel}
                          </Typography>
                          <span>{renderEditLink(event, index)}</span>
                        </Box>
                      </Box>
                    </Box>
                    {event.eventtype !== "retire" &&
                      event.eventtype !== "graduate" && (
                        <Box>
                          <IconButton
                            color="primary"
                            onClick={() => removeItem(index)}
                          >
                            <Icon iconName="fb-trash-can" />
                          </IconButton>
                        </Box>
                      )}
                  </Paper>
                );
              })}
              <Button
                onClick={() => setShowCategories(true)}
                fbColor="primary"
                variant="outlined"
                endIcon={<Icon iconName="fb-add-alt" />}
                className={styles.btnAddItem}
              >
                Add Life Event/Goals
              </Button>
            </>
          </Box>
        )}
      </CenterContainer>
    ),
    nextDisabled: dateInThePast,
    nextTooltip: dateInThePast ? "Please enter a future date." : undefined,
    nextLabel: showAddEditForm ? "Save" : "Next Section",
    onNext,
  });
};

export default LifeEvents;

const useStyles = makeStyles({
  item: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 15px",
    margin: "10px 0px",
    border: `1px solid ${colors.blueGray6}`,
  },
  editRow: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 120,
    alignItems: "center",
    color: colors.blueGray4,
  },
  btnAddItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 30,
  },
  btnCancel: {
    width: "calc(100% - 50px)",
    position: "absolute",
    bottom: 20,
    left: 25,
  },
  autoText: {
    height: 45,
    "&>div": {
      padding: "6px !important",
    },
    "&>legend": {
      visibility: "visible",
    },
  },
});
