import * as React from "react";

import GeneralPage from "../GeneralPage";
import data from "./data";

export const CreditAndPersonalLoans = () => (
  <GeneralPage data={data} title="Credit/Personal Loan Tools and Products" />
);

export default CreditAndPersonalLoans;
