import { HelpItem, TutorialItem } from "src/interfaces";

export const TUTORIALS: { [key: string]: TutorialItem } = {
  PLACEHOLDER: {
    content: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit."],
    name: "PLACEHOLDER",
  },
  post_subscription: {
    name: "post_subscription",
    nImages: 16,
    topic: "Welcome",
  },
  post_profile: {
    name: "post_profile",
    nImages: 10,
    topic: "Welcome",
  },
};

export const VIMEO_VIDEOS: any = {
  PLACEHOLDER: "148751763",
  post_signup: "660734028",
  post_subscription: "623544143",
  planIntro: "660734087",
  planIntroGraduated: "660734087",
  planIntroStudent: "660793985",
  planBuilder0: "620975176",
  planBuilder1: "620974378",
  planBuilder1_2: "636549739",
  planBuilder2: "620974236",
  planBuilder3: "620974280",
  planBuilder4: "620975123",
  netWealthAndHc: "620975079",
  assetSummary: "620974827",
  debtSummary: "620974992",
  riskSummary: "620974236",
  actionItems911: "636548709",
  actionItems12: "636548820",
  actionItems11: "636548757",
  actionItems13: "636548757",
  actionItems14: "636548852",
  actionItems15: "636549040",
  actionItems16: "636549143",
  actionItems17: "636549448",
  actionItems18: "636549739",
  refinance: "666166014",
  actionItems_MONEY_LIFEINS: "636549739",
};

export const YOUTUBE_VIDEOS: any = {
  disabilityInsurance: "G-sQ7dkiqc0",
  lifeInsurance: "3AaODSJKnoI",
};

export const ALL_VIDEOS = { ...VIMEO_VIDEOS, ...YOUTUBE_VIDEOS };

export const HELP: { [key: string]: HelpItem } = {
  PLACEHOLDER: {
    topic: "Placeholder Help",
    content: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut fringilla nibh eu leo egestas dictum. Donec vestibulum commodo mauris, id tincidunt nunc pretium vel. Duis accumsan sit amet nibh et hendrerit. Quisque vel arcu arcu. Cras ac eleifend metus. Vestibulum malesuada massa vitae velit iaculis accumsan. In risus tellus, commodo vestibulum sollicitudin et, elementum ac ante. In hac habitasse platea dictumst. Aliquam id risus eget orci mollis blandit vitae at metus. Sed ut arcu malesuada, tempus ipsum a, suscipit tellus",
    ],
    videoKey: VIMEO_VIDEOS.PLACEHOLDER,
  },
  NOT_FOUND: {
    topic: "Topic Not Found",
    content: ["We couldn't find the help topic you were looking for."],
  },
  buildPlanIntro: {
    topic: "Build and Implement a Plan",
    content: [
      "FitBUX makes it easy to maximize your human capital and increase your FitBUX Score.",
      "Once you've built your plan, upgrade to the FitBUX Solution to implement your plan, track your progress, and receive step-by-step guidance with unlimited access to your FitBUX Coach.",
    ],
    // videoKey: "buildPlanIntro",
  },
  "planBuilder-1": {
    topic: "Duplicate a Plan",
    content: [
      "You can easily duplicate an existing plan and modify it to compare different strategies.",
    ],
  },
  planBuilder0: {
    topic: "Income & Expenses",
    content: [
      "The type of income you receive as well as your spending habits dramatically impact your optimal financial plan.",
      "This video highlights the 3 key items to understand about income, expenses, and remaining cash so you can be on your way to having financial peace of mind.",
    ],
    videoKey: "planBuilder0",
  },
  planBuilder1: {
    topic: "Assets & Debts",
    content: [
      'Understanding what percentage of your income is "going where" is critical to financial planning. This method of planning and implementing your plan is unique to FitBUX.',
      "This video below details how our method will make your financial life a lot easier.",
    ],
    videoKey: "planBuilder1",
    // extraVideoKeys: ["planBuilder1_2"],
  },
  planBuilder2: {
    topic: "Risk Management",
    content: [
      "Manage your risk and your return will be there is a quote our founder often uses. This video details how to use this screen and why insurance is important.",
    ],
    videoKey: "planBuilder2",
  },
  planBuilder3: {
    topic: "Life Events/Goals",
    content: [
      "Some life events/goals can be planned...others may just happen unexpectedly.",
      "The first video below explains how to build a plan and use it to compare scenarios.",
      "The second video highlights why having a plan incorporating expected and potential life events/goals is key to hitting your goals and reducing the risk of (bad) surprises.",
    ],
    videoKey: "planBuilder3",
  },
  planBuilder4: {
    topic: "Emergency Fund",
    content: [
      "Your emergency fund should be customized to you. In the video, we detail how to use this section as well as the factors you'll want to consider when determining what your emergency fund should be.",
    ],
    videoKey: "planBuilder4",
  },
  netWealthAndHc: {
    topic: "Net Wealth",
    content: [],
    videoKey: "netWealthAndHc",
  },
  assetSummary: {
    topic: "Understanding Financial Assets",
    content: [],
    videoKey: "assetSummary",
  },
  debtSummary: {
    topic: "Understanding Debt",
    content: [],
    videoKey: "debtSummary",
  },
  riskSummary: {
    topic: "Understanding Risk Management",
    content: [],
    videoKey: "riskSummary",
  },
  refinance: {
    topic: "Refinancing",
    content: [],
    videoKey: "refinance",
  },
  upgradeFromInvestments: {
    topic: "Investment Recommendations",
    content: [
      "Our recommended optimal asset allocations for your investments takes into account your FitBUX Score and includes many more data points than traditional allocation recommendations.",
      "Since your FitBUX Score is dynamically generated, our recommendations will evolve over time, just like you do.",
    ],
  },
};
