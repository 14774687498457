import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { getPortfolioPerformance } from "src/store/investments/selector";

import AllocationSlider from "./AllocationSlider";
import AllocationTable from "./AllocationTable";

export const ChooseAllocation = ({
  next,
  goBack,
  formValues,
  setFormValues,
}: any) => {
  const { expectedReturns, standardDeviations } = useSelector(
    getPortfolioPerformance
  );
  const fbAllocation = "" + formValues.fitbuxAllocation;
  const myAllocation = "" + formValues.allocation;
  const myAvg = expectedReturns[myAllocation] || 0;
  const fbAvg = expectedReturns[fbAllocation] || 0;
  const myStdDev = standardDeviations[myAllocation] || 0;
  const fbStdDev = standardDeviations[fbAllocation] || 0;

  return (
    <Card title="Use the Slider to Choose Your Investment Allocation">
      <Typography className="mt-4 mb-6">
        If you'd like to use FitBUX's recommended allocation, be sure that Your
        Chosen Allocation is aligned with FitBUX's Recommended Allocation. To
        change your allocation, move the icon labeled Your Chosen Allocation.
      </Typography>
      <AllocationSlider formValues={formValues} setFormValues={setFormValues} />
      <AllocationTable
        myAllocationLabel="Chosen"
        myAvg={myAvg}
        fbAvg={fbAvg}
        myStdDev={myStdDev}
        fbStdDev={fbStdDev}
      />
      <Box className="flex mt-4 justify-center">
        <Button className="mr-4" onClick={goBack}>
          Back
        </Button>
        <Button fbColor="primary" onClick={next}>
          Next
        </Button>
      </Box>
    </Card>
  );
};

export default ChooseAllocation;
