import React, { useState } from "react";
// import {
//   makeStyles,
// } from "@material-ui/core";

import { PlanViewComponent } from "src/interfaces";

import FundingCategories from "./FundingCategories";
import AddIncome from "./AddIncome";
import FedStudentLoan from "./FedStudentLoan";
import Grants from "./Grants";
import OtherFunding from "./OtherFunding";
import ParentPlusLoan from "./ParentPlusLoan";
import PrivStudentLoan from "./PrivStudentLoan";
import UseAssets from "./UseAssets";

const AddFunding: PlanViewComponent = ({
  editingSpecialType,
  onClose,
  render,
}) => {
  const [showCategories, setShowCategories] = useState(!editingSpecialType);
  const [addingSpecialType, setAddingSpecialType] = useState<any>(
    editingSpecialType
  );

  const closeSpecialType = () => {
    if (editingSpecialType) {
      onClose();
      setShowCategories(true);
    } else {
      setAddingSpecialType(null);
      setShowCategories(true);
    }
  };

  const selectCategory = (category: any) => {
    setShowCategories(false);
    setAddingSpecialType({ key: category.key });
  };

  const renderType = () => {
    // TODO
    let SubComponent = null;
    switch (addingSpecialType.key || addingSpecialType.type) {
      case "fed_loan":
        SubComponent = FedStudentLoan;
        break;
      case "priv_loan":
        SubComponent = PrivStudentLoan;
        break;
      case "income":
        SubComponent = AddIncome;
        break;
      case "parent_loan":
        SubComponent = ParentPlusLoan;
        break;
      case "grant":
        SubComponent = Grants;
        break;
      case "assets":
        SubComponent = UseAssets;
        break;
      case "funding":
      default:
        SubComponent = OtherFunding;
    }
    return (
      <SubComponent
        initialValues={editingSpecialType}
        onClose={closeSpecialType}
        onSave={onClose}
        render={render}
        type={addingSpecialType.key || addingSpecialType.type}
      />
    );
  };

  if (showCategories) {
    return render({
      component: <FundingCategories onSelect={selectCategory} />,
      nextLabel: "Cancel",
      onNext: onClose,
    });
  }
  return renderType();
};

export default AddFunding;

// const useStyles = makeStyles({
//   cell: {
//     width: "30%",
//     fontSize: 12,
//   },
//   headerCell: {
//     fontWeight: "bolder",
//     width: "30%",
//     fontSize: 12,
//     marginBottom: 15,
//     color: colors.brandingBlue1,
//   },
//   row: {
//     display: "flex",
//     alignItems: "center",
//   },
//   btnCancel: {
//     display: "block",
//     margin: "20px auto",
//     width: "calc(100% - 50px)",
//   },
// });
