import React from "react";
import { EuiProvider, EuiPageBody } from "@elastic/eui";
import Logo from "src/components/Global/FitbuxLogo";
import "src/Eui.css";

const LoginLayout = ({
  children,
}: {
  children: React.ReactNode;
  pageTitle: string;
}) => {
  return (
    <EuiProvider>
      <EuiPageBody>
        <header className="logo mobile-logo">
          <Logo link="/" className="logo-container" alt="fitbux logo" />
        </header>
        {children}
      </EuiPageBody>
    </EuiProvider>
  );
};

export default LoginLayout;
