import Tap from "@tapfiliate/tapfiliate-js";
import { decodeToken } from "react-jwt";
import { getAuthToken } from "src/config";
const commissionStandard =
  process.env.REACT_APP_COMMISSION_STANDARD || "standard";
const commissionSchool = process.env.REACT_APP_COMMISSION_SCHOOL || "school";

export { initializeTapfiliate } from "./initialize.js";

export const CustomerSignup = () => {
  const applicantID = decodeToken(getAuthToken()).sub;
  Tap.customer(applicantID);
};

export const TapConverted = (uniqueID = "", inSchool) => {
  const commissionType = inSchool ? commissionSchool : commissionStandard;
  const userID = decodeToken(getAuthToken()).sub;
  Tap.conversion(uniqueID, null, { customer_id: userID }, commissionType);
};
