import { makeStyles } from "@material-ui/core";

import colors from "src/theme/colors";

const useStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "space-between",
    height: "100vh",
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closeIcon: {},
  closedAccount: {
    opacity: 0.6,
  },
  providerErrorHeader: {
    textAlign: "center",
  },
  providerErrorColor: {
    color: colors.warn,
  },
  errorHeaderText: {
    color: colors.brandingBlue1,
    fontSize: 16,
    marginBottom: 16,
    marginTop: 18,
  },
  sidebar: {
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    maxHeight: "calc(100vh - 70px)",
    "&>div": {
      height: "100%",
      "& h6": {
        "font-size": "15px !important",
      },
    },
    maxWidth: 410,
    minWidth: 410,
  },
  leftCenter: {
    height: "100%",
    maxHeight: "calc(100vh - 280px)",
    overflowY: "auto",
    marginBottom: 10,
    padding: 24,
  },
  leftBottom: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
    padding: "0 60px",
    alignContent: "center",
    height: 98,
  },
  main: {
    "&>div": {
      "& h6": {
        "font-size": "15px !important",
      },
    },
    height: "100%",
    maxHeight: "calc(100vh - 70px)",
    width: "100%",
    borderRadius: 12,
    marginLeft: 20,
    marginRight: 10,
  },
  mainBottom: {
    height: 150,
    marginLeft: 40,
    paddingTop: 20,
  },
  step: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 15,
    cursor: "pointer",
  },
  inactiveStep: {
    color: colors.blueGray3,
    opacity: 0.6,
  },
  inactiveWarningStep: {
    color: colors.error,
    opacity: 0.6,
  },
  activeWarningStep: {
    fontWeight: 600,
    color: colors.error,
  },
  activeStep: {
    fontWeight: 600,
    fontSize: `13px !important`,
    opacity: `1 !important`,
    color: `${colors.brandingBlue1} !important`,
  },
  completedStep: {
    color: `${colors.brandingBlue1} !important`,
  },
  tableHeader: {
    textAlign: "center",
    width: 400,
  },
  accountList: {
    height: "calc(100vh - 260px)",
    overflowY: "auto",
  },
  accountRow: {
    display: "flex",
    marginTop: 10,
  },
  accountName: {
    display: "flex",
    alignContent: "center",
    width: 400,
  },
  accountBalance: {
    width: 200,
    textAlign: "center",
  },
});

export default useStyles;
