import React from "react";
import { useSelector } from "react-redux";

import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";

import {
  getDebtProjection,
  getMinFutureProjections,
  getLifeEventProjections,
  getEmergencyMonthlyExpenses,
  getCurrentPlan,
} from "src/store/planBuild/selector";
import { PROJECTION_DEBTS } from "src/interfaces";
import { formatWholeDollars } from "src/utils";

import OverviewTable from "../components/OverviewTable";
import Chart from "src/components/Chart";
import CenterContainer from "src/pages/PlanBuilder/Components/CenterContainer";
import { colors } from "src/theme";

const Debts = ({ optimized }: { optimized?: boolean }) => {
  const styles = useStyles();
  const debtProjection = useSelector(getDebtProjection);
  const future = useSelector(getMinFutureProjections);
  const plan = useSelector(getCurrentPlan);
  const planEvents: any[] = plan.lifeevents;
  const monthlyExpenses = useSelector(getEmergencyMonthlyExpenses);
  const months = plan.emergencymonths;
  const events = useSelector(getLifeEventProjections);
  const orderIndex = optimized ? 5 : 4;
  const title = `${orderIndex}. Debts`;

  // goal summary based on user provided life event data
  const eventGoals = events.map((event) => {
    // initial a goal to be displayed
    let goal = 0;
    let date = "";

    // check if the user input a life event for this goal
    const eventData = planEvents.find(
      (e) =>
        e.eventtype === event.type &&
        Date.parse(e.date.slice(0, 7)) >= Date.parse(event.plan)
    );
    // remove the life event so that the same data isn't dispalyed twice
    // planEvents.splice(planEvents.indexOf(eventData), 0);

    // if the event is an emergencyfund then set the goal to be based on montly expenses
    // else set the goal to be based on what the user input into the plan
    if (event.type === "emergencyfund") {
      goal = monthlyExpenses * months;
    } else if (eventData) {
      goal = eventData.down ? eventData.down : eventData.cost || 0;
    }
    date = eventData && eventData.date ? eventData.date : "";

    return {
      ...event,
      goal,
      date,
    };
  });

  const hasDebts = !!eventGoals.length;
  const retirementDate = eventGoals.length
    ? eventGoals[eventGoals.length - 1].plan
    : "";
  const retirementMonth = retirementDate.slice(5);
  const retirementYear = retirementDate.slice(0, 4);

  const now = new Date();
  const nowYear = now.getFullYear();
  const years = [
    "Now",
    "" + (nowYear + 5),
    "" + (nowYear + 10),
    future.retirementYear || "",
  ];

  const debtKeyList = Array.from(future.debtKeys as Set<string>).filter(
    (key: string) => key !== "total"
  );

  const toMonthName = (monthNumber: string) => {
    const date = new Date();
    date.setMonth(Number(monthNumber) - 1);
    return date.toLocaleString("en-US", {
      month: "long",
    });
  };

  return (
    <CenterContainer review title={title}>
      <Grid container spacing={3} className="mt-2 h-full">
        <Grid item xs={6}>
          <Chart
            dollars
            centerTitle
            noTitlePadding
            title="Projected Debts"
            data={debtProjection}
            height="322px"
            keys={["debt", "min"]}
            xKey="month"
            keyLabels={{
              debt: "Plan Followed",
              min: "Plan Not Followed",
            }}
            width="100%"
          />
        </Grid>

        <Grid
          item
          xs={6}
          style={{
            height: "100%",
            overflowY: "scroll",
          }}
        >
          {!!future.debts.length && (
            <OverviewTable
              title="Debts:"
              columns={years}
              rows={[
                {
                  header: "Total if plan followed",
                  columns: years.map((year: string, index: number) =>
                    formatWholeDollars(future.debts[index].plan.total)
                  ),
                  children: debtKeyList.map((key: string) => ({
                    header:
                      PROJECTION_DEBTS[key as keyof typeof PROJECTION_DEBTS],
                    columns: years.map((year, index: number) => {
                      const value = future.debts[index].plan[key];
                      if (value) {
                        return formatWholeDollars(value);
                      }
                      return "";
                    }),
                  })),
                },
                {
                  header: "Total if plan not followed",
                  columns: years.map((year: string, index: number) =>
                    formatWholeDollars(future.debts[index].min.total)
                  ),
                  children: debtKeyList.map((key: string) => ({
                    header:
                      PROJECTION_DEBTS[key as keyof typeof PROJECTION_DEBTS],
                    columns: years.map((year, index: number) => {
                      const value = future.debts[index].min[key];
                      if (value) {
                        return formatWholeDollars(value);
                      }
                      return "";
                    }),
                  })),
                },
              ]}
            />
          )}
          <Grid item xs={12}>
            {hasDebts && (
              <Paper className="px-4 py-5">
                <Box>
                  <Typography variant="h2" className={styles.title}>
                    Details
                  </Typography>
                </Box>

                <Box className="mt-2">
                  {retirementDate && (
                    <Typography style={{ fontSize: "13px" }}>
                      If you follow this plan, you will be debt-free by{" "}
                      {toMonthName(retirementMonth)} {retirementYear}.
                    </Typography>
                  )}
                </Box>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Grid>
    </CenterContainer>
  );
};

export default Debts;

const useStyles = makeStyles({
  actionBar: {
    background: "white",
    padding: "12px 68px",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    width: "100%",
  },
  actionButton: {
    width: 168,
  },
  cardRowCell: {
    fontSize: 12,
    width: "16%",
  },
  cardRowLabel: {
    fontSize: 12,
    paddingLeft: 10,
    width: "36%",
  },
  cardTableHeader: {},
  chart: {
    minWidth: 440,
    maxWidth: 524,
  },
  loaderContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 555,
    background: "rgba(255, 255, 255, .8)",
  },
  title: {
    fontSize: 14,
    color: colors.brandingBlue1,
    fontWeight: 600,
  },
  subheading: {
    fontSize: 13,
    color: colors.brandingBlue2,
    fontWeight: 600,
    marginTop: "10px",
  },
});
