import React, { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Finance } from "financejs";

import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import {
  getRefinanceApi,
  PayoffAccountsResponse,
  RefinanceRequest,
} from "src/apiService";
import Button from "src/components/Button";
import Chart from "src/components/Chart";
import { YesNo } from "src/interfaces";

import { getSpouseGI, getMyGI } from "src/store/cashflow/selector";
import colors from "src/theme/colors";
import {
  AdapterLink,
  DollarTextField,
  formatPercent,
  formatWholeDollars,
} from "src/utils";

const finance = new Finance();

const useStyles = makeStyles({
  borderCell: {
    borderBottom: `1px solid ${colors.brandingBlue1}`,
  },
  chartContainer: {},
  columnHeader: {},
  subHeader: {},
  positive: {
    color: colors.success,
  },
  negative: {
    color: colors.error,
  },
});

export const RefinancePayments = ({
  goBack,
  onNext,
  formValues,
  handleChange,
  loans,
  selectedLoans,
  setResults,
  results,
  isSpouse,
}: any) => {
  const styles = useStyles();
  const resultsRef = useRef<any>(null);
  const grossIncome = useSelector(isSpouse ? getSpouseGI : getMyGI);
  const originalPayments = useMemo(
    () =>
      loans.reduce(
        (result: number, loan: PayoffAccountsResponse) => result + loan.payment,
        0
      ),
    [loans]
  );

  const refiPayments = useMemo(() => {
    const refinancedPayments: number[] = [];
    const refinancedRates: number[] = [];
    let refinancedTotal = 0;
    let nonRefinanced = 0;
    loans.forEach((loan: PayoffAccountsResponse, index: number) => {
      let term = loan.term;
      let interest = loan.interest;
      if (selectedLoans.has(index)) {
        interest = formValues.refinanceRate;
        term = formValues.refinanceTerm;
      }
      const value = finance.PMT(interest / 100 / 12, term * 12, -loan.balance);
      if (selectedLoans.has(index)) {
        refinancedRates.push(formValues.refinanceRate);
        refinancedTotal += value;
      } else {
        refinancedRates.push(loan.interest);
        nonRefinanced += loan.payment;
      }
      refinancedPayments.push(value);
    });
    return {
      refinancedTotal,
      refinancedPayments,
      refinancedRates,
      nonRefinanced,
    };
  }, [formValues, loans]);

  const formattedResults = useMemo(() => {
    if (!results) {
      return [];
    }
    return results.no_refi.map((value: number, index: number) => ({
      month: index,
      no_refi: value,
      prepay: results.prepay[index],
    }));
  }, [results]);

  const submit = () => {
    const noRefinancingPrepayment = formValues.prepayCurrent;
    const refinancingMonthlyPayment = formValues.prepayRefinance;
    const balances: number[] = [];
    const interests: number[] = [];
    loans.forEach((loan: PayoffAccountsResponse) => {
      balances.push(loan.balance);
      interests.push(loan.interest);
    });
    const payload: RefinanceRequest = {
      no_graph: "n" as YesNo,
      noRefinancingPrepayment,
      refinancingMonthlyPayment,
      balances,
      interests: refiPayments.refinancedRates,
      payments: refiPayments.refinancedPayments,
      who: isSpouse ? "spouse" : "applicant",
    };
    return getRefinanceApi(payload).then((results) => {
      setResults(results);
      setTimeout(() => window.scrollTo(0, 660), 100);
    });
  };

  const monthlyPaymentChange =
    refiPayments.nonRefinanced +
    refiPayments.refinancedTotal -
    originalPayments;
  const monthlyPaymentChangeText =
    monthlyPaymentChange < 0
      ? `Decrease by ${formatWholeDollars(-monthlyPaymentChange)}`
      : `Increase by ${formatWholeDollars(monthlyPaymentChange)}`;
  const monthlyPaymentChangeClassName =
    monthlyPaymentChange < 0 ? styles.positive : styles.negative;
  const totalPaymentChange = !results
    ? 0
    : results.detail.refi_accu_payment - results.detail.no_refi_accu_payment;
  const totalPaymentChangeText =
    totalPaymentChange < 0
      ? `Decrease by ${formatWholeDollars(-totalPaymentChange)}`
      : `Increase by ${formatWholeDollars(totalPaymentChange)}`;
  const totalPaymentChangeClassName =
    totalPaymentChange < 0 ? styles.positive : styles.negative;
  const paymentTermChange = !results
    ? 0
    : results.detail.refi_years - results.detail.no_refi_years;
  const paymentTermChangeText =
    paymentTermChange < 0
      ? `Decrease by ${-paymentTermChange.toFixed(1)} years`
      : `Increase by ${paymentTermChange.toFixed(1)} years`;
  const paymentTermChangeClassName =
    paymentTermChange < 0 ? styles.positive : styles.negative;

  const refiPaymentTotal =
    formValues.prepayRefinance +
    refiPayments.nonRefinanced +
    refiPayments.refinancedTotal;
  const noRefiPaymentTotal = formValues.prepayCurrent + originalPayments;

  return (
    <>
      <Paper className="p-6" style={{ maxWidth: 900, margin: "0 auto" }}>
        <Typography variant="h2" component="h2">
          Compare Your Total Monthly Payments
        </Typography>
        <Typography variant="body1">
          Need to correct your existing loan details?{" "}
          <AdapterLink to="/accounts">Click here.</AdapterLink>
        </Typography>
        <TableContainer style={{ maxWidth: 1000 }}>
          <Table>
            <TableHead>
              <TableRow className="border-none">
                <TableCell className="border-none" />
                <TableCell
                  className="border-none"
                  style={{ wordBreak: "break-word" }}
                >
                  Refinance Offer
                </TableCell>
                <TableCell
                  className="border-none"
                  style={{ wordBreak: "break-word" }}
                >
                  Current Loans
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="border-none">
                <TableCell
                  className="border-none"
                  style={{ wordBreak: "break-word" }}
                >
                  Monthly Required Payment Refinanced Loans
                </TableCell>
                <TableCell className="border-none">
                  {formatWholeDollars(refiPayments.refinancedTotal)}
                </TableCell>
                <TableCell className="border-none">N/A</TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className="border-none"
                  style={{ wordBreak: "break-word" }}
                >
                  Non-Refinanced Loans Required Payment
                </TableCell>
                <TableCell className={styles.borderCell}>
                  {formatWholeDollars(refiPayments.nonRefinanced)}
                </TableCell>
                <TableCell className={styles.borderCell}>
                  {formatWholeDollars(originalPayments)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className="border-none"
                  style={{ wordBreak: "break-word" }}
                >
                  Required Monthly Payment
                </TableCell>
                <TableCell className="border-none">
                  {formatWholeDollars(
                    refiPayments.refinancedTotal + refiPayments.nonRefinanced
                  )}
                </TableCell>
                <TableCell className="border-none">
                  {formatWholeDollars(originalPayments)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className="border-none"
                  style={{ wordBreak: "break-word" }}
                >
                  Discretionary Prepayment
                </TableCell>
                <TableCell className={styles.borderCell}>
                  <DollarTextField
                    name="prepayRefinance"
                    variant="outlined"
                    value={formValues.prepayRefinance || ""}
                    onChange={handleChange}
                  />
                </TableCell>
                <TableCell className={styles.borderCell}>
                  <DollarTextField
                    name="prepayCurrent"
                    variant="outlined"
                    value={formValues.prepayCurrent || ""}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="border-none">
                  <p className="mb-1">Total Monthly Payment</p>
                  <Typography variant="caption">
                    Payment as a % of Gross Income
                  </Typography>
                </TableCell>
                <TableCell className="border-none">
                  <p className="mb-1">{formatWholeDollars(refiPaymentTotal)}</p>
                  <Typography variant="caption">
                    {formatPercent(
                      (refiPaymentTotal / (grossIncome / 12)) * 100
                    )}
                  </Typography>
                </TableCell>
                <TableCell className="border-none">
                  <p className="mb-1">
                    {formatWholeDollars(noRefiPaymentTotal)}
                  </p>
                  <Typography variant="caption">
                    {formatPercent(
                      (noRefiPaymentTotal / (grossIncome / 12)) * 100
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          className="my-4 mx-auto block"
          fbColor="primary"
          disabled={!formValues.refinanceRate}
          onClick={submit}
        >
          Calculate
        </Button>
      </Paper>
      {!!results && (
        <>
          <Box className="my-4" style={{ maxWidth: 900, margin: "0 auto" }}>
            <Chart
              ref={resultsRef}
              dollars
              title=""
              data={formattedResults}
              height="322px"
              keys={["prepay", "no_refi"]}
              xInterval={12}
              xKey="month"
              xMargin={60}
              yLabelMargin={70}
              yMargin={15}
              keyLabels={{
                prepay: "Refinance",
                no_refi: "Do Not Refinance",
              }}
              width="100%"
            />
          </Box>
          <Paper className="mt-5 p-4" style={{ maxWidth: 900, margin: "0 auto" }}>
            <Typography variant="h2" className={styles.subHeader}>
              Compared to your existing situation, if you refinanced your
              loan(s):
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="border-none" />
                    <TableCell className="border-none" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="border-none"
                      style={{ wordBreak: "break-word" }}
                    >
                      Your required monthly payment would
                    </TableCell>
                    <TableCell
                      className={monthlyPaymentChangeClassName + " border-none"}
                    >
                      {monthlyPaymentChangeText}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="border-none"
                      style={{ wordBreak: "break-word" }}
                    >
                      Your cumulative amount paid would
                    </TableCell>
                    <TableCell
                      className={totalPaymentChangeClassName + " border-none"}
                    >
                      {totalPaymentChangeText}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="border-none"
                      style={{ wordBreak: "break-word" }}
                    >
                      Your repayment period would
                    </TableCell>
                    <TableCell
                      className={paymentTermChangeClassName + " border-none"}
                    >
                      {paymentTermChangeText}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper className="mt-5 p-4" style={{ maxWidth: 900, margin: "0 auto" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="border-none" />
                    <TableCell className="border-none">
                      Refinance Offer
                    </TableCell>
                    <TableCell className="border-none">Current Loans</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="border-none"
                      style={{ wordBreak: "break-word" }}
                    >
                      Total Required Monthly Payment
                    </TableCell>
                    <TableCell className="border-none">
                      {formatWholeDollars(results.detail.refi_payment)}
                    </TableCell>
                    <TableCell className="border-none">
                      {formatWholeDollars(originalPayments)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="border-none"
                      style={{ wordBreak: "break-word" }}
                    >
                      Discretionary Prepayment
                    </TableCell>
                    <TableCell className={styles.borderCell}>
                      {formatWholeDollars(results.detail.refi_discretionary)}
                    </TableCell>
                    <TableCell className={styles.borderCell}>
                      {formatWholeDollars(results.detail.no_refi_discretionary)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="border-none"
                      style={{ wordBreak: "break-word" }}
                    >
                      Total Monthly Payment
                    </TableCell>
                    <TableCell className="border-none">
                      {formatWholeDollars(results.detail.refi_total_payment)}
                    </TableCell>
                    <TableCell className="border-none">
                      {formatWholeDollars(
                        originalPayments + results.detail.no_refi_discretionary
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="border-none"
                      style={{ wordBreak: "break-word" }}
                    >
                      Total Cumulative Payment Amount
                    </TableCell>
                    <TableCell className="border-none">
                      {formatWholeDollars(results.detail.refi_accu_payment)}
                    </TableCell>
                    <TableCell className="border-none">
                      {formatWholeDollars(results.detail.no_refi_accu_payment)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="border-none"
                      style={{ wordBreak: "break-word" }}
                    >
                      Repayment Period
                    </TableCell>
                    <TableCell className="border-none">
                      {results.detail.refi_years.toFixed(1)} Years
                    </TableCell>
                    <TableCell className="border-none">
                      {results.detail.no_refi_years.toFixed(1)} Years
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Box style={{ maxWidth: 900, margin: "0 auto" }}>
            <Typography variant="caption" component="p" className="mt-4">
              Disclaimer:
            </Typography>
            <Typography variant="caption" component="p">
              By refinancing your federal loans with private loans, you may lose
              certain benefits associated with federal student loans. Please
              consult with your financial advisor regarding which product is
              right for you.
            </Typography>
            <Typography variant="caption" component="p" className="mb-6">
              This calculator assumes fixed interest rate loans under a
              straight-line amortization schedule. It does not take into account
              graduated repayment loans or customized loan repayment schedules.
              If you have questions regarding your specific loans, request a
              call back on the last screen.
            </Typography>
            <Button
              fullWidth
              className="my-4"
              fbColor="primary"
              onClick={onNext}
            >
              Compare This to Other Refinance Offers
            </Button>
            <Button fbColor="secondary" onClick={goBack} fullWidth>
              Back
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default RefinancePayments;
