import * as React from "react";

import GeneralPage from "../GeneralPage";
import useData from "./data";

export const StudentLoans = () => {
  const data = useData();
  return <GeneralPage data={data} title="Student Loan Tools and Products" />;
};

export default StudentLoans;
