import produce from "immer";
import { filter, find } from "lodash";
import { handleActions } from "redux-actions";

import { Cashflow, CashflowState, FbAction } from "../../interfaces";
import { FAIL, START, SUCCESS } from "../common";
import { errorReducer, setLoading } from "../common/reducers";
import * as actions from "./actions";
import { LOG_OUT } from "../system/actions";

const initialState: CashflowState = {
  items: [],
  loaded: false,
  loading: false,
  error: null,
  taxes: 0,
};

const reducerDefinitions: any = {
  [actions.ADD_CASHFLOW_ITEM + START]: setLoading,
  [actions.EDIT_CASHFLOW_ITEM + START]: setLoading,
  [actions.REMOVE_CASHFLOW_ITEM + START]: setLoading,
  [actions.FETCH_CASHFLOW_ITEMS + START]: setLoading,
  [actions.FETCH_CASHFLOW_ITEMS + SUCCESS]: (
    state: CashflowState,
    { payload }: FbAction<Array<Cashflow>>
  ) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.loaded = true;
      draft.error = null;
      draft.items = payload;
    }),
  [actions.ADD_CASHFLOW_ITEM + FAIL]: errorReducer,
  [actions.EDIT_CASHFLOW_ITEM + FAIL]: errorReducer,
  [actions.REMOVE_CASHFLOW_ITEM + FAIL]: errorReducer,
  [actions.FETCH_CASHFLOW_ITEMS + FAIL]: errorReducer,
  [actions.ADD_DRAFT_CASHFLOW]: (
    state: CashflowState,
    { payload }: FbAction<Cashflow>
  ) =>
    produce(state, (draft) => {
      draft.items.push(payload);
    }),
  [actions.REMOVE_DRAFT_CASHFLOW]: (state: CashflowState) =>
    produce(state, (draft) => {
      draft.items = draft.items.filter((item) => item.id >= 0);
    }),

  [actions.TEMPORARY_EDIT_CASHFLOW_ITEM]: (
    state: CashflowState,
    { payload }: FbAction<actions.EditCashflowItemPayload>
  ) =>
    produce(state, (draft) => {
      const cashflow = find(
        draft.items,
        (item: Cashflow) => item.id === payload.id
      );
      if (cashflow) {
        cashflow.amount = payload.amount;
      }
    }),
  [actions.ADD_CASHFLOW_ITEM + SUCCESS]: (
    state: CashflowState,
    { payload }: FbAction<{ data: Cashflow; originalId: number }>
  ) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = null;
      if (payload.originalId && payload.originalId >= 0) {
        const existingCashflowIndex = draft.items.findIndex(
          (item) => item.id === payload.originalId
        );
        if (existingCashflowIndex >= 0) {
          draft.items[existingCashflowIndex] = payload.data;
        }
      } else {
        draft.items.push(payload.data);
      }
    }),
  [actions.EDIT_CASHFLOW_ITEM + SUCCESS]: (
    state: CashflowState,
    { payload }: FbAction<actions.EditCashflowItemPayload>
  ) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = null;
      const cashflow = find(
        draft.items,
        (item: Cashflow) => item.id === payload.id
      );
      if (cashflow) {
        cashflow.amount = payload.amount;
        if (payload.death_benefit) {
          cashflow.death_benefit = payload.death_benefit;
        }
      }
    }),
  [actions.REMOVE_CASHFLOW_ITEM + SUCCESS]: (
    state: CashflowState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = null;
      draft.items = filter(
        draft.items,
        (item: Cashflow) => item.id !== payload
      );
    }),
  [actions.FETCH_TAXES + SUCCESS]: (
    state: CashflowState,
    { payload }: FbAction<number>
  ) => ({ ...state, taxes: payload }),
  [LOG_OUT]: () => initialState,
};

const cashflowReducer = handleActions<CashflowState, any>(
  reducerDefinitions,
  initialState
);

export default cashflowReducer;
