import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getHomeResults } from "src/apiService";
import Button from "src/components/Button";
import StickyFooter from "src/components/StickyFooter";
import { getAccounts } from "src/store/account/selector";
import { getCashflows } from "src/store/cashflow/selector";
import CalculatorLayout from "src/layouts/CalculatorLayout";
import { disableSidebar, setSidebarContent } from "src/store/dashboard/actions";
import { APPLICANT_WHO_TYPES, DEBT_TYPES } from "src/interfaces";
import { AdapterLink } from "src/utils";

import { HOME_CASH_FLOW, SECTIONS, STUDENT_LOAN_TYPES } from "./common";
import { infoText } from "./SidebarContent/text";
import Landing from "./Landing";
import ConfirmIncome from "./Income";
import ConfirmDebts from "./Debt";
import ConfirmDownPayment from "./DownPayment";
import ConfirmSideHustle from "./SideIncome";
import Results from "./Results";

const HomeAffordability = () => {
  const dispatch = useDispatch();
  const accounts = useSelector(getAccounts);
  const [section, setSection] = useState(SECTIONS.LANDING);
  const income: HOME_CASH_FLOW[] = useSelector(getCashflows).income;
  const [homeIncomes, setHomeIncomes] = useState<HOME_CASH_FLOW[]>([]);
  const [homeDebts, setHomeDebts] = useState<any[]>([]);
  const [downPayment, setDownPayment] = useState(0);
  const [resultsIn, setResultsIn] = useState(false);
  const [results, setResults] = useState({
    optimal: {
      home_value: 0,
      housing_expense: 0,
      debts: [],
    },
    ok: {
      home_value: 0,
      housing_expense: 0,
      debts: [],
    },
    risky: {
      home_value: 0,
      housing_expense: 0,
      debts: [],
    },
  });

  useEffect(() => {
    dispatch(setSidebarContent(infoText[section]));
  }, [section]);

  useEffect(() => {
    let sum = 0;
    accounts.forEach((entry) => {
      if (
        entry.balance &&
        (entry.variable === "cash_value" ||
          entry.variable === "other_investments_value")
      ) {
        sum += entry.balance;
      }
    });
    sum = Math.round(sum / 1000) * 1000;
    setDownPayment(sum);
  }, [accounts]);

  useEffect(() => {
    if (homeIncomes.length < 1) {
      const incomes = income
        .filter((incomeItem) => !incomeItem.inschool)
        .map((incomeItem: any) => {
          const who = incomeItem.whose === "spouse" ? "spouse" : "applicant";
          return {
            ...incomeItem,
            who,
            whoLabel: APPLICANT_WHO_TYPES[who],
          };
        });
      setHomeIncomes(incomes);
    }
  }, [income]);

  useEffect(() => {
    if (homeDebts.length < 1) {
      // create an array of debts formatted for my needs
      const debts: any[] = [];
      const storedStudentLoans: any = {};
      accounts.forEach((entry) => {
        if (entry.balance && entry.variable && DEBT_TYPES[entry.variable]) {
          const debtObj = {
            ...entry,
            type: entry.variable,
            typeLabel: DEBT_TYPES[entry.variable],
            monthly: entry.payment,
            annual: entry.payment * 12,
            who: entry.who,
            whoLabel: APPLICANT_WHO_TYPES[entry.who],
          };
          // if the debt is a student loan type then try to aggregate it with a previous entry, if the "who" matches
          if (
            STUDENT_LOAN_TYPES[entry.variable] &&
            storedStudentLoans[entry.variable] &&
            storedStudentLoans[entry.variable].who === entry.who
          ) {
            const dIndex = storedStudentLoans[entry.variable].index;
            debts[dIndex].monthly += debtObj.monthly;
            debts[dIndex].annual = debts[dIndex].monthly * 12;
            // if no previous entry to the student loan then create a new reference and push debt to array
          } else if (STUDENT_LOAN_TYPES[entry.variable]) {
            debts.push(debtObj);
            storedStudentLoans[entry.variable] = {
              who: entry.who,
              index: debts.length - 1,
            };
          } else {
            debts.push(debtObj);
          }
        }
      });
      setHomeDebts(debts);
    }
  }, [accounts]);

  const handleTransition = (
    next = true,
    showResults?: boolean,
    incomeData?: HOME_CASH_FLOW[]
  ) => {
    dispatch(disableSidebar());
    const transitionCount = next ? 1 : -1;
    let nextSection = transitionCount + section;

    if (nextSection === SECTIONS.SIDEINCOME) {
      const sideIncomeCheck = incomeData || homeIncomes;
      const haveSideIncome = sideIncomeCheck.some(
        (entry) => entry.type === "side_hustle"
      );
      nextSection = haveSideIncome
        ? nextSection
        : nextSection + transitionCount;
    }
    if (showResults) {
      getResults();
    }
    if (nextSection <= SECTIONS.RESULTS && nextSection >= SECTIONS.LANDING) {
      setSection(nextSection);
    }
  };

  const getResults = () => {
    // filter out any "dropped" income items before formatting the rest of the data
    const incomes = homeIncomes
      .filter((entry) => !entry.drop)
      .map((entry) => ({
        type: entry.type,
        amount: entry.annual,
        who: entry.who,
      }));

    const debts = homeDebts.map((debtEntry: any) => ({
      type: debtEntry.type,
      payment: debtEntry.monthly,
      who: debtEntry.who,
    }));
    setResultsIn(false);
    getHomeResults({ incomes, debts, down: downPayment })
      .then((data) => {
        setResultsIn(true);
        restructureResults(data);
      })
      .catch(() => {
        setResultsIn(true);
        restructureResults();
      });
  };

  const restructureResults = (resData?: any) => {
    // if home_loan is 0 or less then mark category as unaffordable
    const results = { ...resData };
    if (resData) {
      for (const resultType in results) {
        const homeLoanIndex = results[resultType].debts.findIndex(
          (entry: any) => entry.type === "home_loan"
        );
        const homeLoan = results[resultType].debts[homeLoanIndex];
        if (
          homeLoanIndex > -1 &&
          homeLoan.payment > 0 &&
          results[resultType].home_value > 50000
        ) {
          const sortedDebts = [
            homeLoan,
            {
              type: "housing_expense",
              payment: results[resultType].housing_expense,
            },
            ...results[resultType].debts
              .filter((e: any, i: number) => i !== homeLoanIndex)
              .sort((a: any, b: any) => b.payment - a.payment),
          ];
          results[resultType].debts = sortedDebts;
        } else {
          results[resultType].unaffordable = true;
        }
      }
    } else {
      results.optimal = { unaffordable: true };
      results.ok = { unaffordable: true };
      results.risky = { unaffordable: true };
    }
    setResults(results);
  };

  const getContent = () => {
    switch (section) {
      case SECTIONS.INCOME:
        return (
          <ConfirmIncome
            transition={handleTransition}
            income={homeIncomes}
            setHomeIncome={setHomeIncomes}
          />
        );
      case SECTIONS.DEBT:
        return (
          <ConfirmDebts
            transition={handleTransition}
            debts={homeDebts}
            setHomeDebts={setHomeDebts}
          />
        );
      case SECTIONS.DOWNPAYMENT:
        return (
          <ConfirmDownPayment
            transition={handleTransition}
            downPaymentAmount={downPayment}
            setDownPaymentAmount={setDownPayment}
          />
        );
      case SECTIONS.RESULTS:
        return (
          <Results
            transition={handleTransition}
            resultsIn={resultsIn}
            data={results}
          />
        );
      case SECTIONS.SIDEINCOME:
        return (
          <ConfirmSideHustle
            incomes={homeIncomes}
            setIncomes={setHomeIncomes}
            transition={handleTransition}
          />
        );
      case SECTIONS.LANDING:
      default:
        return <Landing transition={handleTransition} />;
    }
  };

  return (
    <CalculatorLayout
      title="How Much Home Can I Afford"
      backTo="/homebuying"
      disclaimer={""}
      footer={
        section === SECTIONS.RESULTS ? (
          <StickyFooter text="Check purchase rates with FitBUX's mortgage lending partners.">
            <Button
              fbColor="accent"
              component={AdapterLink}
              to="/mortgage-rates"
              className="mr-10"
            >
              Check My Rates
            </Button>
          </StickyFooter>
        ) : null
      }
    >
      {getContent()}
    </CalculatorLayout>
  );
};

export default HomeAffordability;
