import produce from "immer";
import { handleActions } from "redux-actions";

import {
  DashboardState,
  FbAction,
  ScoreRanks,
  SidebarContent,
  TransactionSummary,
  WholePlanSummary,
} from "src/interfaces";
import { FAIL, START, SUCCESS } from "../common";
import { errorReducer, setLoading } from "../common/reducers";
import * as actions from "./actions";
import {
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
} from "../account/actions";
import {
  ADD_CASHFLOW_ITEM,
  EDIT_CASHFLOW_ITEM,
  REMOVE_CASHFLOW_ITEM,
} from "../cashflow/actions";
import {
  GET_SAVED_PLAN_PROJECTIONS,
  GET_LIVE_PLAN_PROJECTION,
  IMPLEMENT_PLAN,
  SAVE_PLAN,
} from "../planBuild/actions";
import {
  UPDATE_PERSONAL_INFO,
  UPDATE_HOUSEHOLD,
  UPDATE_EDUCATION,
  UPDATE_CAREER,
  UPDATE_OTHER_HUMAN_CAPITAL,
} from "../profileBuild/actions";
import {
  ADD_TRANSACTION,
  CONFIRM_TRANSACTIONS,
  UPDATE_TRANSACTION,
} from "../transaction/actions";
import { LOG_OUT } from "../system/actions";
import { HELP, TUTORIALS } from "./helpTopics";

const initialState: DashboardState = {
  actionItems: [],
  activePage: "management",
  loaded: false,
  loading: false,
  loadedTransactions: false,
  loadingTransactions: false,
  loadedSummaries: false,
  loadingSummaries: false,
  loadedUnconfirmedTransactions: false,
  loadingUnconfirmedTransactions: false,
  loadedMiscState: false,
  loadingMiscState: false,
  refreshNeeded: {
    summaries: false,
    liveProjection: false,
    savedProjections: false,
    score: false,
  },
  myIdrAccounts: [],
  spouseIdrAccounts: [],
  myIdrAssumptions: {
    earnings_growth: 0,
    tax_rate: 0,
    savings_rate: 0,
  },
  spouseIdrAssumptions: {
    earnings_growth: 0,
    tax_rate: 0,
    savings_rate: 0,
  },
  myIdrOverview: {
    result: {
      monthlyamount: 0,
      forgiven: 0,
      taxrate: 0,
      taxliability: 0,
      remainingterm: 0,
    },
    target: [],
    actual: [],
  },
  spouseIdrOverview: {
    result: {
      monthlyamount: 0,
      forgiven: 0,
      taxrate: 0,
      taxliability: 0,
      remainingterm: 0,
    },
    target: [],
    actual: [],
  },
  transactionError: null,
  message: "",
  messages: [],
  help: null,
  tutorial: null,
  video: null,
  popup: null,
  headerClicked: 0,
  popupActive: false,
  popupPlan: null,
  calls: "none",
  planNotifications: [],
  score: 0,
  hcScore: 0,
  scoreRanks: {
    applicant: {
      humanCapital: 0,
      riskToIncome: 0,
      education: 0,
      workExperience: 0,
      careerEnhancements: 0,
      extraCurricular: 0,
      financial: 0,
      leverage: 0,
      creditCard: 0,
      loanToValue: 0,
      emergencyFund: 0,
      discretionaryIncome: 0,
    },
    average: {
      humanCapital: 0,
      riskToIncome: 0,
      education: 0,
      workExperience: 0,
      careerEnhancements: 0,
      extraCurricular: 0,
      financial: 0,
      leverage: 0,
      creditCard: 0,
      loanToValue: 0,
      emergencyFund: 0,
      discretionaryIncome: 0,
    },
  },
  lifeInsuranceLiabilities: null,
  idealPrivateLoanRate: null,
  emergencyFundTarget: null,
  transactions: [],
  unconfirmedTransactions: [],
  marketingDeals: [],
  incomeSummary: {
    annual: [],
    monthly: [],
    current: [],
  },
  expenseSummary: {
    annual: [],
    monthly: [],
    current: [],
  },
  riskSummary: {
    annual: [],
    monthly: [],
    current: [],
  },
  studentIncomeSummary: [],
  studentExpenseSummary: [],
  studentRiskSummary: [],
  transactionSummary: null,
  wholePlanSummary: null,
  studentPlanSummary: null,
  studentEducationSummary: null,
  miscState: {},
  isSidebarEnabled: false,
  sidebarContent: null,
};

const reducerDefinitions: any = {
  [actions.SET_ACTIVE_PAGE]: (
    state: DashboardState,
    { payload }: FbAction<string>
  ) =>
    produce(state, (draft) => {
      draft.activePage = payload;
    }),
  [actions.LOAD_DASHBOARD_DATA + SUCCESS]: (
    state: DashboardState,
    { payload }: any
  ) => ({
    ...state,
    lifeInsuranceLiabilities: payload.lifeInsuranceLiabilities,
    emergencyFundTarget: payload.emergencyFundTarget,
    marketingDeals: payload.marketingDeals,
    loaded: true,
  }),
  [actions.GET_SCORE + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<{ score: number; hc: number }>
  ) =>
    produce(state, (draft) => {
      draft.score = payload.score;
      draft.hcScore = payload.hc;
      draft.refreshNeeded.score = false;
    }),
  [actions.GET_SCORE_RANKS + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<{ applicant: ScoreRanks; average: ScoreRanks }>
  ) =>
    produce(state, (draft) => {
      draft.scoreRanks = payload;
      if (!payload.average) {
        draft.scoreRanks.average = payload.applicant;
      }
    }),
  [actions.GET_SCORE_RANKS + FAIL]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.message = payload;
    }),
  [ADD_ACCOUNT + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.score = true;
      draft.refreshNeeded.savedProjections = true;
      draft.refreshNeeded.liveProjection = true;
    }),
  [UPDATE_ACCOUNT + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.score = true;
      draft.refreshNeeded.savedProjections = true;
      draft.refreshNeeded.liveProjection = true;
    }),
  [DELETE_ACCOUNT + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.score = true;
      draft.refreshNeeded.savedProjections = true;
      draft.refreshNeeded.liveProjection = true;
    }),
  [actions.GET_IDR_DATA + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.myIdrAccounts = payload.myAccounts.accounts || [];
      draft.myIdrAssumptions = payload.myAssumptions;
      draft.myIdrOverview = payload.myOverview;
      if (payload.spouseAssumptions) {
        draft.spouseIdrAccounts = payload.spouseAccounts.accounts || [];
        draft.spouseIdrAssumptions = payload.spouseAssumptions;
        draft.spouseIdrOverview = payload.spouseOverview;
      }
    }),
  [actions.SET_IDR_DETAILS + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<actions.IdrDetailsPayload>
  ) =>
    produce(state, (draft) => {
      const accounts =
        payload.who === "spouse"
          ? draft.spouseIdrAccounts
          : draft.myIdrAccounts;
      accounts.forEach((account) => {
        if (payload.accountIds.indexOf(account.id) >= 0) {
          account.selected = true;
        } else {
          account.selected = false;
        }
      });
      if (payload.who === "spouse") {
        draft.spouseIdrAssumptions = payload.assumptions;
      } else {
        draft.myIdrAssumptions = payload.assumptions;
      }
    }),
  [actions.GET_PLAN_NOTIFICATIONS + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any[]>
  ) =>
    produce(state, (draft) => {
      draft.planNotifications = payload;
    }),
  [actions.DISMISS_PLAN_NOTIFICATION + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.planNotifications = state.planNotifications.filter(
        (item: any) => item.id !== payload
      );
    }),
  [actions.DISMISS_ALL_PLAN_NOTIFICATIONS + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.planNotifications = [];
    }),
  [ADD_CASHFLOW_ITEM + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = true;
      draft.refreshNeeded.score = true;
      draft.refreshNeeded.liveProjection = true;
    }),
  [EDIT_CASHFLOW_ITEM + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = true;
      draft.refreshNeeded.score = true;
      draft.refreshNeeded.liveProjection = true;
    }),
  [REMOVE_CASHFLOW_ITEM + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = true;
      draft.refreshNeeded.score = true;
      draft.refreshNeeded.liveProjection = true;
    }),
  [actions.GET_MESSAGES + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any[]>
  ) =>
    produce(state, (draft) => {
      draft.messages = payload;
    }),
  [actions.SEND_MESSAGE + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<string>
  ) =>
    produce(state, (draft) => {
      draft.messages.push({
        body: payload,
        id: -999,
        direction: "user",
        date: "Now",
        new: false,
      });
    }),
  [actions.MARK_MESSAGE_READ + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.messages.forEach((message) => {
        if (message.id === payload) {
          message.new = false;
        }
      });
    }),
  [actions.GET_CALLS + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.calls = payload;
    }),
  [CONFIRM_TRANSACTIONS + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = true;
    }),
  [ADD_TRANSACTION + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = true;
    }),
  [UPDATE_TRANSACTION]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = true;
    }),
  [actions.GET_INCOME_AND_EXPENSE_SUMMARIES + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.incomeSummary = payload.incomeSummary;
      draft.expenseSummary = payload.expenseSummary;
      draft.riskSummary = payload.riskSummary;
      draft.loadedSummaries = true;
      draft.refreshNeeded.summaries = false;
    }),
  [actions.GET_STUDENT_INCOME_AND_EXPENSE_SUMMARIES + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.studentIncomeSummary = payload.incomeSummary;
      draft.studentExpenseSummary = payload.expenseSummary;
      draft.studentRiskSummary = payload.riskSummary;
    }),
  [actions.GET_INCOME_AND_EXPENSE_SUMMARIES + FAIL]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.summaries = false;
    }),
  [actions.LOAD_PLAN_SUMMARY + SUCCESS]: (
    state: DashboardState,
    {
      payload,
    }: FbAction<{
      plan: WholePlanSummary;
      transactions: TransactionSummary;
    }>
  ) =>
    produce(state, (draft) => {
      draft.wholePlanSummary = payload.plan;
      draft.transactionSummary = payload.transactions;
    }),
  [actions.LOAD_STUDENT_PLAN_SUMMARY + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.studentPlanSummary = payload.transactionSummary;
      draft.studentEducationSummary = payload.educationSummary;
    }),
  [GET_LIVE_PLAN_PROJECTION + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.liveProjection = false;
    }),
  [GET_LIVE_PLAN_PROJECTION + FAIL]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.liveProjection = false;
    }),
  [GET_SAVED_PLAN_PROJECTIONS + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.savedProjections = false;
    }),
  [GET_SAVED_PLAN_PROJECTIONS + FAIL]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.savedProjections = false;
    }),
  [actions.GET_MISC_STATE + START]: (state: DashboardState) => ({
    ...state,
    loadingMiscState: true,
  }),
  [actions.GET_MISC_STATE + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) => ({
    ...state,
    loadingMiscState: false,
    loadedMiscState: true,
    miscState: payload,
  }),
  [actions.GET_MISC_STATE + FAIL]: (state: DashboardState) => ({
    ...state,
    loadingMiscState: false,
  }),
  [actions.SET_MISC_STATE + START]: (state: DashboardState) => ({
    ...state,
    loadingMiscState: true,
  }),
  [actions.SET_MISC_STATE + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any>
  ) => ({
    ...state,
    loadingMiscState: false,
    miscState: payload,
  }),
  [actions.SET_MISC_STATE + FAIL]: (state: DashboardState) => ({
    ...state,
    loadingMiscState: false,
  }),
  [actions.SET_HELP]: (
    state: DashboardState,
    { payload }: FbAction<string>
  ) => ({
    ...state,
    help: HELP[payload] || HELP.PLACEHOLDER,
  }),
  [actions.SET_TUTORIAL]: (
    state: DashboardState,
    { payload }: FbAction<string>
  ) => ({
    ...state,
    tutorial: TUTORIALS[payload] || TUTORIALS.PLACEHOLDER,
  }),
  [actions.SET_VIDEO]: (
    state: DashboardState,
    { payload }: FbAction<string>
  ) => ({
    ...state,
    video: payload || "PLACEHOLDER",
  }),
  [actions.CLEAR_HELP]: (state: DashboardState) => ({
    ...state,
    help: null,
  }),
  [actions.CLEAR_TUTORIAL]: (state: DashboardState) => ({
    ...state,
    tutorial: null,
  }),
  [actions.CLEAR_VIDEO]: (state: DashboardState) => ({
    ...state,
    video: null,
  }),
  [actions.SET_POPUP]: (
    state: DashboardState,
    { payload }: FbAction<string>
  ) => ({
    ...state,
    popup: payload || null,
  }),
  [actions.CLEAR_POPUP]: (state: DashboardState) => ({
    ...state,
    popupActive: false,
  }),
  [actions.SET_POPUP_ACTIVE]: (
    state: DashboardState,
    { payload }: FbAction<boolean>
  ) => ({
    ...state,
    popupActive: payload || false,
  }),
  [actions.SET_POPUP_PLAN]: (
    state: DashboardState,
    { payload }: FbAction<number>
  ) => ({
    ...state,
    popupPlan: payload || null,
  }),
  [actions.SET_HEADER_CLICKED]: (state: DashboardState) => ({
    ...state,
    headerClicked: state.headerClicked + 1,
  }),
  [actions.SET_REFRESH_NEEDED]: (
    state: DashboardState,
    {
      payload,
    }: FbAction<{
      summaries: boolean;
      projection: boolean;
      score: boolean;
    }>
  ) => ({
    ...state,
    refreshNeeded: { ...state.refreshNeeded, ...payload },
  }),
  [actions.ENABLE_SIDEBAR]: (state: DashboardState) => ({
    ...state,
    isSidebarEnabled: true,
  }),
  [actions.DISABLE_SIDEBAR]: (state: DashboardState) => ({
    ...state,
    isSidebarEnabled: false,
    sidebarContent: null,
  }),
  [actions.TOGGLE_SIDEBAR]: (state: DashboardState) => ({
    ...state,
    isSidebarEnabled: !state.isSidebarEnabled,
  }),
  [actions.SET_SIDEBAR_CONTENT]: (
    state: DashboardState,
    { payload }: FbAction<SidebarContent[]>
  ) => ({
    ...state,
    sidebarContent: payload,
  }),
  [actions.FETCH_ACTION_ITEMS + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<any[]>
  ) => ({
    ...state,
    actionItems: payload.map((item) => item.type),
  }),
  [actions.HIDE_ACTION_ITEM + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<string>
  ) => ({
    ...state,
    actionItems: state.actionItems.filter((item) => item !== payload),
  }),
  [actions.CLEAR_ACTION_ITEM + SUCCESS]: (
    state: DashboardState,
    { payload }: FbAction<string>
  ) => ({
    ...state,
    actionItems: state.actionItems.filter((item) => item !== payload),
  }),
  [IMPLEMENT_PLAN + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.liveProjection = true;
      draft.refreshNeeded.savedProjections = true;
      draft.refreshNeeded.summaries = true;
      draft.refreshNeeded.score = true;
    }),
  [SAVE_PLAN + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.liveProjection = true;
      draft.refreshNeeded.savedProjections = true;
      draft.refreshNeeded.summaries = true;
      draft.refreshNeeded.score = true;
    }),
  [UPDATE_PERSONAL_INFO + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.liveProjection = true;
      draft.refreshNeeded.savedProjections = true;
    }),
  [UPDATE_EDUCATION + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.liveProjection = true;
      draft.refreshNeeded.savedProjections = true;
    }),
  [UPDATE_CAREER + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.liveProjection = true;
      draft.refreshNeeded.savedProjections = true;
    }),
  [UPDATE_OTHER_HUMAN_CAPITAL + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.liveProjection = true;
      draft.refreshNeeded.savedProjections = true;
    }),
  [UPDATE_HOUSEHOLD + SUCCESS]: (state: DashboardState) =>
    produce(state, (draft) => {
      draft.refreshNeeded.liveProjection = true;
      draft.refreshNeeded.savedProjections = true;
    }),
  [LOG_OUT]: () => initialState,
};

[actions.LOAD_DASHBOARD_DATA, actions.GET_SCORE].forEach((actionType) => {
  reducerDefinitions[actionType + START] = setLoading;
  reducerDefinitions[actionType + FAIL] = errorReducer;
});

const dashboardReducer = handleActions<DashboardState, any>(
  reducerDefinitions,
  initialState
);

export default dashboardReducer;
