import React from "react";

import { Typography } from "@material-ui/core";

import { ACCOUNT_TYPES } from "src/interfaces/common";
import { AdapterLink } from "src/utils";

export const PLAN_NOTIFICATION_MESSAGES = [
  {
    id: 0,
    message: () => "",
  },
  {
    id: 1,
    message: ({ month }: any) => (
      <>
        <Typography paragraph>
          {month}: Your actual income is less than your projected income. You
          either didn't make as much as projected or you haven't updated your
          account.
        </Typography>
        <Typography paragraph>
          If you didn't make as much as projected, we recommend building a new
          plan to reflect your current income.
        </Typography>
        <Typography paragraph>
          If you haven't updated your account, take the following steps:
        </Typography>
        <ol>
          <li>
            <AdapterLink to="/budget">
              Click here to view your income last month.
            </AdapterLink>
          </li>
          <li>
            <AdapterLink to="/transactions">
              Click here to go to the transactions page.
            </AdapterLink>{" "}
            Enter your gross income by confirming your transactions or manually
            entering your gross income by adding a transaction.
          </li>
        </ol>
      </>
    ),
  },
  {
    id: 2,
    message: ({ month }: any) => (
      <Typography>
        {month}: Congratulations, you are currently meeting/exceeding your Plan
        goals and FitBUX Score projections. Keep up the good work.
      </Typography>
    ),
  },
  {
    id: 3,
    message: ({ month }: any) => (
      <>
        <Typography paragraph>
          {month}: It looks like you are not meeting your goals because you are
          overspending on your budget.{" "}
          <AdapterLink to="/budget">
            Click here to review your budget so you can get back on track.
          </AdapterLink>
        </Typography>
        <Typography>
          If you can not follow your plan because your day-to-day expenses are
          higher than you thought, we recommend creating a new plan.
        </Typography>
      </>
    ),
  },
  {
    id: 4,
    message: ({ month }: any) => (
      <>
        <Typography>
          {month}: It looks like you are not updating your transactions.{" "}
          <AdapterLink to="/transactions">
            Click here to confirm and/or enter your transactions.
          </AdapterLink>
        </Typography>
      </>
    ),
  },
  {
    id: 5,
    message: ({ month }: any) => (
      <>
        <Typography paragraph>
          {month}: Your current FitBUX Score is less than your projected Score
          because your income hasn't grown as much as projected or you haven't
          entered all of your gross income.
        </Typography>
        <Typography>
          You may continue with your current plan, enter more gross income, or
          message your FitBUX Coach and see if you should adjust your current
          plan.
        </Typography>
      </>
    ),
  },
  {
    id: 6,
    message: ({ month }: any) => (
      <>
        <Typography paragraph>
          {month}: Your current FitBUX Score is less than your projected Score
          because you have withdrawn investments. Therefore, your assets are
          currently lower than projected.
        </Typography>
        <Typography>
          If you still have these assets and simply moved them, please link the
          new account or manually update the balances on your existing accounts.
        </Typography>
      </>
    ),
  },
  {
    id: 7,
    message: ({ month, asset_type }: any) => (
      <>
        <Typography paragraph>
          {month}: You have been following your plan but your{" "}
          {ACCOUNT_TYPES[asset_type]} growth has not meet expectations.
        </Typography>
        <Typography paragraph>
          If you have not updated the value of your {ACCOUNT_TYPES[asset_type]}{" "}
          please do so by manually editing the balances of your manually entered
          accounts.
        </Typography>
        <Typography paragraph>
          If your accounts are linked and not updating please let us know.
        </Typography>
        <Typography>
          If the values are updated and your assets still are not worth as much
          as projected, then this is out of your control. Therefore, either
          continue with your plan or contact your FitBUX Coach to see if you
          should change your plan in any way.
        </Typography>
      </>
    ),
  },
  {
    id: 8,
    message: ({ month, amount, asset_type }: any) => (
      <>
        <Typography paragraph>
          {month}: You have not saved enough assets according to your plan. To
          get caught up you'll need to allocate {amount} to your{" "}
          {ACCOUNT_TYPES[asset_type]} account.
        </Typography>
        <Typography paragraph>
          If you have made a contribution to this account but you don't think
          it's reflected, either:
        </Typography>
        <ul>
          <li>
            <AdapterLink to="/transactions">
              manually enter a transaction
            </AdapterLink>
            , or
          </li>
          <li>
            <AdapterLink to="/accounts">
              link your financial account
            </AdapterLink>{" "}
            if your institution allows you to link.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 9,
    message: ({ month, amount, debt_type }: any) => (
      <>
        <Typography paragraph>
          {month}: You have not prepaid your {ACCOUNT_TYPES[debt_type]}{" "}
          according to your plan. Therefore, you are not hitting your target to
          repay your loans. To get caught up you'll need to allocate {amount} to
          your {ACCOUNT_TYPES[debt_type]} account.
        </Typography>
        <Typography paragraph>
          If you have made a prepayment to this account but you don't think it's
          reflected, either:
        </Typography>
        <ul>
          <li>
            <AdapterLink to="/transactions">
              manually enter a transaction
            </AdapterLink>
            , or
          </li>
          <li>
            <AdapterLink to="/accounts">
              link your financial account
            </AdapterLink>{" "}
            if your institution allows you to link.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 10,
    message: ({ month, debt_type }: any) => (
      <>
        <Typography paragraph>
          {month}: The balance of your {ACCOUNT_TYPES[debt_type]} debt is
          greater than your plan projections. Either:
        </Typography>
        <ul>
          <li>You aren't making prepayment correctly, or</li>
          <li>You account balances aren't updated in your profile.</li>
        </ul>
        <Typography paragraph>
          <AdapterLink to="/accounts">
            Click here to update the balance
          </AdapterLink>{" "}
          if you are manually updating the account.
        </Typography>
        <Typography>
          If your debt is linked and the balance or your making prepayments but
          the balance isn't decreasing as fast as projected, please notify your
          FitBUX Coach.
        </Typography>
      </>
    ),
  },
  {
    id: 11,
    message: ({ month }: any) => (
      <>
        <Typography paragraph>
          {month}: Your actual income is less than your projected income. You
          either didn't make as much as projected or you haven't updated your
          account.
        </Typography>
        <Typography paragraph>
          If you didn't make as much as projected, we recommend building a new
          plan to reflect your current income.
        </Typography>
        <Typography paragraph>
          If you haven't updated your account, take the following steps:
        </Typography>
        <ol>
          <li>
            <AdapterLink to="/budget">
              Click here to view your income last month.
            </AdapterLink>
          </li>
          <li>
            <AdapterLink to="/transactions">
              Click here to go to the transactions page.
            </AdapterLink>{" "}
            Enter your gross income by confirming your transactions or manually
            entering your gross income by adding a transaction.
          </li>
        </ol>
      </>
    ),
  },
  {
    id: 12,
    message: ({ month }: any) => (
      <>
        <Typography paragraph>
          {month}: Your actual income is less than your projected income. You
          either didn't make as much as projected or you haven't updated your
          account.
        </Typography>
        <Typography paragraph>
          If you didn't make as much as projected, we recommend building a new
          plan to reflect your current income.
        </Typography>
        <Typography paragraph>
          If you haven't updated your account, take the following steps:
        </Typography>
        <ol>
          <li>
            <AdapterLink to="/budget">
              Click here to view your income last month.
            </AdapterLink>
          </li>
          <li>
            <AdapterLink to="/transactions">
              Click here to go to the transactions page.
            </AdapterLink>{" "}
            Enter your gross income by confirming your transactions or manually
            entering your gross income by adding a transaction.
          </li>
        </ol>
      </>
    ),
  },
  {
    id: 13,
    message: () => "",
  },
  {
    id: 14,
    message: () => "",
  },
  {
    id: 15,
    message: () => "",
  },
  {
    id: 16,
    message: () => "",
  },
  {
    id: 17,
    message: () => "",
  },
  {
    id: 18,
    message: () => "",
  },
  {
    id: 19,
    message: () => "",
  },
  {
    id: 20,
    message: () => "",
  },
  {
    id: 21,
    message: () => (
      <Typography>
        Make sure to enter your transactions to keep your plan accurate. You can
        easily do so by linking your financial accounts to your FitBUX profile
        (recommended) or manually from the transaction screen.
      </Typography>
    ),
  },
  {
    id: 22,
    message: () => (
      <Typography>
        So far fo this year, your actual Education costs are over your budget.
        You will either have to take out more in student loans or cut day-to-day
        expenses.
      </Typography>
    ),
  },
  {
    id: 23,
    message: () => (
      <>
        <Typography paragraph>
          So far fo this year, it looks like you have contributed less to assets
          than budgeted. Either reduce your budgeted amount by editing your plan
          or make a contribution to your assets.
        </Typography>
        <Typography>
          If this value is inacurate on your Plan Summary page, be sure to link
          your financial accounts (recommended), or manually update this value.
        </Typography>
      </>
    ),
  },
  {
    id: 24,
    message: () => (
      <>
        <Typography paragraph>
          So far this year, it looks you have paid less towards reducing your
          debts than planned. You should make extra payments towards your loans
          to get caught up.
        </Typography>
        <Typography>
          If the value FitBUX is showing is not accurate, be sure to either link
          your financial accounts (recommended) or go to the transactions page
          and enter your transactions manually.
        </Typography>
      </>
    ),
  },
  {
    id: 25,
    message: () => (
      <Typography>
        So far fo this year, your actual Education costs are above budger. You
        will either have to take out more in student loans and/or potentially
        cut your premium payments/day-to-day expenses.
      </Typography>
    ),
  },
  {
    id: 26,
    message: () => (
      <Typography>
        Congrats! You are following your financial plan perfectly. Keep up the
        good work.
      </Typography>
    ),
  },
];
