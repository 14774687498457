import React from "react";

import {
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";

import Icon from "src/components/Icon";
import { navigatorStyles } from "src/theme";
import { AdapterLink } from "src/utils";

interface ExpandableNavMenuItemProps {
  title: string;
  items: any[];
  icon: string;
  expanded: boolean;
  onClick?: VoidFunction;
  onItemClick: any;
  activeId: string;
  barCollapsed: boolean;
}

const useStyles = makeStyles(navigatorStyles);

const ExpandableNavMenuItem = (props: ExpandableNavMenuItemProps) => {
  const classes = useStyles();
  const {
    items,
    icon,
    title,
    expanded,
    onClick,
    onItemClick,
    activeId,
    barCollapsed,
  } = props;

  return (
    <>
      <ListSubheader
        className={clsx(
          classes.headerItem,
          classes.item,
          classes.itemActionable,
          classes.itemActiveItem
        )}
        onClick={onClick}
      >
        <Icon iconName={icon} className="mr-2" />
        {!barCollapsed && title}
        {!barCollapsed && (
          <Icon
            iconName={expanded ? "fb-chevron-down" : "fb-chevron-right"}
            fontSize="small"
            style={{ position: "absolute", right: 8 }}
          />
        )}
      </ListSubheader>
      {!barCollapsed &&
        expanded &&
        items.map(({ title: itemTitle, id, href }) => (
          <ListItem
            button
            dense
            className={clsx(
              classes.indented,
              classes.item,
              classes.itemActionable,
              id === activeId && classes.itemActiveItem
            )}
            key={id !== null ? id : itemTitle}
            onClick={() => onItemClick(id)}
            component={AdapterLink}
            to={href}
          >
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
                dense: classes.textDense,
              }}
            >
              {itemTitle}
            </ListItemText>
          </ListItem>
        ))}
    </>
  );
};

export default ExpandableNavMenuItem;
