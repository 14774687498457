import React from "react";
import { range } from "lodash";
import {
  Box,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { DAYS_IN_MONTH, MONTHS, STATES } from "src/constants";
import { PhoneNumberInput } from "src/utils";

const nowYear = new Date().getFullYear();
const startYear = nowYear - 70;
const endYear = nowYear - 16;

export const PersonalInfo = ({
  next,
  goBack,
  formValues,
  handleChange,
  errors,
}: any) => {
  let ready = true;
  let notReadyText = "";

  if (
    errors.has("state") ||
    errors.has("address1") ||
    errors.has("city") ||
    errors.has("zip") ||
    errors.has("phone")
  ) {
    ready = false;
    notReadyText = "Please ensure all fields are entered correctly.";
  }

  return (
    <Card title="Confirm Your Personal Info">
      <Box className="flex justify-end">
        <Box className="w-1/2 flex flex-col">
          <FormLabel className="mb-2 mt-4">Address Line 1</FormLabel>
          <TextField
            error={errors.has("street_address")}
            helperText={
              errors.has("street_address") ? "Please enter an address." : ""
            }
            variant="outlined"
            onChange={handleChange}
            name="street_address"
            value={formValues.street_address}
            fullWidth
          />
          <FormLabel className="mb-2 mt-4">Address Line 2</FormLabel>
          <TextField
            variant="outlined"
            onChange={handleChange}
            name="address2"
            value={formValues.address2}
            fullWidth
          />
          <FormLabel className="mb-2 mt-4">City</FormLabel>
          <TextField
            error={errors.has("city")}
            helperText={errors.has("city") ? "Please enter a city." : ""}
            variant="outlined"
            onChange={handleChange}
            name="city"
            value={formValues.city}
            fullWidth
          />
          <FormLabel className="mb-2 mt-4">State</FormLabel>
          <Select
            error={errors.has("state")}
            variant="outlined"
            name="state"
            value={formValues.state}
            onChange={handleChange}
          >
            {STATES.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
          <FormLabel className="mb-2 mt-4">ZIP Code</FormLabel>
          <TextField
            error={errors.has("zip")}
            helperText={
              errors.has("zip") ? "Please enter a valid ZIP code." : ""
            }
            variant="outlined"
            onChange={handleChange}
            name="zip"
            value={formValues.zip}
            fullWidth
          />
          <FormLabel className="mb-2 mt-4">Phone Number</FormLabel>
          <PhoneNumberInput
            error={errors.has("phone_number")}
            helperText={
              errors.has("phone_number")
                ? "Please enter a valid phone number."
                : ""
            }
            variant="outlined"
            name="phone_number"
            value={formValues.phone_number}
            onChange={handleChange}
          />
          <FormLabel className="mb-2 mt-4">Date of Birth</FormLabel>
          <Box className="flex">
            <Select
              variant="outlined"
              style={{ width: "150px" }}
              name="dob_month"
              onChange={handleChange}
              value={formValues.dob_month}
              className="mr-4"
            >
              {MONTHS.map((month: string, index: number) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {month}
                </MenuItem>
              ))}
            </Select>
            <Select
              variant="outlined"
              style={{ width: "150px" }}
              name="dob_day"
              onChange={handleChange}
              value={formValues.dob_day}
              className="mr-4"
            >
              {range(1, DAYS_IN_MONTH[formValues.dob_month] + 1).map((day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </Select>
            <Select
              variant="outlined"
              style={{ width: "150px" }}
              name="dob_year"
              onChange={handleChange}
              value={formValues.dob_year}
            >
              {range(startYear, endYear + 1).map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="flex mt-4 justify-center">
            <Button className="mr-4" onClick={goBack}>
              Back
            </Button>
            <Tooltip
              disableFocusListener={ready}
              disableHoverListener={ready}
              disableTouchListener={ready}
              title={notReadyText}
            >
              <span>
                <Button disabled={!ready} fbColor="primary" onClick={next}>
                  Next
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default PersonalInfo;
