import React from "react";

import { Box, makeStyles, Paper, Tooltip } from "@material-ui/core";
import colors from "src/theme/colors";

interface ITabs {
  onChange: (value: number) => void;
  disabled?: boolean;
  value: number;
  list: Array<string>;
  tooltip?: React.ReactNode;
}

const SimpleTabs = ({ value, list, onChange, disabled, tooltip }: ITabs) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip || ""} placement="top">
      <Paper className={classes.root} style={{ width: list.length * 120 }}>
        {list.map((item, index) => (
          <Box
            key={item}
            onClick={disabled ? () => null : () => onChange(index)}
            className={`${classes.tab} ${disabled && classes.disabled} ${
              value === index && classes.selected
            } ${index < list.length - 1 && classes.withDivider}`}
          >
            {item}
          </Box>
        ))}
      </Paper>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    margin: "0 auto",
    height: 42,
    padding: 4,
  },
  tab: {
    borderRadius: 5,
    cursor: "pointer",
    fontWeight: 600,
    textAlign: "center",
    lineHeight: "32px",
    width: 200,
  },
  selected: {
    background: colors.gradBlueBubbleAngle,
    color: "#fff",
  },
  disabled: {},
  indicator: {
    // background: "transparent",
    // position: "relative",
    maxWidth: 160,
    minWidth: 0,
    transform: "translateX(8px)",
  },
  withDivider: {
    borderRight: `1px solid ${colors.blueGray9}`,
  },
});

export default SimpleTabs;
