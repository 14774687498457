import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import Button from "src/components/Button";
import CustomDialog from "src/components/Dialogs/CustomDialog";
import Icon from "src/components/Icon";
import SimpleTabs from "src/components/SimpleTabs";
import ReviewTransactionsDialog from "src/pages/Dashboard/MyProfile/Transactions/Dialogs/ReviewTransactionsDialog";
import {
  ACCOUNT_TYPES,
  // EDUCATION_EXPENSE_TYPES,
  // ALL_EXPENSE_TYPES,
  // ALL_RISK_MANAGEMENT_TYPES,
} from "src/interfaces";
import { AdapterLink } from "src/utils";
import colors from "src/theme/colors";
import {
  formatPercent,
  formatWholeDollars,
  formatExecutionPercent,
} from "src/utils";
import Card from "src/components/Card";
import { getAccounts, getDebtObligations } from "src/store/account/selector";
import {
  getExecutionSummary,
  getExpenseSummary,
  getIncomeSummary,
  getRefreshNeeded,
  getRiskSummary,
} from "src/store/dashboard/selector";
import {
  getIncomeAndExpenseSummaries,
  loadPlanSummary,
} from "src/store/dashboard/actions";
import { fetchLivePlanProjection } from "src/store/planBuild/actions";
import {
  getDashboardAllocations,
  getLiveProjection,
  getLoadedSavedProjections,
  getLoadingSavedProjections,
  getPlans,
  getSavedProjectionsError,
  livePlanAllocationTotals,
  livePlanExpenseTotal,
  livePlanIncomeTotal,
  livePlanRiskManagementTotal,
} from "src/store/planBuild/selector";
import { openPlanForEditing } from "src/store/planBuild/util";

const useStyles = makeStyles({
  emphasis: {
    color: colors.brandingBlue1,
    fontWeight: 600,
    fontSize: 12,
    borderBottom: 0,
    borderTop: `1px solid ${colors.blueGray6}`,
  },
  indent: {
    paddingLeft: 30,
  },
  table: {
    width: "100%",
    borderBottom: `1px solid ${colors.blueGray6}`,
  },
  tableBorder: {
    padding: "0px 15px",
    width: "100%",
  },
  row: {
    height: "37px",
    fontSize: 12,
    borderBottom: 0,
  },
  dynamicRow: {
    fontSize: 12,
    borderBottom: 0,
    height: "37px",
    "& > *": {
      alignItems: "center",
    },
  },
  rowHeaderBox: {
    display: "flex",
    flexDirection: "row" as const,
    position: "relative" as const,
    height: "24.5px",
    "& svg": {
      position: "absolute",
      fontSize: "12px",
      left: 0,
      top: 6,
    },
    paddingLeft: 15,
  },
  dynamicRowHeader: {
    borderBottom: 0,
    fontSize: 12,
    margin: "auto 0",
  },
  columnHeaders: {
    fontSize: 12,
    textAlign: "center" as const,
  },
  lastColumn: {
    borderLeft: "1px solid lightgrey",
  },
  footer: {
    paddingTop: "20px",
  },
  zero: {
    height: 0,
    padding: 0,
    margin: 0,
    borderBottom: 0,
  },
  footRow: {
    fontSize: 12,
  },
  tabs: {
    margin: "20px auto",
  },
});

const nowYear = new Date(
  new Date().valueOf() - new Date().getTimezoneOffset() * 60000
).getFullYear();

const messageContent = {
  income: {
    body: [
      "To compare your actual total income to the amount budgeted in your plan, visit your Day-to-Day Money page.",
      "If income transactions are missing, make sure to confirm any outstanding transaction and/or add transactions manually.",
    ],
    buttonLabel: "Go to Day-to-Day Money",
    target: "/budget",
  },
  expenses: {
    body: [
      "To compare your actual expenses to the amount budgeted in your plan, visit your Day-to-Day Money page.",
      "If you are missing expenses, make sure your linked accounts are up-to-date or add an expense manually.",
    ],
    buttonLabel: "Go to Day-to-Day Money",
    target: "/budget",
  },
  premiums: {
    body: [
      "To compare your risk premium expenses to the amount budgeted in your plan, visit your Risk Management page.",
      "If you are missing risk premium expenses, make sure your linked accounts are up-to-date or add a risk premium expense manually.",
    ],
    buttonLabel: "Go to Risk Management",
    target: "/risk",
  },
};

const PlanDetails = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const income = useSelector(livePlanIncomeTotal);
  const expenses = useSelector(livePlanExpenseTotal);
  const risks = useSelector(livePlanRiskManagementTotal);
  const executionSummary: any = useSelector(getExecutionSummary);
  const incomeSummary = useSelector(getIncomeSummary);
  const expenseSummary = useSelector(getExpenseSummary);
  const riskSummary = useSelector(getRiskSummary);
  const liveProjection = useSelector(getLiveProjection);
  const plans = useSelector(getPlans);
  const {
    assetsTotal: liveAssetsTotal,
    debtsTotal: liveDebtsTotal,
  } = useSelector(livePlanAllocationTotals);
  const { assets, debts } = useSelector(getDashboardAllocations);
  const debtObligations: any = useSelector(getDebtObligations);
  const accounts = useSelector(getAccounts);
  const [expanded, setExpanded] = useState<Array<number>>([]);
  const [showTransactionsDialog, setShowTransactionsDialog] = useState(false);
  const [transactionQuery, setTransactionQuery] = useState({});
  const [cashMagnifyingMessage, setCashMagnifyingMessage] = useState(false);
  const [currentTab, setTab] = useState(0);
  const livePlan = plans.find((plan) => plan.implemented);
  const loadedProjections = useSelector(getLoadedSavedProjections);
  const loadingProjections = useSelector(getLoadingSavedProjections);
  const projectionsError = useSelector(getSavedProjectionsError);
  const refreshNeeded = useSelector(getRefreshNeeded);
  // const [showUnplannedRows, setShowUnplannedRows] = useState<any>({
  //   education: false,
  //   expenses: false,
  //   assets: false,
  //   debts: false,
  //   risks: false,
  // });
  const [messageDialogContent, setDialogContent] = useState<any>(null);
  const planStart = liveProjection?.start || `${nowYear}-01`;
  const timeframes = ["current", "month" /*, "month_avg"*/, "annual"];
  const timeframe = timeframes[currentTab];

  const startDate = new Date(planStart).toLocaleDateString([], {
    month: "short",
    year: "numeric",
  });

  const load = () => {
    dispatch(getIncomeAndExpenseSummaries());
    dispatch(loadPlanSummary());
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (!loadingProjections) {
      if (
        (!loadedProjections && !projectionsError) ||
        refreshNeeded.liveProjection
      ) {
        dispatch(fetchLivePlanProjection(false));
      }
    }
  }, [loadedProjections, loadingProjections, projectionsError]);

  const getSummaryRisk = () => {
    const riskKey = timeframe === "month" ? "monthly" : timeframe;
    let timeFrameRisk = 0;
    if (riskKey) {
      const riskItem = riskSummary[riskKey as keyof typeof riskSummary];
      for (const item in riskItem) {
        timeFrameRisk += riskItem[item];
      }
    }
    return timeFrameRisk;
  };

  const getWholeDollars = (num: number) => {
    if (num <= 0) {
      return formatWholeDollars(0);
    } else {
      return formatWholeDollars(num);
    }
  };

  const assetKeyList = Array.from(
    executionSummary.assetKeys as Set<string>
  ).filter((key: string) => key !== "total" && key !== "waterfall");
  const debtKeyList = Array.from(
    executionSummary.debtKeys as Set<string>
  ).filter(
    (key: string) => key !== "total" && key !== "waterfall" && key !== "cash"
  );

  const nonZeroPlannedDebts = [...debtKeyList].filter(
    (key) =>
      !!accounts.find((account) => {
        const accountType = account.variable || account.type || "";
        if (key === "student_loan") {
          return (
            ["fed_loan", "perkins_loan", "priv_loan"].indexOf(accountType) >=
              0 && !!account.balance
          );
        }
        return key === accountType && !!account.balance;
      })
  );

  const openCashDialog = () => {
    setTransactionQuery({});
    setShowTransactionsDialog(true);
    setCashMagnifyingMessage(true);
  };

  const openTransactionsDialog = (category: string, key: string) => {
    if (key === "cash_value") {
      openCashDialog();
      return;
    }
    const query: any = {
      category,
      type: key,
    };
    if (timeframe === "month") {
      query.period = "monthly";
    } else {
      query.period = timeframe;
    }
    setShowTransactionsDialog(true);
    setTransactionQuery(query);
    setCashMagnifyingMessage(false);
  };

  const closeTransactionsDialog = () => {
    setShowTransactionsDialog(false);
    setTransactionQuery({});
  };

  const getMagnifier = (
    income: boolean,
    actualOrCash: boolean,
    key: string
  ) => {
    // We treat cash value special so that even if there is no cash contribution we still show that magnifying glass
    // if (actualOrCash) {
    return (
      <IconButton
        color="primary"
        onClick={() => openTransactionsDialog("contribution", key)}
        style={{ height: 0, marginLeft: -26, marginRight: 3, width: 0 }}
      >
        <Icon iconName="fa-pencil" />
      </IconButton>
    );
    // } else {
    //   return <div />;
    // }
  };

  const getPlannedValue = (plannedValue: number) => {
    if (timeframe === "annual") {
      return plannedValue;
    } else {
      return plannedValue / 12;
    }
  };

  const getSummaryRows = React.useMemo(() => {
    let loading = false;
    let rows: any[] = [];
    try {
      const executionIncome = executionSummary.income.total[timeframe].actual;
      rows = [
        {
          header: "Assets",
          columns: [
            formatExecutionPercent(
              executionSummary.asset.total[timeframe].actual,
              executionIncome
            ),
            formatPercent((liveAssetsTotal / income) * 100),
          ],
          children: assetKeyList.map((key: string) => {
            return {
              header: ACCOUNT_TYPES[key as keyof typeof ACCOUNT_TYPES],
              actualDollars: executionSummary.asset[key][timeframe].actual,
              plannedDollars: getPlannedValue(assets[key]),
              actualPercent: formatExecutionPercent(
                executionSummary.asset[key][timeframe].actual,
                executionIncome
              ),
              node: getMagnifier(
                executionIncome,
                executionSummary.asset[key][timeframe].actual ||
                  key === "cash_value",
                key
              ),
            };
          }),
        },
        {
          header: "Debts",
          columns: [
            formatExecutionPercent(
              executionSummary.debt.total[timeframe].actual,
              executionIncome
            ),
            formatPercent((liveDebtsTotal / income) * 100),
          ],
          children: debtKeyList.map((key: string) => {
            let value = debts[key] || 0;
            if (key === "student_loan") {
              value =
                Math.max(
                  debts.fed_loan || 0,
                  (debtObligations.fed_loan || 0) * 12
                ) +
                Math.max(
                  debts.priv_loan || 0,
                  (debtObligations.priv_loan || 0) * 12
                ) +
                Math.max(
                  debts.perkins_loan || 0,
                  (debtObligations.perkins_loan || 0) * 12
                );
            } else {
              value = Math.max(debts[key], (debtObligations[key] || 0) * 12);
            }
            if (nonZeroPlannedDebts.indexOf(key) < 0) {
              value = 0;
            }
            return {
              header: ACCOUNT_TYPES[key as keyof typeof ACCOUNT_TYPES],
              actualDollars: executionSummary.debt[key][timeframe].actual,
              plannedDollars: getPlannedValue(value),
              actualPercent: formatExecutionPercent(
                executionSummary.debt[key][timeframe].actual,
                executionIncome
              ),
              node: getMagnifier(
                executionIncome,
                executionSummary.debt[key][timeframe].actual,
                key
              ),
            };
          }),
        },
      ];
      // }
    } catch (summaryError) {
      loading = true;
      rows = [];
    }
    return { loading, rows };
  }, [
    executionSummary,
    timeframe,
    assets,
    debts,
    liveAssetsTotal,
    liveDebtsTotal,
  ]);

  const summary = useMemo(() => {
    const key = timeframe === "month" ? "monthly" : timeframe;
    let income = 0;
    let expense = 0;
    if (key) {
      const incomeItem = incomeSummary[key as keyof typeof incomeSummary];
      for (const item in incomeItem) {
        income += incomeItem[item];
      }
      const expenseItem = expenseSummary[key as keyof typeof expenseSummary];
      for (const item in expenseItem) {
        expense += expenseItem[item];
      }
    }
    return { income, expense };
  }, [timeframe, incomeSummary, expenseSummary]);

  const isExpanded = (i: number) => {
    return expanded.indexOf(i) > -1;
  };

  const handleToggle = (i: number) => {
    const index = expanded.indexOf(i);
    if (index > -1) {
      setExpanded(expanded.filter((j) => j !== i));
      expanded.splice(index, 1);
    } else {
      setExpanded([...expanded, i]);
    }
  };

  const PlanTableRow = ({
    rowName,
    actualDollars,
    actualPercent,
    plannedDollars,
    plannedPercent,
    noData,
    style,
    clickEvent,
    titleClassName,
  }: {
    rowName: any;
    actualDollars?: any;
    actualPercent?: any;
    plannedDollars?: number;
    plannedPercent?: number | string;
    noData?: boolean;
    style?: string;
    titleClassName?: string;
    clickEvent?: VoidFunction;
  }) => (
    <TableRow
      onClick={() => {
        if (clickEvent) {
          clickEvent();
        }
      }}
    >
      <TableCell className={`${style} ${titleClassName}`}>{rowName}</TableCell>
      <TableCell className={`${style} text-center`}>
        {!noData && (
          <Box className="flex">
            <Box className="w-1/2">{actualDollars || 0}</Box>
            <Box className="w-1/2 text-center">{actualPercent || 0}</Box>
          </Box>
        )}
      </TableCell>
      <TableCell className={`${style} ${styles.lastColumn}`}>
        {!noData && (
          <Box className="flex">
            <Box className="w-1/2 text-center">
              {getWholeDollars(plannedDollars || 0)}
            </Box>
            <Box className="w-1/2 text-center">{plannedPercent || 0}</Box>
          </Box>
        )}
      </TableCell>
    </TableRow>
  );

  const renderSpecialEditIcon = (category: keyof typeof messageContent) => (
    <IconButton
      color="primary"
      onClick={() => setDialogContent(messageContent[category])}
      style={{ height: 0, marginLeft: -26, marginRight: 3, width: 0 }}
    >
      <Icon iconName="fa-pencil" />
    </IconButton>
  );

  const summaryRows = getSummaryRows.rows;

  return (
    <>
      <Box className={styles.tabs}>
        <SimpleTabs
          list={[
            "This Month",
            "Last Month",
            // "Monthly Average",
            "Last 12 Months",
          ]}
          value={currentTab}
          onChange={setTab}
        />
      </Box>
      <Card
        title="My Financial Plan Execution Summary"
        noPadding
        className={styles.tableBorder}
        titleButton={
          <Button
            fbColor="primary"
            className="ml-4"
            onClick={() => openPlanForEditing(dispatch, false, livePlan)}
          >
            Edit My Plan
          </Button>
        }
      >
        <Table size="small" className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={styles.columnHeaders}>Actual</TableCell>
              <TableCell className={styles.columnHeaders}>Planned</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <PlanTableRow
              rowName="Day-to-Day Money"
              noData
              style={styles.emphasis}
            />
            <PlanTableRow
              rowName="Total Income"
              actualDollars={
                <Box className="flex items-center justify-center">
                  {renderSpecialEditIcon("income")}
                  {getWholeDollars(summary.income)}
                </Box>
              }
              actualPercent={"% Income"}
              plannedDollars={getPlannedValue(income)}
              plannedPercent={"% Income"}
              titleClassName={styles.indent}
              style={styles.row}
            />
            <PlanTableRow
              rowName="Day-to-Day Expenses"
              actualDollars={
                <Box className="flex items-center justify-center">
                  {renderSpecialEditIcon("expenses")}
                  {getWholeDollars(summary.expense)}
                </Box>
              }
              actualPercent={formatExecutionPercent(
                summary.expense,
                summary.income
              )}
              plannedDollars={getPlannedValue(expenses)}
              plannedPercent={formatExecutionPercent(expenses, income)}
              titleClassName={styles.indent}
              style={styles.row}
            />

            {/*<PlanTableRow
              rowName="Remaining Cash"
              actualDollars={getWholeDollars(summary.income - summary.expense)}
              actualPercent={formatExecutionPercent(
                summary.income - summary.expense,
                summary.income
              )}
              plannedDollars={getPlannedValue(income - expenses)}
              plannedPercent={formatExecutionPercent(income - expenses, income)}
              style={styles.row}
            />*/}
            <PlanTableRow
              rowName="Money For Future Goals"
              noData
              style={styles.emphasis}
            />
            {["asset", "debt"].map((row: any, index: number) => {
              const rowName =
                row === "asset" ? "Asset Contributions" : "Debt Payments";
              const rowHead = (
                <Box className={styles.rowHeaderBox}>
                  <Icon
                    iconName={
                      isExpanded(index) ? "fb-chevron-down" : "fb-chevron-right"
                    }
                    style={{ cursor: "pointer" }}
                  ></Icon>{" "}
                  <span className={styles.dynamicRowHeader}>{rowName}</span>
                </Box>
              );
              const plannedDollars = getPlannedValue(
                row === "asset" ? liveAssetsTotal : liveDebtsTotal
              );
              return (
                <>
                  <PlanTableRow
                    key={row}
                    rowName={rowHead}
                    actualDollars={getWholeDollars(
                      executionSummary[row].total[timeframe].actual
                    )}
                    actualPercent={summaryRows[index].columns[0]}
                    plannedDollars={plannedDollars}
                    plannedPercent={summaryRows[index].columns[1]}
                    style={styles.dynamicRowHeader}
                    clickEvent={() => handleToggle(index)}
                  />

                  {/* Child Rows */}
                  {isExpanded(index) && (
                    <>
                      <TableRow className={styles.zero}>
                        <TableCell className={styles.zero} />
                        <TableCell className={styles.zero} />
                        <TableCell
                          className={`${styles.lastColumn} ${styles.zero}`}
                        />
                      </TableRow>
                      {summaryRows[index].children.map((childRow: any) => {
                        if (
                          !childRow.plannedDollars &&
                          !childRow.actualDollars
                        ) {
                          return null;
                        }
                        const dollarColumn = (
                          <Box className="flex items-center justify-center">
                            {childRow.node}
                            {getWholeDollars(childRow.actualDollars)}
                          </Box>
                        );
                        return (
                          <>
                            <PlanTableRow
                              key={childRow.header}
                              rowName={childRow.header}
                              actualDollars={dollarColumn}
                              actualPercent={childRow.actualPercent}
                              plannedDollars={childRow.plannedDollars}
                              plannedPercent={formatPercent(
                                (childRow.plannedDollars /
                                  getPlannedValue(income)) *
                                  100
                              )}
                              style={styles.dynamicRow}
                              titleClassName={styles.indent}
                            />
                          </>
                        );
                      })}
                      <TableRow className={styles.zero}>
                        <TableCell className={styles.zero} />
                        <TableCell className={styles.zero} />
                        <TableCell
                          className={`${styles.lastColumn} ${styles.zero}`}
                        />
                      </TableRow>
                    </>
                  )}
                </>
              );
            })}

            <PlanTableRow
              rowName="Risk Management"
              noData
              style={styles.emphasis}
            />
            <PlanTableRow
              rowName="Premium Payments"
              actualDollars={
                <Box className="flex items-center justify-center">
                  {renderSpecialEditIcon("premiums")}
                  {getWholeDollars(getSummaryRisk())}
                </Box>
              }
              actualPercent={formatExecutionPercent(
                getSummaryRisk(),
                summary.income
              )}
              plannedDollars={getPlannedValue(risks)}
              plannedPercent={formatExecutionPercent(risks, income)}
              style={styles.row}
              titleClassName={styles.indent}
            />
          </TableBody>
        </Table>
        <Divider />
        <Box className={styles.footer}>
          <Typography paragraph className={styles.footRow}>
            Reflects amounts since your plan was implemented in {startDate}.
          </Typography>
          {summary.income <= 0 && (
            <Typography paragraph className={styles.footRow}>
              Your actual total income is needed to compute the figures in the
              "Actual" column. To update your income,{" "}
              <AdapterLink to={"/transactions"}>click here</AdapterLink>
            </Typography>
          )}
        </Box>
      </Card>
      <ReviewTransactionsDialog
        onAdd={load}
        onClose={closeTransactionsDialog}
        open={showTransactionsDialog}
        query={transactionQuery}
        cashMessage={cashMagnifyingMessage}
        onRemove={load}
      />
      <CustomDialog
        isOpen={!!messageDialogContent}
        onClose={() => setDialogContent(null)}
        title="Review/Edit Transactions"
        size="sm"
      >
        {!!messageDialogContent && (
          <>
            {messageDialogContent.body.map((text: string) => (
              <Typography paragraph key={text}>
                {text}
              </Typography>
            ))}
            <Box className="flex justify-center">
              <Button
                fbColor="primary"
                component={AdapterLink}
                to={messageDialogContent.target}
              >
                {messageDialogContent.buttonLabel}
              </Button>
            </Box>
          </>
        )}
      </CustomDialog>
    </>
  );
};

export default PlanDetails;
