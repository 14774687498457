import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, makeStyles } from "@material-ui/core";

import CalculatorLayout from "src/layouts/CalculatorLayout";
import { setMiscState } from "src/store/dashboard/actions";
import { getMiscState } from "src/store/dashboard/selector";
import { getIsMarried } from "src/store/system/selector";
import Instructions from "./Instructions";
import Introduction from "./Introduction";
import ReturningUser from "./ReturningUser";
import UserOrSpouse from "./UserOrSpouse";
import ServicerLogin from "./ServicerLogin";
import PrecisionPay from "./PrecisionPay";

const useStyles = makeStyles({
  container: {
    margin: "20px auto",
    width: "90%",
    maxWidth: 1100,
  },
  loading: {
    margin: "120px auto",
    width: 0,
  },
});

const PAY_LOANS_DISCLAIMER =
  "Disclaimer: This service is provided by Spinwheel Inc, a FitBUX Partner. If you believe that a payment was not applied as expected, you will need to contact your lender or loan servicer first, and a FitBUX coach will be available to join a conference call if needed. FitBUX cannot be held responsible for mistakes made by your lender or loan servicer.";

enum STEPS {
  INTRODUCTION,
  INSTRUCTIONS,
  RETURNING_USER,
  USER_OR_SPOUSE,
  SPOUSE_DECISION,
  SERVICER_LOGIN,
  PRECISION_PAY,
}

const PayMyLoans = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const isMarried = useSelector(getIsMarried);
  const miscState = useSelector(getMiscState);
  const [step, setStep] = useState(STEPS.INTRODUCTION);
  const [who, setWho] = useState("applicant");
  const [linkOrPay, setLinkOrPay] = useState("link");

  useEffect(() => {
    if (miscState.applicant?.spinwheel_student_loan) {
      setStep(STEPS.RETURNING_USER);
    }
  }, []);

  useEffect(() => {
    return () => {
      setStep(STEPS.INTRODUCTION);
      setWho("applicant");
      setLinkOrPay("link");
    };
  }, []);

  const renderStep = () => {
    switch (step) {
      case STEPS.INTRODUCTION:
        return <Introduction next={() => setStep(STEPS.INSTRUCTIONS)} />;
      case STEPS.INSTRUCTIONS:
        return (
          <Instructions
            next={() => {
              if (isMarried) {
                setStep(STEPS.USER_OR_SPOUSE);
              } else {
                setStep(STEPS.SERVICER_LOGIN);
              }
            }}
          />
        );
      case STEPS.RETURNING_USER:
        return (
          <ReturningUser
            next={(link: string) => {
              setLinkOrPay(link);
              if (isMarried) {
                setStep(STEPS.USER_OR_SPOUSE);
              } else if (link === "link") {
                setStep(STEPS.SERVICER_LOGIN);
              } else {
                setStep(STEPS.PRECISION_PAY);
              }
            }}
          />
        );
      case STEPS.USER_OR_SPOUSE:
        return (
          <UserOrSpouse
            next={(whoNext: string) => {
              setWho(whoNext);
              if (
                whoNext === "spouse" &&
                !miscState?.applicant?.spinwheel_student_loan &&
                miscState.spouse?.spinwheel_student_loan
              ) {
                setStep(STEPS.SPOUSE_DECISION);
              } else if (linkOrPay === "link") {
                setStep(STEPS.SERVICER_LOGIN);
              } else {
                setStep(STEPS.PRECISION_PAY);
              }
            }}
          />
        );
      case STEPS.SPOUSE_DECISION:
        return (
          <ReturningUser
            next={(link: string) => {
              setLinkOrPay(link);
              if (link === "link") {
                setStep(STEPS.SERVICER_LOGIN);
              } else {
                setStep(STEPS.PRECISION_PAY);
              }
            }}
          />
        );
      case STEPS.SERVICER_LOGIN:
        return (
          <ServicerLogin
            next={() => {
              dispatch(
                setMiscState({
                  ...miscState,
                  [who]: {
                    ...(miscState[who] || {}),
                    spinwheel_student_loan: true,
                  },
                })
              );
              setStep(STEPS.PRECISION_PAY);
            }}
            who={who}
          />
        );
      case STEPS.PRECISION_PAY:
        return (
          <PrecisionPay
            returnToConnect={() => setStep(STEPS.SERVICER_LOGIN)}
            who={who}
          />
        );
    }
  };

  return (
    <CalculatorLayout
      title="Pay My Student Loans"
      backTo="/studentloans"
      disclaimer={PAY_LOANS_DISCLAIMER}
    >
      <Box className={styles.container}>{renderStep()}</Box>
    </CalculatorLayout>
  );
};

export default PayMyLoans;
