import React from "react";
import styled from "@emotion/styled";

interface ProgressBarProps {
  fillPercentage: number;
}

const ProgressBarContainer = styled.div`
  margin-top: 4.2rem;
  background-color: #e9edf3;
  height: 8px;
  width: 100%;
  position: fixed;
  overflow: hidden;
  z-index: 1;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8px;
    background-color: #ffffff;
  }

  &::before {
    left: calc(33.33% - 4px);
  }

  &::after {
    right: calc(33.33% - 4px);
  }
`;

const Filler = styled.div`
  background-color: #00bfb3;
  height: 100%;
  transition: width 0.2s ease-in;
`;

function ProgressBar({ fillPercentage }: ProgressBarProps) {
  return (
    <ProgressBarContainer>
      <Filler style={{ width: `${fillPercentage}%` }} />
    </ProgressBarContainer>
  );
}

export default ProgressBar;
