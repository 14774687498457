import * as React from "react";
import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Icon from "src/components/Icon";
import { colors, investmentsStyles } from "src/theme";

const useStyles = makeStyles({
  ...investmentsStyles,
  greenCheck: {
    color: colors.success,
  },
  tableCell: {
    textAlign: "center",
  },
  wrappedCell: {
    wordBreak: "break-word" as any,
    textAlign: "center",
  },
});

export const OpenAccountIntro = ({ next, goBack }: any) => {
  const classes = useStyles();

  const CheckIcon = () => (
    <Icon iconName="fb-checkmark" className={classes.greenCheck} />
  );
  return (
    <>
      <Paper square className="pb-4">
        <Box className="px-8 pt-8">
          <Typography paragraph>
            We provide optimized investment asset allocation recommendations
            using our one-of-a-kind technology that looks at the "entire you",
            meaning it takes into account traditional financial assets and
            debts, your human capital asset, and your behavior.
          </Typography>
          <Typography paragraph>
            Depending on your goals and willingness to take risk, FitBUX
            customizes your investment allocation and adjusts it automatically
            over time based on your FitBUX Score.
          </Typography>
        </Box>
        <Box
          className={classes.mainContainer}
          style={{ marginTop: 0, marginBottom: 10 }}
        >
          <Typography variant="h2" component="h2" className="text-center">
            Investment Advice Comparison
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.tableCell}
                  style={{ width: 400 }}
                />
                <TableCell className={classes.tableCell}>FitBUX</TableCell>
                <TableCell className={classes.wrappedCell}>
                  Other Investment Advisors
                </TableCell>
                <TableCell className={classes.tableCell}>
                  Do It Yourself
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.wrappedCell}>
                  Traditional Allocation Recommendation
                </TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}>
                  <CheckIcon />
                </TableCell>
                <TableCell className={classes.tableCell} />
              </TableRow>
              <TableRow>
                <TableCell className={classes.wrappedCell}>
                  Enhanced Allocation Recommendation Inclusive Of Your Human
                  Capital &amp; Behavior Analytics
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <CheckIcon />
                </TableCell>
                <TableCell className={classes.tableCell} />
                <TableCell className={classes.tableCell} />
              </TableRow>
              <TableRow>
                <TableCell className={classes.wrappedCell}>
                  Easy to Use Proprietary Technology to Manage Your Investments
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <CheckIcon />
                </TableCell>
                <TableCell className={classes.tableCell} />
                <TableCell className={classes.tableCell} />
              </TableRow>
              <TableRow>
                <TableCell className={classes.wrappedCell}>
                  Updated Allocations Based on Your FitBUX Score
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <CheckIcon />
                </TableCell>
                <TableCell className={classes.tableCell} />
                <TableCell className={classes.tableCell} />
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell}>Costs</TableCell>
                <TableCell className={classes.tableCell}>0.50%</TableCell>
                <TableCell className={classes.tableCell}>
                  1.0% &ndash; 1.5%
                </TableCell>
                <TableCell className={classes.tableCell}>Free</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Paper>
      <Box className="flex mt-4 justify-center">
        <Button className="mr-4" onClick={goBack}>
          Back
        </Button>
        <Button fbColor="primary" onClick={next}>
          Open Investment Account
        </Button>
      </Box>
    </>
  );
};

export default OpenAccountIntro;
