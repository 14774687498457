import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Grid } from "@material-ui/core";

import { Header } from "./Blocks/Header";
import { Cards } from "./Blocks/Cards";
import Solution from "./Solution";
import Interstitial from "./Interstitial";
import MoneySchool, { ContentItem } from "./MoneySchool";
import { detailData } from "./data";
import { listCoursesApi } from "src/apiService";
import { getRouterState } from "src/store/router/selector";

export const Content = () => {
  const [solutionName, setSolution] = useState("");
  const [moneySchoolContent, setMoneySchoolContent] = useState<ContentItem[]>(
    []
  );
  const router = useSelector(getRouterState);

  useEffect(() => {
    listCoursesApi()
      .then((receivedContent) => setMoneySchoolContent(receivedContent || []))
      .catch(() => setMoneySchoolContent([]));
  }, []);

  useEffect(() => {
    if (router.location.query?.solution) {
      setSolution(router.location.query.solution);
    }
  }, [router.location.query]);

  const solution = solutionName
    ? detailData[solutionName as keyof typeof detailData]
    : undefined;
  const clearSolution = () => setSolution("");

  if (solution) {
    switch (solutionName) {
      case "moneyschool":
        return (
          <MoneySchool goBack={clearSolution} content={moneySchoolContent} />
        );
      case "policygeniuslife":
      case "policygeniusdisability":
        return <Interstitial solution={solution} returnTo="/insurance" />;
      case "policygeniushome":
        return (
          <Interstitial
            solution={solution}
            returnTo={
              router.location.query?.return === "insurance"
                ? "/insurance"
                : "/homebuying"
            }
          />
        );
      default:
        return <Solution data={solution} />;
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid item xs={12}>
        <Cards
          setSolution={setSolution}
          enableMoneySchool={!!moneySchoolContent.length}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <Intro
          heading={heading}
          description={description}
          buttons={buttons}
          img={img}
          specialHeading={specialHeading}
        />
      </Grid> */}
    </Grid>
  );
};
