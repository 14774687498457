import React from "react";
import { Box, FormLabel, TextField, Tooltip } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";

export const ContactInfo = ({
  next,
  goBack,
  formValues,
  handleChange,
  errors,
}: any) => {
  const ready =
    !errors.has("email") &&
    !errors.has("first_name") &&
    !errors.has("last_name");

  return (
    <Card title="Confirm Your Name and Contact Info">
      <Box className="flex justify-end">
        <Box className="w-1/2" />
        <Box className="w-1/2 flex flex-col">
          <FormLabel className="mb-2 mt-4">Email</FormLabel>
          <TextField
            error={errors.has("email")}
            helperText={
              errors.has("email") ? "Please enter a valid email address." : ""
            }
            variant="outlined"
            onChange={handleChange}
            name="email"
            value={formValues.email}
            fullWidth
          />
          <FormLabel className="mb-2 mt-4">First Name</FormLabel>
          <TextField
            error={errors.has("first_name")}
            helperText={
              errors.has("first_name") ? "Please enter your first name." : ""
            }
            variant="outlined"
            onChange={handleChange}
            name="first_name"
            value={formValues.first_name}
            fullWidth
          />
          <FormLabel className="mb-2 mt-4">MI</FormLabel>
          <TextField
            variant="outlined"
            onChange={handleChange}
            name="mi"
            value={formValues.mi}
            fullWidth
          />
          <FormLabel className="mb-2 mt-4">Last Name</FormLabel>
          <TextField
            error={errors.has("last_name")}
            helperText={
              errors.has("last_name") ? "Please enter your last name." : ""
            }
            variant="outlined"
            onChange={handleChange}
            name="last_name"
            value={formValues.last_name}
            fullWidth
          />
          <Box className="flex mt-4 justify-center">
            <Button className="mr-4" onClick={goBack}>
              Back
            </Button>
            <Tooltip
              disableFocusListener={ready}
              disableHoverListener={ready}
              disableTouchListener={ready}
              title={ready ? "" : "Please complete the required form fields."}
            >
              <span>
                <Button disabled={!ready} fbColor="primary" onClick={next}>
                  Next
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ContactInfo;
