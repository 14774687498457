import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Formik, FormikProps } from "formik";
import { Redirect } from "react-router";

import { forgotPasswordApi } from "src/apiService";
import { isLoggedInSelector } from "src/store/system/selector";
import { validateEmail } from "src/utils";

import {
  EuiTitle,
  EuiText,
  EuiFieldText,
  EuiButton,
  EuiImage,
  EuiShowFor,
  EuiFormRow,
  EuiLink,
} from "@elastic/eui";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import styled from "@emotion/styled";
import login_image from "src/assets/svgs/login_landing.svg";
import mobile_login_image from "src/assets/svgs/mobile_login.svg";
import success_check from "src/assets/svgs/check_success.svg";
import {
  StyledSpacer,
  StyledSpan,
} from "src/components/Global/StyledComponents";
import FormError from "src/components/Global/FormError";

const LoginContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  height: 100vh;
  padding: 0;
`;

interface StyledEuiLinkProps {
  to: string;
  children: React.ReactNode;
  [key: string]: any;
}

interface MyFormValues {
  email: string;
}

const StyledEuiLink = ({ to, children, ...rest }: StyledEuiLinkProps) => (
  <Link to={to}>
    <EuiLink {...rest} style={{ textDecoration: "underline" }}>
      {children}
    </EuiLink>
  </Link>
);

const ForgotPassword = () => {
  const loggedIn = useSelector(isLoggedInSelector);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");

  if (loggedIn) {
    return <Redirect to="/plan-summary" />;
  }

  const onFormikSubmit = async (
    values: MyFormValues
    // { setSubmitting }: FormikActions<MyFormValues>
  ) => {
    setEmail(values.email);
    if (!!values.email && !emailError && !loading && !success) {
      setLoading(true);
      forgotPasswordApi(values.email)
        .then(() => {
          setLoading(false);
          setSuccess(true);
        })
        .catch(() => {
          setLoading(false);
          setSuccess(false);
        });
    }
  };

  return (
    <LoginContainer fluid className="login-main">
      <div className="half info-container">
        <div className="info-content">
          <EuiImage
            src={login_image}
            alt="work life balance image"
            size="original"
          />
        </div>
      </div>
      <div className="half field-container">
        <div className="field-content">
          <EuiShowFor sizes={["xs", "s", "m"]}>
            <EuiImage
              src={mobile_login_image}
              alt="work life balance image"
              size="original"
            />
          </EuiShowFor>

          {!!emailError && !loading && (
            <>
              <FormError type="text">
                Please enter a valid email address
              </FormError>
              <StyledSpacer size="32px" />
            </>
          )}

          {!!success && !loading ? (
            <>
              <EuiImage
                src={success_check}
                alt="Success check mark"
                size="original"
              />

              <StyledSpacer size="32px" />

              <EuiTitle size="l">
                <h1>Email sent</h1>
              </EuiTitle>

              <StyledSpacer size="32px" />

              <EuiText>
                <p className="success-text">
                  We sent an email to <b>{email}</b> with a link to reset your
                  password.
                </p>
              </EuiText>

              <StyledSpacer size="32px" />

              <EuiText size="m" className="success-text">
                <p>
                  Can’t find the email?&nbsp;
                  <EuiLink
                    color="text"
                    onClick={() => {
                      setSuccess(false);
                      setEmail("");
                    }}
                  >
                    Try another email address.
                  </EuiLink>
                </p>
              </EuiText>
            </>
          ) : (
            <Formik
              initialValues={{
                email: "",
              }}
              onSubmit={onFormikSubmit}
              render={(formikBag: FormikProps<MyFormValues>) => (
                <form noValidate onSubmit={formikBag.handleSubmit}>
                  <EuiShowFor sizes={["xs", "s", "m"]}>
                    <StyledSpacer size="32px" />
                  </EuiShowFor>

                  <EuiTitle size="l" className="forgot-password">
                    <h1>Forgot Password?</h1>
                  </EuiTitle>

                  <StyledSpacer size="32px" />

                  <EuiText>
                    <p className="desc-text forgot-password">
                      Enter the email address you used when you joined and we'll
                      send you a link to reset your password.
                    </p>
                  </EuiText>

                  <StyledSpacer size="32px" />

                  <EuiFormRow
                    label={<StyledSpan>Email Address</StyledSpan>}
                    className="input-size"
                    isInvalid={
                      formikBag.touched.email && !!formikBag.errors.email
                    }
                    error={formikBag.errors.email}
                  >
                    <EuiFieldText
                      name="email"
                      type="email"
                      autoComplete="email"
                      onChange={(e) => {
                        formikBag.handleChange(e);
                        setEmail(e.target.value);
                      }}
                      onBlur={(e) => {
                        formikBag.handleBlur(e);
                        if (!validateEmail(e.target.value)) {
                          setEmailError(true);
                        } else {
                          setEmailError(false);
                        }
                      }}
                      value={formikBag.values.email}
                      isInvalid={
                        formikBag.touched.email && !!formikBag.errors.email
                      }
                    />
                  </EuiFormRow>

                  <StyledSpacer size="32px" />

                  <EuiButton
                    className="login-button"
                    type="submit"
                    color="primary"
                    fill
                    isLoading={loading}
                  >
                    {!loading && "Send link"}
                  </EuiButton>

                  <StyledSpacer size="24px" />

                  <EuiText size="m" className="add-text">
                    <p>
                      <StyledEuiLink color="text" to="/login">
                        Back to Login
                      </StyledEuiLink>
                    </p>
                  </EuiText>
                </form>
              )}
            />
          )}
        </div>
      </div>
    </LoginContainer>
  );
};

export default ForgotPassword;
