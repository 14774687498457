import React from "react";
import { useDispatch } from "react-redux";

import { setHelp } from "src/store/dashboard/actions";

interface ILearnMoreLink {
  learnMoreKey: string;
}

const LearnMoreLink = ({ learnMoreKey }: ILearnMoreLink) => {
  const dispatch = useDispatch();
  const openLink = () => dispatch(setHelp(learnMoreKey));

  return (
    <a href="#" onClick={openLink}>
      (Learn More)
    </a>
  );
};

export default LearnMoreLink;
