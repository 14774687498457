import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";

import { Grid } from "@material-ui/core";

import { validateZipApi } from "src/apiService";
import CenterContainer from "src/components/CenterContainer";
import Questionnaire from "src/components/Questionnaire";
import {
  QuestionFormProps,
  singleQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";
import { validateZip } from "src/utils";
// import CenterContainer from "../PlanBuilder/Components/CenterContainer";

const Moving = ({
  planSetter,
  planData,
  goToNext,
  nextClicked,
}: QuestionFormProps) => {
  // note: hold initial next click count
  const [nextClick] = useState(nextClicked);
  const questionData: QuestionForm = singleQuestions.moving;

  const startingValues = cloneDeep(questionData.emptyValue);
  for (const key in questionData.emptyValue) {
    // check the questionnaire for the key
    // if key exists then use that value
    const savedData = planData[key as keyof typeof planData];
    if (savedData && savedData !== "n") {
      // get the field for the question
      const question = questionData.fields.find(
        (question) => question.field === key
      );
      // set all field enablers to "y"
      if (question && question.enabled) {
        const enablers = question.enabled;
        enablers.forEach((enabler) => {
          startingValues[enabler] = "y";
        });
      }
      startingValues[key] = savedData;
    }
  }
  const [values, setValues] = useState<Question>(startingValues);

  const handleChange = (newValues: any) => {
    updatePlanData(newValues);
    setValues(newValues);
  };

  // on next wait for api to send back response
  // if invalid then set the invalid key first
  // followed by releasing waiting trigger
  // note: if next click count changed after mount then run goToNext
  const handleNext = async () => {
    if (nextClicked !== nextClick) {
      const hasValidZip =
        planData.will_move == "n" ||
        (validateZip(planData.move_zip_code) &&
          (await validateZipApi(planData.move_zip_code)));
      // Only all moving on if the data passes validation
      if (hasValidZip) {
        goToNext();
      }
    }
  };

  useEffect(() => {
    handleNext();
  }, [nextClicked]);

  const updatePlanData = (changedValues: any) => {
    const newPlan = { ...planData };
    if (changedValues.will_move === "n") {
      //  if preReq is n then set will_nonprofit to n
      newPlan.will_move = "n";
      newPlan.move_date = "";
      newPlan.move_zip_code = "";
    } else if (changedValues.will_move === "y") {
      // if preReq is y then update will_nonprofit accordingly
      newPlan.will_move = changedValues.will_move;
      newPlan.move_date = changedValues.move_date;
      newPlan.move_zip_code = changedValues.move_zip_code;
    }
    planSetter(newPlan);
  };

  return (
    <CenterContainer
      title={questionData.typeLabel}
      help={questionData.help}
      iconName="fb-event"
      scrollable
    >
      <Grid container>
        <Questionnaire
          questions={questionData}
          values={values}
          onChange={handleChange}
        />
      </Grid>
    </CenterContainer>
  );
};

export default Moving;
