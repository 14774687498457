import produce from "immer";
import { handleActions } from "redux-actions";

import {
  Coupon,
  FbAction,
  PurchasePlan,
  StripeState,
  Subscription,
} from "../../interfaces";
import { FAIL, START, SUCCESS } from "../common";
import { errorReducer, setLoading } from "../common/reducers";
import * as actions from "./actions";

const initialState: StripeState = {
  plans: [],
  subscriptions: [],
  pubkey: "",
  promo: "",
  coupons: undefined,
  portal: "",

  loaded: false,
  loading: false,
  error: null,
};

const reducerDefinitions: any = {
  [actions.GET_PUBLIC_KEY + SUCCESS]: (
    state: StripeState,
    { payload }: FbAction<string>
  ) =>
    produce(state, (draft: StripeState) => {
      draft.loading = false;
      draft.loaded = true;
      draft.pubkey = payload;
    }),
  [actions.GET_PURCHASE_PLANS + SUCCESS]: (
    state: StripeState,
    { payload }: FbAction<Array<PurchasePlan>>
  ) =>
    produce(state, (draft: StripeState) => {
      draft.loading = false;
      draft.loaded = true;
      draft.plans = payload;
    }),
  [actions.GET_COUPONS + SUCCESS]: (
    state: StripeState,
    { payload }: FbAction<Array<Coupon>>
  ) =>
    produce(state, (draft: StripeState) => {
      draft.loading = false;
      draft.loaded = true;
      draft.coupons = payload;
    }),

  [actions.GET_SUBSCRIPTIONS + SUCCESS]: (
    state: StripeState,
    { payload }: FbAction<Array<Subscription>>
  ) =>
    produce(state, (draft: StripeState) => {
      draft.loading = false;
      draft.loaded = true;
      draft.subscriptions = payload;
    }),
  [actions.CREATE_SUBSCRIPTION + SUCCESS]: (
    state: StripeState,
    { payload }: FbAction<Subscription>
  ) =>
    produce(state, (draft: StripeState) => {
      draft.loading = false;
      draft.loaded = true;
      if (state.subscriptions) {
        draft.subscriptions = [...state.subscriptions, payload];
      } else {
        draft.subscriptions = [payload];
      }
    }),
  [actions.DELETE_SUBSCRIPTION + SUCCESS]: (
    state: StripeState,
    { payload }: FbAction<Array<Subscription>>
  ) =>
    produce(state, (draft: StripeState) => {
      draft.loading = false;
      draft.loaded = true;
      draft.subscriptions = payload;
    }),
  [actions.GET_ALL_PURCHASE_DATA + SUCCESS]: (
    state: StripeState,
    { payload }: FbAction<StripeState>
  ) =>
    produce(state, (draft: StripeState) => {
      draft.loading = false;
      draft.loaded = true;
      draft.plans = payload.plans;
      draft.subscriptions = payload.subscriptions;
      draft.portal = payload.portal;
    }),
};

[
  actions.GET_PUBLIC_KEY,
  actions.GET_PURCHASE_PLANS,
  actions.GET_SUBSCRIPTIONS,
  actions.CREATE_SUBSCRIPTION,
  actions.DELETE_SUBSCRIPTION,
  actions.GET_COUPONS,
  actions.GET_ALL_PURCHASE_DATA,
].forEach((actionType) => {
  reducerDefinitions[actionType + START] = setLoading;
  reducerDefinitions[actionType + FAIL] = errorReducer;
});

const stripeReducer = handleActions<StripeState, any>(
  reducerDefinitions,
  initialState
);

export default stripeReducer;
