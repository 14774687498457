import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { setProfileStep } from "src/store/profileBuild/actions";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";

import {
  EuiTitle,
  EuiButton,
  EuiButtonEmpty,
  EuiImage,
  EuiSpacer,
  EuiShowFor,
} from "@elastic/eui";
import { Col } from "react-bootstrap";
import {
  StyledSpacer,
  StyledEuiCallOut,
} from "src/components/Global/StyledComponents";
import { closeTourCard } from "src/store/tourcard/actions";
import {
  NumberList,
  StyledEuiText,
  StyledMobileRow,
  StyledMobileEuiSpacer,
  StyledImageCol,
  WelcomeLanding,
} from "../../components";

interface WelcomeProps {
  nextStep?: PROFILE_BUILD_STEPS;
  prevStep?: PROFILE_BUILD_STEPS;
  title: string;
  headerContent: React.ReactNode;
  boldStep: number;
  welcomeImg: any;
  nextAction?: VoidFunction;
  nextLabel?: string;
  secondaryButton?: React.ReactNode;
}

const Welcome = ({
  nextStep,
  prevStep,
  title,
  headerContent,
  boldStep,
  welcomeImg,
  nextAction,
  nextLabel,
  secondaryButton,
}: WelcomeProps) => {
  const dispatch = useDispatch();
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const onNext = () => {
    if (nextAction) {
      nextAction();
    } else {
      dispatch(setProfileStep(nextStep || PROFILE_BUILD_STEPS.PERSONAL_INFO));
      dispatch(closeTourCard());
    }
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  };
  const onPrev = () => {
    if (prevStep !== undefined) {
      dispatch(setProfileStep(prevStep));
      dispatch(closeTourCard());
    }
  };

  const numbers = [
    <li>
      1. <span>Personal Info</span>
    </li>,
    <li>
      2. <span>Financial Info</span>
    </li>,
    <li>
      3. <span>Human Capital</span>
    </li>,
  ];

  numbers[boldStep] = <b>{numbers[boldStep]}</b>;

  return (
    <>
      <WelcomeLanding fluid ref={mainContainerRef}>
        <StyledMobileRow className="justify-content-sm-center align-items-sm-center">
          <Col sm={{ span: 8 }} lg={{ span: 7 }}>
            {boldStep !== 0 && prevStep !== undefined && (
              <EuiShowFor sizes={["l", "xl"]}>
                <EuiButtonEmpty
                  color="text"
                  flush="left"
                  iconType="arrowLeft"
                  onClick={onPrev}
                >
                  Back
                </EuiButtonEmpty>
                <StyledSpacer size="64px" />
              </EuiShowFor>
            )}
            <EuiTitle size="l" className="header-font">
              <h1>{title}</h1>
            </EuiTitle>
            <EuiSpacer size="l" />
            <StyledEuiText size="m">
              <div className="landing-text">{headerContent}</div>
            </StyledEuiText>
            <StyledSpacer size="48px" />
            <NumberList>
              <StyledEuiText size="m">
                {numbers[0]}
                <EuiSpacer size="s" />
                {numbers[1]}
                <EuiSpacer size="s" />
                {numbers[2]}
              </StyledEuiText>
            </NumberList>
            {boldStep === 0 && (
              <>
                <StyledSpacer size="32px" />
                <StyledEuiCallOut
                  title="You will be able to schedule a call after completing your profile."
                  iconType="iInCircle"
                  style={{ maxWidth: "600px" }}
                ></StyledEuiCallOut>
              </>
            )}
            <StyledSpacer size="48px" />
            <EuiButton className="btn-text" fill onClick={onNext}>
              {nextLabel || "Next"}
            </EuiButton>
            {!!secondaryButton && <>{secondaryButton}</>}
          </Col>
          {boldStep !== 0 ? (
            <StyledImageCol sm={{ span: 8 }} lg={{ span: 4 }}>
              <EuiShowFor sizes={["l", "xl"]}>
                <StyledSpacer size="64px" />
                <EuiImage src={welcomeImg} alt="image_alt" size="xl" />
              </EuiShowFor>
              <EuiShowFor sizes={["m", "s", "xs"]}>
                <EuiButtonEmpty
                  color="text"
                  flush="left"
                  iconType="arrowLeft"
                  onClick={onPrev}
                  className="align-left"
                >
                  Back
                </EuiButtonEmpty>
                <div className="center-wrapper">
                  <EuiImage
                    src={welcomeImg}
                    alt="image_alt"
                    size="l"
                    className="align-center"
                  />
                </div>
              </EuiShowFor>
              <StyledMobileEuiSpacer />
            </StyledImageCol>
          ) : (
            <StyledImageCol
              sm={{ span: 8 }}
              lg={{ span: 4 }}
              style={{ textAlign: "center" }}
            >
              <EuiShowFor sizes={["l", "xl"]}>
                <EuiImage src={welcomeImg} alt="image_alt" size="xl" />
              </EuiShowFor>
              <EuiShowFor sizes={["m", "s", "xs"]}>
                <EuiImage src={welcomeImg} alt="image_alt" size="l" />
              </EuiShowFor>
              <StyledMobileEuiSpacer />
            </StyledImageCol>
          )}
        </StyledMobileRow>
      </WelcomeLanding>
    </>
  );
};

export default Welcome;
