import React from "react";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";

interface TooltipInterface {
  title: string;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

const ToolTipIcon = ({ title, placement }: TooltipInterface) => {
  return (
    <Tooltip title={title} placement={placement}>
      <InfoOutlinedIcon fontSize="small" />
    </Tooltip>
  );
};

export default ToolTipIcon;
