import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { flatMap, map, pickBy, sortBy } from "lodash";

import DateFnsUtils from "@date-io/date-fns";
import {
  FormControlLabel,
  FormLabel,
  ListSubheader,
  makeStyles,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { addTransactionApi } from "src/apiService";
import Button from "src/components/Button";
import ConfirmDialog from "src/components/Dialogs/ConfirmDialog";
import CustomDialog from "src/components/Dialogs/CustomDialog";
import Icon from "src/components/Icon";
import Radio from "src/components/Radio";
import {
  Account,
  ACCOUNT_TYPES,
  isIncomeTypeToConfirm,
  NewTransaction,
  STUDENT_TOP_LEVEL_CATEGORIES,
  TOP_LEVEL_CATEGORIES,
  Transaction,
  TRANSACTION_ACCOUNT_TYPES,
  TRANSACTION_CATEGORIES,
  TYPE_LIST_ORDER,
} from "src/interfaces";
import {
  addTransactionSuccess,
  addUnconfirmedTransaction,
} from "src/store/transaction/actions";
import { getAccounts } from "src/store/account/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import colors from "src/theme/colors";
import { theme } from "src/theme";
import { DollarTextField } from "src/utils";

const useStyles = makeStyles({
  title: {
    fontSize: "16px !important",
  },
  content: {
    height: 576,
    overflowY: "auto",
  },
  item: {
    alignItems: "center",
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    height: 55,
    marginTop: 10,
    border: `1px solid ${colors.blueGray7}`,
    padding: `0 5px 0 0`,
    display: "flex",
    justifyContent: "space-between",
  },
  subtitle: {
    fontSize: 10,
    color: colors.blueGray2,
  },
  innerItem: {
    alignItems: "center",
    display: "flex",
  },
  textInputLabel: {
    marginBottom: 4,
    marginTop: 8,
    fontSize: 10,
  },
});

interface AddManualTransactionDialogProps {
  visible: boolean;
  onClose: VoidFunction;
  openConfirmDialog: (transaction: Transaction) => void;
  prefill?: any;
  onAdd?: VoidFunction;
  defaultAddAnother?: boolean;
}

const AddManualTransaction = ({
  visible,
  onAdd,
  onClose,
  openConfirmDialog,
  prefill,
  defaultAddAnother,
}: AddManualTransactionDialogProps) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const accounts = useSelector(getAccounts);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const [confirmAddAnother, setConfirmAddAnother] = useState(
    !!defaultAddAnother
  );
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [formState, setFormState] = useState<any>({
    ...(prefill || {}),
    reverse: "n",
  });

  useEffect(() => {
    if (!visible) {
      setConfirmAddAnother(false);
      setFormState({ reverse: "n" });
    } else if (prefill) {
      setFormState((current: any) => ({
        ...prefill,
        ...current,
        reverse: "n",
      }));
    }
  }, [visible, prefill]);

  const topLevelCategories = isCurrentStudent
    ? STUDENT_TOP_LEVEL_CATEGORIES
    : TOP_LEVEL_CATEGORIES;

  // const firstCashAccount = accounts.find(
  //   (account) => account.variable === "cash_value"
  // );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const setFormValue = (e: any) => {
    const target = e.target;
    const newFormState = { ...formState, [target.name]: target.value };
    if (target.name === "category") {
      const category = TRANSACTION_CATEGORIES[target.value];
      const entries = Object.entries(category.types);
      newFormState.type = entries.length ? +entries[0][0] : undefined;
    }
    if (newFormState.type !== formState.type) {
      const validAccountTypes =
        TRANSACTION_ACCOUNT_TYPES[
          ("" + newFormState.type) as keyof typeof TRANSACTION_ACCOUNT_TYPES
        ];
      if (validAccountTypes) {
        const firstAccountType = validAccountTypes[0];
        const firstAccount = accounts.find(
          (account) => (account.variable || account.type) === firstAccountType
        );
        newFormState.account = firstAccount?.id || undefined;
      } else {
        newFormState.account = undefined;
      }
    }
    setFormState(newFormState);
  };

  const submit = () => {
    const date = selectedDate || new Date();
    let month = "" + (date.getMonth() + 1);
    if (+month < 10) {
      month = `0${month}`;
    }
    const newTransaction: NewTransaction = {
      account: formState.account,
      date: `${date.getFullYear()}-${month}-${date.getDate()}`,
      amount: formState.amount,
      type: formState.type,
      description: formState.description,
      reverse: formState.reverse === "y",
    };
    addTransactionApi(newTransaction)
      .then((result) => {
        const resultTransaction = result?.transactions?.[0];
        resultTransaction.isManual = true;
        if (resultTransaction) {
          if (isIncomeTypeToConfirm(resultTransaction.type)) {
            dispatch(addUnconfirmedTransaction(resultTransaction));
            openConfirmDialog(resultTransaction);
          } else {
            dispatch(addTransactionSuccess(resultTransaction));
            setConfirmAddAnother(true);
          }
        }
        setFormState({
          ...(prefill || {}),
          reverse: "n",
        });
        if (onAdd) {
          onAdd();
        }
      })
      .catch(console.error);
  };

  const submitEnabled =
    formState.account !== undefined &&
    formState.amount &&
    formState.category !== undefined &&
    formState.type !== undefined;

  let availableAccounts: Account[] = [];
  if (formState.type !== undefined) {
    const validAccountTypes =
      TRANSACTION_ACCOUNT_TYPES[
        (formState.type + "") as keyof typeof TRANSACTION_ACCOUNT_TYPES
      ];
    availableAccounts = accounts.filter(
      (account) =>
        validAccountTypes.indexOf(account.variable || account.type || "") >= 0
    );
  }

  let availableTypes: any = undefined;
  let typeSelection: JSX.Element[] = [];
  const selectedCategory =
    formState.category === undefined
      ? undefined
      : topLevelCategories[formState.category];
  const subcategories =
    selectedCategory === undefined
      ? []
      : TRANSACTION_CATEGORIES.slice(
          selectedCategory.start,
          selectedCategory.end
        );
  if (subcategories.length) {
    availableTypes = {};
    typeSelection = flatMap(subcategories, (subcategory) => {
      const typeList: any[] = map(
        sortBy(
          Object.entries(
            pickBy(subcategory.types, (label, value) => value !== "6000")
          ),
          (item: [string, string]) =>
            TYPE_LIST_ORDER[item[0] as keyof typeof TYPE_LIST_ORDER]
        ),
        ([value, label]: [string, string]) => {
          availableTypes[value] = label;
          return (
            <MenuItem key={+value} value={+value}>
              {label}
            </MenuItem>
          );
        }
      );
      return [
        <ListSubheader key={subcategory.label} disableSticky>
          {subcategory.label}
        </ListSubheader>,
        ...typeList,
      ];
    });
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {!confirmAddAnother && (
        <CustomDialog
          title="Add Manual Transaction"
          size="sm"
          isOpen={visible}
          onClose={onClose}
        >
          <form className={styles.content}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              placeholder="Select Date"
              fullWidth
              label="Select Date"
              value={selectedDate}
              onChange={handleDateChange}
              keyboardIcon={<Icon iconName="fb-calendar" />}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <Select
              className="my-2"
              fullWidth
              name="category"
              variant="outlined"
              displayEmpty
              renderValue={(value: any) =>
                value !== "" ? topLevelCategories[value].label : "Category"
              }
              value={formState.category === undefined ? "" : formState.category}
              onChange={setFormValue}
            >
              {topLevelCategories.map((item, index) => (
                <MenuItem key={item.label} value={index}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <Select
              className="my-2"
              fullWidth
              disabled={formState.category === undefined}
              name="type"
              variant="outlined"
              displayEmpty
              renderValue={(value: any) =>
                value !== "" && availableTypes
                  ? availableTypes["" + value]
                  : "Type"
              }
              value={formState.type === undefined ? "" : formState.type}
              onChange={setFormValue}
            >
              {typeSelection}
            </Select>
            <Select
              className="my-2"
              fullWidth
              disabled={formState.type === undefined}
              name="account"
              variant="outlined"
              displayEmpty
              renderValue={(value: any) => {
                const account = accounts.find(
                  (account) => account.id === value
                );
                if (account) {
                  if (!account.variable) {
                    return account.name || "Unnamed";
                  }
                  return (
                    account.name || `Unnamed ${ACCOUNT_TYPES[account.variable]}`
                  );
                }
                return "Account";
              }}
              value={formState.category === undefined ? "" : formState.account}
              onChange={setFormValue}
            >
              {availableAccounts.map((account: Account) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.name
                    ? account.name
                    : `Unnamed ${
                        account.variable ? ACCOUNT_TYPES[account.variable] : ""
                      }`}
                </MenuItem>
              ))}
            </Select>
            <FormLabel className={styles.textInputLabel} component="legend">
              Amount
            </FormLabel>
            <DollarTextField
              className="mb-2"
              fullWidth
              name="amount"
              variant="outlined"
              value={formState.amount || ""}
              onChange={setFormValue}
            />
            <FormLabel className={styles.textInputLabel} component="legend">
              Description
            </FormLabel>
            <TextField
              className="mb-2"
              fullWidth
              name="description"
              variant="outlined"
              value={formState.description || ""}
              onChange={setFormValue}
            />
            <FormLabel className="mt-2" component="legend">
              Mark as refund or reversal?
            </FormLabel>
            <RadioGroup
              aria-label="reverse"
              name="reverse"
              value={formState.reverse}
              className="flex flex-row mx-2"
              onChange={setFormValue}
            >
              <FormControlLabel value="n" control={<Radio />} label="No" />
              <FormControlLabel value="y" control={<Radio />} label="Yes" />
            </RadioGroup>
          </form>
          <Button
            disabled={!submitEnabled}
            fullWidth
            fbColor="primary"
            onClick={submit}
          >
            Add Transaction
          </Button>
        </CustomDialog>
      )}
      {confirmAddAnother && (
        <ConfirmDialog
          visible={visible}
          title="Add Another Transaction"
          message="Would you like to add another transaction?"
          onCancel={onClose}
          onConfirm={() => setConfirmAddAnother(false)}
          cancelButton="No, Thank You"
          confirmButton="Yes, Please"
        />
      )}
    </MuiPickersUtilsProvider>
  );
};

export default AddManualTransaction;
