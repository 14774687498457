import React from "react";

import clsx from "clsx";

import { Box, Card, Divider, makeStyles, Typography } from "@material-ui/core";

import { SidebarContent } from "src/interfaces";
import HelpToggle from "../HelpToggle";

const useStyles = makeStyles({
  container: {
    backgroundColor: "white",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    margin: "20px auto",
    "& h1": {
      "font-size": "15px",
    },
  },
  textBody: {
    padding: "24px 20px 0px 20px",
    fontSize: "12px",
  },
});

// TODO: Merge with Card

const TitleCard = ({
  title,
  sideContent,
  children,
  className,
  style,
}: {
  title: string;
  sideContent?: SidebarContent[];
  children: any;
  className?: string;
  style?: any;
}) => {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.container, className || "")} style={style}>
      <Box style={{ display: "flex" }}>
        <Typography variant="h1" className="mb-4">
          {title}
        </Typography>
        {sideContent && <HelpToggle content={sideContent} />}
      </Box>
      <Divider />
      <Box className={classes.textBody}>{children}</Box>
    </Card>
  );
};

export default TitleCard;
