import React from "react";

const LinkedText = ({
  text,
  links,
  indicator,
}: {
  text: string;
  links: string[];
  indicator: string;
}) => {
  const segments = text.split(indicator);
  const count = segments.length - 1;
  return (
    <>
      {segments.map((text, index) => (
        <>
          {text}
          {index < count && <a href={links[index]}>{indicator}</a>}
        </>
      ))}
    </>
  );
};

export default LinkedText;
