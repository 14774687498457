import React from "react";
import { Box, Typography } from "@material-ui/core";

import { AdapterLink } from "src/utils";
import Button from "src/components/Button";

interface CompareProps {
  close: VoidFunction;
  transitionPopup: VoidFunction;
}

const ComparePopup = ({ close, transitionPopup }: CompareProps) => {
  return (
    <Box className="flex flex-col justify-between items-center h-36">
      <Typography variant="h1">Implement Your Plan</Typography>
      <Typography variant="h6">
        Would you like to implement one of the plans you built and begin
        tracking your financial plan or close this comparison screen?
      </Typography>
      <Box className="flex justify-between">
        <Button
          // className={styles.actionButton}
          fbColor="primary"
          onClick={() => {
            transitionPopup();
          }}
          className="mr-4"
        >
          Yes, I Want To Implement My Plan
        </Button>
        <Button
          component={AdapterLink}
          to="/plan-list"
          fbColor="primary"
          onClick={() => {
            close();
          }}
        >
          Not At This Time
        </Button>
      </Box>
    </Box>
  );
};

export default ComparePopup;
