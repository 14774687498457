import React, { useEffect, useState } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Card from "src/components/Card";
import { Box, Typography } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Icon from "src/components/Icon";
import Colors from "src/theme/colors";
import AccountItem from "./AccountItem";
import { MappedAccount, ASSET_TYPES, DEBT_TYPES } from "src/interfaces";
import { PROFILE_TYPE } from "src/store/profileBuild/constants";
import { useSelector } from "react-redux";
import {
  getAccountProviders,
  getLinkedAccounts,
} from "src/store/account/selector";

interface IAccountsListCard {
  className?: string;
  title?: string;
  data?: MappedAccount[];
  isAsset?: boolean;
  onEdit: (id: any) => void;
  footerContent?: React.ReactNode;
  allExpanded?: boolean;
  startExpanded?: boolean;
}

export const AccountsListCard = ({
  className,
  title,
  data,
  isAsset,
  onEdit,
  footerContent,
  allExpanded,
  startExpanded,
}: IAccountsListCard) => {
  const styles = useStyles();
  const linkedAccounts = useSelector(getLinkedAccounts);
  const providers = useSelector(getAccountProviders);
  const [expanded, setExpanded] = useState<Set<number>>(new Set([0]));

  useEffect(() => {
    if (startExpanded) {
      const startingExpanded = new Set(
        Array.from(Array(Object.keys(ASSET_TYPES).length).keys())
      );
      setExpanded(startingExpanded);
    }
  }, []);

  const toggleExpanded = (index: number) => {
    const newExpanded = new Set(expanded);
    if (newExpanded.has(index)) {
      newExpanded.delete(index);
    } else {
      newExpanded.add(index);
    }
    setExpanded(newExpanded);
  };

  const hasVariable = (variable: string) => {
    if (data) {
      return !!data.find((e) => e.variable === variable);
    }
    return false;
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const total =
    data && data.length
      ? data.reduce((result, account) => result + (account.balance || 0), 0)
      : 0;

  const myFedLoans = (data || []).filter(
    (account) =>
      account.variable === "fed_loan" &&
      (account.whose || account.who) !== PROFILE_TYPE.SPOUSE
  );
  const spouseFedLoans = (data || []).filter(
    (account) =>
      account.variable === "fed_loan" &&
      (account.whose || account.who) === PROFILE_TYPE.SPOUSE
  );
  const myFedLoansTotal = myFedLoans.reduce(
    (result, account) => result + (account.balance || 0),
    0
  );
  const spouseFedLoansTotal = spouseFedLoans.reduce(
    (result, account) => result + (account.balance || 0),
    0
  );

  const isLinkedAccount = (id: number) => {
    return linkedAccounts.findIndex((e) => e.id === id) > -1;
  };

  const hasError = (id: number) => {
    const linkedAccount = linkedAccounts.find((e) => e.id === id);
    if (linkedAccount) {
      const provider = providers.find(
        (e) => e.item_id === linkedAccount.item_id
      );
      return !!provider?.error;
    }
    return false;
  };

  const renderContent = () => {
    if (data && !!data.length) {
      return (
        <Box className={styles.content}>
          {Object.entries(isAsset ? ASSET_TYPES : DEBT_TYPES).map(
            ([variable, name], index) => (
              <>
                {hasVariable(variable) && (
                  <Accordion
                    expanded={allExpanded || expanded.has(index)}
                    onChange={() => toggleExpanded(index)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <Icon
                          iconName={
                            allExpanded || expanded.has(index)
                              ? "fb-chevron-up"
                              : "fb-chevron-right"
                          }
                          className={styles.heading}
                        />
                      }
                    >
                      <Typography className={styles.heading}>
                        {name as string}
                      </Typography>
                    </AccordionSummary>
                    {variable === "fed_loan" ? (
                      <AccordionDetails>
                        {!!myFedLoansTotal && (
                          <AccountItem
                            title="My Federal Loans"
                            value={formatter.format(myFedLoansTotal)}
                            hasIcon={isLinkedAccount(myFedLoans[0].id)}
                            id={myFedLoans[0].id}
                            onEdit={onEdit}
                          />
                        )}
                        {!!spouseFedLoansTotal && (
                          <AccountItem
                            title="My Spouse's Federal Loans"
                            value={formatter.format(spouseFedLoansTotal)}
                            hasIcon={isLinkedAccount(spouseFedLoans[0].id)}
                            id={spouseFedLoans[0].id}
                            onEdit={onEdit}
                          />
                        )}
                      </AccordionDetails>
                    ) : (
                      <AccordionDetails>
                        {data
                          ?.filter((account) => account.variable === variable)
                          .map((account) => (
                            <AccountItem
                              title={`${
                                account.name || account.variableLabel
                              } ${
                                (account.who || account.whose) ===
                                PROFILE_TYPE.SPOUSE
                                  ? "(Spouse)"
                                  : ""
                              }`}
                              value={formatter.format(account.balance || 0)}
                              hasIcon={isLinkedAccount(account.id)}
                              id={account.id}
                              onEdit={onEdit}
                              error={hasError(account.id)}
                              helperText="Click to relink"
                            />
                          ))}
                      </AccordionDetails>
                    )}
                  </Accordion>
                )}
              </>
            )
          )}
          {footerContent || null}
        </Box>
      );
    }
    if (footerContent) {
      return footerContent;
    }
    return (
      <Box className={styles.contentEmpty}>
        <Typography className={styles.textEmpty}>Nothing to Show</Typography>
      </Box>
    );
  };

  return (
    <Card
      className={className}
      title={title}
      headComponent={
        <Typography>Total {formatter.format(total || 0)}</Typography>
      }
    >
      {renderContent()}
    </Card>
  );
};

export default AccountsListCard;

const useStyles = makeStyles({
  heading: {
    color: Colors.brandingBlue1,
  },
  content: {
    minHeight: 300,
  },
  contentEmpty: {
    minHeight: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textEmpty: {
    color: Colors.blueGray3,
  },
});

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    marginBottom: 10,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: `1px solid ${Colors.blueGray7}`,
    padding: 0,
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: `12px 0`,
    display: "flex",
    flexDirection: "column",
  },
}))(MuiAccordionDetails);
