import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  DisabilityInsuranceResult,
  getDisabilityInsuranceRecommendationAPI,
} from "src/apiService";
import { EARNED_INCOME_TYPES } from "src/interfaces";
import CalculatorLayout from "src/layouts/CalculatorLayout";
import IncomeStep from "./IncomeStep";
import LandingStep from "./LandingStep";
import ResultStep from "./ResultStep";
import { getCashflows } from "src/store/cashflow/selector";

enum STEPS {
  LANDING_SCREEN,
  INCOME_SCREEN,
  RESULT_SCREEN,
}

const incomeFilter = (item: any) =>
  !!EARNED_INCOME_TYPES[item.type] && !item.inschool;

const DisabilityInsuranceRecommendation = () => {
  const { income }: any = useSelector(getCashflows);
  const [step, setStep] = useState(STEPS.LANDING_SCREEN);
  const [result, setResult] = useState<DisabilityInsuranceResult>({
    any_occupation_monthly_benefit: 0,
    own_occupation_monthly_benefit: 0,
    s_any_occupation_monthly_benefit: 0,
    s_own_occupation_monthly_benefit: 0,
  });
  const [newIncome, setNewIncome] = useState<any>(null);
  const [confirmedIncomes, setConfirmedIncomes] = useState<any[]>([
    ...income.filter(incomeFilter),
  ]);

  const fetchResult = () => {
    const payload = confirmedIncomes.map((item) => ({
      who: item.whose === "spouse" ? "spouse" : "applicant",
      amount: item.annual,
      type: item.type,
    }));
    getDisabilityInsuranceRecommendationAPI(payload).then((data) =>
      setResult(data)
    );
  };

  const goToResults = () => {
    fetchResult();
    setStep(STEPS.RESULT_SCREEN);
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.LANDING_SCREEN:
        return <LandingStep onNext={() => setStep(STEPS.INCOME_SCREEN)} />;
      case STEPS.INCOME_SCREEN:
        return (
          <IncomeStep
            goBack={() => setStep(STEPS.LANDING_SCREEN)}
            goForward={goToResults}
            newIncome={newIncome}
            setNewIncome={setNewIncome}
            confirmedIncomes={confirmedIncomes}
            setConfirmedIncomes={setConfirmedIncomes}
          />
        );
      case STEPS.RESULT_SCREEN:
        return (
          <ResultStep {...result} goBack={() => setStep(STEPS.INCOME_SCREEN)} />
        );
    }
  };

  return (
    <CalculatorLayout
      title="How Much Disability Insurance Do I Need?"
      backTo="/insurance"
      disclaimer=""
    >
      {renderStep()}
    </CalculatorLayout>
  );
};

export default DisabilityInsuranceRecommendation;
