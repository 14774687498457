import React from "react";
import { Box, Theme } from "@material-ui/core";
import TitleCard from "src/components/TitleCard";
import { makeStyles } from "@material-ui/styles";
import { formatWholeDollars } from "src/utils";

interface ResultStepProps {
  months_expenses_to_save: number;
  monthly_expenses: number;
}

const ResultStep = ({
  months_expenses_to_save,
  monthly_expenses,
}: ResultStepProps) => {
  const classes = useStyles();
  return (
    <>
      <TitleCard title="How Much Should I Have In An Emergency Fund?">
        <Box className={classes.container}>
          <Box className={classes.flexItem}>
            <Box>Your Total Monthly Living Expenses &amp; Debt Payments:</Box>
            <Box>{formatWholeDollars(monthly_expenses)}</Box>
          </Box>
          <Box className={classes.flexItem}>
            <Box className={classes.xSign}>X</Box>
            <Box>Recommended Number of Months Of "Safety Net":</Box>
            <Box>{months_expenses_to_save}</Box>
          </Box>
          <Box className={classes.flexItemWithBorder}>
            <Box>Target Emergency Fund Amount:</Box>
            <Box>
              {formatWholeDollars(monthly_expenses * months_expenses_to_save)}
            </Box>
          </Box>
        </Box>
      </TitleCard>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  flexItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    minWidth: 400,
    position: "relative",
  },
  flexItemWithBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    minWidth: 400,
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
  },
  xSign: {
    position: "absolute",
    left: -15,
  },
}));

export default ResultStep;
