import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Link, makeStyles, Typography } from "@material-ui/core";

import { getBreakoutBaseApi, refreshActionItemsApi } from "src/apiService";
import Button from "src/components/Button";
import CustomDialog from "src/components/Dialogs/CustomDialog";
import IncomeTransactionList from "src/components/IncomeTransactionList";
import { AppLoader } from "src/components/Spinners";
import ConfirmIncomeDialog from "src/pages/Dashboard/MyProfile/Transactions/Dialogs/ConfirmIncomeDialog";
import { Transaction, CONFIRMATION_MODAL_STATUS } from "src/interfaces";
import { fetchActionItems } from "src/store/dashboard/actions";
import { colors } from "src/theme";

import {
  closeConfirmationModal,
  confirmTransactions,
  getUnconfirmedTransactions,
} from "src/store/transaction/actions";
import {
  getConfirmationModalOpen,
  getConfirmationModalStatus,
  getLoadingUnconfirmedTransactions,
  getUnconfirmedIncomeAndExpenses,
} from "src/store/transaction/selector";
import { getHasPlan, getIsSubscribed } from "src/store/system/selector";

const useStyles = makeStyles({
  link: {
    color: colors.gray4,
    fontSize: 15,
  },
  text: {
    fontSize: 18,
    marginBottom: 8,
  },
  title: {
    "& > h2": {
      fontSize: 24,
    },
  },
});

export const emptyTransaction: Transaction = {
  account: -999,
  amount: -999,
  date: "1999-01-01",
  description: "",
  id: -999,
  type: 7000,
};

const RootConfirmationDialog = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const subscribed = useSelector(getIsSubscribed);
  const hasPlan = useSelector(getHasPlan);
  const activeStatus = useSelector(getConfirmationModalStatus);
  const loading = useSelector(getLoadingUnconfirmedTransactions);
  const { income, transfers } = useSelector(getUnconfirmedIncomeAndExpenses);
  const open = useSelector(getConfirmationModalOpen);

  const [confirmedOne, setConfirmedOne] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [
    confirmingTransaction,
    setConfirmingTransaction,
  ] = useState<Transaction>(emptyTransaction);
  const [selectedTransfers, setSelectedTransfers] = useState<Set<number>>(
    new Set()
  );

  const showLoading = showConfirmDialog || loading;

  if (
    !subscribed ||
    !hasPlan ||
    activeStatus === CONFIRMATION_MODAL_STATUS.NONE
  ) {
    return null;
  }

  let title = "Welcome Back!";

  const confirmSelectedTransfers = () => {
    dispatch(confirmTransactions(Array.from(selectedTransfers)));
  };

  const openConfirmDialog = (transaction?: Transaction) => {
    let rootTransaction = transaction;
    let promise = Promise.resolve();
    if (transaction) {
      promise = getBreakoutBaseApi(transaction.id)
        .then((result: any) => {
          rootTransaction = result?.transactions?.[0] || transaction;
        })
        .catch(console.error);
    }
    return promise.then(() => {
      if (rootTransaction) {
        setConfirmingTransaction(rootTransaction);
      } else {
        setConfirmingTransaction(emptyTransaction);
      }
      setShowConfirmDialog(true);
    });
  };

  if (activeStatus === CONFIRMATION_MODAL_STATUS.INCOME) {
    if (!showLoading && !income.length) {
      title = "You're All Done!";
    } else if (confirmedOne) {
      title = "Confirm Your Income Transactions";
    }

    const refresh = () => {
      dispatch(getUnconfirmedTransactions());
    };

    const sync = () => {
      setConfirmedOne(true);
    };

    return (
      <CustomDialog
        size="sm"
        isOpen={open}
        title={title}
        hideCloseIcon
        titleClassName={styles.title}
      >
        {showLoading && (
          <Box className="h-48">
            <AppLoader />
          </Box>
        )}
        {!showLoading && !!income.length && (
          <>
            {!confirmedOne && (
              <Typography variant="body1" className={styles.text}>
                Good news, there are new Income transactions from your linked
                accounts. Please confirm these transactions to ensure the
                accuracy of your plan.
              </Typography>
            )}
            <IncomeTransactionList
              variableHeight
              items={income}
              openConfirmDialog={openConfirmDialog}
            />
          </>
        )}
        {!showLoading && (
          <Box className="flex items-center mt-2">
            <Box className="w-1/3">
              {!!income.length && (
                <Link
                  href="#"
                  onClick={() => dispatch(closeConfirmationModal())}
                  className={styles.link}
                >
                  I'll do this later
                </Link>
              )}
            </Box>
            <Box className="w-1/3 text-center">
              {!income.length && (
                <Button
                  fbColor="primary"
                  onClick={() => {
                    refreshActionItemsApi().then(() => {
                      dispatch(fetchActionItems());
                    });
                    dispatch(closeConfirmationModal());
                  }}
                >
                  Done
                </Button>
              )}
              {!!income.length && confirmedOne && (
                <Button fbColor="primary" onClick={refresh}>
                  Refresh
                </Button>
              )}
            </Box>
          </Box>
        )}
        <ConfirmIncomeDialog
          open={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          transaction={confirmingTransaction}
          alreadyConfirmed={false}
          onFinish={sync}
        />
      </CustomDialog>
    );
  }

  const handleSelect = (transaction: Transaction) => {
    setSelectedTransfers((current) => {
      const newSet = new Set(current);
      if (newSet.has(transaction.id)) {
        newSet.delete(transaction.id);
      } else {
        newSet.add(transaction.id);
      }
      return newSet;
    });
  };

  const closeAndConfirmTransfers = () => {
    confirmSelectedTransfers();
    setShowConfirmDialog(false);
    setConfirmedOne(false);
    dispatch(closeConfirmationModal());
  };

  return (
    <CustomDialog
      size="sm"
      isOpen={open}
      title={transfers.length ? "Welcome Back!" : "You're All Done!"}
      hideCloseIcon
      titleClassName={styles.title}
    >
      {showLoading && (
        <Box className="h-48">
          <AppLoader />
        </Box>
      )}
      {!showLoading && (
        <>
          {!!transfers.length && (
            <>
              <Typography variant="body1" className={styles.text}>
                Please review and confirm the transactions below. If any are
                retirement/investment contributions or debt payments, please
                edit and assign to the correct category and transaction type.
              </Typography>
              <IncomeTransactionList
                variableHeight
                onSelect={handleSelect}
                selectedIds={selectedTransfers}
                items={transfers}
                openConfirmDialog={openConfirmDialog}
              />
            </>
          )}
          <Box className="flex items-center mt-2">
            <Box className="w-1/3">
              {!!transfers.length && (
                <Link
                  href="#"
                  onClick={closeAndConfirmTransfers}
                  className={styles.link}
                >
                  I'll do this later
                </Link>
              )}
            </Box>
            <Box className="w-1/3 text-center">
              <Button fbColor="primary" onClick={closeAndConfirmTransfers}>
                Done
              </Button>
            </Box>
          </Box>
        </>
      )}
      <ConfirmIncomeDialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        transaction={confirmingTransaction}
        alreadyConfirmed={false}
      />
    </CustomDialog>
  );
};

export default RootConfirmationDialog;
