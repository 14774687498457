import axiosClient from "../axiosClient";
import makeAuthenticatedRequest from "../authenticatedRequest";
import { Cashflow } from "../../interfaces/cashflow.interface";

export const getCashflowDetailApi = (id: number) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`cashflow/cashflow.php?id=${id}`);
    return data;
  });

export const getCashflowApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("cashflow/cashflow.php");
    return data;
  });

export const addCashflowApi = (item: Cashflow) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("cashflow/cashflow.php", item);
    if (data.length) {
      return data[0];
    }
    return null;
  });

export const deleteCashflowApi = (id: number) =>
  makeAuthenticatedRequest(async () => {
    const { status } = await axiosClient.delete(
      `cashflow/cashflow.php?id=${id}`
    );
    return status === 204;
  });

export const updateCashflowApi = (item: any) =>
  makeAuthenticatedRequest(async () => {
    const body: any = { amount: item.amount };
    if (item.death_benefit) {
      body.death_benefit = item.death_benefit;
    }
    const { data } = await axiosClient.put(
      `cashflow/cashflow.php?id=${item.id}`,
      body
    );
    return data;
  });
