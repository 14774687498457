import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";

import EnterName from "./EnterName";
import ReuseSelections from "./ReuseSelections";

const introViews = {
  [PLAN_BUILD_STEPS.NAME]: EnterName,
  [PLAN_BUILD_STEPS.REUSE_SELECTIONS]: ReuseSelections,
};

export default introViews;
